import Joi from 'joi'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { joiResolver } from '@hookform/resolvers/joi'

import Btn from '../../../../UI/Btn'
import TextField from '../../../../UI/TextField'
import SelectField from '../../../../UI/SelectField'
import TextAreaField from '../../../../UI/TextAreaField'

import { logIn, setAuth, setSignInMode, updateProfile } from '../../../../redux/ducks/user'

import http from '../../../../shared/http'
import { joiPhone } from '../../../../shared/helpers'
import { StateOptions, TApi, TLanguages, TRoutes } from '../../../../shared/const'
import { schemaOptions } from '../../../../shared/i18n'

import styles from '../../common.module.scss'
import PolicyLinks from '../../../../UI/policyLinks'

const schema = Joi.object({
	companyName: Joi.string().min(2).max(26).required(),
	companyState: Joi.string().required(),
	companyEmail: Joi.string().email({ tlds: false }).required(),
	companyPhone: joiPhone,
	companyBranch: Joi.string().required(),
	description: Joi.string().min(50).max(2000).required(),
	offerTypes: Joi.string().required(),
	businessIdea: Joi.string().min(100).max(2000).required(),
	businessActivityDescription: Joi.string().min(100).max(2000).required()
})

function CompanyDetails(props) {
	const { t, i18n } = useTranslation()
	const history = useHistory()

	const {
		register,
		control,
		trigger,
		handleSubmit,
		formState: { errors }
	} = useForm({
		mode: 'onChange',
		resolver: joiResolver(schema, {
			errors: {
				labels: false,
				language: localStorage.getItem('i18nextLng')
			},
			...schemaOptions
		})
	})

	const [isLoading, setLoading] = useState(false)

	useEffect(() => {
		if (Object.keys(errors).length) trigger()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localStorage.getItem('i18nextLng')])

	const onSubmit = fields => {
		setLoading(true)
		http.post(TApi.STUDENT_COMMON_CREATE_COMPANY, {
			...fields,
			companyEmail: fields.companyEmail.toLowerCase(),
			companyType: props.companyType
		})
			.then(() => {
				http.get(TApi.COMMON_ME, {
					disableToast: true
				}).then(({ data }) => {
					props.logIn({
						role: data.role,
						profile: data
					})
					history.push(TRoutes.DASHBOARD)
					toast.success(t('Company was created successful.'))
				})
			})
			.finally(() => setLoading(false))
	}

	return (
		<div className={styles.root}>
			<div className={styles.header}>
				<div className={styles.container}>
					<h2 className={styles.miniTitle}>{t('SignUp.noun')}</h2>
					<h3 className={styles.step}>{t('companyProfile')}</h3>
					<span className={styles.subtitle}>{t('Provide us company data')}</span>
				</div>
				<PolicyLinks />
			</div>

			<form className={clsx(styles.container, styles.form)} autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
				<div className={styles.section}>
					<div className={styles.leftForm}>
						<TextField
							required
							mb={20}
							label={t('Company name')}
							placeholder={t('Company name')}
							error={errors.companyName}
							register={register('companyName')}
							disabled={isLoading}
						/>
					</div>
					<p className={styles.sectionDescription}>
						{t('Note')}:{' '}
						{t(
							'The company name as well as the company type, if any, can only be changed via the country administrator.'
						)}
					</p>
				</div>

				<div className={styles.leftForm}>
					<SelectField
						required
						mb={20}
						label={t('Company state')}
						control={control}
						name="companyState"
						error={errors.companyState}
						placeholder={t('Company state')}
						disabled={isLoading}
						options={StateOptions.map(option => ({
							label: t(option.label),
							value: option.value
						})).sort((a, b) => a.label.localeCompare(b.label))}
					/>
				</div>

				<div className={styles.line} />

				<div className={styles.section}>
					<div className={styles.leftForm}>
						<TextField
							required
							mb={20}
							label={t('Company email')}
							placeholder="company@email.com"
							error={errors.companyEmail}
							register={register('companyEmail')}
							disabled={isLoading}
						/>
					</div>
					<p className={styles.sectionDescription}>{t('CompanyEmailDescription')}</p>
				</div>

				<div className={styles.section}>
					<div className={styles.leftForm}>
						<TextField
							required
							mb={20}
							label={`${t('Company')} ${t('phone')}`}
							placeholder="+43 6XX XXXXXX"
							error={errors.companyPhone}
							register={register('companyPhone')}
							disabled={isLoading}
						/>
					</div>
					<p className={styles.sectionDescription}>{t('phoneDesc')}</p>
				</div>

				<div className={styles.line} />

				<div className={styles.leftForm}>
					<SelectField
						required
						mb={20}
						label={t('branch')}
						control={control}
						name="companyBranch"
						error={errors.companyBranch}
						placeholder={t('branch')}
						options={props.branch?.map(v => ({
							value: v.value_EN,
							label: i18n.language === TLanguages.EN ? v.value_EN : v.value_DE
						}))}
						disabled={isLoading}
					/>
				</div>

				<div className={styles.leftForm}>
					<TextAreaField
						required
						mb={20}
						height={128}
						label={t('description')}
						placeholder={t('Type Short Company Description')}
						error={errors.description}
						register={register('description')}
						disabled={isLoading}
					/>
				</div>

				<div className={styles.leftForm}>
					<SelectField
						required
						mb={20}
						label={t('typeOffers')}
						control={control}
						name="offerTypes"
						error={errors.offerTypes}
						placeholder={t('typeOffers')}
						options={props.typeOfOffers?.map(v => ({
							value: v.value_EN,
							label: i18n.language === TLanguages.EN ? v.value_EN : v.value_DE
						}))}
						disabled={isLoading}
					/>
				</div>

				<div style={{ width: '100%' }}>
					<TextAreaField
						required
						mb={40}
						height={189}
						label={t('Your Business Idea')}
						placeholder={t('We are the...')}
						error={errors.businessIdea}
						maxLength={2000}
						showLettersLeft
						register={register('businessIdea')}
						disabled={isLoading}
					/>
				</div>

				<div style={{ width: '100%' }}>
					<TextAreaField
						required
						mb={40}
						height={189}
						label={t('Detailed description of business activity')}
						placeholder={t('Our...')}
						showError
						error={errors.businessActivityDescription}
						register={register('businessActivityDescription')}
						disabled={isLoading}
					/>
				</div>

				<Btn
					type="submit"
					width={214}
					// disabled={!isValid}
					className={styles.btnSignUp}
					iconSize={{ w: 10, h: 10 }}
					iconColor="#fff"
					icon="arrow"
					loading={isLoading}
				>
					{t('save')}
				</Btn>
			</form>
		</div>
	)
}

CompanyDetails.propTypes = {
	companyType: PropTypes.string.isRequired,
	branch: PropTypes.array.isRequired,
	typeOfOffers: PropTypes.array.isRequired,

	updateProfile: PropTypes.func.isRequired,
	setSignInMode: PropTypes.func.isRequired,
	setAuth: PropTypes.func.isRequired,
	logIn: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
	branch: state.options.branch,
	typeOfOffers: state.options.typeOfOffers
})

const mapDispatchToProps = dispatch => ({
	updateProfile: data => dispatch(updateProfile(data)),
	setSignInMode: value => dispatch(setSignInMode(value)),
	setAuth: value => dispatch(setAuth(value)),
	logIn: data => dispatch(logIn(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetails)
