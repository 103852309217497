import styles from './style.module.scss'

import { useEffect, useState } from 'react'
import { TApi, TWidgetDomains } from '../../../shared/const'
import http from '../../../shared/http'
import { customNumberFormatW } from '../../../shared/helpers'
import { useTranslation } from 'react-i18next'

export default function FourSectionsDataWidget(props) {
	const { t } = useTranslation()
	const [widgetDynamicData, setWidgetDynamicData] = useState({})

	const MAP_DOMAIN_TO_DATA = {
		[TWidgetDomains.COMPANIES]: {
			api: TApi.ADMIN_WIDGET_GET_COMPANIES,
			header: t('companies'),
			leftSideTextUpper: t('Total'),
			leftSideText: t('Pending'),
			rightSideTextUpper: t('Registered'),
			rightSideText: t('Active'),
			activeColor: '#3BB055',
			pendingColor: '#EF8400',
			totalColor: '#303E65',
			registeredColor: '#303E65'
		},
		[TWidgetDomains.STUDENTS]: {
			api: TApi.ADMIN_WIDGET_GET_STUDENTS,
			header: t('students'),
			leftSideTextUpper: t('Total'),
			leftSideText: t('Pending'),
			rightSideTextUpper: t('Registered'),
			rightSideText: t('Active'),
			activeColor: '#3BB055',
			pendingColor: '#EF8400',
			totalColor: '#303E65',
			registeredColor: '#303E65'
		},
		[TWidgetDomains.REPORTS]: {
			api: TApi.ADMIN_WIDGET_GET_REPORTS,
			header: t('reports'),
			leftSideTextUpper: t('Total'),
			leftSideText: t('Pending'),
			rightSideTextUpper: t('Registered'),
			rightSideText: t('Active'),
			activeColor: '#3BB055',
			pendingColor: '#EF8400',
			totalColor: '#303E65',
			registeredColor: '#303E65'
		},
		[TWidgetDomains.INVOICES]: {
			api: TApi.ADMIN_WIDGET_GET_INVOICES,
			header: t('invoices'),
			leftSideTextUpper: t('Total'),
			leftSideText: t('Unpaid'),
			rightSideTextUpper: t('Paid'),
			rightSideText: t('Overdue'),
			activeColor: '#EB5757',
			pendingColor: '#EF8400',
			totalColor: '#303E65',
			registeredColor: '#3BB055'
		},
		[TWidgetDomains.TEACHERS]: {
			api: TApi.ADMIN_WIDGET_GET_TEACHERS,
			header: t('teachers'),
			leftSideTextUpper: t('Total'),
			leftSideText: t('Pending'),
			rightSideTextUpper: t('Registered'),
			rightSideText: t('Active'),
			activeColor: '#3BB055',
			pendingColor: '#EF8400',
			totalColor: '#303E65',
			registeredColor: '#303E65'
		},
		[TWidgetDomains.REPORT_DATA]: {
			api: TApi.ADMIN_WIDGET_GET_REPORTS_DATA,
			header: t('reports'),
			leftSideTextUpper: t('Total fin. result'),
			leftSideText: t('Expanses'),
			rightSideTextUpper: t('Revenues'),
			rightSideText: t('Charges'),
			activeColor: '#303E65',
			pendingColor: '#303E65',
			totalColor: '#303E65',
			registeredColor: '#303E65'
		}
	}

	const stateFilter = props.settings?.stateFilter
	const widgetStaticData = MAP_DOMAIN_TO_DATA[props.domain]

	const fetchWidgetData = () => {
		http.get(widgetStaticData.api, {
			params: {
				state: stateFilter
			}
		}).then(({ data }) => {
			setWidgetDynamicData({ ...data.data })
		})
	}

	useEffect(() => {
		fetchWidgetData()
		/* eslint-disable-next-line */
	}, [stateFilter])

	return (
		<div className={styles.root}>
			<div style={{ marginBottom: '25px' }} className={styles.main}>
				<h1 className={styles.header}>{widgetStaticData.header}</h1>
			</div>
			<div className={styles.firstRow}>
				<div className={styles.firstCol}>
					<h2 style={{ color: widgetStaticData.totalColor }} className={styles.mainNumber}>
						{customNumberFormatW(widgetDynamicData.total)}
					</h2>
					<h4 className={styles.text}>{widgetStaticData.leftSideTextUpper}</h4>
				</div>
				<div className={styles.secondCol}>
					<h2 style={{ color: widgetStaticData.registeredColor }} className={styles.mainNumber}>
						{customNumberFormatW(widgetDynamicData.registered)}
					</h2>
					<h4 className={styles.text}>{widgetStaticData.rightSideTextUpper}</h4>
				</div>
			</div>
			<div className={styles.secondRow}>
				<div className={styles.firstCol}>
					<h2 style={{ color: widgetStaticData.pendingColor }} className={styles.mainNumber}>
						{customNumberFormatW(widgetDynamicData.pending)}
					</h2>
					<h4 className={styles.text}>{widgetStaticData.leftSideText}</h4>
				</div>
				<div className={styles.secondCol}>
					<h2 style={{ color: widgetStaticData.activeColor }} className={styles.mainNumber}>
						{customNumberFormatW(widgetDynamicData.active)}
					</h2>
					<h4 className={styles.text}>{widgetStaticData.rightSideText}</h4>
				</div>
			</div>
		</div>
	)
}
