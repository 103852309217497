import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

import injectRole from '../../../components/HOC/injectRole'

import { TRoles, TRoutes } from '../../../shared/const'
import Admins from '../../../components/views/admin/Admins'

function AdminsPage(props) {
	if ([TRoles.FEDERAL_ADMIN].includes(props.role)) return <Admins />
	else return <Redirect to={TRoutes.DASHBOARD} />
}

AdminsPage.propTypes = {
	role: PropTypes.string.isRequired
}

export default injectRole(AdminsPage)
