import { useMemo, useState, useEffect } from 'react'
import styles from './style.module.scss'
import { TApi } from '../../../../shared/const'
import http from '../../../../shared/http'
import { downloadFile, getFormattedDate } from '../../../../shared/helpers'
import MiniHeader from '../../../../UI/MiniHeader'
import Table from '../../../../UI/Table'
import Btn from '../../../../UI/Btn'
import Pagination from '../../../../UI/Pagination'
import invoiceIcon from '../../../../assets/icons/invoices.svg'
import reportIcon from '../../../../assets/icons/reportIcon.svg'
import businessPlanIcon from '../../../../assets/icons/businessPlan.svg'
import insuranceIcon from '../../../../assets/icons/insuranceIcon.svg'
import paperParentalIcon from '../../../../assets/icons/paperParental.svg'
import { useTranslation } from 'react-i18next'

const documentTypesIcon = {
	INVOICE: invoiceIcon,
	REPORT: reportIcon,
	BUSSINES_PLAN: businessPlanIcon,
	INSURANCE: insuranceIcon,
	PARENTAL_CONFIRMATION: paperParentalIcon,
	CONSENT_DOCUMENT: businessPlanIcon,
	PARTICIPATION_DOCUMENT: businessPlanIcon
}

export default function DocumentsList() {
	const { t } = useTranslation()
	const [documents, setDocuments] = useState([])
	const [countAll, setCountAll] = useState(0)
	const [page, setPage] = useState(0)
	const [sort, setSort] = useState({ name: 'name', direction: 'asc' })
	const limit = 10

	const fetchDocuments = offset => {
		http.get(TApi.ADMIN_GET_DOCUMENTS, {
			params: {
				limit,
				offset,
				order: sort.name,
				direction: sort.direction
			}
		}).then(({ data }) => {
			setDocuments(data.rows)
			setCountAll(data.count)
		})
	}
	const handleSort = (name, direction) => {
		setSort({ name, direction })
	}
	const downoload = name => {
		http.get(TApi.ADMIN_GET_FILES_DOCUMENT(name), {
			headers: {
				'Content-Type': 'application/pdf'
			},
			responseType: 'blob'
		}).then(({ data }) => {
			downloadFile(name, data)
		})
	}

	useEffect(() => {
		fetchDocuments(page * limit)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, sort])

	const columns = useMemo(
		() => [
			{
				Header: () => {
					return <span className={styles.tableTitleDocumentName}>{t('DOCUMENT NAME')}</span>
				},
				accessor: 'name',
				Cell: ({ row }) => {
					return (
						<div className={styles.tableNameContainer}>
							<img className={styles.documentIcon} src={documentTypesIcon[row.original.type]} alt="" />
							<span className={styles.documentName}>{row.original.originalName}</span>
						</div>
					)
				}
			},
			{
				Header: 'TYPE',
				accessor: 'type',
				Cell: ({ row }) => {
					return <p className={styles.type}>{row.original.type}</p>
				}
			},

			{
				Header: t('EMPLOYEE'),
				accessor: 'employee',
				Cell: ({ row }) => {
					return (
						<p className={styles.employee}>
							{row.original.Creator.firstName} {row.original.Creator.lastName}
						</p>
					)
				}
			},
			{
				Header: t('DATE'),
				accessor: 'createdAt',
				Cell: ({ row }) => {
					return (
						<div className={styles.dateContainer}>
							<p className={styles.date}>{getFormattedDate(row.original.createdAt)}</p>
							<Btn
								theme="download"
								icon="download"
								iconSize={{ w: 24, h: 24 }}
								onClick={() => downoload(row.original.name)}
							/>
						</div>
					)
				}
			}
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[documents, t]
	)
	return (
		<div className={styles.root}>
			<MiniHeader title={t('Documents')} bg="#f8faff" />
			<div className={styles.subHeader}>
				<Pagination limit={limit} page={page} label={t('files')} countAll={countAll} onChange={setPage} />
				<div className={styles.downloadContent}>
					{t('Download list as')}
					<div>
						<Btn theme="download" iconSize={{ w: 24, h: 24 }} icon="download">
							XLS
						</Btn>
						<Btn theme="download" iconSize={{ w: 24, h: 24 }} icon="download">
							PDF
						</Btn>
					</div>
				</div>
			</div>
			<div className="container">
				<Table
					columns={columns}
					items={documents}
					limit={limit}
					countAll={countAll}
					onChange={setPage}
					handleSort={handleSort}
				/>
			</div>
		</div>
	)
}
