import PropTypes from 'prop-types'
import clsx from 'clsx'
import ReactTooltip from 'react-tooltip'
import { useTranslation } from 'react-i18next'

import Icon from '../Icon'

import styles from './style.module.scss'

export default function Btn(props) {
	const { t } = useTranslation()
	const themes = {
		download: styles.themeDownload,
		grey: styles.themeGrey,
		orange: styles.themeOrange,
		white: styles.themeWhite,
		minimal: styles.themeMinimal,
		whiteBackground: styles.whiteBackground,
		red: styles.themeRed
	}

	return (
		<>
			<button
				onClick={props.onClick}
				type={props.type}
				disabled={props.loading || props.disabled}
				style={props.width ? { width: props.width } : {}}
				data-tip
				data-for={props.tooltip}
				className={clsx(
					styles.root,
					props.className,
					props.loading && styles.rootLoading,
					props.theme && themes[props.theme]
				)}
			>
				{props.icon && !props.loading && (
					<Icon
						style={{ order: props.iconOrder }}
						className={clsx(styles.icon, props.iconClassName)}
						size={props.iconSize}
						name={props.icon}
						noFill={props.noFillIcon}
						color={props.iconColor}
					/>
				)}
				{props.loading && (
					<Icon
						style={{ order: props.iconOrder }}
						className={clsx(styles.icon, props.iconClassName)}
						size={props.iconSize || { w: 16, h: 16 }}
						name="loading"
						color={props.iconColor || '#fff'}
					/>
				)}{' '}
				{props.children}
			</button>
			{props.tooltip && (
				<ReactTooltip id={props.tooltip} place="top">
					<span>{t(props.tooltip)}</span>
				</ReactTooltip>
			)}
		</>
	)
}

Btn.propTypes = {
	className: PropTypes.string,
	tooltip: PropTypes.string,
	width: PropTypes.number,
	type: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	icon: PropTypes.string,
	iconClassName: PropTypes.string,
	iconColor: PropTypes.string,
	iconSize: PropTypes.shape({
		w: PropTypes.number.isRequired,
		h: PropTypes.number.isRequired
	}),
	iconOrder: PropTypes.number,
	loading: PropTypes.bool,
	disabled: PropTypes.bool,

	theme: PropTypes.string,
	onClick: PropTypes.func,
	noFillIcon: PropTypes.bool
}

Btn.defaultProps = {
	tooltip: '',
	type: 'button',
	className: '',
	loading: false,
	disabled: false,
	iconOrder: 1,
	noFillIcon: false,
	children: [],
	onClick: () => {} // TODO REMOVE
}
