import clsx from 'clsx'
import PropTypes from 'prop-types'

import styles from './style.module.scss'

export default function CheckBoxFilter(props) {
	return (
		<label className={styles.root}>
			<input
				checked={props.checked}
				onChange={evt => props.onChange(evt.target.checked)}
				className={styles.input}
				type="checkbox"
				value={props.value}
			/>
			<div className={styles.content}>
				<span className={clsx(styles.checkmark, props.className)} />
				<span className={styles.label}>{props.label}</span>
			</div>
		</label>
	)
}

CheckBoxFilter.propTypes = {
	label: PropTypes.string,
	className: PropTypes.string,
	checked: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired
}
