import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

import injectRole from '../../../components/HOC/injectRole'

import { TRoles, TRoutes } from '../../../shared/const'
import Schools from '../../../components/views/admin/Schools'

function SchoolsPage(props) {
	if ([TRoles.ADMIN, TRoles.FEDERAL_ADMIN].includes(props.role)) return <Schools />
	else return <Redirect to={TRoutes.DASHBOARD} />
}

SchoolsPage.propTypes = {
	role: PropTypes.string.isRequired
}

export default injectRole(SchoolsPage)
