import { useEffect, useState } from 'react'
import BaseModal from '../../../../UI/BaseModal/BaseModal'
import { types } from '../../../../shared/eventEmitter'
import styles from '../style.module.scss'
import Joi from 'joi'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import http from '../../../../shared/http'
import { TApi, TRoles } from '../../../../shared/const'
import Btn from '../../../../UI/Btn'
import TextField from '../../../../UI/TextField'
import BtnTooltip from '../../../../UI/BtnTooltip'
import { schemaOptions } from '../../../../shared/i18n'
import Avatar from 'react-avatar'
import { toast } from 'react-toastify'
import SelectField from '../../../../UI/SelectField'
import Chip from '../../../../UI/Chip'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

const schema = Joi.object({
	email: Joi.string().required()
})

const selectSchema = Joi.object({
	teacher: Joi.object().required()
})

function AddTeacherModal(props) {
	const { t } = useTranslation()

	const {
		handleSubmit,
		register,
		trigger,
		watch,
		setValue,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema, {
			errors: {
				labels: false,
				language: localStorage.getItem('i18nextLng')
			},
			...schemaOptions
		})
	})

	const {
		handleSubmit: submit,
		register: reg,
		control: contr,
		setValue: setV,
		formState: { errors: selectErrors }
	} = useForm({
		resolver: joiResolver(selectSchema)
	})

	const [isLoading, setLoading] = useState(false)
	const [isLoadingSec, setLoadingSec] = useState(false)
	const [uniqKeyTeacher, setUniqKeyTeacher] = useState(0)
	const [invitationUser, setInvitationUser] = useState([])
	const [companyTeacher, setCompanyTeachers] = useState([])
	const [teacherRequests, setTeacherRequests] = useState([])
	const [supervision, setSupervision] = useState({})
	const [schoolId, setSchoolId] = useState(null)
	const [companyId, setCompanyId] = useState(null)
	const [emailField] = watch(['email'])

	useEffect(() => {
		if (Object.keys(errors).length) trigger()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localStorage.getItem('i18nextLng')])

	const loadTeacherOptions = async (search, loadedOptions) => {
		const companyTeacherIds = companyTeacher.map(({ teacherId }) => teacherId)
		const {
			data: { rows, count }
		} = await http.get(TApi.STUDENT_GET_TEACHERS, {
			params: {
				search,
				limit: 30,
				except: JSON.stringify({
					ids: [supervision.teacherId, ...companyTeacherIds]
				}),
				offset: loadedOptions.length,
				schoolId: schoolId,
				state: props.state
			}
		})

		return {
			options: rows.map(row => ({ ...row, value: row.id, label: row.name })),
			hasMore: loadedOptions.length < count
		}
	}

	const onSubmitInviteTeacher = form => {
		setLoadingSec(true)

		const isAdmin = [(TRoles.ADMIN, TRoles.FEDERAL_ADMIN)].includes(props.role)

		isAdmin
			? inviteTeacher(TApi.ADMIN_INVITE_TEACHER, { email: form.email, companyId })
			: inviteTeacher(TApi.STUDENT_POST_INVITE_TEACHER, { email: form.email })
	}

	const onSubmitTeacher = form => {
		setLoading(true)

		const isAdmin = [(TRoles.ADMIN, TRoles.FEDERAL_ADMIN)].includes(props.role)

		isAdmin
			? addTeacher(TApi.ADMIN_ADD_TEACHER, { teacherId: form.teacher.id, companyId })
			: addTeacher(TApi.STUDENT_ADD_TEACHER, { teacherId: form.teacher.id })
	}

	const handleDeleteInviteUser = id => {
		http.delete(TApi.STUDENT_DELETE_INVITE_TEACHER(id)).then(() => {
			setInvitationUser(invitationUser.filter(user => user.id !== id))
			toast.success(t('Teacher deleted successfully'))
		})
	}

	const handleOpen = id => {
		setLoading(true)

		if (id) setCompanyId(id)

		const url = [TRoles.ADMIN, TRoles.FEDERAL_ADMIN].includes(props.role)
			? TApi.ADMIN_GET_COMPANY_TEACHERS(id)
			: TApi.STUDENT_GET_COMPANY_TEACHERS

		http.get(url)
			.then(({ data }) => {
				setTeacherRequests(data.teacherRequests)
				setInvitationUser(data.invitationUser)
				setCompanyTeachers(data.companyTeachers)
				setSupervision(data.supervision)
				setSchoolId(data.schoolId)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const handleDeleteCompanyTeacher = id => {
		setLoading(true)

		const url = [TRoles.ADMIN, TRoles.FEDERAL_ADMIN].includes(props.role)
			? TApi.ADMIN_DELETE_COMPANY_TEACHER(companyId, id)
			: TApi.STUDENT_DELETE_COMPANY_TEACHER(id)

		http.delete(url)
			.then(() => {
				setCompanyTeachers(companyTeacher.filter(user => user.teacherId !== id))
				toast.success(t('Teacher deleted from your company successfully'))
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const cancelRequest = id => {
		http.delete(TApi.STUDENT_ASSIGN_REQUEST_ID(id)).then(() => {
			handleOpen()
			toast.success('Request rejected')
		})
	}

	const acceptRequest = form => {
		setLoading(true)
		http.post(TApi.STUDENT_ACCEPT_REQUEST, form)
			.then(() => {
				toast.success(t('Teacher added to company'))
				handleOpen()
			})
			.finally(() => {
				setLoading(false)
			})
	}

	useEffect(() => {
		setUniqKeyTeacher(uniqKeyTeacher + 1)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const inviteTeacher = (url, params) => {
		http.post(url, params)
			.then(({ data }) => {
				setInvitationUser([...invitationUser, data])
				setValue('email', '')
				toast.success(t('Invitation was sent successfully'))
			})
			.finally(() => {
				setLoadingSec(false)
			})
	}

	const addTeacher = (url, params) => {
		http.post(url, params)
			.then(({ data }) => {
				setCompanyTeachers([...companyTeacher, data])
				setV('teacher', null)
				toast.success(t('Teacher added successfully'))
			})
			.finally(() => {
				setLoading(false)
			})
	}

	return (
		<BaseModal
			onOpen={handleOpen}
			nameEvent={types.openAddTeacherModal}
			title={t('Invite Teacher')}
			isLoading={isLoading}
			maxWidth={522}
		>
			{() => (
				<div>
					<div className={styles.profiles}>
						<div className={styles.profilesItem}>
							<div className={styles.profileBlock}>
								<Avatar
									className={styles.userAvatar}
									round
									size={32}
									name={`${supervision.Teacher?.firstName} ${supervision.Teacher?.lastName}`}
									src={
										supervision.Teacher?.avatarHashName &&
										TApi.PUBLIC_PROFILE_AVATAR(supervision.Teacher?.avatarHashName)
									}
								/>

								<div className={styles.profilesName}>
									{supervision.Teacher?.firstName} {supervision.Teacher?.lastName}{' '}
								</div>
							</div>
							<div className={styles.profilesStatus}>{t('Supervision Teacher')}</div>
						</div>

						{companyTeacher?.map(item => {
							return (
								<div className={styles.profilesItem} key={item.teacherId}>
									<div className={styles.profileBlock}>
										<Avatar
											className={styles.userAvatar}
											round
											size={32}
											src={
												item.Teacher?.avatarHashName &&
												TApi.PUBLIC_PROFILE_AVATAR(item.Teacher?.avatarHashName)
											}
										/>
										<div className={styles.profilesName}>
											{item.Teacher?.firstName} {item.Teacher?.lastName}
										</div>
									</div>
									<div className={styles.profilesStatus}>{t('Teacher')}</div>
									<div>
										<BtnTooltip
											items={[
												{
													label: t('Remove'),
													className: styles.btnRemove,
													onClick: () => handleDeleteCompanyTeacher(item.teacherId)
												}
											]}
										/>
									</div>
								</div>
							)
						})}
						{teacherRequests?.map(item => {
							return (
								<div className={styles.profilesItem} key={item.teacherId}>
									<div className={styles.profileBlock}>
										<Avatar
											className={styles.userAvatar}
											round
											size={32}
											src={
												item.Teacher?.avatarHashName &&
												TApi.PUBLIC_PROFILE_AVATAR(item.Teacher?.avatarHashName)
											}
										/>
										<div className={styles.profilesName}>
											{item.Teacher?.firstName} {item.Teacher?.lastName}
										</div>
									</div>
									<div className={styles.profilesStatus}>{t('Request')}</div>
									<div>
										<BtnTooltip
											items={[
												{
													label: t('Accept'),
													onClick: () =>
														acceptRequest({
															teacherId: item.teacherId,
															id: item.id
														})
												},
												{
													label: t('Cancel'),
													onClick: () => cancelRequest(item.id)
												}
											]}
										/>
									</div>
								</div>
							)
						})}
						{invitationUser?.map(item => {
							return (
								<div className={styles.profilesItem} key={item.id}>
									<div className={styles.profileBlock}>
										<Avatar round size={32} className={styles.userAvatar} />
										<div className={styles.profilesName}>
											{item.email} <span style={{ color: '#22404D' }}>(Invitation)</span>
										</div>
									</div>
									<div className={styles.profilesStatus}>{t('Pending')}</div>
									<div>
										<BtnTooltip
											items={[
												{
													label: t('Remove'),
													className: styles.btnRemove,
													onClick: () => handleDeleteInviteUser(item.id)
												}
											]}
										/>
									</div>
								</div>
							)
						})}
					</div>

					{
						<form className={styles.container} onSubmit={submit(onSubmitTeacher)}>
							<div className={styles.searchSection}>
								<SelectField
									async
									disabled={isLoading || emailField}
									label={t('Select teacher')}
									isSearchable
									register={reg('teacher')}
									control={contr}
									name="teacher"
									className={styles.selectField}
									placeholder={t('Select teacher')}
									debounceTimeout={400}
									error={selectErrors.teacher}
									loadOptions={loadTeacherOptions}
									cacheUniqs={[uniqKeyTeacher]}
									formatOptionLabel={({ label, ...row }) => (
										<div className={styles.selectFieldOption}>
											{row.verifiedTeacher && <Chip miniRadius>{t('Verified')}</Chip>}
											<span style={{ marginLeft: 10 }}>
												{row.titlePrefixed} {row.firstName} {row.lastName} {row.titleSuffixed}
											</span>
										</div>
									)}
								/>
								<div className={styles.btnWrapper}>
									<Btn
										disabled={isLoading || emailField}
										loading={isLoading}
										className={styles.inviteSectionBtn}
										type="submit"
										width={125}
									>
										{t('Assign')}
									</Btn>
								</div>
							</div>
						</form>
					}
					<form className={styles.container} onSubmit={handleSubmit(onSubmitInviteTeacher)}>
						<p className={styles.sectionText}>{t('Or invite new teacher by Email')}</p>
						<div className={styles.inviteSection}>
							<TextField
								label=""
								disabled={isLoadingSec}
								register={register('email')}
								error={errors.email}
								placeholder={t('Teacher Email')}
							/>
							<div className={styles.btnWrapper}>
								<Btn
									disabled={isLoadingSec}
									loading={isLoadingSec}
									className={styles.inviteSectionBtn}
									type="submit"
									width={115}
								>
									{t('Send')}
								</Btn>
							</div>
						</div>
					</form>
				</div>
			)}
		</BaseModal>
	)
}

const mapStateToProps = state => ({
	state: state.user?.profile?.EmploymentCompany?.state,
	role: state.user?.role
})

export default connect(mapStateToProps)(AddTeacherModal)
