import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

import Icon from '../Icon'

import styles from './style.module.scss'

export default function FlatLink(props) {
	return (
		<Link
			className={clsx(styles.root, props.className, props.disabled && styles.rootDisabled)}
			onClick={props.onClick}
			to={props.disabled ? '#' : props.to}
			style={{ color: props.color }}
		>
			{props.children}{' '}
			{props.icon && (
				<Icon
					className={clsx(styles.icon, props.classNameIcon)}
					size={props.iconSize}
					name={props.icon}
					color={props.iconColor}
				/>
			)}
		</Link>
	)
}

FlatLink.propTypes = {
	className: PropTypes.string,
	to: PropTypes.string.isRequired,
	color: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	icon: PropTypes.string,
	iconColor: PropTypes.string,
	iconSize: PropTypes.shape({
		w: PropTypes.number.isRequired,
		h: PropTypes.number.isRequired
	}),
	disabled: PropTypes.bool,
	classNameIcon: PropTypes.string,
	onClick: PropTypes.func
}

FlatLink.defaultProps = {
	disabled: false,
	color: '#22404D',
	icon: false,
	iconColor: '#000',
	classNameIcon: ''
}
