import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import styles from './style.module.scss'
import http from '../../../shared/http'
import { useTranslation } from 'react-i18next'

import Icon from '../../Icon'

import { TWidgetDomains, TApi } from '../../../shared/const'
import { customNumberFormat } from '../../../shared/helpers'
import { NavLink } from 'react-router-dom'

export default function LinkWidget(props) {
	const { t } = useTranslation()
	const [widgetDynamicData, setWidgetDynamicData] = useState({})

	const MAP_DOMAIN_TO_DATA = {
		[TWidgetDomains.COMPANIES_PENDING]: {
			api: TApi.ADMIN_WIDGET_GET_COMPANIES_PENDING,
			sign: '%',
			header: t('Companies Pending, %'),
			link: '/admin-companies',
			linkText: t('Resolve')
		},
		[TWidgetDomains.OVERDUE_INVOICES]: {
			api: TApi.ADMIN_WIDGET_GET_INVOICES_OVERDUE,
			sign: '€',
			header: t('Overdue Invoices'),
			link: '/invoices',
			linkText: t('Show Invoices')
		},
		[TWidgetDomains.TURNOVER]: {
			api: TApi.ADMIN_WIDGET_GET_TURNOVERS,
			sign: '€',
			header: t('Total fin. result'),
			link: '/reports-table',
			linkText: t('Show Reports')
		}
	}

	const widgetStaticData = MAP_DOMAIN_TO_DATA[props.domain]

	const fetchWidgetData = async () => {
		const {
			data: { data }
		} = await http.get(widgetStaticData.api)
		setWidgetDynamicData({ data })
	}

	useEffect(() => {
		fetchWidgetData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className={styles.root}>
			<div className={styles.main}>
				<h1 className={styles.header}>{widgetStaticData.header}</h1>
				<h2 style={widgetStaticData.sign === '€' ? { fontSize: '2em' } : {}} className={styles.mainNumber}>
					{widgetStaticData.sign} {customNumberFormat(widgetDynamicData.data)}
				</h2>
			</div>
			<div className={styles.footerContainer}>
				<NavLink className={styles.link} to={widgetStaticData.link}>
					<span className={styles.linkText}>{widgetStaticData.linkText}</span>
					<Icon name={'arrow'} color={'#ffffff'} size={{ w: 10, h: 10 }} />
				</NavLink>
			</div>
		</div>
	)
}

LinkWidget.propTypes = {
	id: PropTypes.string.isRequired,
	domain: PropTypes.oneOf([TWidgetDomains.OVERDUE_INVOICES, TWidgetDomains.COMPANIES_PENDING])
}
