import Joi from 'joi'
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Avatar from 'react-avatar'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { useTranslation } from 'react-i18next'

import http from '../../../../shared/http'
import { TApi } from '../../../../shared/const'
import { schemaOptions } from '../../../../shared/i18n'
import { types } from '../../../../shared/eventEmitter'

import Btn from '../../../../UI/Btn'
import TextField from '../../../../UI/TextField'
import BtnTooltip from '../../../../UI/BtnTooltip'
import BaseModal from '../../../../UI/BaseModal/BaseModal'

import styles from '../style.module.scss'
import { updateProfile } from '../../../../redux/ducks/user'

const schema = Joi.object({
	email: Joi.string().email({ tlds: false }).required()
})

function AddEmployeeModal(props) {
	const { t } = useTranslation()
	const {
		handleSubmit,
		register,
		trigger,
		watch,
		reset,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema, {
			errors: {
				labels: false,
				language: localStorage.getItem('i18nextLng')
			},
			...schemaOptions
		})
	})

	const [employees, setEmployees] = useState([])
	const [invitationUser, setInvitationUser] = useState([])
	const [isLoading, setLoading] = useState(false)
	const [error, setError] = useState('')
	const [onRefresh, setOnRefresh] = useState(null)

	useEffect(() => {
		if (Object.keys(errors).length) trigger()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localStorage.getItem('i18nextLng')])

	useEffect(() => {
		setError('')
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watch('email')])

	const onSubmit = form => {
		setLoading(true)

		http.post(TApi.STUDENT_POST_INVITE_EMPLOYEE, {
			...form,
			email: form.email.toLowerCase()
		})
			.then(({ data }) => {
				setError('')
				reset()

				if (data.status === 'REGISTERED') {
					if (onRefresh) onRefresh()
					return setEmployees([...employees, data])
				}
				setInvitationUser([...invitationUser, data])

				toast.success(t('Invitation was sent successfully'))
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const handleOpen = onRefresh => {
		setOnRefresh(() => onRefresh)
		http.get(TApi.STUDENT_GET_EMPLOYEES).then(({ data }) => {
			setEmployees(data.employees)
			setInvitationUser(data.invitationUser)
		})
	}

	const handleDeleteInviteUser = id => {
		http.delete(TApi.STUDENT_DELETE_INVITE_EMPLOYEE(id)).then(() => {
			setError('')
			setInvitationUser(invitationUser.filter(user => user.id !== id))
		})
	}

	const makeCEO = userId => {
		setLoading(true)
		http.put(TApi.STUDENT_MAKE_CEO(userId))
			.then(() => {
				toast.success(t('CEO Updated'))
				props.updateProfile({ isCEO: false })
			})
			.finally(() => {
				setLoading(false)
				http.get(TApi.STUDENT_GET_EMPLOYEES).then(({ data }) => {
					setEmployees(data.employees)
					setInvitationUser(data.invitationUser)
				})
			})
	}

	const deleteEmployee = userId => {
		setLoading(true)
		http.delete(TApi.STUDENT_DELETE_EMPLOYEE(userId))
			.then(() => {
				toast.success(t('Employee successfully deleted'))
				//props.updateProfile({ isCEO: false })
			})
			.finally(() => {
				setLoading(false)
				http.get(TApi.STUDENT_GET_EMPLOYEES).then(({ data }) => {
					setEmployees(data.employees)
					setInvitationUser(data.invitationUser)
				})
			})
	}

	return (
		<BaseModal
			onOpen={handleOpen}
			nameEvent={types.openAddEmployeeModal}
			title={t('Add Employee')}
			isLoading={isLoading}
			maxWidth={522}
		>
			{() => (
				<div className={styles.minContainer}>
					<div className={styles.profiles}>
						{employees.map(item => {
							return (
								<div className={styles.profilesItem} key={item.id}>
									<div className={styles.profileBlock}>
										<Avatar
											className={styles.userAvatar}
											round
											size={32}
											src={item.avatarHashName && TApi.PUBLIC_PROFILE_AVATAR(item.avatarHashName)}
										/>
										<div className={styles.profilesName}>
											{item.firstName ? `${item.firstName} ${item.lastName}` : item.email}
											{props.userId === item.id && (
												<span style={{ color: '#22404D' }}>({t('You')})</span>
											)}
										</div>
									</div>

									<div className={styles.profilesButton}>
										<BtnTooltip
											items={[
												{
													label: t('Make CEO'),
													onClick: () => makeCEO(item.id),
													disabled: item.isCEO
												},
												{
													label: t('Delete employee'),
													className: styles.btnRemove,
													onClick: () => deleteEmployee(item.id)
												}
											]}
										/>
									</div>
								</div>
							)
						})}
						{invitationUser.map(item => {
							return (
								<div className={styles.profilesItem} key={item.id}>
									<div className={styles.profileBlock}>
										<Avatar round size={32} className={styles.userAvatar} />
										<div className={styles.profilesName}>
											{item.email} <span style={{ color: '#22404D' }}>(Invitation)</span>
										</div>
									</div>
									<div className={styles.profilesStatus}>{t('Pending')}</div>
									<div>
										<BtnTooltip
											items={[
												{
													label: t('Delete Invite'),
													className: styles.btnRemove,
													onClick: () => handleDeleteInviteUser(item.id)
												}
											]}
										/>
									</div>
								</div>
							)
						})}
					</div>

					<p className={styles.sectionText}>{t('Invite new employee by Email')}</p>
					<form onSubmit={handleSubmit(onSubmit)} className={styles.inviteSection}>
						<TextField
							disabled={isLoading}
							error={errors.email}
							register={register('email')}
							placeholder={t('Employee Email')}
						/>

						<Btn
							loading={isLoading}
							className={styles.inviteSectionBtn}
							type="submit"
							icon="send"
							iconSize={{ w: 12, h: 12 }}
							iconColor="#fff"
						/>
					</form>
					<span className={styles.errorMessage}>{error}</span>
				</div>
			)}
		</BaseModal>
	)
}

AddEmployeeModal.propTypes = {
	userId: PropTypes.number,
	isCEO: PropTypes.bool
}

const mapStateToProps = state => ({
	userId: state.user.profile.id,
	isCEO: state.user.profile.isCEO
})

const mapDispatchToProps = dispatch => ({
	updateProfile: data => dispatch(updateProfile(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddEmployeeModal)
