import clsx from 'clsx'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'
import { Controller } from 'react-hook-form'
import { connect } from 'react-redux'

import styles from './style.module.scss'

function TextFieldNumber(props) {
	return (
		<label style={props.mb ? { marginBottom: props.mb } : {}} className={clsx(styles.root, props.className)}>
			{props.label && (
				<span className={styles.inputName}>
					{props.label} {props.required ? <span className={styles.require}>*</span> : ''}
				</span>
			)}
			<Controller
				name={props.name}
				control={props.control}
				render={({ field: { onChange, ref, value, ...field } }) => (
					<NumberFormat
						{...field}
						value={props.value ? props.value : value}
						onValueChange={({ floatValue }) => onChange(floatValue)}
						thousandSeparator="."
						decimalSeparator=","
						decimalPrecision={2}
						decimalScale={props.noDecimal ? 0 : 2}
						fixedDecimalScale={2}
						disabled={props.disabled || props.isTeacherStudentView}
						className={clsx(styles.input, props.classNameInput)}
						onChange={props.onChange}
						displayType="input"
						type="text"
						getInputRef={ref}
						placeholder={props.placeholder}
					/>
				)}
			/>
			{props.error && <span className={styles.error}>{props.error.message}</span>}
		</label>
	)
}

TextFieldNumber.propTypes = {
	label: PropTypes.string,
	error: PropTypes.object,
	name: PropTypes.string.isRequired,
	control: PropTypes.object.isRequired,
	mb: PropTypes.number,
	className: PropTypes.string,
	placeholder: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
	classNameInput: PropTypes.string,
	noDecimal: PropTypes.bool,
	isTeacherStudentView: PropTypes.bool.isRequired
}

TextFieldNumber.defaultProps = {
	disabled: false
}

const mapStateToProps = state => ({
	isTeacherStudentView: state.user.isTeacherStudentView
})

export default connect(mapStateToProps)(TextFieldNumber)
