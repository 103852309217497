import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import Btn from '../../../../../../UI/Btn'
import styles from '../style.module.scss'
import Joi from 'joi'
import { joiResolver } from '@hookform/resolvers/joi'
import { useTranslation } from 'react-i18next'
import TextFieldNumber from '../../../../../../UI/TextFieldNumber'

const schema = Joi.object({
	shareAmounts: Joi.object().keys({
		JC: Joi.number().required(),
		JCC: Joi.number().required(),
		JBC: Joi.number().required()
	})
})

export default function ShareAmounts(props) {
	const { t } = useTranslation()
	const {
		control,
		reset,
		handleSubmit,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema)
	})

	const onSubmit = form => {
		props
			.updateOptions({ name: 'shareAmounts', value: form.shareAmounts })
			.then(() => toast.success(t('Options was updated')))
	}

	useEffect(() => {
		reset({ shareAmounts: props.form?.shareAmounts || {} })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.form])
	return (
		<form id="shareAmounts" className={styles.root} onSubmit={handleSubmit(onSubmit)}>
			<section className={styles.section}>
				<h3 className={styles.sectionTitle}>{t('Max. Share Amounts')}</h3>
				<div className={styles.sectionContent}>
					<TextFieldNumber
						label="Junior"
						className={styles.field}
						mb={20}
						error={errors.shareAmounts?.JC}
						placeholder="Input new share amount"
						control={control}
						name={`shareAmounts.JC`}
					/>
					<TextFieldNumber
						label="Junior Compact"
						className={styles.field}
						mb={20}
						error={errors.shareAmounts?.JCC}
						placeholder="Input new share amount"
						control={control}
						name={`shareAmounts.JCC`}
					/>
					<TextFieldNumber
						label="Junior Basic"
						className={styles.field}
						mb={20}
						error={errors.shareAmounts?.JBC}
						placeholder="Input new share amount"
						control={control}
						name={`shareAmounts.JBC`}
					/>
					<div className={styles.btnContainer}>
						<Btn
							className={styles.btn}
							type="submit"
							icon="arrow"
							iconSize={{ w: 10, h: 10 }}
							iconColor="#fff"
						>
							{t('save')}
						</Btn>
					</div>
				</div>
			</section>
		</form>
	)
}
ShareAmounts.propTypes = {
	form: PropTypes.object.isRequired,
	updateOptions: PropTypes.func.isRequired
}
