import Joi from 'joi'
import { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import http from '../../../../shared/http'
import { TApi, StateOptions } from '../../../../shared/const'
import { types } from '../../../../shared/eventEmitter'

import Btn from '../../../../UI/Btn'
import BaseModal from '../../../../UI/BaseModal/BaseModal'

import styles from '../style.module.scss'
import localStyles from './style.module.scss'
import SelectField from '../../../../UI/SelectField'
import { schemaOptions } from '../../../../shared/i18n'
import { updateWidgetSettings } from '../../../../redux/ducks/user'
import { joiResolver } from '@hookform/resolvers/joi'

const schema = Joi.object({
	state: Joi.string().allow(null)
})

function CustomizeSimpleWidgetModal(props) {
	const { t } = useTranslation()
	const ALL_STATES_OPTION = {
		value: null,
		label: t('All states')
	}

	const [widgetId, setWidgetId] = useState('')
	const [availableWidgets, setAvailableWidgets] = useState([])
	const [isLoading, setLoading] = useState(false)

	const { handleSubmit, control, setValue } = useForm({
		resolver: joiResolver(schema, {
			errors: {
				labels: false,
				language: localStorage.getItem('i18nextLng')
			},
			...schemaOptions
		})
	})

	const onSubmit = ({ form, closeModal }) => {
		setLoading(true)

		const newSettings = availableWidgets.map(widget => {
			if (widget.id === widgetId) {
				return {
					...widget,
					settings: {
						schoolFilter: form.school?.name,
						stateFilter: form.state
					}
				}
			}

			return widget
		})

		http.put(TApi.ADMIN_UPDATE_WIDGET_SETTINGS, { widgetSettings: newSettings })
			.then(({ data }) => {
				props.updateWidgetSettings({
					id: widgetId,
					settings: { stateFilter: form.state }
				})
				closeModal()

				toast.success(t('Widget settings updated successfully'))
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const handleOpen = (widgetType = '', currentOption, widgetId, availableWidgets) => {
		availableWidgets.map(widget => {
			if (widget.id === widgetId) {
				setValue('state', widget.settings.stateFilter)
			}
			return widget
		})

		setWidgetId(widgetId)
		setAvailableWidgets(availableWidgets)
	}

	const getStatesOptions = () => {
		const options = StateOptions.map(v => ({
			label: t(v.label),
			value: v.value
		})).sort((a, b) => a.label.localeCompare(b.label))
		return [ALL_STATES_OPTION, ...options]
	}

	return (
		<BaseModal
			onOpen={handleOpen}
			nameEvent={types.openCustomizeSimpleWidgetModal}
			title={t('Customize Widget')}
			isLoading={isLoading}
			maxWidth={522}
		>
			{({ closeModal }) => (
				<div className={styles.minContainer}>
					<form
						onSubmit={handleSubmit(form => onSubmit({ form, closeModal }))}
						className={styles.inviteSection}
					>
						<div className={localStyles.optionsContainer}>
							<SelectField
								mb={20}
								disabled={isLoading}
								label={t('state')}
								control={control}
								name="state"
								placeholder={t('Select')}
								options={getStatesOptions()}
							/>
							<Btn
								loading={isLoading}
								width={214}
								className={clsx(styles.btn, localStyles.btn)}
								type="submit"
								icon="arrow"
								iconSize={{ w: 10, h: 10 }}
								iconColor="#fff"
							>
								{t('save')}
							</Btn>
						</div>
					</form>
				</div>
			)}
		</BaseModal>
	)
}

CustomizeSimpleWidgetModal.propTypes = {
	userId: PropTypes.number
}

const mapStateToProps = state => ({
	userId: state.user.profile.id
})

const mapDispatchToProps = dispatch => ({
	updateWidgetSettings: data => dispatch(updateWidgetSettings(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomizeSimpleWidgetModal)
