import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import styles from './style.module.scss'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

function TextAreaField(props) {
	const { t } = useTranslation()
	const [count, setCount] = useState(0)
	const minCharacters = 100

	useEffect(() => {
		setCount(props.value ? props.value.length : 0)
	}, [props.value])

	return (
		<label style={props.mb ? { marginBottom: props.mb } : {}} className={clsx(styles.root, props.classNameLabel)}>
			<div className={styles.fieldHeader}>
				{props.label && (
					<span className={styles.inputName}>
						{props.label} {props.required ? <span className={styles.require}>*</span> : ''}
					</span>
				)}
				{props.showError && (
					<p className={styles.inputWarning}>
						<span
							className={clsx({
								[styles.default]: count > minCharacters,
								[styles.errorColor]: count <= minCharacters
							})}
						>
							Min 100 {t('Symbols')}
						</span>
					</p>
				)}
				{props.showLettersLeft && (
					<p className={styles.inputWarning}>
						<span color="#22404D">{props.maxLength - count}</span> {t('Symbols Left')}
					</p>
				)}
			</div>
			<textarea
				maxLength={props.maxLength}
				disabled={props.disabled || props.isTeacherStudentView}
				className={styles.input}
				style={{
					height: props.height ? `${props.height}px` : '',
					resize: count > 150 ? 'vertical' : 'none'
				}}
				{...props.register}
				onChange={e => {
					props.register.onChange(e)
					setCount(e.target.value.length)
				}}
				placeholder={props.placeholder}
				name={props.register.name}
			/>
			{props.error && <span className={styles.error}>{props.error.message}</span>}
		</label>
	)
}

TextAreaField.propTypes = {
	label: PropTypes.string.isRequired,
	error: PropTypes.object,
	register: PropTypes.object.isRequired,
	placeholder: PropTypes.string.isRequired,
	height: PropTypes.number,
	showLettersLeft: PropTypes.bool,
	showError: PropTypes.bool,
	maxLength: PropTypes.number,
	required: PropTypes.bool,
	value: PropTypes.string,

	mb: PropTypes.number,

	disabled: PropTypes.bool,

	classNameLabel: PropTypes.string,
	isTeacherStudentView: PropTypes.bool.isRequired
}

TextAreaField.defaultProps = {
	disabled: false
}

const mapStateToProps = state => ({
	isTeacherStudentView: state.user.isTeacherStudentView
})

export default connect(mapStateToProps)(TextAreaField)
