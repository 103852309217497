import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { useLocation } from 'react-router'

import CompanyProfile from '../../../components/views/student/CompanyProfile'

import injectRole from '../../../components/HOC/injectRole'

import { TRoles, TRoutes } from '../../../shared/const'

function EditCompany(props) {
	const location = useLocation()
	const id = location.pathname.split('/')[location.pathname.split('/').length - 1]

	if (isNaN(Number(id))) return <Redirect to={TRoutes.NotFound} />
	if (props.role === TRoles.ADMIN || props.role === TRoles.FEDERAL_ADMIN) return <CompanyProfile />
	else return <Redirect to={TRoutes.DASHBOARD} />
}

EditCompany.propTypes = {
	role: PropTypes.string.isRequired
}

export default injectRole(EditCompany)
