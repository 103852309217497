import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

import TeacherCompaniesList from '../../components/views/teacher/CompaniesList'

import injectRole from '../../components/HOC/injectRole'

import { TRoles, TRoutes } from '../../shared/const'

function CompaniesList(props) {
	if (props.role === TRoles.TEACHER) return <TeacherCompaniesList />
	else return <Redirect to={TRoutes.DASHBOARD} />
}

CompaniesList.propTypes = {
	role: PropTypes.string.isRequired
}

export default injectRole(CompaniesList)
