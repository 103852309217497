import clsx from 'clsx'
import { useEffect } from 'react'
import PropTypes from 'prop-types'

import styles from './style.module.scss'
import Icon from '../Icon'

export default function Search(props) {
	const { value = '', onSubmit } = props

	useEffect(() => {
		if (!value.length) onSubmit(new Event('placeholder'))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value])

	return (
		<label className={clsx(styles.root, props.className)}>
			<form onSubmit={props.onSubmit}>
				<input
					value={props.value}
					onChange={props.onChange}
					disabled={props.disabled}
					className={clsx(styles.input, props.inputClassName)}
					placeholder={props.placeholder}
				/>
				<button type="submit" onClick={props.onClick} className={clsx(styles.btn, props.btnClassName)}>
					<Icon name="search" noFill={true} size={{ h: 30, w: 30 }} />
				</button>
				<button
					onClick={evt => {
						evt.preventDefault()
						props.onChange({
							target: {
								value: ''
							}
						})
					}}
					className={clsx(styles.btn, styles.btnClean)}
				>
					<Icon name="close" noFill={true} size={{ h: 10, w: 10 }} />
				</button>
			</form>
		</label>
	)
}

Search.propTypes = {
	onSubmit: PropTypes.func,
	onClick: PropTypes.func,
	onChange: PropTypes.func,
	value: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	inputClassName: PropTypes.string,
	btnClassName: PropTypes.string
}
Search.defaultProps = {
	disabled: false
}
