import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

import TeacherDocumentsList from '../../components/views/teacher/DocumentsList'

import StudentDocumentsList from '../../components/views/student/DocumentsList'

import AdminDocumentsList from '../../components/views/admin/DocumentsList'

import injectRole from '../../components/HOC/injectRole'

import { TRoles, TRoutes } from '../../shared/const'

function DocumentsList(props) {
	switch (props.role) {
		case TRoles.TEACHER:
			return <TeacherDocumentsList />

		case TRoles.STUDENT:
			return <StudentDocumentsList />

		case TRoles.ADMIN:
			return <AdminDocumentsList />
		case TRoles.FEDERAL_ADMIN:
			return <AdminDocumentsList />
		default:
			return <Redirect to={TRoutes.DASHBOARD} />
	}
	// if (props.role === TRoles.TEACHER) return <TeacherDocumentsList />
	// else if (props.role === TRoles.STUDENT) return <StudentDocumentsList />
	// else return <Redirect to={TRoutes.DASHBOARD} />
}

DocumentsList.propTypes = {
	role: PropTypes.string.isRequired
}

export default injectRole(DocumentsList)
