import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import Btn from '../../../../../../UI/Btn'
import TextField from '../../../../../../UI/TextField'
import styles from '../style.module.scss'
import Joi from 'joi'
import { joiResolver } from '@hookform/resolvers/joi'
import { useTranslation } from 'react-i18next'

const schema = Joi.object({
	bankData: Joi.object().keys({
		payee: Joi.string().required(),
		bankPayee: Joi.string().required(),
		iban: Joi.string().required(),
		bic: Joi.string().required()
	})
})

export default function BankData(props) {
	const { t } = useTranslation()
	const {
		register,
		reset,
		handleSubmit,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema)
	})

	const onSubmit = form => {
		props
			.updateOptions({ name: 'bankData', value: form.bankData })
			.then(() => toast.success(t('Options was updated')))
	}

	useEffect(() => {
		reset({ bankData: props.form?.bankData || {} })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.form])
	return (
		<form id="bankData" className={styles.root} onSubmit={handleSubmit(onSubmit)}>
			<section className={styles.section}>
				<h3 className={styles.sectionTitle}>{t('Bank Data')}</h3>
				<div className={styles.sectionContent}>
					<div className={styles.flexContainer}>
						<TextField
							className={styles.field}
							label={t('Beneficiary')}
							mb={20}
							register={register(`bankData.payee`)}
							error={errors.bankData?.payee}
						/>
						<TextField
							className={styles.field}
							label={`Bank of the ${t('Beneficiary')}`}
							mb={20}
							register={register(`bankData.bankPayee`)}
							error={errors.bankData?.bankPayee}
						/>
					</div>
					<div className={styles.flexContainer}>
						<TextField
							className={styles.field}
							label="IBAN"
							mb={20}
							register={register(`bankData.iban`)}
							error={errors.bankData?.iban}
						/>
						<TextField
							className={styles.field}
							label="BIC"
							mb={20}
							register={register(`bankData.bic`)}
							error={errors.bankData?.bic}
						/>
					</div>
					<div className={styles.btnContainer}>
						<Btn
							className={styles.btn}
							type="submit"
							icon="arrow"
							iconSize={{ w: 10, h: 10 }}
							iconColor="#fff"
						>
							{t('save')}
						</Btn>
					</div>
				</div>
			</section>
		</form>
	)
}
BankData.propTypes = {
	form: PropTypes.object.isRequired,
	updateOptions: PropTypes.func.isRequired
}
