import Joi from 'joi'
import clsx from 'clsx'
import _omit from 'lodash.omit'
import PropTypes from 'prop-types'
import { subYears } from 'date-fns'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { joiResolver } from '@hookform/resolvers/joi'
import { connect } from 'react-redux'

import Btn from '../../../../UI/Btn'
import TextField from '../../../../UI/TextField'
import SelectField from '../../../../UI/SelectField'
import DateTimeField from '../../../../UI/DateTimeField'
import AvatarUploader from '../../../../UI/AvatarUploader'

import http from '../../../../shared/http'
import { joiFile, joiPhone, useInvite } from '../../../../shared/helpers'
import { schemaOptions } from '../../../../shared/i18n'
import { TApi, genderOption, TRoutes, TLanguages } from '../../../../shared/const'

import styles from '../../common.module.scss'
import { setAuth, setSignInMode, updateProfile } from '../../../../redux/ducks/user'
import { useHistory } from 'react-router-dom'
import PolicyLinks from '../../../../UI/policyLinks'

const schema = Joi.object({
	avatar: joiFile(10),
	salutation: Joi.string().required(),
	gender: Joi.string().required(),
	firstName: Joi.string()
		.min(2)
		.max(26)
		.regex(/^[a-zA-ZА-яЁёÄÖÜẞäöüß]+( [a-zA-ZА-яЁёÄÖÜẞäöüß]+)*$/)
		.required()
		.messages({
			'string.pattern.base': 'Only letters are allowed !'
		}),
	// .regex(/^([a-zA-ZА-яЁёÄÖÜẞäöüß])*[^\s]\1*$/)
	lastName: Joi.string()
		.min(2)
		.max(26)
		.regex(/^[a-zA-ZА-яЁёÄÖÜẞäöüß]+( [a-zA-ZА-яЁёÄÖÜẞäöüß]+)*$/)
		.required()
		.messages({
			'string.pattern.base': 'Only letters are allowed !'
		}),
	birthday: Joi.date(),
	phone: joiPhone,
	parentalConfirmation: joiFile(10) // TODO REMOVE HERE and on Server
})

function PersonalDetails(props) {
	const { t, i18n } = useTranslation()
	const history = useHistory()
	const {
		register,
		control,
		handleSubmit,
		trigger,
		setValue,
		watch,
		formState: { errors, isValid }
	} = useForm({
		resolver: joiResolver(schema, {
			errors: {
				labels: false,
				language: localStorage.getItem('i18nextLng')
			},
			...schemaOptions
		}),
		mode: 'onChange',
		defaultValues: _omit(props.form, ['avatar', 'parentalConfirmation'])
	})
	const { isToken } = useInvite()

	const [avatarSrc, setAvatarSrc] = useState('')
	const [isLoading, setLoading] = useState(false)

	const minAge = subYears(new Date(), 6)
	const maxAge = subYears(new Date(), 20)

	const [avatarField] = watch(['avatar'])

	useEffect(() => {
		if (Object.keys(errors).length) trigger()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localStorage.getItem('i18nextLng')])

	const onSubmit = fields => {
		const formData = new FormData()

		if (fields.avatar && fields.avatar[0]) formData.append('avatar', fields.avatar[0])
		if (fields.parentalConfirmation && fields.parentalConfirmation[0])
			formData.append('parentalConfirmation', fields.parentalConfirmation[0])

		formData.append('salutation', fields.salutation)
		formData.append('gender', fields.gender)
		formData.append('firstName', fields.firstName)
		formData.append('lastName', fields.lastName)
		if (fields.birthday) formData.append('birthday', fields.birthday)
		formData.append('phone', fields.phone)

		setLoading(true)
		http.put(TApi.STUDENT_COMMON_PERSONAL_DETAILS, formData)
			.then(({ data }) => {
				props.updateProfile(data)
				toast.success(t('Registration was successful. Please confirm email.'))
				if (isToken || props.companyId) {
					props.setSignInMode(false)
					props.setAuth(true)
					history.push(TRoutes.DASHBOARD)
				} else props.onNext()
			})
			.finally(() => setLoading(false))
	}

	const handleAvatarUploaderClean = () => {
		setValue('avatar', null)
		setAvatarSrc('')
	}

	useEffect(() => {
		const file = avatarField && avatarField[0]
		if (!file) return

		setAvatarSrc(URL.createObjectURL(file))
	}, [avatarField, props.form])

	useEffect(() => {
		if (props.phone) props.onNext()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.phone])

	return (
		<div className={styles.root}>
			<div className={styles.header}>
				<div className={styles.container}>
					<h2 className={styles.miniTitle}>{t('SignUp.noun')}</h2>
					<h3 className={styles.step}>{t('Step 2')}</h3>
					<span className={styles.subtitle}>{t('Provide your personal details')}</span>
				</div>
				<PolicyLinks />
			</div>
			<form
				className={clsx(styles.container, styles.form, styles.formPersonalDetails)}
				onSubmit={handleSubmit(onSubmit)}
				autoComplete="off"
			>
				<AvatarUploader
					src={avatarSrc}
					error={errors.avatar}
					disabled={isLoading}
					onClean={handleAvatarUploaderClean}
					register={register('avatar')}
					className={styles.avatarUploader}
				/>
				<section className={styles.section}>
					<div className={styles.leftForm}>
						<SelectField
							disabled={isLoading}
							required
							mb={20}
							label={t('salutation')}
							control={control}
							name="salutation"
							error={errors.salutation}
							placeholder={t('Select salutation')}
							options={props.salutations?.map(v => ({
								value: v.value_EN,
								label: i18n.language === TLanguages.EN ? v.value_EN : v.value_DE
							}))}
						/>
					</div>
					<div className={styles.rightForm}>
						<SelectField
							disabled={isLoading}
							required
							mb={20}
							label={t('gender')}
							control={control}
							name="gender"
							error={errors.gender}
							placeholder={t('Select gender')}
							options={genderOption
								.map(v => ({ label: t(v.label), value: v.value }))
								.sort((a, b) => a.label.localeCompare(b.label))}
						/>
					</div>
				</section>
				<section className={styles.section}>
					<div className={styles.leftForm}>
						<TextField
							disabled={isLoading}
							required
							mb={20}
							label={t('First Name')}
							placeholder={t('Type First Name')}
							error={errors.firstName}
							register={register('firstName')}
						/>
					</div>
					<div className={styles.rightForm}>
						<TextField
							disabled={isLoading}
							required
							mb={20}
							label={t('Last Name')}
							placeholder={t('Type Last Name')}
							error={errors.lastName}
							register={register('lastName')}
						/>
					</div>
				</section>

				<section className={styles.section}>
					<div className={styles.leftForm}>
						<DateTimeField
							disabled={isLoading}
							mb={20}
							name="birthday"
							label={t('Date of Birth')}
							placeholder="01.01.2000"
							error={errors.birthday}
							control={control}
							dateFormat="dd.MM.yyyy"
							minDate={maxAge}
							maxDate={minAge}
						/>
					</div>
					<div className={styles.rightForm}>
						<TextField
							disabled={isLoading}
							required
							mb={20}
							label={t('phone')}
							placeholder="+43 6XX XXXXXX"
							error={errors.phone}
							register={register('phone')}
						/>
					</div>
				</section>

				<div className={styles.line} />

				<Btn
					loading={isLoading}
					type="submit"
					width={214}
					disabled={!isValid}
					className={styles.btnSignUp}
					iconSize={{ w: 10, h: 10 }}
					iconColor="#fff"
					icon="arrow"
				>
					{t('SignUp.verb')}
				</Btn>
			</form>
		</div>
	)
}

PersonalDetails.propTypes = {
	onNext: PropTypes.func.isRequired,
	onSubmitInvite: PropTypes.func.isRequired,
	phone: PropTypes.string.isRequired,
	salutations: PropTypes.array.isRequired,
	genders: PropTypes.array.isRequired,

	updateProfile: PropTypes.func.isRequired,
	setSignInMode: PropTypes.func.isRequired,
	setAuth: PropTypes.func.isRequired,
	companyId: PropTypes.number.isRequired
}

const mapStateToProps = state => ({
	phone: state.user.profile.phone,
	salutations: state.options.salutations,
	genders: state.options.genders,
	companyId: state.user.profile.companyId
})

const mapDispatchToProps = dispatch => ({
	updateProfile: data => dispatch(updateProfile(data)),
	setSignInMode: value => dispatch(setSignInMode(value)),
	setAuth: value => dispatch(setAuth(value))
})

export default connect(mapStateToProps, mapDispatchToProps)(PersonalDetails)
