import React, { useEffect, useState } from 'react'
import styles from '../style.module.scss'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import Joi from 'joi'
import http from '../../../../../shared/http'
import { TApi, TRoutes } from '../../../../../shared/const'
import { joiResolver } from '@hookform/resolvers/joi'
import SelectField from '../../../../../UI/SelectField'
import Btn from '../../../../../UI/Btn'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import eventEmitter, { types } from '../../../../../shared/eventEmitter'
import TextField from '../../../../../UI/TextField'

const schema = Joi.object({
	school: Joi.object().required(),
	supervisingTeacher: Joi.object().required()
})

function Supervising(props) {
	const { id } = useParams()
	const location = useLocation()
	const path = location.pathname

	const { t } = useTranslation()
	const {
		control,
		handleSubmit,
		setValue,
		watch,
		getValues,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema),
		defaultValues: {
			school: {
				...props.companyProfile?.TeachersSupervisions?.[0]?.Teacher?.School,
				value: props.companyProfile?.TeachersSupervisions?.[0]?.Teacher?.School?.id,
				label: props.companyProfile?.TeachersSupervisions?.[0]?.Teacher?.School?.name
			},
			supervisingTeacher: {
				value: props.companyProfile?.TeachersSupervisions?.[0]?.Teacher?.id,
				label: `${props.companyProfile?.TeachersSupervisions?.[0]?.Teacher?.firstName} ${props.companyProfile?.TeachersSupervisions?.[0]?.Teacher?.lastName}`
			}
		}
	})
	const [isLoading, setLoading] = useState(false)
	const schoolFiled = watch('school')

	const loadTeacherOptions = async (search, loadedOptions) => {
		const school = getValues('school')

		const teachersRoute = path === TRoutes.COMPANY ? TApi.STUDENT_GET_TEACHERS : TApi.ADMIN_GET_TEACHERS
		const {
			data: { rows, count }
		} = await http.get(teachersRoute, {
			params: {
				search,
				limit: 30,
				offset: loadedOptions.length,
				schoolId: school ? school.value : school
			}
		})

		return {
			options: rows.map(row => ({
				...row,
				value: row.id,
				label: row.firstName + ' ' + row.lastName
			})),
			hasMore: loadedOptions.length < count
		}
	}

	const loadSchoolOptions = async (search, loadedOptions) => {
		const schoolRoute = path === TRoutes.COMPANY ? TApi.STUDENT_GET_SCHOOLS : TApi.ADMIN_GET_SCHOOLS

		const {
			data: { rows, count }
		} = await http.get(schoolRoute, {
			params: {
				search,
				limit: 30,
				offset: loadedOptions.length
			}
		})

		return {
			options: rows.map(row => ({ ...row, value: row.id, label: row.name })),
			hasMore: loadedOptions.length < count
		}
	}

	const onSubmit = data => {
		setLoading(true)
		const schema = {
			schoolId: data.school.value,
			teacherId: data.supervisingTeacher.value
		}

		const updateCompany =
			path === TRoutes.COMPANY
				? http.put(TApi.COMPANY_PUT_PROFILE, schema)
				: http.put(TApi.ADMIN_GET_COMPANY(id), schema)

		updateCompany
			.then(async () => {
				await props.fetchCompany()
				toast.success(t('Company profile has been updated successfully.'))
			})
			.finally(() => setLoading(false))
	}

	useEffect(() => {
		if (schoolFiled?.id !== props.companyProfile?.TeachersSupervisions?.[0]?.Teacher?.School?.id)
			setValue('supervisingTeacher', null)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [schoolFiled])

	const teachers = props.companyProfile?.TeachersSupervisions?.filter(teacher => !teacher.isSupervision) || []

	return (
		<div className={styles.root}>
			<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
				<div className={styles.section}>
					<h1 className={styles.sectionTitle}>
						{t('school')} & {t('Supervision Teacher')}
					</h1>
					<div className={styles.sectionContent}>
						<div className={styles.flexContainer}>
							<SelectField
								required
								async
								className={styles.field}
								disabled={
									isLoading ||
									props.companyProfile.isActive ||
									props.companyProfile.isNeedReview ||
									!props.companyProfile?.TeachersSupervisions?.[0]?.Teacher?.id
								}
								mb={20}
								label={t('school')}
								isSearchable
								control={control}
								error={errors.school}
								name="school"
								placeholder="Select"
								debounceTimeout={400}
								loadOptions={loadSchoolOptions}
								formatOptionLabel={({ label, ...row }) => (
									<div className={styles.selectFieldOption}>
										<span style={{ marginLeft: 10 }}>{row.name}</span>
									</div>
								)}
							/>
							<span className={styles.sectionNote}>{t('schoolDesc')}</span>
						</div>
						<div className={styles.flexContainer}>
							<SelectField
								required
								async
								className={styles.field}
								disabled={isLoading || !props.companyProfile?.TeachersSupervisions?.[0]?.Teacher?.id}
								mb={20}
								label={t('Supervision Teacher')}
								isSearchable
								control={control}
								error={errors.supervisingTeacher}
								name="supervisingTeacher"
								placeholder="Select"
								debounceTimeout={400}
								loadOptions={loadTeacherOptions}
							/>
							<span className={styles.sectionNote}>{t('teacherDesc')}</span>
						</div>
						{!props.isTeacherStudentView && (
							<div className={styles.flexContainer}>
								<Btn
									className={styles.field}
									icon="plus"
									iconSize={{ w: 10, h: 10 }}
									iconColor="#fff"
									disabled={!props.companyProfile?.TeachersSupervisions?.[0]?.Teacher?.id}
									onClick={() => eventEmitter.emit(types.openAddTeacherModal, id)}
								>
									{t('Add Teacher')}
								</Btn>
							</div>
						)}

						{teachers.length > 0 && (
							<div className={styles.teachersContainer}>
								{teachers.map(t => {
									const teacher = t.Teacher
									return (
										<TextField
											label="Company teacher"
											placeholder="Company teacher"
											value={`${teacher.firstName} ${teacher.lastName}`}
											disabled
										/>
									)
								})}
							</div>
						)}

						{!props.isTeacherStudentView && (
							<div className={styles.btnContainer}>
								<Btn
									className={styles.btn}
									loading={isLoading}
									type="submit"
									icon="arrow"
									disabled={!props.companyProfile?.TeachersSupervisions?.[0]?.Teacher?.id}
									iconSize={{ w: 10, h: 10 }}
									iconColor="#fff"
								>
									{t('save')}
								</Btn>
							</div>
						)}
					</div>
				</div>
			</form>
		</div>
	)
}

Supervising.propTypes = {
	companyProfile: PropTypes.object,
	isTeacherStudentView: PropTypes.bool.isRequired,
	fetchCompany: PropTypes.func.isRequired
}

export default Supervising
