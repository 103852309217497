import user from './user'
import options from './options'
import filter from './filter'

const ducks = {
	user,
	options,
	filter
}
export default ducks
