import clsx from 'clsx'
import PropTypes from 'prop-types'

import checkIcon from '../../../assets/icons/checkWhite.svg'

import styles from './style.module.scss'

export default function MiniStep(props) {
	return (
		<div
			className={styles.root}
			style={{ left: `${(100 / (props.allSteps - 1)) * props.needStep}%` }}
			onClick={props.onClick}
		>
			<div className={styles.backgroundCircule} style={{ backgroundColor: props.bg }}>
				<div className={clsx(styles.step, props.needStep < props.currentStep && styles.accomplished)}>
					{props.needStep >= props.currentStep ? props.needStep + 1 : <img src={checkIcon} alt="" />}
				</div>
			</div>
			<p className={styles.stepText}>{props.title}</p>
		</div>
	)
}
MiniStep.propTypes = {
	title: PropTypes.string,
	currentStep: PropTypes.number,
	needStep: PropTypes.number,
	allSteps: PropTypes.number,
	onClick: PropTypes.func
}
