import Joi from 'joi'
import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { joiResolver } from '@hookform/resolvers/joi'

import Btn from '../../../../../../UI/Btn'
import TextField from '../../../../../../UI/TextField'

import styles from '../style.module.scss'

const schema = Joi.object({
	companyReminders: Joi.object().keys({
		firstReminderNotFinished: Joi.number().integer().min(1).required(),
		firstReminderSubmittedApproval: Joi.number().integer().min(1).required()
	})
})

export default function CompanyReminders(props) {
	const { t } = useTranslation()
	const {
		register,
		reset,
		handleSubmit,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema)
	})

	const onSubmit = form => {
		props
			.updateOptions({ name: 'companyReminders', value: form.companyReminders })
			.then(() => toast.success(t('Options was updated')))
	}

	useEffect(() => {
		reset({ companyReminders: props.form?.companyReminders || {} })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.form])
	return (
		<form id="companyReminders" className={styles.root} onSubmit={handleSubmit(onSubmit)}>
			<section className={styles.section}>
				<h3 className={styles.sectionTitle}>{t('Company Reminders')}</h3>
				<div className={styles.sectionContent}>
					<div className={styles.flexContainer}>
						<TextField
							className={styles.field}
							label={t(
								'1st reminder [x] days after company set-up started and not finished and no login'
							)}
							mb={20}
							register={register(`companyReminders.firstReminderNotFinished`)}
							error={errors.companyReminders?.firstReminderNotFinished}
						/>
					</div>
					{/* 					<div className={styles.flexContainer}>
						<TextField
							className={styles.field}
							label={t('1st reminder [x] days after company profile has been submitted for approval and no login')}
							mb={20}
							register={register(`companyReminders.firstReminderSubmittedApproval`)}
							error={errors.companyReminders?.firstReminderSubmittedApproval}
						/>
					</div> */}
					<div className={styles.btnContainer}>
						<Btn
							className={styles.btn}
							type="submit"
							icon="arrow"
							iconSize={{ w: 10, h: 10 }}
							iconColor="#fff"
						>
							{t('save')}
						</Btn>
					</div>
				</div>
			</section>
		</form>
	)
}

CompanyReminders.propTypes = {
	form: PropTypes.object.isRequired,
	updateOptions: PropTypes.func.isRequired
}
