import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

import { TRoutes } from '../../shared/const'

function OnlyPublicRoute({ isAuth, except, component: Component, ...rest }) {
	return (
		<Route
			{...rest}
			render={() =>
				isAuth && !except.includes(rest.location.pathname) ? <Redirect to={TRoutes.DASHBOARD} /> : <Component />
			}
		/>
	)
}

OnlyPublicRoute.propTypes = {
	isAuth: PropTypes.bool.isRequired,
	except: PropTypes.arrayOf(PropTypes.string).isRequired,
	component: PropTypes.object.isRequired
}

OnlyPublicRoute.defaultProps = {
	except: []
}

const mapStateToProps = state => ({
	isAuth: state.user.isAuth
})

export default connect(mapStateToProps)(OnlyPublicRoute)
