import Joi from 'joi'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { joiResolver } from '@hookform/resolvers/joi'
import { useForm, useFieldArray } from 'react-hook-form'

import Btn from '../../../../UI/Btn'
import BtnAdd from '../../../../UI/BtnAdd'
import MiniHeader from '../../../../UI/MiniHeader'
import TextField from '../../../../UI/TextField'
import TextAreaField from '../../../../UI/TextAreaField'
import TextFieldNumber from '../../../../UI/TextFieldNumber'

import http from '../../../../shared/http'
import { TApi } from '../../../../shared/const'
import { schemaOptions } from '../../../../shared/i18n'

import styles from './style.module.scss'

const schema = Joi.object({
	totalTurnover: Joi.number().allow(null),
	targetGroup: Joi.string().min(200).max(10000).required().messages({
		'string.empty': `is not allowed to be empty`,
		'string.min': `must be greater than 200 characters`,
		'string.max': `must be less than 10000 characters`
	}),
	productUsage: Joi.string().min(200).max(10000).required().messages({
		'string.empty': `is not allowed to be empty`,
		'string.min': `must be greater than 200 characters`,
		'string.max': `must be less than 10000 characters`
	}),
	customer: Joi.string().min(200).max(10000).required().messages({
		'string.empty': `is not allowed to be empty`,
		'string.min': `must be greater than 200 characters`,
		'string.max': `must be less than 10000 characters`
	}),
	riskStrategy: Joi.array()
		.items(
			Joi.object({
				risk: Joi.string().min(2).max(1000).required().messages({
					'string.empty': `is not allowed to be empty`
				}),
				strategy: Joi.string().min(50).max(10000).required().messages({
					'string.empty': `is not allowed to be empty`
				})
			})
		)
		.min(3),
	salesForecast: Joi.array().items(
		Joi.object({
			product: Joi.string().messages({
				'string.empty': `is not allowed to be empty`
			}),
			price: Joi.number().messages({
				'number.base': `must be a number`
			}),
			expectedSales: Joi.number().messages({
				'number.base': `must be a number`
			}),
			expectedTurnover: Joi.number().messages({
				'number.base': `must be a number`
			})
		})
	)
})

function CompanyInsurance(props) {
	const { t } = useTranslation()
	const {
		register,
		handleSubmit,
		control,
		trigger,
		reset,
		setValue,
		watch,
		formState: { errors }
	} = useForm({
		defaultValues: {
			salesForecast: [{ product: '', price: '', expectedSales: '' }],
			riskStrategy: [
				{ risk: '', strategy: '' },
				{ risk: '', strategy: '' },
				{ risk: '', strategy: '' }
			]
		},
		resolver: joiResolver(schema, {
			errors: {
				labels: false,
				language: localStorage.getItem('i18nextLng')
			},
			...schemaOptions
		})
	})

	useEffect(() => {
		if (Object.keys(errors).length) trigger()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localStorage.getItem('i18nextLng')])

	const {
		fields: salesFields,
		append,
		remove
	} = useFieldArray({
		reset,
		control,
		name: 'salesForecast'
	})

	const {
		fields: riskFields,
		append: riskAppend,
		remove: riskRemove
	} = useFieldArray({
		reset,
		control,
		name: 'riskStrategy'
	})
	const salesForecastWatch = watch('salesForecast')

	const salesFieldsWithTurnover = salesForecastWatch
		.map(item => ({
			...item,
			expectedTurnover: item.price * item.expectedSales
		}))
		.reduce((accumulator, currentValue) => accumulator + currentValue.expectedTurnover, 0)

	const [isLoading, setLoading] = useState(false)

	const onSubmit = data => {
		setLoading(true)
		const { totalTurnover, ...form } = data

		const expectTurn = data.salesForecast.map(item => ({
			...item,
			expectedTurnover: item.price * item.expectedSales
		}))
		const finalData = {
			...form,
			salesForecast: expectTurn,
			totalAmoutOfSales: salesFieldsWithTurnover
		}

		http.put(TApi.STUDENT_COMPANY_INSURANCE, finalData)
			.then(() => {
				toast.success(t('Risk analysis form successfully updated'))
			})
			.finally(() => setLoading(false))
	}

	useEffect(() => {
		http.get(TApi.STUDENT_COMPANY_INSURANCE).then(({ data }) => {
			if (!data) return
			setValue('productUsage', data.productUsage, { shouldDirty: true })
			setValue('targetGroup', data.targetGroup, { shouldDirty: true })
			setValue('customer', data.customer, { shouldDirty: true })
			setValue('riskStrategy', data.riskStrategy, { shouldDirty: true })
			setValue('salesForecast', data.salesForecast, { shouldDirty: true })
		})

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const isDisabledFields = isLoading || props.isActivatedCompany || props.isNeedReview
	return (
		<div className={styles.root}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<MiniHeader className={styles.miniHeader} title={t('Market and Risk Analysis')} bg="#f8faff" />
				<div className={styles.contentContainer}>
					<h1 className={styles.title}>{t('Market Description')}</h1>
					<div className={styles.content}>
						<div className={styles.flexWrapp}>
							<TextAreaField
								disabled={isDisabledFields}
								classNameLabel={styles.textArea}
								height={99}
								label={t('Target group(s)')}
								placeholder={t('Target group(s)')}
								error={errors.targetGroup}
								register={register('targetGroup')}
							/>
							<p className={styles.noteRight}>
								<h1 className={styles.noteTitle}>{t('To market description')}</h1>
								{t(
									'Describe your target group(s) as precisely as possible with their specific characteristics, such as age group, life situation, interests, whereabouts, etc.'
								)}
							</p>
						</div>

						<div className={styles.flexWrapp}>
							<TextAreaField
								disabled={isDisabledFields}
								classNameLabel={styles.textArea}
								height={99}
								label={t('Product usage')}
								placeholder={t('Add usage...')}
								error={errors.productUsage}
								register={register('productUsage')}
							/>

							<p className={styles.noteRight}>
								<h1 className={styles.noteTitle}>{t('To product benefit')}</h1>
								{t(
									'What added value does your offer bring to the target group, what needs does it serve? For what benefit does the customer pay the purchase price?'
								)}
							</p>
						</div>

						<div className={styles.flexWrapp}>
							<TextAreaField
								disabled={isDisabledFields}
								classNameLabel={styles.textArea}
								height={99}
								label={t('Customer contact, sales strategy')}
								placeholder={t('Add contact & Strategy')}
								error={errors.customer}
								register={register('customer')}
							/>
							<p className={styles.noteRight}>
								<h1 className={styles.noteTitle}>{t('Customer contact, sales strategy')}</h1>
								{t(
									'Where is/are your target group(s) to be found? How do you promote your offer to the respective target group? Which communication channels do you use?'
								)}
							</p>
						</div>
					</div>
				</div>
				<div className={styles.contentContainer}>
					<h1 className={styles.title}>{t('Sales forecast')}</h1>
					<div className={styles.content}>
						<p className={styles.note}>
							{t(
								'Enter your expected sales figures for your up to 5 most important offers and their respective prices. This will give you a forecast of your sales for the entire project year.'
							)}
						</p>
						{salesFields.map((item, index) => {
							return (
								<div key={item.id} style={{ width: '100%' }}>
									<div className={styles.flexWrapp} style={{ marginBottom: 40 }}>
										<TextField
											disabled={isDisabledFields}
											className={styles.field}
											label={t('Product / Service')}
											placeholder={t('Product name')}
											error={errors.salesForecast && errors.salesForecast[index]?.product}
											register={register(`salesForecast.${index}.product`)}
										/>
										<TextFieldNumber
											disabled={isDisabledFields}
											className={styles.field}
											label={t('Price (€) / unit')}
											placeholder="€ 00.000,00"
											error={errors.salesForecast && errors.salesForecast[index]?.price}
											register={register(`salesForecast.${index}.price`)}
											control={control}
											name={`salesForecast.${index}.price`}
										/>
									</div>
									<div className={styles.flexWrapp} style={{ marginBottom: 40 }}>
										<TextFieldNumber
											disabled={isDisabledFields}
											className={styles.field}
											name={`salesForecast.${index}.expectedSales`}
											control={control}
											label={t('Expected sales (number)')}
											placeholder="00.000"
											noDecimal
											error={errors.salesForecast && errors.salesForecast[index]?.expectedSales}
										/>
										<TextFieldNumber
											className={styles.field}
											readOnly
											disabled={isDisabledFields}
											value={
												(salesForecastWatch[index].price || 0) *
												(salesForecastWatch[index].expectedSales || 0)
											}
											label={t('Expected turnover (€)')}
											placeholder="€ 00.000,00"
											control={control}
											name={`salesForecast.${index}.expectedTurnover`}
										/>
									</div>
									{index === 0 ? (
										''
									) : (
										<Btn
											disabled={isDisabledFields}
											className={styles.deleteBtn}
											theme="white"
											icon="delete"
											iconColor="#000"
											onClick={() => remove(index)}
										/>
									)}
								</div>
							)
						})}

						<TextFieldNumber
							readOnly
							value={salesFieldsWithTurnover}
							disabled={isDisabledFields}
							mb={36}
							label={t('The total amount of estimated annual sales is €.')}
							placeholder="€ 00.000,00"
							name={'totalTurnover'}
							control={control}
						/>

						{!props.isTeacherStudentView && (
							<div className={styles.btnAdd}>
								<BtnAdd
									type="button"
									disabled={isDisabledFields}
									onClick={() => append({ product: '', price: '', expectedSales: '' })}
								/>
								<h1 className={styles.btnTitle}>{t('Add new forecast')}</h1>
							</div>
						)}
					</div>
				</div>
				<div className={styles.contentContainer}>
					<h1 className={styles.title}>{t('Risk and strategy')}</h1>
					<div className={styles.content}>
						<p className={styles.note}>
							{t(
								'Enter here possible risks affecting your company and describe a strategy for dealing with the risk in each case. Risks can arise, for example, in connection with your production, with the target group, with suppliers or with the competition.'
							)}
						</p>
						{riskFields.map((item, index) => {
							return (
								<div key={item.id} style={{ width: '100%' }}>
									<TextAreaField
										disabled={isDisabledFields}
										classNameLabel={styles.textArea}
										mb={36}
										height={48}
										label={t('Risk')}
										placeholder={t('Risk')}
										error={errors.riskStrategy && errors.riskStrategy[index]?.risk}
										register={register(`riskStrategy.${index}.risk`)}
									/>

									<TextAreaField
										disabled={isDisabledFields}
										classNameLabel={styles.textArea}
										mb={36}
										height={99}
										label={t('Avoidance strategy')}
										placeholder={t('Add avoidance strategy')}
										error={errors.riskStrategy && errors.riskStrategy[index]?.strategy}
										register={register(`riskStrategy.${index}.strategy`)}
									/>
									{index !== 0 && index !== 1 && index !== 2 && (
										<Btn
											disabled={isLoading || props.isActivatedCompany || props.isNeedReview}
											className={styles.deleteBtn}
											theme="white"
											icon="delete"
											iconColor="#000"
											onClick={() => riskRemove(index)}
										/>
									)}
								</div>
							)
						})}
						{!props.isTeacherStudentView && (
							<div className={styles.btnAdd}>
								<BtnAdd
									type="button"
									disabled={props.isActivatedCompany || props.isNeedReview}
									onClick={() => riskAppend({ risk: '', strategy: '' })}
								/>
								<h1 className={styles.btnTitle}>{t('Add new Risk')}</h1>
							</div>
						)}

						{!props.isActivatedCompany && !props.isNeedReview && !props.isTeacherStudentView && (
							<div className={styles.btnContainer}>
								<Btn
									className={styles.btn}
									loading={isLoading}
									type="submit"
									icon="arrow"
									iconSize={{ w: 10, h: 10 }}
									iconColor="#fff"
								>
									{t('save')}
								</Btn>
							</div>
						)}
					</div>
				</div>
			</form>
		</div>
	)
}

CompanyInsurance.propTypes = {
	isActivatedCompany: PropTypes.bool.isRequired,
	isNeedReview: PropTypes.bool.isRequired,
	isTeacherStudentView: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
	isActivatedCompany: !!state.user.profile.EmploymentCompany?.isActive,
	isNeedReview: !!state.user.profile.EmploymentCompany?.isNeedReview,
	isTeacherStudentView: state.user.isTeacherStudentView
})

export default connect(mapStateToProps)(CompanyInsurance)
