import React, { useEffect, useState } from 'react'
import styles from '../../style.module.scss'
import PropTypes from 'prop-types'
import Joi from 'joi'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { schemaOptions } from '../../../../../../../../shared/i18n'
import Btn from '../../../../../../../../UI/Btn'
import clsx from 'clsx'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import TextFieldNumber from '../../../../../../../../UI/TextFieldNumber'
import Decimal from 'decimal.js'
import { TReports, TRoles } from '../../../../../../../../shared/const'
import { connect } from 'react-redux'
import imageWarning from '../../../../../../../../assets/images/warning.png'
import VisibilityAccess from '../../../../../../../VisibilityAccess'

function CompanyWagesSalary(props) {
	const isSalariesNotPaid = value => {
		return new Decimal(value).isZero() && new Decimal(props.form.semiAnnualReportPersonellCostsFirst).isZero()
	}

	const { t } = useTranslation()

	const existBothSemesterSalaryError = 'Gross salaries may not be EUR 0 in both semesters'

	const schema = Joi.object({
		grossSalariesEmployeeTotal: Joi.number()
			.required()
			.custom((value, helper) => {
				if (props.form?.type === TReports.SEMIANNUAL && new Decimal(value).isZero()) return value
				if (props.form?.type === TReports.ANNUAL && new Decimal(value).isZero() && !isSalariesNotPaid(value))
					return value
				if (props.form?.type === TReports.ANNUAL && isSalariesNotPaid(value)) {
					return helper.message(t(existBothSemesterSalaryError))
				}
				return value
			})
			.messages({
				'number.base': `must be a number`,
				'any.required': `is a required field`
			}),
		grossSalariesEmployerTotal: Joi.number()
			.required()
			.custom((value, helper) => {
				const total = new Decimal(helper.state.ancestors[0].grossSalariesEmployeeTotal)
				if (total.equals(value)) return value
				if (total.isZero()) return helper.message(t('This field must be over 0'))
				return helper.message(t('Wrong result'))
			})
			.messages({
				'number.base': `must be a number`,
				'any.required': `is a required field`
			}),
		socialSecurityContributionEmployee: Joi.number()
			.required()
			.custom((value, helper) => {
				const total = new Decimal(helper.state.ancestors[0].grossSalariesEmployeeTotal)
				if (total.times(0.05).toDecimalPlaces(2).equals(value)) return value
				return helper.message(t('Wrong result'))
			}),
		calculationBasis: Joi.number()
			.required()
			.custom((value, helper) => {
				const total = new Decimal(helper.state.ancestors[0].grossSalariesEmployeeTotal)
				const socialSecurity = new Decimal(helper.state.ancestors[0].socialSecurityContributionEmployee)
				const calculation = total.minus(socialSecurity).toDecimalPlaces(2)
				if (calculation.equals(value)) return value
				return helper.message(t('Wrong result'))
			})
			.messages({
				'number.base': `must be a number`,
				'any.required': `is a required field`
			}),
		wageTax: Joi.number()
			.required()
			.custom((value, helper) => {
				const total = new Decimal(helper.state.ancestors[0].grossSalariesEmployeeTotal)
				if (total.minus(total.times(0.05)).times(0.1).toDecimalPlaces(2).equals(value)) return value
				return helper.message(t('Wrong result'))
			})
			.messages({
				'number.base': `must be a number`,
				'any.required': `is a required field`
			}),
		netSalaries: Joi.number()
			.required()
			.custom((value, helper) => {
				const calculationBasis = new Decimal(helper.state.ancestors[0].calculationBasis)
				const wageTax = new Decimal(helper.state.ancestors[0].wageTax)
				const netSalary = calculationBasis.minus(wageTax).toDecimalPlaces(2)
				if (netSalary.equals(value)) return value
				return helper.message(t('Wrong result'))
			})
			.messages({
				'number.base': `must be a number`,
				'any.required': `is a required field`
			}),

		socialSecurityContributionEmployer: Joi.number()
			.required()
			.custom((value, helper) => {
				const total = new Decimal(helper.state.ancestors[0].grossSalariesEmployeeTotal)
				if (total.times(0.06).toDecimalPlaces(2).equals(value)) return value
				return helper.message(t('Wrong result'))
			})
			.messages({
				'number.base': `must be a number`,
				'any.required': `is a required field`
			})
	})
	const [validate, setValidate] = useState(false)
	const {
		control,
		reset,
		setValue,
		handleSubmit,
		trigger,
		formState: { errors }
	} = useForm({
		resolver: validate
			? null
			: joiResolver(schema, {
					errors: {
						labels: false,
						language: localStorage.getItem('i18nextLng')
					},
					...schemaOptions
			  })
	})

	const onSubmit = form => {
		let data = form
		for (const key in data) {
			if (data[key] === '') {
				data[key] = null
			}
		}

		props
			.updateData(data)
			.then(() => {
				toast.success(t('Salary-related taxes was updated'))
				if (!validate) props.handlerNextStep()
			})
			.finally(() => setValidate(false))
	}
	useEffect(() => {
		reset({
			grossSalariesEmployeeTotal: props.form?.grossSalariesEmployeeTotal,
			grossSalariesEmployerTotal: props.form?.grossSalariesEmployerTotal,
			socialSecurityContributionEmployee: props.form?.socialSecurityContributionEmployee,
			calculationBasis: props.form?.calculationBasis,
			wageTax: props.form?.wageTax,
			netSalaries: props.form?.netSalaries,
			socialSecurityContributionEmployer: props.form?.socialSecurityContributionEmployer
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.form])

	useEffect(() => {
		if (Object.keys(errors).length) trigger()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localStorage.getItem('i18nextLng')])

	if (props.currentStep !== props.needStep) return null

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<section className={styles.section}>
				<h3 className={styles.sectionTitle}>{t('Salary-dependent contributions (employee share)')}</h3>
				<div className={styles.sectionContent}>
					<div className={styles.flexContainer}>
						<TextFieldNumber
							disabled={props.form?.isLoading || props.form?.isSubmitted}
							mb={20}
							label={t('Total gross salaries (in €)')}
							name={'grossSalariesEmployeeTotal'}
							control={control}
							placeholder="00.000,00"
							onChange={e => {
								setValue('grossSalariesEmployerTotal', e.target.value)
							}}
						/>
						{errors.grossSalariesEmployeeTotal &&
							errors.grossSalariesEmployeeTotal.message === t(existBothSemesterSalaryError) && (
								<div className={styles.warningContainer}>
									<VisibilityAccess access={[TRoles.STUDENT]}>
										<img src={imageWarning} className={styles.warningImage} alt="" />
										<span className={styles.warningTextColor}>
											{t(existBothSemesterSalaryError)}
										</span>
									</VisibilityAccess>
								</div>
							)}
					</div>
					<div className={styles.flexContainer}>
						<TextFieldNumber
							disabled={props.form?.isLoading || props.form?.isSubmitted}
							mb={20}
							label={t('minus 5% social security contribution (in €)')}
							name={'socialSecurityContributionEmployee'}
							control={control}
							placeholder="00.000,00"
							error={errors.socialSecurityContributionEmployee}
						/>

						<TextFieldNumber
							disabled={props.form?.isLoading || props.form?.isSubmitted}
							mb={20}
							label={t('= Calculation basis (in €)')}
							name={'calculationBasis'}
							control={control}
							placeholder="00.000,00"
							error={errors.calculationBasis}
						/>
					</div>
					<div className={styles.flexContainer}>
						<TextFieldNumber
							disabled={props.form?.isLoading || props.form?.isSubmitted}
							mb={20}
							label={t('minus 10% wage tax (in €)')}
							name={'wageTax'}
							control={control}
							placeholder="00.000,00"
							error={errors.wageTax}
						/>

						<TextFieldNumber
							disabled={props.form?.isLoading || props.form?.isSubmitted}
							mb={20}
							label={t('= Net wages (in €)')}
							name={'netSalaries'}
							control={control}
							placeholder="00.000,00"
							error={errors.netSalaries}
						/>
					</div>
				</div>
			</section>
			<section className={styles.section}>
				<h3 className={styles.sectionTitle}>{t('Salary-related taxes (employer`s contribution)')}</h3>
				<div className={styles.sectionContent}>
					<div className={styles.flexContainer}>
						<TextFieldNumber
							disabled
							mb={20}
							label={t('Total gross salaries (in €)')}
							name={'grossSalariesEmployerTotal'}
							control={control}
							placeholder="00.000,00"
							error={errors.grossSalariesEmployerTotal}
						/>

						<TextFieldNumber
							disabled={props.form?.isLoading || props.form?.isSubmitted}
							mb={20}
							label={t('of which 6% social security contribution (in €)')}
							name={'socialSecurityContributionEmployer'}
							control={control}
							placeholder="00.000,00"
							error={errors.socialSecurityContributionEmployer}
						/>
					</div>

					<div className={clsx(styles.flexContainer, styles.btnContainer)}>
						{props.form?.isSubmitted || props.isTeacherStudentView ? (
							<>
								<Btn className={styles.btn} type="button" onClick={() => props.handlerPrevStep()}>
									{t('Previous')}
								</Btn>
								<Btn className={styles.btn} type="button" onClick={() => props.handlerNextStep()}>
									{t('Next')}
								</Btn>
							</>
						) : (
							<>
								<Btn
									disabled={props.form?.isLoading || props.form?.isSubmitted}
									className={styles.btn}
									type="submit"
									theme="whiteBackground"
								>
									{t('Validate')}
								</Btn>
								<Btn
									disabled={props.form?.isLoading || props.form?.isSubmitted}
									className={styles.btn}
									type="submit"
									onClick={() => setValidate(true)}
								>
									{t('save')}
								</Btn>
							</>
						)}
					</div>
				</div>
			</section>
		</form>
	)
}

CompanyWagesSalary.propTypes = {
	form: PropTypes.object.isRequired,
	updateData: PropTypes.func.isRequired,

	currentStep: PropTypes.number.isRequired,
	needStep: PropTypes.number.isRequired,
	handlerNextStep: PropTypes.func.isRequired,
	handlerPrevStep: PropTypes.func.isRequired,
	isTeacherStudentView: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
	isTeacherStudentView: state.user.isTeacherStudentView
})

export default connect(mapStateToProps)(CompanyWagesSalary)
