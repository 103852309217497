import Joi from 'joi'
import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Btn from '../../../../UI/Btn'
import TextField from '../../../../UI/TextField'
import SelectField from '../../../../UI/SelectField'
import BaseModal from '../../../../UI/BaseModal/BaseModal'
import http from '../../../../shared/http'
import { types } from '../../../../shared/eventEmitter'
import { TApi, TLanguages, TRoles } from '../../../../shared/const'

import styles from './style.module.scss'
import { joiPhone } from '../../../../shared/helpers'
import DateTimeField from '../../../../UI/DateTimeField'

const schema = Joi.object({
	salutation: Joi.string().allow(''),
	firstName: Joi.string()
		.allow('')
		.min(2)
		.max(26)
		.regex(/^[a-zA-ZА-яЁёÄÖÜẞäöüß]+( [a-zA-ZА-яЁёÄÖÜẞäöüß]+)*$/)
		.allow('')
		.messages({
			'string.pattern.base': 'Only letters are allowed !'
		}),
	lastName: Joi.string()
		.min(2)
		.max(26)
		.regex(/^[a-zA-ZА-яЁёÄÖÜẞäöüß]+( [a-zA-ZА-яЁёÄÖÜẞäöüß]+)*$/)
		.allow('')
		.messages({
			'string.pattern.base': 'Only letters are allowed !'
		}),
	dateOfBirth: Joi.object().allow(null),
	phone: joiPhone.allow(''),
	street: Joi.string().min(2).max(50).allow(''),
	city: Joi.string().allow(''),
	houseNumber: Joi.number().allow(''),
	postalCode: Joi.number().allow(''),
	email: Joi.string().email({ tlds: false }).allow('')
})

function StudentProfile(props) {
	const { t, i18n } = useTranslation()
	const {
		reset,
		register,
		control,
		setValue,
		handleSubmit,
		trigger,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema)
	})

	const [isLoading, setLoading] = useState(false)
	const [id, setId] = useState(null)
	const [role, setRole] = useState('')
	const [funcUser, setFuncUser] = useState({ fetchUsers: () => {} })

	useEffect(() => {
		if (Object.keys(errors).length) trigger()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localStorage.getItem('i18nextLng')])

	const onSubmit = (data, closeModal) => {
		setLoading(true)

		const schema = {}

		if (data.salutation) schema.salutation = data.salutation
		if (data.firstName) schema.firstName = data.firstName
		if (data.lastName) schema.lastName = data.lastName
		if (data.dateOfBirth) schema.dateOfBirth = data.dateOfBirth
		if (data.phone) schema.phone = data.phone
		if (data.street) schema.street = data.street
		if (data.city) schema.city = data.city
		if (data.houseNumber) schema.houseNumber = data.houseNumber
		if (data.postalCode) schema.postalCode = data.postalCode

		http.put(TApi.ADMIN_PUT_USER(id), schema)
			.then(() => {
				toast.success(t('User profile has been updated.'))
				setId(null)
				funcUser.fetchUsers()
				closeModal()
			})
			.finally(() => setLoading(false))
	}

	const handleOpen = (profile, fetchUsers) => {
		setId(profile.id)
		setRole(profile.role)
		profile.salutation && setValue('salutation', profile.salutation)
		profile.firstName && setValue('firstName', profile.firstName)
		profile.lastName && setValue('lastName', profile.lastName)
		profile.dateOfBirth && setValue('dateOfBirth', new Date(profile.dateOfBirth))
		profile.phone && setValue('phone', profile.phone)
		profile.street && setValue('street', profile.street)
		profile.city && setValue('city', profile.city)
		profile.houseNumber && setValue('houseNumber', profile.houseNumber)
		profile.postalCode && setValue('postalCode', profile.postalCode)
		profile.email && setValue('email', profile.email)
		setFuncUser({ fetchUsers })
	}

	return (
		<BaseModal
			onClose={reset}
			onOpen={handleOpen}
			nameEvent={types.openStudentProfile}
			title={t('profile')}
			isLoading={isLoading}
			maxWidth={850}
		>
			{({ closeModal }) => (
				<form
					className={styles.root}
					onSubmit={handleSubmit(form =>
						onSubmit(form, () => {
							setId(null)
							closeModal()
						})
					)}
				>
					<SelectField
						disabled={isLoading || props.isTeacher}
						mb={20}
						label={t('salutation')}
						control={control}
						name="salutation"
						error={errors.salutation}
						placeholder="Salutation"
						options={props.salutations?.map(v => ({
							value: v.value_EN,
							label: i18n.language === TLanguages.EN ? v.value_EN : v.value_DE
						}))}
					/>
					<section className={styles.flexContainer}>
						<TextField
							disabled={isLoading || props.isTeacher}
							mb={20}
							label={t('First Name')}
							placeholder={t('First Name')}
							error={errors.firstName}
							register={register('firstName')}
						/>
						<TextField
							disabled={isLoading || props.isTeacher}
							mb={20}
							label={t('Last Name')}
							placeholder={t('Last Name')}
							error={errors.lastName}
							register={register('lastName')}
						/>
					</section>
					<section className={styles.flexContainer}>
						{role === TRoles.STUDENT && (
							<DateTimeField
								disabled={isLoading || props.isTeacher}
								mb={20}
								label={t('Date of Birth')}
								placeholder="Date of Birth"
								name="dateOfBirth"
								error={errors.dateOfBirth}
								control={control}
								dateFormat="dd.MM.yyyy"
							/>
						)}
						<TextField
							disabled={isLoading || props.isTeacher}
							mb={20}
							label={t('phone')}
							placeholder={t('phone')}
							error={errors.phone}
							register={register('phone')}
						/>
					</section>
					<TextField
						disabled
						mb={20}
						label={t('Your email')}
						placeholder="student@gmail.com"
						error={errors.email}
						register={register('email')}
					/>
					<section className={styles.flexContainer}>
						<TextField
							disabled={isLoading || props.isTeacher}
							mb={20}
							label={t('Street')}
							placeholder={t('Street')}
							error={errors.street}
							register={register('street')}
						/>
						<TextField
							disabled={isLoading || props.isTeacher}
							mb={20}
							label={t('City')}
							placeholder={t('City')}
							error={errors.city}
							register={register('city')}
						/>
					</section>
					<section className={styles.flexContainer}>
						<TextField
							disabled={isLoading || props.isTeacher}
							mb={20}
							label={t('House number')}
							placeholder={t('House number')}
							error={errors.houseNumber}
							register={register('houseNumber')}
						/>
						<TextField
							disabled={isLoading || props.isTeacher}
							mb={20}
							label={t('Zip Code')}
							placeholder={t('Zip Code')}
							error={errors.postalCode}
							register={register('postalCode')}
						/>
					</section>
					{!props.isTeacher && (
						<Btn
							loading={isLoading}
							width={155}
							className={styles.btnSubmit}
							type="submit"
							icon="send"
							iconSize={{ w: 15, h: 15 }}
							iconColor="#fff"
						>
							{t('save')}
						</Btn>
					)}
				</form>
			)}
		</BaseModal>
	)
}

StudentProfile.propTypes = {
	salutations: PropTypes.array.isRequired
}
const mapStateToProps = state => ({
	salutations: state.options.salutations,
	isTeacher: state.user.profile.role === TRoles.TEACHER
})
export default connect(mapStateToProps)(StudentProfile)
