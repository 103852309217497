import { useState, useEffect } from 'react'
import styles from './style.module.scss'
import http from '../../../../../shared/http'
import { TApi } from '../../../../../shared/const'
import MiniHeader from '../../../../../UI/MiniHeader'
import Expenditures from './Expenditures'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

export default function CompanyReportJuniorBasic(props) {
	const { t } = useTranslation()

	const [formData, setFormData] = useState(null)
	const [isLoading, setLoading] = useState(false)

	const fetchData = () => {
		setLoading(true)
		http.get(TApi.STUDENT_REPORTS)
			.then(({ data }) => {
				setFormData(data.SemiannualReport)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const updateData = async form => {
		setLoading(true)
		return http
			.put(TApi.STUDENT_REPORTS, { type: 'SEMIANNUAL', ...form })
			.then(({ data }) => {
				setFormData(data)
			})
			.finally(() => setLoading(false))
	}
	useEffect(() => {
		!props.data && fetchData()
		props.data && setFormData(props.data)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className={styles.root}>
			<MiniHeader title={t('Annual financial statements')} bg="#f8faff"></MiniHeader>
			<div className={'container'}>
				<Expenditures updateData={updateData} form={formData} isLoading={isLoading} />
			</div>
		</div>
	)
}
CompanyReportJuniorBasic.propTypes = {
	data: PropTypes.object
}
