import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import CompanyReview from '../../components/views/admin/CompanyReview'

import injectRole from '../../components/HOC/injectRole'

import { TRoles, TRoutes } from '../../shared/const'

function CompanyReviewPage(props) {
	if ([TRoles.ADMIN, TRoles.FEDERAL_ADMIN].includes(props.role)) return <CompanyReview />
	else return <Redirect to={TRoutes.DASHBOARD} />
}

CompanyReviewPage.propTypes = {
	role: PropTypes.string.isRequired
}

export default injectRole(CompanyReviewPage)
