import { Route, Switch, Redirect } from 'react-router-dom'

import PrivateRoute from './PrivateRoute'
import OnlyPublicRoute from './OnlyPublicRoute'

import NotFound from '../../pages/NotFound'

import { TRoutes } from '../../shared/const'

import Auth from '../../UI/layouts/Auth'
import Main from '../../UI/layouts/Main'
import CompanyPresentation from '../../UI/layouts/CompanyPresentation'

function Routes() {
	return (
		<Switch>
			<Route path={TRoutes.MAIN} component={CompanyPresentation} exact />
			<OnlyPublicRoute path={TRoutes.AUTH} except={[TRoutes.AUTH_CONFIRM, TRoutes.SIGN_UP]} component={Auth} />
			<PrivateRoute path={TRoutes.MAIN} component={Main} />

			<Route exact path={TRoutes.NOT_FOUND} component={NotFound} />
			<Redirect exact from="*" to={TRoutes.NOT_FOUND} />
		</Switch>
	)
}

export default Routes
