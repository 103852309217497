import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Step from '../ProgressBar/Step'
import ProgressBar from '../ProgressBar'

import styles from './style.module.scss'
import { TCompanies } from '../../shared/const'
import { format } from 'date-fns'

export default function ProgressBarPage({ values, isActivated, companyType, reportsDeadline, isNeedReview }) {
	const { t } = useTranslation()
	const titles = [t('Registration'), t('Approval'), t('BUSINESS ACTIVITIES'), t('reports')]

	const activations = [
		values.hello,
		values.employees || isActivated,
		values.experts || isActivated,
		values.riskAnalysis || isActivated,
		values.businessPlan || isActivated,
		isNeedReview || isActivated,
		isActivated,
		values.shares,
		companyType === TCompanies.JC
			? values.reports?.Semiannual && values.reports?.AnnualReport
			: values.reports?.Semiannual,
		companyType === TCompanies.JC
			? values.invoices?.Semiannual && values.invoices?.AnnualReport
			: values.invoices?.Semiannual,
		values.finalReport
	]

	return (
		<div className={styles.scrollWrapper}>
			<div className={styles.root}>
				<div className={styles.titles}>
					{titles.map((title, index) => {
						if (t('reports') !== title)
							return (
								<h1 key={index} className={styles.title}>
									{title}
								</h1>
							)

						return (
							<div>
								<h1 key={index} className={styles.reportTitle}>
									{title}
								</h1>
								<div className={styles.deadline}>
									{(companyType === 'JC' && (
										<>
											<div className={styles.deadlineText}>
												{t('Annual deadline')}:{' '}
												{reportsDeadline?.JC_annual &&
													format(new Date(reportsDeadline?.JC_annual), 'dd.MM.yyyy')}
											</div>
											<div className={styles.deadlineText}>
												{t('Semiannual deadline')}:{' '}
												{reportsDeadline?.JC_semiannual &&
													format(new Date(reportsDeadline?.JC_semiannual), 'dd.MM.yyyy')}
											</div>
										</>
									)) ||
										(companyType === 'JBC' && (
											<div className={styles.deadlineText}>
												{t('Deadline')}:{' '}
												{reportsDeadline?.JBC &&
													format(new Date(reportsDeadline?.JBC), 'dd.MM.yyyy')}
											</div>
										)) ||
										(companyType === 'JCC' && (
											<div className={styles.deadlineText}>
												{t('Deadline')}:{' '}
												{reportsDeadline?.JCC &&
													format(new Date(reportsDeadline?.JCC), 'dd.MM.yyyy')}
											</div>
										))}
								</div>
							</div>
						)
					})}
				</div>
				<ProgressBar
					fullFill={values.finalReport}
					currentStep={
						activations.slice(
							0,
							activations.findIndex(item => !!item !== true)
						).length
					}
				>
					<Step position={1} isActive={values.hello} title={t('Hello')} />
					<Step position={6} isActive={values.employees || isActivated} title={t('Add Employees')} />
					<Step position={12} isActive={values.experts || isActivated} title={t('Add Experts')} />
					<Step position={18} isActive={values.riskAnalysis || isActivated} title={t('Risk Analysis')} />
					<Step position={24} isActive={values.businessPlan} title={t('Business Plan')} />
					<Step position={35} isActive={isNeedReview || isActivated} title={t('Check')} />
					<Step position={48} isActive={isActivated} title={t('Approved')} />
					<Step position={60} isActive={values.shares} title={t('Create Share')} />
					<Step
						position={70}
						isActive={
							companyType === TCompanies.JC
								? values.reports?.Semiannual && values.reports?.AnnualReport
								: values.reports?.Semiannual
						}
						title={t('Semester Report')}
						items={
							companyType === TCompanies.JC
								? [{ value: t('Semi-annual Report') }, { value: t('Annual Report') }]
								: null
						}
					/>
					<Step
						position={80}
						isActive={
							companyType === TCompanies.JC
								? values.invoices?.Semiannual && values.invoices?.AnnualReport
								: values.invoices?.Semiannual
						}
						title={t('Pay Invoice')}
						items={
							companyType === TCompanies.JC
								? [{ value: t('Semi-annual Invoice') }, { value: t('Annual Invoice') }]
								: null
						}
					/>
					<Step position={95} isActive={values.finalReport} title={t('Business Report')} />
				</ProgressBar>
			</div>
		</div>
	)
}
ProgressBarPage.propTypes = {
	isActivated: PropTypes.bool.isRequired,
	values: PropTypes.object.isRequired,
	companyType: PropTypes.string.isRequired,
	isNeedReview: PropTypes.bool.isRequired,
	reportsDeadline: PropTypes.object.isRequired
}
