import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import Btn from '../../../../../../UI/Btn'
import styles from '../style.module.scss'
import Joi from 'joi'
import { joiResolver } from '@hookform/resolvers/joi'
import { useTranslation } from 'react-i18next'
import TextFieldNumber from '../../../../../../UI/TextFieldNumber'

const schema = Joi.object({
	juniorInsurance: Joi.object().keys({
		JC: Joi.number().required(),
		JCC: Joi.number().required(),
		JBC: Joi.number().required()
	})
})

export default function JuniorInsurance(props) {
	const { t } = useTranslation()
	const {
		control,
		reset,
		handleSubmit,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema)
	})

	const onSubmit = form => {
		props
			.updateOptions({ name: 'juniorInsurance', value: form.juniorInsurance })
			.then(() => toast.success(t('Options was updated')))
	}

	useEffect(() => {
		reset({ juniorInsurance: props.form?.juniorInsurance || {} })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.form])
	return (
		<form id="juniorInsurance" className={styles.root} onSubmit={handleSubmit(onSubmit)}>
			<section className={styles.section}>
				<h3 className={styles.sectionTitle}>{t('Junior Insurance')}</h3>
				<div className={styles.sectionContent}>
					<div className={styles.miniSection}>
						<div className={styles.flexContainer}>
							<TextFieldNumber
								placeholder={''}
								className={styles.field}
								label={`Junior ${t('Insurance')}`}
								mb={20}
								error={errors.juniorInsurance?.JC}
								control={control}
								name={`juniorInsurance.JC`}
							/>
						</div>
					</div>
					<div className={styles.miniSection}>
						<div className={styles.flexContainer}>
							<TextFieldNumber
								placeholder={''}
								className={styles.field}
								label={`Junior Compact ${t('Insurance')}`}
								mb={20}
								error={errors.juniorInsurance?.JCC}
								control={control}
								name={`juniorInsurance.JCC`}
							/>
						</div>
					</div>
					<div className={styles.flexContainer}>
						<TextFieldNumber
							placeholder={''}
							className={styles.field}
							label={`Junior Basic ${t('Insurance')}`}
							mb={20}
							error={errors.juniorInsurance?.JBC}
							control={control}
							name={`juniorInsurance.JBC`}
						/>
					</div>
					<div className={styles.btnContainer}>
						<Btn
							className={styles.btn}
							type="submit"
							icon="arrow"
							iconSize={{ w: 10, h: 10 }}
							iconColor="#fff"
						>
							{t('save')}
						</Btn>
					</div>
				</div>
			</section>
		</form>
	)
}
JuniorInsurance.propTypes = {
	form: PropTypes.object.isRequired,
	updateOptions: PropTypes.func.isRequired
}
