import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

import CompanyEmployee from '../../components/views/student/CompanyEmployee'

import injectRole from '../../components/HOC/injectRole'

import { TRoles, TRoutes } from '../../shared/const'

function CompanyEmployeePage(props) {
	if (props.role === TRoles.STUDENT) return <CompanyEmployee />
	else return <Redirect to={TRoutes.DASHBOARD} />
}

CompanyEmployeePage.propTypes = {
	role: PropTypes.string.isRequired
}

export default injectRole(CompanyEmployeePage)
