import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { Redirect, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import JuniorCompanyFirstReporting from '../../components/views/student/CompanyReports/JuniorCompany/JuniorCompanyFirstReporting'
import JuniorCompanySecondReporting from '../../components/views/student/CompanyReports/JuniorCompany/JuniorCompanySecondReporting'

import ReportCompact from '../../components/views/student/CompanyReports/JuniorCompactCompany'

import JuniorBasicCompany from '../../components/views/student/CompanyReports/JuniorBasicCompany'

import { TRoles, TRoutes } from '../../shared/const'

import Alert from '../../UI/Alert'

function Reports(props) {
	const { t } = useTranslation()
	const location = useLocation()
	const { type } = queryString.parse(location.search)

	if (props.role !== TRoles.STUDENT) return <Redirect to={TRoutes.DASHBOARD} />

	if (!props.isActivatedCompany)
		return (
			<div style={{ padding: 20, width: '100%' }}>
				<Alert style={{ maxWidth: '100%' }}>
					{t('You need to activate the company to unlock this feature')}
				</Alert>
			</div>
		)

	switch (props.reportType) {
		case 'JC':
			if (props.reportPeriod?.SEMIANNUAL && type === 'annual') {
				return <JuniorCompanySecondReporting />
			}
			if (props.reportPeriod?.SEMIANNUAL && type === 'semiannual') {
				return <JuniorCompanyFirstReporting />
			}
			return <JuniorCompanyFirstReporting />

		case 'JCC':
			return <ReportCompact />

		case 'JBC':
			return <JuniorBasicCompany />
		default:
			return <Redirect to={TRoutes.DASHBOARD} />
	}
}
Reports.propTypes = {
	role: PropTypes.string.isRequired,
	isActivatedCompany: PropTypes.bool.isRequired,
	reportType: PropTypes.string.isRequired,
	reportPeriod: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
	role: state.user.role,
	isActivatedCompany: !!state.user.profile.EmploymentCompany?.isActive,
	reportType: state.user.profile.EmploymentCompany?.companyType,
	reportPeriod: state.user.profile.reportPeriod
})

export default connect(mapStateToProps)(Reports)
