import PropTypes from 'prop-types'
import { Redirect, useParams } from 'react-router-dom'

import injectRole from '../../../components/HOC/injectRole'

import { TApi, TCompanies, TReports, TRoles, TRoutes } from '../../../shared/const'
import http from '../../../shared/http'
import JuniorCompanyFirstReporting from '../../../components/views/student/CompanyReports/JuniorCompany/JuniorCompanyFirstReporting'
import JuniorCompanySecondReporting from '../../../components/views/student/CompanyReports/JuniorCompany/JuniorCompanySecondReporting'
import ReportCompact from '../../../components/views/student/CompanyReports/JuniorCompactCompany'
import CompanyReportJuniorBasic from '../../../components/views/student/CompanyReports/JuniorBasicCompany'
import { useEffect, useState } from 'react'
import Loading from '../../../UI/Loading'

function ReportReview(props) {
	const { id } = useParams()
	const [report, setData] = useState({})

	const getReports = () => {
		if (props.role === TRoles.TEACHER) return TApi.TEACHER_GET_REPORT(id)
		if (props.role === TRoles.ADMIN || props.role === TRoles.FEDERAL_ADMIN) return TApi.ADMIN_GET_REPORT(id)
	}

	useEffect(() => {
		http.get(getReports(id)).then(({ data }) => setData(data))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if ([TRoles.ADMIN, TRoles.FEDERAL_ADMIN, TRoles.TEACHER].includes(props.role) && !isNaN(Number(id))) {
		switch (report.Company?.companyType) {
			case TCompanies.JC:
				return report.type === TReports.SEMIANNUAL ? (
					<JuniorCompanyFirstReporting data={report} />
				) : (
					<JuniorCompanySecondReporting data={report} />
				)
			case TCompanies.JCC:
				return <ReportCompact data={report} />
			case TCompanies.JBC:
				return <CompanyReportJuniorBasic data={report} />
			default:
				return <Loading />
		}
	} else return <Redirect to={TRoutes.DASHBOARD} />
}

ReportReview.propTypes = {
	role: PropTypes.string.isRequired
}

export default injectRole(ReportReview)
