import styles from '../style.module.scss'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import Joi from 'joi'
import { joiResolver } from '@hookform/resolvers/joi'
import { schemaOptions } from '../../../../../../shared/i18n'
import Btn from '../../../../../../UI/Btn'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { optionsDateWhioutTime } from '../../../../../../shared/const'
import TextFieldNumber from '../../../../../../UI/TextFieldNumber'
import { useEffect } from 'react'

function Expenditures(props) {
	const { t } = useTranslation()

	const schema = Joi.object({
		totalGrossRevenue: Joi.number().required().messages({
			'number.base': `must be a number`,
			'any.required': `is a required field`
		}),
		salaryExpenses: Joi.number().required().messages({
			'number.base': `must be a number`,
			'any.required': `is a required field`
		}),
		materialExpenses: Joi.number().required().messages({
			'number.base': `must be a number`,
			'any.required': `is a required field`
		}),
		otherExpenses: Joi.number().required().messages({
			'number.base': `must be a number`,
			'any.required': `is a required field`
		}),
		totalExpenses: Joi.number()
			.custom((value, helper) => {
				const { salaryExpenses, materialExpenses, otherExpenses } = helper.state.ancestors[0]
				const calculations = salaryExpenses + materialExpenses + otherExpenses
				return Math.round(calculations * 100) / 100 === value ? value : helper.message(t('Wrong result'))
			})
			.messages({
				'number.base': `must be a number`,
				'any.required': `is a required field`
			})
			.required(),
		profitLoss: Joi.number()
			.custom((value, helper) => {
				const { totalGrossRevenue, totalExpenses } = helper.state.ancestors[0]
				const calculations = totalGrossRevenue - totalExpenses

				return Math.round(calculations * 100) / 100 === value ? value : helper.message(t('Wrong result'))
			})
			.messages({
				'number.base': `must be a number`,
				'any.required': `is a required field`
			})
			.required(),
		juniorInsurance: Joi.number(),
		netProfitLoss: Joi.number()
			.custom((value, helper) => {
				const { profitLoss, juniorInsurance } = helper.state.ancestors[0]
				const calculations = profitLoss - juniorInsurance

				return Math.round(calculations * 100) / 100 === value ? value : helper.message(t('Wrong result'))
			})
			.messages({
				'number.base': `must be a number`,
				'any.required': `is a required field`
			})
			.required()
	})

	const {
		control,
		reset,
		handleSubmit,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema, {
			errors: {
				labels: false,
				language: localStorage.getItem('i18nextLng')
			},
			...schemaOptions
		})
	})

	const onSubmit = form => {
		let data = form

		const taxesTransferredToJuniorAustria = data.juniorInsurance
		delete data.juniorInsurance

		props
			.updateData({
				...data,
				isSubmitted: true,
				annualReportDate: new Date().toISOString(),
				taxesTransferredToJuniorAustria
			})
			.then(() => {
				toast.success(t('Profit/Loss was submitted'))
				toast.success(t('Invoice created, you can find it in documents'))
			})
	}
	useEffect(() => {
		reset({
			totalGrossRevenue: props.form?.totalGrossRevenue,
			salaryExpenses: props.form?.salaryExpenses,
			materialExpenses: props.form?.materialExpenses,
			otherExpenses: props.form?.otherExpenses,
			totalExpenses: props.form?.totalExpenses,
			profitLoss: props.form?.profitLoss,
			juniorInsurance: props.juniorInsurance,
			netProfitLoss: props.form?.netProfitLoss
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.form])

	return (
		<form className={styles.root} onSubmit={handleSubmit(onSubmit)}>
			<section className={styles.section} style={{ padding: '25px 0' }}>
				<div className={styles.sectionTitle} />
				<div className={styles.sectionContent}>
					<p className={styles.deadline}>{`${t('Deadline by')} ${new Date(
						props.reportsDeadline?.JBC
					).toLocaleDateString('de-DE', optionsDateWhioutTime)}`}</p>
					<p className={styles.title}>
						{t(
							'To successfully complete the Junior Basic Company the following final accounting must be done.'
						)}
					</p>
				</div>
			</section>
			<section className={styles.section}>
				<h1 className={styles.sectionTitle}>{t('Revenue')}</h1>
				<div className={styles.sectionContent}>
					<div className={styles.flexContainer}>
						<TextFieldNumber
							label={t('Total gross revenue')}
							className={styles.field}
							name="totalGrossRevenue"
							control={control}
							error={errors.totalGrossRevenue}
							disabled={props.isLoading || props.form?.isSubmitted}
							placeholder="00.000,00"
						/>

						<p className={styles.noteTitle}>
							<p> {t('Total gross revenue')}</p>
							<span>
								{t(
									'All income from your sales WITHOUT income from the sale of Junior Basic Company shares.'
								)}
							</span>
						</p>
					</div>
				</div>
			</section>
			<section className={styles.section}>
				<h1 className={styles.sectionTitle}>{t('Expenses')}</h1>
				<div className={styles.sectionContent}>
					<div className={styles.flexContainer}>
						<TextFieldNumber
							label={t('Total salary expenses')}
							className={styles.field}
							name="salaryExpenses"
							control={control}
							error={errors.salaryExpenses}
							disabled={props.isLoading || props.form?.isSubmitted}
							placeholder="00.000,00"
						/>

						<p className={styles.noteTitle}>
							<p> {t('Total salary expenses')}</p>
							<span>{t('All expenditures for wages of employees of your Junior Basic Company.')}</span>
						</p>
					</div>
					<div className={styles.flexContainer}>
						<TextFieldNumber
							label={`+ ${t('Material expenses')}`}
							className={styles.field}
							name="materialExpenses"
							control={control}
							error={errors.materialExpenses}
							disabled={props.isLoading || props.form?.isSubmitted}
							placeholder="00.000,00"
						/>

						<p className={styles.noteTitle}>
							<p> {t('Material expenses')}</p>
							<span>{t('All expenses for the materials you needed to make your products.')}</span>
						</p>
					</div>
					<div className={styles.flexContainer}>
						<TextFieldNumber
							label={`+ ${t('Other expenses')}`}
							className={styles.field}
							name="otherExpenses"
							control={control}
							error={errors.otherExpenses}
							disabled={props.isLoading || props.form?.isSubmitted}
							placeholder="00.000,00"
						/>

						<p className={styles.noteTitle}>
							<p> {t('Other expenses')}</p>
							<span>
								{t(
									'Other expenses such as transportation or travel expenses, company Christmas party, etc.'
								)}
							</span>
						</p>
					</div>

					<TextFieldNumber
						label={`= ${t('Total gross expenses')}`}
						className={styles.field}
						name="totalExpenses"
						control={control}
						error={errors.totalExpenses}
						disabled={props.isLoading || props.form?.isSubmitted}
						placeholder="00.000,00"
					/>
					<div className={styles.flexContainer}>
						<TextFieldNumber
							label={t('Profit/Loss')}
							className={styles.field}
							name="profitLoss"
							control={control}
							error={errors.profitLoss}
							disabled={props.isLoading || props.form?.isSubmitted}
							placeholder="00.000,00"
						/>

						<p className={styles.noteTitle}>
							<p> {t('Profit/Loss')}</p>
							<span>
								{t(
									'Your income minus expenses result in the profit or loss. Important a loss is written as a minus number!'
								)}
							</span>
						</p>
					</div>
					<div className={styles.flexContainer}>
						<TextFieldNumber
							label={`- ${t('insurance contribution')}`}
							className={styles.field}
							name="juniorInsurance"
							control={control}
							error={errors.juniorInsurance}
							disabled
							placeholder="00.000,00"
						/>
						<p className={styles.noteTitle}>
							<p> {t('insurance contribution')}</p>
							<span>{t('Insurance premium for all activities of your Junior Company.')}</span>
						</p>
					</div>
					<div className={styles.flexContainer}>
						<TextFieldNumber
							label={`= ${t('Net profit/loss')}`}
							className={styles.field}
							name="netProfitLoss"
							control={control}
							error={errors.netProfitLoss}
							disabled={props.isLoading || props.form?.isSubmitted}
							placeholder="00.000,00"
						/>

						<p className={styles.noteTitle}>
							<p> {t('Net profit/loss')}</p>
							<span>{t('Profit/loss minus insurance contribution gives the net profit/loss.')} </span>
						</p>
					</div>
					{!props.form?.isSubmitted && !props.isTeacherStudentView && (
						<Btn
							disabled={props.form?.isSubmitted}
							className={styles.btn}
							width={337}
							loading={props.isLoading}
							type="submit"
						>
							{t('Submit')}
						</Btn>
					)}
				</div>
			</section>
		</form>
	)
}

Expenditures.propTypes = {
	form: PropTypes.object,
	isLoading: PropTypes.bool.isRequired,
	juniorInsurance: PropTypes.number.isRequired,
	reportsDeadline: PropTypes.object.isRequired,
	isTeacherStudentView: PropTypes.bool.isRequired
}
const mapStateToProps = state => ({
	juniorInsurance: state.options.juniorInsurance?.JBC,
	reportsDeadline: state.options.reportsDeadline,
	isTeacherStudentView: state.user.isTeacherStudentView
})
export default connect(mapStateToProps, null)(Expenditures)
