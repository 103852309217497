import styles from './style.module.scss'

import { useEffect, useState } from 'react'
import { TApi } from '../../../shared/const'
import http from '../../../shared/http'
import eventEmitter, { types } from '../../../shared/eventEmitter'
import { customNumberFormatW } from '../../../shared/helpers'

const title = {
	STUDENTS: 'Students',
	TEACHERS: 'Teachers',
	COMPANIES: 'Companies'
}

export default function FourSectionsWidget(props) {
	const [widgetDynamicData, setWidgetDynamicData] = useState({})

	const fetchWidgetData = () => {
		http.get(TApi.ADMIN_WIDGET_GET_MULTI).then(({ data }) => {
			setWidgetDynamicData({ ...data.data })
		})
	}

	const currentOption = props.settings ? props.settings : {}

	useEffect(() => {
		fetchWidgetData()
	}, [])

	const handleOptionsClick = ({ currentOption }) => {
		eventEmitter.emit(types.openCustomizeMultiModal, currentOption, props.id, props.availableWidgets)
	}

	return (
		<div className={styles.root}>
			<div className={styles.firstRow}>
				<div className={styles.firstCol}>
					{currentOption.firstSlot ? (
						<>
							{widgetDynamicData[currentOption.firstSlot] && (
								<div className={styles.notificationCircle}>
									+{customNumberFormatW(widgetDynamicData[currentOption.firstSlot].new)}
								</div>
							)}
							<h2 className={styles.mainNumber}>
								{widgetDynamicData[currentOption.firstSlot] &&
									customNumberFormatW(widgetDynamicData[currentOption.firstSlot].total)}
							</h2>
							<p className={styles.text}>{title[currentOption.firstSlot]}</p>
						</>
					) : (
						<h2 onClick={() => handleOptionsClick({ currentOption })} className={styles.plus}>
							+
						</h2>
					)}
				</div>
				<div className={styles.secondCol}>
					{currentOption.secondSlot ? (
						<>
							{widgetDynamicData[currentOption.secondSlot] && (
								<div className={styles.notificationCircle}>
									+{customNumberFormatW(widgetDynamicData[currentOption.secondSlot].new)}
								</div>
							)}
							<h2 className={styles.mainNumber}>
								{widgetDynamicData[currentOption.secondSlot] &&
									customNumberFormatW(widgetDynamicData[currentOption.secondSlot].total)}
							</h2>
							<p className={styles.text}>{title[currentOption.secondSlot]}</p>
						</>
					) : (
						<h2 onClick={() => handleOptionsClick({ currentOption })} className={styles.plus}>
							+
						</h2>
					)}
				</div>
			</div>
			<div className={styles.secondRow}>
				<div className={styles.firstCol}>
					{currentOption.thirdSlot ? (
						<>
							{widgetDynamicData[currentOption.thirdSlot] && (
								<div className={styles.notificationCircle}>
									+{customNumberFormatW(widgetDynamicData[currentOption.thirdSlot].new)}
								</div>
							)}
							<h2 className={styles.mainNumber}>
								{widgetDynamicData[currentOption.thirdSlot] &&
									customNumberFormatW(widgetDynamicData[currentOption.thirdSlot].total)}
							</h2>
							<p className={styles.text}>{title[currentOption.thirdSlot]}</p>
						</>
					) : (
						<h2 onClick={() => handleOptionsClick({ currentOption })} className={styles.plus}>
							+
						</h2>
					)}
				</div>
				<div className={styles.secondCol}>
					{currentOption.fourthSlot ? (
						<>
							{widgetDynamicData[currentOption.fourthSlot] && (
								<div className={styles.notificationCircle}>
									+{customNumberFormatW(widgetDynamicData[currentOption.fourthSlot].new)}
								</div>
							)}
							<h2 className={styles.mainNumber}>
								{widgetDynamicData[currentOption.fourthSlot] &&
									customNumberFormatW(widgetDynamicData[currentOption.fourthSlot].total)}
							</h2>
							<p className={styles.text}>{title[currentOption.fourthSlot]}</p>
						</>
					) : (
						<h2 onClick={() => handleOptionsClick({ currentOption })} className={styles.plus}>
							+
						</h2>
					)}
				</div>
			</div>
		</div>
	)
}
