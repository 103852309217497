import BaseModal from '../../../../UI/BaseModal/BaseModal'
import { types } from '../../../../shared/eventEmitter'
import styles from './style.module.scss'
import { useForm } from 'react-hook-form'
import TextField from '../../../../UI/TextField'
import { useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Hr from '../../../../UI/Hr'

function CompanyRisk(props) {
	const { t } = useTranslation()
	const { reset } = useForm()

	const [targetGroup, setTargetGroup] = useState(null)
	const [riskStrategy, setRiskStrategy] = useState([])
	const [salesForecast, setSalesForecast] = useState([])
	const [productUsage, setProductUsage] = useState(null)
	const [customer, setCustomer] = useState(null)
	const [totalAmoutOfSales, setTotalAmoutOfSales] = useState(0)
	// eslint-disable-next-line no-unused-vars
	const [onDone, setOnDone] = useState(null)

	const handleOpen = (riskAnalysis = null, onDone = null) => {
		setOnDone(() => onDone)

		if (!riskAnalysis) return
		setTargetGroup(riskAnalysis.targetGroup)
		setProductUsage(riskAnalysis.productUsage)
		setCustomer(riskAnalysis.customer)
		setRiskStrategy(riskAnalysis.riskStrategy)
		setSalesForecast(riskAnalysis.salesForecast)
		setTotalAmoutOfSales(riskAnalysis.totalAmoutOfSales)
	}

	const handleClose = () => {
		reset()
		setTargetGroup(null)
	}

	return (
		<BaseModal
			onClose={handleClose}
			onOpen={handleOpen}
			nameEvent={types.openCompanyRisk}
			title={t('Market and Riskanalysis')}
			maxWidth={890}
		>
			{() => (
				<div className={styles.main}>
					<div className={styles.businessActivity}>
						<div className={styles.checkboxContainer}>{t('Market Description')}</div>
						<div className={styles.businessActivityMain}>
							<div className={styles.readOnlyInput}>
								<h3 className={styles.readOnlyLabel}>{t('Target group(s)')}</h3>
								<div className={styles.readOnlyTextArea}> {targetGroup}</div>
							</div>
							<div className={styles.readOnlyInput}>
								<h3 className={styles.readOnlyLabel}>{t('Product usage')}</h3>
								<div className={styles.readOnlyTextArea}>{productUsage}</div>
							</div>
							<div className={styles.readOnlyInput}>
								<h3 className={styles.readOnlyLabel}>{t('Customer contact, sales strategy')}</h3>
								<div className={styles.readOnlyTextArea}>{customer}</div>
							</div>
						</div>
					</div>
					<div className={styles.businessActivity}>
						<div className={styles.checkboxContainer}>{t('Sales forecast')}</div>
						<div>
							{salesForecast.map((item, index) => {
								return (
									<div className={styles.sectionWrapper}>
										<div className={styles.flexWrapp}>
											<TextField
												value={item?.product}
												readOnly
												className={styles.field}
												label={t('Product / Service')}
												placeholder={t('Product name')}
											/>
											<TextField
												readOnly
												value={item?.price}
												className={styles.field}
												label={t('Price (€) / unit')}
												placeholder="€ 0.00"
											/>
										</div>
										<div className={styles.flexWrapp}>
											<TextField
												value={item?.expectedSales}
												readOnly
												className={styles.field}
												label={t('Expected sales (number)')}
												placeholder="0"
											/>
											<TextField
												className={styles.field}
												readOnly
												value={item?.expectedTurnover}
												label={t('Expected turnover (€)')}
												placeholder="€ 0.00"
											/>
										</div>
										<Hr />
									</div>
								)
							})}
						</div>
					</div>
					<div className={styles.totalAmount}>
						<TextField
							readOnly
							value={totalAmoutOfSales}
							mb={36}
							label={t('The total amount of estimated annual sales is €.')}
							placeholder="€ 0.00"
						/>
					</div>
					{riskStrategy.map((item, index) => {
						return (
							<div className={styles.businessActivity}>
								<div className={styles.checkboxContainer}>{t('Risk and strategy')}</div>
								<div className={styles.businessActivityMain}>
									<div className={styles.readOnlyInput}>
										<h3 className={styles.readOnlyLabel}>{t('Risk')}</h3>
										<div className={styles.readOnlyTextArea}> {item?.risk}</div>
									</div>
									<div className={styles.readOnlyInput}>
										<h3 className={styles.readOnlyLabel}>{t('Avoidance strategy')}</h3>
										<div className={styles.readOnlyTextArea}>{item?.strategy}</div>
									</div>
								</div>
							</div>
						)
					})}
				</div>
			)}
		</BaseModal>
	)
}

const mapStateToProps = state => ({
	role: state.user.role
})

export default connect(mapStateToProps)(CompanyRisk)
