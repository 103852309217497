import Joi from 'joi'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { joiResolver } from '@hookform/resolvers/joi'
import imageWarning from '../../../../../assets/images/warning.png'

import Btn from '../../../../../UI/Btn'
import Uploader from '../../../../../UI/Uploader'

import http from '../../../../../shared/http'
import { joiFile } from '../../../../../shared/helpers'
import { schemaOptions } from '../../../../../shared/i18n'
import { DOCUMENT_TYPES, TApi } from '../../../../../shared/const'

import styles from '../style.module.scss'
import { addParentalConfirmation } from '../../../../../redux/ducks/user'
/* import { ReactComponent as RoundWarningIcon } from '../../../../../assets/icons/round-warning.svg' */
import { updateProfile } from '../../../../../redux/ducks/user'

const schema = Joi.object({
	parentalConfirmation: joiFile(10)
})

function ParentalConfirmation(props) {
	const { t } = useTranslation()

	const {
		register,
		handleSubmit,
		watch,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema, {
			errors: {
				labels: false,
				language: localStorage.getItem('i18nextLng')
			},
			...schemaOptions
		})
	})

	const [parentalConfirmationFileName, setParentalConfirmationFileName] = useState('')
	const [isLoading, setLoading] = useState(false)

	const [parentalConfirmationField] = watch(['parentalConfirmation'])

	useEffect(() => {
		const file = parentalConfirmationField && parentalConfirmationField[0]
		if (!file) return

		setParentalConfirmationFileName(file.name)
	}, [parentalConfirmationField])

	useEffect(() => {
		setParentalConfirmationFileName(props.parentalConfirmationFileName)
	}, [props.parentalConfirmationFileName])

	useEffect(() => {
		if (errors.parentalConfirmation) {
			setParentalConfirmationFileName(props.parentalConfirmationFileName)
		}
	}, [props.parentalConfirmationFileName, errors.parentalConfirmation])

	const handleChange = fields => {
		const formData = new FormData()
		if (fields.parentalConfirmation && fields.parentalConfirmation[0])
			formData.append('parentalConfirmation', fields.parentalConfirmation[0])

		http.put(TApi.STUDENT_PARENTAL_CONFIRMATION, formData).then(({ data }) => {
			toast.success(t('Parental confirmation updated'))
			props.addParentalConfirmation(data)
			props.updateProfile({ rejectedParentalConfirmation: false })
		})
	}

	const downoload = () => {
		setLoading(true)
		http.get(TApi.STUDENT_PARENTAL_CONFIRMATION, {
			headers: {
				'Content-Type': 'application/pdf,image/jpeg,image/png,image/gif'
			},
			responseType: 'blob'
		})
			.then(({ data }) => {
				const file = new Blob([data], { type: data.type })
				const fileURL = URL.createObjectURL(file)
				window.open(fileURL)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const parentalTemplate = () => {
		setLoading(true)
		http.get(TApi.STUDENT_PARENTAL_CONFIRMATION_TEMPLATE, {
			headers: {
				'Content-Type': 'application/pdf,image/jpeg,image/png,image/gif'
			},
			responseType: 'blob'
		})
			.then(({ data }) => {
				const file = new Blob([data], { type: data.type })
				const fileURL = URL.createObjectURL(file)
				window.open(fileURL)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	return (
		<form className={styles.root}>
			<div className={styles.section}>
				<div className={styles.parentalTitle}>
					<h1 className={styles.parentalTitleText}>{t('Consent of parent or guardian')}</h1>
					{(!props.isParentalConfirmation || props.rejectedParentalConfirmation) && (
						<img
							src={imageWarning}
							className={styles.warningParentalConfirmation}
							data-tip={
								props.rejectedParentalConfirmation
									? t('Parental confirmation was rejected, upload new please !')
									: t('Need to upload parental confirmation')
							}
							alt=""
						/>
					)}
				</div>
				<div className={styles.sectionContent}>
					<div className={styles.flexContainer}>
						<div className={styles.parentalConfirmation}>
							<Uploader
								className={styles.field}
								classNameLabel={
									props.isActiveCompany || props.isTeacherStudentView ? styles.disabledLabel : ''
								}
								disabled={props.isActiveCompany || props.isTeacherStudentView}
								onChange={handleSubmit(handleChange)}
								error={errors.parentalConfirmation}
								accept="application/pdf,image/jpeg,image/png,image/gif"
								nameFile={parentalConfirmationFileName}
								title={t('Parental Confirmation')}
								register={register('parentalConfirmation')}
							/>
							<Btn
								className={styles.btnDownload}
								disabled={!parentalConfirmationFileName}
								theme="white"
								icon="download"
								iconSize={{ w: 24, h: 24 }}
								onClick={() => downoload()}
							/>
						</div>
						{!props.isTeacherStudentView && (
							<div className={styles.btnContainer}>
								<Btn
									loading={isLoading}
									className={styles.btnWithoutIcon}
									onClick={() => parentalTemplate()}
								>
									{t('Download template')}
								</Btn>
							</div>
						)}
					</div>
				</div>
			</div>
		</form>
	)
}

ParentalConfirmation.propTypes = {
	parentalConfirmationFileName: PropTypes.string.isRequired,
	isActiveCompany: PropTypes.bool.isRequired,
	isParentalConfirmation: PropTypes.bool.isRequired,
	isTeacherStudentView: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
	isActiveCompany: state.user.profile.EmploymentCompany?.isActive,
	parentalConfirmationFileName:
		state.user.profile.Documents &&
		state.user.profile.Documents.find(({ type }) => type === DOCUMENT_TYPES.PARENTAL_CONFIRMATION)?.originalName,
	isParentalConfirmation: state.user.profile.Documents?.find(
		({ type }) => type === DOCUMENT_TYPES.PARENTAL_CONFIRMATION
	),
	rejectedParentalConfirmation: state.user.profile.rejectedParentalConfirmation
})

const mapDispatchToProps = dispatch => ({
	addParentalConfirmation: data => dispatch(addParentalConfirmation(data)),
	updateProfile: data => dispatch(updateProfile(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(ParentalConfirmation)
