import clsx from 'clsx'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import { Controller } from 'react-hook-form'

import styles from './style.module.scss'

export default function DateTimeField(props) {
	const handleDateChangeRaw = e => {
		e.preventDefault()
	}
	return (
		<label style={props.mb ? { marginBottom: props.mb } : {}} className={clsx(styles.root, props.className)}>
			{props.error ? (
				<span className={styles.error}>{props.error.message}</span>
			) : (
				<span className={styles.inputName}>
					{props.label} {props.required ? <span className={styles.require}>*</span> : ''}
				</span>
			)}
			<Controller
				name={props.name}
				control={props.control}
				render={({ field }) => (
					<DatePicker
						{...field}
						showYearDropdown
						showMonthDropdown
						yearDropdownItemNumber={14}
						onChangeRaw={handleDateChangeRaw}
						popperClassName={styles.popperStyles}
						wrapperClassName={styles.datePicker}
						disabled={props.disabled}
						autoComplete="off"
						selected={field.value}
						isClearable={!props.disabled}
						onChange={date => field.onChange(date)}
						showTimeSelect={props.showTimeSelect}
						placeholderText={props.placeholder}
						dateFormat={props.dateFormat}
						minDate={props.minDate}
						maxDate={props.maxDate}
					/>
				)}
			/>
		</label>
	)
}

DateTimeField.propTypes = {
	className: PropTypes.string,
	label: PropTypes.string.isRequired,
	error: PropTypes.object,
	control: PropTypes.object.isRequired,
	name: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	dateFormat: PropTypes.string,
	defaultValue: PropTypes.object,
	mb: PropTypes.number,
	disabled: PropTypes.bool,
	showTimeSelect: PropTypes.bool,
	minDate: PropTypes.object,
	maxDate: PropTypes.object,
	required: PropTypes.bool
}

DateTimeField.defaultProps = {
	dateFormat: 'dd.MM.yyyy',

	disabled: false
}
