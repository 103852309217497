import { useMemo } from 'react'
import Btn from '../../../../UI/Btn'
import Table from '../../../../UI/Table'
import MiniHeader from '../../../../UI/MiniHeader'
import { useTranslation } from 'react-i18next'

import styles from './style.module.scss'
import BtnOption from '../../../../UI/BtnOption'
import messageICon from '../../../../assets/icons/Messages.svg'
import { useHistory } from 'react-router-dom'
import { TRoutes } from '../../../../shared/const'

export default function EmailTemplates() {
	const { t } = useTranslation()
	const history = useHistory()

	const data = useMemo(
		() =>
			new Array(12).fill({
				templateName: 'Confirmation Register company',
				modifiedDate: '27/08/2021'
			}),
		[]
	)

	const columns = useMemo(
		() => [
			{
				Header: t('TEMPLATE NAME'),
				accessor: 'templateName',
				Cell: ({ row }) => {
					return (
						<span
							style={{
								display: 'flex',
								alignItems: 'center',
								minWidth: '400px'
							}}
						>
							<img
								src={messageICon}
								alt="Message Icon"
								style={{ marginRight: 17, width: '24px', height: '24px' }}
							/>
							<span>{row.values.templateName}</span>
						</span>
					)
				}
			},
			{
				Header: t('MODIFIED DATE'),
				accessor: 'modifiedDate',
				Cell: ({ row }) => {
					return (
						<div className={styles.date}>
							<p>{row.values.modifiedDate}</p>
							<Btn icon="send" theme="download" iconColor="white" iconSize={{ w: 20, h: 20 }} />
							<BtnOption />
						</div>
					)
				}
			}
		],
		[t]
	)
	return (
		<div className={styles.root}>
			<MiniHeader title={t('Email Templates')} />
			<div className={styles.subHeader}>
				<p className={styles.userCounter}>16 {t('Templates')}</p>
				<Btn
					width={168}
					onClick={() => history.push(TRoutes.NEW_EMAIL_TEMPLATE)}
					iconSize={{ w: 10, h: 10 }}
					icon="arrow"
					iconColor="white"
					theme="orange"
				>
					{t('Create Template')}
				</Btn>
			</div>
			<div className="container">
				<Table items={data} columns={columns} />
			</div>
		</div>
	)
}
