import Joi from 'joi'
import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Btn from '../../../../UI/Btn'
import TextField from '../../../../UI/TextField'
import SelectField from '../../../../UI/SelectField'
import BaseModal from '../../../../UI/BaseModal/BaseModal'
import http from '../../../../shared/http'
import { types } from '../../../../shared/eventEmitter'
import { TApi, StateOptions } from '../../../../shared/const'

import styles from './style.module.scss'

const schema = Joi.object({
	schoolName: Joi.string().min(2).max(64).required(),
	schoolType: Joi.string().required(),
	schoolState: Joi.string().required(),
	street: Joi.string().required(),
	number: Joi.number().min(1).max(1000).required(),
	zipCode: Joi.number().min(1).required(),
	location: Joi.string().min(2).max(64).required()
})

function AddSchool(props) {
	const { t } = useTranslation()
	const {
		reset,
		register,
		control,
		handleSubmit,
		trigger,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema)
	})

	const [isLoading, setLoading] = useState(false)
	const [dataFunc, setFunc] = useState({})

	useEffect(() => {
		if (Object.keys(errors).length) trigger()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localStorage.getItem('i18nextLng')])

	const onSubmit = (data, closeModal) => {
		setLoading(true)

		const schema = {
			name: data.schoolName,
			type: data.schoolType,
			streetName: data.street,
			number: data.number,
			zipCode: data.zipCode,
			location: data.location,
			state: data.schoolState
		}
		http.post(TApi.STUDENT_CREATE_SCHOOL, schema)
			.then(({ data }) => {
				dataFunc.func('school', data)
				toast.success(t('School has been created successfully.'))
				closeModal()
			})
			.finally(() => setLoading(false))
	}

	const handleOpen = setValue => {
		setFunc({ func: setValue })
	}

	return (
		<BaseModal
			onClose={reset}
			onOpen={handleOpen}
			nameEvent={types.openAddSchool}
			title={t('Create School')}
			isLoading={isLoading}
			maxWidth={522}
		>
			{({ closeModal }) => (
				<form
					className={styles.root}
					onSubmit={handleSubmit(form =>
						onSubmit(form, () => {
							closeModal()
						})
					)}
				>
					<SelectField
						required
						disabled={isLoading}
						mb={30}
						label={t('School Types')}
						control={control}
						name="schoolType"
						error={errors.schoolType}
						placeholder={t('Select')}
						options={props.schoolTypes?.map(v => ({
							value: v.value,
							label: v.value
						}))}
					/>

					<TextField
						required
						disabled={isLoading}
						mb={30}
						label={t('School Name')}
						placeholder={t('School Name')}
						error={errors.schoolName}
						register={register('schoolName')}
					/>

					<TextField
						required
						disabled={isLoading}
						mb={30}
						label={t('Street')}
						placeholder={t('Street')}
						error={errors.street}
						register={register('street')}
					/>

					<TextField
						required
						disabled={isLoading}
						mb={30}
						label={t('Nummer')}
						placeholder={t('Nummer')}
						error={errors.number}
						register={register('number')}
					/>
					<TextField
						required
						disabled={isLoading}
						mb={30}
						label={t('Zip Code')}
						placeholder={t('Zip Code')}
						error={errors.zipCode}
						register={register('zipCode')}
					/>

					<TextField
						required
						disabled={isLoading}
						mb={30}
						label={t('City')}
						placeholder={t('City')}
						error={errors.location}
						register={register('location')}
					/>
					<SelectField
						required
						disabled={isLoading}
						mb={20}
						label={t('state')}
						control={control}
						name="schoolState"
						error={errors.schoolState}
						placeholder={t('Select')}
						options={StateOptions.map(v => ({
							label: t(v.label),
							value: v.value
						})).sort((a, b) => a.label.localeCompare(b.label))}
					/>

					<Btn
						loading={isLoading}
						width={155}
						className={styles.btnSubmit}
						type="submit"
						icon="send"
						iconSize={{ w: 15, h: 15 }}
						iconColor="#fff"
					>
						{t('save')}
					</Btn>
				</form>
			)}
		</BaseModal>
	)
}

AddSchool.propTypes = {
	schoolTypes: PropTypes.array.isRequired
}
const mapStateToProps = state => ({
	schoolTypes: state.options.schoolTypes
})
export default connect(mapStateToProps)(AddSchool)
