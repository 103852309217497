import clsx from 'clsx'
import PropTypes from 'prop-types'

import styles from './style.module.scss'
//use RadioField inside label tag
export default function RadioField(props) {
	return (
		<span className={clsx(styles.root, props.className)}>
			<input type="radio" className={styles.radio} {...props.register} value={props.value} />
			{props.error && <span className={styles.error}>{props.error.message}</span>}
			<span className={styles.radioWrapper} />
		</span>
	)
}

RadioField.propTypes = {
	error: PropTypes.object,
	className: PropTypes.string,
	register: PropTypes.object.isRequired,
	value: PropTypes.string.isRequired
}
