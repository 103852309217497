import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

import CompanyInsurance from '../../components/views/student/CompanyInsurance'

import injectRole from '../../components/HOC/injectRole'

import { TRoles, TRoutes } from '../../shared/const'

function CompanyInsurancePage(props) {
	if (props.role === TRoles.STUDENT) return <CompanyInsurance />
	else return <Redirect to={TRoutes.DASHBOARD} />
}

CompanyInsurancePage.propTypes = {
	role: PropTypes.string.isRequired
}

export default injectRole(CompanyInsurancePage)
