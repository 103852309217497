import Joi from 'joi'
import clsx from 'clsx'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { joiResolver } from '@hookform/resolvers/joi'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import Btn from '../../UI/Btn'
import FlatLink from '../../UI/FlatLink'
import TextField from '../../UI/TextField'

import http from '../../shared/http'
import { TApi, TRoutes } from '../../shared/const'
import { schemaOptions } from '../../shared/i18n'

import styles from './common.module.scss'
import PolicyLinks from '../../UI/policyLinks'

const schema = Joi.object({
	email: Joi.string().email({ tlds: false }).required()
})

export default function RecoverPass() {
	const { t } = useTranslation()
	const history = useHistory()
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema, {
			errors: {
				labels: false,
				language: localStorage.getItem('i18nextLng')
			},
			...schemaOptions
		})
	})
	const [isLoading, setLoading] = useState(false)

	const onSubmit = form => {
		setLoading(true)

		http.post(TApi.REQUEST_PASSWORD_RESET, { email: form.email.toLowerCase() })
			.then(() => {
				toast.success(t('A letter with a link to reset the password is sent to the e-mail'))
				history.push(TRoutes.SIGN_IN)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	return (
		<div className={styles.root}>
			<div className={styles.header}>
				<div className={styles.miniContainer}>
					<h2 className={styles.titleSignIn}>{t('Pass Recovery')}</h2>

					<p className={styles.accountMessage}>
						{t('Back to')}{' '}
						<FlatLink
							disabled={isLoading}
							to={TRoutes.SIGN_IN}
							icon="arrow"
							iconSize={{ w: 0, h: 0 }}
							iconColor="#008B9C"
						>
							{t('Sign In')}
						</FlatLink>
					</p>
				</div>
				<PolicyLinks />
			</div>
			<form className={clsx(styles.recoverContainer)} autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
				<TextField
					label="Email"
					placeholder={t('Your email')}
					error={errors.email}
					register={register('email')}
				/>

				<Btn
					loading={isLoading}
					width={167}
					iconClassName={styles.iconRecover}
					className={styles.btnRecover}
					type="submit"
					icon="arrow"
					iconSize={{ w: 10, h: 10 }}
					iconColor="#fff"
				>
					{t('Recover')}
				</Btn>
			</form>
		</div>
	)
}
