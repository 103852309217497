import { useState, useEffect } from 'react'
import styles from '../style.module.scss'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import Joi from 'joi'
import http from '../../../../../shared/http'
import { TApi, StateOptions, TRoutes, CompaniesOptionsFull } from '../../../../../shared/const'
import { schemaOptions } from '../../../../../shared/i18n'
import { joiResolver } from '@hookform/resolvers/joi'
import SelectField from '../../../../../UI/SelectField'
import TextField from '../../../../../UI/TextField'
import Btn from '../../../../../UI/Btn'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'

const schema = Joi.object({
	companyName: Joi.string().required(),
	companyType: Joi.string().required(),
	state: Joi.string().required()
})

function CompanyProfile(props) {
	const { id } = useParams()
	const location = useLocation()
	const path = location.pathname

	const { t } = useTranslation()
	const {
		register,
		trigger,
		control,
		handleSubmit,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema, {
			errors: {
				labels: false,
				language: localStorage.getItem('i18nextLng')
			},
			...schemaOptions
		}),
		defaultValues: {
			companyName: props.companyProfile.name,
			companyType: props.companyProfile.companyType,
			state: props.companyProfile.state
		}
	})
	const [isLoading, setLoading] = useState(false)

	const disabledFields = isLoading || props.companyProfile.isActive || props.companyProfile.isNeedReview

	useEffect(() => {
		if (Object.keys(errors).length) trigger()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localStorage.getItem('i18nextLng')])

	const onSubmit = data => {
		setLoading(true)
		const schema = {
			name: data.companyName,
			companyType: data.companyType,
			state: data.state
		}

		const updateCompany =
			path === TRoutes.COMPANY
				? http.put(TApi.COMPANY_PUT_PROFILE, schema)
				: http.put(TApi.ADMIN_GET_COMPANY(id), schema)

		updateCompany
			.then(() => {
				toast.success(t('Company profile has been updated successfully.'))
			})
			.finally(() => setLoading(false))
	}
	return (
		<div className={styles.root}>
			<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
				<div className={styles.section}>
					<h1 className={styles.sectionTitle}>{t('Basic Info')}</h1>
					<div className={styles.sectionContent}>
						<div className={styles.flexContainer}>
							<TextField
								required
								className={styles.field}
								disabled={disabledFields}
								mb={36}
								label={t('Company name')}
								placeholder={t('Company name')}
								error={errors.companyName}
								register={register('companyName')}
							/>
							<span className={styles.sectionNote}>
								<span style={{ fontWeight: 600 }}>{t('Note')}:</span>{' '}
								{t(
									'The company name as well as the company type, if any, can only be changed via the country administrator.'
								)}
								.
							</span>
						</div>

						<SelectField
							required
							className={styles.field}
							disabled={disabledFields}
							mb={36}
							label={t('Company type')}
							control={control}
							name="companyType"
							error={errors.companyType}
							placeholder="Select"
							options={CompaniesOptionsFull}
						/>

						<SelectField
							required
							className={styles.field}
							disabled={disabledFields}
							mb={36}
							label={t('state')}
							control={control}
							name="state"
							error={errors.state}
							placeholder="Select state"
							options={StateOptions.map(v => ({
								label: t(v.label),
								value: v.value
							})).sort((a, b) => a.label.localeCompare(b.label))}
						/>
						{!props.companyProfile.isActive && !props.isTeacherStudentView && (
							<div className={styles.btnContainer}>
								<Btn
									className={styles.btn}
									loading={isLoading}
									type="submit"
									icon="arrow"
									iconSize={{ w: 10, h: 10 }}
									iconColor="#fff"
								>
									{t('save')}
								</Btn>
							</div>
						)}
					</div>
				</div>
			</form>
		</div>
	)
}

CompanyProfile.propTypes = {
	companyProfile: PropTypes.object,
	isTeacherStudentView: PropTypes.bool.isRequired
}

export default CompanyProfile
