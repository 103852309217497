import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

import injectRole from '../../../components/HOC/injectRole'

import { TRoles, TRoutes } from '../../../shared/const'
import EmailTemplates from '../../../components/views/admin/EmailTemplates'

function EmailTemplatesPage(props) {
	if ([TRoles.ADMIN, TRoles.FEDERAL_ADMIN].includes(props.role)) return <EmailTemplates />
	else return <Redirect to={TRoutes.DASHBOARD} />
}

EmailTemplatesPage.propTypes = {
	role: PropTypes.string.isRequired
}

export default injectRole(EmailTemplatesPage)
