import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

import injectRole from '../../components/HOC/injectRole'

import { TRoles, TRoutes } from '../../shared/const'
import Notifications from '../../components/views/common/Notifications'

function NotificationPage(props) {
	if (props.role === TRoles.STUDENT || props.role === TRoles.TEACHER) return <Notifications />
	else return <Redirect to={TRoutes.DASHBOARD} />
}

NotificationPage.propTypes = {
	role: PropTypes.string.isRequired
}

export default injectRole(NotificationPage)
