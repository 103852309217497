import Joi from 'joi'
import clsx from 'clsx'
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { joiResolver } from '@hookform/resolvers/joi'
import { useHistory, Redirect } from 'react-router-dom'

import Btn from '../../UI/Btn'
import FlatLink from '../../UI/FlatLink'
import TextField from '../../UI/TextField'

import http from '../../shared/http'
import { TApi, TRoutes } from '../../shared/const'
import { schemaOptions } from '../../shared/i18n'
import { logIn } from '../../redux/ducks/user'

import styles from './common.module.scss'
import { fullLogIn } from '../../shared/helpers'
import PolicyLinks from '../../UI/policyLinks'

const schema = Joi.object({
	email: Joi.string().email({ tlds: false }).required(),
	password: Joi.string().min(5).max(32).required()
})

function SignIn(props) {
	const { t } = useTranslation()
	const history = useHistory()

	const {
		register,
		trigger,
		handleSubmit,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema, {
			errors: {
				labels: false,
				language: localStorage.getItem('i18nextLng')
			},
			...schemaOptions
		})
	})
	const [isLoading, setLoading] = useState(false)

	useEffect(() => {
		if (Object.keys(errors).length) trigger()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localStorage.getItem('i18nextLng')])

	const onSubmit = form => {
		setLoading(true)
		http.post(TApi.SIGN_IN, {
			email: form.email.toLowerCase(),
			password: form.password
		})
			.then(({ data }) => {
				fullLogIn(data.token, data.user)

				history.push(TRoutes.DASHBOARD)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	if (props.isSignInMode) return <Redirect to={TRoutes.SIGN_UP} />

	return (
		<div className={styles.root}>
			<div className={styles.header}>
				<div className={styles.miniContainer}>
					<h2 className={styles.titleSignIn}>{t('Sign In')}</h2>

					<p className={styles.accountMessage}>
						{t('Do not have account yet?')}{' '}
						<FlatLink
							disabled={isLoading}
							to={TRoutes.SIGN_UP}
							icon="arrow"
							iconSize={{ w: 10, h: 10 }}
							iconColor="#008B9C"
						>
							{t('SignUp.noun')}
						</FlatLink>
					</p>
				</div>
				<PolicyLinks />
			</div>
			<form
				className={clsx(styles.miniContainer, styles.form)}
				onSubmit={handleSubmit(onSubmit)}
				autoComplete="off"
			>
				<TextField
					mb={22}
					label="Email"
					placeholder={t('Your email')}
					error={errors.email}
					register={register('email')}
				/>
				<TextField
					mb={22}
					type="password"
					label={t('Password')}
					placeholder={t('Your password')}
					error={errors.password}
					register={register('password')}
				/>
				<p className={styles.forgotPassportMessage}>
					{t('Forgot password?')}{' '}
					<FlatLink
						disabled={isLoading}
						to={TRoutes.RECOVER_PASS}
						color="#46B17B"
						icon="arrow"
						iconSize={{ w: 10, h: 10 }}
						iconColor="#008B9C"
					>
						{t('Recover Pass')}
					</FlatLink>
				</p>

				<Btn
					loading={isLoading}
					width={167}
					className={styles.btnLogIn}
					type="submit"
					icon="arrow"
					iconSize={{ w: 10, h: 10 }}
					iconColor="#fff"
				>
					{t('Sign In')}
				</Btn>
			</form>
		</div>
	)
}

SignIn.propTypes = {
	isSignInMode: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
	isSignInMode: state.user.isSignInMode
})

const mapDispatchToProps = dispatch => ({
	logIn: data => dispatch(logIn(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
