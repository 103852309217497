import styles from './style.module.scss'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

export default function SingleData(props) {
	const { t } = useTranslation()
	return (
		<div className={styles.root}>
			<h1 className={styles.header}>{t(props.header.toLowerCase())}</h1>
			<h2 className={styles.existNumber}>{props.data}</h2>
		</div>
	)
}

SingleData.propTypes = {
	header: PropTypes.string.isRequired,
	data: PropTypes.string.isRequired
}
