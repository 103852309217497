import clsx from 'clsx'
import PropTypes from 'prop-types'
import { format } from 'date-fns'

import Loading from '../Loading'
import Icon from '../Icon'

import styles from './style.module.scss'
import Btn from '../Btn'

export default function Uploader(props) {
	return (
		<div className={clsx(styles.root, props.className)}>
			{props.error && <span className={styles.error}>{props.error.message}</span>}
			{props.isLoading && <Loading className={styles.loaderContainer} />}
			{!props.isLoading && (
				<label
					className={clsx(styles.wrapper, 'uploader-wrapper', props.classNameLabel)}
					onClick={props.onClick}
				>
					<input
						type="file"
						accept={props.accept}
						className={styles.upload}
						{...(props.register || {})}
						disabled={props.disabled}
						onChange={evt => {
							if (props.register) props.register.onChange(evt)
							if (props.onChange) props.onChange(evt)
						}}
					/>
					<div className={clsx(styles.content, 'uploader-content', props.classNameContent)}>
						{props.nameFile ? (
							<Icon
								name={props.iconName || 'paperParental'}
								noFill
								className={styles.icon}
								size={{ w: 38, h: 38 }}
							/>
						) : (
							<div className={clsx(styles.wrapperPlusIcon, 'uploader-btn-add')}>
								<Icon name="plus" color="#22404D" size={{ w: 12, h: 32 }} className={styles.plusIcon} />
							</div>
						)}
						<div>
							<h1 className={clsx(styles.title, 'uploader-title')}>{props.title}</h1>
							{props.nameFile && (
								<>
									<span className={styles.fileName}>{props.nameFile}</span> <br />{' '}
								</>
							)}
							{props.reviewedParentalConfirmation && (
								<div className={styles.reviewedParentalConfirmation}>OK</div>
							)}
							{props.createdAt && (
								<span className={styles.fileName}>
									{format(new Date(props.createdAt), 'dd.MM.yyyy hh:mm a')}
								</span>
							)}
						</div>
						{props.nameFile && props.onDelete && (
							<Btn
								onClick={props.onDelete}
								className={styles.btnRemove}
								icon="delete"
								iconColor="#eb5757"
								iconSize={{ w: 12, h: 12 }}
								theme="white"
							/>
						)}
					</div>
				</label>
			)}
		</div>
	)
}

Uploader.propTypes = {
	title: PropTypes.string.isRequired,
	isLoading: PropTypes.bool,
	nameFile: PropTypes.string,
	register: PropTypes.object,
	error: PropTypes.object,
	className: PropTypes.string,
	classNameLabel: PropTypes.string,
	classNameContent: PropTypes.string,
	handleClick: PropTypes.func,
	disabled: PropTypes.bool,
	accept: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	onDelete: PropTypes.func,
	reviewedParentalConfirmation: PropTypes.bool
}

Uploader.defaultProps = {
	onClick: () => {},
	disabled: false,
	isLoading: false
}
