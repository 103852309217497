import BaseModal from '../../../../../UI/BaseModal/BaseModal'
import { types } from '../../../../../shared/eventEmitter'
import styles from '../style.module.scss'
import SelectField from '../../../../../UI/SelectField'
import Joi from 'joi'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import Btn from '../../../../../UI/Btn'
import http from '../../../../../shared/http'
import { TApi } from '../../../../../shared/const'
import { schemaOptions } from '../../../../../shared/i18n'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const schema = Joi.object({
	location: Joi.string().required(),
	school: Joi.string().required()
})

export default function CustomizeCompaniesModal(props) {
	const { t } = useTranslation()
	const {
		handleSubmit,
		control,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema, {
			errors: {
				labels: false,
				language: localStorage.getItem('i18nextLng')
			},
			...schemaOptions
		})
	})

	const [isLoading, setLoading] = useState(false)

	const onSubmit = (form, closeModal) => {
		setLoading(true)

		http.put(TApi.CUSTOMIZE_COMPANIES_WIDGET, form)
			.then(() => {
				closeModal()
			})
			.finally(() => {
				setLoading(false)
			})
	}

	return (
		<BaseModal
			nameEvent={types.openCustomizeCompaniesModal}
			title={t('Customize Companies Widget')}
			isLoading={isLoading}
			maxWidth={522}
		>
			{({ closeModal }) => (
				<div>
					<form className={styles.container} onSubmit={handleSubmit(form => onSubmit(form, closeModal))}>
						<SelectField
							mb={20}
							disabled={isLoading}
							label={t('state')}
							control={control}
							error={errors.location}
							name="location"
							placeholder="Select Location"
							options={[
								{ value: 'hello', label: 'Hello' },
								{ value: 'hallo', label: 'Hallo' }
							]}
						/>

						<SelectField
							mb={20}
							disabled={isLoading}
							label="School"
							control={control}
							error={errors.school}
							name="school"
							placeholder="all Schools"
							options={[
								{ value: 'hello', label: 'Hello' },
								{ value: 'hallo', label: 'Hallo' }
							]}
						/>

						<Btn
							loading={isLoading}
							width={214}
							className={styles.btn}
							type="submit"
							icon="arrow"
							iconSize={{ w: 10, h: 10 }}
							iconColor="#fff"
						>
							{t('save')}
						</Btn>
					</form>
				</div>
			)}
		</BaseModal>
	)
}
