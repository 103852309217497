import { useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactTooltip from 'react-tooltip'

import BaseModal from '../../../../UI/BaseModal/BaseModal'
import { types } from '../../../../shared/eventEmitter'

import WorkArea from '../../../views/student/Profile/components/WorkArea'

function StudentWorkAreaModal() {
	const [modalStudent, setModalStudent] = useState(null)
	const [refreshEmployees, setRefreshEmployees] = useState(() => () => {})
	const { t } = useTranslation()

	const handleOpen = (
		{ studentId, isCEO, primaryWorkArea, secondaryWorkArea, tertiaryWorkArea },
		refreshEmployees
	) => {
		setModalStudent({
			studentId,
			isCEO,
			primaryWorkArea,
			secondaryWorkArea,
			tertiaryWorkArea
		})

		setRefreshEmployees(() => () => refreshEmployees())
	}

	useLayoutEffect(() => {
		ReactTooltip.rebuild()
	}, [modalStudent])

	return (
		<BaseModal
			onOpen={handleOpen}
			nameEvent={types.openStudentWorkAreaModal}
			title={t('Edit function & working area')}
			isLoading={false}
			maxWidth={850}
		>
			{() => <WorkArea refreshEmployees={refreshEmployees} modalStudent={modalStudent} />}
		</BaseModal>
	)
}

export default StudentWorkAreaModal
