import styles from './style.module.scss'
import PropTypes from 'prop-types'
import Avatar from 'react-avatar'

export default function StudentItem(props) {
	return (
		<div className={styles.root}>
			<div>
				<div className={styles.info}>
					<Avatar
						src={props.avatar}
						name={props.name}
						className={styles.avatar}
						round
						size={35}
						color="#303E65"
					/>
					<h1 className={styles.name}> {props.name} </h1>
				</div>
			</div>
			<div className={styles.info}>{/* <BtnOption /> */}</div>
		</div>
	)
}

StudentItem.propTypes = {
	id: PropTypes.number.isRequired,
	avatar: PropTypes.string,
	name: PropTypes.string.isRequired
}
