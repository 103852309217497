import styles from '../style.module.scss'
import http from '../../../../../shared/http'
import { TApi } from '../../../../../shared/const'

import Uploader from '../../../../../UI/Uploader'
import { useTranslation } from 'react-i18next'
export default function ParentalConfirmation(props) {
	const parentalConfirmationHashName = props.parentalConfirmationHashName
	const { t } = useTranslation()

	const downoload = () => {
		http.get(TApi.ADMIN_PARENTAL_CONFIRMATION(parentalConfirmationHashName), {
			responseType: 'blob'
		}).then(({ data }) => {
			const file = new Blob([data], { type: data.type })
			const fileURL = URL.createObjectURL(file)
			window.open(fileURL)
		})
	}

	return (
		<Uploader
			accept="application/pdf"
			title={t('Parental Confirmation')}
			onClick={downoload}
			disabled={true}
			classNameContent={styles.uploader}
			classNameLabel={styles.wrapper}
			nameFile={props.originalName}
			reviewedParentalConfirmation={props.reviewedParentalConfirmation}
		/>
	)
}
