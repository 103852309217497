import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

import injectRole from '../../../components/HOC/injectRole'

import { TRoles, TRoutes } from '../../../shared/const'
import EmailTemplate from '../../../components/views/admin/NewEmailTemplate'

function NewEmailTemplatePage(props) {
	if ([TRoles.ADMIN, TRoles.FEDERAL_ADMIN].includes(props.role)) return <EmailTemplate />
	else return <Redirect to={TRoutes.DASHBOARD} />
}

NewEmailTemplatePage.propTypes = {
	role: PropTypes.string.isRequired
}

export default injectRole(NewEmailTemplatePage)
