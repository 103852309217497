import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useEffect, useMemo, useState } from 'react'
import { format as formatDate, parseISO } from 'date-fns'

import Btn from '../../../../UI/Btn'
import MiniHeader from '../../../../UI/MiniHeader'
import Filter from '../../../../UI/Filter'
import BtnFilter from '../../../../UI/BtnFilter'

import http from '../../../../shared/http'
import { StateOptions, TApi, TLanguages, TRoles } from '../../../../shared/const'
import eventEmitter, { types } from '../../../../shared/eventEmitter'

import Table from '../../../../UI/Table'
import Chip from '../../../../UI/Chip'
import BtnTooltip from '../../../../UI/BtnTooltip'

import injectRole from '../../../HOC/injectRole'
import VisibilityAccess from '../../../VisibilityAccess'
import { connect } from 'react-redux'

import styles from './style.module.scss'
import { setNewsFilter } from '../../../../redux/ducks/filter'
import { generateFilter } from '../../../../shared/helpers'
import clsx from 'clsx'

function News(props) {
	const { t, i18n } = useTranslation()
	const [news, setNews] = useState([])
	const [countNews, setCountNews] = useState(0)
	const [page, setPage] = useState(0)
	const [sort, setSort] = useState({ name: 'createdAt', direction: 'desc' })
	const limit = 10
	const [countFilters, setCountFilters] = useState(0)
	const [showFilter, setShowFilter] = useState(false)

	const getNameApiGetNews = () => {
		const api = {
			[TRoles.ADMIN]: TApi.ADMIN_GET_NEWS,
			[TRoles.FEDERAL_ADMIN]: TApi.ADMIN_GET_NEWS,
			[TRoles.TEACHER]: TApi.TEACHER_GET_NEWS,
			[TRoles.STUDENT]: TApi.STUDENT_GET_NEWS
		}

		return api[props.role]
	}

	const fetchNews = () => {
		const filter = generateFilter(props.newsFilter)

		http.get(getNameApiGetNews(), {
			params: {
				limit,
				offset: page * limit,
				order: sort.name,
				direction: sort.direction,
				filter: JSON.stringify(filter)
			}
		}).then(({ data }) => {
			setNews(data.rows)
			setCountNews(data.count)
		})
	}

	const handleSearch = () => {
		if (page === 0) fetchNews()
		setPage(0)
	}

	const handleDeleteNews = id => http.delete(TApi.ADMIN_DELETE_NEWS(id)).then(() => fetchNews())

	const handleDone = () => fetchNews()

	const handleEditNews = id => {
		eventEmitter.emit(
			types.openAddFeedModal,
			news.find(item => item.id === id),
			handleDone
		)
	}

	const handleSort = (name, direction) => {
		setSort({ name, direction })
	}

	useEffect(() => {
		if (Object.values(props.newsFilter).every(item => !item.length)) fetchNews()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.newsFilter])

	/*

	onDelete={handleDeleteNews}
	onEdit={handleEditNews}
	onUpdate={handleEditNews}

	*/

	const columns = useMemo(
		() =>
			[
				{
					Header: t('Title'),
					minWidth: 100,
					accessor: 'title',
					Cell: ({ row }) => (
						<div className={styles.nameWrapper}>
							<span className={styles.spanName}>{row.original.title}</span>
							{(TRoles.ADMIN === props.role || TRoles.FEDERAL_ADMIN === props.role) && (
								<BtnTooltip
									className={styles.btnToolTip}
									items={[
										{
											label: 'Edit',
											onClick: () => handleEditNews(row.original.id)
										},
										{
											label: t('Remove'),
											style: {
												color: '#eb5757'
											},
											onClick: () => handleDeleteNews(row.original.id)
										}
									]}
								/>
							)}
							{TRoles.STUDENT === props.role && (
								<BtnTooltip
									className={styles.btnToolTip}
									items={[
										{
											label: 'Read',
											onClick: () =>
												eventEmitter.emit(types.openDetailFeedModal, {
													id: row.original.id,
													title: row.original.title,
													description: row.original.description,
													externalLink: row.original.externalLink,
													place: row.original.place,
													startDate: row.original.startDate,
													endDate: row.original.endDate,
													states: row.original.states,
													roles: row.original.roles,
													companyTypes: row.original.companyTypes
												})
										}
									]}
								/>
							)}
						</div>
					)
				},
				(TRoles.ADMIN === props.role || TRoles.FEDERAL_ADMIN === props.role) && {
					Header: 'Status',
					width: 70,
					accessor: 'status',
					Cell: ({ row }) => <span>{t(`${row.original.status}`) || '-'}</span>
				},
				(TRoles.ADMIN === props.role || TRoles.FEDERAL_ADMIN === props.role) && {
					Header: t('state'),
					minWidth: 100,
					accessor: 'states',
					Cell: ({ row }) =>
						row.original.states ? (
							<span
								data-tip={row.original.states
									.map(state => t(StateOptions.find(({ value }) => value === state)?.label))
									.join(', ')}
							>
								<Chip miniRadius>
									{t(StateOptions.find(({ value }) => value === row.original.states[0])?.label)}{' '}
									{row.original.states.length > 1 &&
										`${t('and')} ${row.original.states.length - 1} ${t('others')}`}
								</Chip>
							</span>
						) : (
							<Chip miniRadius>{t('All')}</Chip>
						)
				},
				(TRoles.ADMIN === props.role || TRoles.FEDERAL_ADMIN === props.role) && {
					Header: t('Target'),
					minWidth: 100,
					accessor: 'roles',
					Cell: ({ row }) =>
						row.original.roles ? (
							row.original.roles.map(role => (
								<Chip style={{ marginLeft: 5 }} miniRadius>
									{t(`${role}`)}
								</Chip>
							))
						) : (
							<Chip miniRadius>{t('All')}</Chip>
						)
				},
				(TRoles.ADMIN === props.role || TRoles.FEDERAL_ADMIN === props.role) && {
					Header: t('School Types'),
					minWidth: 100,
					accessor: 'schoolType',
					Cell: ({ row }) =>
						row.original.schoolType ? (
							<span data-tip={row.original.schoolType.map(state => state).join(', ')}>
								<Chip miniRadius>
									{row.original.schoolType[0]}{' '}
									{row.original.schoolType.length > 1 &&
										`${t('and')} ${row.original.schoolType.length - 1} ${t('others')}`}
								</Chip>
							</span>
						) : (
							<Chip miniRadius>{t('All')}</Chip>
						)
				},
				(TRoles.ADMIN === props.role || TRoles.FEDERAL_ADMIN === props.role) && {
					Header: t('Company type'),
					minWidth: 100,
					accessor: 'companyTypes',
					Cell: ({ row }) =>
						row.original.companyTypes ? (
							row.original.companyTypes.map(type => (
								<Chip style={{ marginLeft: 5 }} miniRadius>
									{type}
								</Chip>
							))
						) : (
							<Chip miniRadius>{t('All')}</Chip>
						)
				},
				(TRoles.ADMIN === props.role || TRoles.FEDERAL_ADMIN === props.role) && {
					Header: t('branch'),
					minWidth: 100,
					accessor: 'companyBranch',
					Cell: ({ row }) =>
						row.original.companyBranch ? (
							<span
								data-tip={
									i18n.language === TLanguages.DE
										? row.original.companyBranch
												.map(
													item =>
														props.companyBranch?.find(v => v.value_EN === item)?.value_DE
												)
												.join(', ')
										: row.original.companyBranch
												.map(
													item =>
														props.companyBranch?.find(v => v.value_EN === item)?.value_EN
												)
												.join(', ')
								}
							>
								<Chip miniRadius>
									{i18n.language === TLanguages.DE
										? props.companyBranch?.find(v => v.value_EN === row.original.companyBranch[0])
												?.value_DE
										: props.companyBranch?.find(v => v.value_EN === row.original.companyBranch[0])
												?.value_EN}
									{row.original.companyBranch.length > 1 &&
										`${t('and')} ${row.original.companyBranch.length - 1} ${t('others')}`}
								</Chip>
							</span>
						) : (
							<Chip miniRadius>{t('All')}</Chip>
						)
				},
				(TRoles.ADMIN === props.role || TRoles.FEDERAL_ADMIN === props.role) && {
					Header: t('From'),
					minWidth: 100,
					accessor: 'startDate',
					Cell: ({ row }) =>
						row.original.startDate ? (
							<Chip className={styles.newsChip} miniRadius>
								{formatDate(parseISO(row.original.startDate), 'dd MMM RRRR')}
							</Chip>
						) : (
							'-'
						)
				},
				(TRoles.ADMIN === props.role || TRoles.FEDERAL_ADMIN === props.role) && {
					Header: t('To'),
					minWidth: 100,
					accessor: 'endDate',
					Cell: ({ row }) =>
						row.original.endDate ? (
							<Chip className={styles.newsChip} miniRadius>
								{formatDate(parseISO(row.original.endDate), 'dd MMM RRRR')}
							</Chip>
						) : (
							'-'
						)
				},
				(TRoles.ADMIN === props.role || TRoles.FEDERAL_ADMIN === props.role) && {
					Header: t('Author'),
					minWidth: 100,
					accessor: 'creator',
					Cell: ({ row }) => <Chip miniRadius>{row.original.creator.email}</Chip>
				},
				{
					Header: t('Content'),
					minWidth: 300,
					accessor: 'description',
					Cell: ({ row }) => (
						<div
							style={{
								display: 'flex',
								wordBreak: 'break-word',
								flexDirection: 'column',
								width: '100%',
								justifyContent: 'center',
								padding: '10px'
							}}
						>
							{row.original.description.length > 200
								? row.original.description.substring(0, 199) + '...'
								: row.original.description}{' '}
							<span
								className={styles.link}
								onClick={() =>
									eventEmitter.emit(types.openDetailFeedModal, {
										id: row.original.id,
										title: row.original.title,
										description: row.original.description,
										externalLink: row.original.externalLink,
										place: row.original.place,
										startDate: row.original.startDate,
										endDate: row.original.endDate,
										states: row.original.states,
										roles: row.original.roles,
										companyTypes: row.original.companyTypes
									})
								}
							>
								{' '}
								{t('read more')}
							</span>{' '}
						</div>
					)
				}
			].filter(item => !!item),
		/* eslint-disable-next-line */
		[t, news, props.role]
	)

	useEffect(() => {
		fetchNews()
		/* eslint-disable-next-line */
	}, [page, sort])

	return (
		<div
			className={clsx(styles.root, {
				[styles.rootAdmin]: props.role === TRoles.ADMIN || props.role === TRoles.FEDERAL_ADMIN
			})}
		>
			<MiniHeader className={styles.miniHeader} title={t('Events & News')} bg="#F8FAFF">
				<div className={styles.miniHeaderContent}>
					<VisibilityAccess access={[TRoles.ADMIN, TRoles.FEDERAL_ADMIN]}>
						<Btn
							width={168}
							onClick={() => eventEmitter.emit(types.openAddFeedModal, null, handleDone)}
							iconSize={{ w: 10, h: 10 }}
							icon="arrow"
							iconColor="white"
							theme="orange"
							className={styles.createButton}
						>
							{t('Create message')}
						</Btn>
						<BtnFilter
							className={styles.filterBtn}
							icon="arrow"
							onClick={() => setShowFilter(true)}
							count={countFilters}
						>
							Filter ({countFilters})
						</BtnFilter>
					</VisibilityAccess>
				</div>
			</MiniHeader>

			<div className="container">
				<Table
					columns={columns}
					handleSort={handleSort}
					items={news}
					limit={limit}
					countAll={countNews}
					onChange={setPage}
					page={page}
					paginationLabel="News"
				/>
			</div>
			<Filter
				toShow={{
					states: true,
					schoolType: true,
					companyTypes: true,
					userRole: true,
					branches: true,
					newsStatus: true
				}}
				onSearch={handleSearch}
				showFilter={showFilter}
				setShowFilter={setShowFilter}
				onCountFilters={setCountFilters}
				onFilterOptions={props.setFilter}
				filters={props.newsFilter}
			/>
		</div>
	)
}

News.propTypes = {
	role: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
	companyBranch: state.options.branch,
	newsFilter: state.filter.news
})

const mapDispatchToProps = dispatch => ({
	setFilter: data => dispatch(setNewsFilter(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(injectRole(News))
