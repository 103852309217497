import { TRoles } from '../../shared/const'
import { authInfo } from '../../shared/http'

const name = 'user'

const LOG_IN = `${name}/LOG_IN`
const LOG_OUT = `${name}/LOG_OUT`
const UPDATE_PROFILE = `${name}/UPDATE_PROFILE`
const UPDATE_USER = `${name}/UPDATE_USER`
const SET_ACTIVE_CONFIRM_EMAIL = `${name}/SET_ACTIVE_CONFIRM_EMAIL`
const UPDATE_WIDGET_SETTINGS = `${name}/UPDATE_WIDGET_SETTINGS`
const SET_SIGN_IN_MODE = `${name}/SET_SIGN_IN_MODE`
const SET_AUTH = `${name}/SET_AUTH`
const UPDATE_COMPANY_INFO = `${name}/UPDATE_COMPANY_INFO`
const ADD_PARENTAL_CONFIRMATION = `${name}/ADD_PARENTAL_CONFIRMATION`
const SET_SEARCH_LIMIT = `${name}/SET_SEARCH_LIMIT`
const SET_TEACHER_STUDENT_MODE = `${name}/SET_TEACHER_STUDENT_MODE`

const initialState = () => ({
	isAuth: !!authInfo.token,
	isSignInMode: false,
	isTeacherStudentView: false,
	role: authInfo.role || null,
	defaultLimit: 10,
	profile: {
		titlePrefixed: '',
		salutation: '',
		titleSuffixed: '',
		subordinateTitle: '',
		school: '',
		photoLink: '',
		email: '',
		firstName: '',
		lastName: '',
		companyName: '',
		position: '',
		widgetSettings: [],
		phone: '',
		emailConfirmed: false,
		EmploymentCompany: null,
		reportPeriod: null,
		notifications: {},
		Documents: [{ type: 'PARENTAL_CONFIRMATION' }],
		rejectedParentalConfirmation: false
	}
})

export default function reducer(state = initialState(), action = {}) {
	const { payload } = action
	switch (action.type) {
		case LOG_IN: {
			const getAuthStatus = () => {
				switch (payload.profile.role) {
					case TRoles.STUDENT:
						const isAuth = !!payload.profile.EmploymentCompany?.name && !!payload.profile.firstName
						return {
							isSignInMode: !isAuth,
							isAuth
						}
					case TRoles.TEACHER:
						return {
							isSignInMode: !payload.profile.gender,
							isAuth: !!payload.profile.gender
						}
					default:
						return {
							isSignInMode: false,
							isAuth: true
						}
				}
			}
			return {
				...state,
				...payload,
				...getAuthStatus()
			}
		}
		case LOG_OUT: {
			return {
				...initialState(),
				isAuth: false,
				isSignInMode: false,
				role: null
			}
		}
		case UPDATE_PROFILE: {
			return {
				...state,
				profile: {
					...state.profile,
					...payload.profile
				}
			}
		}
		case SET_ACTIVE_CONFIRM_EMAIL: {
			return {
				...state,
				profile: {
					...state.profile,
					emailConfirmed: true
				}
			}
		}
		case UPDATE_WIDGET_SETTINGS: {
			const { id, settings } = payload
			const newSettings = state.profile.widgetSettings.map(widget => {
				if (widget.id === id) {
					return {
						...widget,
						settings
					}
				}

				return widget
			})

			return {
				...state,
				profile: {
					...state.profile,
					widgetSettings: newSettings
				}
			}
		}

		case SET_SIGN_IN_MODE: {
			return {
				...state,
				isSignInMode: payload
			}
		}

		case SET_AUTH: {
			return {
				...state,
				isAuth: payload
			}
		}

		case UPDATE_USER: {
			return {
				...state,
				...payload.user
			}
		}

		case UPDATE_COMPANY_INFO: {
			return {
				...state,
				profile: {
					...state.profile,
					EmploymentCompany: {
						...state.profile.EmploymentCompany,
						...payload
					}
				}
			}
		}

		case ADD_PARENTAL_CONFIRMATION: {
			return {
				...state,
				profile: {
					...state.profile,
					Documents: [payload, ...state.profile.Documents]
				}
			}
		}

		case SET_SEARCH_LIMIT: {
			return {
				...state,
				defaultLimit: payload
			}
		}

		case SET_TEACHER_STUDENT_MODE: {
			return {
				...state,
				isTeacherStudentView: payload
			}
		}

		default: {
			return state
		}
	}
}

export function logIn({ role, profile }) {
	return {
		type: LOG_IN,
		payload: { role, profile }
	}
}

export function logOut() {
	return {
		type: LOG_OUT
	}
}

export function updateProfile(profile) {
	return {
		type: UPDATE_PROFILE,
		payload: { profile }
	}
}

export function setActiveConfirmEmail() {
	return {
		type: SET_ACTIVE_CONFIRM_EMAIL
	}
}

export function updateWidgetSettings({ id, settings }) {
	return {
		type: UPDATE_WIDGET_SETTINGS,
		payload: { id, settings }
	}
}

export function setSignInMode(value) {
	return {
		type: SET_SIGN_IN_MODE,
		payload: value
	}
}

export function setAuth(value) {
	return {
		type: SET_AUTH,
		payload: value
	}
}

export function updateCompanyInfo(data) {
	return {
		type: UPDATE_COMPANY_INFO,
		payload: data
	}
}

export function addParentalConfirmation(data) {
	return {
		type: ADD_PARENTAL_CONFIRMATION,
		payload: data
	}
}

export function setSearchLimit(data) {
	return {
		type: SET_SEARCH_LIMIT,
		payload: data
	}
}

export function setTeacherCompanyView(value) {
	return {
		type: SET_TEACHER_STUDENT_MODE,
		payload: value
	}
}
