import styles from './style.module.scss'
import MiniHeader from '../../../../../UI/MiniHeader'
import CompanyRevenues from './components/FirstReporting/CompanyRevenues'
import CompanyExpenditure from './components/FirstReporting/CompanyExpenditure'
import CompanyWagesSalary from './components/FirstReporting/CompanyWagesSalaries'

import { useState } from 'react'
import PropTypes from 'prop-types'
import http from '../../../../../shared/http'
import CompanyTaxCalculation from './components/SecondReporting/CompanyTaxCalculation'
import CompanyShareCalculation from './components/SecondReporting/CompanyShareCalculation'
import MiniProgressBar from '../../../../../UI/MiniProgressBar'
import MiniStep from '../../../../../UI/MiniProgressBar/MiniStep'
import CompanyContrinutionsCalculation from './components/SecondReporting/CompanuContrinutionsCalculation'
import { useEffect } from 'react'
import CompanyAdvanceReturn from './components/FirstReporting/CompanyAdvanceReturn'
import { TApi } from '../../../../../shared/const'
import { useTranslation } from 'react-i18next'

export default function JuniorCompanySecondReporting(props) {
	const { t } = useTranslation()
	const steps = [
		{
			title: t('Revenues and sales')
		},
		{
			title: t('Expenses and input tax deduction')
		},
		{
			title: t('Salary-related taxes (employee share)')
		},
		{
			title: t('Advance return for sales tax')
		},
		{
			title: t('Payload or credit')
		},
		{
			title: t('Income/Expense statement ')
		},
		{
			title: t('Calculation of contributions 2nd half')
		}
	]
	const [isLoading, setLoading] = useState(false)
	const [formData, setFormData] = useState(null)
	const [currentStep, setcurrentStep] = useState(1)
	const prevStep = e => {
		setcurrentStep(currentStep - 1)
	}
	const nextStep = e => {
		setcurrentStep(currentStep + 1)
	}

	const fetchData = () => {
		setLoading(true)
		http.get(TApi.STUDENT_REPORTS)
			.then(({ data }) => {
				setFormData({
					...formData,
					...data.AnnualReport,
					advanceReturnValueAddedTaxFirst: data.SemiannualReport?.advanceReturnValueAddedTax,
					advanceVatReturnNetIncomeTotalFirst: data.SemiannualReport?.advanceVatReturnNetIncomeTotal,
					semiAnnualReportPersonellCostsFirst: data.SemiannualReport?.semiAnnualReportPersonellCosts,
					advanceVatReturnNetExpensesFirst: data.SemiannualReport?.advanceVatReturnNetExpenses,
					expensesTaxFreeTotalFirst: data.SemiannualReport?.expensesTaxFreeTotal,
					semiAnnualReportResultTotalFirst: data.SemiannualReport?.semiAnnualReportResultTotal
				})
			})
			.finally(() => setLoading(false))
	}

	const updateData = async form => {
		setLoading(true)
		return http
			.put(TApi.STUDENT_REPORTS, { type: 'ANNUAL', ...form })
			.then(({ data }) => {
				setFormData({ ...formData, ...data })
			})
			.finally(() => setLoading(false))
	}

	useEffect(() => {
		const data = props.data
		!props.data && fetchData()
		props.data &&
			setFormData({
				...formData,
				...data.AnnualReport,
				advanceReturnValueAddedTaxFirst: data.SemiannualReport?.advanceReturnValueAddedTax,
				advanceVatReturnNetIncomeTotalFirst: data.SemiannualReport?.advanceVatReturnNetIncomeTotal,
				semiAnnualReportPersonellCostsFirst: data.SemiannualReport?.semiAnnualReportPersonellCosts,
				advanceVatReturnNetExpensesFirst: data.SemiannualReport?.advanceVatReturnNetExpenses,
				expensesTaxFreeTotalFirst: data.SemiannualReport?.expensesTaxFreeTotal,
				semiAnnualReportResultTotalFirst: data.SemiannualReport?.semiAnnualReportResultTotal
			})

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className={styles.root}>
			<MiniHeader title={t('Financial report second semester')} bg="#f8faff" />
			<MiniProgressBar className={styles.progerssBar} bg="#F8FAFF">
				{steps.map((v, i, a) => {
					return (
						<MiniStep
							key={i}
							needStep={i}
							currentStep={currentStep}
							allSteps={a.length}
							title={v.title}
							bg="#F8FAFF"
							onClick={e =>
								formData?.isSubmitted ? setcurrentStep(i + 1) : i < currentStep && setcurrentStep(i + 1)
							}
						/>
					)
				})}
			</MiniProgressBar>
			<div className="container">
				<CompanyRevenues
					isLoading={isLoading}
					form={formData}
					updateData={updateData}
					currentStep={currentStep}
					needStep={1}
					handlerNextStep={nextStep}
				/>
				<CompanyExpenditure
					isLoading={isLoading}
					form={formData}
					updateData={updateData}
					currentStep={currentStep}
					needStep={2}
					handlerNextStep={nextStep}
					handlerPrevStep={prevStep}
				/>
				<CompanyWagesSalary
					isLoading={isLoading}
					form={formData}
					updateData={updateData}
					currentStep={currentStep}
					needStep={3}
					handlerNextStep={nextStep}
					handlerPrevStep={prevStep}
				/>
				<CompanyAdvanceReturn
					showPayCredit
					isLoading={isLoading}
					form={formData}
					updateData={updateData}
					currentStep={currentStep}
					needStep={4}
					handlerNextStep={nextStep}
					handlerPrevStep={prevStep}
				/>
				<CompanyTaxCalculation
					isLoading={isLoading}
					form={formData}
					updateData={updateData}
					currentStep={currentStep}
					needStep={5}
					handlerNextStep={nextStep}
					handlerPrevStep={prevStep}
				/>
				<CompanyShareCalculation
					isLoading={isLoading}
					form={formData}
					updateData={updateData}
					currentStep={currentStep}
					needStep={6}
					handlerNextStep={nextStep}
					handlerPrevStep={prevStep}
				/>
				<CompanyContrinutionsCalculation
					isLoading={isLoading}
					form={formData}
					updateData={updateData}
					currentStep={currentStep}
					needStep={7}
					handlerPrevStep={prevStep}
				/>
			</div>
		</div>
	)
}
JuniorCompanySecondReporting.propTypes = {
	reportPeriod: PropTypes.string,
	data: PropTypes.object
}
