import BaseModal from '../../../../../UI/BaseModal/BaseModal'
import { types } from '../../../../../shared/eventEmitter'
import styles from '../style.module.scss'
import SelectField from '../../../../../UI/SelectField'
import Joi from 'joi'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import Btn from '../../../../../UI/Btn'
import http from '../../../../../shared/http'
import { TApi } from '../../../../../shared/const'
import { schemaOptions } from '../../../../../shared/i18n'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import { updateWidgetSettings } from '../../../../../redux/ducks/user'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

const schema = Joi.object({
	firstSlot: Joi.string(),
	secondSlot: Joi.string(),
	thirdSlot: Joi.string(),
	fourthSlot: Joi.string()
})

const options = [
	{ value: 'STUDENTS', label: 'Students' },
	{ value: 'TEACHERS', label: 'Teachers' },
	{ value: 'COMPANIES', label: 'Companies' }
]

function CustomizeMultiModal(props) {
	const { t } = useTranslation()
	const {
		handleSubmit,
		control,
		setValue,
		getValues,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema, {
			errors: {
				labels: false,
				language: localStorage.getItem('i18nextLng')
			},
			...schemaOptions
		})
	})

	const [isLoading, setLoading] = useState(false)
	const [widgetId, setWidgetId] = useState('')
	const [availableWidgets, setAvailableWidgets] = useState([])
	const [settings, setSettings] = useState({})

	const onSubmit = (form, closeModal) => {
		setLoading(true)
		const newSettings = availableWidgets.map(widget => {
			if (widget.id === widgetId) {
				return {
					...widget,
					settings: {
						...form
					}
				}
			}
			return widget
		})

		http.put(TApi.ADMIN_UPDATE_WIDGET_SETTINGS, { widgetSettings: newSettings })
			.then(() => {
				props.updateWidgetSettings({ id: widgetId, settings: getValues() })
				closeModal()
				toast.success(t('Widget settings updated successfully'))
			})
			.finally(() => {
				setLoading(false)
			})
	}
	const handleOpen = (currentOption, widgetId, availableWidgets) => {
		setWidgetId(widgetId)
		setAvailableWidgets(availableWidgets)
		setSettings(currentOption)
	}

	useEffect(() => {
		setValue('firstSlot', settings.firstSlot)
		setValue('secondSlot', settings.secondSlot)
		setValue('thirdSlot', settings.thirdSlot)
		setValue('fourthSlot', settings.fourthSlot)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [settings])

	return (
		<BaseModal
			onOpen={handleOpen}
			nameEvent={types.openCustomizeMultiModal}
			title={t('Customize Multi Widget')}
			isLoading={isLoading}
			maxWidth={522}
		>
			{({ closeModal }) => (
				<div>
					<form className={styles.container} onSubmit={handleSubmit(form => onSubmit(form, closeModal))}>
						<SelectField
							mb={15}
							disabled={isLoading}
							label={t('First Slot')}
							control={control}
							error={errors.firstSlot}
							name="firstSlot"
							placeholder={t('Select')}
							options={options}
						/>

						<SelectField
							mb={15}
							disabled={isLoading}
							label={t('Second Slot')}
							control={control}
							error={errors.secondSlot}
							name="secondSlot"
							placeholder={t('Select')}
							options={options}
						/>

						<SelectField
							mb={15}
							disabled={isLoading}
							label={t('Third Slot')}
							control={control}
							error={errors.thirdSlot}
							name="thirdSlot"
							placeholder={t('Select')}
							options={options}
						/>

						<SelectField
							mb={25}
							disabled={isLoading}
							label={t('Fourth Slot')}
							control={control}
							error={errors.fourthSlot}
							name="fourthSlot"
							placeholder={t('Select')}
							options={options}
						/>

						<Btn
							loading={isLoading}
							width={214}
							type="submit"
							icon="arrow"
							iconSize={{ w: 10, h: 10 }}
							iconColor="#fff"
						>
							{t('save')}
						</Btn>
					</form>
				</div>
			)}
		</BaseModal>
	)
}

CustomizeMultiModal.propTypes = {
	userId: PropTypes.number
}

const mapStateToProps = state => ({
	userId: state.user.profile.id
})

const mapDispatchToProps = dispatch => ({
	updateWidgetSettings: data => dispatch(updateWidgetSettings(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomizeMultiModal)
