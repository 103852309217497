import { useState, useEffect } from 'react'
import styles from '../style.module.scss'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import Joi from 'joi'
import http from '../../../../../shared/http'
import { TApi, TRoutes } from '../../../../../shared/const'
import { schemaOptions } from '../../../../../shared/i18n'
import { joiResolver } from '@hookform/resolvers/joi'
import TextAreaField from '../../../../../UI/TextAreaField'
import Btn from '../../../../../UI/Btn'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'

const schema = Joi.object({
	businessActivity: Joi.string().min(100).max(2000).required()
})

function CompanyProfile(props) {
	const { id } = useParams()
	const location = useLocation()
	const path = location.pathname

	const { t } = useTranslation()
	const {
		register,
		trigger,
		handleSubmit,
		watch,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema, {
			errors: {
				labels: false,
				language: localStorage.getItem('i18nextLng')
			},
			...schemaOptions
		}),
		defaultValues: {
			businessActivity: props.companyProfile.businessActivityDescription
		}
	})
	const [isLoading, setLoading] = useState(false)
	const watchBusinessActivity = watch('businessActivity')

	useEffect(() => {
		if (Object.keys(errors).length) trigger()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localStorage.getItem('i18nextLng')])

	const onSubmit = data => {
		setLoading(true)
		const schema = {
			businessActivityDescription: data.businessActivity
		}

		const updateCompany =
			path === TRoutes.COMPANY
				? http.put(TApi.COMPANY_PUT_PROFILE, schema)
				: http.put(TApi.ADMIN_GET_COMPANY(id), schema)

		updateCompany
			.then(() => {
				toast.success(t('Company profile has been updated successfully.'))
			})
			.finally(() => setLoading(false))
	}
	return (
		<div className={styles.root}>
			<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
				<div className={styles.section}>
					<h1 className={styles.sectionTitle}>{t('Detailed description of business activity')}</h1>
					<div className={styles.sectionContent}>
						<TextAreaField
							required
							classNameLabel={styles.textArea}
							disabled={isLoading}
							mb={36}
							height={189}
							label={t('Detailed description of business activity')}
							placeholder={t('Unsere...')}
							error={errors.businessActivity}
							showError
							register={register('businessActivity')}
							value={watchBusinessActivity}
						/>

						{!props.isTeacherStudentView && (
							<div className={styles.btnContainer}>
								<Btn
									className={styles.btn}
									loading={isLoading}
									type="submit"
									icon="arrow"
									iconSize={{ w: 10, h: 10 }}
									iconColor="#fff"
								>
									{t('save')}
								</Btn>
							</div>
						)}
					</div>
				</div>
			</form>
		</div>
	)
}

CompanyProfile.propTypes = {
	companyProfile: PropTypes.object,
	isTeacherStudentView: PropTypes.bool.isRequired
}

export default CompanyProfile
