import { useEffect, useState } from 'react'

import Contact from './components/Contact'
import BasicInfo from './components/BasicInfo'
import Supervising from './components/Supervising'
import BusinessIdea from './components/BusinessIdea'
import BusinessActivity from './components/BusinessActivity'
import BusinessDescription from './components/BusinessDescription'

import MiniHeader from '../../../../UI/MiniHeader'

import styles from './style.module.scss'

import http from '../../../../shared/http'
import { TApi, TRoutes } from '../../../../shared/const'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

function CompanyProfile(props) {
	const { t } = useTranslation()
	const [profile, setProfile] = useState({})
	const [loading, setLoading] = useState(false)

	const { id } = useParams()
	const location = useLocation()
	const path = location.pathname

	const getCompany =
		path === TRoutes.COMPANY ? http.get(TApi.STUDENT_GET_COMPANY) : http.get(TApi.ADMIN_GET_COMPANY_PROFILE(id))

	const fetchCompany = () => {
		setLoading(true)
		getCompany
			.then(({ data }) => {
				setProfile(data)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	useEffect(() => {
		fetchCompany()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (loading) {
		//TODO LOADER
		return <div />
	}

	return (
		<div className={styles.wrapper}>
			<MiniHeader title={t('companyProfile')} bg="#f8faff" />
			<BasicInfo
				companyProfile={profile}
				isTeacherStudentView={props.isTeacherStudentView}
				fetchCompany={fetchCompany}
			/>
			<Contact
				companyProfile={profile}
				isTeacherStudentView={props.isTeacherStudentView}
				fetchCompany={fetchCompany}
			/>
			<Supervising
				companyProfile={profile}
				isTeacherStudentView={props.isTeacherStudentView}
				fetchCompany={fetchCompany}
			/>
			<BusinessActivity
				companyProfile={profile}
				isTeacherStudentView={props.isTeacherStudentView}
				fetchCompany={fetchCompany}
			/>
			<BusinessIdea
				companyProfile={profile}
				isTeacherStudentView={props.isTeacherStudentView}
				fetchCompany={fetchCompany}
			/>
			<BusinessDescription
				companyProfile={profile}
				isTeacherStudentView={props.isTeacherStudentView}
				fetchCompany={fetchCompany}
			/>
		</div>
	)
}

CompanyProfile.propTypes = {
	isTeacherStudentView: PropTypes.bool.isRequired
}
const mapStateToProps = state => ({
	isTeacherStudentView: state.user.isTeacherStudentView
})

export default connect(mapStateToProps)(CompanyProfile)
