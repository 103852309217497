import React, { useEffect, useRef } from 'react'
import MiniHeader from '../../../../UI/MiniHeader'
import styles from './style.module.scss'
import codeMirror from 'codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/pug/pug'
import './codeMirrorStyles.css'
import Btn from '../../../../UI/Btn'
import { useHistory } from 'react-router-dom'
import { TRoutes } from '../../../../shared/const'
import { useTranslation } from 'react-i18next'

export default function EmailTemplate(props) {
	const { t } = useTranslation()
	const textareaRef = useRef(null)
	const history = useHistory()

	useEffect(() => {
		codeMirror.fromTextArea(textareaRef.current, {
			mode: 'pug',
			lineWrapping: true
		})
	}, [])

	return (
		<div className={styles.root}>
			<MiniHeader title={t('New email template')} />
			<div className={styles.innerWrapper}>
				<div className={styles.separator} />
				<div className={styles.templateName}>
					<div className={styles.title}>{t('Template name')}</div>
					<input type="text" className={styles.templateNameInput} />
				</div>
				<div className={styles.emailBody}>
					<div className={styles.title}>{t('Email body')}</div>
					<div className={styles.editorWrapper}>
						<textarea ref={textareaRef}></textarea>
					</div>
				</div>
				<div className={styles.saveBtnWrapper}>
					<Btn
						width={214}
						type="submit"
						icon="arrow"
						iconSize={{ w: 10, h: 10 }}
						iconColor="#fff"
						onClick={() => history.push(TRoutes.EMAILS)}
					>
						{t('save')}
					</Btn>
				</div>
			</div>
		</div>
	)
}
