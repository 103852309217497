import { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import styles from './style.module.scss'
import BtnToggle from '../../../../UI/BtnToggle'
import MiniHeader from '../../../../UI/MiniHeader'
import Search from '../../../../UI/Search'
import Table from '../../../../UI/Table'
import { TApi } from '../../../../shared/const'
import { generateFilter, getFormattedDate } from '../../../../shared/helpers'
import http from '../../../../shared/http'
import { useTranslation } from 'react-i18next'
import BtnTooltip from '../../../../UI/BtnTooltip'
import eventEmitter, { types } from '../../../../shared/eventEmitter'
import { connect } from 'react-redux'

import Avatar from 'react-avatar'
import Filter from '../../../../UI/Filter'
import BtnFilter from '../../../../UI/BtnFilter'
import imageWarning from '../../../../assets/images/warning.png'
import imageChecked from '../../../../assets/images/checked.png'
import Pagination from '../../../../UI/Pagination'
import { logIn, setTeacherCompanyView } from '../../../../redux/ducks/user'
import { setStudentsFilter } from '../../../../redux/ducks/filter'

function StudentsList(props) {
	const { t } = useTranslation()
	const [students, setStudents] = useState([])
	const [countAll, setCountAll] = useState(0)
	const [assigned, setAssigned] = useState(true)
	const [page, setPage] = useState(0)
	const [sort, setSort] = useState({ name: 'firstName', direction: 'asc' })
	const [search, setSearch] = useState('')
	const limit = 10
	const [showFilter, setShowFilter] = useState(false)
	const [countFilters, setCountFilters] = useState(0)

	const fetchStudents = () => {
		const filter = generateFilter(props.studentsFilter)

		http.get(assigned ? TApi.TEACHER_GET_ASSIGNED_STUDENTS : TApi.TEACHER_GET_STUDENTS, {
			params: {
				limit,
				offset: page * limit,
				order: sort.name,
				direction: sort.direction,
				search,
				filter: JSON.stringify(filter)
			}
		}).then(({ data }) => {
			setStudents(assigned ? data.allAssignedStudents.rows : data.allStudents.rows)
			setCountAll(assigned ? data.allAssignedStudents.count : data.allStudents.count)
		})
	}

	useEffect(() => {
		fetchStudents()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, assigned, sort])

	useEffect(() => {
		if (Object.values(props.studentsFilter).every(item => !item.length)) fetchStudents()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.studentsFilter])

	const handleSearch = e => {
		const { value } = e.target
		setSearch(value)
	}

	const handleSort = (name, direction) => {
		setSort({ name, direction })
	}

	const handleStudentMode = async studentId => {
		const {
			data: { token }
		} = await http.get(TApi.TEACHER_GET_TOKEN_STUDENT(studentId))

		http.defaults.headers['Authorization'] = `Bearer ${token}`
		props.setTeacherCompanyView(true)
		const { data } = await http.get(TApi.COMMON_ME, { disableToast: true })
		props.logIn({
			role: data.role,
			profile: data
		})
	}

	const columns = useMemo(
		() => [
			{
				Header: () => {
					return <span className={styles.tableTitleName}>Name</span>
				},
				minWidth: 250,
				accessor: 'firstName',
				Cell: ({ row }) => {
					return (
						<div className={styles.companyNameContainer}>
							<div>
								<Avatar
									className={styles.userAvatar}
									round
									size={32}
									name={`${row.original.firstName} ${row.original.lastName}`}
									src={
										row.original.avatarHashName &&
										TApi.PUBLIC_PROFILE_AVATAR(row.original.avatarHashName)
									}
								/>

								<span className={styles.name}>
									{row.original.firstName} {row.original.lastName}
								</span>
							</div>
							<BtnTooltip
								items={[
									{
										label: t('Change view'),
										onClick: () => handleStudentMode(row.original.id)
									},
									{
										label: t('View profile'),
										onClick: () =>
											eventEmitter.emit(types.openStudentProfile, row.original, fetchStudents)
									}
								]}
							/>
						</div>
					)
				}
			},
			{
				Header: t('Company'),
				accessor: 'companyId',
				Cell: ({ row }) => {
					return (
						<span className={styles.company}>
							{row.original.EmploymentCompany ? row.original.EmploymentCompany.name : 'null'}
						</span>
					)
				}
			},
			{
				Header: t('Work Area'),
				accessor: 'primaryWorkArea',
				Cell: ({ row }) => {
					return (
						<p className={styles.position}>
							{row.original.primaryWorkArea
								? t(row.original.primaryWorkArea)
								: t('No main work area yet')}
						</p>
					)
				}
			},
			{
				Header: t('Registration Date'),
				accessor: 'createdAt',
				Cell: ({ row }) => {
					return <span className={styles.registrationDate}>{getFormattedDate(row.original.createdAt)}</span>
				}
			},
			{
				Header: t('Parental Confirmation'),
				accessor: 'reviewedParentalConfirmation',
				Cell: ({ row }) => {
					return (
						<div className={styles.statusContainer}>
							<span className={clsx(styles.status)}>
								{row.original.reviewedParentalConfirmation && !!row.original.Documents[0] && (
									<div className={styles.parentalWrapper}>
										<img src={imageChecked} className={styles.checked} alt="" />
										<span style={{ color: 'blue' }}>{t('Checked')}</span>
									</div>
								)}
								{!!row.original.Documents[0] &&
									!row.original.rejectedParentalConfirmation &&
									!row.original.reviewedParentalConfirmation && (
										<div className={styles.parentalWrapper}>
											<img src={imageChecked} className={styles.checked} alt="" />
											<span style={{ color: '#285F74', marginLeft: '10px' }}>
												{t('Submitted')}
											</span>
										</div>
									)}
								{!!!row.original.Documents[0] && !row.original.rejectedParentalConfirmation && (
									<div className={styles.parentalWrapper}>
										{' '}
										<img src={imageWarning} className={styles.warning} alt="" />
										<span style={{ color: '#46B17B', marginLeft: '5px' }}>{t('Open')}</span>
									</div>
								)}
								{!!row.original.Documents[0] && row.original.rejectedParentalConfirmation && (
									<div className={styles.parentalWrapper}>
										<img src={imageWarning} className={styles.warning} alt="" />
										<span style={{ color: 'red' }}>{t('Rejected')}</span>
									</div>
								)}
							</span>
						</div>
					)
				}
			}
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[students, t]
	)
	return (
		<div className={styles.root}>
			<MiniHeader className={styles.headerContainer} title={t('students')}>
				<div className={styles.header}>
					<div className={styles.toggleContainer}>
						<p className={styles.toggleLeftTitle}>{t('Assigned Students')}</p>
						<BtnToggle
							onClick={() => {
								setAssigned(!assigned)
							}}
						/>
						<p className={styles.toggleRightTitle}>{t('All Students In School')}</p>
					</div>
					<div className={styles.searchContainer}>
						<Search
							className={styles.searchLabel}
							inputClassName={styles.searchInput}
							btnClassName={styles.searchBtn}
							placeholder={t('Search for a student')}
							onChange={handleSearch}
							value={search}
							onSubmit={e => {
								e.preventDefault()
								fetchStudents(page * limit)
							}}
						/>
						<BtnFilter
							className={styles.filterBtn}
							icon="arrow"
							onClick={() => setShowFilter(true)}
							count={countFilters}
						>
							Filter ({countFilters})
						</BtnFilter>
					</div>
				</div>
			</MiniHeader>
			<div className={styles.subHeader}>
				<Pagination limit={limit} page={page} label={t('students')} countAll={countAll} onChange={setPage} />
			</div>
			<div className="container">
				<Table
					columns={columns}
					items={students}
					page={page}
					limit={limit}
					countAll={countAll}
					onChange={setPage}
					handleSort={handleSort}
				/>
			</div>
			<Filter
				toShow={{
					reviewedParentalConfirmation: true,
					isCEO: true
				}}
				onSearch={fetchStudents}
				showFilter={showFilter}
				setShowFilter={setShowFilter}
				onCountFilters={setCountFilters}
				onFilterOptions={props.setFilter}
				filters={props.studentsFilter}
			/>
		</div>
	)
}

StudentsList.propTypes = {
	setTeacherCompanyView: PropTypes.func.isRequired,
	logIn: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
	workAreas: state.options.workAreas,
	studentsFilter: state.filter.students
})
const mapDispatchToProps = dispatch => ({
	setTeacherCompanyView: data => dispatch(setTeacherCompanyView(data)),
	logIn: data => dispatch(logIn(data)),
	setFilter: data => dispatch(setStudentsFilter(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(StudentsList)
