import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

import injectRole from '../../../components/HOC/injectRole'

import { TRoles, TRoutes } from '../../../shared/const'
import Companies from '../../../components/views/admin/Companies'

function CompaniesPage(props) {
	if ([TRoles.ADMIN, TRoles.FEDERAL_ADMIN].includes(props.role)) return <Companies />
	else return <Redirect to={TRoutes.DASHBOARD} />
}

CompaniesPage.propTypes = {
	role: PropTypes.string.isRequired
}

export default injectRole(CompaniesPage)
