import clsx from 'clsx'
import PropTypes from 'prop-types'

import styles from './style.module.scss'

export default function Chip(props) {
	return (
		<span
			style={{
				borderRadius: props.miniRadius ? 10 : '50%',
				...(props.miniRadius ? { padding: '0 5px', minWidth: 'unset' } : {}),
				...props.style
			}}
			className={clsx(styles.root, props.className)}
		>
			{props.children}
		</span>
	)
}

Chip.propTypes = {
	className: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	miniRadius: PropTypes.bool,
	style: PropTypes.object
}

Chip.defaultProps = {
	miniRadius: false
}
