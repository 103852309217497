import axios from 'axios'
import { toast } from 'react-toastify'

import { API_URL } from './config'
import { fullLogOut } from './helpers'
import i18n from 'i18next'

const instance = axios.create({
	baseURL: API_URL
})

instance.interceptors.response.use(
	response => response,
	error => {
		if (error.response) {
			const { error: errorRes } = error.response.data
			errorRes && errorRes.description && toast.error(errorRes.description)

			if (error.response.status === 401) fullLogOut()
		}

		if (!error.response?.config?.disableToast) {
			const message = error.response?.data?.message || ''
			console.log(error.message)
			console.log(message)
			toast.error(`${i18n.t(error.message)}. ${i18n.t(message)}`)
		}

		return Promise.reject(error)
	}
)

export const getAuthInfo = () => JSON.parse(localStorage.getItem('auth') || '{}')
export const authInfo = getAuthInfo()
if (authInfo.token) instance.defaults.headers['Authorization'] = `Bearer ${authInfo.token}`

export default instance
