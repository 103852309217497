import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import AdminSettings from '../../components/views/admin/Settings'

import { TRoles, TRoutes } from '../../shared/const'

function Settings(props) {
	if (props.role !== TRoles.FEDERAL_ADMIN) return <Redirect to={TRoutes.DASHBOARD} />
	return <AdminSettings />
}
Settings.propTypes = {
	role: PropTypes.string.isRequired
}
const mapStateToProps = state => ({
	role: state.user.role
})

export default connect(mapStateToProps)(Settings)
