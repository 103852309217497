import BaseModal from '../../../../UI/BaseModal/BaseModal'
import { types } from '../../../../shared/eventEmitter'
import styles from '../style.module.scss'
import dangerIcon from '../../../../assets/icons/Danger Triangle.svg'
import SelectField from '../../../../UI/SelectField'
import Joi from 'joi'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import TextField from '../../../../UI/TextField'
import Uploader from '../../../../UI/Uploader'
import Btn from '../../../../UI/Btn'
import http from '../../../../shared/http'
import { TApi, genderOption, TLanguages } from '../../../../shared/const'
import { useState, useEffect } from 'react'
import { schemaOptions } from '../../../../shared/i18n'
import { joiPhone } from '../../../../shared/helpers'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { joiFile } from '../../../../shared/helpers'

function AddShareholderModal(props) {
	const { t, i18n } = useTranslation()
	const [watchMode, setWatchMode] = useState(false)
	const schema = Joi.object({
		salutation: Joi.string().required(),
		titlePrefixed: Joi.string().allow(''),
		firstName: Joi.string()
			.min(2)
			.max(26)
			.regex(/^[a-zA-ZА-яЁёÄÖÜẞäöüß]+( [a-zA-ZА-яЁёÄÖÜẞäöüß]+)*$/)
			.required()
			.messages({
				'string.pattern.base': 'Only letters are allowed !'
			}),
		lastName: Joi.string()
			.min(2)
			.max(26)
			.regex(/^[a-zA-ZА-яЁёÄÖÜẞäöüß]+( [a-zA-ZА-яЁёÄÖÜẞäöüß]+)*$/)
			.required()
			.messages({
				'string.pattern.base': 'Only letters are allowed !'
			}),
		titleSuffixed: Joi.string().allow(''),
		companyShare: Joi.number().required(),
		email: Joi.string().email({ tlds: false }).required(),
		street: Joi.string().min(2).max(50).allow(null).empty('').default(''),
		zipCode: Joi.number().empty('').allow(null).default(null),
		phone: joiPhone,
		houseNumber: Joi.number().empty('').allow(null).default(null),
		city: Joi.string().allow(null).empty('').default(''),
		gender: Joi.string().required(),
		consentDocument: joiFile(10, t)
	})

	const {
		register,
		setValue,
		handleSubmit,
		getValues,
		reset,
		trigger,
		watch,
		setError,
		control,
		formState: { errors, dirtyFields }
	} = useForm({
		resolver: joiResolver(schema, {
			errors: {
				labels: false,
				language: localStorage.getItem('i18nextLng')
			},
			...schemaOptions
		})
	})

	const [isLoading, setLoading] = useState(false)
	const [consentDocumentFileName, setConsentDocumentFileName] = useState('')
	const [consentDocumentField] = watch(['consentDocument'])
	const [onDone, setOnDone] = useState(null)

	useEffect(() => {
		if (Object.keys(errors).length) trigger()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localStorage.getItem('i18nextLng')])

	const handleOpen = (feed = null, onDone = null, profile = null, watchMode = false) => {
		setOnDone(() => onDone)
		setConsentDocumentFileName('')

		setWatchMode(watchMode)

		if (profile) {
			setValue('salutation', profile.salutation)
			setValue('titlePrefixed', profile.titlePrefixed)
			setValue('firstName', profile.firstName)
			setValue('lastName', profile.lastName)
			setValue('titleSuffixed', profile.titleSuffixed)
			setValue('companyShare', profile.companyShare)
			setValue('email', profile.email)
			setValue('phone', profile.phone)
			setValue('street', profile.street)
			setValue('zipCode', profile.zipCode)
			setValue('houseNumber', profile.houseNumber)
			setValue('city', profile.city)
			setValue('gender', profile.gender)
		}

		if (!feed) return
	}

	const data = getValues()

	const isConsentDocumentValid = data => {
		if (!dirtyFields.consentDocument)
			return setError('consentDocument', {
				type: 'manual',
				message: t('Please upload the warrant!')
			})
		return true
	}

	const onSubmit = (data, closeModal) => {
		if (!isConsentDocumentValid(data)) return

		const form = new FormData()
		const file = data.consentDocument[0]

		const schema = {
			salutation: data.salutation,
			titlePrefixed: data.titlePrefixed,
			firstName: data.firstName,
			lastName: data.lastName,
			titleSuffixed: data.titleSuffixed,
			companyShare: data.companyShare,
			email: data.email,
			phone: data.phone,
			street: data.street,
			zipCode: data.zipCode,
			houseNumber: data.houseNumber,
			city: data.city,
			gender: data.gender ? data.gender : '',
			consentDocument: file
		}

		for (const property in schema) {
			form.append(property, schema[property] || '')
		}

		setLoading(true)

		http.post(TApi.STUDENT_ADD_SHAREHOLDER, form)
			.then(() => {
				closeModal()
				toast.success(t('The shareholder was created successfully'))
				if (onDone) onDone()
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const getShareholderCertificateTemplate = () => {
		setLoading(true)
		http.get(TApi.STUDENT_GET_SHAREHOLDER_TEMPLATE, {
			headers: {
				'Content-Type': 'application/pdf,image/jpeg,image/png,image/gif'
			},
			responseType: 'blob'
		})
			.then(({ data }) => {
				const file = new Blob([data], { type: data.type })
				const fileURL = URL.createObjectURL(file)
				window.open(fileURL)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	useEffect(() => {
		const file = consentDocumentField ? consentDocumentField[0] : data.consentDocument && data.consentDocument[0]
		if (!file) return

		setConsentDocumentFileName(file.name)
	}, [consentDocumentField, data])

	return (
		<BaseModal
			onClose={reset}
			onOpen={handleOpen}
			nameEvent={types.openAddShareholderModal}
			title={t('Shareholder')}
			isLoading={isLoading}
			maxWidth={890}
		>
			{({ closeModal }) => (
				<div>
					<form className={styles.container} onSubmit={handleSubmit(data => onSubmit(data, closeModal))}>
						{!watchMode && (
							<div className={styles.attentionCard}>
								<img className={styles.warningImage} src={dangerIcon} alt="Danger Icon" />
								<div>
									<h3 className={styles.cardTitle}>{t('Important')}</h3>
									<p className={styles.cardDescription}>{t('shareholdersDesc')}</p>
								</div>
							</div>
						)}
						<section className={styles.section}>
							<span className={styles.leftForm}>
								<SelectField
									disabled={isLoading || watchMode}
									required
									mb={20}
									label={t('salutation')}
									control={control}
									name="salutation"
									error={errors.salutation}
									placeholder="Select salutation"
									options={props.salutations?.map(v => ({
										value: v.value_EN,
										label: i18n.language === TLanguages.EN ? v.value_EN : v.value_DE
									}))}
								/>
							</span>
							<span className={styles.rightForm}>
								<TextField
									mb={20}
									label={t('Title Prefixed')}
									placeholder={t('Enter Title')}
									error={errors.titlePrefixed}
									register={register('titlePrefixed')}
									disabled={isLoading || watchMode}
								/>
							</span>
						</section>

						<section className={styles.section}>
							<span className={styles.leftForm}>
								<TextField
									required
									mb={20}
									disabled={isLoading || watchMode}
									label={t('First Name')}
									register={register('firstName')}
									error={errors.firstName}
									placeholder={t('Type First Name')}
								/>
							</span>
							<span className={styles.rightForm}>
								<TextField
									required
									mb={20}
									disabled={isLoading || watchMode}
									label={t('Last Name')}
									register={register('lastName')}
									error={errors.lastName}
									placeholder={t('Type Last Name')}
								/>
							</span>
						</section>

						<section className={styles.section}>
							<span className={styles.leftForm}>
								<TextField
									label={t('Title Suffixed')}
									disabled={isLoading || watchMode}
									error={errors.titleSuffixed}
									register={register('titleSuffixed')}
									placeholder={t('Enter Title')}
								/>
							</span>
							<span className={styles.rightForm}>
								<SelectField
									disabled={isLoading || watchMode}
									mb={20}
									label={t('gender')}
									control={control}
									name="gender"
									error={errors.gender}
									placeholder={t('Select Gender')}
									options={genderOption
										.map(v => ({ label: t(v.label), value: v.value }))
										.sort((a, b) => a.label.localeCompare(b.label))}
								/>
							</span>
						</section>

						<div className={styles.line} />

						<section className={styles.section}>
							<span className={styles.leftForm}>
								<SelectField
									required
									mb={20}
									disabled={isLoading || watchMode}
									label={t('Company Share')}
									error={errors.companyShare}
									control={control}
									name="companyShare"
									placeholder={t('Select the amount')}
									options={props.shareholderShares?.[props.reportType]?.map(v => ({
										value: v.value,
										label: v.value + '€'
									}))}
								/>
							</span>
						</section>
						<section className={styles.section}>
							{!watchMode && (
								<div style={{ marginLeft: 10, marginBottom: 15 }}>
									<span className={styles.linkDocument} onClick={getShareholderCertificateTemplate}>
										{t('Get certificate template')}
									</span>
								</div>
							)}
						</section>
						{!watchMode && (
							<section className={styles.section}>
								<Uploader
									disabled={isLoading}
									title={t('Warrant Upload')}
									error={errors.consentDocument}
									accept="application/pdf,image/jpeg,image/png,image/gif"
									nameFile={consentDocumentFileName}
									register={register('consentDocument')}
								/>
							</section>
						)}

						<div className={styles.line} />

						<section className={styles.section}>
							<span className={styles.leftForm}>
								<TextField
									required
									mb={20}
									disabled={isLoading || watchMode}
									label="Email"
									register={register('email')}
									error={errors.email}
									placeholder="shareholder@email.com"
								/>
							</span>
							<span className={styles.rightForm}>
								<TextField
									required
									mb={20}
									disabled={isLoading || watchMode}
									label={t('phone')}
									register={register('phone')}
									error={errors.phone}
									placeholder={t('phone')}
								/>
							</span>
						</section>

						<section className={styles.section}>
							<span className={styles.leftForm}>
								<TextField
									mb={20}
									disabled={isLoading || watchMode}
									label={t('Street')}
									register={register('street')}
									error={errors.street}
									placeholder={t('Street')}
								/>
							</span>
							<span className={styles.rightForm}>
								<TextField
									mb={20}
									disabled={isLoading || watchMode}
									label={t('House number')}
									register={register('houseNumber')}
									error={errors.houseNumber}
									placeholder={t('House number')}
								/>
							</span>
						</section>

						<section className={styles.section}>
							<span className={styles.leftForm}>
								<TextField
									mb={20}
									disabled={isLoading || watchMode}
									label={t('Zip Code')}
									register={register('zipCode')}
									error={errors.zipCode}
									placeholder={t('Zip Code')}
								/>
							</span>
							<span className={styles.rightForm}>
								<TextField
									mb={20}
									disabled={isLoading || watchMode}
									label={t('City')}
									register={register('city')}
									error={errors.city}
									placeholder={t('City')}
								/>
							</span>
						</section>

						{!watchMode && (
							<Btn
								loading={isLoading}
								width={214}
								className={styles.btn}
								type="submit"
								icon="arrow"
								iconSize={{ w: 10, h: 10 }}
								iconColor="#fff"
							>
								{t('save')}
							</Btn>
						)}
					</form>
				</div>
			)}
		</BaseModal>
	)
}
AddShareholderModal.propTypes = {
	reportType: PropTypes.string,

	salutations: PropTypes.array,
	shareholderShares: PropTypes.object,
	genders: PropTypes.array
}
const mapStateToProps = state => ({
	reportType: state.user.profile.EmploymentCompany?.companyType,

	salutations: state.options.salutations,
	shareholderShares: state.options.shareholderShares,
	genders: state.options.genders
})
export default connect(mapStateToProps, null)(AddShareholderModal)
