import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

import injectRole from '../../components/HOC/injectRole'

import { TRoles, TRoutes } from '../../shared/const'
import CreateSchool from '../CreateSchool'

function UpdateSchools(props) {
	if ([TRoles.ADMIN, TRoles.FEDERAL_ADMIN].includes(props.role)) return <CreateSchool />
	else return <Redirect to={TRoutes.DASHBOARD} />
}

UpdateSchools.propTypes = {
	role: PropTypes.string.isRequired
}

export default injectRole(UpdateSchools)
