import { useState, useEffect } from 'react'
import styles from '../style.module.scss'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import Joi from 'joi'
import http from '../../../../../shared/http'
import { TApi, TLanguages, genderOption } from '../../../../../shared/const'
import { schemaOptions } from '../../../../../shared/i18n'
import { joiPhone } from '../../../../../shared/helpers'
import { joiResolver } from '@hookform/resolvers/joi'
import PropTypes from 'prop-types'

import SelectField from '../../../../../UI/SelectField'
import TextField from '../../../../../UI/TextField'
import DateTimeField from '../../../../../UI/DateTimeField'
import Btn from '../../../../../UI/Btn'
import { subYears } from 'date-fns'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

const schema = Joi.object({
	salutation: Joi.string().required(),
	firstName: Joi.string()
		.min(2)
		.max(26)
		.regex(/^[a-zA-ZА-яЁёÄÖÜẞäöüß]+( [a-zA-ZА-яЁёÄÖÜẞäöüß]+)*$/)
		.required()
		.messages({
			'string.pattern.base': 'Only letters are allowed !'
		}),
	lastName: Joi.string()
		.min(2)
		.max(26)
		.regex(/^[a-zA-ZА-яЁёÄÖÜẞäöüß]+( [a-zA-ZА-яЁёÄÖÜẞäöüß]+)*$/)
		.required()
		.messages({
			'string.pattern.base': 'Only letters are allowed !'
		}),
	dateOfBirth: Joi.object().allow(null),
	phone: joiPhone,
	gender: Joi.string().required()
})

function BasicInfo(props) {
	const { t, i18n } = useTranslation()
	const {
		register,
		control,
		handleSubmit,
		reset,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema, {
			errors: {
				labels: false,
				language: localStorage.getItem('i18nextLng')
			},
			...schemaOptions
		})
	})
	const [isLoading, setLoading] = useState(false)

	useEffect(() => {
		reset({
			salutation: props.salutation,
			firstName: props.firstName,
			lastName: props.lastName,
			dateOfBirth: props.dateOfBirth && new Date(Date.parse(props.dateOfBirth)),
			phone: props.phone,
			gender: props.gender
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props])

	const onSubmit = data => {
		setLoading(true)

		http.put(TApi.STUDENT_PUT_PROFILE, data)
			.then(() => {
				toast.success(t('Profile has been updated successfully.'))
			})
			.finally(() => setLoading(false))
	}

	const minAge = subYears(new Date(), 6)
	const maxAge = subYears(new Date(), 20)

	return (
		<div className={styles.root}>
			<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
				<div className={styles.section}>
					<h1 className={styles.sectionTitle}>{t('Basic Info')}</h1>
					<div className={styles.sectionContent}>
						<div className={styles.flexContainer}>
							<SelectField
								required
								disabled={isLoading}
								mb={20}
								label={t('salutation')}
								control={control}
								name="salutation"
								error={errors.salutation}
								placeholder="Salutation"
								options={props.salutations?.map(v => ({
									value: v.value_EN,
									label: i18n.language === TLanguages.EN ? v.value_EN : v.value_DE
								}))}
							/>
							<SelectField
								className={styles.textField}
								mb={20}
								required
								label={t('gender')}
								error={errors.gender}
								placeholder={t('Select gender')}
								options={genderOption
									.map(v => ({ label: t(v.label), value: v.value }))
									.sort((a, b) => a.label.localeCompare(b.label))}
								control={control}
								name="gender"
							/>
						</div>
						<div className={styles.flexContainer}>
							<TextField
								required
								disabled={isLoading}
								mb={20}
								label={t('First Name')}
								placeholder={props.firstName}
								error={errors.firstName}
								register={register('firstName')}
							/>
							<TextField
								required
								disabled={isLoading}
								mb={20}
								label={t('Last Name')}
								placeholder={props.lastName}
								error={errors.lastName}
								register={register('lastName')}
							/>
						</div>

						<div className={styles.flexContainer}>
							<DateTimeField
								disabled={isLoading || props.isTeacherStudentView}
								mb={20}
								label={t('Date of Birth')}
								placeholder="Date of Birth"
								name="dateOfBirth"
								error={errors.dateOfBirth}
								control={control}
								dateFormat="dd.MM.yyyy"
								minDate={maxAge}
								maxDate={minAge}
							/>
							<TextField
								disabled={isLoading}
								mb={20}
								label={t('phone')}
								placeholder={props.phone}
								error={errors.phone}
								register={register('phone')}
							/>
						</div>
						{!props.isTeacherStudentView && (
							<div className={styles.btnContainer}>
								<Btn
									loading={isLoading}
									className={clsx(styles.btn)}
									type="submit"
									icon="arrow"
									iconSize={{ w: 10, h: 10 }}
									iconColor="#fff"
								>
									{t('save')}
								</Btn>
							</div>
						)}
					</div>
				</div>
			</form>
		</div>
	)
}
BasicInfo.propTypes = {
	salutation: PropTypes.string.isRequired,
	firstName: PropTypes.string.isRequired,
	lastName: PropTypes.string.isRequired,
	dateOfBirth: PropTypes.object,
	phone: PropTypes.string.isRequired,

	salutations: PropTypes.array.isRequired,
	isTeacherStudentView: PropTypes.bool.isRequired
}
const mapStateToProps = state => ({
	salutation: state.user.profile.salutation,
	firstName: state.user.profile.firstName,
	lastName: state.user.profile.lastName,
	dateOfBirth: state.user.profile.dateOfBirth,
	phone: state.user.profile.phone,
	gender: state.user.profile.gender,

	salutations: state.options.salutations
})

export default connect(mapStateToProps)(BasicInfo)
