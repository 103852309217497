import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import Btn from '../../../../../../UI/Btn'
import TextField from '../../../../../../UI/TextField'
import styles from '../style.module.scss'
import Joi from 'joi'
import { joiResolver } from '@hookform/resolvers/joi'
import { useTranslation } from 'react-i18next'

const schema = Joi.object({
	invoicesOverdue: Joi.object().keys({
		value: Joi.number().required()
	})
})

export default function InvoicesOverdue(props) {
	const { t } = useTranslation()
	const {
		register,
		reset,
		handleSubmit,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema)
	})

	const onSubmit = form => {
		props
			.updateOptions({ name: 'invoicesOverdue', value: form.invoicesOverdue })
			.then(() => toast.success(t('Options was updated')))
	}

	useEffect(() => {
		reset({ invoicesOverdue: props.form?.invoicesOverdue || {} })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.form])
	return (
		<form id="invoicesOverdue" className={styles.root} onSubmit={handleSubmit(onSubmit)}>
			<section className={styles.section}>
				<h3 className={styles.sectionTitle}>{t('Invoices overdue time')}</h3>
				<div className={styles.sectionContent}>
					<TextField
						className={styles.field}
						mb={20}
						label={t('days')}
						register={register(`invoicesOverdue.value`)}
						placeholder="Input new overdue time for invoices"
						error={errors.invoicesOverdue?.value}
					/>
					<div className={styles.btnContainer}>
						<Btn
							className={styles.btn}
							type="submit"
							icon="arrow"
							iconSize={{ w: 10, h: 10 }}
							iconColor="#fff"
						>
							{t('save')}
						</Btn>
					</div>
				</div>
			</section>
		</form>
	)
}
InvoicesOverdue.propTypes = {
	form: PropTypes.object.isRequired,
	updateOptions: PropTypes.func.isRequired
}
