import Joi from 'joi'
import clsx from 'clsx'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as queryString from 'query-string'
import { joiResolver } from '@hookform/resolvers/joi'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Btn from '../../UI/Btn'
import TextField from '../../UI/TextField'

import http from '../../shared/http'
import { TApi, TRoutes } from '../../shared/const'
import { schemaOptions } from '../../shared/i18n'

import styles from './common.module.scss'
import FlatLink from '../../UI/FlatLink'
import { toast } from 'react-toastify'
import PolicyLinks from '../../UI/policyLinks'

export default function NewPass() {
	const { t } = useTranslation()

	const schema = Joi.object({
		password: Joi.string().min(8).max(32).required(),
		repeatPassword: Joi.any()
			.empty('')
			.equal(Joi.ref('password'))
			.required()
			.error(errors => {
				return errors.map(error => {
					if (error.code === 'any.only') error.message = t('Repeat password does not match with Password')
					return error
				})
			})
	})

	const history = useHistory()
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema, {
			errors: {
				labels: false,
				language: localStorage.getItem('i18nextLng')
			},
			...schemaOptions
		})
	})
	const [isLoading, setLoading] = useState(false)
	const location = useLocation()

	const path = location.pathname

	const { token } = queryString.parse(location.search)

	const onSubmit = form => {
		setLoading(true)

		http.post(path === TRoutes.NEW_PASS ? TApi.RESET_PASSWORD : TApi.PASS_NEW_USER, {
			token,
			password: form.password
		})
			.then(() => {
				path === TRoutes.NEW_PASS
					? toast.success(t('Password changed successfully !'))
					: toast.success(t('Successfully updated'))
				history.push(TRoutes.SIGN_IN)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	if (!token) return <Redirect to={TRoutes.SIGN_IN} />

	return (
		<div className={styles.root}>
			<div className={styles.header}>
				<div className={styles.miniContainer}>
					<h2 className={styles.newPassTitle}>
						{path === TRoutes.NEW_PASS ? t('Password Recovery') : t('Set password for your new account')}
					</h2>
					<span className={styles.subtitle}>{t('Create a new password')}</span>
					<p style={{ marginTop: '30px' }} className={styles.accountMessage}>
						{t('Back to')}{' '}
						<FlatLink
							disabled={isLoading}
							to={TRoutes.SIGN_IN}
							icon="arrow"
							iconSize={{ w: 0, h: 0 }}
							iconColor="#008B9C"
						>
							{t('Sign In')}
						</FlatLink>
						{t('page')}
					</p>
				</div>
				<PolicyLinks />
			</div>
			<form className={clsx(styles.recoverContainer)} onSubmit={handleSubmit(onSubmit)}>
				<TextField
					mb={20}
					label={t('New password')}
					type="password"
					placeholder={t('New password')}
					error={errors.password}
					register={register('password')}
				/>
				<TextField
					label={t('Repeat new password')}
					type="password"
					placeholder={t('Repeat new password')}
					error={errors.repeatPassword}
					register={register('repeatPassword')}
				/>

				<Btn
					loading={isLoading}
					width={167}
					className={styles.btnRecover}
					type="submit"
					icon="arrow"
					iconSize={{ w: 10, h: 10 }}
					iconColor="#fff"
				>
					{t('save')}
				</Btn>
			</form>
		</div>
	)
}
