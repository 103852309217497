import styles from './style.module.scss'

export default function ProgressBar({ currentStep, ...props }) {
	const steps = {
		1: 6,
		2: 12,
		3: 18,
		4: 24,
		5: 37,
		6: 50,
		7: 62,
		8: 74,
		9: 86,
		10: 100
	}
	return (
		<div className={styles.root}>
			<div className={styles.bar}>
				<div
					className={styles.progress}
					style={
						props.fullFill
							? {
									width: `100%`,
									borderRadius: `10px`
							  }
							: {
									width: `${steps[currentStep] - 0.28}%`
							  }
					}
				/>
				{props.children}
			</div>
		</div>
	)
}
