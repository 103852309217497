import clsx from 'clsx'
import { connect } from 'react-redux'
import { useEffect, useState } from 'react'
import * as queryString from 'query-string'
import { useLocation } from 'react-router-dom'

import http from '../../../shared/http'
import { TApi, TRoles } from '../../../shared/const'

import Logo from '../../../assets/icons/logo.svg'

import styles from '../../../pages/Auth/common.module.scss'
import { logIn } from '../../../redux/ducks/user'
import { useTranslation } from 'react-i18next'

function ConfirmEmailChange(props) {
	const { t } = useTranslation()
	const [isLoading, setLoading] = useState(false)
	const [message, setMessage] = useState('')
	const [isStatusError, setStatusError] = useState(false)
	const { search } = useLocation()

	const { token } = queryString.parse(search)

	const fetchConfirm = () => {
		setLoading(true)

		const request =
			props.role === TRoles.STUDENT ? TApi.STUDENT_EMAIL_CHANGE_CONFIRM : TApi.TEACHER_EMAIL_CHANGE_CONFIRM

		http.put(
			request,
			{
				token
			},
			{
				disableToast: true
			}
		)
			.then(({ data }) => {
				http.defaults.headers['Authorization'] = `Bearer ${data.token}`
				http.get(TApi.COMMON_ME, {
					disableToast: true
				}).then(({ data }) => {
					props.logIn({
						role: data.role,
						profile: data
					})
				})
				localStorage.setItem(
					'auth',
					JSON.stringify({
						role: data.user.role,
						token: data.token
					})
				)
				setMessage(t('Email successfully changed'))
			})
			.catch(err => {
				const message = err.response?.data?.message || ''
				setMessage(`${err.message}. ${message}`)
				setStatusError(true)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	useEffect(() => {
		if (!token) {
			setStatusError(true)
			return setMessage(t('Token is not founded'))
		}

		fetchConfirm()
		/* eslint-disable-next-line */
	}, [])

	return (
		<div className={styles.root}>
			<div className={styles.header}>
				<div className={styles.miniContainer}>
					<h2 className={styles.title}>{t('Email change confirmation')}</h2>
				</div>
			</div>
			<div className={clsx(styles.container, styles.form, styles.confirmContentSuc)}>
				<img className={styles.logoImg} src={Logo} alt="Logo" />
				{isLoading && (
					<span className={styles.confirmText}>{t('Account confirmation is in progress. Loading...')}</span>
				)}
				{!!message && (
					<span className={clsx(styles.confirmText, isStatusError && styles.confirmTextError)}>
						{message}
					</span>
				)}
			</div>
		</div>
	)
}

const mapStateToProps = state => ({
	role: state.user.role
})

const mapDispatchToProps = dispatch => ({
	logIn: data => dispatch(logIn(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmailChange)
