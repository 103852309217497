import PropTypes from 'prop-types'
import styles from './style.module.scss'
import ProgressAvatar from '../../ProgressAvatar'
import { useTranslation } from 'react-i18next'

export default function CompanyItem(props) {
	const { t } = useTranslation()

	return (
		<div id={props.item} className={styles.root}>
			<div className={styles.info}>
				<ProgressAvatar src={props.avatar} name={props.name} round size={27} value={50} color="#303E65" />
				<h1 className={styles.name}> {props.name} </h1>
			</div>

			<div className={styles.info}>
				<span className={styles.txt}>
					{props.members} {props.members === 1 ? t('member') : t('Members').toLowerCase()}{' '}
				</span>
				{/*  <Btn className={styles.arrowBtn} icon="arrow" iconSize={{ w: 12, h: 12 }} iconColor="#008B9C" /> */}
			</div>
		</div>
	)
}

CompanyItem.propTypes = {
	id: PropTypes.number.isRequired,
	avatar: PropTypes.string,
	name: PropTypes.string.isRequired,
	members: PropTypes.number.isRequired
}
