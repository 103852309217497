import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

import ProfileTeacher from '../../components/views/teacher/Profile'
import ProfileStudent from '../../components/views/student/Profile'
import ProfileAdmin from '../../components/views/admin/Profile'

import injectRole from '../../components/HOC/injectRole'

import { TRoles, TRoutes } from '../../shared/const'

function Dashboard(props) {
	if (props.role === TRoles.STUDENT) return <ProfileStudent />
	else if (props.role === TRoles.TEACHER) return <ProfileTeacher />
	else if ([TRoles.ADMIN, TRoles.FEDERAL_ADMIN].includes(props.role)) return <ProfileAdmin />
	else return <Redirect to={TRoutes.DASHBOARD} />
}

Dashboard.propTypes = {
	role: PropTypes.string.isRequired
}

export default injectRole(Dashboard)
