import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useState, useEffect, useLayoutEffect } from 'react'

import eventEmitter from '../../shared/eventEmitter'

import styles from './BaseModal.module.scss'
import Icon from '../Icon'

export default function BaseModal(props) {
	const [isOpen, setOpen] = useState(props.defaultOpen)
	const [params, setParams] = useState(null)

	const handleOpen = (...args) => {
		setParams(args)
		setOpen(true)
	}

	const closeModal = () => {
		if (props.isLoading) return

		setOpen(false)
		if (props.onClose) props.onClose()
	}

	useEffect(() => {
		eventEmitter.on(props.nameEvent, handleOpen)
		return () => {
			eventEmitter.off(props.nameEvent, handleOpen)
		}
	}, [props.nameEvent])

	useLayoutEffect(() => {
		if (isOpen && props.onOpen) setTimeout(() => props.onOpen(...params))

		/* eslint-disable-next-line */
	}, [isOpen])

	if (!isOpen) return null

	return (
		<div className={styles.overlay}>
			<div style={{ maxWidth: props.maxWidth }} className={styles.modal}>
				<div className={clsx(styles.header, styles.container)}>
					<h3 className={styles.title}>{props.title}</h3>
					{!props.hideClose && (
						<button className={styles.btnClose} onClick={closeModal}>
							<Icon name="close" size={{ w: 12, h: 12 }} />
						</button>
					)}
				</div>
				<div className={styles.container}>{props.children({ closeModal })}</div>
			</div>
		</div>
	)
}

BaseModal.propTypes = {
	children: PropTypes.func.isRequired,
	nameEvent: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,

	disabledCrossClose: PropTypes.bool,
	isLoading: PropTypes.bool,

	maxWidth: PropTypes.number,

	hideClose: PropTypes.bool,
	defaultOpen: PropTypes.bool,

	onClose: PropTypes.func,
	onOpen: PropTypes.func
}

BaseModal.defaultProps = {
	disabledCrossClose: false,
	isLoading: false,
	defaultOpen: false,
	maxWidth: 522,

	hideClose: false
}
