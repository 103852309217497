import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import en from './en'
import ger from './ger'

export const gerErrors = {
	'date.min': 'muss größer oder gleich der aktuellen Uhrzeit sein',
	'array.hasUnknown': 'enthält nicht mindestens eine erforderliche Übereinstimmung',
	'any.default': 'hat beim Ausführen der Standardmethode einen Fehler ausgegeben',
	'any.failover': 'hat einen Fehler beim Ausführen der Failover-Methode ausgelöst',
	'any.invalid': 'enthält einen ungültigen Wert',
	// 'any.only': ' must be {if(#valids.length == 1, "", "one of ")}{{#valids}}',
	// 'any.ref': ' {{#arg}} references "{{#ref}}" which {{#reason}}',
	'any.required': 'erforderlich',
	'any.unknown': ' ist nicht erlaubt',

	'number.base': ' muss eine Zahl sein',
	'number.greater': ' muss größer als {{#limit}} sein',
	'number.infinity': ' kann nicht unendlich sein',
	'number.integer': ' muss eine ganze Zahl sein',
	'number.less': ' muss kleiner als {{#limit}} sein',
	'number.max': ' muss kleiner oder gleich {{#limit}} sein',
	'number.min': ' muss größer oder gleich {{#limit}} sein',
	'number.multiple': ' muss ein Vielfaches von {{#multiple}} sein',
	'number.negative': ' muss eine negative Zahl sein',
	'number.port': ' muss ein gültiger Port sein',
	'number.positive': ' muss eine positive Zahl sein',
	'number.precision': ' darf nicht mehr als {{#limit}} Dezimalstellen haben',
	'number.unsafe': ' muss eine sichere Nummer sein',

	'string.base': ' muss eine Zeichenfolge sein',
	'string.empty': ' darf nicht leer sein',
	'string.email': ' muss eine gültige E-Mail sein',
	'string.max': ' länge muss kleiner oder gleich {{#limit}} Zeichen lang sein',
	'string.min': ' länge muss mindestens {{#limit}} Zeichen lang sein',
	'string.pattern.base': 'Es sind nur Buchstaben erlaubt!',
	'string.uri': 'Bitte beachten Sie die URL-Syntax, z.B. https://www.junior.cc',

	'phoneNumber.invalid': 'telefonnummer ist nicht gültig',
	'confirm.invalid': 'datenschutzerklärung ist erforderlich',
	'repeat.password': 'repeat'
}

const enErrors = {
	'phoneNumber.invalid': ' Phone number is not valid',
	'confirm.invalid': 'confirm consent in checkbox !',
	'repeat.password': 'test',
	'date.min': 'must be greater than or equal to the current time',
	'array.hasUnknown': 'does not contain at least one required match',
	'any.default': 'returned an error while executing the standard method',
	'any.failover': 'threw an error while executing the failover method',
	'any.invalid': 'contains an invalid value',
	// 'any.only': 'must be {if (# valids.length == 1, "", "one of")} {{# valids}}',
	// 'any.ref': '{{#arg}} references "{{#ref}}" which {{#reason}}',
	'any.required': 'required',
	'any.unknown': 'is not allowed',

	'number.base': 'must be a number',
	'number.greater': 'must be greater than {{#limit}}',
	'number.infinity': 'cannot be infinite',
	'number.integer': 'must be an integer',
	'number.less': 'must be smaller than {{#limit}}',
	'number.max': 'must be less than or equal to {{#limit}}',
	'number.min': 'must be greater than or equal to {{#limit}}',
	'number.multiple': 'must be a multiple of {{#multiple}}',
	'number.negative': 'must be a negative number',
	'number.port': 'must be a valid port',
	'number.positive': 'must be a positive number',
	'number.precision': 'may not have more than {{#limit}} decimal places',
	'number.unsafe': 'must be a safe number',

	'string.base': 'must be a string',
	'string.empty': 'must not be empty',
	'string.email': 'must be a valid email',
	'string.max': 'length must be less than or equal to {{#limit}} characters long',
	'string.min': 'length must be at least {{#limit}} characters long',
	'string.pattern.base': 'only letters are allowed!',
	'string.uri': 'Please note the URL syntax e.g. https://www.junior.cc'
}

export const schemaOptions = {
	messages: {
		ger: { ...gerErrors },
		en: { ...enErrors }
	}
}

i18n.use(LanguageDetector).init({
	resources: {
		en,
		ger
	},
	fallbackLng: 'en',
	debug: true,
	ns: ['translations'],
	defaultNS: 'translations',
	// keySeparator: false, // we use content as keys

	interpolation: {
		escapeValue: false, // not needed for react!!
		formatSeparator: ','
	},
	react: {
		wait: true
	}
})

export default i18n
