import style from './style.module.scss'

export default function PolicyLinks() {
	return (
		<div className={style.policyLinkContainer}>
			<a className={style.impintLink} href="https://www.junior.cc/impressum" download>
				Impressum
			</a>
			<a className={style.impintLink} href="/data-policy.pdf" download>
				Datenschutzerklärung
			</a>
			<a className={style.poweredLink} href="https://davincilab.at/">
				Powered by DaVinciLab
			</a>
		</div>
	)
}
