import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'

import FlatLink from '../FlatLink'

import styles from './style.module.scss'
import { useTranslation } from 'react-i18next'
import { TRoutes } from '../../shared/const'

export default function MiniHeader(props) {
	const { t } = useTranslation()
	const history = useHistory()
	const { pathname } = useLocation()

	return (
		<div className={clsx(styles.root, props.className)} style={{ backgroundColor: props.bg }}>
			<div>
				{pathname !== TRoutes.DASHBOARD && (
					<FlatLink
						to="#"
						onClick={evt => {
							evt.preventDefault()
							history.goBack()
						}}
						className={styles.flatLink}
						classNameIcon={styles.arrowLeft}
						icon="arrow"
						color="#303E65"
						iconSize={{ w: 9, h: 9 }}
						iconColor="#008B9C"
					>
						{t('Go back')}
					</FlatLink>
				)}

				<h3 className={styles.title}>{props.title}</h3>
			</div>
			<div>{props.children}</div>
		</div>
	)
}

MiniHeader.propTypes = {
	title: PropTypes.string.isRequired,
	bg: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	className: PropTypes.string
}

MiniHeader.defaultProps = {
	bg: '#fff'
}
