import clsx from 'clsx'
import PropTypes from 'prop-types'

import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg'
import { ReactComponent as LockIcon } from '../../assets/icons/lock.svg'
import { ReactComponent as SwapIcon } from '../../assets/icons/swap.svg'
import { ReactComponent as SendIcon } from '../../assets/icons/Send.svg'
import { ReactComponent as HideIcon } from '../../assets/icons/Hide.svg'
import { ReactComponent as ShowIcon } from '../../assets/icons/Show.svg'
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg'
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg'
import { ReactComponent as ShareIcon } from '../../assets/icons/shares.svg'
import { ReactComponent as DangerIcon } from '../../assets/icons/danger.svg'
import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg'
import { ReactComponent as TicketIcon } from '../../assets/icons/ticket.svg'
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg'
import { ReactComponent as LogoutIcon } from '../../assets/icons/Logout.svg'
import { ReactComponent as SearchIcon } from '../../assets/icons/Search.svg'
import { ReactComponent as LoadingIcon } from '../../assets/icons/loading.svg'
import { ReactComponent as LanguageIcon } from '../../assets/icons/language.svg'
import { ReactComponent as CheckboxIcon } from '../../assets/icons/checkbox.svg'
import { ReactComponent as DocumentIcon } from '../../assets/icons/document.svg'
import { ReactComponent as ReportsIcon } from '../../assets/icons/Report Icon.svg'
import { ReactComponent as DownloadIcon } from '../../assets/icons/Property 36.svg'
import { ReactComponent as filterSearch } from '../../assets/icons/filterSearch.svg'
import { ReactComponent as FilterSecondIcon } from '../../assets/icons/filterIcon.svg'
import { ReactComponent as CheckForBoxIcon } from '../../assets/icons/box-for-check.svg'
import { ReactComponent as PaperParentalIcon } from '../../assets/icons/paperParental.svg'
import { ReactComponent as LocationIcon } from '../../assets/icons/location.svg'
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg'
import { ReactComponent as ThreeDotsIcon } from '../../assets/icons/threeDots.svg'
import { ReactComponent as BusineessPlan } from '../../assets/icons/businessPlan.svg'
import { ReactComponent as SaveIcon } from '../../assets/icons/save.svg'
import { ReactComponent as InsuranceIcon } from '../../assets/icons/insuranceIcon.svg'
import { ReactComponent as RefreshIcon } from '../../assets/icons/refresh-arrow.svg'
import { ReactComponent as MergeArrowIcon } from '../../assets/icons/merge-arrow.svg'
import { ReactComponent as ResendEmailIcon } from '../../assets/icons/mail-resend.svg'
import { ReactComponent as PublicIcon } from '../../assets/icons/public.svg'
import { ReactComponent as PrivateIcon } from '../../assets/icons/private.svg'

import styles from './style.module.scss'

const Icons = {
	arrow: ArrowIcon,
	edit: EditIcon,
	loading: LoadingIcon,
	document: DocumentIcon,
	paperParental: PaperParentalIcon,
	plus: PlusIcon,
	ticket: TicketIcon,
	filter: FilterIcon,
	filterSearch: filterSearch,
	close: CloseIcon,
	lock: LockIcon,
	location: LocationIcon,
	swap: SwapIcon,
	danger: DangerIcon,
	download: DownloadIcon,
	report: ReportsIcon,
	send: SendIcon,
	delete: DeleteIcon,
	hide: HideIcon,
	show: ShowIcon,
	checkbox: CheckboxIcon,
	checkForBox: CheckForBoxIcon,
	logout: LogoutIcon,
	filterSecond: FilterSecondIcon,
	search: SearchIcon,
	share: ShareIcon,
	language: LanguageIcon,
	threeDots: ThreeDotsIcon,
	business: BusineessPlan,
	save: SaveIcon,
	risk: InsuranceIcon,
	refresh: RefreshIcon,
	mergeArrow: MergeArrowIcon,
	resend: ResendEmailIcon,
	private: PrivateIcon,
	public: PublicIcon
}

export default function Icon(props) {
	const CIcon = Icons[props.name]

	const svgStyle = {
		fill: props.color,
		stroke: props.color
	}

	if (props.noFill) delete svgStyle.fill
	return (
		<span
			className={clsx(styles.root, props.className)}
			style={{
				width: props.size.w,
				height: props.size.h,
				...props.style,
				...svgStyle
			}}
		>
			<CIcon style={svgStyle} />
		</span>
	)
}

Icon.propTypes = {
	name: PropTypes.string.isRequired,
	color: PropTypes.string,
	className: PropTypes.string,
	size: PropTypes.shape({
		w: PropTypes.number.isRequired,
		h: PropTypes.number.isRequired
	}),
	style: PropTypes.object,
	noFill: PropTypes.bool
}

Icon.defaultProps = {
	color: '#ccc',
	className: '',
	size: {
		w: 16,
		h: 16
	},
	style: {},
	noFill: false
}
