import Joi from 'joi'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { joiResolver } from '@hookform/resolvers/joi'
import { TApi } from '../../../../../../shared/const'
import http from '../../../../../../shared/http'
import { schemaOptions } from '../../../../../../shared/i18n'
import { joiPhone } from '../../../../../../shared/helpers'
import { useTranslation } from 'react-i18next'

import TextField from '../../../../../../UI/TextField'
import Btn from '../../../../../../UI/Btn'
import styles from '../../style.module.scss'
import { toast } from 'react-toastify'
import { updateProfile } from '../../../../../../redux/ducks/user'

const schema = Joi.object({
	phone: joiPhone,
	email: Joi.string().email({ tlds: false }).required()
})

function Contact(props) {
	const { t } = useTranslation()
	const {
		register,
		trigger,
		handleSubmit,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema, {
			errors: {
				labels: false,
				language: localStorage.getItem('i18nextLng')
			},
			...schemaOptions
		}),
		defaultValues: { ...props.profile }
	})

	useEffect(() => {
		if (Object.keys(errors).length) trigger()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localStorage.getItem('i18nextLng')])

	const onSubmit = data => {
		props.setLoading(true)
		http.put(TApi.TEACHER_PUT_PROFILE_CONTACTS, data)
			.then(({ data }) => {
				props.updateProfile(data)
				toast.success(t('Contacts updated'))
			})
			.finally(() => props.setLoading(false))
	}

	return (
		<form className={styles.section} onSubmit={handleSubmit(onSubmit)}>
			<h1 className={styles.sectionTitle}>{t('contacts')}</h1>
			<div className={styles.sectionContent}>
				<TextField
					disabled={props.isLoading}
					mb={20}
					label={t('phone')}
					placeholder="+43 6XX XXXXXX"
					error={errors.phone}
					register={register('phone')}
				/>
				<div className={styles.flexContainer}>
					<TextField
						disabled
						mb={20}
						label={t('Your email')}
						placeholder="kenneth_ras_23@gmail.com"
						error={errors.email}
						register={register('email')}
					/>
					{
						<div className={styles.btnContainer}>
							<Btn
								className={styles.btnWithoutIcon}
								loading={props.isLoading}
								width={214}
								type="submit"
								icon="arrow"
								iconSize={{ w: 10, h: 10 }}
								iconColor="#fff"
							>
								{t('save')}
							</Btn>
						</div>
					}
				</div>
			</div>
		</form>
	)
}

const mapStateToProps = state => ({
	profile: {
		phone: state.user.profile.phone,
		email: state.user.profile.email
	}
})
const mapDispatchToProps = dispatch => ({
	updateProfile: data => dispatch(updateProfile(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Contact)
