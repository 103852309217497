import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

import injectRole from '../../../components/HOC/injectRole'

import { TRoles, TRoutes } from '../../../shared/const'
import Invoices from '../../../components/views/admin/Invoices'

function InvoicesPage(props) {
	if ([TRoles.ADMIN, TRoles.FEDERAL_ADMIN].includes(props.role)) return <Invoices />
	else return <Redirect to={TRoutes.DASHBOARD} />
}

InvoicesPage.propTypes = {
	role: PropTypes.string.isRequired
}

export default injectRole(InvoicesPage)
