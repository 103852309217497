import { useEffect } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import Btn from '../../../../../../UI/Btn'
import TextField from '../../../../../../UI/TextField'
import styles from '../style.module.scss'
import Joi from 'joi'
import { joiResolver } from '@hookform/resolvers/joi'
import { useTranslation } from 'react-i18next'
import RadioField from '../../../../../../UI/RadioField'

export default function Rates(props) {
	const { t } = useTranslation()

	const schema = Joi.object({
		default: Joi.string().required(),
		[props.name]: Joi.array().items(
			Joi.object().keys({
				value: Joi.number().required(),
				default: Joi.string().required()
			})
		)
	})
	const {
		control,
		register,
		reset,
		handleSubmit,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema),
		defaultValues: {
			default: '0'
		}
	})
	const { fields, append, remove } = useFieldArray({
		control, // control props comes from useForm (optional: if you are using FormContext)
		name: props.name // unique name for your Field Array
		// keyName: "id", default to "id", you can change the key name
	})

	const onSubmit = form => {
		props
			.updateOptions({
				name: props.name,
				value: { default: form.default, values: form[props.name] }
			})
			.then(() => toast.success(t('Options was updated')))
	}

	useEffect(() => {
		reset({
			[props.name]: props.form?.[props.name]?.values || [],
			default: props.form?.[props.name]?.default
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.form])
	return (
		<form id={props.name} className={styles.root} onSubmit={handleSubmit(onSubmit)}>
			<section className={styles.section}>
				<h3 className={styles.sectionTitle}>{props.title}</h3>
				<div className={styles.sectionContent}>
					{fields?.map((field, index) => (
						<div key={field.id} className={styles.block}>
							<label style={{ marginRight: 10 }}>
								<RadioField register={register(`default`)} value={index} />
							</label>
							<TextField
								className={styles.field}
								register={register(`${props.name}.${index}.value`)}
								placeholder={`Input new ${props.title.toLowerCase()}`}
								error={errors?.[props.name]?.[index]?.value || errors?.[props.name]?.[index]?.default}
							/>
							<Btn
								className={styles.deleteBtn}
								theme="white"
								icon="delete"
								iconColor="#000"
								type="button"
								onClick={() => remove(index)}
							/>
						</div>
					))}
					<Btn
						className={styles.addBtn}
						theme="white"
						icon="plus"
						iconColor="#22404D"
						iconSize={{ w: 9, h: 9 }}
						type="button"
						onClick={() => append({ value: '', default: 'false' })}
					/>
					<div className={styles.btnContainer}>
						<Btn
							className={styles.btn}
							type="submit"
							icon="arrow"
							iconSize={{ w: 10, h: 10 }}
							iconColor="#fff"
						>
							{t('save')}
						</Btn>
					</div>
				</div>
			</section>
		</form>
	)
}
Rates.propTypes = {
	name: PropTypes.string.isRequired,
	number: PropTypes.bool,
	title: PropTypes.string.isRequired,
	form: PropTypes.object.isRequired,
	updateOptions: PropTypes.func.isRequired
}
