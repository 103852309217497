import { useEffect, useMemo, useState } from 'react'
import Btn from '../../../../UI/Btn'
import Table from '../../../../UI/Table'
import MiniHeader from '../../../../UI/MiniHeader'

import styles from './style.module.scss'
import Search from '../../../../UI/Search'
import BtnFilter from '../../../../UI/BtnFilter'
import http from '../../../../shared/http'
import Pagination from '../../../../UI/Pagination'
import BtnTooltip from '../../../../UI/BtnTooltip'
import { useTranslation } from 'react-i18next'
import Filter from '../../../../UI/Filter'
import { StateOptions, TApi, TLanguages, TRoutes } from '../../../../shared/const'
import { format } from 'date-fns'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router'
import { blobTypes, customNumberFormat, downloadFile, generateFilter, reportData } from '../../../../shared/helpers'
import clsx from 'clsx'
import Icon from '../../../../UI/Icon'
import { connect } from 'react-redux'
import { setReportsFilter } from '../../../../redux/ducks/filter'

function Reports(props) {
	const { t, i18n } = useTranslation()
	const history = useHistory()
	const [search, setSearch] = useState('')
	const [reports, setReports] = useState([])
	const [countAll, setCountAll] = useState(0)
	const [page, setPage] = useState(0)
	const [sort, setSort] = useState({ name: 'companyName', direction: 'asc' })
	const [showFilter, setShowFilter] = useState(false)
	const [countFilters, setCountFilters] = useState(0)
	const [loading, setLoading] = useState(false)
	const limit = 10

	const fetchReports = () => {
		const filter = generateFilter(props.reportsFilter)

		http.get(TApi.ADMIN_GET_REPORTS, {
			params: {
				limit,
				offset: page * limit,
				order: sort.name,
				direction: sort.direction,
				search,
				filter: filter
			}
		}).then(({ data }) => {
			const arrayTable = data.rows.map(v => {
				const formatData = reportData(v.Company.companyType, {
					...v,
					insurance: data.insurance
				})
				return {
					...v,
					...formatData,
					companyName: v.Company.name,
					companyType: v.Company.companyType,
					branch: v.Company.branch,
					state: v.Company.state,
					createdAt: format(new Date(v.createdAt), 'dd.MM.yyyy'),
					school: v.Company?.TeachersSupervisions?.[0]?.Teacher?.School?.name,
					teacher: `${v.Company?.TeachersSupervisions?.[0]?.Teacher?.firstName} ${v.Company?.TeachersSupervisions?.[0]?.Teacher?.lastName}`
				}
			})
			setReports(arrayTable)
			setCountAll(data.count)
		})
	}
	const handleSort = (name, direction) => {
		setSort({ name, direction })
	}
	const handleSearch = () => {
		if (page === 0) fetchReports()
		setPage(0)
	}
	useEffect(() => {
		fetchReports()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, sort])

	useEffect(() => {
		if (Object.values(props.reportsFilter).every(item => !item.length)) fetchReports()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.reportsFilter])

	const columns = useMemo(
		() => [
			{
				Header: t('Company'),
				accessor: 'companyName',
				Cell: ({ row }) => (
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							width: '100%',
							marginRight: '10px',
							wordBreak: 'break-word'
						}}
					>
						<span style={{ overflow: 'hidden', width: 'auto' }}>{row.values.companyName}</span>
						<BtnTooltip
							items={[
								{
									disabled: !row.original.isSubmitted,
									label: t('View Report'),
									onClick: () => history.push(TRoutes.REPORT_REVIEW(row.original.id))
								},
								{
									disabled: !row.original.isSubmitted || row.original.Invoice?.status === 'Paid',
									label: t('Reject report'),
									onClick: () => {
										http.put(TApi.ADMIN_UNSUBMIT_REPORTS(row.original.id)).then(() => {
											toast.success('Report was unsubmitted')
											fetchReports()
										})
									}
								},
								{
									disabled: !row.original.isSubmitted,
									label: t('Download report'),
									onClick: () => downloadReport(row.original.id, row.original)
								}
							]}
						/>
					</div>
				),
				minWidth: 100
			},
			{
				Header: t('Typ'),
				accessor: 'companyType',
				width: 60
			},
			{
				Header: t('Rep.Typ'),
				accessor: 'type',
				Cell: ({ row }) => (
					<span
						style={{
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap'
						}}
					>
						{t(row.values.type)}
					</span>
				),
				width: 120
			},
			{
				Header: t('Br.'),
				headerToolTip: t('Branchе'),
				accessor: 'branch',
				Cell: ({ row }) => (
					<span style={{ overflow: 'hidden', wordBreak: 'break-word' }}>
						{i18n.language === TLanguages.DE
							? props.branch?.find(v => v.value_EN === row.values.branch)?.value_DE
							: props.branch?.find(v => v.value_EN === row.values.branch)?.value_EN}
					</span>
				),
				width: 120
			},
			{
				Header: t('FS'),
				headerToolTip: t('Fed. State'),
				accessor: 'state',
				cellToolTip: ({ row }) => t(StateOptions.find(({ value }) => value === row.original.state)?.label),
				Cell: ({ row }) =>
					t(StateOptions.find(({ value }) => value === row.original.state)?.label)
						.split(' ')
						.map(v => v.slice(0, 1))
						.join(''),
				width: 50
			},
			{
				Header: t('Rev.'),
				headerToolTip: t('Revenues'),
				accessor: 'revenues',
				width: 100,
				disableSortBy: true,
				Cell: ({ row }) => {
					return <div>{customNumberFormat(row.original.revenues)}</div>
				}
			},
			{
				Header: t('Exp.'),
				headerToolTip: t('Expanses'),
				accessor: 'expenses',
				width: 100,
				disableSortBy: true,
				Cell: ({ row }) => {
					return <div>{customNumberFormat(row.original.expenses)}</div>
				}
			},
			{
				Header: t('Res.'),
				headerToolTip: t('Result'),
				accessor: 'result',
				width: 100,
				disableSortBy: true,
				Cell: ({ row }) => {
					return <div>{customNumberFormat(row.original.result)}</div>
				}
			},
			{
				Header: t('Cha.'),
				headerToolTip: t('Charges'),
				accessor: 'charges',
				width: 100,
				disableSortBy: true,
				Cell: ({ row }) => {
					return <div>{customNumberFormat(row.original.charges)}</div>
				}
			},
			{
				Header: t('Rep.'),
				headerToolTip: t('Reported'),
				accessor: 'createdAt',
				width: 100
			},
			{
				Header: t('School'),
				accessor: 'school',
				Cell: ({ row }) => (
					<span style={{ overflow: 'hidden', wordBreak: 'break-word' }}>{row.values.school}</span>
				),
				minWidth: 50
			},
			{
				Header: t('Teacher'),
				accessor: 'teacher',
				Cell: ({ row }) => <span style={{ overflow: 'hidden' }}>{row.values.teacher}</span>,
				minWidth: 50
			}
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[t]
	)

	const downoloadList = fileType => {
		setLoading(true)
		http.get(TApi.ADMIN_DOWNOLOAD_REPORTS_LIST, {
			params: {
				type: fileType,
				leng: localStorage.getItem('i18nextLng')
			},
			responseType: 'arraybuffer'
		})
			.then(res => {
				let blob = new Blob([res.data], {
					type: blobTypes[fileType]
				})
				downloadFile('Reports', blob)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const downloadReport = (id, data) => {
		const fileName = `Berichte - ${data.companyType} - ${data.companyName} - ${data.type}`
		setLoading(true)
		http.get(TApi.ADMIN_DOWNLOAD_REPORT(id), {
			responseType: 'arraybuffer'
		})
			.then(res => {
				let blob = new Blob([res.data], {
					type: blobTypes.XLS
				})
				downloadFile(fileName, blob)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	return (
		<div className={styles.root}>
			<MiniHeader className={styles.miniHeader} title={t('reports')}>
				<div className={styles.miniHeaderContent}>
					<Search
						value={search}
						onChange={e => setSearch(e.target.value)}
						onSubmit={e => {
							e.preventDefault()
							handleSearch()
						}}
						className={styles.search}
						placeholder={t('Search reports')}
					/>
					<BtnFilter
						className={styles.filterBtn}
						icon="arrow"
						onClick={() => setShowFilter(true)}
						count={countFilters}
					>
						Filter ({countFilters})
					</BtnFilter>
				</div>
			</MiniHeader>
			<div className={styles.subHeader}>
				<Pagination limit={limit} page={page} label={t('reports')} countAll={countAll} onChange={setPage} />
				<div className={styles.downloadContent}>
					{t('Download list as')}
					<div>
						{!loading && (
							<Btn
								theme="download"
								iconSize={{ w: 24, h: 24 }}
								icon="download"
								onClick={() => {
									downoloadList('XLS')
								}}
							>
								XLS
							</Btn>
						)}
						{!loading && (
							<Btn
								theme="download"
								iconSize={{ w: 24, h: 24 }}
								icon="download"
								onClick={() => {
									downoloadList('PDF')
								}}
							>
								PDF
							</Btn>
						)}
						{loading && (
							<Icon
								className={clsx(styles.icon, styles.rootLoading)}
								size={{ w: 16, h: 16 }}
								name="loading"
								color={'#22404D'}
							/>
						)}
					</div>
				</div>
			</div>

			<div className="container">
				<Table
					page={page}
					items={reports}
					handleSort={handleSort}
					countAll={countAll}
					onChange={setPage}
					limit={limit}
					columns={columns}
				/>
			</div>
			<Filter
				toShow={{
					states: true,
					companyTypes: true,
					branches: true
				}}
				onSearch={fetchReports}
				showFilter={showFilter}
				setShowFilter={setShowFilter}
				onCountFilters={setCountFilters}
				onFilterOptions={props.setFilter}
				filters={props.reportsFilter}
			/>
		</div>
	)
}

const mapStateToProps = state => ({
	branch: state.options.branch,
	reportsFilter: state.filter.reports
})

const mapDispatchToProps = dispatch => ({
	setFilter: data => dispatch(setReportsFilter(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Reports)
