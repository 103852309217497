import Joi from 'joi'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { joiResolver } from '@hookform/resolvers/joi'
import { isValidPhoneNumber } from 'libphonenumber-js'

import Btn from '../../../../UI/Btn'
import TextField from '../../../../UI/TextField'
import SelectField from '../../../../UI/SelectField'
import AvatarUploader from '../../../../UI/AvatarUploader'

import http from '../../../../shared/http'
import { joiFile } from '../../../../shared/helpers'
import { TApi, TRoutes, genderOption, TLanguages } from '../../../../shared/const'

import { setAuth, setSignInMode, updateProfile } from '../../../../redux/ducks/user'

import styles from '../../common.module.scss'
import PolicyLinks from '../../../../UI/policyLinks'

const schema = Joi.object({
	avatar: joiFile(10),
	salutation: Joi.string().required(),
	gender: Joi.string().required(),
	titlePrefixed: Joi.string().min(2).max(26).allow(''),
	titleSuffixed: Joi.string().min(2).max(26).allow(''),
	firstName: Joi.string()
		.min(2)
		.max(26)
		.regex(/^[a-zA-ZА-яЁёÄÖÜẞäöüß]+( [a-zA-ZА-яЁёÄÖÜẞäöüß]+)*$/)
		.required()
		.messages({
			'string.pattern.base': 'Only letters are allowed !'
		}),
	lastName: Joi.string()
		.min(2)
		.max(26)
		.regex(/^[a-zA-ZА-яЁёÄÖÜẞäöüß]+( [a-zA-ZА-яЁёÄÖÜẞäöüß]+)*$/)
		.required()
		.messages({
			'string.pattern.base': 'Only letters are allowed !'
		}),

	phone: Joi.string()
		.custom((value, helper) => {
			if (isValidPhoneNumber(value)) return value

			return helper.message('Phone number is not valid')
		})
		.required()
})

function TeacherDetails(props) {
	const { t, i18n } = useTranslation()
	const {
		register,
		control,
		handleSubmit,
		setValue,
		watch,
		formState: { errors, isValid }
	} = useForm({
		resolver: joiResolver(schema),
		mode: 'onChange'
	})

	const history = useHistory()

	const [avatarSrc, setAvatarSrc] = useState('')
	const [avatarField] = watch(['avatar'])
	const [isLoading, setLoading] = useState(false)

	useEffect(() => {
		if (avatarField && avatarField[0]) setAvatarSrc(URL.createObjectURL(avatarField[0]))
	}, [avatarField])

	const handleAvatarUploaderClean = () => {
		setValue('avatar', '')
		setAvatarSrc('')
	}

	const onSubmit = fields => {
		const formData = new FormData()

		if (fields.avatar && fields.avatar[0]) formData.append('avatar', fields.avatar[0])

		formData.append('salutation', fields.salutation)
		formData.append('gender', fields.gender)
		formData.append('firstName', fields.firstName)
		formData.append('lastName', fields.lastName)
		formData.append('phone', fields.phone)
		formData.append('titlePrefixed', fields.titlePrefixed)
		formData.append('titleSuffixed', fields.titleSuffixed)

		setLoading(true)
		http.put(TApi.TEACHER_COMMON_PERSONAL_DETAILS, formData)
			.then(({ data }) => {
				props.updateProfile(data)
				props.setSignInMode(false)
				props.setAuth(true)
				toast.success(t('Registration was successful.'))
				history.push(TRoutes.DASHBOARD)
			})
			.finally(() => setLoading(false))
	}

	return (
		<div className={styles.root}>
			<div className={styles.header}>
				<div className={styles.container}>
					<h2 className={styles.miniTitle}>{t('SignUp.noun')}</h2>
					<h3 className={styles.step}>{t('Step 2')}</h3>
					<span className={styles.subtitle}>{t('Provide your personal details')}</span>
				</div>
				<PolicyLinks />
			</div>

			<form
				className={clsx(styles.container, styles.form, styles.formPersonalDetails)}
				autoComplete="off"
				onSubmit={handleSubmit(onSubmit)}
			>
				<AvatarUploader
					src={avatarSrc}
					disabled={isLoading}
					error={errors.avatar}
					register={register('avatar')}
					onClean={handleAvatarUploaderClean}
					className={styles.avatarUploader}
				/>
				<section className={styles.section}>
					<div className={styles.leftForm}>
						<SelectField
							required
							mb={20}
							label={t('salutation')}
							control={control}
							name="salutation"
							error={errors.salutation}
							placeholder={t('Select salutation')}
							options={props.salutations?.map(v => ({
								value: v.value_EN,
								label: i18n.language === TLanguages.EN ? v.value_EN : v.value_DE
							}))}
							disabled={isLoading}
						/>
					</div>
					<div className={styles.rightForm}>
						<SelectField
							required
							disabled={isLoading}
							mb={20}
							label={t('gender')}
							control={control}
							name="gender"
							error={errors.gender}
							placeholder={t('Select gender')}
							options={genderOption
								.map(v => ({ label: t(v.label), value: v.value }))
								.sort((a, b) => a.label.localeCompare(b.label))}
						/>
					</div>
				</section>
				<TextField
					mb={20}
					label={t('Title Prefixed')}
					placeholder={t('Enter Title')}
					error={errors.titlePrefixed}
					register={register('titlePrefixed')}
					disabled={isLoading}
				/>

				<section className={styles.section}>
					<div className={styles.leftForm}>
						<TextField
							required
							mb={20}
							label={t('First Name')}
							placeholder={t('Type First Name')}
							error={errors.firstName}
							register={register('firstName')}
							disabled={isLoading}
						/>
					</div>
					<div className={styles.rightForm}>
						<TextField
							required
							mb={20}
							label={t('Last Name')}
							placeholder={t('Type Last Name')}
							error={errors.lastName}
							register={register('lastName')}
							disabled={isLoading}
						/>
					</div>
				</section>

				<TextField
					mb={20}
					label={t('Title Suffixed')}
					placeholder={t('Enter Title')}
					error={errors.titleSuffixed}
					register={register('titleSuffixed')}
					disabled={isLoading}
				/>

				<div className={styles.line} />

				<TextField
					required
					mb={20}
					label={t('phone')}
					placeholder="+43 6XX XXXXXX"
					error={errors.phone}
					register={register('phone')}
					disabled={isLoading}
				/>

				<div className={styles.line} />

				<Btn
					type="submit"
					width={214}
					disabled={!isValid}
					className={styles.btnSignUp}
					iconSize={{ w: 10, h: 10 }}
					iconColor="#fff"
					loading={isLoading}
					icon="arrow"
				>
					{t('SignUp.verb')}
				</Btn>
			</form>
		</div>
	)
}

TeacherDetails.propTypes = {
	salutations: PropTypes.array.isRequired,
	genders: PropTypes.array.isRequired,
	updateProfile: PropTypes.func.isRequired,
	setSignInMode: PropTypes.func.isRequired,
	setAuth: PropTypes.func.isRequired
}
const mapStateToProps = state => ({
	salutations: state.options.salutations,
	genders: state.options.genders
})

const mapDispatchToProps = dispatch => ({
	updateProfile: data => dispatch(updateProfile(data)),
	setSignInMode: value => dispatch(setSignInMode(value)),
	setAuth: value => dispatch(setAuth(value))
})

export default connect(mapStateToProps, mapDispatchToProps)(TeacherDetails)
