import Joi from 'joi'
import clsx from 'clsx'
import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { connect } from 'react-redux'
import http from '../../../../shared/http'

import Btn from '../../../../UI/Btn'
import RadioField from '../../../../UI/RadioField'

import { INVITATION_TYPE, TApi, TCompanies } from '../../../../shared/const'
import { schemaOptions } from '../../../../shared/i18n'

import styles from '../../common.module.scss'
import { useTranslation } from 'react-i18next'
import PolicyLinks from '../../../../UI/policyLinks'

const schema = Joi.object({
	companyType: Joi.string().required().messages({ 'string.base': `Choose one of the types of company !` })
})

function CreateCompany(props) {
	const { t } = useTranslation()
	const {
		register,
		trigger,
		setValue,
		handleSubmit,
		formState: { errors, isValid }
	} = useForm({
		resolver: joiResolver(schema, {
			errors: {
				labels: false,
				language: localStorage.getItem('i18nextLng')
			},
			...schemaOptions
		}),
		mode: 'onChange',
		defaultValues: {
			companyType: TCompanies.JC
		}
	})
	const onSubmit = ({ companyType }) => {
		props.setCompanyType(companyType)
		if (INVITATION_TYPE === companyType) {
			http.put(TApi.STUDENT_SET_AWAIT_INVITATION, { isAwait: true }).then(v => props.setStep(4))
			return
		}
		props.setStep(5)
	}

	useEffect(() => {
		if (Object.keys(errors).length) trigger()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [t])

	useEffect(() => {
		if (!props.companyId) setValue('companyType', INVITATION_TYPE)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.companyId])

	return (
		<div className={styles.root}>
			<div className={styles.header}>
				<div className={styles.container}>
					<h2 className={styles.miniTitle}>{t('SignUp.noun')}</h2>
					<h3 className={styles.step}>{t('Create Company')}</h3>
					<span className={styles.subtitle}>{t('Choose company Type')}</span>
				</div>
				<PolicyLinks />
			</div>

			<form className={clsx(styles.container, styles.form)} autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
				<div className={styles.companyTypeContainer}>
					<span className={styles.companyType}>
						<label className={styles.companyCheckboxContainer}>
							<RadioField
								error={errors.companyType}
								register={register('companyType')}
								value={TCompanies.JC}
							/>
							<span className={styles.companyCard}>Junior Company</span>
						</label>
						<p className={styles.companyDescription}>
							{t(
								'Classical Junior Company for 15-19 year old students. Duration 1 school year (2 semesters)'
							)}
						</p>
					</span>
					<span className={styles.companyType}>
						<label className={styles.companyCheckboxContainer}>
							<RadioField
								error={errors.companyType}
								register={register('companyType')}
								value={TCompanies.JBC}
							/>
							<span className={styles.companyCard}>Junior Basic Company</span>
						</label>
						<p className={styles.companyDescription}>
							{t(
								'Simplified Junior Company for 7th-9th grade. Duration 1 semester. Simplified Accounting.'
							)}
						</p>
					</span>
					<span className={styles.companyType}>
						<label className={styles.companyCheckboxContainer}>
							<RadioField
								error={errors.companyType}
								register={register('companyType')}
								value={TCompanies.JCC}
							/>
							<span className={styles.companyCard}>Junior Compact Company</span>
						</label>
						<p className={styles.companyDescription}>
							{t('Shortened Junior Company for 9th-13th grade students. Can be done in 1 semester.')}
						</p>
					</span>
					<span className={styles.companyType}>
						<label className={styles.companyCheckboxContainer}>
							<RadioField
								error={errors.companyType}
								register={register('companyType')}
								value={INVITATION_TYPE}
							/>
							<span className={styles.companyCard}>{t('Wait Invitation')}</span>
						</label>
						<p className={styles.companyDescription}>
							{t('Wait for an invitation from an existing company')}
						</p>
					</span>
				</div>

				<div className={styles.line} />

				<Btn
					loading={props.isLoading}
					type="submit"
					disabled={!isValid}
					width={150}
					className={styles.btnSignUp}
					iconSize={{ w: 10, h: 10 }}
					iconColor="#fff"
					icon="arrow"
				>
					{t('Create')}
				</Btn>
			</form>
		</div>
	)
}

CreateCompany.propTypes = {
	setStep: PropTypes.func.isRequired,
	setCompanyType: PropTypes.func.isRequired,
	companyId: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
	companyId: state.user.profile.companyId
})

export default connect(mapStateToProps)(CreateCompany)
