import PropTypes from 'prop-types'

import styles from '../style.module.scss'
import Btn from '../../../../../../UI/Btn'
import { schemaOptions } from '../../../../../../shared/i18n'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { toast } from 'react-toastify'
import Joi from 'joi'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import TextFieldNumber from '../../../../../../UI/TextFieldNumber'
import Decimal from 'decimal.js'
import { connect } from 'react-redux'

function CorporateIncome(props) {
	const { t } = useTranslation()

	/* Option2 to fix #93 function roundNumber(num, scale) {
		if(!("" + num).includes("e")) {
		  return +(Math.round(num + "e+" + scale)  + "e-" + scale);
		} else {
		  var arr = ("" + num).split("e");
		  var sig = ""
		  if(+arr[1] + scale > 0) {
			sig = "+";
		  }
		  return +(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
		}
	  }
	  var roundedNum = roundNumber(1.005, 2); // Returns 1.01 */

	const schema = Joi.object({
		profitLossBeforeIncomeTax: Joi.any(),
		corporateIncomeTax: Joi.number()
			.required()
			.custom((value, helper) => {
				const roundedIncomeTax = new Decimal(props.form.profitLossBeforeIncomeTax)
					.mul(0.25)
					.toDecimalPlaces(2)
					.toNumber()
				if (Math.abs(roundedIncomeTax - value) < 0.01) return value
				return helper.message(t('Wrong result'))
			})
			.messages({
				'any.required': 'is not allowed to be empty'
			}),
		founderBonus: Joi.number()
			.required()
			.custom((value, helper) => {
				const roundedFounderBonus = new Decimal(helper.state.ancestors[0].corporateIncomeTax)
					.mul(0.2)
					.toDecimalPlaces(2)
					.toNumber()
				if (Math.abs(roundedFounderBonus - value) < 0.01) return value
				return helper.message(t('Wrong result'))
			})
			.messages({
				'any.required': `is not allowed to be empty`
			}),
		reducedCorporateIncomeTax: Joi.number()
			.required()
			.custom((value, helper) => {
				const roundedReducedIncomeTax = new Decimal(helper.state.ancestors[0].corporateIncomeTax)
					.minus(helper.state.ancestors[0].founderBonus)
					.toDecimalPlaces(2)
					.toNumber()
				if (Math.abs(roundedReducedIncomeTax - value) < 0.01) return value
				return helper.message(t('Wrong result'))
			})
			.messages({
				'any.required': `is not allowed to be empty`
			})
	})

	const [validate, setValidate] = useState(false)

	const {
		control,
		handleSubmit,
		reset,
		formState: { errors }
	} = useForm({
		resolver: validate
			? null
			: joiResolver(schema, {
					errors: {
						labels: false,
						language: localStorage.getItem('i18nextLng')
					},
					...schemaOptions
			  })
	})

	const onSubmit = form => {
		let data = form

		for (const key in data) {
			if (data[key] === '') {
				data[key] = null
			}
		}
		props
			.updateData(data)
			.then(() => {
				toast.success(t('Calculation of corporate income tax was updated'))
				if (!validate) props.handlerNextStep()
			})
			.finally(() => {
				setValidate(false)
			})
	}

	useEffect(() => {
		reset({
			profitLossBeforeIncomeTax: props.form?.profitLossBeforeIncomeTax,
			corporateIncomeTax: props.form?.corporateIncomeTax,
			founderBonus: props.form?.founderBonus,
			reducedCorporateIncomeTax: props.form?.reducedCorporateIncomeTax
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.form])

	if (props.currentStep !== props.needStep) return null
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<section className={styles.section}>
				<h1 className={styles.sectionTitle}>{t('Calculation of corporate income tax')}</h1>
				<div className={styles.sectionContent}>
					<div className={styles.flexContainer}>
						<TextFieldNumber
							className={styles.field}
							name="profitLossBeforeIncomeTax"
							disabled
							control={control}
							placeholder="00.000,00"
							error={errors.profitLossBeforeIncomeTax}
							label={`= ${t('Profit/loss (before corporate income tax)')}`}
						/>
						<p className={styles.description}>
							<p>{t('Profit/loss (before corporate income tax)')}</p>
							{t(
								'Preliminary profit/loss before deduction of corporate income tax, which is due only in case of profit.'
							)}
						</p>
					</div>
					<div className={styles.flexContainer}>
						<TextFieldNumber
							label={t('25% corporate income tax')}
							name="corporateIncomeTax"
							control={control}
							error={errors.corporateIncomeTax}
							disabled={props.isLoading || props.form?.isSubmitted}
							mb={20}
							placeholder="00.000,00"
						/>
						<p className={styles.description}>
							<p>{t('Corporate income tax')}</p>
							{t('Corporate income is taxed at a fixed rate of 25%.')}
						</p>
					</div>
					<div className={styles.flexContainer}>
						<TextFieldNumber
							label={`- ${t('Founder`s bonus (20% of corporate income tax)')}`}
							name="founderBonus"
							control={control}
							error={errors.founderBonus}
							disabled={props.isLoading || props.form?.isSubmitted}
							mb={20}
							placeholder="00.000,00"
						/>

						<p className={styles.description}>
							<p>{t('Founder`s bonus (20% of corporate income tax)')}</p>
							{t(
								'This represents a tax relief of 20% of the corporate income tax exclusively for junior companies.'
							)}{' '}
						</p>
					</div>
					<div className={styles.flexContainer}>
						<TextFieldNumber
							label={`= ${t('Reduced corporate income tax')}`}
							name="reducedCorporateIncomeTax"
							control={control}
							error={errors.reducedCorporateIncomeTax}
							disabled={props.isLoading || props.form?.isSubmitted}
							mb={20}
							placeholder="00.000,00"
						/>
						<p className={styles.description}>
							<p>{t('Reduced corporate income tax')}</p>
							{t('The calculated amount represents the deductible corporate income tax.')}
						</p>
					</div>
					{!props.form?.isSubmitted && !props.isTeacherStudentView ? (
						<div className={styles.flexContainer}>
							<Btn className={styles.btn} loading={props.isLoading} type="submit" theme="whiteBackground">
								{t('Validate')}
							</Btn>

							<Btn
								className={styles.btn}
								loading={props.isLoading}
								type="submit"
								onClick={() => setValidate(true)}
							>
								{t('save')}
							</Btn>
						</div>
					) : (
						<div className={styles.flexContainer}>
							<Btn className={styles.btn} loading={props.isLoading} onClick={props.handlerPrevStep}>
								{t('Previous')}
							</Btn>
							<Btn className={styles.btn} loading={props.isLoading} onClick={props.handlerNextStep}>
								{t('Next')}
							</Btn>
						</div>
					)}
				</div>
			</section>
		</form>
	)
}
CorporateIncome.propTypes = {
	form: PropTypes.object,
	updateData: PropTypes.func,
	currentStep: PropTypes.number,
	needStep: PropTypes.number,
	handlerNextStep: PropTypes.func,
	handlerPrevStep: PropTypes.func,
	isTeacherStudentView: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
	isTeacherStudentView: state.user.isTeacherStudentView
})

export default connect(mapStateToProps)(CorporateIncome)
