import { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styles from './style.module.scss'
import Icon from '../Icon'
import clsx from 'clsx'

export default function BtnOption(props) {
	const toggleContainer = useRef(null)
	const [isOpen, setOpen] = useState(false)

	useEffect(() => {
		document.addEventListener('click', handleClick)
		return () => document.removeEventListener('click', handleClick)
		function handleClick(e) {
			if (toggleContainer && toggleContainer.current) {
				const ref = toggleContainer.current
				if (!ref.contains(e.target)) {
					setOpen(false)
				}
			}
		}
	}, [])

	return (
		<div ref={toggleContainer} className={clsx(styles.root, props.className)}>
			<button className={styles.btn} onClick={() => setOpen(!isOpen)}>
				<Icon name="threeDots" />
			</button>
			<div className={clsx(styles.popup, isOpen ? props.opened : styles.closed)}>
				<ul className={styles.options}>
					{props.options.map(val => (
						<li className={styles.option} onClick={val.handler}>
							{val.lable}
						</li>
					))}
				</ul>
			</div>
		</div>
	)
}
BtnOption.propTypes = {
	className: PropTypes.string,
	options: PropTypes.array
}
BtnOption.defaultProps = {
	options: []
}
