import { API_URL } from './config'

export class TRoutes {
	static MAIN = '/'
	static AUTH = '/auth'

	static SIGN_IN = `${TRoutes.AUTH}/signin`
	static SIGN_UP = `${TRoutes.AUTH}/signup`
	static RECOVER_PASS = `${TRoutes.AUTH}/request-password-reset`
	static NEW_PASS = `${TRoutes.AUTH}/reset-password/`
	static NEW_PASS_NEW_USER = `${TRoutes.NEW_PASS}new-user`
	static AUTH_CONFIRM = `${TRoutes.AUTH}/confirm`
	static EXPERT_CONFIRM = `${TRoutes.AUTH}/expert/confirm`

	static DASHBOARD = `/dashboard`
	static COMPANIES = `/companies`
	static ADMIN_COMPANIES = `/admin-companies`
	static ADMIN_DOCUMENTS = `/admin-documents`
	static STUDENTS = `/students`
	static DOCUMENTS = `/documents`
	static NOTIFICATIONS = `/notifications`

	static COMPANY = `/company`
	static COMPANY_INSURANCE = `/company-insurance`
	static COMPANY_REVIEW = (id = ':id') => `/company-review/${id}`
	static COMPANY_REPORT_JUNIOR = `/company-report-junior`
	static COMPANY_EMPLOYEE = `${TRoutes.COMPANY}-employee`
	static COMPANY_EXPERTS = `${TRoutes.COMPANY}-experts`
	static SHARES = `/shares`
	static REPORTS = `/reports`
	static REPORTS_TABLE = `/reports-table`
	static REPORT_REVIEW = (id = ':id') => `/report/${id}`
	static REPORTS_COMPANY = `${TRoutes.REPORTS}/company`
	static REPORTS_COMPANY_2ND = `${TRoutes.REPORTS}/company/2nd`
	static REPORTS_COMPACT = `${TRoutes.REPORTS}/compact`
	static REPORTS_BASIC = `${TRoutes.REPORTS}/basic`

	static PROFILE = `/profile`
	static EMAIL_CHANGE_CONFIRM = `/confirm/email`
	static STUDENT_EMAIL_CHANGE_CONFIRM = `/student/confirm/email`
	static ADMIN_EMAIL_CHANGE_CONFIRM = `/admin/confirm/email`

	static USERS = `/users`
	static INVOICES = `/invoices`

	static EMAILS = `/emails`
	static NEW_EMAIL_TEMPLATE = `${TRoutes.EMAILS}/new-template`
	static NEWS = `/news`
	static ADMINS = `/admins`
	static SCHOOLS = `/schools`
	static SETTINGS = `/settings`
	static PDFs = `/settings/documents`
	static EXPERTS = `/experts`
	static CREATE_SCHOOL = `${TRoutes.SCHOOLS}/create`
	static UPDATE_SCHOOL = (id = ':id') => `${TRoutes.SCHOOLS}/update/${id}`
	static UPDATE_COMPANY = (id = ':id') => `${TRoutes.COMPANY}/update/${id}`

	static NOT_FOUND = `/not-found`
}

export class TApi {
	static SIGN_IN = '/api/public/auth/signin'
	static PUBLIC_AUTH_SING_UP = '/api/public/auth/signup'

	//static SING_UP_USER_STUDENT = '/api/public/auth/signup/student'
	//static SING_UP_USER_STUDENT_INVITE = '/api/public/auth/signup/student/invite'
	//static SING_UP_USER_TEACHER = '/api/public/auth/signup/teacher'
	//static SING_UP_USER_TEACHER_INVITE = '/api/public/auth/signup/teacher/invite'
	static SIGN_UP_CONFIRM = `/api/public/auth/signup/confirm`
	static EXPERT_CONFIRM = 'api/public/auth/experts/confirm'

	static EMAIL_CHANGE_CONFIRM = `/api/private/teacher/profile/email/confirm`
	static STUDENT_EMAIL_CHANGE_CONFIRM = `/api/private/student/profile/email/confirm`
	static TEACHER_EMAIL_CHANGE_CONFIRM = `/api/private/teacher/profile/email/confirm`

	static COMMON_ME = '/api/private/common/me'
	static COMMON_RESEND_EMAIL = '/api/private/common/resend-email'
	static COMMON_GET_OPTIONS = '/api/public/options'
	static COMMON_GET_STATS = '/api/public/statistic'
	static COMMON_GET_COMPANIES = '/api/public/companies'
	static COMMON_GET_SCHOOLS = 'api/public/schools'

	static STUDENT_COMMON_PERSONAL_DETAILS = '/api/private/student/auth/personal-details'
	static STUDENT_COMMON_CREATE_COMPANY = '/api/private/student/auth/create-company'
	static STUDENT_SET_AWAIT_INVITATION = '/api/private/student/auth/set-await-invitation'

	static STUDENT_GET_COMPANY = '/api/private/student/company'
	static STUDENT_GET_COMPANY_SUPERVISION = '/api/private/student/company/supervision'
	static STUDENT_GET_DASHBOARD = '/api/private/student/dashboard'
	static STUDENT_PUT_SET_REVIEW = '/api/private/student/company/review'
	static STUDENT_GET_NEWS = '/api/private/student/news'
	static STUDENT_GET_EMPLOEES = '/api/private/student/employees'
	static STUDENT_GET_INVITED_EMPLOYEES = '/api/private/student/employees/invited'
	static STUDENT_MAKE_CEO = userId => `/api/private/student/users/${userId}`
	static STUDENT_DELETE_EMPLOYEE = userId => `/api/private/student/company/employees/${userId}`

	static STUDENT_GET_DOCUMENTS = '/api/private/student/documents'
	static STUDENT_DOWNLOAD_DOCUMENT = name => `/api/private/student/documents/${name}`

	static STUDENT_GET_SCHOOLS = '/api/private/student/schools'
	static STUDENT_GET_TEACHERS = '/api/private/student/teachers'
	static STUDENT_PUT_PROFILE = '/api/private/student/profile'
	static STUDENT_EXPERTS = '/api/private/student/experts'
	static STUDENT_CHANGE_EXPERTS = id => `/api/private/student/experts/${id}`
	static ADMIN_PUT_PROFILE = '/api/private/admin/profile'
	static ADMIN_EMAIL_CHANGE = `${TApi.ADMIN_PUT_PROFILE}/email`
	static ADMIN_PASSWORD_CHANGE = `${TApi.ADMIN_PUT_PROFILE}/password`
	static ADMIN_EMAIL_CHANGE_CONFIRM = `/api/private/admin/profile/email/confirm`

	static STUDENT_PUT_COMPANY_SUPERVISION = '/api/private/student/company/supervision'
	static STUDENT_POST_INVITE_EMPLOYEE = '/api/private/student/company/employees/invite'
	static STUDENT_POST_UPLOAD_BUSINESS_PLAN = '/api/private/student/documents/business-plan'
	static STUDENT_POST_UPLOAD_FINAL_REPORT = '/api/private/student/documents/final-report'
	static STUDENT_DELETE_INVITE_EMPLOYEE = id => `/api/private/student/company/employees/invite/${id}`
	static STUDENT_GET_EMPLOYEES = '/api/private/student/company/employees'
	static STUDENT_PUT_EMPLOYEES = id => `/api/private/student/employees/${id}`
	static STUDENT_COMPANY_INSURANCE = `/api/private/student/insurance`

	static STUDENT_POST_INVITE_TEACHER = 'api/private/student/company/teachers/invite'
	static STUDENT_DELETE_INVITE_TEACHER = id => `/api/private/student/company/teachers/invite/${id}`
	static STUDENT_GET_COMPANY_TEACHERS = '/api/private/student/company/teachers'
	static STUDENT_DELETE_COMPANY_TEACHER = id => `/api/private/student/company/teachers/delete/${id}`
	static STUDENT_ADD_TEACHER = 'api/private/student/company/teachers/add'
	static STUDENT_CREATE_SCHOOL = 'api/private/common/schools'

	static STUDENT_GET_SHARES = '/api/private/student/shareholders'
	static STUDENT_DOWNOLOAD_SHARE_CONSENT = name => `/api/private/student/shareholders/${name}`
	static STUDENT_DELETE_SHARE = id => `api/private/student/shareholders/${id}`
	static STUDENT_ADD_SHAREHOLDER = '/api/private/student/shareholders'
	static STUDENT_GET_SHAREHOLDER_COMPANY_TYPE = 'api/private/student/shareholders/companyType'
	static STUDENT_POST_INVOICES = '/api/private/student/invoices'
	static STUDENT_GET_SHAREHOLDER_TEMPLATE = '/api/private/student/shareholders/shareholder-certificate-template'

	static TEACHER_PUT_PROFILE = '/api/private/teacher/profile'
	static TEACHER_PUT_PROFILE_CONTACTS = '/api/private/teacher/profile/contacts'
	static TEACHER_PROFILE_AVATAR = '/api/private/teacher/profile/avatar'
	static TEACHER_COMMON_PERSONAL_DETAILS = '/api/private/teacher/auth/personal-details'

	static TEACHER_GET_SCHOOLS = '/api/private/teacher/schools'
	static TEACHER_PUT_PROFILE_PASSWORD = '/api/private/teacher/profile/password'
	static STUDENT_EMAIL_CHANGE = `${TApi.STUDENT_PUT_PROFILE}/email`
	static STUDENT_PASSWORD_CHANGE = `${TApi.STUDENT_PUT_PROFILE}/password`
	static STUDENT_AVATAR_UPLOAD = `${TApi.STUDENT_PUT_PROFILE}/avatar`
	static STUDENT_AVATAR_DELETE = `${TApi.STUDENT_PUT_PROFILE}/avatar`
	static STUDENT_PARENTAL_CONFIRMATION = `${TApi.STUDENT_PUT_PROFILE}/parental-confirmation`
	static STUDENT_PARENTAL_CONFIRMATION_TEMPLATE = `${TApi.STUDENT_PUT_PROFILE}/parental-confirmation-template`
	static STUDENT_REPORTS = `api/private/student/reports`
	static STUDENT_PUT_REPORTS = id => `/api/private/student/reports/${id}`
	static PUBLIC_PROFILE_AVATAR = name => `${API_URL}/api/public/files/avatar/${name}`
	static PUBLIC_GET_FILES_DOCUMENT = name => `${API_URL}/api/public/files/document/${name}`

	static ADMIN_GET_FILES_DOCUMENT = name => `${API_URL}/api/private/admin/files/document/${name}`
	static ADMIN_REVIEW_PARENTAL_CONFIRMATION = id => `${API_URL}/api/private/admin/users/students/${id}/reviewed`
	static ADMIN_REJECT_PARENTAL_CONFIRMATION = id => `${API_URL}/api/private/admin/users/students/${id}/reject`
	static ADMIN_ACTIVATE_TEACHER = id => `api/private/admin/users/teachers/activate/${id}`

	static COMMON_GET_NOTIFICATIONS = '/api/private/common/notifications'
	static COMMON_MARK_READ_NOTIFICATIONS = '/api/private/common/notifications'

	static TEACHER_GET_STUDENTS = '/api/private/teacher/students/all'
	static TEACHER_GET_ASSIGNED_STUDENTS = '/api/private/teacher/students/assigned'
	static TEACHER_GET_TOKEN_STUDENT = id => `/api/private/teacher/students/token/${id}`
	//changed mock
	static TEACHER_GET_COMPANIES = '/api/private/teacher/companies/assigned'
	static TEACHER_GET__ASSIGNED_COMPANIES = 'api/private/teacher/companies/assigned'
	static TEACHER_GET_DOCUMENTS = '/api/private/teacher/documents'
	static TEACHER_DOWNLOAD_DOCUMENT = name => `/api/private/teacher/documents/${name}`

	static TEACHER_GET_ALL_COMPANIES = '/api/private/teacher/companies/all'
	static TEACHER_GET_ASSIGNED_COMPANIES = '/api/private/teacher/companies/assigned'
	static TEACHER_ASSIGN_REQUEST = '/api/private/teacher/companies/request'
	static STUDENT_ASSIGN_REQUEST_ID = id => `/api/private/student/teachers/request/${id}`
	static STUDENT_ACCEPT_REQUEST = '/api/private/student/teachers/request/accept'

	static RECOVER_PASS = '/api/auth/recover'
	static NEW_PASS = '/api/auth/recover/newpass'

	static TEACHER_GET_DASHBOARD = '/api/private/teacher/dashboard'
	static TEACHER_GET_REPORTS = '/api/private/teacher/reports'

	static COMPANY_PROFILE_UPDATE = `company/profile/update`
	static COMPANY_PUT_PROFILE = '/api/private/student/company'

	static REQUEST_PASSWORD_RESET = '/api/public/auth/forgot-password/request-password-reset'
	static RESET_PASSWORD = '/api/public/auth/forgot-password/reset-password'
	static PASS_NEW_USER = '/api/public/auth/signup/new-user-password'

	static PROFILE_UPDATE = `/profile/update`

	static CREATE_SCHOOL = `/api/private/admin/schools`

	static ADD_SHAREHOLDER = '/company/user/add/shareholder'
	static ADD_EXPERT = '/company/user/add/expert'
	static ADD_TEACHER = '/company/user/add/teacher'
	static ADD_EMPLOYEE = '/company/user/add/employee'

	static CUSTOMIZE_COMPANIES_WIDGET = '/widgets/customize/companies'
	static CUSTOMIZE_INCOME_WIDGET = '/widgets/customize/income'
	static CUSTOMIZE_MULTI_WIDGET = '/widgets/customize/multi'
	static CUSTOMIZE_TABLE_FIELDS = '/widgets/customize/table-fields'

	static TEACHER_GET_NEWS = '/api/private/teacher/news'

	static ADMIN_GET_NEWS = '/api/private/admin/news'
	static ADMIN_POST_NEWS = '/api/private/admin/news'
	static ADMIN_PUT_NEWS = id => `/api/private/admin/news/${id}`
	static ADMIN_DELETE_NEWS = id => `/api/private/admin/news/${id}`
	static ADMIN_GET_SCHOOLS = '/api/private/admin/schools'
	static ADMIN_PUT_SCHOOLS_MERGE = '/api/private/admin/schools/merge'
	static ADMIN_PUT_TEACHERS_MERGE = '/api/private/admin/users/teachers/merge'
	static ADMIN_SCHOOL_ID = id => `api/private/admin/schools/${id}`
	static ADMIN_SCHOOL_VERIFY = id => `api/private/admin/schools/${id}/verify`
	static ADMIN_GET_COMPANY = id => `/api/private/admin/company/${id}`
	static ADMIN_GET_COMPANY_PROFILE = id => `/api/private/admin/company/profile/${id}`
	static ADMIN_GET_COMPANIES = '/api/private/admin/companies/'
	static ADMIN_DELETE_COMPANY = id => `/api/private/admin/companies/${id}`
	static ADMIN_DOWNOLOAD_COMPANIES_LIST = '/api/private/admin/companies/downoload/list'
	static ADMIN_DOWNLOAD_COMPANY_DATA = id => `/api/private/admin/companies/download/${id}`
	static ADMIN_GET_TEACHERS = '/api/private/admin/users/teachers'
	static ADMIN_PARENTAL_CONFIRMATION = parentalConfirmationHashName =>
		`/api/private/admin/company/parental-confirmation/${parentalConfirmationHashName}`
	static ADMIN_PUT_COMPANY_COMMENT = id => `api/private/admin/company/${id}/comment`
	static ADMIN_PUT_COMPANY_ACTIVATION = id => `api/private/admin/company/${id}/activation`
	static ADMIN_PUT_REGENERATE_COMPANY_CERTIFICATE = id => `api/private/admin/company/${id}/regenerate`
	static ADMIN_PUT_COMPANY_REJECT = id => `api/private/admin/company/${id}/reject`
	static ADMIN_EXPERTS = '/api/private/admin/experts'
	static ADMIN_EXPERT_ID = id => `/api/private/admin/experts/${id}`
	static ADMIN_CONFIRM_EXPERT = id => `/api/private/admin/experts/confirm/${id}`
	static ADMIN_UNCONFIRM_EXPERT = id => `/api/private/admin/experts/unconfirm/${id}`
	static ADMIN_RESEND_EMAIL_EXPERT = id => `/api/private/admin/experts/resend/${id}`
	static ADMIN_GET_LIST_DOCUMENTS = '/api/private/admin/documents'

	static ADMIN_UPDATE_WIDGET_SETTINGS = '/api/private/admin/widgets/settings'
	static ADMIN_WIDGET_GET_COMPANIES_PENDING = '/api/private/admin/widgets/companies-pending'
	static ADMIN_WIDGET_GET_INVOICES_OVERDUE = '/api/private/admin/widgets/invoices-overdue'
	static ADMIN_WIDGET_GET_COMPANIES = '/api/private/admin/widgets/companies'
	static ADMIN_WIDGET_GET_STUDENTS = '/api/private/admin/widgets/students'
	static ADMIN_WIDGET_GET_REPORTS = '/api/private/admin/widgets/reports'
	static ADMIN_WIDGET_GET_REPORTS_DATA = '/api/private/admin/widgets/reports-data'
	static ADMIN_WIDGET_GET_TEACHERS = '/api/private/admin/widgets/teachers'
	static ADMIN_WIDGET_GET_TURNOVERS = '/api/private/admin/widgets/turnovers'
	static ADMIN_WIDGET_GET_INVOICES = '/api/private/admin/widgets/invoices'
	static ADMIN_WIDGET_GET_MULTI = '/api/private/admin/widgets/multi'
	static ADMIN_STATE_ADMINS = '/api/private/admin/state-admins'
	static ADMIN_STATE_ADMINS_ID = id => `/api/private/admin/state-admins/${id}`
	static ADMIN_GET_USERS = '/api/private/admin/users'
	static ADMIN_DOWNOLOAD_USERS_LIST = '/api/private/admin/users/downoload/list'
	static ADMIN_GET_INVOICES = '/api/private/admin/invoices'
	static ADMIN_DOWNOLOAD_INVOICES_LIST = '/api/private/admin/invoices/downoload/list'
	static ADMIN_GET_REPORTS = '/api/private/admin/reports'
	static ADMIN_DOWNOLOAD_REPORTS_LIST = '/api/private/admin/reports/downoload/list'
	static ADMIN_GET_REPORT = id => `/api/private/admin/reports/${id}`
	static ADMIN_DOWNLOAD_REPORT = id => `/api/private/admin/reports/download/calculation/${id}`
	static TEACHER_GET_REPORT = id => `/api/private/teacher/reports/${id}`
	static TEACHER_DOWNOLOAD_REPORTS_LIST = '/api/private/teacher/reports/downoload/list'
	static ADMIN_DOWNOLOAD_REPORTS_XLS = '/api/private/admin/reports/downoload/xls'
	static ADMIN_UNSUBMIT_REPORTS = id => `/api/private/admin/reports/delete/${id}`
	static ADMIN_PUT_INVOICES = id => `/api/private/admin/invoices/${id}`
	static ADMIN_DOWNLOAD_INVOICES = id => `api/private/admin/invoices/download/${id}`
	static ADMIN_DOWNLOAD_DOCUMENT = name => `/api/private/admin/documents/${name}`
	static ADMIN_OPTIONS = 'api/private/admin/options/'
	static ADMIN_GET_DASHBOARD = 'api/private/admin/dashboard'
	static ADMIN_PUT_USER = id => `api/private/admin/users/${id}`
	static ADMIN_CLEAR_DB = '/api/private/admin/clear/'
	static ADMIN_DOWNLOAD_ANONIMIZED_FILE = '/api/private/admin/clear/download'

	static ADMIN_GET_DOCUMENTS = 'api/private/admin/files/documents'
	static ADMIN_GET_PARENTAL = id => `api/private/admin/files/document/${id}`
	static ADMIN_DOWNOLOAD_SCHOOLS_LIST = '/api/private/admin/schools/downoload/list'
	static ADMIN_DOWNOLOAD_EXPERTS_LIST = '/api/private/admin/experts/downoload/list'
	static ADMIN_GET_COMPANY_TEACHERS = id => `/api/private/admin/company/${id}/teachers`
	static ADMIN_ADD_TEACHER = `/api/private/admin/company/teachers/add`
	static ADMIN_INVITE_TEACHER = `/api/private/admin/company/teachers/invite`
	static ADMIN_DELETE_COMPANY_TEACHER = (companyId, teacherId) =>
		`/api/private/admin/company/teachers/${companyId}/${teacherId}`
	static ADMIN_GET_TOKEN_USER = id => `/api/private/admin/users/token/${id}`
}
export const INVITATION_TYPE = 'INVITATION_TYPE'

export class TRoles {
	static STUDENT = 'STUDENT'
	static TEACHER = 'TEACHER'
	static ADMIN = 'ADMINISTRATOR'
	static FEDERAL_ADMIN = 'FEDERAL_ADMINISTRATOR'
}

export class TCompanies {
	static JC = 'JC'
	static JBC = 'JBC'
	static JCC = 'JCC'
}

export class TNews {
	static NEWS = 'NEWS'
	static EVENT = 'EVENT'
}

export class TReports {
	static ANNUAL = 'ANNUAL'
	static SEMIANNUAL = 'SEMIANNUAL'
}

export class TParentalConfirmationTypes {
	static PDF = 'application/pdf'
}

export class TConsentDocumentTypes {
	static PDF = 'application/pdf'
}

export class TStates {
	static ALL = 'ALL' // тут не можна змінювати чи переклдати значення
	static BURGENLAND = 'BURGENLAND'
	static CARINTHIA = 'CARINTHIA'
	static LOWER_AUSTRIA = 'LOWER_AUSTRIA'
	static SALZBURG = 'SALZBURG'
	static STYRIA = 'STYRIA'
	static TYROL = 'TYROL'
	static UPPER_AUSTRIA = 'UPPER_AUSTRIA'
	static VIENNA = 'VIENNA'
	static VORARLBERG = 'VORARLBERG'
}

export class DOCUMENT_TYPES {
	static INVOICE = 'INVOICE'
	static REPORT = 'REPORT'
	static BUSINESS_PLAN = 'BUSINESS_PLAN'
	static PARENTAL_CONFIRMATION = 'PARENTAL_CONFIRMATION'
	static CONSENT_DOCUMENT = 'CONSENT_DOCUMENT'
	static PARTICIPATION_DOCUMENT = 'PARTICIPATION_DOCUMENT'
	static COMPANY_CERTIFICATE = 'COMPANY_CERTIFICATE'
	static SHAREHOLDER_CERTIFICATE = 'SHAREHOLDER_CERTIFICATE'
	static SHARE_TEMPLATE = 'SHARE_TEMPLATE'
}
export class TGender {
	static MALE = 'MALE'
	static FEMALE = 'FEMALE'
	static DIVERS = 'DIVERS'
}

export const DocumentOptions = [
	{
		value: DOCUMENT_TYPES.INVOICE,
		label: 'Invoice'
	},
	{
		value: DOCUMENT_TYPES.REPORT,
		label: 'Business report'
	},
	{
		value: DOCUMENT_TYPES.BUSINESS_PLAN,
		label: 'Business plan'
	},
	{
		value: DOCUMENT_TYPES.PARENTAL_CONFIRMATION,
		label: 'Parental confirmation'
	},
	{
		value: DOCUMENT_TYPES.CONSENT_DOCUMENT,
		label: 'Consent document'
	},
	{
		value: DOCUMENT_TYPES.PARTICIPATION_DOCUMENT,
		label: 'Certificate of completion'
	},
	{
		value: DOCUMENT_TYPES.COMPANY_CERTIFICATE,
		label: 'Company certificate'
	},
	{
		value: DOCUMENT_TYPES.SHAREHOLDER_CERTIFICATE,
		label: 'Shareholder certificate'
	},
	{
		value: DOCUMENT_TYPES.SHARE_TEMPLATE,
		label: 'Shareholder certificate template'
	}
]

export const CompaniesOptions = [
	{
		value: TCompanies.JBC,
		label: 'JBC'
	},
	{
		value: TCompanies.JC,
		label: 'JC'
	},
	{
		value: TCompanies.JCC,
		label: 'JCC'
	}
]

export const CompaniesOptionsFull = [
	{
		value: TCompanies.JBC,
		label: 'Junior Basic Company'
	},
	{
		value: TCompanies.JC,
		label: 'Junior Company'
	},
	{
		value: TCompanies.JCC,
		label: 'Junior Compact Company'
	}
]

export const StateOptions = [
	// TStates.ALL must be added separately
	{
		value: TStates.BURGENLAND,
		label: 'Burgenland'
	},
	{
		value: TStates.CARINTHIA,
		label: 'Carinthia'
	},
	{
		value: TStates.LOWER_AUSTRIA,
		label: 'Lower Austria'
	},
	{
		value: TStates.SALZBURG,
		label: 'Salzburg '
	},
	{
		value: TStates.STYRIA,
		label: 'Styria'
	},
	{
		value: TStates.TYROL,
		label: 'Tyrol'
	},
	{
		value: TStates.UPPER_AUSTRIA,
		label: 'Upper Austria'
	},
	{
		value: TStates.VIENNA,
		label: 'Vienna'
	},
	{
		value: TStates.VORARLBERG,
		label: 'Vorarlberg'
	}
]

export const TWidgetTypes = {
	LINK: 'LINK',
	TWO_SECTIONS: 'TWO_SECTIONS',
	THREE_SECTIONS: 'THREE_SECTION',
	FOUR_SECTIONS: 'FOUR_SECTIONS',
	FOUR_SECTIONS_DATA: 'FOUR_SECTIONS_DATA'
}

export const TReject = {
	COMPANY: 'COMPANY',
	PARENTAL: 'PARENTAL'
}

export const TWidgetDomains = {
	COMPANIES: 'COMPANIES',
	STUDENTS: 'STUDENTS',
	TEACHERS: 'TEACHERS',
	INVOICES: 'INVOICES',
	REPORTS: 'REPORTS',
	TURNOVER: 'TURNOVER',
	COMPANIES_PENDING: 'COMPANIES_PENDING',
	OVERDUE_INVOICES: 'OVERDUE_INVOICES',
	REPORT_DATA: 'REPORT_DATA'
}

export const optionsDate = {
	day: '2-digit',
	month: '2-digit',
	year: 'numeric',
	hour: 'numeric',
	minute: '2-digit',
	second: '2-digit'
}
export const optionsDateWhioutTime = {
	day: '2-digit',
	month: '2-digit',
	year: 'numeric'
}

export const TNotifications = {
	COMPANY_CONFIRMATION: 'COMPANY_CONFIRMATION',
	PARENTAL_CONFIRMATION: 'PARENTAL_CONFIRMATION',
	TEACHER_CONFIRMATION: 'TEACHER_CONFIRMATION',
	REJECT_COMPANY: 'REJECT_COMPANY',
	REPORT_SUBMITTING: 'REPORT_SUBMITTING',
	USERS_JOINING: 'USERS_JOINING',
	PAYMENT_REQUEST: 'PAYMENT_REQUEST',
	PAYMENT_DONE: 'PAYMENT_DONE',
	PROGRAM_FINISH: 'PROGRAM_FINISH',
	NEED_REVIEW: 'NEED_REVIEW',
	REJECT_PARENTAL: 'REJECT_PARENTAL',
	ASSIGN_REQUEST: 'ASSIGN_REQUEST',
	REJECT_ASSIGN_REQUEST: 'REJECT_ASSIGN_REQUEST',
	INVOICE_PAYMENT_REMINDER: 'INVOICE_PAYMENT_REMINDER',
	REJECT_REPORT: 'REJECT_REPORT',
	REPORT_REMINDER: 'REPORT_REMINDER',
	INVITE_EMPLOYEE: 'INVITE_EMPLOYEE',
	INVITE_TEACHER: 'INVITE_TEACHER',
	ADD_EXPERT: 'ADD_EXPERT',
	CONFIRM_EXPERT: 'CONFIRM_EXPERT',
	SUBMIT_RISK: 'SUBMIT_RISK',
	SUBMIT_BUSINESS_PLAN: 'SUBMIT_BUSINESS_PLAN',
	SUBMIT_PARENTAL: 'SUBMIT_PARENTAL',
	FINISH_COMPANY: 'FINISH_COMPANY',
	FIRST_ENTER: 'FIRST_ENTER',
	ACTIVATE_COMPANY_REMINDER: 'ACTIVATE_COMPANY_REMINDER',
	SUBMIT_APPROVAL_COMPANY_REMINDER: 'SUBMIT_APPROVAL_COMPANY_REMINDER',
	INVOICE_GENERATED: 'INVOICE_GENERATED',
	INVOICE_PART_PAID: 'INVOICE_PART_PAID',
	TEACHER_FIRST_ENTER: 'TEACHER_FIRST_ENTER',
	TEACHER_NEW_COMPANY: 'TEACHER_NEW_COMPANY',
	TEACHER_COMPANY_ACTIVATED: 'TEACHER_COMPANY_ACTIVATED',
	TEACHER_REPORT_SUBMITTED: 'TEACHER_REPORT_SUBMITTED',
	TEACHER_FINANCIAL_REPORT_SUBMITTED: 'TEACHER_FINANCIAL_REPORT_SUBMITTED',
	TEACHER_REPORT_REMINDER: 'TEACHER_REPORT_REMINDER',
	TEACHER_INVOICE_REMINDER: 'TEACHER_INVOICE_REMINDER',
	COMPANY_READY_FOR_SUBMISSION: 'COMPANY_READY_FOR_SUBMISSION',
	REPORT_SUBMITTING_FINAL_REPORT: 'REPORT_SUBMITTING_FINAL_REPORT',
	CREATING_NEWS: 'CREATING_NEWS',
	CREATING_EVENT: 'CREATING_EVENT'
}

export const genderOption = [
	{
		value: TGender.MALE,
		label: 'Männlich'
	},
	{
		value: TGender.FEMALE,
		label: 'Weiblich'
	},
	{
		value: TGender.DIVERS,
		label: 'Divers'
	}
]
export class TLanguages {
	static EN = 'en'
	static DE = 'ger'
}
