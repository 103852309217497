import { useState, useEffect } from 'react'
import styles from '../style.module.scss'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form'
import clsx from 'clsx'
import Joi from 'joi'
import http from '../../../../../shared/http'
import { TApi } from '../../../../../shared/const'
import { schemaOptions } from '../../../../../shared/i18n'
import { joiResolver } from '@hookform/resolvers/joi'
import PropTypes from 'prop-types'

import TextField from '../../../../../UI/TextField'
import Btn from '../../../../../UI/Btn'
import { useTranslation } from 'react-i18next'

const schema = Joi.object({
	street: Joi.string().min(2).max(50).allow(''),
	city: Joi.string().allow(''),
	houseNumber: Joi.number().empty('').allow(null).default(null),
	postalCode: Joi.number().empty('').allow(null).default(null)
})

function JuniorInfo(props) {
	const { t } = useTranslation()
	const {
		register,
		reset,
		handleSubmit,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema, {
			errors: {
				labels: false,
				language: localStorage.getItem('i18nextLng')
			},
			...schemaOptions
		})
	})
	const [isLoading, setLoading] = useState(false)

	useEffect(() => {
		reset({
			street: props.street ?? '',
			city: props.city ?? '',
			houseNumber: props.houseNumber,
			postalCode: props.postalCode
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props])

	const onSubmit = data => {
		setLoading(true)

		http.put(TApi.ADMIN_PUT_PROFILE, { ...data })
			.then(() => {
				toast.success(t('Profile has been updated successfully.'))
			})
			.finally(() => setLoading(false))
	}

	return (
		<div className={styles.root}>
			<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
				<div className={styles.section}>
					<h1 className={styles.sectionTitle}>{t('Residential address')}</h1>
					<div className={styles.sectionContent}>
						<div className={styles.flexContainer}>
							<TextField
								disabled={isLoading}
								mb={20}
								label={t('Street')}
								placeholder={t('Street')}
								error={errors.street}
								register={register('street')}
							/>
							<TextField
								disabled={isLoading}
								mb={20}
								label={t('House number')}
								placeholder={t('House number')}
								error={errors.houseNumber}
								register={register('houseNumber')}
							/>
						</div>
						<div className={clsx(styles.flexContainer, styles.location)}>
							<TextField
								disabled={isLoading}
								mb={20}
								label={t('Zip Code')}
								placeholder={t('Zip Code')}
								error={errors.postalCode}
								register={register('postalCode')}
							/>
							<TextField
								disabled={isLoading}
								mb={20}
								label={t('City')}
								placeholder={t('City')}
								error={errors.city}
								register={register('city')}
							/>
						</div>

						<div className={styles.btnContainer}>
							<Btn
								loading={isLoading}
								className={clsx(styles.btn)}
								type="submit"
								icon="arrow"
								iconSize={{ w: 10, h: 10 }}
								iconColor="#fff"
							>
								{t('save')}
							</Btn>
						</div>
					</div>
				</div>
			</form>
		</div>
	)
}
JuniorInfo.propTypes = {
	street: PropTypes.string.isRequired,
	city: PropTypes.string.isRequired,
	houseNumber: PropTypes.number.isRequired,
	postalCode: PropTypes.number.isRequired
}
const mapStateToProps = state => ({
	street: state.user.profile.street,
	city: state.user.profile.city,
	houseNumber: state.user.profile.houseNumber,
	postalCode: state.user.profile.postalCode
})

export default connect(mapStateToProps)(JuniorInfo)
