import truncate from 'truncate'
import PropTypes from 'prop-types'
import { parseISO, format as formatDate } from 'date-fns'

import FlatLink from '../../../FlatLink'

import styles from './style.module.scss'
import eventEmitter, { types } from '../../../../shared/eventEmitter'

export default function FeedItem(props) {
	return (
		<div className={styles.root}>
			<div className={styles.wrapper}>
				<button className={styles.titleBtn}>
					<h4 className={styles.title}> {props.title} </h4>
				</button>
				<p className={styles.description}> {truncate(props.description, 120)} </p>
			</div>
			<div className={styles.wrapperFooter}>
				{(props.startDate || props.endDate) && (
					<span className={styles.date}>
						{props.startDate ? formatDate(parseISO(props.startDate), 'dd MMM RRRR') : 'now'} -{' '}
						{props.endDate ? formatDate(parseISO(props.endDate), 'dd MMM RRRR') : 'future'}
					</span>
				)}
				<FlatLink
					className={styles.viewDetail}
					to="#"
					onClick={() =>
						eventEmitter.emit(types.openDetailFeedModal, {
							id: props.id,
							title: props.title,
							description: props.description,
							externalLink: props.externalLink,
							place: props.place,
							startDate: props.startDate,
							endDate: props.endDate,
							states: props.states,
							roles: props.roles,
							companyTypes: props.companyTypes
						})
					}
					icon="arrow"
					color="#303E65"
					iconSize={{ w: 9, h: 9 }}
					iconColor="#008B9C"
				>
					View Detail
				</FlatLink>
			</div>
		</div>
	)
}

FeedItem.propTypes = {
	id: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	place: PropTypes.string,
	externalLink: PropTypes.string,
	startDate: PropTypes.string,
	endDate: PropTypes.string,
	states: PropTypes.array,
	roles: PropTypes.array,
	companyTypes: PropTypes.array
}
