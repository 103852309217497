import clsx from 'clsx'
import PropTypes from 'prop-types'

import styles from './style.module.scss'

export default function Checkbox(props) {
	return (
		<label style={props.mb ? { marginBottom: props.mb } : {}} className={styles.root}>
			{props.error && <span className={styles.error}>{props.error.message}</span>}

			<input
				disabled={props.disabled}
				className={styles.input}
				type="checkbox"
				value={props.value}
				{...(props.register || {})}
			/>
			<div className={styles.content}>
				<div>
					<span className={clsx(styles.checkmark, props.classNameCheckbox)} />
					{props.required ? <div className={styles.require}>*</div> : ''}
				</div>
				<p className={clsx(styles.label, props.classNameLabel)}>{props.label}</p>
			</div>
		</label>
	)
}

Checkbox.propTypes = {
	error: PropTypes.object,
	register: PropTypes.object,

	label: PropTypes.string.isRequired,

	mb: PropTypes.number,

	disabled: PropTypes.bool,

	classNameCheckbox: PropTypes.string,

	classNameLabel: PropTypes.string,

	required: PropTypes.bool
}

Checkbox.defaultProps = {
	disabled: false
}
