import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

import injectRole from '../../../components/HOC/injectRole'

import { TRoles, TRoutes } from '../../../shared/const'
import Documents from '../../../components/views/admin/Documents'

function DocumentsPage(props) {
	if ([TRoles.ADMIN, TRoles.FEDERAL_ADMIN].includes(props.role)) return <Documents />
	else return <Redirect to={TRoutes.DASHBOARD} />
}

DocumentsPage.propTypes = {
	role: PropTypes.string.isRequired
}

export default injectRole(DocumentsPage)
