const name = 'options'

const ADD_OPTIONS = `${name}/ADD_OPTIONS`
const DELETE_OPTIONS = `${name}/DELETE_OPTIONS`
const UPDATE_OPTIONS = `${name}/UPDATE_OPTIONS`

const initialState = () => ({
	juniorInsurance: null,
	shareholderShares: null,
	branch: null,
	vatRateRevenues: null,
	vatRateExpenditure: null,
	schoolTypes: null,
	salutations: null,
	workAreas: null,
	typeOfOffers: null,
	shareAmounts: null,
	bankData: null,
	genders: null,
	reportsDeadline: null,
	invoicesOverdue: null,
	clearDBFile: null
})
export default function reducer(state = initialState(), action = {}) {
	const { payload } = action
	switch (action.type) {
		case ADD_OPTIONS: {
			return {
				...state,
				...payload.options
			}
		}
		case DELETE_OPTIONS: {
			return {
				...initialState()
			}
		}
		case UPDATE_OPTIONS: {
			return {
				...state,
				...payload.options
			}
		}
		default: {
			return state
		}
	}
}

export const addOptions = options => {
	return {
		type: ADD_OPTIONS,
		payload: {
			options
		}
	}
}
export const updateOptions = options => {
	return {
		type: UPDATE_OPTIONS,
		payload: {
			options
		}
	}
}
