import styles from './style.module.scss'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { TApi, TRoles, TWidgetDomains, StateOptions } from '../../../shared/const'
import http from '../../../shared/http'
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow-down.svg'
import { types } from '../../../shared/eventEmitter'
import eventEmitter from '../../../shared/eventEmitter'
import { useTranslation } from 'react-i18next'
import { customNumberFormatW } from '../../../shared/helpers'
import InjectRole from '../../../components/HOC/injectRole'

function ThreeSectionsWidget(props) {
	const { t } = useTranslation()
	const [widgetDynamicData, setWidgetDynamicData] = useState({})

	const DEFAULT_OPTION = t('All states')

	const MAP_DOMAIN_TO_DATA = {
		[TWidgetDomains.COMPANIES]: {
			api: TApi.ADMIN_WIDGET_GET_COMPANIES,
			header: t('companies'),
			leftSideText: t('New'),
			leftSideNumberColor: '#3BB055',
			rightSideText: t('Pending'),
			rightSideNumberColor: '#EF8400'
		},
		[TWidgetDomains.STUDENTS]: {
			api: TApi.ADMIN_WIDGET_GET_STUDENTS,
			header: t('students'),
			leftSideText: t('New'),
			leftSideNumberColor: '#3BB055',
			rightSideText: t('Pending'),
			rightSideNumberColor: '#EF8400'
		},
		[TWidgetDomains.REPORTS]: {
			api: TApi.ADMIN_WIDGET_GET_REPORTS,
			header: t('reports'),
			leftSideText: t('New'),
			leftSideNumberColor: '#3BB055',
			rightSideText: t('Overdue'),
			rightSideNumberColor: '#EB5757'
		},
		[TWidgetDomains.INVOICES]: {
			api: TApi.ADMIN_WIDGET_GET_INVOICES,
			header: t('invoices'),
			leftSideText: t('New'),
			leftSideNumberColor: '#3BB055',
			rightSideText: t('Unpaid'),
			rightSideNumberColor: '#EF8400'
		},
		[TWidgetDomains.TEACHERS]: {
			api: TApi.ADMIN_WIDGET_GET_TEACHERS,
			header: t('teachers'),
			leftSideText: t('New'),
			leftSideNumberColor: '#3BB055',
			rightSideText: t('Pending'),
			rightSideNumberColor: '#EF8400'
		}
	}

	const widgetStaticData = MAP_DOMAIN_TO_DATA[props.domain]

	const stateFilter = props.settings?.stateFilter
	const currentOption = stateFilter ? stateFilter : DEFAULT_OPTION

	const fetchWidgetData = () => {
		const params = {
			state: props.settings.stateFilter
		}

		http.get(widgetStaticData.api, { params }).then(({ data }) => {
			setWidgetDynamicData({ ...data.data })
		})
	}

	useEffect(() => {
		fetchWidgetData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stateFilter])

	const handleOptionsClick = ({ currentOption }) => {
		eventEmitter.emit(
			types.openCustomizeSimpleWidgetModal,
			widgetStaticData.header,
			currentOption,
			props.id,
			props.availableWidgets
		)
	}

	return (
		<div className={styles.root}>
			<div className={styles.main}>
				<h1 className={styles.header}>{widgetStaticData.header}</h1>
				<div
					onClick={
						props.role === TRoles.FEDERAL_ADMIN ? () => handleOptionsClick({ currentOption }) : () => {}
					}
					className={styles.options}
				>
					<div className={styles.currentOption}>
						{t(StateOptions.find(({ value }) => value === currentOption)?.label) || DEFAULT_OPTION}
					</div>
					{props.role === TRoles.FEDERAL_ADMIN ? <ArrowDownIcon /> : ''}
				</div>
				<h2 className={styles.existNumber}>{customNumberFormatW(widgetDynamicData.total)}</h2>
			</div>
			<div className={styles.footerContainer}>
				<div className={styles.newContainer}>
					<h2
						className={widgetDynamicData.new ? styles.newNumber : clsx(styles.newNumber, styles.zero)}
						style={{ color: widgetStaticData.leftSideNumberColor }}
					>
						{widgetDynamicData.new ? '+' : ''}
						{customNumberFormatW(widgetDynamicData.new)}
					</h2>
					<p className={styles.statusText}>{widgetStaticData.leftSideText}</p>
				</div>
				<div className={styles.pendingContainer}>
					<h2 className={styles.pendingNumber} style={{ color: widgetStaticData.rightSideNumberColor }}>
						{customNumberFormatW(widgetDynamicData.pending)}
					</h2>
					<p className={styles.statusText}>{widgetStaticData.rightSideText}</p>
				</div>
			</div>
		</div>
	)
}

ThreeSectionsWidget.propTypes = {
	domain: PropTypes.string.isRequired,
	availableWidgets: PropTypes.array.isRequired,
	id: PropTypes.string.isRequired,
	settings: PropTypes.shape({
		schoolFilter: PropTypes.string,
		stateFilter: PropTypes.string
	})
}

export default InjectRole(ThreeSectionsWidget)
