import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { TRoles, TRoutes } from '../../shared/const'

import Reports from '../../components/views/admin/Reports'
import TeacherReports from '../../components/views/teacher/Reports'

function ReportsTable(props) {
	if (![TRoles.FEDERAL_ADMIN, TRoles.ADMIN, TRoles.TEACHER].includes(props.role))
		return <Redirect to={TRoutes.DASHBOARD} />

	return props.role === TRoles.TEACHER ? <TeacherReports /> : <Reports />
}

ReportsTable.propTypes = {
	role: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
	role: state.user.role
})

export default connect(mapStateToProps)(ReportsTable)
