import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

import { TRoutes } from '../../shared/const'
import { TRoles } from '../../shared/const'
import injectRole from '../../components/HOC/injectRole'
import TeacherStudentsList from '../../components/views/teacher/StudentsList'

function StudentsList(props) {
	if (props.role === TRoles.TEACHER) return <TeacherStudentsList />
	else return <Redirect to={TRoutes.DASHBOARD} />
}

StudentsList.propTypes = {
	role: PropTypes.string.isRequired
}

export default injectRole(StudentsList)
