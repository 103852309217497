import PropTypes from 'prop-types'
import clsx from 'clsx'

import filterIcon from '../../assets/icons/filterIcon.svg'

import styles from './style.module.scss'

export default function BtnFilter(props) {
	return (
		<button
			type={props.type}
			disabled={props.loading || props.disabled}
			style={props.width ? { width: props.width } : {}}
			className={clsx(styles.root, props.className, {
				[styles.filterActive]: !!props.count //only when open === true
			})}
			onClick={props.onClick}
		>
			<img className={styles.icon} src={filterIcon} alt="filter icon" />
			{props.children}
		</button>
	)
}

BtnFilter.propTypes = {
	className: PropTypes.string,
	width: PropTypes.number,
	type: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	icon: PropTypes.string,
	iconClassName: PropTypes.string,
	iconColor: PropTypes.string,
	iconSize: PropTypes.shape({
		w: PropTypes.number.isRequired,
		h: PropTypes.number.isRequired
	}),
	iconOrder: PropTypes.number,
	loading: PropTypes.bool,
	disabled: PropTypes.bool,
	onClick: PropTypes.func,

	theme: PropTypes.string
}

BtnFilter.defaultProps = {
	type: 'button',
	className: '',
	loading: false,
	disabled: false,
	iconOrder: 1,
	children: []
}
