import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import injectRole from '../../../components/HOC/injectRole'

import { TRoles, TRoutes } from '../../../shared/const'
import Experts from '../../../components/views/admin/Experts'

function ExpertsPage(props) {
	if ([TRoles.ADMIN, TRoles.FEDERAL_ADMIN].includes(props.role)) return <Experts />
	else return <Redirect to={TRoutes.DASHBOARD} />
}

ExpertsPage.propTypes = {
	role: PropTypes.string.isRequired
}

export default injectRole(ExpertsPage)
