import Joi from 'joi'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { joiResolver } from '@hookform/resolvers/joi'

import Btn from '../../../../UI/Btn'
import TextAreaField from '../../../../UI/TextAreaField'
import BaseModal from '../../../../UI/BaseModal/BaseModal'

import http from '../../../../shared/http'
import { TApi, TReject } from '../../../../shared/const'
import { schemaOptions } from '../../../../shared/i18n'
import { types } from '../../../../shared/eventEmitter'

import styles from '../AddFeedModal/style.module.scss'

const schema = Joi.object({
	description: Joi.string().max(1500).required()
})

export default function RejectCompanyComment() {
	const { t } = useTranslation()
	const titles = {
		[TReject.PARENTAL]: t('Reject Parental Confirmation'),
		[TReject.COMPANY]: t('Reject Company')
	}
	const {
		reset,
		register,
		handleSubmit,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema, {
			errors: {
				labels: false,
				language: localStorage.getItem('i18nextLng')
			},
			...schemaOptions
		})
	})

	const [id, setId] = useState(null)
	const [isLoading, setLoading] = useState(false)
	const [reject, setReject] = useState('')
	const [onDone, setOnDone] = useState(null)

	const handleOpen = (id, reject, onDone) => {
		setOnDone(() => onDone)
		setId(id)
		setReject(reject)
		reset()
	}

	const onSubmit = (fields, closeModal) => {
		setLoading(true)

		switch (reject) {
			case TReject.COMPANY: {
				http.put(TApi.ADMIN_PUT_COMPANY_REJECT(id), fields)
					.then(() => {
						toast.success(t('The company was rejected successfully'))
						closeModal()
						reset()
						setId(null)
						if (onDone) onDone()
					})
					.finally(() => {
						setLoading(false)
					})
				break
			}
			case TReject.PARENTAL: {
				http.post(TApi.ADMIN_REJECT_PARENTAL_CONFIRMATION(id), fields)
					.then(() => {
						toast.success(t('The parental confirmation was rejected successfully'))
						closeModal()
						reset()
						setId(null)
						if (onDone) onDone()
					})
					.finally(() => {
						setLoading(false)
					})
				break
			}
			default: {
				break
			}
		}
	}

	return (
		<BaseModal
			onOpen={handleOpen}
			nameEvent={types.openRejectCompanyCommentModal}
			title={titles[reject]}
			maxWidth={522}
		>
			{({ closeModal }) => (
				<form className={styles.root} onSubmit={handleSubmit(form => onSubmit(form, closeModal))}>
					<TextAreaField
						mb={25}
						label={t('description')}
						height={200}
						maxLength={500}
						showLettersLeft
						register={register('description')}
						error={errors.description}
						placeholder={t('description')}
					/>

					<Btn
						loading={isLoading}
						width={155}
						className={styles.btnSubmit}
						type="submit"
						icon="send"
						iconSize={{ w: 15, h: 15 }}
						iconColor="#fff"
					>
						{t('Submit')}
					</Btn>
				</form>
			)}
		</BaseModal>
	)
}
