import Joi from 'joi'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { TApi } from '../../../../../../shared/const'
import { schemaOptions } from '../../../../../../shared/i18n'
import http from '../../../../../../shared/http'
import { useTranslation } from 'react-i18next'

import TextField from '../../../../../../UI/TextField'
import Btn from '../../../../../../UI/Btn'
import styles from '../../style.module.scss'
import { toast } from 'react-toastify'

export default function Security(props) {
	const { t } = useTranslation()

	const schema = Joi.object({
		oldPassword: Joi.string().min(8).max(32).required(),
		newPassword: Joi.string().min(8).max(32).regex(/^\S+$/).required().messages({
			'string.pattern.base': 'Not allowed spaces !'
		}),
		repeatPassword: Joi.any()
			.empty('')
			.equal(Joi.ref('newPassword'))
			.required()
			.error(errors => {
				return errors.map(error => {
					if (error.code === 'any.only') error.message = t('Repeat password does not match with Password')
					return error
				})
			})
	})

	const {
		register,
		handleSubmit,
		trigger,
		setValue,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema, {
			errors: {
				labels: false,
				language: localStorage.getItem('i18nextLng')
			},
			...schemaOptions
		})
	})

	useEffect(() => {
		if (Object.keys(errors).length) trigger()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localStorage.getItem('i18nextLng')])

	const onSubmit = data => {
		props.setLoading(true)
		http.put(TApi.TEACHER_PUT_PROFILE_PASSWORD, {
			newPassword: data.newPassword,
			oldPassword: data.oldPassword
		})
			.then(() => {
				toast.success(t('Password changed successfully !'))
				setValue('oldPassword', null)
				setValue('newPassword', null)
				setValue('repeatPassword', null)
			})
			.finally(() => props.setLoading(false))
	}

	return (
		<form className={styles.section} onSubmit={handleSubmit(onSubmit)}>
			<h1 className={styles.sectionTitle}>{t('Change Password')}</h1>
			<div className={styles.sectionContent}>
				<div>
					<TextField
						type="password"
						disabled={props.isLoading}
						mb={20}
						label={t('Old password')}
						placeholder={t('Old password')}
						error={errors.oldPassword}
						register={register('oldPassword')}
					/>
					<TextField
						type="password"
						disabled={props.isLoading}
						mb={20}
						label={t('New password')}
						placeholder={t('New password')}
						error={errors.newPassword}
						register={register('newPassword')}
					/>
					<div className={styles.flexContainer}>
						<TextField
							type="password"
							disabled={props.isLoading}
							mb={20}
							label={t('Repeat new password')}
							placeholder={t('Repeat new password')}
							error={errors.repeatPassword}
							register={register('repeatPassword')}
						/>
						<div className={styles.btnContainer}>
							<Btn
								loading={props.isLoading}
								className={styles.btn}
								width={214}
								type="submit"
								icon="arrow"
								iconSize={{ w: 10, h: 10 }}
								iconColor="#fff"
							>
								{t('save')}
							</Btn>
						</div>
					</div>
				</div>
			</div>
		</form>
	)
}
