import Joi from 'joi'
import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Btn from '../../../../UI/Btn'
import TextField from '../../../../UI/TextField'
import SelectField from '../../../../UI/SelectField'
import BaseModal from '../../../../UI/BaseModal/BaseModal'
import http from '../../../../shared/http'
import { types } from '../../../../shared/eventEmitter'
import { TApi, genderOption, TLanguages } from '../../../../shared/const'

import styles from './style.module.scss'

const schema = Joi.object({
	email: Joi.string().email({ tlds: false }).required(),
	salutation: Joi.string().required(),
	gender: Joi.string().required(),
	firstName: Joi.string()
		.min(2)
		.max(26)
		.regex(/^[a-zA-ZА-яЁёÄÖÜẞäöüß]+( [a-zA-ZА-яЁёÄÖÜẞäöüß]+)*$/)
		.required()
		.messages({
			'string.pattern.base': 'Only letters are allowed !'
		}),
	lastName: Joi.string()
		.min(2)
		.max(26)
		.regex(/^[a-zA-ZА-яЁёÄÖÜẞäöüß]+( [a-zA-ZА-яЁёÄÖÜẞäöüß]+)*$/)
		.required()
		.messages({
			'string.pattern.base': 'Only letters are allowed !'
		})
})

function AddTeacher(props) {
	const { t, i18n } = useTranslation()
	const {
		register,
		control,
		handleSubmit,
		trigger,
		reset,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema)
	})

	const [isLoading, setLoading] = useState(false)
	const [school, setSchool] = useState({})
	const [dataFunc, setFunc] = useState({})

	useEffect(() => {
		if (Object.keys(errors).length) trigger()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localStorage.getItem('i18nextLng')])

	const onSubmit = (fields, closeModal) => {
		setLoading(true)

		const schema = {
			...fields,
			schoolId: school.id
		}

		http.post(TApi.STUDENT_GET_TEACHERS, schema)
			.then(({ data }) => {
				dataFunc.func('teacher', data)
				toast.success(t('Teacher registered successful.'))
			})
			.finally(() => {
				setLoading(false)
				closeModal()
			})
	}

	const handleOpen = (school, setValue) => {
		setSchool(school)
		setFunc({ func: setValue })
	}

	return (
		<BaseModal
			onClose={reset}
			onOpen={handleOpen}
			nameEvent={types.openAddTeacher}
			title={t('Add Teacher')}
			isLoading={isLoading}
			maxWidth={522}
		>
			{({ closeModal }) => (
				<form
					className={styles.root}
					onSubmit={handleSubmit(form =>
						onSubmit(form, () => {
							closeModal()
						})
					)}
				>
					<TextField mb={20} label={t('School')} disabled value={school.name} placeholder="" />
					<SelectField
						required
						mb={20}
						label={t('salutation')}
						control={control}
						name="salutation"
						error={errors.salutation}
						placeholder={t('Select salutation')}
						options={props.salutations?.map(v => ({
							value: v.value_EN,
							label: i18n.language === TLanguages.EN ? v.value_EN : v.value_DE
						}))}
						disabled={isLoading}
					/>
					<SelectField
						required
						disabled={isLoading}
						mb={20}
						label={t('gender')}
						control={control}
						name="gender"
						error={errors.gender}
						placeholder={t('Select gender')}
						options={genderOption
							.map(v => ({ label: t(v.label), value: v.value }))
							.sort((a, b) => a.label.localeCompare(b.label))}
					/>
					<TextField
						required
						mb={20}
						label={t('First Name')}
						placeholder={t('Type First Name')}
						error={errors.firstName}
						register={register('firstName')}
						disabled={isLoading}
					/>
					<TextField
						required
						mb={20}
						label={t('Last Name')}
						placeholder={t('Type Last Name')}
						error={errors.lastName}
						register={register('lastName')}
						disabled={isLoading}
					/>

					<TextField
						required
						disabled={isLoading}
						mb={20}
						label="Email"
						placeholder={t('Email')}
						error={errors.email}
						register={register('email')}
					/>

					<Btn
						loading={isLoading}
						width={155}
						className={styles.btnSubmit}
						type="submit"
						icon="send"
						iconSize={{ w: 15, h: 15 }}
						iconColor="#fff"
					>
						{t('save')}
					</Btn>
				</form>
			)}
		</BaseModal>
	)
}

AddTeacher.propTypes = {
	salutations: PropTypes.array.isRequired,
	genders: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
	salutations: state.options.salutations,
	genders: state.options.genders
})

export default connect(mapStateToProps)(AddTeacher)
