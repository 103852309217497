import clsx from 'clsx'
import { useEffect, useMemo, useState } from 'react'
import Avatar from 'react-avatar'
import { TApi } from '../../../../shared/const'
import eventEmitter, { types } from '../../../../shared/eventEmitter'
import http from '../../../../shared/http'
import Btn from '../../../../UI/Btn'
import MiniHeader from '../../../../UI/MiniHeader'
import Table from '../../../../UI/Table'
import styles from './style.module.scss'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import PropTypes from 'prop-types'

function CompanyExperts(props) {
	const { t } = useTranslation()
	const [countExperts, setCountExperts] = useState(0)
	const [page, setPage] = useState(0)
	const limit = 10
	const [isLoading, setLoading] = useState(false)
	const [sort, setSort] = useState({ name: 'name', direction: 'desc' })
	const [experts, setExperts] = useState([])

	const handleSort = (name, direction) => {
		setSort({ name, direction })
	}

	const fetchExperts = offset => {
		http.get(TApi.STUDENT_EXPERTS, {
			params: {
				limit,
				offset,
				order: sort.name,
				direction: sort.direction
			}
		}).then(({ data }) => {
			setExperts(data.rows)
			setCountExperts(data.count)
		})
	}
	const deleteExpert = id => {
		setLoading(true)
		http.delete(TApi.STUDENT_CHANGE_EXPERTS(id))
			.then(() => {
				fetchExperts()
			})
			.finally(() => setLoading(false))
	}

	useEffect(() => {
		fetchExperts(page * limit)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, sort])

	const handleButton = id => {
		Swal.fire({
			title: t('Are you sure?'),
			text: t('You will not be able to revert this!'),
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: t('Yes, delete it!'),
			cancelButtonText: t('Cancel')
		}).then(result => {
			if (result.isConfirmed) {
				Swal.fire(t('The expert was deleted successfully'))
				deleteExpert(id)
			}
		})
	}

	const columns = useMemo(
		() => [
			{
				Header: () => {
					return <span style={{ paddingLeft: 20 }}>Name</span>
				},
				accessor: originalRow => {
					return {
						avatar: originalRow.avatar,
						firstName: originalRow.firstName,
						lastName: originalRow.lastName
					}
				},
				id: 'name',
				minWidth: 250,
				Cell: ({ row }) => {
					return (
						<div style={{ minWidth: 250 }}>
							<Avatar
								style={{ marginLeft: 20 }}
								src={row.values.name.avatar}
								round
								size={32}
								name={`${row.values.name.firstName} ${row.values.name.lastName}`}
							/>
							<span
								className={styles.nameText}
							>{`${row.values.name.firstName} ${row.values.name.lastName}`}</span>
						</div>
					)
				}
			},
			{
				Header: t('Company'),
				accessor: 'company',
				Cell: ({ row }) => {
					return <p style={{ fontSize: 13, minWidth: 200 }}>{row.values.company}</p>
				}
			},
			{
				Header: t('Position'),
				accessor: 'position',
				minWidth: 150,
				Cell: ({ row }) => {
					return <span style={{ fontSize: 13 }}>{row.values.position}</span>
				}
			},
			{
				Header: 'Email',
				accessor: 'email',
				Cell: ({ row }) => {
					return <p style={{ fontSize: 13, minWidth: 200 }}>{row.values.email}</p>
				}
			},
			{
				Header: t('City'),
				accessor: 'city',
				minWidth: 90,
				Cell: ({ row }) => {
					return <span style={{ fontSize: 13 }}>{row.values.city}</span>
				}
			},
			{
				Header: 'Status',
				accessor: 'emailConfirmed',
				minWidth: 200,
				Cell: ({ row }) => {
					return (
						<div className={styles.branchContainer}>
							<span style={{ fontSize: 13 }}>
								{row.values.emailConfirmed ? (
									<div className={styles.accepted}>{t('Accepted')}</div>
								) : (
									<div className={styles.pending}>{t('Pending')}</div>
								)}
							</span>
							{!props.isTeacherStudentView && (
								<Btn
									loading={isLoading}
									iconColor={isLoading ? '#000' : 'none'}
									iconSize={isLoading ? { w: 16, h: 16 } : { w: 20, h: 20 }}
									icon={isLoading ? '' : 'edit'}
									theme="white"
									onClick={() => {
										eventEmitter.emit(
											types.openAddExpertModal,
											experts.find(item => item.id === row.original.id),
											fetchExperts
										)
									}}
								/>
							)}
							{!props.isTeacherStudentView && (
								<Btn
									loading={isLoading}
									className={styles.deleteBtn}
									iconColor="#000"
									iconSize={{ w: 16, h: 16 }}
									icon={isLoading ? '' : 'delete'}
									theme="white"
									onClick={() => {
										handleButton(row.original.id)
									}}
								/>
							)}
						</div>
					)
				}
			}
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[experts, isLoading, t]
	)

	return (
		<div className={styles.root}>
			<MiniHeader className={styles.header} title={t('Economic experts')} bg="#f8faff">
				{!props.isActivatedCompany && !props.isNeedReview && !props.isTeacherStudentView && (
					<Btn
						loading={isLoading}
						className={styles.addExpertBtn}
						icon="plus"
						iconColor="#fff"
						theme="orange"
						onClick={() => {
							eventEmitter.emit(types.openAddExpertModal, null, fetchExperts)
						}}
					>
						{t('Add Expert')}
					</Btn>
				)}
			</MiniHeader>
			<div className={clsx('container', styles.tableContainer)}>
				<Table
					columns={columns}
					handleSort={handleSort}
					items={experts}
					page={page}
					limit={limit}
					countAll={countExperts}
					onChange={setPage}
				/>
			</div>
		</div>
	)
}
CompanyExperts.propTypes = {
	isActivatedCompany: PropTypes.bool.isRequired,
	isNeedReview: PropTypes.bool.isRequired,
	isTeacherStudentView: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
	isActivatedCompany: state.user.profile?.EmploymentCompany?.isActive,
	isNeedReview: state.user.profile?.EmploymentCompany?.isNeedReview,
	isTeacherStudentView: state.user.isTeacherStudentView
})

export default connect(mapStateToProps)(CompanyExperts)
