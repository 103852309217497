import MiniHeader from '../../../../UI/MiniHeader'
import Checkbox from '../../../../UI/Checkbox'
import TextAreaField from '../../../../UI/TextAreaField'
import { useForm } from 'react-hook-form'
import Btn from '../../../../UI/Btn'
import http from '../../../../shared/http'
import { DOCUMENT_TYPES, TApi, TReject, StateOptions, TLanguages } from '../../../../shared/const'
import { useState, useEffect } from 'react'
import Avatar from 'react-avatar'
import ParentalConfirmation from './components/ParentalConfirmation'
import styles from './style.module.scss'
import { toast } from 'react-toastify'
import { useHistory, useParams } from 'react-router-dom'
import Uploader from '../../../../UI/Uploader'
import { useTranslation } from 'react-i18next'
import Hr from '../../../../UI/Hr'
import eventEmitter, { types } from '../../../../shared/eventEmitter'
import { TRoutes, TRoles } from '../../../../shared/const'
import BtnTooltip from '../../../../UI/BtnTooltip'
import { connect } from 'react-redux'
import { parse, differenceInYears } from 'date-fns'
import VisibilityAccess from '../../../VisibilityAccess'

function CompanyReview(props) {
	const { t, i18n } = useTranslation()
	const history = useHistory()
	const { register, handleSubmit, setValue } = useForm()
	const { id } = useParams()
	const [isLoadingSave, setLoadingSave] = useState(false)
	const [isLoadingReject, setLoadingReject] = useState(false)
	const [isLoadingAct, setLoadingAct] = useState(false)
	const [company, setCompany] = useState({})
	const { companyInfo, employees, invitationUser, experts, supervision, teachers, businessPlan, riskAnalysis } =
		company

	const onSubmit = form => {
		setLoadingSave(true)

		http.put(TApi.ADMIN_PUT_COMPANY_COMMENT(id), {
			adminComment: form.adminComment
		})
			.then(() => toast.success(t('Company comment is updated')))
			.finally(() => setLoadingSave(false))
	}

	const handleActivateCompany = () => {
		setLoadingAct(true)
		http.put(TApi.ADMIN_PUT_COMPANY_ACTIVATION(companyInfo.id)).then(() => {
			toast.success(t('Company approved'))
			setCompany({
				...company,
				companyInfo: {
					...company.companyInfo,
					isActive: true
				}
			})
			setLoadingAct(false)
		})
	}

	const handleRegenerateCompanyCertificate = () => {
		setLoadingAct(true)
		http.put(TApi.ADMIN_PUT_REGENERATE_COMPANY_CERTIFICATE(companyInfo.id)).then(() => {
			toast.success(t('REGENERATED COMPANY CERTIFICATE'))
			setLoadingAct(false)
		})
	}

	const handleRejectCompany = () => {
		setLoadingReject(true)

		eventEmitter.emit(types.openRejectCompanyCommentModal, id, TReject.COMPANY, () => {
			setCompany({
				...company,
				companyInfo: {
					...company.companyInfo,
					isNeedReview: false,
					isRejected: true
				}
			})
			history.push(TRoutes.ADMIN_COMPANIES)
		})
		setLoadingReject(false)
	}

	const handleRejectParental = (id, companyId) => {
		setLoadingReject(true)
		eventEmitter.emit(types.openRejectCompanyCommentModal, id, TReject.PARENTAL, () => {
			http.get(TApi.ADMIN_GET_COMPANY(companyId)).then(({ data }) => {
				setCompany(data)
				setValue('adminComment', data.companyInfo?.adminComment)
			})
		})
		setLoadingReject(false)
	}

	useEffect(() => {
		http.get(TApi.ADMIN_GET_COMPANY(id)).then(({ data }) => {
			setCompany(data)
			setValue('adminComment', data.companyInfo?.adminComment)
		})

		/* eslint-disable-next-line */
	}, [])

	const activations = {
		basicInfo: true,
		employees: employees?.length >= 2 && employees.every(employee => !!employee.reviewedParentalConfirmation),
		experts: experts?.length > 0,
		supervision: !!supervision,
		contacts: !!(companyInfo?.email && companyInfo?.phone),
		school: supervision?.Teacher?.School,
		businessActivity: !!(companyInfo?.businessActivityDescription && companyInfo?.offerTypes),
		businessIdea: !!companyInfo?.businessIdea,
		documents: !!riskAnalysis,
		description: !!companyInfo?.description
	}

	return (
		<div className={styles.root}>
			<div className={styles.header}>
				<MiniHeader title={t('Confirm Company Profile')} bg="#F8FAFF" />
			</div>
			<div>
				<div className={styles.main}>
					<div className={styles.basicInfo}>
						<div className={styles.checkboxContainer}>
							<Checkbox
								classNameCheckbox={styles.checkbox}
								classNameLabel={styles.checkboxLabel}
								label={t('Basic Info')}
								register={{
									checked: activations.basicInfo || !!companyInfo?.isActive
								}}
							/>
						</div>
						<div className={styles.basicInfoMain}>
							<div className={styles.readOnlyInput}>
								<h3 className={styles.readOnlyLabel}>{t('Company name')}</h3>
								<div className={styles.readOnlyText}>{companyInfo?.name}</div>
							</div>
							<div className={styles.readOnlyInput}>
								<h3 className={styles.readOnlyLabel}>{t('Company type')}</h3>
								<div className={styles.readOnlyText}>{companyInfo?.companyType}</div>
							</div>
							<div className={styles.readOnlyInput}>
								<h3 className={styles.readOnlyLabel}>{t('branch')}</h3>
								<div className={styles.readOnlyText}>{t(companyInfo?.branch)}</div>
							</div>
							<div className={styles.readOnlyInput}>
								<h3 className={styles.readOnlyLabel}>{t('state')}</h3>
								<div className={styles.readOnlyText}>
									{t(StateOptions.find(({ value }) => value === companyInfo?.state)?.label)}
								</div>
							</div>
						</div>
					</div>
					<Hr />
					<div className={styles.emploee}>
						<div className={styles.checkboxContainer}>
							<Checkbox
								register={{
									checked: activations.employees || !!companyInfo?.isActive
								}}
								classNameCheckbox={styles.checkbox}
								classNameLabel={styles.checkboxLabel}
								label={t('employees')}
							/>
						</div>
						<div className={styles.emploeeMain}>
							{employees?.map((item, index) => {
								const age = item.dateOfBirth
									? differenceInYears(new Date(), parse(item.dateOfBirth, 'yyyy-MM-dd', new Date()))
									: ''
								return (
									<div className={styles.emploeeContainer}>
										<span className={styles.employeeProfile}>
											<Avatar
												className={styles.userAvatar}
												round
												size={32}
												src={
													item.avatarHashName &&
													TApi.PUBLIC_PROFILE_AVATAR(item.avatarHashName)
												}
											/>
											<p className={styles.name}>
												{item.firstName} {item.lastName}{' '}
												{!!age && (
													<span>
														({age}&nbsp;{t('years')})
													</span>
												)}
											</p>
											<BtnTooltip
												items={[
													{
														label: t('Edit Userprofile'),
														onClick: () =>
															eventEmitter.emit(types.openStudentProfile, item, () => {})
													}
												]}
											/>
										</span>
										<p className={styles.employeeEmail}>E: {item.email} </p>
										<p className={styles.employeePhone}>
											{t('T')}: {item.phone}{' '}
										</p>
										<p className={styles.employeeFunction}>
											F:{' '}
											{item.primaryWorkArea
												? i18n.language === TLanguages.DE
													? props.workAreas?.find(v => v.value_EN === item.primaryWorkArea)
															?.value_DE
													: props.workAreas?.find(v => v.value_EN === item.primaryWorkArea)
															?.value_EN
												: t('No main work area yet')}
										</p>
										<div className={styles.employeesWrapper}>
											{item.Documents[0]?.originalName && (
												<div style={{ width: '250px', marginRight: '30px' }}>
													<ParentalConfirmation
														parentalConfirmationHashName={item.Documents[0]?.name}
														originalName={item.Documents[0]?.originalName}
														reviewedParentalConfirmation={item.reviewedParentalConfirmation}
													/>
												</div>
											)}
											{item.Documents.find(
												({ type }) => type === DOCUMENT_TYPES.PARENTAL_CONFIRMATION
											) &&
												!companyInfo.isActive && (
													<div>
														{!item.rejectedParentalConfirmation && (
															<div className={styles.checkboxContent}>
																<span className={styles.radioRoot}>
																	<input
																		type="radio"
																		checked={item.reviewedParentalConfirmation}
																		onClick={e => {
																			!item.reviewedParentalConfirmation &&
																				http
																					.put(
																						TApi.ADMIN_REVIEW_PARENTAL_CONFIRMATION(
																							item.id
																						)
																					)
																					.then(() => {
																						const _users = employees.slice()
																						_users[index] = {
																							..._users[index],
																							reviewedParentalConfirmation: true
																						}
																						setCompany({
																							...company,
																							employees: _users
																						})
																						toast.success(
																							t('Successfully updated')
																						)
																					})
																		}}
																		className={styles.radio}
																	/>
																	<span className={styles.radioWrapper} />
																</span>
																<label>{t('Checked')}</label>
															</div>
														)}

														{!item.reviewedParentalConfirmation && (
															<Btn
																onClick={() =>
																	handleRejectParental(item.id, item.companyId)
																}
																theme="orange"
																disabled={item.rejectedParentalConfirmation}
															>
																{item.rejectedParentalConfirmation
																	? t('Rejected')
																	: t('Reject')}
															</Btn>
														)}
													</div>
												)}
										</div>
									</div>
								)
							})}

							{invitationUser?.map(item => {
								return (
									<div className={styles.emploeeContainer}>
										<Avatar
											className={styles.userAvatar}
											round
											size={32}
											src={item.avatarHashName && TApi.PUBLIC_PROFILE_AVATAR(item.avatarHashName)}
										/>
										<p className={styles.name}>{item.email}</p>
										<p className={styles.status}>{t('Invitation Pending')}</p>
									</div>
								)
							})}
						</div>
					</div>
					<Hr />
					<div className={styles.expert}>
						<div className={styles.checkboxContainer}>
							<Checkbox
								register={{
									checked: activations.experts || !!companyInfo?.isActive
								}}
								classNameCheckbox={styles.checkbox}
								classNameLabel={styles.checkboxLabel}
								label={t('Economic experts')}
							/>
						</div>
						<div className={styles.expertMain}>
							{experts?.map(item => {
								return (
									<div>
										<div className={styles.expertContainer}>
											<Avatar
												className={styles.userAvatar}
												round
												size={32}
												src={
													item.avatarHashName &&
													TApi.PUBLIC_PROFILE_AVATAR(item.avatarHashName)
												}
											/>
											<p className={styles.name}>
												{' '}
												{item.firstName} {item.lastName}{' '}
											</p>
										</div>
										<p className={styles.employeeEmail}>Email: {item.email} </p>
										<p className={styles.employeePhone}>Phone: {item.phone} </p>
									</div>
								)
							})}
						</div>
					</div>
					<Hr />
					<div className={styles.responsibleTeacher}>
						<div className={styles.checkboxContainer}>
							<Checkbox
								register={{
									checked: activations.supervision || !!companyInfo?.isActive
								}}
								classNameCheckbox={styles.checkbox}
								classNameLabel={styles.checkboxLabel}
								label={t('Supervision Teacher')}
							/>
						</div>
						<div className={styles.responsibleTeacherMain}>
							<div className={styles.responsibleTeacherContainer}>
								<Avatar
									className={styles.userAvatar}
									round
									size={32}
									src={
										supervision?.Teacher?.avatarHashName &&
										TApi.PUBLIC_PROFILE_AVATAR(supervision?.Teacher?.avatarHashName)
									}
								/>
								<p className={styles.name}>
									{supervision?.Teacher?.firstName} {supervision?.Teacher?.lastName}
								</p>
								<BtnTooltip
									items={[
										{
											label: t('Edit Userprofile'),
											onClick: () =>
												eventEmitter.emit(
													types.openStudentProfile,
													supervision?.Teacher,
													() => {}
												)
										}
									]}
								/>
							</div>
							<p className={styles.employeeEmail}>Email: {supervision?.Teacher?.email} </p>
							<p className={styles.employeePhone}>Phone: {supervision?.Teacher?.phone} </p>
						</div>
					</div>
					<Hr />
					<div className={styles.expert}>
						<div className={styles.checkboxContainer}>
							<div className={styles.checkboxLabalWrap}>{t('teachers')}</div>
						</div>
						<div className={styles.expertMain}>
							{teachers?.map(item => {
								return (
									<div className={styles.expertContainer}>
										<Avatar
											className={styles.userAvatar}
											round
											size={32}
											src={
												item.Teacher.avatarHashName &&
												TApi.PUBLIC_PROFILE_AVATAR(item.Teacher.avatarHashName)
											}
										/>
										<p className={styles.name}>
											{' '}
											{item.Teacher.firstName} {item.Teacher.lastName}{' '}
										</p>
										<BtnTooltip
											items={[
												{
													label: t('Edit Userprofile'),
													onClick: () =>
														eventEmitter.emit(
															types.openStudentProfile,
															item.Teacher,
															() => {}
														)
												}
											]}
										/>
									</div>
								)
							})}
						</div>
					</div>
					<Hr />
					<div className={styles.documentation}>
						<div className={styles.checkboxContainer}>
							<Checkbox
								register={{
									checked: activations.documents || !!companyInfo?.isActive
								}}
								classNameCheckbox={styles.checkbox}
								classNameLabel={styles.checkboxLabel}
								label={t('Documentation')}
							/>
						</div>
						<div className={styles.documentationMain}>
							{businessPlan ? (
								<div className={styles.documentationContainer}>
									<Uploader
										iconName="business"
										title={t('Business Plan')}
										classNameLabel={styles.wrapper}
										accept="application/pdf"
										disabled={true}
										nameFile={businessPlan?.originalName}
										onClick={() => {
											http.get(TApi.PUBLIC_GET_FILES_DOCUMENT(businessPlan.name), {
												responseType: 'blob'
											}).then(({ data }) => {
												const file = new Blob([data], {
													type: 'application/pdf'
												})
												const fileURL = URL.createObjectURL(file)
												window.open(fileURL)
											})
										}}
									/>
								</div>
							) : (
								<div className={styles.emptyContText}> {t('No business plan yet')}</div>
							)}
							{riskAnalysis ? (
								<div className={styles.documentationContainer}>
									<Btn
										className={styles.btnRisk}
										iconClassName={styles.btnIcon}
										icon="risk"
										width={340}
										type="submit"
										iconSize={{ w: 20, h: 20 }}
										iconColor="#fff"
										onClick={() => {
											eventEmitter.emit(types.openCompanyRisk, riskAnalysis, null)
										}}
									>
										<div className={styles.btnText}> {t('Market- & Riskanalysis')}</div>
									</Btn>
								</div>
							) : (
								<div className={styles.emptyContText}> {t('No risk analysis yet')}</div>
							)}
						</div>
					</div>
					<Hr />
					<div className={styles.contact}>
						<div className={styles.checkboxContainer}>
							<Checkbox
								register={{
									checked: activations.contacts || !!companyInfo?.isActive
								}}
								classNameCheckbox={styles.checkbox}
								classNameLabel={styles.checkboxLabel}
								label={t('contacts')}
							/>
						</div>
						<div className={styles.contactMain}>
							<div className={styles.readOnlyInput}>
								<h3 className={styles.readOnlyLabel}>{t('Company email')}</h3>
								<div className={styles.readOnlyText}>{companyInfo?.email}</div>
							</div>
							<div className={styles.readOnlyInput}>
								<h3 className={styles.readOnlyLabel}>{t('phone')}</h3>
								<div className={styles.readOnlyText}>{companyInfo?.phone}</div>
							</div>
							<div className={styles.readOnlyInput}>
								<h3 className={styles.readOnlyLabel}>{t('Social Media')}</h3>
								<div className={styles.socialContainer}>
									{companyInfo?.socialNet && companyInfo.socialNet.length
										? companyInfo?.socialNet.map(social => (
												<a
													className={styles.socialLink}
													href={social.link}
													target="_blank"
													rel="noreferrer"
												>
													{social.name}
												</a>
										  ))
										: '--------------'}
								</div>
							</div>
							<div className={styles.readOnlyInput}>
								<h3 className={styles.readOnlyLabel}>{t('Website & Social Media')}</h3>
								<div className={styles.readOnlyText}>
									{' '}
									{companyInfo?.websiteURL ? (
										<a
											href={companyInfo?.websiteURL}
											target="_blank
"
										>
											{companyInfo?.websiteURL}
										</a>
									) : (
										'--------------'
									)}
								</div>
							</div>
						</div>
					</div>
					<Hr />
					<div className={styles.schoolTeacher}>
						<div className={styles.checkboxContainer}>
							<Checkbox
								register={{
									checked: activations.school || !!companyInfo?.isActive
								}}
								classNameCheckbox={styles.checkbox}
								classNameLabel={styles.checkboxLabel}
								label={t('school')}
							/>
						</div>
						<div className={styles.schoolTeacherMain}>
							<div className={styles.readOnlyInput}>
								<h3 className={styles.readOnlyLabel}>{t('school')}</h3>
								<div className={styles.readOnlyText}>{supervision?.Teacher?.School?.name}</div>
							</div>
						</div>
					</div>
					<Hr />
					<div className={styles.businessActivity}>
						<div className={styles.checkboxContainer}>
							<Checkbox
								register={{
									checked: activations.businessActivity || !!companyInfo?.isActive
								}}
								classNameCheckbox={styles.checkbox}
								classNameLabel={styles.checkboxLabel}
								label={t('Business Activity')}
							/>
						</div>
						<div className={styles.businessActivityMain}>
							<div className={styles.readOnlyInput}>
								<h3 className={styles.readOnlyLabel}>{t('description')}</h3>
								<div className={styles.readOnlyTextArea}>
									{' '}
									{companyInfo?.businessActivityDescription
										? companyInfo?.businessActivityDescription
										: ''}
								</div>
							</div>
							<div className={styles.readOnlyInput}>
								<h3 className={styles.readOnlyLabel}>{t('typeOffers')}</h3>
								<div className={styles.readOnlyTextArea}>
									{' '}
									{companyInfo?.offerTypes ? companyInfo?.offerTypes : ''}
								</div>
							</div>
						</div>
					</div>
					<div className={styles.businessIdea}>
						<div className={styles.checkboxContainer}>
							<Checkbox
								register={{
									checked: activations.businessIdea || !!companyInfo?.isActive
								}}
								classNameCheckbox={styles.checkbox}
								classNameLabel={styles.checkboxLabel}
								label={t('Business Idea')}
							/>
						</div>
						<div className={styles.businessIdeaMain}>
							<div className={styles.readOnlyInput}>
								<h3 className={styles.readOnlyLabel}>{t('description')}</h3>
								<div className={styles.readOnlyTextArea}>
									{' '}
									{companyInfo?.businessIdea ? companyInfo?.businessIdea : ''}
								</div>
							</div>
						</div>
					</div>
					<div className={styles.descriptionActivity}>
						<div className={styles.checkboxContainer}>
							<Checkbox
								register={{
									checked: activations.description || !!companyInfo?.isActive
								}}
								classNameCheckbox={styles.checkbox}
								classNameLabel={styles.checkboxLabel}
								label={t('Company Description')}
							/>
						</div>
						<div className={styles.businessIdeaMain}>
							<div className={styles.readOnlyInput}>
								<h3 className={styles.readOnlyLabel}>{t('description')}</h3>
								<div className={styles.readOnlyTextArea}>
									{' '}
									{companyInfo?.description ? companyInfo?.description : ''}
								</div>
							</div>
						</div>
					</div>

					<div className={styles.adminComment}>
						<form className={styles.adminCommentMain} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
							<TextAreaField
								height={102}
								classNameLabel={styles.adminCommentTextArea}
								label={t('Your Comment about Company (Only administrators will see this comment)')}
								placeholder={t('Type comment about company')}
								register={register('adminComment')}
							/>

							<Btn
								loading={isLoadingSave}
								width={214}
								type="submit"
								icon="save"
								iconSize={{ w: 15, h: 'auto' }}
								iconColor="#fff"
							>
								{t('save')}
							</Btn>
						</form>
					</div>
					<div className={styles.footer}>
						<Btn
							width={214}
							className={styles.goBackBtn}
							iconClassName={styles.goBackBtnIcon}
							onClick={() => history.goBack()}
							icon="arrow"
							iconOrder={0}
							iconSize={{ w: 10, h: 10 }}
							iconColor="#fff"
						>
							{t('Go back')}
						</Btn>
						{!companyInfo?.isActive && (
							<Btn
								disabled={companyInfo?.isRejected}
								className={styles.btnReject}
								loading={isLoadingReject}
								onClick={handleRejectCompany}
								width={214}
								icon="arrow"
								iconSize={{ w: 10, h: 10 }}
								iconColor="#fff"
							>
								{companyInfo?.isRejected ? t('Company Rejected') : t('Reject Company')}
							</Btn>
						)}
						{!companyInfo?.isActive && (
							<Btn
								disabled={
									Object.values(activations).some(item => item === false) || companyInfo?.isRejected
								}
								loading={isLoadingAct}
								onClick={handleActivateCompany}
								width={214}
								icon="arrow"
								iconSize={{ w: 10, h: 10 }}
								iconColor="#fff"
							>
								{t('Activate Company')}
							</Btn>
						)}
						<VisibilityAccess access={[TRoles.FEDERAL_ADMIN]}>
							<Btn
								disabled={
									Object.values(activations).some(item => item === false) || companyInfo?.isRejected
								}
								loading={isLoadingAct}
								onClick={handleRegenerateCompanyCertificate}
								width={214}
								icon="arrow"
								iconSize={{ w: 10, h: 10 }}
								iconColor="#fff"
							>
								{t('Regenerate company certificate')}
							</Btn>
						</VisibilityAccess>
					</div>
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = state => ({
	workAreas: state.options.workAreas
})

export default connect(mapStateToProps)(CompanyReview)
