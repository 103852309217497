import Avatar from 'react-avatar'
import PropTypes from 'prop-types'
// import { CircularProgressbarWithChildren } from 'react-circular-progressbar'

import styles from './style.module.scss'

export default function ProgressAvatar(props) {
	/*return (
		<CircularProgressbarWithChildren className={styles.root} value={props.value}>
			<Avatar {...props} className={styles.avatar} />
		</CircularProgressbarWithChildren>
	)*/
	// 		CircularProgressbarWithChildren - лагает страничка из за библиотеки
	return <Avatar {...props} className={styles.avatar} />
}

ProgressAvatar.propTypes = {
	...Avatar.propTypes,
	value: PropTypes.string
}
