import { useMemo, useState, useEffect } from 'react'
import styles from './style.module.scss'
import { TApi, DocumentOptions } from '../../../../shared/const'
import http from '../../../../shared/http'
import { getFormattedDate } from '../../../../shared/helpers'
import MiniHeader from '../../../../UI/MiniHeader'
import Table from '../../../../UI/Table'
import Btn from '../../../../UI/Btn'
import invoiceIcon from '../../../../assets/icons/invoices.svg'
import reportIcon from '../../../../assets/icons/reportIcon.svg'
import businessPlanIcon from '../../../../assets/icons/businessPlan.svg'
import insuranceIcon from '../../../../assets/icons/insuranceIcon.svg'
import paperParentalIcon from '../../../../assets/icons/paperParental.svg'
import { useTranslation } from 'react-i18next'

const documentTypesIcon = {
	INVOICE: invoiceIcon,
	REPORT: reportIcon,
	BUSINESS_PLAN: businessPlanIcon,
	INSURANCE: insuranceIcon,
	PARENTAL_CONFIRMATION: paperParentalIcon,
	CONSENT_DOCUMENT: businessPlanIcon,
	PARTICIPATION_DOCUMENT: businessPlanIcon,
	COMPANY_CERTIFICATE: businessPlanIcon,
	SHAREHOLDER_CERTIFICATE: businessPlanIcon,
	SHARE_TEMPLATE: businessPlanIcon
}

export default function DocumentsList() {
	const { t } = useTranslation()
	const [documents, setDocuments] = useState([])
	const [countAll, setCountAll] = useState(0)
	const [page, setPage] = useState(0)
	const [sort, setSort] = useState({ name: 'name', direction: 'asc' })
	const limit = 10

	const fetchDocuments = offset => {
		http.get(TApi.STUDENT_GET_DOCUMENTS, {
			params: {
				limit,
				offset,
				order: sort.name,
				direction: sort.direction
			}
		}).then(({ data }) => {
			setDocuments(data.documents.rows)
			setCountAll(data.documents.count)
		})
	}
	const handleSort = (name, direction) => {
		setSort({ name, direction })
	}
	const downoload = name => {
		http.get(TApi.STUDENT_DOWNLOAD_DOCUMENT(name), {
			headers: {
				'Content-Type': 'application/pdf'
			},
			responseType: 'blob'
		}).then(({ data }) => {
			const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', name)
			document.body.appendChild(link)
			link.click()
			link.parentNode.removeChild(link)
		})
	}

	useEffect(() => {
		fetchDocuments(page * limit)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, sort])

	const columns = useMemo(
		() => [
			{
				Header: () => {
					return <span className={styles.tableTitleDocumentName}>{t('Document name')}</span>
				},
				minWidth: 250,
				accessor: 'name',
				Cell: ({ row }) => {
					return (
						<div className={styles.tableNameContainer}>
							<img
								className={styles.documentIcon}
								src={documentTypesIcon[row.original.type]}
								alt="icon"
							/>
							<span className={styles.documentName}>{row.original.originalName}</span>
						</div>
					)
				}
			},
			{
				Header: t('Type'),
				minWidth: 250,
				accessor: 'type',
				Cell: ({ row }) => {
					return (
						<p className={styles.type}>
							{' '}
							{t(DocumentOptions.find(({ value }) => value === row.original.type)?.label)}
						</p>
					)
				}
			},
			{
				Header: t('Employee'),
				minWidth: 200,
				accessor: 'employee',
				Cell: ({ row }) => {
					return (
						<p className={styles.employee}>
							{row.original.Creator.firstName} {row.original.Creator.lastName}
						</p>
					)
				}
			},
			{
				Header: t('Date'),
				accessor: 'time',
				Cell: ({ row }) => {
					return (
						<div className={styles.dateContainer}>
							<p className={styles.date}>{getFormattedDate(row.original.createdAt)}</p>
							<Btn
								theme="download"
								icon="download"
								iconSize={{ w: 24, h: 24 }}
								onClick={() => downoload(row.original.name)}
							/>
						</div>
					)
				}
			}
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[documents, t]
	)
	return (
		<div className={styles.root}>
			<MiniHeader title={t('Company Documents')} bg="#f8faff" />
			<div className="container">
				<Table
					columns={columns}
					items={documents}
					page={page}
					limit={limit}
					countAll={countAll}
					onChange={setPage}
					handleSort={handleSort}
				/>
			</div>
		</div>
	)
}
