import { useState, useEffect } from 'react'
import styles from '../style.module.scss'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import Joi from 'joi'
import http from '../../../../../shared/http'
import { TApi, TLanguages, TRoutes } from '../../../../../shared/const'
import { schemaOptions } from '../../../../../shared/i18n'
import { joiResolver } from '@hookform/resolvers/joi'
import SelectField from '../../../../../UI/SelectField'
import TextAreaField from '../../../../../UI/TextAreaField'
import Btn from '../../../../../UI/Btn'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
const schema = Joi.object({
	branch: Joi.string().required(),
	companyDescription: Joi.string().min(50).max(1000).required(),
	offerTypes: Joi.string().required()
})

function CompanyProfile(props) {
	const { id } = useParams()
	const location = useLocation()
	const path = location.pathname

	const { t, i18n } = useTranslation()
	const {
		register,
		trigger,
		control,
		handleSubmit,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema, {
			errors: {
				labels: false,
				language: localStorage.getItem('i18nextLng')
			},
			...schemaOptions
		}),
		defaultValues: {
			branch: props.companyProfile.branch,
			companyDescription: props.companyProfile.description,
			offerTypes: props.companyProfile.offerTypes
		}
	})
	const [isLoading, setLoading] = useState(false)

	useEffect(() => {
		if (Object.keys(errors).length) trigger()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localStorage.getItem('i18nextLng')])

	const onSubmit = data => {
		setLoading(true)
		const schema = {
			branch: data.branch,
			description: data.companyDescription,
			offerTypes: data.offerTypes
		}
		const updateCompany =
			path === TRoutes.COMPANY
				? http.put(TApi.COMPANY_PUT_PROFILE, schema)
				: http.put(TApi.ADMIN_GET_COMPANY(id), schema)

		updateCompany
			.then(() => {
				toast.success(t('Company profile has been updated successfully.'))
			})
			.finally(() => setLoading(false))
	}
	return (
		<div className={styles.root}>
			<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
				<div className={styles.section}>
					<h1 className={styles.sectionTitle}>{t('Business Activity')}</h1>
					<div className={styles.sectionContent}>
						<SelectField
							required
							className={styles.field}
							disabled={isLoading || props.companyProfile.isActive || props.companyProfile.isNeedReview}
							mb={36}
							label={t('branch')}
							control={control}
							name="branch"
							error={errors.branch}
							placeholder={t('Select Branch')}
							options={props.branch?.map(v => ({
								value: v.value_EN,
								label: i18n.language === TLanguages.EN ? v.value_EN : v.value_DE
							}))}
						/>

						<div className={styles.flexContainer}>
							<TextAreaField
								required
								classNameLabel={styles.textArea}
								disabled={isLoading}
								mb={36}
								height={128}
								label={t('description')}
								placeholder={t('descriptionPlaceholder')}
								error={errors.companyDescription}
								register={register('companyDescription')}
							/>

							<span className={styles.sectionNote}>{t('descriptionNote')}</span>
						</div>
						<div className={styles.flexContainer}>
							<SelectField
								required
								className={styles.field}
								mb={20}
								label={t('typeOffers')}
								control={control}
								name="offerTypes"
								error={errors.offerTypes}
								placeholder={t('typeOffers')}
								options={props.typeOfOffers?.map(v => ({
									value: v.value_EN,
									label: i18n.language === TLanguages.EN ? v.value_EN : v.value_DE
								}))}
								disabled={isLoading}
							/>

							<span className={styles.sectionNote}>{t('typeOffersNote')}</span>
						</div>

						{!props.isTeacherStudentView && (
							<div className={styles.btnContainer}>
								<Btn
									className={styles.btn}
									loading={isLoading}
									type="submit"
									icon="arrow"
									iconSize={{ w: 10, h: 10 }}
									iconColor="#fff"
								>
									{t('save')}
								</Btn>
							</div>
						)}
					</div>
				</div>
			</form>
		</div>
	)
}

CompanyProfile.propTypes = {
	companyProfile: PropTypes.object,
	branch: PropTypes.array.isRequired,
	typeOfOffers: PropTypes.array.isRequired,
	isTeacherStudentView: PropTypes.bool.isRequired
}
const mapStateToProps = state => ({
	branch: state.options.branch,
	typeOfOffers: state.options.typeOfOffers
})
export default connect(mapStateToProps, null)(CompanyProfile)
