import clsx from 'clsx'
import Avatar from 'react-avatar'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Btn from '../Btn'
import Chip from '../Chip'

import styles from './style.module.scss'
import FlatLink from '../FlatLink'

export default function CardUsers(props) {
	const { t } = useTranslation()

	if (!props.users?.length)
		return (
			<div className={clsx(styles.emptyBlock, props.className)}>
				{!!props.onAdd && (
					<Btn
						icon="plus"
						iconColor="#22404D"
						iconSize={{ w: 12, h: 12 }}
						theme="white"
						onClick={props.onAdd}
					/>
				)}
				<p className={styles.emptyBlockMessage}>{props.title}</p>
			</div>
		)

	return (
		<div className={clsx(styles.root, props.className)}>
			<div className={styles.header}>
				<h1 className={styles.title}>{props.title}</h1>
				{!!props.newCount && <Chip className={styles.newCount}>+{props.newCount}</Chip>}
				{!!props.toFullList && (
					<FlatLink
						className={styles.linkFullList}
						color="#303e65"
						icon="arrow"
						iconSize={{ w: 10, h: 10 }}
						iconColor="#008B9C"
						to={props.toFullList}
					>
						{t('Full list')}
					</FlatLink>
				)}
			</div>
			<span className={styles.info}>{props.labelCount}</span>

			<div className={styles.avatars}>
				{props.users
					.slice(0, 7)
					.map(user =>
						props.children ? (
							props.children(user)
						) : (
							<Avatar
								key={user.id}
								className={styles.avatar}
								src={user.avatar}
								name={user.fullName}
								size={30}
								round
							/>
						)
					)}
				{!!props.onAdd && (
					<Btn
						onClick={props.onAdd}
						className={styles.btnAdd}
						icon="plus"
						iconColor="#22404D"
						iconSize={{ w: 10, h: 10 }}
						theme="white"
					/>
				)}
			</div>
		</div>
	)
}

CardUsers.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string.isRequired,
	labelCount: PropTypes.string.isRequired,
	newCount: PropTypes.number,
	users: PropTypes.array.isRequired,
	toFullList: PropTypes.string,
	onAdd: PropTypes.func,
	children: PropTypes.func
}
