import clsx from 'clsx'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useEffect, useState } from 'react'
import * as queryString from 'query-string'
import { useLocation } from 'react-router-dom'

import http from '../../shared/http'
import { TApi } from '../../shared/const'

import LogoImg from '../../assets/images/logo.svg'

import styles from './common.module.scss'
import { setActiveConfirmEmail } from '../../redux/ducks/user'
import { useTranslation } from 'react-i18next'
import PolicyLinks from '../../UI/policyLinks'

function ExpertConfirm(props) {
	const { t } = useTranslation()
	const [isLoading, setLoading] = useState(false)
	const [message, setMessage] = useState('')
	const [isStatusError, setStatusError] = useState(false)
	const { search } = useLocation()

	const { token } = queryString.parse(search)

	const fetchConfirm = () => {
		setLoading(true)

		http.post(
			TApi.EXPERT_CONFIRM,
			{
				token
			},
			{
				headers: { Authorization: '' },
				disableToast: true
			}
		)
			.then(() => {
				setMessage('Activated')
				if (props.isAuth) props.setActiveConfirmEmail()
			})
			.catch(err => {
				const message = err.response?.data?.message || ''
				setMessage(`${message}`)
				setStatusError(true)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	useEffect(() => {
		if (!token) {
			setStatusError(true)
			return setMessage('Token is not founded')
		}

		fetchConfirm()
		/* eslint-disable-next-line */
	}, [])

	return (
		<div className={styles.root}>
			<div className={styles.header}>
				<div className={styles.miniContainer}>
					<h2 className={styles.title}>{t('Expert Activation')}</h2>
				</div>
				<PolicyLinks />
			</div>
			<div className={clsx(styles.container, styles.form, styles.confirmContentSuc)}>
				<img className={styles.logoImg} src={LogoImg} alt="Logo" />
				{isLoading && (
					<span className={styles.confirmText}>{t('Account confirmation is in progress. Loading...')}</span>
				)}
				{!!message && (
					<span className={clsx(styles.confirmText, isStatusError && styles.confirmTextError)}>
						{t(message)}
					</span>
				)}
			</div>
		</div>
	)
}

ExpertConfirm.propTypes = {
	isAuth: PropTypes.bool.isRequired,
	setActiveConfirmEmail: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
	isAuth: state.user.isAuth
})

const mapDispatchToProps = dispatch => ({
	setActiveConfirmEmail: () => dispatch(setActiveConfirmEmail())
})

export default connect(mapStateToProps, mapDispatchToProps)(ExpertConfirm)
