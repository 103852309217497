import { useState } from 'react'
import PropTypes from 'prop-types'
import AnimateHeight from 'react-animate-height'

import CheckBox from '../../Checkbox'
import CheckBoxFilter from './components/CheckBoxFilter'

import styles from '../style.module.scss'
import { useTranslation } from 'react-i18next'

export default function SectionBlock(props) {
	const { t } = useTranslation()
	const [isOpen, setOpen] = useState(false)

	return (
		<div className={styles.sectionBlock}>
			<div className={styles.filterSectionHeader}>
				<CheckBoxFilter checked={isOpen} onChange={setOpen} label={props.name} className={styles.mainCheck} />

				<button
					className={styles.btnLocalClear}
					onClick={evt => {
						evt.preventDefault()
						props.onChange([])
					}}
				>
					{t('Clear all')}
				</button>
			</div>
			<AnimateHeight duration={500} height={isOpen ? 'auto' : 0}>
				<div className={styles.popupOptions}>
					{props.options?.map(item => (
						<div className={styles.option} key={item.value}>
							<CheckBox
								value={item.value}
								label={item.label}
								register={{
									checked: props.values.find(({ value }) => value === item.value),
									onChange: evt => {
										if (evt.target.checked) props.onChange([...props.values, item])
										else props.onChange(props.values.filter(({ value }) => value !== item.value))
									}
								}}
								mb={10}
								classNameLabel={styles.checkName}
								classNameCheckbox={styles.check}
							/>
						</div>
					))}
				</div>
			</AnimateHeight>
		</div>
	)
}

SectionBlock.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired
		})
	).isRequired,
	name: PropTypes.string.isRequired,

	values: PropTypes.arrayOf({
		label: PropTypes.string.isRequired,
		value: PropTypes.string.isRequired
	}).isRequired,
	onChange: PropTypes.func.isRequired
}
