import { useEffect } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import Btn from '../../../../../../UI/Btn'
import TextField from '../../../../../../UI/TextField'
import styles from '../style.module.scss'
import Joi from 'joi'
import { joiResolver } from '@hookform/resolvers/joi'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

export default function TypeOfOffers(props) {
	const { t } = useTranslation()
	const schema = Joi.object({
		typeOfOffers: Joi.array().items(
			Joi.object().keys({
				value_EN: Joi.string().required(),
				value_DE: Joi.string().required()
			})
		)
	})
	const {
		control,
		register,
		reset,
		handleSubmit,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema)
	})
	const { fields, append, remove } = useFieldArray({
		control, // control props comes from useForm (optional: if you are using FormContext)
		name: 'typeOfOffers' // unique name for your Field Array
		// keyName: "id", default to "id", you can change the key name
	})

	const onSubmit = form => {
		props
			.updateOptions({ name: 'typeOfOffers', value: form.typeOfOffers })
			.then(() => toast.success(t('Options was updated')))
	}

	useEffect(() => {
		reset({ typeOfOffers: props.form?.typeOfOffers || [] })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.form])
	return (
		<form id="typeOfOffers" className={styles.root} onSubmit={handleSubmit(onSubmit, onSubmit)}>
			<section className={styles.section}>
				<h3 className={styles.sectionTitle}>{t('Type of Offers')}</h3>
				<div className={styles.sectionContent}>
					{fields?.map((field, index) => (
						<div key={field.id} className={styles.flexContainer} style={{ marginBottom: '10px' }}>
							<TextField
								label={index === 0 && 'EN'}
								className={clsx(styles.field, styles.typeOfOffers)}
								register={register(`typeOfOffers.${index}.value_EN`)}
								placeholder={`Input new typeOfOffers`}
								error={errors?.typeOfOffers?.[index]?.value_EN}
							/>
							<TextField
								label={index === 0 && 'DE'}
								className={clsx(styles.field, styles.typeOfOffers)}
								register={register(`typeOfOffers.${index}.value_DE`)}
								placeholder={`Input new typeOfOffers`}
								error={errors?.typeOfOffers?.[index]?.value_DE}
							/>

							<Btn
								className={styles.deleteBtnBranch}
								theme="white"
								icon="delete"
								iconColor="#000"
								type="button"
								onClick={() => remove(index)}
							/>
						</div>
					))}
					<div className={styles.addBtnBranch}>
						<Btn
							theme="white"
							icon="plus"
							iconColor="#22404D"
							iconSize={{ w: 9, h: 9 }}
							type="button"
							onClick={() => append({ value_EN: '', value_DE: '' })}
						/>
					</div>
					<div className={styles.btnContainer}>
						<Btn
							className={styles.btn}
							type="submit"
							icon="arrow"
							iconSize={{ w: 10, h: 10 }}
							iconColor="#fff"
						>
							{t('save')}
						</Btn>
					</div>
				</div>
			</section>
		</form>
	)
}
TypeOfOffers.propTypes = {
	number: PropTypes.bool,
	form: PropTypes.object.isRequired,
	updateOptions: PropTypes.func.isRequired
}
