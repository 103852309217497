import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import Btn from '../../../../UI/Btn'
import Icon from '../../../../UI/Icon'

import { TRoutes } from '../../../../shared/const'

import styles from './style.module.scss'
import { useTranslation } from 'react-i18next'

export default function CardFormRisk(props) {
	const { t } = useTranslation()
	const history = useHistory()

	return (
		<div className={clsx(styles.root, props.className, props.existsForm && styles.rootExistsForm)}>
			{props.existsForm ? (
				<div className={styles.existsForm} onClick={() => history.push(TRoutes.COMPANY_INSURANCE)}>
					<div className={styles.wrapperIcon}>
						<Icon name="paperParental" noFill size={{ w: 19, h: 21 }} />
					</div>
					<div className={styles.wrapper}>
						<h3 className={styles.title}>{t('riskAnalysis')}</h3>
						<span className={styles.description}>{t('Risk Analysis Form')}</span>
					</div>
				</div>
			) : (
				<div className={styles.empty}>
					<Btn
						disabled={props.disabled}
						onClick={() => {
							history.push(TRoutes.COMPANY_INSURANCE)
						}}
						icon="plus"
						iconColor="#22404D"
						iconSize={{ w: 12, h: 12 }}
						theme="white"
					/>
					<span className={styles.emptyBlockTitle}>{t('riskAnalysis')}</span>
				</div>
			)}
		</div>
	)
}

CardFormRisk.propTypes = {
	className: PropTypes.string,
	existsForm: PropTypes.bool.isRequired,
	disabled: PropTypes.bool
}
