import clsx from 'clsx'
import Avatar from 'react-avatar'
import PropTypes from 'prop-types'

import Btn from '../Btn'

import photoIcon from '../../assets/icons/photo.svg'

import styles from './style.module.scss'
import { useTranslation } from 'react-i18next'

export default function AvatarUploader(props) {
	const { t } = useTranslation()
	return (
		<div className={clsx(styles.root, props.className)}>
			<Avatar src={props.src} className={styles.preview} color="#e5eaf5" round size={80} />

			<div className={styles.wrapper}>
				{props.error && <span className={styles.error}>{props.error.message}</span>}
				<div className={styles.wrapperInput}>
					<label className={clsx(styles.labelUploadFile, props.disabled && styles.labelUploadFileDisabled)}>
						<input
							{...(props.register || {})}
							disabled={props.disabled}
							className={styles.input}
							type="file"
							accept="image/jpeg,image/png,image/gif"
							onChange={evt => {
								if (props.register) props.register.onChange(evt)
								if (props.onChange) props.onChange(evt)
							}}
							onClick={event => (event.target.value = null)}
						/>
						<img className={styles.labelUploadFileIcon} src={photoIcon} alt="icon" />
						<span>{t('Upload profile photo')}</span>
					</label>
					<Btn
						disabled={props.disabled}
						className={styles.btnRemove}
						onClick={props.onClean}
						icon="delete"
						iconColor="#008B9C"
						theme="white"
					/>
				</div>
			</div>
		</div>
	)
}

AvatarUploader.propTypes = {
	className: PropTypes.string,
	src: PropTypes.string,
	register: PropTypes.object,
	error: PropTypes.object,
	disabled: PropTypes.bool,
	onClean: PropTypes.func,
	onChange: PropTypes.func
}

AvatarUploader.defaultProps = {
	disabled: false,
	onClean: () => {}
}
