import PropTypes from 'prop-types'

import styles from '../style.module.scss'
import Btn from '../../../../../../UI/Btn'
import { schemaOptions } from '../../../../../../shared/i18n'
import Joi from 'joi'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { optionsDateWhioutTime } from '../../../../../../shared/const'
import TextFieldNumber from '../../../../../../UI/TextFieldNumber'

function RevenueExpenses(props) {
	const { t } = useTranslation()
	const schema = Joi.object({
		totalGrossRevenue: Joi.number().required().messages({
			'any.required': `is not allowed to be empty`
		}),
		salaryExpenses: Joi.number().required().messages({
			'any.required': `is not allowed to be empty`
		}),
		materialExpenses: Joi.number().required().messages({
			'any.required': `is not allowed to be empty`
		}),
		otherExpenses: Joi.number().required().messages({
			'any.required': `is not allowed to be empty`
		}),
		totalExpenses: Joi.number()
			.custom((value, helper) => {
				if (
					Math.round(
						(helper.state.ancestors[0].salaryExpenses +
							helper.state.ancestors[0].materialExpenses +
							helper.state.ancestors[0].otherExpenses) *
							100
					) /
						100 ===
					value
				)
					return value
				return helper.message(t('Wrong result'))
			})
			.required()
			.messages({
				'any.required': `is not allowed to be empty`
			}),
		profitLossBeforeTaxes: Joi.number()
			.custom((value, helper) => {
				if (
					Math.round(
						(helper.state.ancestors[0].totalGrossRevenue - helper.state.ancestors[0].totalExpenses) * 100
					) /
						100 ===
					value
				)
					return value
				return helper.message(t('Wrong result'))
			})
			.required()
			.messages({
				'any.required': `is not allowed to be empty`
			}),
		salaryDeferredSocialExpenses: Joi.number().required().messages({
			'any.required': `is not allowed to be empty`
		}),
		profitLossBeforeIncomeTax: Joi.number()
			.custom((value, helper) => {
				if (
					Math.round(
						(helper.state.ancestors[0].profitLossBeforeTaxes -
							helper.state.ancestors[0].profitLossInsuranceContribution -
							helper.state.ancestors[0].salaryDeferredSocialExpenses) *
							100
					) /
						100 ===
					value
				)
					return value
				return helper.message(t('Wrong result'))
			})
			.required()
			.messages({
				'any.required': `is not allowed to be empty`
			}),
		profitLossInsuranceContribution: Joi.number()
			.custom((value, helper) => {
				if (Math.round(helper.state.ancestors[0].salaryExpenses * 0.13 * 100) / 100 === value) return value
				return helper.message(t('Wrong result'))
			})
			.required()
			.messages({
				'any.required': `is not allowed to be empty`
			})
	})
	const [validate, setValidate] = useState(false)

	const {
		control,
		handleSubmit,
		reset,
		formState: { errors }
	} = useForm({
		resolver: validate
			? null
			: joiResolver(schema, {
					errors: {
						labels: false,
						language: localStorage.getItem('i18nextLng')
					},
					...schemaOptions
			  })
	})

	const onSubmit = form => {
		let data = form
		for (const key in data) {
			if (data[key] === '') {
				data[key] = null
			}
		}
		props
			.updateData(data)
			.then(() => {
				toast.success(t('Calculation of profit/loss before taxes was updated'))
				if (!validate) props.handlerNextStep()
			})
			.finally(() => {
				setValidate(false)
			})
	}

	useEffect(() => {
		reset({
			totalGrossRevenue: props.form?.totalGrossRevenue,
			salaryExpenses: props.form?.salaryExpenses,
			materialExpenses: props.form?.materialExpenses,
			otherExpenses: props.form?.otherExpenses,
			totalExpenses: props.form?.totalExpenses,
			profitLossBeforeTaxes: props.form?.profitLossBeforeTaxes,
			salaryDeferredSocialExpenses: props.juniorInsurance,
			profitLossBeforeIncomeTax: props.form?.profitLossBeforeIncomeTax,
			profitLossInsuranceContribution: props.form?.profitLossInsuranceContribution
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.form])

	if (props.currentStep !== props.needStep) return null
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<section className={styles.section} style={{ padding: '25px 0' }}>
				<div className={styles.sectionTitle} />
				<div className={styles.sectionContent}>
					<p className={styles.deadline}>{`${t('Deadline by')} ${new Date(
						props.reportsDeadline?.JCC
					).toLocaleDateString('de-DE', optionsDateWhioutTime)}`}</p>
					<p className={styles.title}>
						{t(
							'To successfully complete the Junior Compact Company the following final financial statement must be made.'
						)}
					</p>
				</div>
			</section>
			<section className={styles.section}>
				<h1 className={styles.sectionTitle}>{t('Revenue')}</h1>
				<div className={styles.sectionContent}>
					<div className={styles.flexContainer}>
						<TextFieldNumber
							disabled={props.isLoading || props.form?.isSubmitted}
							name="totalGrossRevenue"
							control={control}
							placeholder="00.000,00"
							error={errors.totalGrossRevenue}
							label={t('Total Income')}
						/>
						<p className={styles.description}>
							<p>{t('Total revenue.')}</p>
							{t(
								'All income from your sales and other income WITHOUT income from the sale of Junior Compact Company shares!'
							)}
						</p>
					</div>
				</div>
			</section>
			<section className={styles.section}>
				<h1 className={styles.sectionTitle}>{t('Expenses')}</h1>
				<div className={styles.sectionContent}>
					<div className={styles.flexContainer}>
						<TextFieldNumber
							mb={15}
							name="salaryExpenses"
							disabled={props.isLoading || props.form?.isSubmitted}
							control={control}
							placeholder="00.000,00"
							error={errors.salaryExpenses}
							label={t('Salary expenses')}
						/>
						<p className={styles.description}>
							<p>{t('Salary expenses')}</p>
							{t(
								'All expenses for wages of employees of your Junior Compact Company ("Entrepreneur`s Wages").'
							)}{' '}
						</p>
					</div>
					<div className={styles.flexContainer}>
						<TextFieldNumber
							mb={15}
							name="materialExpenses"
							disabled={props.isLoading || props.form?.isSubmitted}
							control={control}
							placeholder="00.000,00"
							error={errors.materialExpenses}
							label={`+ ${t('Material expenses')}`}
						/>
						<p className={styles.description}>
							<p>{t('Material expenses')}</p>
							{t(
								'All expenses for materials you needed to produce your products, including sales taxes.'
							)}{' '}
						</p>
					</div>
					<div className={styles.flexContainer}>
						<TextFieldNumber
							mb={15}
							name="otherExpenses"
							disabled={props.isLoading || props.form?.isSubmitted}
							control={control}
							placeholder="00.000,00"
							error={errors.otherExpenses}
							label={`+ ${t('Other expenses')}`}
						/>
						<p className={styles.description}>
							<p>{t('Other expenses')}</p>
							{t(
								'Other expenses such as transportation or travel expenses, company Christmas party, ....'
							)}{' '}
						</p>
					</div>
					<TextFieldNumber
						mb={15}
						name="totalExpenses"
						disabled={props.isLoading || props.form?.isSubmitted}
						control={control}
						placeholder="00.000,00"
						error={errors.totalExpenses}
						label={`= ${t('Sum of expenses')}`}
					/>
				</div>
			</section>
			<section className={styles.section} style={{ border: 'none' }}>
				<h1 className={styles.sectionTitle}>{t('Calculation of profit/loss before taxes')}</h1>
				<div className={styles.sectionContent}>
					<div className={styles.flexContainer}>
						<TextFieldNumber
							mb={15}
							name="profitLossBeforeTaxes"
							disabled={props.isLoading || props.form?.isSubmitted}
							control={control}
							placeholder="00.000,00"
							error={errors.profitLossBeforeTaxes}
							label={t('Profit/Loss (before tax)')}
						/>
						<p className={styles.description}>
							<p>{t('Profit/Loss (before tax)')}</p>
							{t('Preliminary profit/loss before deduction of taxes.')}
						</p>
					</div>
					<div className={styles.flexContainer}>
						<TextFieldNumber
							mb={15}
							name="profitLossInsuranceContribution"
							disabled={props.isLoading || props.form?.isSubmitted}
							control={control}
							placeholder="00.000,00"
							error={errors.profitLossInsuranceContribution}
							label={t('- 13% Salary-related social security contributions')}
						/>
						<p className={styles.description}>
							<p>{t('Salary-related social security contributions')}</p>
							{t(
								'Depending on the amount of salaries or entrepreneur wages paid, 13% social contributions must be paid.'
							)}
						</p>
					</div>
					<div className={styles.flexContainer}>
						<TextFieldNumber
							mb={15}
							name="salaryDeferredSocialExpenses"
							disabled
							control={control}
							placeholder="00.000,00"
							error={errors.salaryDeferredSocialExpenses}
							label={`- ${t('Insurance contribution')}`}
						/>
						<p className={styles.description}>
							<p>{t('Insurance contribution')}</p>
							{t('Insurance contribution for all activities of your Junior Company.')}
						</p>
					</div>
					<div className={styles.flexContainer}>
						<TextFieldNumber
							className={styles.field}
							name="profitLossBeforeIncomeTax"
							disabled={props.isLoading || props.form?.isSubmitted}
							control={control}
							placeholder="00.000,00"
							error={errors.profitLossBeforeIncomeTax}
							label={`= ${t('Profit/loss (before corporate income tax)')}`}
						/>
						<p className={styles.description}>
							<p>{t('Profit/loss (before corporate income tax)')}</p>
							{t(
								'Preliminary profit/loss before deduction of corporate income tax, which is due only in case of profit.'
							)}
						</p>
					</div>

					{!props.form?.isSubmitted && !props.isTeacherStudentView ? (
						<div className={styles.flexContainer}>
							<Btn
								type="submit"
								theme="whiteBackground"
								loading={props.isLoading}
								className={styles.btn}
								iconColor="#fff"
							>
								{t('Validate')}
							</Btn>

							<Btn
								className={styles.btn}
								loading={props.isLoading}
								type="submit"
								onClick={() => setValidate(true)}
							>
								{t('save')}
							</Btn>
						</div>
					) : (
						<Btn
							className={styles.btn}
							loading={props.isLoading}
							onClick={props.handlerNextStep}
							width={337}
						>
							{t('Next')}
						</Btn>
					)}
				</div>
			</section>
		</form>
	)
}
RevenueExpenses.propTypes = {
	form: PropTypes.object,
	updateData: PropTypes.func,
	currentStep: PropTypes.number,
	needStep: PropTypes.number,
	handlerNextStep: PropTypes.func,
	juniorInsurance: PropTypes.number.isRequired,
	reportsDeadline: PropTypes.object.isRequired,
	isTeacherStudentView: PropTypes.bool.isRequired
}
const mapStateToProps = state => ({
	juniorInsurance: state.options.juniorInsurance?.JCC,
	reportsDeadline: state.options.reportsDeadline,
	isTeacherStudentView: state.user.isTeacherStudentView
})
export default connect(mapStateToProps, null)(RevenueExpenses)
