import PropTypes from 'prop-types'

import DashboardAdmin from '../../components/views/admin/Dashboard'
import DashboardTeacher from '../../components/views/teacher/Dashboard'
import DashboardStudent from '../../components/views/student/Dashboard'

import injectRole from '../../components/HOC/injectRole'
import { TRoles } from '../../shared/const'

function Dashboard(props) {
	if ([TRoles.ADMIN, TRoles.FEDERAL_ADMIN].includes(props.role)) return <DashboardAdmin />
	else if (props.role === TRoles.TEACHER) return <DashboardTeacher />
	else return <DashboardStudent />
}

Dashboard.propTypes = {
	role: PropTypes.string.isRequired
}

export default injectRole(Dashboard)
