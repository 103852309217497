import { useState, useEffect } from 'react'
import styles from '../style.module.scss'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import Joi from 'joi'
import http from '../../../../../shared/http'
import { TApi, TLanguages, TRoles } from '../../../../../shared/const'
import { schemaOptions } from '../../../../../shared/i18n'
import { joiPhone } from '../../../../../shared/helpers'
import { joiResolver } from '@hookform/resolvers/joi'
import PropTypes from 'prop-types'

import SelectField from '../../../../../UI/SelectField'
import TextField from '../../../../../UI/TextField'
import DateTimeField from '../../../../../UI/DateTimeField'
import Btn from '../../../../../UI/Btn'
import { subYears } from 'date-fns'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { updateProfile } from '../../../../../redux/ducks/user'
import VisibilityAccess from '../../../../VisibilityAccess'

const schema = Joi.object({
	salutation: Joi.string().required(),
	firstName: Joi.string()
		.min(2)
		.max(26)
		.regex(/^[a-zA-ZА-яЁёÄÖÜẞäöüß]+( [a-zA-ZА-яЁёÄÖÜẞäöüß]+)*$/)
		.required()
		.messages({
			'string.pattern.base': 'Only letters are allowed !'
		}),
	lastName: Joi.string()
		.min(2)
		.max(26)
		.regex(/^[a-zA-ZА-яЁёÄÖÜẞäöüß]+( [a-zA-ZА-яЁёÄÖÜẞäöüß]+)*$/)
		.required()
		.messages({
			'string.pattern.base': 'Only letters are allowed !'
		}),
	dateOfBirth: Joi.object().allow(null),
	phone: joiPhone,
	titlePrefixed: Joi.string().allow('', null),
	titleSuffixed: Joi.string().allow('', null)
})

function BasicInfo(props) {
	const { t, i18n } = useTranslation()
	const {
		register,
		control,
		handleSubmit,
		reset,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema, {
			errors: {
				labels: false,
				language: localStorage.getItem('i18nextLng')
			},
			...schemaOptions
		})
	})
	const [isLoading, setLoading] = useState(false)

	useEffect(() => {
		reset({
			salutation: props.salutation,
			firstName: props.firstName,
			lastName: props.lastName,
			dateOfBirth: props.dateOfBirth && new Date(Date.parse(props.dateOfBirth)),
			phone: props.phone,
			titlePrefixed: props.titlePrefixed,
			titleSuffixed: props.titleSuffixed
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props])

	const onSubmit = data => {
		setLoading(true)

		http.put(TApi.ADMIN_PUT_PROFILE, data)
			.then(() => {
				props.updateProfile(data)
				toast.success(t('Profile has been updated successfully.'))
			})
			.finally(() => setLoading(false))
	}

	const minAge = subYears(new Date(), 6)
	const maxAge = subYears(new Date(), 20)

	return (
		<div className={styles.root}>
			<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
				<div className={styles.section}>
					<h1 className={styles.sectionTitle}>{t('Basic Info')}</h1>
					<div className={styles.sectionContent}>
						<div className={styles.flexContainer}>
							<SelectField
								required
								disabled={isLoading}
								mb={20}
								label={t('salutation')}
								control={control}
								name="salutation"
								error={errors.salutation}
								placeholder="Salutation"
								options={props.salutations?.map(v => ({
									value: v.value_EN,
									label: i18n.language === TLanguages.EN ? v.value_EN : v.value_DE
								}))}
							/>
							<VisibilityAccess access={[TRoles.ADMIN]}>
								<TextField
									disabled
									mb={20}
									label={t('state')}
									placeholder={props.state}
									value={props.state}
								/>
							</VisibilityAccess>
						</div>
						<div className={styles.flexContainer}>
							<TextField
								required
								disabled={isLoading}
								mb={20}
								label={t('First Name')}
								placeholder={props.firstName}
								error={errors.firstName}
								register={register('firstName')}
							/>
							<TextField
								required
								disabled={isLoading}
								mb={20}
								label={t('Last Name')}
								placeholder={props.lastName}
								error={errors.lastName}
								register={register('lastName')}
							/>
						</div>

						<div className={styles.flexContainer}>
							<DateTimeField
								disabled={isLoading}
								mb={20}
								label={t('Date of Birth')}
								placeholder="Date of Birth"
								name="dateOfBirth"
								error={errors.dateOfBirth}
								control={control}
								dateFormat="dd.MM.yyyy"
								minDate={maxAge}
								maxDate={minAge}
							/>
							<TextField
								required
								disabled={isLoading}
								mb={20}
								label={t('phone')}
								placeholder="+43 6XX XXXXXX"
								error={errors.phone}
								register={register('phone')}
							/>
						</div>
						<div className={styles.flexContainer}>
							<TextField
								disabled={props.isLoading}
								mb={20}
								label={t('Title Prefixed')}
								error={errors.titlePrefixed}
								placeholder={t('Enter Title')}
								register={register('titlePrefixed')}
							/>
							<TextField
								disabled={props.isLoading}
								mb={20}
								label={t('Title Suffixed')}
								placeholder={t('Enter Title')}
								error={errors.titleSuffixed}
								register={register('titleSuffixed')}
							/>
						</div>
						<div className={styles.btnContainer}>
							<Btn
								loading={isLoading}
								className={clsx(styles.btn)}
								type="submit"
								icon="arrow"
								iconSize={{ w: 10, h: 10 }}
								iconColor="#fff"
							>
								{t('save')}
							</Btn>
						</div>
					</div>
				</div>
			</form>
		</div>
	)
}
BasicInfo.propTypes = {
	salutation: PropTypes.string.isRequired,
	firstName: PropTypes.string.isRequired,
	lastName: PropTypes.string.isRequired,
	dateOfBirth: PropTypes.object,
	phone: PropTypes.string.isRequired,
	state: PropTypes.string.isRequired,
	titleSuffixed: PropTypes.string.isRequired,
	titlePrefixed: PropTypes.string.isRequired,
	salutations: PropTypes.array.isRequired
}
const mapStateToProps = state => ({
	salutation: state.user.profile.salutation,
	firstName: state.user.profile.firstName,
	lastName: state.user.profile.lastName,
	dateOfBirth: state.user.profile.dateOfBirth,
	phone: state.user.profile.phone || '',
	state: state.user.profile.state,
	titleSuffixed: state.user.profile.titleSuffixed,
	titlePrefixed: state.user.profile.titlePrefixed,
	salutations: state.options.salutations
})

const mapDispatchToProps = dispatch => ({
	updateProfile: data => dispatch(updateProfile(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(BasicInfo)
