import { useEffect, useMemo, useState } from 'react'
import Btn from '../../../../UI/Btn'
import Table from '../../../../UI/Table'
import MiniHeader from '../../../../UI/MiniHeader'
import { useHistory } from 'react-router-dom'
import http from '../../../../shared/http'
import { StateOptions, TApi, TCompanies, TRoutes } from '../../../../shared/const'
import styles from './style.module.scss'
import BtnTooltip from '../../../../UI/BtnTooltip'
import Search from '../../../../UI/Search'
import Filter from '../../../../UI/Filter'
import BtnFilter from '../../../../UI/BtnFilter'
import { useTranslation } from 'react-i18next'
import Pagination from '../../../../UI/Pagination'
import { format } from 'date-fns'
import { blobTypes, customNumberFormat, downloadFile, generateFilter } from '../../../../shared/helpers'
import clsx from 'clsx'
import Icon from '../../../../UI/Icon'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { setSearchLimit } from '../../../../redux/ducks/user'
import LimitFilterButton from '../../../../UI/LimitFilterButton'
import { setCompanyFilter } from '../../../../redux/ducks/filter'

const saldo = {
	JC: '800',
	JCC: '800',
	JBC: '300'
}

function Companies(props) {
	const { t } = useTranslation()
	const history = useHistory()
	const [companies, setCompanies] = useState([])
	const [countAll, setCountAll] = useState(0)
	const [page, setPage] = useState(0)
	const [sort, setSort] = useState({ name: 'createdAt', direction: 'desc' })
	const [search, setSearch] = useState('')
	const [showFilter, setShowFilter] = useState(false)
	const [countFilters, setCountFilters] = useState(0)
	const [loading, setLoading] = useState(false)
	const options = [
		{ value: 10, label: 10 },
		{ value: 100, label: 100 },
		{ value: 1000, label: 1000 }
	]
	const { defaultLimit } = props
	const handleChangeLimit = selectedOption => {
		props.setSearchLimit(selectedOption.value)
	}

	// тут запит на сервер зі всіми фільтрами (з пагінацією і з пошуком також)
	const offset = page * defaultLimit

	const fetchAllCompanies = () => {
		const filter = generateFilter(props.companyFilter)

		http.get(TApi.ADMIN_GET_COMPANIES, {
			params: {
				limit: defaultLimit,
				offset,
				order: sort.name,
				direction: sort.direction,
				search,
				filter: JSON.stringify(filter)
			}
		}).then(({ data }) => {
			const tableArray = data.allCompanies.map(v => {
				const supervision = v.TeachersSupervisions.find(({ isSupervision }) => isSupervision)
				const supervisionTeacher = supervision
					? `${supervision?.Teacher?.firstName} ${supervision?.Teacher?.lastName}`
					: '-'
				return {
					...v,
					balance: `${(v.Invoice?.[0]?.balance && customNumberFormat(v.Invoice?.[0]?.balance)) || '-'} | ${
						(v.Invoice?.[1]?.balance && customNumberFormat(v.Invoice?.[1]?.balance)) || '-'
					}`,
					school: v.TeachersSupervisions?.[0]?.Teacher?.School?.name || '-',
					state: v.state,
					createdAt: format(new Date(v.createdAt), 'dd.MM.yyyy'),
					reportsCount:
						TCompanies.JC === v.companyType
							? (v.AnnualReport?.isSubmitted && v.SemiannualReport?.isSubmitted && '2') ||
							  (v.AnnualReport?.isSubmitted && 1) ||
							  (v.SemiannualReport?.isSubmitted && 1) ||
							  '0'
							: (v.SemiannualReport?.isSubmitted && 1) || '0',
					analysisPlan: Boolean(v.insuranceCount),
					saldo: saldo[v.companyType],
					supervisionTeacher
				}
			})
			setCompanies(tableArray)
			setCountAll(data.count)
		})
	}

	useEffect(() => {
		fetchAllCompanies()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, sort, defaultLimit])

	const handleSearch = e => {
		if (page === 0) fetchAllCompanies()
		setPage(0)
	}

	useEffect(() => {
		if (Object.values(props.companyFilter).every(item => !item.length)) fetchAllCompanies()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.companyFilter])

	const handleSort = (name, direction) => {
		setSort({ name, direction })
	}

	const handleOpen = id => history.push(TRoutes.COMPANY_REVIEW(id))

	const openConfirmRemoveModal = async id => {
		const { isConfirmed } = await Swal.fire({
			title: t('Are you sure?'),
			text: t('You will not be able to revert this!'),
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#0CA789',
			cancelButtonColor: '#d33',
			confirmButtonText: t('Yes, delete it!'),
			cancelButtonText: t('Cancel')
		})

		if (!isConfirmed) return

		setLoading(true)
		try {
			await http.delete(TApi.ADMIN_DELETE_COMPANY(id))
			fetchAllCompanies()
			toast.success(t('The company successfully deleted'))
		} finally {
			setLoading(false)
		}
	}

	const downloadExport = id => {
		setLoading(true)
		console.log(id)

		http.get(TApi.ADMIN_DOWNLOAD_COMPANY_DATA(id), {
			responseType: 'arraybuffer'
		})
			.then(res => {
				var blob = new Blob([res.data], {
					type: blobTypes.XLS
				})
				downloadFile('Company', blob)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const columns = useMemo(
		() => [
			{
				Header: t('Company'),
				accessor: 'name',
				Cell: ({ row }) => (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							wordBreak: 'break-word',
							width: '100%',
							justifyContent: 'space-between'
						}}
					>
						<span style={{ overflow: 'hidden', width: '120px' }}>{row.values.name}</span>
						<div style={{ marginRight: '20px' }}>
							<BtnTooltip
								items={[
									{
										label: t('Open profile'),
										onClick: () => handleOpen(row.original.id)
									},
									{
										disabled: row.original.isActive,
										label: t('update'),
										onClick: () => history.push(TRoutes.UPDATE_COMPANY(row.original.id))
									},
									{
										disabled:
											row.original.isActive ||
											row.original.SemiannualReport ||
											row.original.AnnualReport,
										label: t('Delete'),
										onClick: () => openConfirmRemoveModal(row.original.id)
									},
									{
										label: t('Export'),
										onClick: () => downloadExport(row.original.id)
									}
								]}
							/>
						</div>
					</div>
				),
				minWidth: 150
			},

			{
				Header: t('Type'),
				accessor: 'companyType',
				width: 60
			},
			{
				Header: t('Stat.'),
				headerToolTip: t('Company status'),
				accessor: 'isActive',
				Cell: ({ row }) => {
					if (row.original.isNeedReview) return <span className={styles.red}>{t('C')}</span>
					if (row.original.isActive) return <span className={styles.green}>A</span>
					return 'R'
				},
				width: 50
			},
			{
				Header: t('A&P'),
				headerToolTip: t('Analysis & Plan'),
				accessor: 'analysisPlan',
				disableSortBy: true,
				Cell: ({ row }) =>
					row.values.analysisPlan ? (
						<span className={styles.green}>{t('Yes').slice(0, 1)}</span>
					) : (
						<span className={styles.red}>{t('No').slice(0, 1)}</span>
					),
				width: 50
			},
			{
				Header: t('Emp.'),
				headerToolTip: t('Employees'),
				accessor: 'employeesCount',
				width: 50
			},
			{
				Header: t('Pa.C'),
				headerToolTip: t('Parental consent'),
				accessor: 'parentalConfirmationCount',
				disableSortBy: true,
				width: 50
			},
			{
				Header: t('School'),
				accessor: 'school',
				Cell: ({ row }) => (
					<span style={{ overflow: 'hidden', wordBreak: 'break-word' }}>{row.values.school}</span>
				),
				width: 120
			},
			{
				Header: t('FS'),
				headerToolTip: t('Fed. State'),
				accessor: 'state',
				Cell: ({ row }) =>
					t(StateOptions.find(({ value }) => value === row.original.state)?.label)
						?.split(' ')
						.map(v => v.slice(0, 1))
						.join(''),
				width: 50
			},
			{
				Header: t('Rep.'),
				headerToolTip: t('Reports'),
				accessor: 'reportsCount',
				disableSortBy: true,
				width: 50
			},

			{
				Header: t('Bal.'),
				headerToolTip: t('Balance'),
				accessor: 'balance',
				width: 100
			},
			{
				Header: t('Reg.Dat.'),
				accessor: 'createdAt',
				width: 80
			},
			{
				Header: t('Teacher'),
				accessor: 'supervisionTeacher',
				Cell: ({ row }) => (
					<span style={{ overflow: 'hidden', wordBreak: 'break-word' }}>{row.values.supervisionTeacher}</span>
				),
				minWidth: 120
			}
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[t]
	)

	const downoloadList = fileType => {
		setLoading(true)

		http.get(TApi.ADMIN_DOWNOLOAD_COMPANIES_LIST, {
			params: {
				type: fileType,
				leng: localStorage.getItem('i18nextLng')
			},
			responseType: 'arraybuffer'
		})
			.then(res => {
				var blob = new Blob([res.data], {
					type: blobTypes[fileType]
				})
				downloadFile('Companies', blob)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	return (
		<div className={styles.root}>
			<MiniHeader className={styles.miniHeader} title={t('companies')}>
				<div className={styles.miniHeaderContent}>
					<Search
						value={search}
						className={styles.search}
						placeholder={t('Search companies...')}
						onChange={e => setSearch(e.target.value)}
						onSubmit={e => {
							e.preventDefault()
							handleSearch()
						}}
					/>
					<BtnFilter
						className={styles.filterBtn}
						icon="arrow"
						width={150}
						onClick={() => setShowFilter(true)}
						count={countFilters}
					>
						Filter ({countFilters})
					</BtnFilter>
				</div>
			</MiniHeader>
			<div className={styles.subHeader}>
				<Pagination
					limit={defaultLimit}
					page={page}
					label={t('companies')}
					countAll={countAll}
					onChange={setPage}
				/>
				<div className={styles.downloadContent}>
					{t('Download list as')}
					<div>
						{!loading && (
							<Btn
								theme="download"
								iconSize={{ w: 24, h: 24 }}
								icon="download"
								onClick={() => downoloadList('XLS')}
							>
								XLS
							</Btn>
						)}
						{loading && (
							<Icon
								className={clsx(styles.icon, styles.rootLoading)}
								size={{ w: 16, h: 16 }}
								name="loading"
								color={'#22404D'}
							/>
						)}
						{!loading && (
							<Btn
								theme="download"
								iconSize={{ w: 24, h: 24 }}
								icon="download"
								onClick={() => downoloadList('PDF')}
							>
								PDF
							</Btn>
						)}
					</div>
				</div>
				<LimitFilterButton limit={defaultLimit} options={options} onChange={handleChangeLimit} />
			</div>
			<div className="container">
				<Table
					columns={columns}
					items={companies}
					page={page}
					limit={defaultLimit}
					countAll={countAll}
					onChange={setPage}
					handleSort={handleSort}
				/>
			</div>
			<Filter
				toShow={{
					states: true,
					companyTypes: true,
					branches: true,
					schools: true,
					teachers: true,
					isActive: true,
					isNeedReview: true,
					schoolType: true
				}}
				onSearch={handleSearch}
				showFilter={showFilter}
				setShowFilter={setShowFilter}
				onCountFilters={setCountFilters}
				onFilterOptions={props.setFilter}
				filters={props.companyFilter}
			/>
		</div>
	)
}
const mapStateToProps = state => ({
	defaultLimit: state.user.defaultLimit,
	email: state.user.profile.email,
	companyFilter: state.filter.companies
})

const mapDispatchToProps = dispatch => ({
	setSearchLimit: data => dispatch(setSearchLimit(data)),
	setFilter: data => dispatch(setCompanyFilter(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Companies)
