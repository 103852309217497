import clsx from 'clsx'
import PropTypes from 'prop-types'

import styles from './style.module.scss'

export default function BtnToggle(props) {
	return (
		<label className={styles.switch}>
			<input type="checkbox" onClick={props.onClick} />
			<span className={clsx(styles.slider, styles.round)}></span>
		</label>
	)
}
BtnToggle.propTypes = {
	onClick: PropTypes.func
}
