import { useMemo, useEffect, useState } from 'react'
import clsx from 'clsx'
import Avatar from 'react-avatar'
import styles from './style.module.scss'
import http from '../../../../shared/http'
import { TApi, TLanguages } from '../../../../shared/const'
import Table from '../../../../UI/Table'
import MiniHeader from '../../../../UI/MiniHeader'
import { useTranslation } from 'react-i18next'
import Btn from '../../../../UI/Btn'
import eventEmitter, { types } from '../../../../shared/eventEmitter'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import BtnTooltip from '../../../../UI/BtnTooltip'
import imageWarning from '../../../../assets/images/warning.png'
import imageChecked from '../../../../assets/images/checked.png'
import Swal from 'sweetalert2'
import ReactTooltip from 'react-tooltip'
import { fullLogOut } from '../../../../shared/helpers'

const statusClassName = {
	Active: styles.statusActive,
	Verification: styles.statusVerification,
	Registered: styles.statusRegistered
}

function CompanyEmployee(props) {
	const { t, i18n } = useTranslation()
	const [employees, setEmployees] = useState([])
	const [invitedEmployees, setInvitedEmployees] = useState([])
	const [countAll, setCountAll] = useState(0)
	const [page, setPage] = useState(0)
	const [sort, setSort] = useState({ name: 'name', direction: 'asc' })
	const limit = 10
	const [isLoading, setLoading] = useState(false)

	const fetchEmployees = async offset => {
		const [
			{
				data: { rows, count }
			},
			{ data: invitedUsers }
		] = await Promise.all([
			http.get(TApi.STUDENT_GET_EMPLOEES, {
				params: {
					limit,
					offset,
					order: sort.name,
					direction: sort.direction
				}
			}),
			http.get(TApi.STUDENT_GET_INVITED_EMPLOYEES)
		])
		setEmployees(rows)
		setCountAll(count)
		setInvitedEmployees(invitedUsers.map(user => ({ ...user, isInvited: true })))
		ReactTooltip.rebuild()
	}

	useEffect(() => {
		fetchEmployees(page * limit)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, sort])

	const handleSort = (name, direction) => {
		setSort({ name, direction })
	}

	const makeCEO = userId => {
		http.put(TApi.STUDENT_MAKE_CEO(userId))
			.then(() => {
				toast.success(t('CEO Updated'))
			})
			.finally(() => {
				fetchEmployees()
			})
	}

	const deleteEmployee = (userId, isInvited) => {
		const url = isInvited ? TApi.STUDENT_DELETE_INVITE_EMPLOYEE(userId) : TApi.STUDENT_DELETE_EMPLOYEE(userId)
		const sameUser = userId === props.userId

		setLoading(true)
		http.delete(url)
			.then(() => {
				if (sameUser) {
					fullLogOut()
				} else {
					toast.success(t('Employee successfully deleted'))
					Swal.fire(t('The employee was deleted successfully'))
					eventEmitter.emit(types.refreshEmployeesNavbar)
				}
			})
			.finally(() => {
				if (!sameUser) fetchEmployees()
				setLoading(false)
			})
	}

	const handleDeleteButton = async ({ userId, isCEO, isInvited }) => {
		if (isCEO)
			return Swal.fire({
				icon: 'warning',
				title: t('The company must not be leaderless!'),
				text: t('First assign your CEO function to another person!'),
				confirmButtonColor: '#0CA789',
				iconColor: '#E1574C'
			})

		const { isConfirmed } = await Swal.fire({
			title: t('Are you sure?'),
			text: t('Remove employee.'),
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#0CA789',
			iconColor: '#E1574C',
			cancelButtonColor: '#d33',
			confirmButtonText: t('Remove'),
			cancelButtonText: t('Cancel')
		})

		if (isConfirmed) deleteEmployee(userId, isInvited)
	}
	const columns = useMemo(
		() => [
			{
				Header: () => {
					return <span className={styles.tableTitleName}>Name</span>
				},
				minWidth: 250,
				accessor: 'name',
				Cell: ({ row }) => {
					const { isInvited } = row.original
					const fullName = isInvited
						? t('Not verified')
						: row.original.firstName
						? `${row.original.firstName} ${row.original.lastName}`
						: '-------'
					return (
						<span className={styles.nameContainer}>
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									width: '100%'
								}}
							>
								<div className={styles.avatarWrapper}>
									<Avatar className={styles.userAvatar} round name={fullName} size={32} />

									<span className={styles.name}>{fullName}</span>
								</div>
							</div>
							{!isInvited && !props.isTeacherStudentView && (
								<div>
									<BtnTooltip
										items={[
											{
												label: t('Make CEO'),
												onClick: () => makeCEO(row.original.id),
												disabled: row.original.isCEO
											},
											{
												label: t('Edit function & working area'),
												onClick: () =>
													eventEmitter.emit(
														types.openStudentWorkAreaModal,
														{
															studentId: row.original.id,
															isCEO: row.original.isCEO,
															primaryWorkArea: row.original.primaryWorkArea,
															secondaryWorkArea: row.original.secondaryWorkArea,
															tertiaryWorkArea: row.original.tertiaryWorkArea
														},
														() => fetchEmployees(page * limit)
													)
											}
										]}
									/>
								</div>
							)}
						</span>
					)
				}
			},
			{
				Header: t('Function & working area'),
				accessor: 'primaryWorkArea',
				Cell: ({ row }) => {
					const { primaryWorkArea } = row.original
					const text = primaryWorkArea ? (
						i18n.language === TLanguages.DE ? (
							props.workAreas?.find(v => v.value_EN === primaryWorkArea)?.value_DE
						) : (
							props.workAreas?.find(v => v.value_EN === primaryWorkArea)?.value_EN
						)
					) : (
						<span className={styles.parentalWrapper}>
							<img
								src={imageWarning}
								className={styles.warning}
								alt=""
								data-tip={t('A working area has to be selected. Please assign for a working area')}
							/>
							{t('No working area')}
						</span>
					)

					return <p className={styles.position}>{text || t(primaryWorkArea)}</p>
				}
			},
			{
				Header: t('E-Mail'),
				accessor: 'email',
				Cell: ({ row }) => {
					return <p className={styles.position}>{row.original.email}</p>
				}
			},
			{
				Header: t('phone'),
				accessor: 'phone',
				Cell: ({ row }) => {
					return <p className={styles.phone}>{row.original.phone}</p>
				}
			},

			{
				Header: 'Status',
				accessor: 'status',
				Cell: ({ row }) => {
					if (row.original.isInvited)
						return (
							<span className={clsx(styles.statusWrapper, styles.status)}>
								<img
									src={imageWarning}
									className={styles.warningStatus}
									data-tip={
										props.rejectedParentalConfirmation
											? t('Parental confirmation was rejected, upload new please !')
											: t('Need to upload parental confirmation')
									}
									alt=""
								/>
								{t('Pending')}
							</span>
						)

					return (
						<div className={styles.statusContainer}>
							<span
								className={clsx(
									styles.status,
									row.original.emailConfirmed ? statusClassName.Active : statusClassName.Verification
								)}
							>
								{row.original.emailConfirmed ? 'Active' : 'Verification'}
							</span>
						</div>
					)
				}
			},
			{
				Header: `Status ${t('Parental Confirmation')}`,
				Cell: ({ row }) => {
					const {
						Documents,
						rejectedParentalConfirmation,
						reviewedParentalConfirmation,
						isInvited,
						id,
						isCEO
					} = row.original

					const btn = !props.isTeacherStudentView && (
						<Btn
							loading={isLoading}
							iconColor="#000"
							iconSize={{ w: 16, h: 16 }}
							icon={isLoading ? '' : 'delete'}
							theme="white"
							onClick={() =>
								handleDeleteButton({
									isInvited,
									userId: id,
									isCEO
								})
							}
						/>
					)

					if (isInvited)
						return (
							<div className={styles.statusContainer}>
								<div className={clsx(styles.parentalWrapper, styles.status)}>
									{' '}
									<img src={imageWarning} className={styles.warning} alt="" />
									<span style={{ color: '#46B17B', marginLeft: '5px' }}>{t('Open')}</span>
								</div>
								{btn}
							</div>
						)

					return (
						<div className={styles.statusContainer}>
							<span className={clsx(styles.status)}>
								{reviewedParentalConfirmation && !!Documents[0] && (
									<div className={styles.parentalWrapper}>
										<img src={imageChecked} className={styles.checked} alt="" />
										<span style={{ color: 'blue' }}>{t('Checked')}</span>
									</div>
								)}
								{!!Documents[0] && !rejectedParentalConfirmation && !reviewedParentalConfirmation && (
									<div className={styles.parentalWrapper}>
										<img src={imageChecked} className={styles.checked} alt="" />
										<span style={{ color: '#285F74', marginLeft: '10px' }}>{t('Submitted')}</span>
									</div>
								)}
								{!Documents[0] && !rejectedParentalConfirmation && (
									<div className={styles.parentalWrapper}>
										{' '}
										<img src={imageWarning} className={styles.warning} alt="" />
										<span style={{ color: '#46B17B', marginLeft: '5px' }}>{t('Open')}</span>
									</div>
								)}
								{!!Documents[0] && rejectedParentalConfirmation && (
									<div className={styles.parentalWrapper}>
										<img src={imageWarning} className={styles.warning} alt="" />
										<span style={{ color: 'red' }}>{t('Rejected')}</span>
									</div>
								)}
							</span>
							{btn}
						</div>
					)
				}
			}
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[t]
	)
	return (
		<div className={styles.root}>
			<MiniHeader className={styles.miniHeader} title={t('Company Employee')} bg="#f8faff">
				{!props.isActivatedCompany && !props.isNeedReview && !props.isTeacherStudentView && (
					<Btn
						className={styles.addEmployeeBtn}
						icon="plus"
						iconColor="#fff"
						theme="orange"
						onClick={() => eventEmitter.emit(types.openAddEmployeeModal)}
					>
						{t('Add Employee')}
					</Btn>
				)}
			</MiniHeader>
			<div className="container">
				<Table
					columns={columns}
					items={[...invitedEmployees, ...employees]}
					page={page}
					limit={limit}
					countAll={countAll}
					onChange={setPage}
					handleSort={handleSort}
				/>
			</div>
		</div>
	)
}

CompanyEmployee.propTypes = {
	isActivatedCompany: PropTypes.bool.isRequired,
	isNeedReview: PropTypes.bool.isRequired,
	isTeacherStudentView: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
	isActivatedCompany: !!state.user.profile.EmploymentCompany?.isActive,
	isNeedReview: !!state.user.profile.EmploymentCompany?.isNeedReview,
	workAreas: state.options.workAreas,
	isTeacherStudentView: state.user.isTeacherStudentView,
	userId: state.user.profile.id
})

export default connect(mapStateToProps)(CompanyEmployee)
