import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useLocation, Link } from 'react-router-dom'

import styles from './style.module.scss'

export default function PopUpList(props) {
	const { pathname, search } = useLocation()

	useEffect(() => {
		const handler = () => {
			if (props.isOpen) setTimeout(() => props.onClose(), 0)
		}
		document.addEventListener('mouseup', handler)
		return () => {
			document.removeEventListener('mouseup', handler)
		}
	})

	if (!props.isOpen) return null

	const longPopUp = props?.popUpItems?.length > 5

	return (
		<nav className={clsx(styles.root, longPopUp && styles.fixedRoot)}>
			{props?.popUpItems.map(item => (
				<Link
					key={item.name}
					to={item.to}
					className={clsx(
						styles.navItem,
						item.className,
						`${pathname}${search}` === item.to && styles.navItemActive
					)}
				>
					<span className={styles.navItemName}>{item.name}</span>
				</Link>
			))}
		</nav>
	)
}

PopUpList.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	popUpItems: PropTypes.array.isRequired
}
