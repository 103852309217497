import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import MainInfo from './components/MainInfo'
import CreateCompany from './components/CreateCompany'
import CompanyDetails from './components/CompanyDetails'
import TeacherDetails from './components/TeacherDetails'
import PersonalDetails from './components/PersonalDetails'
import AwaitConfirmEmail from './components/AwaitConfirmEmail'

import { INVITATION_TYPE, TRoles } from '../../../shared/const'
import AwaitInvitation from './components/AwaitInvitation'

function SignUp(props) {
	const [activeStep, setActiveStep] = useState(0)
	const [companyType, setCompanyType] = useState(null)

	useEffect(() => {
		if (INVITATION_TYPE === companyType || props.isAwait) return setActiveStep(4)
		// setActiveStep(5)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.isAwait])
	/*const handleSubmitInvite = fields => {
		const isStudent = role.toLowerCase() === TRoles.STUDENT.toLowerCase()
		const form = new FormData()

		const newData = {
			...data,
			...fields
		}
		let schema = {
			password: newData.password,
			salutation: newData.salutation,
			gender: newData.gender,
			firstName: newData.firstName,
			lastName: newData.lastName,
			phone: newData.phone,
			inviteToken: invite
		}

		setLoading(true)
		if (isStudent)
			schema = {
				...schema,
				birthday: newData.birthday
			}
		else
			schema = {
				...schema,
				titlePrefixed: newData.titlePrefixed,
				titleSuffixed: newData.titleSuffixed
			}

		for (const property in schema) {
			form.append(property, schema[property])
		}

		if (newData.avatar && newData.avatar[0]) form.append('avatar', newData.avatar[0])
		if (newData.parentalConfirmation && newData.parentalConfirmation[0])
			form.append('parentalConfirmation', newData.parentalConfirmation[0])

		http
			.post(isStudent ? TApi.SING_UP_USER_STUDENT_INVITE : TApi.SING_UP_USER_TEACHER_INVITE, form)
			.then(() => {
				toast.success(t('Registration was successful. Please sign in'))
				history.push(TRoutes.SIGN_IN)
			})
			.finally(() => setLoading(false))
	}*/

	const roleSingUp =
		props.role === TRoles.STUDENT ? <PersonalDetails onNext={() => setActiveStep(3)} /> : <TeacherDetails />

	const views = [
		<MainInfo onNext={() => setActiveStep(1)} />,
		<AwaitConfirmEmail onNext={() => setActiveStep(2)} />,
		roleSingUp,
		<CreateCompany setStep={setActiveStep} setCompanyType={setCompanyType} />,
		<AwaitInvitation />,
		<CompanyDetails companyType={companyType} />
	]
	return views[activeStep]
}

SignUp.propTypes = {
	role: PropTypes.string.isRequired,
	isAwait: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
	role: state.user.role,
	isAwait: state.user.profile.isAwait
})

export default connect(mapStateToProps)(SignUp)
