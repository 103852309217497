import clsx from 'clsx'
import Avatar from 'react-avatar'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Btn from '../../../../UI/Btn'
import Icon from '../../../../UI/Icon'
import Uploader from '../../../../UI/Uploader'
import CardUsers from '../../../../UI/СardUsers'
import CardFormRisk from '../../common/CardFormRisk'
import ProgressBarPage from '../../../../UI/ProgresBarPage'
import { toast } from 'react-toastify'
import News from '../../common/News'

import http from '../../../../shared/http'
import { TApi, TCompanies } from '../../../../shared/const'
import eventEmitter, { types } from '../../../../shared/eventEmitter'
import { downloadFile, mapCompanyTypeToName } from '../../../../shared/helpers'

import { updateCompanyInfo } from '../../../../redux/ducks/user'

import styles from './style.module.scss'
import Swal from 'sweetalert2'
import i18n from 'i18next'

function Dashboard(props) {
	const { t } = useTranslation()
	const [dashboard, setDashboard] = useState({
		news: [],
		lastExperts: [],
		employees: {
			count: 0,
			rows: []
		},
		experts: {
			count: 0,
			rows: []
		},
		supervisingTeacher: {},
		teachers: {
			count: 0,
			rows: []
		},
		riskAnalysis: null,
		businessPlan: null,
		shareholders: [],
		activationsStatus: {},
		lastReports: [],
		finalReport: null
	})

	const [avatarSrc, setAvatarSrc] = useState('')
	const [isLoading, setLoading] = useState(false)
	useEffect(() => {
		setAvatarSrc(
			dashboard.supervisingTeacher.avatarHashName
				? TApi.PUBLIC_PROFILE_AVATAR(dashboard.supervisingTeacher.avatarHashName)
				: ''
		)
	}, [dashboard.supervisingTeacher.avatarHashName])

	const handleDoneExpert = expert => {
		setDashboard({
			...dashboard,
			experts: {
				count: dashboard.experts.count + 1,
				rows: [...dashboard.experts.rows, expert]
			},
			activationsStatus: {
				...dashboard.activationsStatus,
				experts: true
			}
		})
	}

	const handleAddBusinessPlan = evt => {
		setLoading(true)
		const form = new FormData()

		form.append('document', evt.target.files[0])

		http.post(TApi.STUDENT_POST_UPLOAD_BUSINESS_PLAN, form)
			.then(({ data }) => {
				setDashboard({
					...dashboard,
					businessPlan: data,
					activationsStatus: {
						...dashboard.activationsStatus,
						businessPlan: true
					}
				})
				toast.success(t('Business Plan uploaded successfully'))
			})
			.finally(() => setLoading(false))
	}

	const handleDeleteBusinessPlan = evt => {
		evt.preventDefault()
		evt.stopPropagation()
		setLoading(true)

		http.delete(TApi.STUDENT_POST_UPLOAD_BUSINESS_PLAN)
			.then(() => {
				setDashboard({
					...dashboard,
					businessPlan: null,
					activationsStatus: {
						...dashboard.activationsStatus,
						businessPlan: false
					}
				})
				toast.success(t('Business Plan removed successfully'))
			})
			.finally(() => setLoading(false))
	}

	const handleDeleteFinalReport = evt => {
		evt.preventDefault()
		evt.stopPropagation()
		setLoading(true)

		http.delete(TApi.STUDENT_POST_UPLOAD_FINAL_REPORT)
			.then(() => {
				setDashboard({
					...dashboard,
					finalReport: null,
					activationsStatus: {
						...dashboard.activationsStatus,
						finalReport: false
					}
				})
				toast.success(t('Final report removed successfully'))
			})
			.finally(() => setLoading(false))
	}

	const handleAddFinalReport = evt => {
		setLoading(true)
		const form = new FormData()

		form.append('document', evt.target.files[0])

		http.post(TApi.STUDENT_POST_UPLOAD_FINAL_REPORT, form, { timeout: 180000 })
			.then(({ data }) => {
				setDashboard({
					...dashboard,
					finalReport: data,
					activationsStatus: {
						...dashboard.activationsStatus,
						finalReport: true
					}
				})
				toast.success(t('Final Report uploaded successfully'))
			})
			.finally(() => setLoading(false))
	}

	const handleSubmitReview = () => {
		const { activationsStatus } = dashboard

		const isAllowReview =
			activationsStatus.experts &&
			activationsStatus.riskAnalysis &&
			activationsStatus.employees &&
			activationsStatus.parentalConfirmation &&
			!activationsStatus.isInvitedEmployeesCount &&
			activationsStatus.isWorkAreaAssigned

		if (isAllowReview)
			return http.put(TApi.STUDENT_PUT_SET_REVIEW).then(() => {
				props.updateCompanyInfo({
					isNeedReview: true
				})
				toast.success(i18n.t('Company submitted for verification and activation.'))
			})

		const errorMessages = [
			!activationsStatus.riskAnalysis && t('Complete company profile & risk analysis'),
			!activationsStatus.employees && t('The company must consist of at least two employees'),
			!activationsStatus.experts && t('At least 1 business expert'),
			!activationsStatus.parentalConfirmation && t('Parental consent from all employees'),
			activationsStatus.isInvitedEmployeesCount && t('Wait for confirmation new employee'),
			!activationsStatus.isInvitedEmployeesCount &&
				!activationsStatus.isWorkAreaAssigned &&
				t('Every employee must have a function & working area')
		]
		const message = `${t('Requirements')}: <br> ${errorMessages.filter(Boolean).join('<br>')}`
		Swal.fire({
			icon: 'warning',
			title: t('Approval not possible yet!'),
			html: message,
			confirmButtonColor: '#0CA789'
		})
	}

	useEffect(() => {
		if (!props.isSignInMode && props.isAuth && props.email) {
			http.get(TApi.STUDENT_GET_DASHBOARD).then(({ data }) => {
				setDashboard({ ...dashboard, ...data })
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.isAuth, props.isSignInMode, props.email])

	return (
		<div className={styles.root}>
			<div className={clsx('container', styles.header)}>
				<div>
					<h2 className={styles.companyTitle}>{props.company.name}</h2>
					<p className={styles.companyType}>{mapCompanyTypeToName(props.company.type)}</p>
					<span className={styles.branch}>{t(props.company.branch)}</span>
				</div>
				<div className={styles.statusWrapper}>
					{props.company.isActivated ? (
						<span className={styles.tag}>{t('Company approved')}</span>
					) : (
						<span className={styles.tagCompanyStatus}>
							<Icon name="danger" size={{ w: 24, h: 24 }} color="#fff" />
							<span className={styles.tagStatusText}>
								{props.company.isNeedReview
									? t('Company submitted for approval')
									: t('Company not yet approved')}
							</span>
						</span>
					)}
					{!props.company.isNeedReview && !props.company.isActivated && !props.isTeacherStudentView && (
						<Btn
							disabled={isLoading}
							onClick={handleSubmitReview}
							className={styles.btnSubmitReview}
							theme="minimal"
						>
							{t('Submit for approval')}
						</Btn>
					)}
				</div>
				<div className={styles.user}>
					{dashboard.supervisingTeacher?.firstName ? (
						<Avatar
							className={styles.userAvatar}
							src={avatarSrc}
							size={32}
							round
							name={`${dashboard.supervisingTeacher?.firstName} ${dashboard.supervisingTeacher?.lastName}`}
						/>
					) : (
						''
					)}

					<div className={styles.userInfo}>
						<span className={styles.userFullName}>
							{dashboard.supervisingTeacher?.firstName} {dashboard.supervisingTeacher?.lastName}
						</span>
						<span className={styles.userType}>{t('Supervision Teacher')}</span>
					</div>
					<span>
						{t('Lehrer: ')}
						{dashboard.Teacher?.count} {dashboard.supervisingTeacher?.count}
					</span>
					{!props.isTeacherStudentView && (
						<Btn
							disabled={isLoading}
							className={styles.userProfileAdd}
							icon="plus"
							iconColor="#22404D"
							iconSize={{ w: 12, h: 12 }}
							theme="white"
							onClick={() => eventEmitter.emit(types.openAddTeacherModal)}
						/>
					)}
				</div>
				<div>
					{dashboard.Teacher?.count} {dashboard.supervisingTeacher?.count}
				</div>
			</div>
			<div className={styles.userInfo}>
				<div>
					{t('Lehrer: ')}
					{dashboard.Teacher?.count} {dashboard.supervisingTeacher?.count}
				</div>
				<div>
					<span>{t('Lehrer: ')}</span>
				</div>
			</div>
			<ProgressBarPage
				isActivated={props.company?.isActivated}
				values={dashboard.activationsStatus}
				companyType={props.company.type}
				reportsDeadline={props.options?.reportsDeadline}
				isNeedReview={props.company.isNeedReview}
			/>
			<div className={clsx('container', styles.usersCards)}>
				<CardUsers
					className={styles.usersCard}
					users={dashboard.employees.rows.map(item => ({
						fullName: `${item.firstName} ${item.lastName}`,
						avatar: item.avatarHashName && TApi.PUBLIC_PROFILE_AVATAR(item.avatarHashName)
					}))}
					labelCount={`${dashboard.employees.count} ${t('employees')}`}
					title={t('companyEmployees')}
					onAdd={
						props.company.isActivated || props.company.isNeedReview || props.isTeacherStudentView
							? undefined
							: () =>
									eventEmitter.emit(types.openAddEmployeeModal, () =>
										http
											.get(TApi.STUDENT_GET_DASHBOARD)
											.then(({ data }) => setDashboard({ ...dashboard, ...data }))
									)
					}
				/>
				<CardUsers
					className={styles.usersCard}
					users={dashboard.teachers.rows.map(item => {
						const teacher = item.Teacher
						return {
							fullName: `${teacher.firstName} ${teacher.lastName}`,
							avatar: teacher.avatarHashName && TApi.PUBLIC_PROFILE_AVATAR(teacher.avatarHashName)
						}
					})}
					labelCount={`${dashboard.teachers.count} ${t('teachers')}`}
					title={t('Further support teachers')}
					onAdd={
						props.company.isActivated || props.company.isNeedReview || props.isTeacherStudentView
							? undefined
							: () => eventEmitter.emit(types.openAddTeacherModal)
					}
				/>
				<CardUsers
					className={styles.usersCard}
					users={dashboard.experts.rows.map(item => ({
						fullName: `${item.firstName} ${item.lastName}`,
						avatar: null
					}))}
					labelCount={`${dashboard.experts.count} ${t('experts')}`}
					title={t('Economic experts')}
					onAdd={
						props.company.isActivated || props.company.isNeedReview || props.isTeacherStudentView
							? undefined
							: () => eventEmitter.emit(types.openAddExpertModal, null, handleDoneExpert)
					}
				/>
				<CardFormRisk existsForm={!!dashboard.riskAnalysis} className={styles.usersCard} disabled={isLoading} />
				<Uploader
					isLoading={isLoading}
					className={clsx((!dashboard.businessPlan || isLoading) && styles.emptyBlockFile, styles.usersCard)}
					title={dashboard.businessPlan?.originalName ? t('Download Business Plan') : t('uploadBP')}
					accept="application/pdf"
					disabled={dashboard.businessPlan || props.isTeacherStudentView}
					nameFile={dashboard.businessPlan?.originalName}
					onChange={handleAddBusinessPlan}
					createdAt={dashboard.businessPlan?.createdAt}
					onDelete={props.isTeacherStudentView ? undefined : handleDeleteBusinessPlan}
					onClick={() => {
						if (!dashboard.businessPlan?.originalName) return
						http.get(TApi.PUBLIC_GET_FILES_DOCUMENT(dashboard.businessPlan.name), {
							headers: {
								'Content-Type': 'application/pdf'
							},
							responseType: 'blob'
						}).then(({ data }) => downloadFile(dashboard.businessPlan.originalName, data))
					}}
				/>
				{(props.company?.type === TCompanies.JC
					? dashboard.activationsStatus?.reports?.Semiannual &&
					  dashboard.activationsStatus?.reports?.AnnualReport
					: dashboard.activationsStatus?.reports?.Semiannual ||
					  dashboard.activationsStatus?.reports?.AnnualReport) && (
					<Uploader
						isLoading={isLoading}
						className={clsx(
							(!dashboard.finalReport || isLoading) && styles.emptyBlockFile,
							styles.usersCard
						)}
						title={
							dashboard.finalReport?.originalName ? t('Download Final Report') : t('Upload Final Report')
						}
						accept="application/pdf"
						disabled={dashboard.finalReport || props.isTeacherStudentView}
						nameFile={dashboard.finalReport?.originalName}
						createdAt={dashboard.finalReport?.createdAt}
						onChange={handleAddFinalReport}
						onDelete={props.isTeacherStudentView ? undefined : handleDeleteFinalReport}
						onClick={() => {
							if (!dashboard.finalReport?.originalName) return
							http.get(TApi.PUBLIC_GET_FILES_DOCUMENT(dashboard.finalReport.name), {
								headers: {
									'Content-Type': 'application/pdf'
								},
								responseType: 'blob'
							}).then(({ data }) => downloadFile(dashboard.finalReport.originalName, data))
						}}
					/>
				)}
			</div>
			{/* 			<div className={clsx('container', quickListStyles.quickLists)}>
				<QuickList
					linkTitle={t('Go To News')}
					className={quickListStyles.quickList}
					title={t('news')}
					items={dashboard.news}
					noMessages={[t('No news yet')]}
					toFullList={TRoutes.NEWS}>
					{item => (
						<FeedItem
							key={item.id}
							id={item.id}
							title={item.title}
							description={item.description}
							startDate={item.startDate}
							endDate={item.endDate}
							place={item.place}
							externalLink={item.externalLink}
							roles={item.roles}
							states={item.states}
							companyTypes={item.companyTypes}
						/>
					)}
				</QuickList>
				<QuickList
					linkTitle={t('Go To Shares')}
					noMessages={[t('No shares yet'), t('You can sell the company shares after activating the company')]}
					className={quickListStyles.quickList}
					items={dashboard.shareholders}
					title={t('shares')}
					toFullList={TRoutes.SHARES}>
					{item => <ShareItem key={item.id} name={`${item.firstName}  ${item.lastName}`} amount={item.companyShare} />}
				</QuickList>
				<QuickList
					title={t('reports')}
					className={quickListStyles.quickList}
					items={dashboard.lastReports?.[0] || dashboard.lastReports?.[1] ? dashboard.lastReports : []}
					linkTitle={t('Go To Reports')}
					noMessages={[t('No reports yet'), t('You can create first report after Company activation')]}
					toFullList={TRoutes.REPORTS}>
					{item => item && <ReportItem key={item.id} type={item.type} />}
				</QuickList>
			</div> */}
			<News />
		</div>
	)
}

Dashboard.propTypes = {
	company: PropTypes.shape({
		isActivated: PropTypes.bool.isRequired,
		isNeedReview: PropTypes.bool.isRequired,
		name: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
		branch: PropTypes.string.isRequired
	}).isRequired,

	updateCompanyInfo: PropTypes.func.isRequired,

	isAuth: PropTypes.bool.isRequired,
	isSignInMode: PropTypes.bool.isRequired,
	email: PropTypes.bool.isRequired,
	options: PropTypes.object.isRequired,
	isTeacherStudentView: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
	company: {
		isActivated: !!state.user.profile.EmploymentCompany?.isActive,
		isNeedReview: !!state.user.profile.EmploymentCompany?.isNeedReview,
		name: state.user.profile.EmploymentCompany?.name || '',
		type: state.user.profile.EmploymentCompany?.companyType || '',
		branch: state.user.profile.EmploymentCompany?.branch || ''
	},
	isAuth: state.user.isAuth,
	isSignInMode: state.user.isSignInMode,
	email: state.user.profile.email,
	options: state.options,
	profile: state.user.profile.EmploymentCompany?.isActive,
	isTeacherStudentView: state.user.isTeacherStudentView
})

const mapDispatchToProps = dispatch => ({
	updateCompanyInfo: data => dispatch(updateCompanyInfo(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
