import Joi from 'joi'
import PropTypes from 'prop-types'
import { parseISO, subDays } from 'date-fns'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { joiResolver } from '@hookform/resolvers/joi'

import Checkbox from '../../../../UI/Checkbox'
import Hr from '../../../../UI/Hr'
import Btn from '../../../../UI/Btn'
import TextField from '../../../../UI/TextField'
import SelectField from '../../../../UI/SelectField'
import TextAreaField from '../../../../UI/TextAreaField'
import DateTimeField from '../../../../UI/DateTimeField'
import BaseModal from '../../../../UI/BaseModal/BaseModal'

import http from '../../../../shared/http'
import { schemaOptions } from '../../../../shared/i18n'
import { types } from '../../../../shared/eventEmitter'
import { TApi, TCompanies, TRoles, StateOptions, TNews, TLanguages } from '../../../../shared/const'

import styles from './style.module.scss'
import injectRole from '../../../HOC/injectRole'

const schema = Joi.object({
	newsType: Joi.any().valid(TNews.EVENT, TNews.NEWS).required(),
	title: Joi.string().max(255).required(),
	description: Joi.string().max(500).required(),
	place: Joi.string().max(255).empty('').allow(null).default(null),
	externalLink: Joi.string().uri().empty('').allow(null).default(null),
	startDate: Joi.date().min(subDays(new Date(), 1)).empty('').allow(null).default(null).required(),
	endDate: Joi.date()
		.min(subDays(new Date(), 1))
		.greater(Joi.ref('startDate'))
		.empty('')
		.allow(null)
		.default(null)
		.required(),
	states: Joi.array().min(1).items(Joi.string()).allow(null).allow('').default(null),
	roles: Joi.array().min(1).items(Joi.string()).allow(null).default(null),
	schoolType: Joi.array().min(1).items(Joi.string()).allow(null).default(null),
	companyTypes: Joi.array().min(1).items(Joi.string()).allow(null).default(null),
	companyBranch: Joi.array().min(1).items(Joi.string()).allow(null).default(null),
	sendEmail: Joi.boolean().default(false)
})

function AddFeedModal(props) {
	const { t, i18n } = useTranslation()
	const {
		reset,
		control,
		setValue,
		trigger,
		register,
		handleSubmit,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema, {
			errors: {
				labels: false,
				language: localStorage.getItem('i18nextLng')
			},
			...schemaOptions
		})
	})

	const [id, setId] = useState(null)
	const [isLoading, setLoading] = useState(false)
	const [onDone, setOnDone] = useState(null)
	const [isChecked, setIsChecked] = useState(false)

	const handleCheckboxChange = event => {
		setIsChecked(event.target.checked)
	}
	const rolesOptions = [TRoles.STUDENT, TRoles.TEACHER].map(item => ({
		label: item,
		value: item
	}))
	const companyTypesOptions = Object.values(TCompanies).map(item => ({
		label: item,
		value: item
	}))
	const newsTypesOptions = Object.values(TNews).map(item => ({
		label: t(item),
		value: item
	}))

	useEffect(() => {
		if (Object.keys(errors).length) trigger()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localStorage.getItem('i18nextLng')])

	const onSubmit = (fields, closeModal) => {
		setLoading(true)
		if (fields.states?.some(state => state === 'ALL')) fields.states = null
		const form = { ...fields } // Update sendEmail value

		const request = id ? http.put(TApi.ADMIN_PUT_NEWS(id), form) : http.post(TApi.ADMIN_POST_NEWS, form)

		request
			.then(() => {
				toast.success(`The news was ${id ? 'updated' : 'created'} successfully`)
				closeModal()
				setId(null)
				if (onDone) onDone()
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const handleOpen = (feed = null, onDone = null) => {
		setOnDone(() => onDone)
		if (!feed) return
		setId(feed.id || null)
		setValue('newsType', feed.newsType || '')
		setValue('title', feed.title || '')
		setValue('description', feed.description || '')
		setValue('place', feed.place || '')
		setValue('externalLink', feed.externalLink || '')
		setValue('startDate', feed.startDate && parseISO(feed.startDate))
		setValue('endDate', feed.endDate && parseISO(feed.endDate))
		setValue('states', feed.states || ['ALL'])
		setValue('roles', feed.roles)
		setValue('schoolType', feed.schoolType)
		setValue('companyTypes', feed.companyTypes)
		setValue('companyBranch', feed.companyBranch)
	}

	return (
		<BaseModal
			onClose={reset}
			onOpen={handleOpen}
			nameEvent={types.openAddFeedModal}
			title={t('Add event')}
			isLoading={isLoading}
			maxWidth={522}
		>
			{({ closeModal }) => (
				<form className={styles.root} onSubmit={handleSubmit(form => onSubmit(form, closeModal))}>
					<SelectField
						label={t('Message type')}
						className={styles.textField}
						valuesToOptions
						mb={25}
						control={control}
						name="newsType"
						placeholder={t('Select type or types')}
						options={newsTypesOptions}
						required
						error={errors.newsType}
					/>
					<TextField
						className={styles.textField}
						required
						mb={25}
						label={t('Title')}
						register={register('title')}
						error={errors.title}
						placeholder={t('Title')}
					/>
					<TextAreaField
						mb={25}
						label={t('description')}
						required
						height={200}
						maxLength={500}
						showLettersLeft
						register={register('description')}
						error={errors.description}
						placeholder={t('description')}
					/>
					<TextField
						className={styles.textField}
						mb={25}
						label={t('Place')}
						register={register('place')}
						error={errors.place}
						placeholder={t('Place')}
					/>
					<TextField
						className={styles.textField}
						label={t('External Link')}
						register={register('externalLink')}
						error={errors.externalLink}
						placeholder={t('External Link')}
					/>
					<span className={styles.uriTip}>{t('Example')}: https://google.com</span>
					<DateTimeField
						required
						className={styles.textField}
						control={control}
						error={errors.startDate}
						mb={25}
						label={t('Start date')}
						name="startDate"
						placeholder={t('Start date')}
					/>
					<DateTimeField
						required
						className={styles.textField}
						control={control}
						error={errors.endDate}
						mb={25}
						label={t('End date')}
						name="endDate"
						placeholder={t('End date')}
					/>

					<Hr />
					<h4>{t('Target groups')}</h4>
					<SelectField
						label={t('state')}
						className={styles.textField}
						isMulti
						mb={25}
						control={control}
						error={errors.states}
						valuesToOptions
						options={[
							{
								value: 'ALL',
								label: t('All states')
							},
							...StateOptions.map(v => ({
								label: t(v.label),
								value: v.value
							})).sort((a, b) => a.label.localeCompare(b.label))
						]}
						name="states"
						placeholder={t('Select state or states')}
						required={false}
					/>
					<SelectField
						label={t('User roles')}
						className={styles.textField}
						control={control}
						error={errors.roles}
						valuesToOptions
						mb={25}
						isMulti
						options={rolesOptions.map(v => ({
							label: t(v.value),
							value: v.value
						}))}
						name="roles"
						placeholder={t('Select role or roles')}
						required={false}
					/>

					<SelectField
						label={t('School Types')}
						className={styles.textField}
						mb={30}
						valuesToOptions
						isMulti
						control={control}
						name="schoolType"
						error={errors.schoolType}
						placeholder={t('Select')}
						options={props.schoolTypes?.map(v => ({
							value: v.value,
							label: v.value
						}))}
						required={false}
					/>

					<SelectField
						label={t('Company type')}
						className={styles.textField}
						error={errors.companyTypes}
						valuesToOptions
						isMulti
						mb={25}
						control={control}
						options={companyTypesOptions}
						name="companyTypes"
						placeholder={t('Select type or types')}
						required={false}
					/>

					<SelectField
						label={t('branch')}
						className={styles.textField}
						valuesToOptions
						isMulti
						mb={25}
						control={control}
						name="companyBranch"
						placeholder={t('branch')}
						options={props.branch?.map(v => ({
							value: v.value_EN,
							label: i18n.language === TLanguages.EN ? v.value_EN : v.value_DE
						}))}
						required={false}
					/>
					<Checkbox
						classNameCheckbox={styles.checkbox}
						classNameLabel={styles.checkboxLabel}
						label={t('Notify via email to all users')}
						register={register('sendEmail')}
						checked={isChecked}
						onChange={handleCheckboxChange}
					/>
					<Btn
						loading={isLoading}
						width={155}
						className={styles.btnSubmit}
						type="submit"
						icon="send"
						iconSize={{ w: 15, h: 15 }}
						iconColor="#fff"
					>
						{t('save')}
					</Btn>
				</form>
			)}
		</BaseModal>
	)
}

AddFeedModal.propTypes = {
	schoolTypes: PropTypes.array,
	branch: PropTypes.array,
	role: PropTypes.array,
	state: PropTypes.array
}

export default injectRole(AddFeedModal, state => ({
	state: state.user.profile.state
}))
