import clsx from 'clsx'
import PropTypes from 'prop-types'
import styles from './style.module.scss'
import LocationIcon from '../../assets/icons/location.svg'

export default function Location(props) {
	return (
		<label>
			<p className={styles.userLocationLabel}>{props.label}</p>
			<div className={clsx(styles.userLocation, props.className)}>
				<img className={styles.userLocationIcon} src={LocationIcon} alt="" />
				<div className={styles.userLocationText}>
					<h3>{props.title},</h3> <p>{props.allInfo}</p>
				</div>
			</div>
		</label>
	)
}
Location.propTypes = {
	className: PropTypes.string,
	label: PropTypes.string,
	title: PropTypes.string,
	allInfo: PropTypes.string
}
