import styles from './style.module.scss'
import PropTypes from 'prop-types'
import clsx from 'clsx'

export default function Loading(props) {
	return (
		<div className={clsx(styles.root, props.className)}>
			<div className={styles.loader}>Loading...</div>
		</div>
	)
}
Loading.propTypes = {
	className: PropTypes.string
}
