import { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Table from '../../../../UI/Table'
import MiniHeader from '../../../../UI/MiniHeader'
import { useTranslation } from 'react-i18next'

import styles from './style.module.scss'
import Search from '../../../../UI/Search'
import Btn from '../../../../UI/Btn'
import { StateOptions, TApi, TRoutes } from '../../../../shared/const'
import http from '../../../../shared/http'
import Pagination from '../../../../UI/Pagination'
import BtnTooltip from '../../../../UI/BtnTooltip'
import Filter from '../../../../UI/Filter'
import BtnFilter from '../../../../UI/BtnFilter'
import eventEmitter, { types } from '../../../../shared/eventEmitter'
import { toast } from 'react-toastify'
import { blobTypes, downloadFile, generateFilter } from '../../../../shared/helpers'
import clsx from 'clsx'
import Icon from '../../../../UI/Icon'
import { setSchoolFilter } from '../../../../redux/ducks/filter'
import { connect } from 'react-redux'

function Schools(props) {
	const { t } = useTranslation()
	const history = useHistory()
	const [schools, setSchools] = useState([])
	const [countSchools, setCountSchools] = useState(0)
	const [page, setPage] = useState(0)
	const [search, setSearch] = useState('')
	const [showFilter, setShowFilter] = useState(false)
	const [countFilters, setCountFilters] = useState(0)
	const [sort, setSort] = useState({ name: 'name', direction: 'asc' })
	const [loading, setLoading] = useState(false)
	const limit = 10

	const fetchSchools = () => {
		const filter = generateFilter(props.schoolFilter)

		http.get(TApi.ADMIN_GET_SCHOOLS, {
			params: {
				limit,
				offset: page * limit,
				order: sort.name,
				direction: sort.direction,
				filter: JSON.stringify(filter),
				search
			}
		}).then(({ data }) => {
			setSchools(data.rows)
			setCountSchools(data.count)
		})
	}

	const confirmSchool = id => {
		http.put(TApi.ADMIN_SCHOOL_VERIFY(id), { verified: true }).then(() => {
			toast.success(t('The school has been approved'))
			fetchSchools()
		})
	}

	const handleSort = (name, direction) => {
		setSort({ name, direction })
	}
	const handleSearch = () => {
		if (page === 0) fetchSchools()
		setPage(0)
	}
	useEffect(() => {
		fetchSchools()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, sort])

	useEffect(() => {
		if (Object.values(props.schoolFilter).every(item => !item.length)) fetchSchools()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.schoolFilter])

	const columns = useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'name',
				minWidth: 250,
				Cell: ({ row }) => {
					return (
						<div className={styles.name}>
							<span style={{ fontWeight: 'bold', color: '#303E65' }}>{row.original.name}</span>
							<BtnTooltip
								items={[
									{
										label: t('Merge school and remove'),
										onClick: () =>
											eventEmitter.emit(types.openMergeSchoolModal, row.original, () =>
												fetchSchools()
											)
									},
									{
										label: t('details'),
										onClick: () => history.push(`${TRoutes.UPDATE_SCHOOL(row.original.id)}`)
									},
									{
										label: t('Approve school'),
										onClick: () => confirmSchool(row.original.id),
										disabled: row.values.verified
									}
									/*{
							label: t('Remove'),
							onClick: () => deleteSchool(row.original.id),
							disabled: row.original.Members?.length
						}*/
								]}
							/>
						</div>
					)
				}
			},
			{
				Header: t('state'),
				accessor: 'state',
				Cell: ({ row }) => {
					return <span>{t(StateOptions.find(({ value }) => value === row.original.state)?.label)}</span>
				},
				width: 100
			},
			{
				Header: t('Type'),
				accessor: 'type',
				Cell: ({ row }) => {
					return <span>{row.original.type}</span>
				},
				width: 200
			},
			{
				Header: t('Number of companies'),
				accessor: 'schoolCompanies',
				Cell: ({ row }) => {
					return <span>{row.original.schoolCompanies}</span>
				},
				disableSortBy: true,
				width: 100
			},
			{
				Header: t('Number of Students'),
				accessor: 'schoolStudents',
				Cell: ({ row }) => {
					return <span>{row.original.schoolStudents}</span>
				},
				disableSortBy: true,
				width: 100
			},
			{
				Header: 'Status',
				accessor: 'verified',
				Cell: ({ row }) => {
					return (
						<div className={styles.status}>
							<p style={row.original.verified ? { color: 'green' } : { color: '#ef8400' }}>
								{row.original.verified ? t('Verified') : t('Not Verified')}
							</p>
						</div>
					)
				},
				width: 60
			}
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[t]
	)
	const downoloadList = fileType => {
		setLoading(true)
		http.get(TApi.ADMIN_DOWNOLOAD_SCHOOLS_LIST, {
			params: {
				type: fileType,
				leng: localStorage.getItem('i18nextLng')
			},
			responseType: 'arraybuffer'
		})
			.then(res => {
				var blob = new Blob([res.data], {
					type: blobTypes[fileType]
				})
				downloadFile('Schools', blob)
			})
			.finally(() => {
				setLoading(false)
			})
	}
	return (
		<div className={styles.root}>
			<MiniHeader className={styles.miniHeader} title={t('schools')}>
				<div className={styles.miniHeaderContent}>
					<Search
						onChange={e => setSearch(e.target.value)}
						value={search}
						onSubmit={e => {
							e.preventDefault()
							handleSearch()
						}}
						className={styles.search}
						placeholder={t('Find a school')}
					/>
					<BtnFilter
						className={styles.filterBtn}
						icon="arrow"
						onClick={() => setShowFilter(true)}
						count={countFilters}
					>
						Filter ({countFilters})
					</BtnFilter>
				</div>
			</MiniHeader>
			<div className={styles.subHeader}>
				<Pagination limit={limit} page={page} label={t('schools')} countAll={countSchools} onChange={setPage} />

				<Btn
					// loading={isLoading}
					type="button"
					className={styles.addBtn}
					icon="plus"
					iconColor="white"
					onClick={() => history.push(TRoutes.CREATE_SCHOOL)}
				>
					{t('Create School')}
				</Btn>
				<div className={styles.downloadContent}>
					{t('Download list as')}
					<div>
						{!loading && (
							<Btn
								theme="download"
								iconSize={{ w: 24, h: 24 }}
								icon="download"
								onClick={() => {
									downoloadList('XLS')
								}}
							>
								XLS
							</Btn>
						)}
						{loading && (
							<Icon
								className={clsx(styles.icon, styles.rootLoading)}
								size={{ w: 16, h: 16 }}
								name="loading"
								color={'#22404D'}
							/>
						)}
						{!loading && (
							<Btn
								theme="download"
								iconSize={{ w: 24, h: 24 }}
								icon="download"
								onClick={() => {
									downoloadList('PDF')
								}}
							>
								PDF
							</Btn>
						)}
					</div>
				</div>
			</div>
			<div className="container">
				<Table
					items={schools}
					handleSort={handleSort}
					columns={columns}
					limit={limit}
					countAll={countSchools}
					onChange={setPage}
					page={page}
					paginationLabel={t('schools')}
				/>
			</div>
			<Filter
				toShow={{
					states: true,
					schoolType: true,
					schoolStatus: true,
					companyTypes: true
				}}
				onSearch={handleSearch}
				showFilter={showFilter}
				setShowFilter={setShowFilter}
				onCountFilters={setCountFilters}
				onFilterOptions={props.setFilter}
				filters={props.schoolFilter}
			/>
		</div>
	)
}

const mapStateToProps = state => ({
	schoolFilter: state.filter.schools
})

const mapDispatchToProps = dispatch => ({
	setFilter: data => dispatch(setSchoolFilter(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Schools)
