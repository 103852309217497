import PropTypes from 'prop-types'
import { useEffect, useLayoutEffect } from 'react'
import { useFlexLayout, usePagination, useSortBy, useTable } from 'react-table'
import clsx from 'clsx'
import Icon from '../Icon'
import { useTranslation } from 'react-i18next'

import style from './style.module.scss'
import Pagination from '../Pagination'
import ReactTooltip from 'react-tooltip'

export default function Table(props) {
	const { i18n } = useTranslation()
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		state: { sortBy }
	} = useTable(
		{
			columns: props.columns,
			data: props.items,
			manualSortBy: true
		},
		useSortBy,
		usePagination,
		useFlexLayout
	)
	useLayoutEffect(() => {
		ReactTooltip.rebuild()
	}, [i18n.language])

	useEffect(() => {
		if (sortBy[0]) {
			const direction = sortBy[0].desc ? 'desc' : 'asc'
			props.handleSort(sortBy[0].id, direction)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sortBy])

	return (
		<div className={style.root}>
			<div className={style.tableWrapper}>
				<div {...getTableProps()} className={style.table}>
					<div>
						{headerGroups.map(headerGroup => (
							<div {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map(column => {
									return (
										<>
											<ReactTooltip />
											<div
												data-tip={column.headerToolTip}
												className={clsx(style.title, props.classNameHeader)}
												{...column.getHeaderProps(column.getSortByToggleProps())}
											>
												{column.render('Header')}

												{column.isSorted && (
													<>
														<Icon
															className={style.btnOrderAsc}
															name="swap"
															color={column.isSortedDesc ? '#008B9C' : '#285F74'}
															size={{ w: 6, h: 10 }}
														/>
														<Icon
															name="swap"
															color={column.isSortedDesc ? '#285F74' : '#008B9C'}
															size={{ w: 6, h: 10 }}
														/>
													</>
												)}
											</div>
										</>
									)
								})}
							</div>
						))}
					</div>
					<div {...getTableBodyProps()}>
						{rows.map(row => {
							prepareRow(row)
							return (
								<tr
									{...row.getRowProps()}
									className={clsx(style.row, props.getClassByRow && props.getClassByRow(row))}
								>
									{row.cells.map(cell => (
										<td
											data-tip={(cell.column.cellToolTip && cell.column.cellToolTip(cell)) || ''}
											{...cell.getCellProps()}
											className={clsx(style.cell, props.classNameRow)}
										>
											{cell.render('Cell')}
										</td>
									))}
								</tr>
							)
						})}
					</div>
				</div>
			</div>

			{props.countAll > props.limit && (
				<Pagination
					limit={props.limit}
					page={props.page}
					label={props.paginationLabel}
					countAll={props.countAll}
					onChange={props.onChange}
				/>
			)}
		</div>
	)
}

Table.propTypes = {
	items: PropTypes.array.isRequired,
	columns: PropTypes.array.isRequired,
	limit: PropTypes.number,
	countAll: PropTypes.number,
	onChange: PropTypes.func,
	handleSort: PropTypes.func,
	classNameHeader: PropTypes.string,
	classNameRow: PropTypes.string
}
Table.defaultProps = {
	handleSort: () => {}
}
