import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Icon from '../Icon'

import styles from './styles.module.scss'

export default function Alert(props) {
	const { t } = useTranslation()
	return (
		<div className={styles.root} style={props.style}>
			<Icon name="danger" size={{ w: 24, h: 24 }} color="#fff" className={styles.icon} />
			<div className={styles.content}>
				<h1 className={styles.title}>{t('Important')}</h1>
				<span className={styles.message}>{props.children}</span>
			</div>
		</div>
	)
}
Alert.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	style: PropTypes.object
}
