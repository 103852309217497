import { useEffect } from 'react'
import http from '../../../../shared/http'
import { TApi } from '../../../../shared/const'
import { connect } from 'react-redux'
import { logIn } from '../../../../redux/ducks/user'

import MiniHeader from '../../../../UI/MiniHeader'
import Contact from './components/Contact'
import Security from './components/Security'
import BasicInfo from './components/BasicInfo'
import JuniorInfo from './components/JuniorInfo'

import { useTranslation } from 'react-i18next'

import styles from './style.module.scss'

function Profile(props) {
	const { t } = useTranslation()

	useEffect(() => {
		http.get(TApi.COMMON_ME, {
			disableToast: true
		}).then(({ data }) => {
			props.logIn({
				role: data.role,
				profile: data
			})
		})
		/* eslint-disable-next-line */
	}, [])

	return (
		<div className={styles.wrapper}>
			<MiniHeader title={t('My Profile')} bg="#f8faff" />
			<BasicInfo />
			<JuniorInfo />
			<Contact />
			<Security />
		</div>
	)
}
const mapDispatchToProps = dispatch => ({
	logIn: data => dispatch(logIn(data))
})

export default connect(null, mapDispatchToProps)(Profile)
