import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Btn from '../Btn'

import SectionBlock from './components/SectionBlock'

import {
	CompaniesOptions,
	DOCUMENT_TYPES,
	DocumentOptions,
	StateOptions,
	TLanguages,
	TRoles,
	TRoutes
} from '../../shared/const'

import styles from './style.module.scss'
import SelectedOptions from './components/SelectedOptions'
import SchoolsSection from './components/SchoolsSection'
import TeachersSection from './components/TeachersSection'
import clsx from 'clsx'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

function Filter(props) {
	const { t, i18n } = useTranslation()
	const [filterStates, setFilterStates] = useState(props.filters?.state || [])
	const [filterCompanyTypes, setFilterCompanyTypes] = useState(props.filters?.companyType || [])
	const [filterBranches, setFilterBranches] = useState(props.filters?.branch || [])
	const [filterSchools, setFilterSchools] = useState(props.filters?.school || [])
	const [filterTeachers, setFilterTeachers] = useState(props.filters?.teacher || [])
	const [filterCompanyStatus, setFilterCompanyStatus] = useState(props.filters?.companyStatus || [])
	const [filterParental, setFilterParental] = useState(props.filters?.reviewedParentalConfirmation || [])
	const [filterCEO, setFilterCEO] = useState(props.filters.isCEO || [])
	const [filterDocumentType, setFilterDocumentType] = useState(props.filters?.documentType || [])
	const [filterIsNeedReview, setFilterIsNeedReview] = useState([])
	const [filterSchoolType, setFilterSchoolType] = useState(props.filters?.schoolType || [])
	const [filterSchoolStatus, setFilterSchoolStatus] = useState(props.filters?.schoolStatus || [])
	const [filterRole, setFilterRole] = useState(props.filters?.role || [])
	const [filterVerifiedTeacher, setFilterVerifiedTeacher] = useState(props.filters?.teacherStatus || [])
	const [filterEmailConfirmed, setFilterEmailConfirmed] = useState(props.filters?.emailStatus || [])
	const [filterVerifiedExpert, setFilterVerifiedExpert] = useState(props.filters?.status || [])
	const [filterInvoiceStatus, setFilterInvoiceStatus] = useState(props.filters?.invoiceStatus || [])
	const [filterNewsStatus, setFilterNewsStatus] = useState(props.filters?.newsStatus || [])

	const location = useLocation()

	const statusOptions = [
		{
			value: 'ACTIVATED',
			label: t('Activated')
		},
		{
			value: 'NOT_ACTIVATED',
			label: t('Registered')
		},
		{
			value: 'IS_NEED_REVIEW',
			label: t('To be checked')
		}
	]

	const verifiedTeacherOptions = [
		{
			value: true,
			label: t('Verified')
		},
		{
			value: false,
			label: t('Not Verified')
		}
	]

	const parentalOptions = [
		{
			value: 'SUBMITTED',
			label: t('Submitted')
		},
		{
			value: 'OPEN',
			label: t('Open')
		},
		{
			value: 'CHECKED',
			label: t('Checked')
		},
		{
			value: 'REJECTED',
			label: t('Rejected')
		}
	]

	const ceoOptions = [
		{
			value: true,
			label: t('CEO')
		},
		{
			value: false,
			label: t('Not CEO')
		}
	]

	const schoolStatus = [
		{
			value: true,
			label: t('Verified')
		},
		{
			value: false,
			label: t('Not Verified')
		}
	]

	const emailConfirmedOptions = [
		{
			value: true,
			label: t('Accepted')
		},
		{
			value: false,
			label: t('Pending')
		}
	]

	const verifiedExpertOptions = [
		{
			value: true,
			label: t('Verified')
		},
		{
			value: false,
			label: t('Not Verified')
		}
	]

	const invoiceStatusOptions = [
		{
			value: 'Paid',
			label: t('Paid')
		},
		{
			value: 'Unpaid',
			label: t('Unpaid')
		},
		{
			value: 'Overdue',
			label: t('Overdue')
		},
		{
			value: 'Credit',
			label: t('Credit')
		}
	]

	const newsStatusOptions = [
		{
			value: 'Active',
			label: t('Active')
		},
		{
			value: 'Expired',
			label: t('Expired')
		},
		{
			value: 'Planned',
			label: t('Planned')
		}
	]

	const handleClearAll = () => {
		setFilterStates([])
		setFilterCompanyTypes([])
		setFilterBranches([])
		setFilterSchools([])
		setFilterTeachers([])
		setFilterCompanyStatus([])
		setFilterParental([])
		setFilterCEO([])
		setFilterDocumentType([])
		setFilterIsNeedReview([])
		setFilterSchoolType([])
		setFilterSchoolStatus([])
		setFilterRole([])
		setFilterVerifiedTeacher([])
		setFilterEmailConfirmed([])
		setFilterVerifiedExpert([])
		setFilterInvoiceStatus([])
		setFilterNewsStatus([])
	}

	const countFilters = () => {
		const count =
			filterStates.length +
			filterCompanyTypes.length +
			filterBranches.length +
			filterSchools.length +
			filterTeachers.length +
			filterCompanyStatus.length +
			filterParental.length +
			filterCEO.length +
			filterDocumentType.length +
			filterIsNeedReview.length +
			filterSchoolType.length +
			filterSchoolStatus.length +
			filterRole.length +
			filterVerifiedTeacher.length +
			filterEmailConfirmed.length +
			filterVerifiedExpert.length +
			filterInvoiceStatus.length +
			filterNewsStatus.length

		props.onCountFilters(count)

		return count
	}

	useEffect(() => {
		props.onFilterOptions({
			state: filterStates,
			branch: filterBranches,
			companyType: filterCompanyTypes,
			school: filterSchools,
			teacher: filterTeachers,
			companyStatus: filterCompanyStatus,
			isNeedReview: filterIsNeedReview,
			reviewedParentalConfirmation: filterParental,
			isCEO: filterCEO,
			type: filterDocumentType,
			schoolType: filterSchoolType,
			schoolStatus: filterSchoolStatus,
			role: filterRole,
			verifiedTeacher: filterVerifiedTeacher,
			emailConfirmed: filterEmailConfirmed,
			expertConfirmed: filterVerifiedExpert,
			invoiceStatus: filterInvoiceStatus,
			newsStatus: filterNewsStatus
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		filterStates,
		filterCompanyTypes,
		filterBranches,
		filterSchools,
		filterTeachers,
		filterCompanyStatus,
		filterParental,
		filterCEO,
		filterDocumentType,
		filterIsNeedReview,
		filterSchoolType,
		filterSchoolStatus,
		filterRole,
		filterVerifiedTeacher,
		filterEmailConfirmed,
		filterVerifiedExpert,
		filterInvoiceStatus,
		filterNewsStatus
	])

	return (
		<div className={clsx(styles.root, props.showFilter && styles.open)}>
			<div className={styles.header}>
				<Btn
					className={styles.closeBtn}
					icon="close"
					iconColor="#fff"
					iconSize={{ w: 16, h: 16 }}
					iconClassName={styles.closeBtnIcon}
					onClick={() => props.setShowFilter(false)}
				/>
				<h1 className={styles.title}>Filter ({countFilters()})</h1>
				<button type="button" className={styles.btnClear} onClick={handleClearAll}>
					{t('Clear all')}
				</button>
			</div>
			<div className={styles.main}>
				<div className={styles.optionBlocks}>
					<SelectedOptions values={filterStates} onChange={setFilterStates} />
					<SelectedOptions values={filterCompanyTypes} onChange={setFilterCompanyTypes} />
					<SelectedOptions values={filterBranches} onChange={setFilterBranches} />
					<SelectedOptions values={filterSchools} onChange={setFilterSchools} />
					<SelectedOptions values={filterTeachers} onChange={setFilterTeachers} />
					<SelectedOptions values={filterCompanyStatus} onChange={setFilterCompanyStatus} />
					<SelectedOptions values={filterParental} onChange={setFilterParental} />
					<SelectedOptions values={filterCEO} onChange={setFilterCEO} />
					<SelectedOptions values={filterDocumentType} onChange={setFilterDocumentType} />
					<SelectedOptions values={filterIsNeedReview} onChange={setFilterIsNeedReview} />
					<SelectedOptions values={filterSchoolType} onChange={setFilterSchoolType} />
					<SelectedOptions values={filterSchoolStatus} onChange={setFilterSchoolStatus} />
					<SelectedOptions values={filterRole} onChange={setFilterRole} />
					<SelectedOptions values={filterVerifiedTeacher} onChange={setFilterVerifiedTeacher} />
					<SelectedOptions values={filterEmailConfirmed} onChange={setFilterEmailConfirmed} />
					<SelectedOptions values={filterVerifiedExpert} onChange={setFilterVerifiedExpert} />
					<SelectedOptions values={filterInvoiceStatus} onChange={setFilterInvoiceStatus} />
					<SelectedOptions values={filterNewsStatus} onChange={setFilterNewsStatus} />
				</div>

				{props.toShow.states && (props.role !== TRoles.ADMIN || location.pathname === TRoutes.MAIN) && (
					<SectionBlock
						values={filterStates}
						onChange={setFilterStates}
						name={t('state')}
						options={StateOptions.map(v => ({
							label: t(v.label),
							value: v.value
						})).sort((a, b) => a.label.localeCompare(b.label))}
					/>
				)}

				{props.toShow.companyTypes && (
					<SectionBlock
						values={filterCompanyTypes}
						onChange={setFilterCompanyTypes}
						name={t('Company type')}
						options={CompaniesOptions}
					/>
				)}
				{props.toShow.role && (
					<SectionBlock
						values={filterRole}
						onChange={setFilterRole}
						name={t('Role')}
						options={[
							{
								value: TRoles.STUDENT,
								label: t('Student')
							},
							{
								value: TRoles.TEACHER,
								label: t('Teacher')
							}
						]}
					/>
				)}
				{props.toShow.userRole && (
					<SectionBlock
						values={filterRole}
						onChange={setFilterRole}
						name={t('User roles')}
						options={[
							{
								value: TRoles.STUDENT,
								label: t('Student')
							},
							{
								value: TRoles.TEACHER,
								label: t('Teacher')
							}
						]}
					/>
				)}
				{props.toShow.branches && (
					<SectionBlock
						values={filterBranches}
						onChange={setFilterBranches}
						name={t('branch')}
						options={props.branch?.map(v => ({
							value: v.value_EN,
							label: i18n.language === TLanguages.EN ? v.value_EN : v.value_DE
						}))}
					/>
				)}
				{props.toShow.schools && (
					<SchoolsSection values={filterSchools} isPublic={props.isPublic} onChange={setFilterSchools} />
				)}
				{props.toShow.teachers && <TeachersSection values={filterTeachers} onChange={setFilterTeachers} />}
				{props.toShow.isActive && (
					<SectionBlock
						values={filterCompanyStatus}
						onChange={setFilterCompanyStatus}
						name={t('Company status')}
						options={statusOptions}
					/>
				)}
				{props.toShow.reviewedParentalConfirmation && (
					<SectionBlock
						values={filterParental}
						onChange={setFilterParental}
						name={t('Parental Confirmation')}
						options={parentalOptions}
					/>
				)}
				{props.toShow.isCEO && (
					<SectionBlock values={filterCEO} onChange={setFilterCEO} name="CEO" options={ceoOptions} />
				)}
				{props.toShow.documentType && (
					<SectionBlock
						values={filterDocumentType}
						onChange={setFilterDocumentType}
						name={t('Document Type')}
						options={DocumentOptions.map(v => ({
							label: t(v.label),
							value: v.value
						})).sort((a, b) => a.label.localeCompare(b.label))}
					/>
				)}
				{props.toShow.adminDocumentType && (
					<SectionBlock
						values={filterDocumentType}
						onChange={setFilterDocumentType}
						name={t('Document Type')}
						options={DocumentOptions.filter(e => e.value !== DOCUMENT_TYPES.INVOICE)
							.map(v => ({ label: t(v.label), value: v.value }))
							.sort((a, b) => a.label.localeCompare(b.label))}
					/>
				)}
				{props.toShow.schoolType && (
					<SectionBlock
						values={filterSchoolType}
						onChange={setFilterSchoolType}
						name={t('School Types')}
						options={props.schoolTypes?.map(v => ({
							value: v.value,
							label: v.value
						}))}
					/>
				)}
				{props.toShow.schoolStatus && (
					<SectionBlock
						values={filterSchoolStatus}
						onChange={setFilterSchoolStatus}
						name={'Status'}
						options={schoolStatus}
					/>
				)}
				{props.toShow.verifiedTeacher && (
					<SectionBlock
						values={filterVerifiedTeacher}
						onChange={setFilterVerifiedTeacher}
						name={t('Teacher Status')}
						options={verifiedTeacherOptions}
					/>
				)}
				{props.toShow.isExpertEmailConfirmed && (
					<SectionBlock
						values={filterEmailConfirmed}
						onChange={setFilterEmailConfirmed}
						name={t('Email Status')}
						options={emailConfirmedOptions}
					/>
				)}
				{props.toShow.verifiedExpert && (
					<SectionBlock
						values={filterVerifiedExpert}
						onChange={setFilterVerifiedExpert}
						name={t('Status')}
						options={verifiedExpertOptions}
					/>
				)}
				{props.toShow.invoiceStatus && (
					<SectionBlock
						values={filterInvoiceStatus}
						onChange={setFilterInvoiceStatus}
						name={t('Status')}
						options={invoiceStatusOptions}
					/>
				)}
				{props.toShow.newsStatus && (
					<SectionBlock
						values={filterNewsStatus}
						onChange={setFilterNewsStatus}
						name={t('Status')}
						options={newsStatusOptions}
					/>
				)}

				<Btn type="submit" onClick={() => props.onSearch()} className={styles.btn}>
					Filter
				</Btn>
			</div>
		</div>
	)
}

Filter.propTypes = {
	toShow: PropTypes.object.isRequired,
	onSearch: PropTypes.func.isRequired,
	showFilter: PropTypes.bool.isRequired,
	setShowFilter: PropTypes.func.isRequired,
	onCountFilters: PropTypes.func.isRequired,
	onFilterOptions: PropTypes.func.isRequired,
	isPublic: PropTypes.bool,

	branch: PropTypes.array.isRequired,
	schoolTypes: PropTypes.array.isRequired,

	filters: PropTypes.object
}
const mapStateToProps = state => ({
	branch: state.options.branch,
	schoolTypes: state.options.schoolTypes,
	role: state.user.role
})

export default connect(mapStateToProps)(Filter)
