import { useEffect } from 'react'
import http from '../../../../shared/http'
import { TApi } from '../../../../shared/const'
import { connect } from 'react-redux'
import { logIn } from '../../../../redux/ducks/user'

import MiniHeader from '../../../../UI/MiniHeader'
import Contact from './components/Contact'
import Security from './components/Security'
import BasicInfo from './components/BasicInfo'
import WorkArea from './components/WorkArea'
import JuniorInfo from './components/JuniorInfo'
import AvatarPhoto from './components/AvatarPhoto'
import ParentalConf from './components/ParentalConfirmation'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import styles from './style.module.scss'

function Profile(props) {
	const { t } = useTranslation()

	useEffect(() => {
		http.get(TApi.COMMON_ME, {
			disableToast: true
		}).then(({ data }) => {
			props.logIn({
				role: data.role,
				profile: data
			})
		})
		/* eslint-disable-next-line */
	}, [])

	return (
		<div className={styles.wrapper}>
			<MiniHeader title={t('My Profile')} bg="#f8faff" />
			<AvatarPhoto isTeacherStudentView={props.isTeacherStudentView} />
			<BasicInfo isTeacherStudentView={props.isTeacherStudentView} />
			<JuniorInfo isTeacherStudentView={props.isTeacherStudentView} />
			<Contact isTeacherStudentView={props.isTeacherStudentView} />
			<ParentalConf isTeacherStudentView={props.isTeacherStudentView} />
			<WorkArea isTeacherStudentView={props.isTeacherStudentView} />
			<Security isTeacherStudentView={props.isTeacherStudentView} />
		</div>
	)
}

Profile.propTypes = {
	isTeacherStudentView: PropTypes.bool.isRequired
}

const mapDispatchToProps = dispatch => ({
	logIn: data => dispatch(logIn(data))
})

const mapStateToProps = state => ({
	isTeacherStudentView: state.user.isTeacherStudentView
})

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
