import PropTypes from 'prop-types'
import ReactPaginate from 'react-paginate'
import { useTranslation } from 'react-i18next'

import Icon from '../Icon'

import styles from './style.module.scss'
import { customNumberFormatW } from '../../shared/helpers'

export default function Pagination(props) {
	const { t } = useTranslation()
	const pageCount = Math.ceil(props.countAll / props.limit)

	return (
		<div className={styles.root}>
			<span className={styles.infoPage}>
				{t('Result')}: {customNumberFormatW(props.countAll)} {props.label} ({t('page')}{' '}
				{pageCount === 0 ? 0 : customNumberFormatW(props.page + 1)} {t('from')} {customNumberFormatW(pageCount)}
				)
			</span>
			<ReactPaginate
				forcePage={props.page}
				marginPagesDisplayed={1}
				pageRangeDisplayed={2}
				onPageChange={({ selected }) => props.onChange(selected)}
				pageCount={pageCount}
				containerClassName={styles.container}
				pageLinkClassName={styles.pageLink}
				pageClassName={styles.page}
				previousClassName={styles.previous}
				previousLinkClassName={styles.previousLink}
				nextLinkClassName={styles.nextLink}
				breakClassName={styles.breakLabel}
				activeClassName={styles.active}
				previousLabel={<Icon name="arrow" color="#008B9C" size={{ w: 10, h: 9 }} />}
				nextLabel={<Icon name="arrow" color="#008B9C" size={{ w: 10, h: 9 }} />}
				breakLabel={'...'}
			/>
		</div>
	)
}

Pagination.propTypes = {
	countAll: PropTypes.number.isRequired,
	limit: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired
}
