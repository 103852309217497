import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { useForm, useWatch } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import Btn from '../../../../UI/Btn'
import Icon from '../../../../UI/Icon'
import Chip from '../../../../UI/Chip'
import SelectField from '../../../../UI/SelectField'
import BaseModal from '../../../../UI/BaseModal/BaseModal'
import VisibilityAccess from '../../../VisibilityAccess'

import http from '../../../../shared/http'
import eventEmitter, { types } from '../../../../shared/eventEmitter'
import { fullLogOut } from '../../../../shared/helpers'
import { TApi, TRoles, TRoutes } from '../../../../shared/const'

import { updateProfile } from '../../../../redux/ducks/user'

import styles from './style.module.scss'

function CheckConfirmAccountModal(props) {
	const { t } = useTranslation()
	const { pathname } = useLocation()
	const {
		handleSubmit,
		control,
		setValue,
		reset,
		formState: { errors }
	} = useForm({})
	const schoolField = useWatch({ control, name: 'school' })
	const teacherField = useWatch({ control, name: 'teacher' })

	const setData = (field = '', data) => {
		setValue(field, data)
	}

	const [uniqKeyTeacher, setUniqKeyTeacher] = useState(0)
	const [company, setCompany] = useState({
		supervision: {}
	})

	const loadSchoolOptions = async (search, loadedOptions) => {
		const isStudent = props.role === TRoles.STUDENT

		const {
			data: { rows, count }
		} = await http.get(isStudent ? TApi.STUDENT_GET_SCHOOLS : TApi.TEACHER_GET_SCHOOLS, {
			params: {
				search,
				limit: 30,
				offset: loadedOptions.length
			}
		})

		return {
			options: rows.map(row => ({ ...row, value: row.id, label: row.name })),
			hasMore: loadedOptions.length < count
		}
	}

	const loadTeacherOptions = async (search, loadedOptions) => {
		const {
			data: { rows, count }
		} = await http.get(TApi.STUDENT_GET_TEACHERS, {
			params: {
				search,
				limit: 30,
				offset: loadedOptions.length,
				schoolId: schoolField.id
			}
		})

		return {
			options: rows.map(row => ({ ...row, value: row.id, label: row.name })),
			hasMore: loadedOptions.length < count
		}
	}

	const onSubmit = form => {
		const isStudent = props.role === TRoles.STUDENT

		const data = isStudent
			? {
					id: form.teacher.id
			  }
			: {
					schoolId: form.school.id
			  }

		http.put(isStudent ? TApi.STUDENT_PUT_COMPANY_SUPERVISION : TApi.TEACHER_PUT_PROFILE, data).then(({ data }) => {
			//props.updateProfile(data)
			if (isStudent)
				setCompany({
					...company,
					supervision: form
				})
			else props.updateProfile(data.user)
			toast.success(t('Profile has been updated successfully.'))
			reset()
		})
	}

	const handleExit = () => {
		reset()
		fullLogOut()
	}

	useEffect(() => {
		setValue('teacher', null)
		setUniqKeyTeacher(uniqKeyTeacher + 1)

		/* eslint-disable-next-line */
	}, [schoolField])

	useEffect(() => {
		if (
			TRoles.STUDENT === props.role &&
			props.isAuth &&
			pathname !== TRoutes.AUTH_CONFIRM &&
			pathname !== TRoutes.MAIN &&
			props.emailConfirmed
		) {
			http.get(TApi.STUDENT_GET_COMPANY_SUPERVISION).then(({ data }) => {
				if (data) return setCompany(data)
			})
		}

		/* eslint-disable-next-line */
	}, [props.role, props.isAuth, props.isSignInMode, pathname, props.emailConfirmed])

	if (!props.emailConfirmed) return null
	if (pathname === TRoutes.AUTH_CONFIRM) return null
	if (!props.isAuth) return null
	if (props.isSignInMode) return null
	if (props.isAuth && props.emailConfirmed && [TRoles.ADMIN, TRoles.FEDERAL_ADMIN].includes(props.role)) return null
	if (props.isAuth && TRoles.STUDENT === props.role && company.supervision /*&& props.teacherId*/) return null
	if (props.isAuth && TRoles.TEACHER === props.role && props.schoolId /*&& props.teacherId*/) return null

	return (
		<BaseModal
			defaultOpen
			nameEvent={types.openCheckConfirmAccount}
			hideClose
			title={t('Checklist confirm account')}
			maxWidth={600}
		>
			{() => (
				<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
					<div className={styles.step}>
						<h3 className={styles.stepTitleWrapper}>
							<Icon name={props.emailConfirmed ? 'checkbox' : 'checkForBox'} />
							<span className={styles.stepTitle}>{t('Confirm Email')}</span>
							<hr className={styles.stepTitleHr} />
						</h3>
					</div>
					<VisibilityAccess access={[TRoles.STUDENT, TRoles.TEACHER]}>
						<div className={styles.step}>
							<h3 className={styles.stepTitleWrapper}>
								<Icon name={schoolField ? 'checkbox' : 'checkForBox'} />
								<span className={styles.stepTitle}>{t('Select or add your school')}</span>
								<hr className={styles.stepTitleHr} />
							</h3>
							<div className={styles.flexContainer}>
								<SelectField
									async
									disabled={!props.emailConfirmed}
									label={t('School')}
									isSearchable
									control={control}
									error={errors.titlePrefixed}
									name="school"
									className={styles.selectField}
									placeholder={t('School')}
									debounceTimeout={400}
									loadOptions={loadSchoolOptions}
									formatOptionLabel={({ label, ...row }) => (
										<div className={styles.selectFieldOption}>
											{row.verified && <Chip miniRadius>Verified</Chip>}
											<span style={{ marginLeft: 10 }}>{row.name}</span>
										</div>
									)}
								/>
								<Btn
									theme="white"
									disabled={!props.emailConfirmed}
									onClick={() => eventEmitter.emit(types.openAddSchool, setData)}
									iconColor="#307062"
									icon="plus"
									className={styles.btnAdd}
								/>
							</div>
						</div>
					</VisibilityAccess>
					<VisibilityAccess access={[TRoles.STUDENT]}>
						<div className={styles.step}>
							<h3 className={styles.stepTitleWrapper}>
								<Icon name={teacherField ? 'checkbox' : 'checkForBox'} />
								<span className={styles.stepTitle}>{t('Select or add your teacher')}</span>
								<hr className={styles.stepTitleHr} />
							</h3>
							<div className={styles.flexContainer}>
								<SelectField
									async
									disabled={!props.emailConfirmed || !schoolField}
									label={t('Teacher')}
									isSearchable
									control={control}
									error={errors.titlePrefixed}
									name="teacher"
									className={styles.selectField}
									placeholder={t('Teacher')}
									debounceTimeout={400}
									loadOptions={loadTeacherOptions}
									cacheUniqs={[uniqKeyTeacher]}
									formatOptionLabel={({ label, ...row }) => (
										<div className={styles.selectFieldOption}>
											{row.verifiedTeacher && <Chip miniRadius>{t('Verified')}</Chip>}
											<span style={{ marginLeft: 10 }}>
												{row.titlePrefixed} {row.firstName} {row.lastName} {row.titleSuffixed}
											</span>
										</div>
									)}
								/>
								<Btn
									theme="white"
									disabled={!props.emailConfirmed || !schoolField}
									onClick={() => eventEmitter.emit(types.openAddTeacher, schoolField, setData)}
									iconColor="#307062"
									icon="plus"
									className={styles.btnAdd}
								/>
							</div>
						</div>
					</VisibilityAccess>
					<div className={styles.btns}>
						<Btn
							onClick={handleExit}
							className={styles.btnLogout}
							iconClassName={styles.btnLogoutIcon}
							width={215}
							theme="white"
							iconSize={{ w: 22, h: 22 }}
							iconColor="#008B9C"
							noFillIcon
							icon="logout"
						>
							{t('Cancel')}
						</Btn>

						<Btn
							className={styles.btnSubmit}
							disabled={
								TRoles.TEACHER === props.role ? !schoolField : !props.emailConfirmed || !teacherField
							}
							width={215}
							type="submit"
							iconSize={{ w: 10, h: 10 }}
							iconColor="#fff"
							icon="arrow"
						>
							{t('OK')}
						</Btn>
					</div>
				</form>
			)}
		</BaseModal>
	)
}

CheckConfirmAccountModal.propTypes = {
	emailConfirmed: PropTypes.bool.isRequired,
	isAuth: PropTypes.bool.isRequired,
	isSignInMode: PropTypes.bool.isRequired,
	role: PropTypes.string.isRequired,
	schoolId: PropTypes.number,
	teacherId: PropTypes.number,

	updateProfile: PropTypes.func.isRequired
}

const mapStateToProps = state => {
	return {
		emailConfirmed: state.user.profile.emailConfirmed,
		schoolId: state.user.profile.schoolId,
		teacherId: state.user.profile.teacherId,

		isAuth: state.user.isAuth,
		isSignInMode: state.user.isSignInMode,
		role: state.user.role
	}
}

const mapDispatchToProps = dispatch => ({
	updateProfile: data => dispatch(updateProfile(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(CheckConfirmAccountModal)
