import Joi from 'joi'
import { v4 as uuidv4 } from 'uuid'
import prettyBytes from 'pretty-bytes'
import * as queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { isValidPhoneNumber } from 'libphonenumber-js'
import i18n from './i18n'

import http from './http'
import store from '../redux'
import { logIn, logOut } from '../redux/ducks/user'

import { TCompanies } from './const'

export const megabytesToBytes = megabytes => {
	return megabytes * 1048576 // 1 Mb = 1048576 bytes
}

export const joiPhone = Joi.string()
	.custom((value, helper) => {
		if (isValidPhoneNumber(value)) return value

		return helper.error('phoneNumber.invalid')
	})
	.required()

export const joiFile = maxMegabytes =>
	Joi.custom((value, helper) => {
		if (!value[0]) return value[0]
		console.log(megabytesToBytes(maxMegabytes), value[0].size)
		if (megabytesToBytes(maxMegabytes) < value[0].size)
			return helper.message(
				`${i18n.t('The file is too large. Current file size')}: ${prettyBytes(value[0].size)}. ${i18n.t(
					'Max file size is'
				)} ${maxMegabytes}M.`
			)
		return value
	})

export const fullLogOut = () => {
	localStorage.removeItem('auth')
	store.dispatch(logOut())
	delete http.defaults.headers['Authorization']
}

export const fullLogIn = (token, user) => {
	http.defaults.headers['Authorization'] = `Bearer ${token}`
	store.dispatch(
		logIn({
			role: user.role,
			profile: user
		})
	)
	localStorage.setItem(
		'auth',
		JSON.stringify({
			role: user.role,
			token: token
		})
	)
}

export const convertValuesToOptions = (values, options) => {
	const valuesLabels = options.reduce((acc, curr) => {
		acc[curr.value] = curr.label
		return acc
	}, {})

	return values.map(value => ({
		value,
		label: valuesLabels[value]
	}))
}
export const getFormattedDate = value => {
	const date = new Date(value)

	return date.toLocaleDateString('en-GB', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit'
	})
}

export const useInvite = () => {
	const { search } = useLocation()
	const { invite, role, email } = queryString.parse(search)

	return {
		invite,
		role,
		email,
		isToken: !!invite
	}
}

export const mapCompanyTypeToName = type => {
	const names = {
		[TCompanies.JCC]: 'Junior Compact Company',
		[TCompanies.JC]: 'Junior Company',
		[TCompanies.JBC]: 'Junior Basic Company'
	}

	return names[type]
}

export const getRandomUUID = () => uuidv4()
export const downloadFile = (name, data) => {
	const url = window.URL.createObjectURL(new Blob([data], { type: data.type }))
	const link = document.createElement('a')
	link.href = url
	link.setAttribute('download', name)
	document.body.appendChild(link)
	link.click()
	link.parentNode.removeChild(link)
}

export const customNumberFormat = number => {
	if (!number) return 0
	return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(number)
}

export const blobTypes = {
	XLS: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
	PDF: 'application/pdf'
}

export const customNumberFormatW = number => {
	if (!number) return 0
	return new Intl.NumberFormat('de-DE').format(number)
}

export const reportData = (type, data) => {
	switch (type) {
		case TCompanies.JC:
			return {
				revenues: data.advanceVatReturnTotalIncome,
				expenses: data.advanceVatReturnTotalExpenses,
				result: data.semiAnnualReportResultTotal,
				charges: data.semiAnnualReportTotalDuties
			}
		case TCompanies.JCC:
			return {
				revenues: data.totalGrossRevenue,
				expenses: data.totalExpenses,
				result: data.profitLossReducedCorporateIncomeTax,
				charges: data.taxesTransferredToJuniorAustria
			}
		case TCompanies.JBC:
			return {
				revenues: data.totalGrossRevenue,
				expenses: data.totalExpenses,
				result: data.netProfitLoss,
				charges: data.insurance.JBC
			}
		default:
			return {}
	}
}

export const generateFilter = input => {
	const filter = {}

	for (const [key, values] of Object.entries(input)) {
		filter[key] = values.map(item => item.value)
	}

	return filter
}
