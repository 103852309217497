import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

import CompanyProfile from '../../components/views/student/CompanyProfile'

import injectRole from '../../components/HOC/injectRole'

import { TRoles, TRoutes } from '../../shared/const'

function CompanyProfilePage(props) {
	if (props.role === TRoles.STUDENT) return <CompanyProfile />
	else return <Redirect to={TRoutes.DASHBOARD} />
}

CompanyProfilePage.propTypes = {
	role: PropTypes.string.isRequired
}

export default injectRole(CompanyProfilePage)
