import Select from 'react-select'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from '../../components/views/admin/Companies/style.module.scss'

const customStyles = {
	control: (provided, state) => ({
		...provided,
		backgroundColor: '#f8faff',
		borderRadius: '15px',
		boxShadow: 'inset 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 5px 20px rgba(0, 0, 0, 0.03)',
		color: '#303e65',
		border: state.isFocused ? `1px solid #008292` : `1px solid #e5eaf5;`,
		'&:hover': {
			borderColor: '#008292'
		}
	}),
	valueContainer: provided => ({
		...provided,
		justifyContent: 'center'
	}),
	menu: provided => ({
		...provided,
		textAlign: 'center',
		borderRadius: '15px'
	}),
	option: (provided, state) => ({
		...provided,
		backgroundColor: state.isSelected ? '#008292' : state.isFocused ? '#d6ebff' : provided.backgroundColor,
		borderRadius: '15px',
		color: state.isSelected ? 'white' : provided.color,
		'&:hover': {
			backgroundColor: state.isSelected ? '#008b9c' : '#e5eaf5'
		}
	})
}
export default function LimitFilterButton(props) {
	const { t } = useTranslation()
	const { limit, onChange, options } = props
	return (
		<div className={styles.limitContainer}>
			<div className={styles.limitTitle}>{t('Show')}:</div>
			<Select
				styles={customStyles}
				className={styles.selectLimit}
				defaultValue={limit}
				value={{ value: limit, label: String(limit) }}
				onChange={onChange}
				options={options}
			/>
		</div>
	)
}

LimitFilterButton.propTypes = {
	limit: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired,
	options: PropTypes.array
}
