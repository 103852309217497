import { useState, useEffect } from 'react'
import { TWidgetTypes, TWidgetDomains, TApi } from '../../../../shared/const'
import { getRandomUUID } from '../../../../shared/helpers'
import { useTranslation } from 'react-i18next'

import ThreeSectionsWidget from '../../../../UI/Widget/threeSectionsWidget'
import FourSectionsWidget from '../../../../UI/Widget/fourSectionsWidget'
import LinkWidget from '../../../../UI/Widget/linkWidget'
import styles from './style.module.scss'

import { connect } from 'react-redux'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import Btn from '../../../../UI/Btn'
import http from '../../../../shared/http'
import clsx from 'clsx'
import { toast } from 'react-toastify'
import { updateProfile } from '../../../../redux/ducks/user'
import Icon from '../../../../UI/Icon'
import FourSectionsDataWidget from '../../../../UI/Widget/fourSectionsDataWidget'

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list)
	const [removed] = result.splice(startIndex, 1)
	result.splice(endIndex, 0, removed)

	return result
}

const getItemStyle = (isDragging, draggableStyle) => ({
	userSelect: 'none',
	margin: '10px',
	boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.05)',
	borderRadius: '20px',
	height: '205px',
	color: '#303E65',

	...draggableStyle
})

const getListStyleSelect = () => ({
	width: '100%',
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center'
})

const getListStyle = () => ({
	width: '100%',
	minHeight: '200px',
	display: 'flex',
	flexWrap: 'wrap'
})

const GeneralInfoWidgets = ({ widgetSettings, isActiveBar, handleWidgetBar, updateUser, state }) => {
	const { t } = useTranslation()
	const [widgets, setWidgets] = useState({
		items: [],
		selected: []
	})

	const id2List = {
		droppable: 'items',
		droppable2: 'selected'
	}

	const move = (source, destination, droppableSource, droppableDestination) => {
		const sourceClone = Array.from(source)
		const destClone = Array.from(destination)
		const [removed] = sourceClone.splice(droppableSource.index, 1)

		destClone.splice(droppableDestination.index, 0, removed)

		const result = {}
		result[droppableSource.droppableId] = sourceClone
		result[droppableDestination.droppableId] = destClone

		return result
	}

	const updateWidgets = () => {
		http.put(TApi.ADMIN_UPDATE_WIDGET_SETTINGS, {
			widgetSettings: widgets.selected
		})
			.then(({ data }) => {
				updateUser({ widgetSettings: data.widgetSettings })
			})
			.finally(() => {
				toast.success(t('Widgets updated'))
				handleWidgetBar()
			})
	}

	function onDragEnd(result) {
		const { source, destination } = result

		if (!destination) {
			return
		}

		const getList = id => widgets[id2List[id]]

		if (source.droppableId === destination.droppableId) {
			const items = reorder(getList(source.droppableId), source.index, destination.index)

			if (source.droppableId === 'droppable2') {
				setWidgets({ ...widgets, selected: items })
			} else {
				setWidgets({ ...widgets, items })
			}
		} else {
			const result = move(getList(source.droppableId), getList(destination.droppableId), source, destination)

			if (destination.droppableId === 'droppable2' && result.droppable2.length > 8) {
				toast.warning(t('Max number of widgets is 8'))
				return
			}

			setWidgets({
				items: result.droppable,
				selected: result.droppable2
			})
		}
	}

	useEffect(() => {
		setWidgets({
			items: DEFAULT_WIDGETS,
			selected: widgetSettings
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [widgetSettings])

	const settings = {}
	if (state) settings.stateFilter = state

	const DEFAULT_WIDGETS = [
		{
			id: getRandomUUID(),
			settings,
			type: TWidgetTypes.FOUR_SECTIONS_DATA,
			domain: TWidgetDomains.REPORT_DATA
		},
		{
			id: getRandomUUID(),
			settings,
			type: TWidgetTypes.FOUR_SECTIONS_DATA,
			domain: TWidgetDomains.COMPANIES
		},
		{
			id: getRandomUUID(),
			settings,
			type: TWidgetTypes.FOUR_SECTIONS_DATA,
			domain: TWidgetDomains.INVOICES
		},
		{
			id: getRandomUUID(),
			settings,
			type: TWidgetTypes.FOUR_SECTIONS_DATA,
			domain: TWidgetDomains.TEACHERS
		},
		{
			id: getRandomUUID(),
			settings,
			type: TWidgetTypes.FOUR_SECTIONS_DATA,
			domain: TWidgetDomains.STUDENTS
		},
		{
			id: getRandomUUID(),
			settings,
			type: TWidgetTypes.THREE_SECTIONS,
			domain: TWidgetDomains.COMPANIES
		},
		{
			id: getRandomUUID(),
			settings,
			type: TWidgetTypes.THREE_SECTIONS,
			domain: TWidgetDomains.STUDENTS
		},
		{
			id: getRandomUUID(),
			settings,
			type: TWidgetTypes.THREE_SECTIONS,
			domain: TWidgetDomains.TEACHERS
		},
		{
			id: getRandomUUID(),
			settings,
			type: TWidgetTypes.THREE_SECTIONS,
			domain: TWidgetDomains.INVOICES
		},
		{
			id: getRandomUUID(),
			settings,
			type: TWidgetTypes.THREE_SECTIONS,
			domain: TWidgetDomains.REPORTS
		},
		{
			id: getRandomUUID(),
			settings,
			type: TWidgetTypes.LINK,
			domain: TWidgetDomains.TURNOVER
		},
		{
			id: getRandomUUID(),
			settings,
			type: TWidgetTypes.LINK,
			domain: TWidgetDomains.COMPANIES_PENDING
		},
		{
			id: getRandomUUID(),
			settings,
			type: TWidgetTypes.LINK,
			domain: TWidgetDomains.OVERDUE_INVOICES
		},
		{
			id: getRandomUUID(),
			settings,
			type: TWidgetTypes.FOUR_SECTIONS,
			domain: 'None'
		}
	]

	const MAP_WIDGET_TYPE_TO_COMPONENT = {
		[TWidgetTypes.LINK]: LinkWidget,
		[TWidgetTypes.THREE_SECTIONS]: ThreeSectionsWidget,
		[TWidgetTypes.FOUR_SECTIONS]: FourSectionsWidget,
		[TWidgetTypes.FOUR_SECTIONS_DATA]: FourSectionsDataWidget
	}

	return (
		<div className={styles.container}>
			<DragDropContext onDragEnd={onDragEnd}>
				<div className={styles.currentTableFieldsCard}>
					<Droppable direction="horizontal" droppableId="droppable2">
						{(provided, snapshot) => (
							<div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
								{widgets.selected.map((item, index) => (
									<Draggable key={item.id} draggableId={item.id} index={index}>
										{(provided, snapshot) => {
											const WidgetComponent = MAP_WIDGET_TYPE_TO_COMPONENT[item.type]
											return (
												<div
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													style={getItemStyle(
														snapshot.isDragging,
														provided.draggableProps.style
													)}
												>
													<WidgetComponent
														id={item.id}
														domain={item.domain}
														availableWidgets={widgets.selected}
														settings={item.settings}
													/>
												</div>
											)
										}}
									</Draggable>
								))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</div>

				<div
					className={
						isActiveBar ? clsx(styles.selectTableFieldsCard, styles.active) : styles.selectTableFieldsCard
					}
				>
					{isActiveBar && (
						<button onClick={() => handleWidgetBar()} className={styles.closeBtn}>
							<Icon name="close" size={{ w: 14, h: 14 }} color="red" />
						</button>
					)}
					<div className={styles.sideHead}>
						{t('Available widgets')}
						<Btn
							className={styles.saveBtn}
							onClick={updateWidgets}
							theme="minimal"
							iconOrder={0}
							icon="filter"
							iconClassName={styles.icon}
							width="auto"
						>
							{t('save')}
						</Btn>
					</div>
					<Droppable droppableId="droppable">
						{(provided, snapshot) => (
							<div ref={provided.innerRef} style={getListStyleSelect(snapshot.isDraggingOver)}>
								{widgets.items.map((item, index) => (
									<Draggable key={item.id} draggableId={item.id} index={index}>
										{(provided, snapshot) => {
											const WidgetComponent = MAP_WIDGET_TYPE_TO_COMPONENT[item.type]
											return (
												<div
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													style={getItemStyle(
														snapshot.isDragging,
														provided.draggableProps.style
													)}
												>
													<WidgetComponent
														id={item.id}
														domain={item.domain}
														availableWidgets={widgets.items}
														settings={item.settings}
													/>
												</div>
											)
										}}
									</Draggable>
								))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</div>
			</DragDropContext>
		</div>
	)
}

const mapStateToProps = store => {
	return {
		widgetSettings: store.user.profile.widgetSettings,
		role: store.user.profile.role,
		state: store.user.profile.state
	}
}

const mapDispatchToProps = dispatch => ({
	updateUser: data => dispatch(updateProfile(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfoWidgets)
