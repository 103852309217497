import React from 'react'
import styles from './style.module.scss'

export default function ReportsCard({ title, value }) {
	return (
		<div className={styles.root}>
			<div className={styles.title}>{title}</div>
			<div className={styles.value}>{value}</div>
		</div>
	)
}
