import Joi from 'joi'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { joiResolver } from '@hookform/resolvers/joi'
import { useForm, useFieldArray } from 'react-hook-form'

import http from '../../../../../shared/http'

import { TApi, TLanguages } from '../../../../../shared/const'
import { schemaOptions } from '../../../../../shared/i18n'

import Btn from '../../../../../UI/Btn'
import BtnAdd from '../../../../../UI/BtnAdd'
import imageInfo from '../../../../../assets/icons/info.png'
import SelectField from '../../../../../UI/SelectField'

import imageWarning from '../../../../../assets/images/warning.png'

import styles from '../style.module.scss'

const schema = Joi.object({
	primaryWorkArea: Joi.string().required(),
	workAreas: Joi.array()
		.items(
			Joi.object({
				workArea: Joi.string().allow('')
			})
		)
		.max(2)
})

function WorkArea(props) {
	const { t, i18n } = useTranslation()
	const {
		control,
		reset,
		setValue,
		watch,
		handleSubmit,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema, {
			errors: {
				labels: false,
				language: localStorage.getItem('i18nextLng')
			},
			...schemaOptions
		})
	})
	const firstWorkAreaWatch = watch('primaryWorkArea')

	const {
		fields: areasFields,
		append,
		remove
	} = useFieldArray({
		reset,
		control,
		name: 'workAreas'
	})
	const [isLoading, setLoading] = useState(false)
	const isCEO = useMemo(
		() => (props.modalStudent ? props.modalStudent.isCEO : props.isCEO),
		[props.isCEO, props.modalStudent]
	)
	const onSubmit = data => {
		setLoading(true)

		const url = props.modalStudent
			? TApi.STUDENT_PUT_EMPLOYEES(props.modalStudent.studentId)
			: TApi.STUDENT_PUT_PROFILE
		const body = {
			primaryWorkArea: data.primaryWorkArea,
			secondaryWorkArea: data.workAreas[0] ? data.workAreas[0].workArea : '',
			tertiaryWorkArea: data.workAreas[1] ? data.workAreas[1].workArea : ''
		}

		http.put(url, body)
			.then(() => {
				toast.success(t('Profile has been updated successfully.'))

				if (props.refreshEmployees) props.refreshEmployees()
			})
			.finally(() => setLoading(false))
	}

	const workAreaOptions = ({ isInjectCEO } = { isInjectCEO: false }) => {
		const workAreas = props.workAreas
			?.map(v => ({
				value: v.value_EN,
				label: i18n.language === TLanguages.EN ? v.value_EN : v.value_DE
			}))
			.filter(v => !(!isInjectCEO && v.value === 'CEO / Managing Director'))

		return workAreas
	}

	useEffect(() => {
		let areas = []

		const primaryWorkArea = props.modalStudent ? props.modalStudent.primaryWorkArea : props.primaryWorkArea
		const secondaryWorkArea = props.modalStudent ? props.modalStudent.secondaryWorkArea : props.secondaryWorkArea
		const tertiaryWorkArea = props.modalStudent ? props.modalStudent.tertiaryWorkArea : props.tertiaryWorkArea

		setValue('primaryWorkArea', primaryWorkArea)
		if (secondaryWorkArea) areas.push({ workArea: secondaryWorkArea })
		if (tertiaryWorkArea) areas.push({ workArea: tertiaryWorkArea })

		if (areas.length) setValue('workAreas', areas, { shouldDirty: true })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.modalStudent, props.primaryWorkArea, props.secondaryWorkArea, props.tertiaryWorkArea])

	return (
		<div className={styles.root}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className={clsx(styles.section, props.modalStudent && styles.sectionModal)}>
					<h1 className={clsx(styles.sectionTitle, props.modalStudent && styles.sectionTitleModal)}>
						{t('Functional and working area')}
						{!isCEO && !firstWorkAreaWatch && (
							<img
								className={styles.warningParentalConfirmation}
								src={imageWarning}
								data-tip={t('Edit function & working area')}
								alt=""
							/>
						)}
					</h1>
					<div className={styles.sectionContent}>
						<div className={styles.mainFunction}>
							<SelectField
								required
								disabled={isLoading || isCEO}
								mb={20}
								label={t('Main function')}
								control={control}
								name="primaryWorkArea"
								error={errors.primaryWorkArea}
								placeholder={t('Your main work area')}
								options={workAreaOptions({ isInjectCEO: isCEO })}
							/>
							{isCEO && (
								<img
									className={styles.info}
									src={imageInfo}
									data-tip={t(
										'The CEO role must always be assigned to one person. It can be assigned to another person via the 3-point menu in the "Employees" view. CEO name is shown on the company register extract.'
									)}
									alt="info icon"
								/>
							)}
						</div>

						{areasFields.map((item, index) => (
							<div key={item.id} className={styles.flexContainer}>
								<SelectField
									disabled={isLoading}
									mb={20}
									label={t('Work area')}
									control={control}
									name={`workAreas.${index}.workArea`}
									error={errors.workAreas && errors.workAreas[index]?.workArea}
									placeholder={t('Your work area')}
									options={workAreaOptions()}
								/>

								<Btn
									disabled={isLoading}
									theme="white"
									icon="delete"
									iconColor="#000"
									onClick={() => remove(index)}
								/>
							</div>
						))}

						{areasFields.length <= 1 && !props.isTeacherStudentView && (
							<div className={styles.btnAdd}>
								<BtnAdd disabled={isLoading} type="button" onClick={() => append({ workArea: '' })} />
								<h1 className={styles.btnTitle}>{t('Add work area')}</h1>
							</div>
						)}
						{!props.isTeacherStudentView && (
							<div className={styles.btnContainer}>
								<Btn
									loading={isLoading}
									className={clsx(styles.btn)}
									type="submit"
									icon="arrow"
									iconSize={{ w: 10, h: 10 }}
									iconColor="#fff"
								>
									{t('save')}
								</Btn>
							</div>
						)}
					</div>
				</div>
			</form>
		</div>
	)
}

WorkArea.propTypes = {
	primaryWorkArea: PropTypes.string.isRequired,
	secondaryWorkArea: PropTypes.string.isRequired,
	tertiaryWorkArea: PropTypes.string.isRequired,
	workAreas: PropTypes.array,
	isCEO: PropTypes.bool.isRequired,
	modalStudent: PropTypes.shape({
		studentId: PropTypes.number.isRequired,
		isCEO: PropTypes.bool.isRequired,
		primaryWorkArea: PropTypes.string.isRequired,
		secondaryWorkArea: PropTypes.string.isRequired,
		tertiaryWorkArea: PropTypes.string.isRequired
	}),
	refreshEmployees: PropTypes.func,
	isTeacherStudentView: PropTypes.bool.isRequired
}

WorkArea.defaultProps = {
	modalStudent: null,
	refreshEmployees: null
}
const mapStateToProps = state => ({
	primaryWorkArea: state.user.profile.primaryWorkArea,
	secondaryWorkArea: state.user.profile.secondaryWorkArea,
	tertiaryWorkArea: state.user.profile.tertiaryWorkArea,
	isCEO: state.user.profile.isCEO,
	workAreas: state.options.workAreas
})

export default connect(mapStateToProps)(WorkArea)
