import { useState, useEffect } from 'react'
import styles from '../style.module.scss'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import Joi from 'joi'
import http from '../../../../../shared/http'
import { TApi } from '../../../../../shared/const'
import { schemaOptions } from '../../../../../shared/i18n'
import { joiResolver } from '@hookform/resolvers/joi'

import TextField from '../../../../../UI/TextField'
import Btn from '../../../../../UI/Btn'
import { useTranslation } from 'react-i18next'

const schema = Joi.object({
	oldPassword: Joi.string(),
	newPassword: Joi.string().min(8).max(32).required(),
	repeatPassword: Joi.string().valid(Joi.ref('newPassword')).required()
})

export default function Security(props) {
	const { t } = useTranslation()
	const {
		register,
		trigger,
		handleSubmit,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema, {
			errors: {
				labels: false,
				language: localStorage.getItem('i18nextLng')
			},
			...schemaOptions
		})
	})
	const [isLoading, setLoading] = useState(false)

	useEffect(() => {
		if (Object.keys(errors).length) trigger()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localStorage.getItem('i18nextLng')])

	const onSubmit = data => {
		setLoading(true)
		const { oldPassword, newPassword } = data
		http.put(TApi.STUDENT_PASSWORD_CHANGE, { oldPassword, newPassword })
			.then(() => {
				toast.success(t('Passsword has been changed successfuly.'))
			})
			.finally(() => setLoading(false))
	}

	return (
		<div className={styles.root}>
			<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
				<div className={styles.section}>
					<h1 className={styles.sectionTitle}>{t('Change Password')}</h1>
					<div className={styles.sectionContent}>
						<TextField
							mb={20}
							type="password"
							label={t('Old password')}
							placeholder={t('Old password')}
							error={errors.oldPassword}
							register={register('oldPassword')}
						/>
						<TextField
							disabled={isLoading}
							mb={20}
							type="password"
							label={t('New password')}
							placeholder={t('New password')}
							error={errors.newPassword}
							register={register('newPassword')}
						/>
						<div className={styles.flexContainer}>
							<TextField
								disabled={isLoading}
								mb={20}
								type="password"
								label={t('Repeat new password')}
								placeholder={t('Repeat new password')}
								error={errors.repeatPassword}
								register={register('repeatPassword')}
							/>
							{!props.isTeacherStudentView && (
								<div className={styles.btnContainer}>
									<Btn
										loading={isLoading}
										className={styles.btnWithoutIcon}
										icon="arrow"
										iconSize={{ w: 10, h: 10 }}
										type="submit"
										iconColor="#fff"
									>
										{t('save')}
									</Btn>
								</div>
							)}
						</div>
					</div>
				</div>
			</form>
		</div>
	)
}
