import clsx from 'clsx'
import Avatar from 'react-avatar'
import PropTypes from 'prop-types'

import photoIcon from '../../assets/icons/photo.svg'

import styles from './style.module.scss'
import { useTranslation } from 'react-i18next'
import eventEmitter, { types } from '../../shared/eventEmitter'

export default function LogoUploader(props) {
	const { t } = useTranslation()
	return (
		<div className={clsx(styles.root, props.className)}>
			<Avatar src={props.src} className={styles.preview} color="#e5eaf5" size={160} />

			<div className={styles.wrapper}>
				{props.error && <span className={styles.error}>{props.error.message}</span>}
				<div className={styles.wrapperInput}>
					<label className={clsx(styles.labelUploadFile, props.disabled && styles.labelUploadFileDisabled)}>
						<input
							{...(props.register || {})}
							disabled={props.disabled}
							className={styles.input}
							type="file"
							accept="image/jpeg,image/png,image/gif"
							onChange={evt => {
								eventEmitter.emit(types.openCropperModal, evt.target.files[0], file => {
									props.onChange(file)
								})
							}}
							onClick={event => (event.target.value = null)}
						/>
						<img className={styles.labelUploadFileIcon} src={photoIcon} alt="icon" />
						<span>{t('Upload logo & product images')}</span>
					</label>
				</div>
			</div>
		</div>
	)
}

LogoUploader.propTypes = {
	className: PropTypes.string,
	src: PropTypes.string,
	register: PropTypes.object,
	error: PropTypes.object,
	disabled: PropTypes.bool,
	onChange: PropTypes.func.isRequired
}

LogoUploader.defaultProps = {
	disabled: false
}
