import { useEffect, useState } from 'react'
import http from '../../../shared/http'
import PropTypes from 'prop-types'
import AnimateHeight from 'react-animate-height'

import CheckBoxFilter from './components/CheckBoxFilter'

import styles from '../style.module.scss'
import Checkbox from '../../Checkbox'
import { TApi } from '../../../shared/const'
import Search from '../../Search'
import { useTranslation } from 'react-i18next'

export default function TeachersSection(props) {
	const { t } = useTranslation()
	const [isOpen, setOpen] = useState(false)
	const [options, setOptions] = useState([])
	const [search, setSearch] = useState('')

	const fetchTeachers = () => {
		http.get(TApi.ADMIN_GET_USERS, {
			params: {
				limit: 10,
				direction: 'asc',
				role: 'TEACHER',
				search
			}
		}).then(({ data }) => {
			setOptions(
				data.rows.map(v => ({
					value: v.id,
					label: `${v.firstName} ${v.lastName}`
				}))
			)
		})
	}

	useEffect(() => {
		fetchTeachers()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className={styles.sectionBlock}>
			<div className={styles.filterSectionHeader}>
				<CheckBoxFilter checked={isOpen} onChange={setOpen} label={t('Teacher')} className={styles.mainCheck} />

				<button
					className={styles.btnLocalClear}
					onClick={evt => {
						evt.preventDefault()
						props.onChange([])
					}}
				>
					{t('Clear all')}
				</button>
			</div>
			<AnimateHeight duration={500} height={isOpen ? 'auto' : 0}>
				<Search
					className={styles.search}
					onChange={e => setSearch(e.target.value)}
					value={search}
					onSubmit={evt => {
						evt.preventDefault()
						fetchTeachers()
					}}
				/>
				<div className={styles.popupOptions}>
					{options.map(item => (
						<div className={styles.option} key={item.value}>
							<Checkbox
								value={item.value}
								label={item.label}
								register={{
									checked: props.values.find(({ value }) => value === item.value),
									onChange: evt => {
										if (evt.target.checked) props.onChange([...props.values, item])
										else props.onChange(props.values.filter(({ value }) => value !== item.value))
									}
								}}
								mb={10}
								classNameLabel={styles.checkName}
								classNameCheckbox={styles.check}
							/>
						</div>
					))}
				</div>
			</AnimateHeight>
		</div>
	)
}

TeachersSection.propTypes = {
	values: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired
		})
	).isRequired,
	onChange: PropTypes.func.isRequired
}
