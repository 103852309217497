import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import Btn from '../../../../../../UI/Btn'
import TextField from '../../../../../../UI/TextField'
import styles from '../style.module.scss'
import Joi from 'joi'
import { joiResolver } from '@hookform/resolvers/joi'
import { useTranslation } from 'react-i18next'
import http from '../../../../../../shared/http'
import { downloadFile } from '../../../../../../shared/helpers'
import { TApi } from '../../../../../../shared/const'

const schema = Joi.object({
	password: Joi.string().required()
})

export default function ClearDB(props) {
	const { t } = useTranslation()
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema)
	})

	const onSubmit = form => {
		props.updateOptions({ name: 'clearDBFile', value: true })
		http.get(TApi.ADMIN_CLEAR_DB, {
			responseType: 'arraybuffer',
			params: form
		}).then(res => {
			let blob = new Blob([res.data], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
			})
			downloadFile('ClearDB', blob)

			toast.success(`${t('Database was successfully anonymized and cleared')}`)
		})
	}

	const download = () => {
		http.get(TApi.ADMIN_DOWNLOAD_ANONIMIZED_FILE, {
			responseType: 'arraybuffer'
		}).then(res => {
			let blob = new Blob([res.data], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
			})
			downloadFile('ClearDB', blob)
		})
	}

	return (
		<form id="clearDB" className={styles.root} onSubmit={handleSubmit(onSubmit)}>
			<section className={styles.section}>
				<h3 className={styles.sectionTitle}>{t('Anonimize and clear db')}</h3>
				<div className={styles.sectionContent}>
					<TextField
						type="password"
						className={styles.field}
						mb={20}
						label={t('Password')}
						register={register('password')}
						placeholder="Type your password"
						error={errors.password}
					/>
					<Btn iconColor="#fff" onClick={() => download()} disabled={!props.options?.clearDBFile}>
						{t('download anonimized database')}
					</Btn>

					<div className={styles.btnContainer}>
						<Btn
							className={styles.btn}
							type="submit"
							icon="arrow"
							iconSize={{ w: 10, h: 10 }}
							iconColor="#fff"
						>
							{t('Clear Database')}
						</Btn>
					</div>
				</div>
			</section>
		</form>
	)
}
