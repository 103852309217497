import React from 'react'
import styles from '../../style.module.scss'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import Btn from '../../../../../../../../UI/Btn'
import clsx from 'clsx'
import { toast } from 'react-toastify'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import TextFieldNumber from '../../../../../../../../UI/TextFieldNumber'
import Decimal from 'decimal.js'
function CompanyShareCalculation(props) {
	const { t } = useTranslation()
	const {
		control,
		reset,
		handleSubmit,
		formState: { errors }
	} = useForm()

	const onSubmit = form => {
		let data = form
		data.totalGrossRevenue = form.annualAdvanceVatReturnNetIncomeTotal
		data.netProfitLoss = form.finalAnnualReportResultTotal
		delete data.advanceVatReturnTotalIncome
		delete data.advanceVatReturnTotalExpenses
		delete data.advanceVatReturnTotalTaxedIncome
		delete data.advanceVatReturnTotalTaxedExpenses
		delete data.advanceVatReturnNetIncomeTotal
		delete data.juniorInsurance
		// delete annual data
		delete data.annualReportPersonellCosts
		delete data.annualReportPersonellCostsTotal
		delete data.annualExpensesTaxFreeTotal
		delete data.annualAdvanceVatReturnNetExpenses
		delete data.annualAdvanceVatReturnNetIncomeTotal
		delete data.annualReportResultTotalTax
		delete data.annualReportResultTotal
		delete data.expensesTaxFreeTotal

		props.updateData(data).then(() => {
			toast.success(t('Income/Expense statement was updated'))
			props.handlerNextStep()
		})
	}

	useEffect(() => {
		console.log('Form data: ', props.form)
		console.log('grossSalariesEmployeeTotal ', props.form?.grossSalariesEmployeeTotal)
		//Fixed Null issue

		const isSubmitted = props.form?.isSubmitted

		const advanceVatReturnNetIncomeTotal =
			props.form?.totalGrossRevenue && isSubmitted
				? props.form?.totalGrossRevenue
				: new Decimal(props.form?.advanceVatReturnNetIncomeTotal || 0)
						.plus(new Decimal(props.form?.advanceVatReturnNetIncomeTotalFirst || 0))
						.toDecimalPlaces(2)
						.toNumber() || 0

		const advanceVatReturnNetExpensesFirst = props.form?.advanceVatReturnNetExpensesFirst
			? new Decimal(props.form?.advanceVatReturnNetExpensesFirst)
			: new Decimal(0)

		const advanceVatReturnNetExpenses =
			props.form?.advanceVatReturnNetExpenses && isSubmitted
				? props.form?.advanceVatReturnNetExpenses
				: new Decimal(props.form?.advanceVatReturnNetExpenses || 0)
						.plus(advanceVatReturnNetExpensesFirst)
						.toDecimalPlaces(2)
						.toNumber() || 0

		const expensesTaxFreeTotalFirst = props.form?.expensesTaxFreeTotalFirst
			? new Decimal(props.form?.expensesTaxFreeTotalFirst)
			: new Decimal(0)

		const annualExpensesTaxFreeTotalFinal = new Decimal(props.form?.expensesTaxFreeTotal ?? 0)
			.plus(expensesTaxFreeTotalFirst)
			.toDecimalPlaces(2)
			.toNumber()

		const annualReportPersonellCostsTotal =
			new Decimal(props.form?.grossSalariesEmployeeTotal || 0)
				.times(1.06)
				.plus(new Decimal(props.form?.semiAnnualReportPersonellCostsFirst || 0))
				.toDecimalPlaces(2)
				.toNumber() || 0

		const finalAnnualReportResultTotal =
			new Decimal(advanceVatReturnNetIncomeTotal || 0)
				.minus(new Decimal(advanceVatReturnNetExpenses || 0))
				.minus(
					new Decimal(props.form?.expensesTaxFreeTotal ?? 0)
						.plus(new Decimal(props.form?.expensesTaxFreeTotalFirst ?? 0))
						.toNumber()
				)
				.minus(annualReportPersonellCostsTotal || 0)
				.minus(new Decimal(props.juniorInsurance || 0))
				.toDecimalPlaces(2)
				.toNumber() || 0

		const finalAnnualReportResultTotalTax = (() => {
			let finalAnnualReportResultTotalTax =
				new Decimal(finalAnnualReportResultTotal || 0).times(0.1).toDecimalPlaces(2).toNumber() || 0

			if (finalAnnualReportResultTotal < 0) {
				finalAnnualReportResultTotalTax = new Decimal(0).toDecimalPlaces(2).toNumber()
			}

			return finalAnnualReportResultTotalTax
		})()
		reset({
			semiAnnualReportPersonellCosts: annualReportPersonellCostsTotal ?? 0,
			annualReportPersonellCostsTotal: annualReportPersonellCostsTotal ?? 0,
			juniorInsurance: props.juniorInsurance ?? 0,
			semiAnnualReportResultTotal: finalAnnualReportResultTotal ?? 0,
			semiAnnualReportResultTotalTax: finalAnnualReportResultTotalTax ?? 0,
			annualReportResultTotal: finalAnnualReportResultTotal ?? 0,
			annualReportResultTotalTax: finalAnnualReportResultTotalTax ?? 0,
			advanceVatReturnNetIncomeTotal: advanceVatReturnNetIncomeTotal ?? 0,
			advanceVatReturnNetExpenses: advanceVatReturnNetExpenses ?? 0,
			annualAdvanceVatReturnNetIncomeTotal: advanceVatReturnNetIncomeTotal ?? 0,
			annualAdvanceVatReturnNetExpenses: advanceVatReturnNetExpenses ?? 0,
			expensesTaxFreeTotal: annualExpensesTaxFreeTotalFinal ?? 0,
			annualExpensesTaxFreeTotal: annualExpensesTaxFreeTotalFinal ?? 0,
			advanceVatReturnTotalTaxedIncome: new Decimal(props.form?.advanceVatReturnTotalTaxedIncome ?? 0)
				.toDecimalPlaces(2)
				.toNumber(),
			advanceVatReturnTotalTaxedExpenses: new Decimal(props.form?.advanceVatReturnTotalTaxedExpenses ?? 0)
				.toDecimalPlaces(2)
				.toNumber(),

			advanceVatReturnTotalIncome: new Decimal(props.form?.advanceVatReturnTotalIncome ?? 0)
				.toDecimalPlaces(2)
				.toNumber(),
			advanceVatReturnTotalExpenses: new Decimal(props.form?.advanceVatReturnTotalExpenses ?? 0)
				.toDecimalPlaces(2)
				.toNumber()
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.form])

	if (props.currentStep !== props.needStep) return null

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<section className={styles.section}>
				<h3 className={styles.sectionTitle}>{t('Income/Expense statement for whole year')}</h3>
				<div className={styles.sectionContent}>
					<div className={styles.flexContainer}>
						<TextFieldNumber
							disabled
							mb={20}
							label={t('Sales net in €')}
							name="advanceVatReturnNetIncomeTotal"
							control={control}
							placeholder="00.000,00"
							error={errors.advanceVatReturnNetIncomeTotal}
						/>
						<TextFieldNumber
							disabled
							mb={20}
							label={t('less material and other costs net in €')}
							name="advanceVatReturnNetExpenses"
							control={control}
							placeholder="00.000,00"
							error={errors.advanceVatReturnNetExpenses}
						/>
					</div>
					<div className={styles.flexContainer}>
						<TextFieldNumber
							disabled
							mb={20}
							label={t('less personnel costs incl. all taxes in €')}
							name="semiAnnualReportPersonellCosts"
							control={control}
							placeholder="00.000,00"
							error={errors.semiAnnualReportPersonellCosts}
						/>
						<TextFieldNumber
							disabled
							mb={20}
							label={t('less other expenses not subject to sales tax in €')}
							name="expensesTaxFreeTotal"
							control={control}
							placeholder="00.000,00"
							error={errors.expensesTaxFreeTotal}
						/>
					</div>
					<TextFieldNumber
						className={styles.disabledInput}
						disabled
						mb={20}
						label={t('less junior insurance in €')}
						error={errors.juniorInsurance}
						placeholder="00.000,00"
						name="juniorInsurance"
						control={control}
					/>
					<div className={styles.flexContainer}>
						<TextFieldNumber
							disabled
							classNameInput={styles.calculated}
							mb={20}
							label={t('Total net income for the year in €')}
							name="semiAnnualReportResultTotal"
							control={control}
							placeholder="00.000,00"
							error={errors.semiAnnualReportResultTotal}
						/>
						<TextFieldNumber
							disabled
							classNameInput={styles.calculated}
							mb={20}
							label={t('thereof 10% corporate income tax in €')}
							name="semiAnnualReportResultTotalTax"
							control={control}
							placeholder="00.000,00"
							error={errors.semiAnnualReportResultTotalTax}
						/>
					</div>
					<div className={clsx(styles.flexContainer, styles.btnContainer)}>
						{props.form?.isSubmitted || props.isTeacherStudentView ? (
							<>
								<Btn
									className={styles.btn}
									type="button"
									width={150}
									onClick={() => props.handlerPrevStep()}
								>
									{t('Previous')}
								</Btn>
								<Btn
									className={styles.btn}
									type="button"
									width={150}
									onClick={() => props.handlerNextStep()}
								>
									{t('Next')}
								</Btn>
							</>
						) : (
							<Btn
								disabled={props.form?.isLoading || props.form?.isSubmitted}
								className={styles.btn}
								type="submit"
								width={337}
							>
								{t('save')}
							</Btn>
						)}
					</div>
				</div>
			</section>
		</form>
	)
}

CompanyShareCalculation.propTypes = {
	form: PropTypes.object,
	updateData: PropTypes.func,

	currentStep: PropTypes.number,
	needStep: PropTypes.number,
	handlerNextStep: PropTypes.func,
	handlerPrevStep: PropTypes.func,
	juniorInsurance: PropTypes.number,
	isTeacherStudentView: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
	juniorInsurance: state.options.juniorInsurance?.JC,
	isTeacherStudentView: state.user.isTeacherStudentView
})
export default connect(mapStateToProps, null)(CompanyShareCalculation)
