import Joi from 'joi'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { joiResolver } from '@hookform/resolvers/joi'
import { TApi, TLanguages, genderOption } from '../../../../../../shared/const'
import { schemaOptions } from '../../../../../../shared/i18n'
import http from '../../../../../../shared/http'

import SelectField from '../../../../../../UI/SelectField'
import TextField from '../../../../../../UI/TextField'
import Btn from '../../../../../../UI/Btn'
import styles from '../../style.module.scss'
import { updateProfile } from '../../../../../../redux/ducks/user'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const schema = Joi.object({
	salutation: Joi.string().required(),
	titlePrefixed: Joi.string().allow('', null),
	titleSuffixed: Joi.string().allow('', null),
	firstName: Joi.string()
		.min(2)
		.max(26)
		.regex(/^[a-zA-ZА-яЁёÄÖÜẞäöüß]+( [a-zA-ZА-яЁёÄÖÜẞäöüß]+)*$/)
		.required()
		.messages({
			'string.pattern.base': 'Only letters are allowed !'
		}),
	lastName: Joi.string()
		.min(2)
		.max(26)
		.regex(/^[a-zA-ZА-яЁёÄÖÜẞäöüß]+( [a-zA-ZА-яЁёÄÖÜẞäöüß]+)*$/)
		.required()
		.messages({
			'string.pattern.base': 'Only letters are allowed !'
		}),
	gender: Joi.string().required()
})

function BasicInfo(props) {
	const { t, i18n } = useTranslation()
	const {
		register,
		control,
		trigger,
		handleSubmit,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema, {
			errors: {
				labels: false,
				language: localStorage.getItem('i18nextLng')
			},
			...schemaOptions
		}),
		defaultValues: { ...props.profile }
	})

	useEffect(() => {
		if (Object.keys(errors).length) trigger()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localStorage.getItem('i18nextLng')])

	const onSubmit = data => {
		props.setLoading(true)
		http.put(TApi.TEACHER_PUT_PROFILE, data)
			.then(({ data }) => {
				props.updateProfile(data.user)
				toast.success('Basic information updated')
			})
			.finally(() => props.setLoading(false))
	}

	return (
		<form className={styles.section} onSubmit={handleSubmit(onSubmit)}>
			<h1 className={styles.sectionTitle}>{t('Basic Info')}</h1>
			<div className={styles.sectionContent}>
				<div>
					<div className={styles.flexContainer}>
						<SelectField
							className={styles.select}
							disabled={props.isLoading}
							mb={20}
							label={t('salutation')}
							control={control}
							name="salutation"
							error={errors.salutation}
							placeholder={t('Select salutation')}
							options={props.salutations?.map(v => ({
								value: v.value_EN,
								label: i18n.language === TLanguages.EN ? v.value_EN : v.value_DE
							}))}
						/>
						<TextField
							className={styles.select}
							disabled={props.isLoading}
							mb={20}
							label={t('Title Prefixed')}
							error={errors.titlePrefixed}
							placeholder={t('Enter Title')}
							register={register('titlePrefixed')}
						/>
					</div>
					<div className={styles.flexContainer}>
						<TextField
							disabled={props.isLoading}
							mb={20}
							label={t('First Name')}
							placeholder={t('Type First Name')}
							error={errors.firstName}
							register={register('firstName')}
						/>
						<TextField
							disabled={props.isLoading}
							mb={20}
							label={t('Last Name')}
							placeholder={t('Type Last Name')}
							error={errors.lastName}
							register={register('lastName')}
						/>
					</div>
					<div className={styles.flexContainer}>
						<TextField
							disabled={props.isLoading}
							mb={20}
							label={t('Title Suffixed')}
							placeholder={t('Enter Title')}
							error={errors.titleSuffixed}
							register={register('titleSuffixed')}
						/>
						<SelectField
							className={styles.select}
							mb={20}
							required
							label={t('gender')}
							error={errors.gender}
							placeholder={t('Select gender')}
							options={genderOption
								.map(v => ({ label: t(v.label), value: v.value }))
								.sort((a, b) => a.label.localeCompare(b.label))}
							control={control}
							name="gender"
						/>
					</div>
					<div className={styles.btnContainer}>
						<Btn
							className={styles.btnWithoutIcon}
							loading={props.isLoading}
							width={214}
							type="submit"
							icon="arrow"
							iconSize={{ w: 10, h: 10 }}
							iconColor="#fff"
						>
							{t('save')}
						</Btn>
					</div>
				</div>
			</div>
		</form>
	)
}

const mapStateToProps = state => {
	const { salutation, titleSuffixed, titlePrefixed, firstName, lastName, gender } = state.user.profile
	return {
		profile: {
			salutation,
			titleSuffixed,
			titlePrefixed,
			firstName,
			lastName,
			gender
		},
		salutations: state.options.salutations
	}
}
const mapDispatchToProps = dispatch => ({
	updateProfile: data => dispatch(updateProfile(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(BasicInfo)
