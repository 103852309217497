import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import Joi from 'joi'
import { toast } from 'react-toastify'

import AvatarUploader from '../../../../../UI/AvatarUploader'

import styles from '../style.module.scss'

import http from '../../../../../shared/http'
import { TApi } from '../../../../../shared/const'
import { useTranslation } from 'react-i18next'
import { joiFile } from '../../../../../shared/helpers'
import { updateProfile } from '../../../../../redux/ducks/user'

const schema = Joi.object({
	avatar: joiFile(10)
})

function AvatarPhoto(props) {
	const { t } = useTranslation()
	const {
		register,
		handleSubmit,
		clearErrors,
		formState: { errors }
	} = useForm({
		reValidateMode: 'onChange',
		resolver: joiResolver(schema)
	})
	const [avatarSrc, setAvatarSrc] = useState('')

	const handleChange = fields => {
		const form = new FormData()
		if (fields.avatar && fields.avatar[0]) form.append('avatar', fields.avatar[0])

		http.put(TApi.STUDENT_AVATAR_UPLOAD, form).then(({ data }) => {
			props.updateProfile({ ...data })
			setAvatarSrc(URL.createObjectURL(fields.avatar[0]))
			toast.success(t('Avatar successfully updated'))
		})
	}

	const handleClean = () => {
		if (avatarSrc)
			http.delete(TApi.STUDENT_AVATAR_DELETE).then(() => {
				toast.success(t('Avatar successfully deleted'))
				setAvatarSrc('')
				clearErrors('avatar')
				props.updateProfile({ avatarHashName: '' })
			})
	}

	useEffect(() => {
		setAvatarSrc(props.avatarHashName ? TApi.PUBLIC_PROFILE_AVATAR(props.avatarHashName) : '')
	}, [props.avatarHashName])

	return (
		<div className={styles.section}>
			<h1 className={styles.sectionTitle}>{t('Avatar photo')}</h1>
			<div className={styles.sectionContent}>
				<AvatarUploader
					src={avatarSrc}
					error={errors.avatar}
					onClean={handleClean}
					onChange={handleSubmit(handleChange)}
					className={styles.avatarUploader}
					register={register('avatar')}
					disabled={props.isTeacherStudentView}
				/>
			</div>
		</div>
	)
}

const mapStateToProps = state => {
	return { avatarHashName: state.user.profile.avatarHashName }
}

const mapDispatchToProps = dispatch => ({
	updateProfile: data => dispatch(updateProfile(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AvatarPhoto)
