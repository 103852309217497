import { useEffect } from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import { parseISO, format as formatDate } from 'date-fns'
import { useTranslation } from 'react-i18next'

import Btn from '../../../../../../UI/Btn'
import Chip from '../../../../../../UI/Chip'
import Icon from '../../../../../../UI/Icon'

import VisibilityAccess from '../../../../../VisibilityAccess'

import { TRoles } from '../../../../../../shared/const'

import styles from './style.module.scss'

// MOVE TO UI
export default function NewsWidget(props) {
	const { t } = useTranslation()
	useEffect(() => {
		ReactTooltip.rebuild()
	}, [t])

	return (
		<div className={styles.root}>
			<div className={styles.titleContainer}>
				<h1 className={styles.title}>
					<Chip className={styles.newsChip} miniRadius>
						#{props.id}
					</Chip>
					{props.title}
				</h1>
				<VisibilityAccess access={[TRoles.ADMIN, TRoles.FEDERAL_ADMIN]}>
					{!!props.onEdit && (
						<Btn
							onClick={() => props.onEdit(props.id)}
							theme="minimal"
							icon="arrow"
							iconSize={{ w: 8, h: 8 }}
							iconColor="#303e65"
						>
							{t('Edit')}
						</Btn>
					)}
				</VisibilityAccess>
			</div>
			<VisibilityAccess access={[TRoles.ADMIN, TRoles.FEDERAL_ADMIN]}>
				<div className={styles.feedInfo}>
					<span className={styles.feedInfoItem}>
						<span className={styles.feedInfoItemLabel}>{t('Target groups')}:</span>{' '}
						{props.roles ? (
							props.roles.map(role => (
								<Chip className={styles.newsChip} miniRadius>
									{role}
								</Chip>
							))
						) : (
							<Chip miniRadius>All</Chip>
						)}
					</span>
					<span className={styles.feedInfoItem}>
						<span className={styles.feedInfoItemLabel}>States:</span>{' '}
						{props.states ? (
							<span data-tip={props.states.map(state => state.replaceAll('_', ' ')).join(', ')}>
								<Chip miniRadius>
									{props.states[0]}{' '}
									{props.states.length > 1 && `and ${props.states.length - 1} others`}
								</Chip>
							</span>
						) : (
							<Chip miniRadius>All</Chip>
						)}
					</span>
				</div>
			</VisibilityAccess>
			<div className={styles.feedInfo}>
				{!!props.place && (
					<span className={styles.feedInfoItem}>
						<span className={styles.feedInfoItemLabel}>{t('Place')}:</span>{' '}
						<Chip miniRadius>{props.place}</Chip>
					</span>
				)}
			</div>
			<VisibilityAccess access={[TRoles.ADMIN, TRoles.FEDERAL_ADMIN]}>
				<div className={styles.feedInfo}>
					<span className={styles.feedInfoItem}>
						<span className={styles.feedInfoItemLabel}>Company types:</span>{' '}
						{props.companyTypes ? (
							props.companyTypes.map(type => (
								<Chip className={styles.newsChip} miniRadius>
									{type}
								</Chip>
							))
						) : (
							<Chip miniRadius>{t('All')}</Chip>
						)}
					</span>
				</div>
			</VisibilityAccess>
			{(props.startDate || props.endDate) && (
				<div className={styles.feedInfo}>
					<VisibilityAccess access={[TRoles.ADMIN, TRoles.FEDERAL_ADMIN]}>
						{!!props.startDate && (
							<span className={styles.feedInfoItem}>
								<span className={styles.feedInfoItemLabel}>{t('Start date')}:</span>{' '}
								<Chip className={styles.newsChip} miniRadius>
									{formatDate(parseISO(props.startDate), 'dd MMM RRRR')}
								</Chip>
							</span>
						)}
						{!!props.endDate && (
							<span className={styles.feedInfoItemEndDate}>
								<span className={styles.feedInfoItemLabel}>{t('End date')}:</span>{' '}
								<Chip className={styles.newsChip} miniRadius>
									{formatDate(parseISO(props.endDate), 'dd MMM RRRR')}
								</Chip>
							</span>
						)}
					</VisibilityAccess>
				</div>
			)}
			<p className={styles.description}>{props.description}</p>

			<div className={styles.actions}>
				<VisibilityAccess access={[TRoles.ADMIN, TRoles.FEDERAL_ADMIN]}>
					{!!props.onDelete && (
						<Btn
							onClick={() => props.onDelete(props.id)}
							className={styles.btnRemove}
							theme="minimal"
							icon="delete"
							iconColor="#303e65"
						>
							{t('Remove')}
						</Btn>
					)}
				</VisibilityAccess>
				{!!props.externalLink && (
					<a
						className={styles.externalLink}
						href={props.externalLink}
						target="_blank"
						rel="noopener noreferrer"
					>
						{t('External Link')}{' '}
						<Icon className={styles.externalLinkIcon} name="arrow" color="#303e65" size={{ w: 8, h: 8 }} />
					</a>
				)}
			</div>
		</div>
	)
}
NewsWidget.propTypes = {
	id: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	place: PropTypes.string,
	externalLink: PropTypes.string,
	startDate: PropTypes.string,
	endDate: PropTypes.string,
	states: PropTypes.array,
	roles: PropTypes.array,
	companyTypes: PropTypes.array,

	onDelete: PropTypes.func,
	onEdit: PropTypes.func
}
