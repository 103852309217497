import { useState } from 'react'

import BaseModal from '../../../../UI/BaseModal/BaseModal'

import { types } from '../../../../shared/eventEmitter'
import NewsWidget from '../../../views/common/News/components/NewsWidget'

export default function ViewFeedModal() {
	const [feed, setFeed] = useState({
		id: 0,
		title: '',
		description: '',
		startDate: new Date().toISOString(),
		endDate: new Date().toISOString(),
		place: '',
		externalLink: '',
		roles: [],
		states: [],
		companyTypes: []
	})

	const handleOpen = feed => setFeed(feed)

	return (
		<BaseModal onOpen={handleOpen} nameEvent={types.openDetailFeedModal} title="View feed" maxWidth={522}>
			{() => (
				<NewsWidget
					id={feed.id}
					title={feed.title}
					description={feed.description}
					place={feed.place}
					externalLink={feed.externalLink}
					startDate={feed.startDate}
					endDate={feed.endDate}
					roles={feed.roles}
					states={feed.states}
					companyTypes={feed.companyTypes}
				/>
			)}
		</BaseModal>
	)
}
