import BaseModal from '../../../../UI/BaseModal/BaseModal'
import { types } from '../../../../shared/eventEmitter'
import styles from '../style.module.scss'
import SelectField from '../../../../UI/SelectField'
import Joi from 'joi'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { joiPhone } from '../../../../shared/helpers'
import TextField from '../../../../UI/TextField'
import { StateOptions, TLanguages } from '../../../../shared/const'
import PropTypes from 'prop-types'
import { schemaOptions } from '../../../../shared/i18n'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import TextAreaField from '../../../../UI/TextAreaField'

const schema = Joi.object({
	companyType: Joi.string().required(),
	companyName: Joi.string().required(),
	companyState: Joi.string().required(),
	companyEmail: Joi.string().email({ tlds: false }).required(),
	companyPhone: joiPhone,
	companyBranch: Joi.string().required(),
	description: Joi.string().required(),
	offerTypes: Joi.string().required(),
	businessIdea: Joi.string().required(),
	businessActivityDescription: Joi.string().required()
})

function CompanyProfile(props) {
	const { t, i18n } = useTranslation()
	const { register, setValue, reset, control } = useForm({
		resolver: joiResolver(schema, {
			errors: {
				labels: false,
				language: localStorage.getItem('i18nextLng')
			},
			...schemaOptions
		})
	})

	const handleOpen = (profile = null) => {
		if (!profile) return
		setValue('companyType', profile.companyType)
		setValue('companyName', profile.name)
		setValue('companyState', profile.state)
		setValue('companyEmail', profile.email)
		setValue('companyPhone', profile.phone)
		setValue('companyBranch', profile.branch)
		setValue('description', profile.description)
		setValue('offerTypes', profile.offerTypes)
		setValue('businessIdea', profile.businessIdea)
		setValue('businessActivityDescription', profile.businessActivityDescription)
		setValue('adminComment', profile.adminComment)
	}

	const handleClose = () => {
		reset()
	}

	return (
		<BaseModal
			onClose={handleClose}
			onOpen={handleOpen}
			nameEvent={types.openCompanyProfile}
			title={t('companyProfile')}
			maxWidth={890}
		>
			{({ closeModal }) => (
				<div>
					<form className={styles.container}>
						<div className={styles.section}>
							<div className={styles.leftForm}>
								<TextField
									mb={20}
									label="Company Type"
									placeholder="Company Type"
									register={register('companyType')}
									disabled
								/>
							</div>
						</div>
						<div className={styles.section}>
							<div className={styles.leftForm}>
								<TextField
									mb={20}
									label={t('Company name')}
									placeholder={t('Company name')}
									register={register('companyName')}
									disabled
								/>
							</div>
						</div>

						<div className={styles.leftForm}>
							<SelectField
								mb={20}
								label={t('Company state')}
								control={control}
								name="companyState"
								placeholder={t('Company state')}
								disabled
								options={StateOptions.map(v => ({
									label: t(v.label),
									value: v.value
								})).sort((a, b) => a.label.localeCompare(b.label))}
							/>
						</div>

						<div className={styles.line} />

						<div className={styles.section}>
							<div className={styles.leftForm}>
								<TextField
									mb={20}
									label={t('Company email')}
									placeholder="company@email.com"
									register={register('companyEmail')}
									disabled
								/>
							</div>
						</div>

						<div className={styles.section}>
							<div className={styles.leftForm}>
								<TextField
									mb={20}
									label={`${t('Company')} ${t('phone')}`}
									placeholder="+43 6XX XXXXXX"
									register={register('companyPhone')}
									disabled
								/>
							</div>
						</div>

						<div className={styles.line} />

						<div className={styles.leftForm}>
							<SelectField
								mb={20}
								label={t('branch')}
								control={control}
								name="companyBranch"
								placeholder={t('branch')}
								options={props.branch?.map(v => ({
									value: v.value_EN,
									label: i18n.language === TLanguages.EN ? v.value_EN : v.value_DE
								}))}
								disabled
							/>
						</div>

						<div className={styles.leftForm}>
							<TextAreaField
								mb={20}
								height={128}
								label={t('description')}
								placeholder={t('Type Short Company Description')}
								register={register('description')}
								disabled
							/>
						</div>

						<div className={styles.leftForm}>
							<SelectField
								mb={20}
								label={t('typeOffers')}
								control={control}
								name="offerTypes"
								placeholder={t('typeOffers')}
								options={props.typeOfOffers?.map(v => ({
									value: v.value_EN,
									label: i18n.language === TLanguages.EN ? v.value_EN : v.value_DE
								}))}
								disabled
							/>
						</div>

						<div style={{ width: '100%' }}>
							<TextAreaField
								mb={40}
								height={189}
								label={t('Your Business Idea')}
								placeholder={t('We are the...')}
								register={register('businessIdea')}
								disabled
							/>
						</div>

						<div style={{ width: '100%' }}>
							<TextAreaField
								mb={40}
								height={189}
								label={t('Detailed description of business activity')}
								placeholder={t('Our...')}
								register={register('businessActivityDescription')}
								disabled
							/>
						</div>
						<div style={{ width: '100%' }}>
							<TextAreaField
								mb={40}
								height={189}
								label={t('Administrator comment about Company')}
								register={register('adminComment')}
								disabled
							/>
						</div>
					</form>
				</div>
			)}
		</BaseModal>
	)
}
CompanyProfile.propTypes = {
	branch: PropTypes.array.isRequired,
	typeOfOffers: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
	branch: state.options.branch,
	typeOfOffers: state.options.typeOfOffers
})

export default connect(mapStateToProps)(CompanyProfile)
