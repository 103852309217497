import Joi from 'joi'
import { joiResolver } from '@hookform/resolvers/joi'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { types } from '../../../../shared/eventEmitter'
import BaseModal from '../../../../UI/BaseModal/BaseModal'
import Btn from '../../../../UI/Btn'
import TextFieldNumber from '../../../../UI/TextFieldNumber'
import styles from '../style.module.scss'
import http from '../../../../shared/http'
import { TApi } from '../../../../shared/const'
import { toast } from 'react-toastify'
import dangerIcon from '../../../../assets/icons/Danger Triangle.svg'
import { customNumberFormat } from '../../../../shared/helpers'

const schema = Joi.object({
	paid: Joi.number()
		.custom((value, helper) => {
			const { amount } = helper.prefs.data
			if (Math.round(amount * 100) / 100 >= value) return value
			return helper.message('number can not be grather than amount')
		})
		.required()
		.messages({
			'number.base': `must be a number`,
			'any.required': `is a required field`
		})
})

export default function InvoiceChangeData() {
	const { t } = useTranslation()
	const [data, setData] = useState({})
	const {
		handleSubmit,
		reset,
		trigger,
		control,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema, {
			data
		})
	})

	const [id, setId] = useState(null)
	const [isLoading, setLoading] = useState(false)
	const [onDone, setOnDone] = useState(null)

	useEffect(() => {
		if (Object.keys(errors).length) trigger()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localStorage.getItem('i18nextLng')])

	const handleOpen = (invoice, onDone = null) => {
		setOnDone(() => onDone)

		if (!invoice) return
		setId(invoice.id)
		setData({ amount: invoice.amount, balance: invoice.balance })
		reset({ paid: invoice.amount - invoice.balance })
	}

	const onSubmit = (form, closeModal) => {
		setLoading(true)
		http.put(TApi.ADMIN_PUT_INVOICES(id), form)
			.then(() => {
				toast.success(t('Status was updated'))
				setId(null)
				closeModal()
			})
			.then(() => {
				if (onDone) onDone()
			})
			.finally(() => setLoading(false))
	}

	const handleClose = () => {
		reset({})
		setId(null)
	}

	return (
		<BaseModal
			onClose={handleClose}
			onOpen={handleOpen}
			nameEvent={types.openInvoiceChangeData}
			title={t('Change payment')}
			isLoading={isLoading}
			maxWidth={500}
		>
			{({ closeModal }) => (
				<form className={styles.container} onSubmit={handleSubmit(form => onSubmit(form, closeModal))}>
					<div className={styles.attentionCard}>
						<img className={styles.warningImage} src={dangerIcon} alt="Danger Icon" />
						<div>
							<h3 className={styles.cardTitle}>{t('Important')}</h3>
							<p className={styles.cardDescription}>
								{t(
									'If the invoice amount matches the payment, the invoice status changes to paid. If there is a difference, the balance is not equal to zero.'
								)}
							</p>
						</div>
					</div>
					<section className={styles.sectionCenter}>
						<TextFieldNumber
							disabled={isLoading}
							mb={20}
							label="Paid in €"
							name="paid"
							control={control}
							placeholder={data.amount ? customNumberFormat(data.amount) : '0,00'}
							error={errors.paid}
						/>
						<Btn
							loading={isLoading}
							width={337}
							type="submit"
							icon="arrow"
							iconSize={{ w: 10, h: 10 }}
							iconColor="#fff"
						>
							{t('save')}
						</Btn>
					</section>
				</form>
			)}
		</BaseModal>
	)
}
