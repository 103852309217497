import { connect } from 'react-redux'

export default function injectRole(WrappedComponent, stateToProps = () => ({})) {
	const mapStateToProps = state => ({
		role: state.user.role,
		schoolTypes: state.options.schoolTypes,
		branch: state.options.branch,

		...stateToProps(state)
	})

	return connect(mapStateToProps)(WrappedComponent)
}
