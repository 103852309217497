import PropTypes from 'prop-types'
import styles from './style.module.scss'
import FlatLink from '../FlatLink'
import Icon from '../Icon'
import clsx from 'clsx'
import BtnAdd from '../BtnAdd'

export default function QuickList(props) {
	return (
		<div className={clsx(styles.root, props.className)}>
			<div className={styles.header}>
				{props.icon && <Icon noFill className={styles.icon} size={{ w: 22, h: 22 }} name="document" />}
				<h4 className={styles.title}>{props.title}</h4>
			</div>

			{props?.items?.length ? (
				<div className={styles.items}>{props.items.map(props.children)}</div>
			) : (
				<div className={styles.emptyBLock}>
					{props.onAdd && <BtnAdd />}
					{props.noMessages.map((message, index) => (
						<p key={index} className={styles.messageNo}>
							{message}
						</p>
					))}
				</div>
			)}

			<FlatLink
				className={styles.btnFullList}
				classNameIcon={styles.linkIcon}
				color="#303e65"
				iconColor="#008B9C"
				iconSize={{ w: 8, h: 8 }}
				to={props.toFullList}
				icon="arrow"
			>
				{props.linkTitle}
			</FlatLink>
		</div>
	)
}

QuickList.propTypes = {
	toFullList: PropTypes.string.isRequired,
	items: PropTypes.array.isRequired,
	children: PropTypes.func.isRequired,
	icon: PropTypes.bool,
	title: PropTypes.string.isRequired,
	linkTitle: PropTypes.string,

	onAdd: PropTypes.func,

	className: PropTypes.string,
	noMessages: PropTypes.arrayOf(PropTypes.string.isRequired)
}

QuickList.defaultProps = {
	icon: false,
	noMessages: [],
	linkTitle: 'Full List'
}
