import { useEffect, useState } from 'react'
import styles from './style.module.scss'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import Joi from 'joi'
import { joiResolver } from '@hookform/resolvers/joi'
import { toast } from 'react-toastify'

import MiniHeader from '../../UI/MiniHeader'
import Btn from '../../UI/Btn'
import SelectField from '../../UI/SelectField/index'
import TextField from '../../UI/TextField/index'

import http from '../../shared/http'
import { TRoutes, TApi, StateOptions } from '../../shared/const'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const schema = Joi.object({
	schoolName: Joi.string().min(2).max(64).required(),
	schoolType: Joi.string().required(),
	schoolState: Joi.string().required(),
	street: Joi.string().required(),
	number: Joi.number().min(1).max(1000).required(),
	zipCode: Joi.number().min(1).required(),
	location: Joi.string().min(2).max(64).required()
})

function CreateSchool(props) {
	const { t } = useTranslation()
	const { id } = useParams()
	const location = useLocation()
	const path = location.pathname

	const {
		register,
		control,
		handleSubmit,
		setValue,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema)
	})

	const history = useHistory()
	const [isLoading, setLoading] = useState(false)

	const onSubmit = data => {
		setLoading(true)

		const schema = {
			name: data.schoolName,
			type: data.schoolType,
			streetName: data.street,
			number: data.number,
			zipCode: data.zipCode,
			location: data.location,
			state: data.schoolState
		}

		const request =
			path === TRoutes.CREATE_SCHOOL
				? http.post(TApi.CREATE_SCHOOL, schema)
				: http.put(TApi.ADMIN_SCHOOL_ID(id), schema)

		request
			.then(() => {
				toast.success(
					path === TRoutes.CREATE_SCHOOL
						? t('School has been created successfully.')
						: t('School has been updated successfully.')
				)
				history.push(TRoutes.SCHOOLS)
			})
			.finally(() => setLoading(false))
	}

	const fetchSchoolData = () => {
		setLoading(true)
		http.get(TApi.ADMIN_SCHOOL_ID(id))
			.then(({ data }) => {
				setValue('schoolName', data.name)
				setValue('schoolType', data.type)
				setValue('schoolState', data.state)
				setValue('street', data.streetName)
				setValue('number', data.number)
				setValue('zipCode', data.zipCode)
				setValue('location', data.location)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	useEffect(() => {
		path !== TRoutes.CREATE_SCHOOL && fetchSchoolData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className={styles.wrapper}>
			<div className={styles.root}>
				<MiniHeader title={t('Create new school')} bg="#f8faff" />
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className={styles.section}>
						<div className={styles.sectionContent}>
							<div className={styles.flexContainer}>
								<SelectField
									required
									disabled={isLoading}
									mb={30}
									label={t('School Types')}
									control={control}
									name="schoolType"
									error={errors.schoolType}
									placeholder={t('Select')}
									options={props.schoolTypes?.map(v => ({
										value: v.value,
										label: v.value
									}))}
								/>

								<TextField
									required
									disabled={isLoading}
									mb={30}
									label={t('School Name')}
									placeholder={t('School Name')}
									error={errors.schoolName}
									register={register('schoolName')}
								/>
							</div>

							<div className={styles.flexContainer}>
								<TextField
									required
									disabled={isLoading}
									mb={30}
									label={t('Street')}
									placeholder={t('Street')}
									error={errors.street}
									register={register('street')}
								/>

								<TextField
									required
									disabled={isLoading}
									mb={30}
									label={t('Nummer')}
									placeholder={t('Nummer')}
									error={errors.number}
									register={register('number')}
								/>
							</div>
							<div className={styles.flexContainer}>
								<TextField
									required
									disabled={isLoading}
									mb={30}
									label={t('Zip Code')}
									placeholder={t('Zip Code')}
									error={errors.zipCode}
									register={register('zipCode')}
								/>

								<TextField
									required
									disabled={isLoading}
									mb={30}
									label={t('City')}
									placeholder={t('City')}
									error={errors.location}
									register={register('location')}
								/>
							</div>
							<SelectField
								required
								disabled={isLoading}
								mb={20}
								label={t('state')}
								control={control}
								name="schoolState"
								error={errors.schoolState}
								placeholder={t('Select')}
								options={StateOptions.map(v => ({
									label: t(v.label),
									value: v.value
								})).sort((a, b) => a.label.localeCompare(b.label))}
							/>
						</div>
					</div>
					<div className={styles.btnCreate}>
						<Btn
							loading={isLoading}
							width={214}
							type="submit"
							icon="arrow"
							iconSize={{ w: 10, h: 10 }}
							iconColor="#fff"
						>
							{t('save')}
						</Btn>
					</div>
				</form>
			</div>
		</div>
	)
}
CreateSchool.propTypes = { schoolTypes: PropTypes.array.isRequired }
const mapStateToProps = state => ({
	schoolTypes: state.options.schoolTypes
})
export default connect(mapStateToProps)(CreateSchool)
