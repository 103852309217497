import clsx from 'clsx'
import Select from 'react-select'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import { AsyncPaginate } from 'react-select-async-paginate'
import { connect } from 'react-redux'

import styles from './style.module.scss'
import { convertValuesToOptions } from '../../shared/helpers'

function SelectField(props) {
	const CSelect = props.async ? AsyncPaginate : Select

	const convertValue = field => {
		if (props.isMulti && props.options && props.valuesToOptions)
			return field.value && convertValuesToOptions(field.value, props.options)
		if (props.options) return props.options.find(item => item.value === field.value)

		return field.value
	}

	return (
		<label style={props.mb ? { marginBottom: props.mb } : {}} className={clsx(styles.root, props.className)}>
			{
				<span className={styles.inputName}>
					{props.label} {props.required ? <span className={styles.require}>*</span> : ''}
				</span>
			}
			<Controller
				name={props.name}
				control={props.control}
				defaultValue={props.defaultValue}
				render={({ field }, ref) => {
					return (
						<CSelect
							isDisabled={props.disabled || props.isTeacherStudentView}
							{...field}
							isMulti={props.isMulti}
							cacheUniqs={props.cacheUniqs}
							formatOptionLabel={props.formatOptionLabel}
							onChange={option => {
								if (!option) return field.onChange('')
								if (!props.options) return field.onChange(option)
								if (props.isMulti)
									return field.onChange(option.length ? option.map(item => item.value) : null)
								field.onChange(option.value)
								props.onChange(option)
							}}
							value={convertValue(field)}
							className={styles.select}
							placeholder={props.placeholder}
							isSearchable={props.isSearchable}
							isClearable={props.isClearable}
							classNamePrefix="select"
							options={props.options}
							debounceTimeout={props.debounceTimeout}
							loadOptions={props.loadOptions}
							ref={ref}
						/>
					)
				}}
			/>
			{props.error && <span className={styles.error}>{props.error.message}</span>}
		</label>
	)
}

SelectField.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	control: PropTypes.object.isRequired,
	placeholder: PropTypes.string.isRequired,

	mb: PropTypes.number,
	async: PropTypes.bool,
	error: PropTypes.object,
	isMulti: PropTypes.bool,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	onChange: PropTypes.func,
	cacheUniqs: PropTypes.array,
	loadOptions: PropTypes.func,
	isSearchable: PropTypes.bool,
	isClearable: PropTypes.bool,
	required: PropTypes.bool,
	valuesToOptions: PropTypes.bool,
	debounceTimeout: PropTypes.number,
	formatOptionLabel: PropTypes.func,

	options: PropTypes.shape({
		value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
		label: PropTypes.string.isRequired
	}),
	isTeacherStudentView: PropTypes.bool.isRequired
}

SelectField.defaultProps = {
	async: false,
	isMulti: false,
	disabled: false,
	isSearchable: false,
	isClearable: false,
	valuesToOptions: false,
	required: PropTypes.bool,
	onChange: () => {}
}

const mapStateToProps = state => ({
	isTeacherStudentView: state.user.isTeacherStudentView
})

export default connect(mapStateToProps)(SelectField)
