import Joi from 'joi'
import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { joiResolver } from '@hookform/resolvers/joi'

import Btn from '../../../../../../UI/Btn'
import SelectField from '../../../../../../UI/SelectField'

import styles from '../style.module.scss'

const schema = Joi.object({
	companyPresentationType: Joi.string().valid('ACTIVATED', 'NOT_ACTIVATED', 'IS_NEED_REVIEW').required()
})

export default function CompanyPresentationType(props) {
	const { t } = useTranslation()
	const {
		reset,
		control,
		handleSubmit,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema)
	})

	const onSubmit = form => {
		console.log(form)
		props
			.updateOptions({ name: 'companyPresentationType', value: form.companyPresentationType })
			.then(() => toast.success(t('Options was updated')))
	}

	const statusOptions = [
		{
			value: 'ACTIVATED',
			label: t('Activated')
		},
		{
			value: 'NOT_ACTIVATED',
			label: t('Registered')
		},
		{
			value: 'IS_NEED_REVIEW',
			label: t('To be checked')
		}
	]

	useEffect(() => {
		reset({ companyPresentationType: props.form?.companyPresentationType || 'approved' })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.form])
	return (
		<form id="companyPresentationType" className={styles.root} onSubmit={handleSubmit(onSubmit)}>
			<section className={styles.section}>
				<h3 className={styles.sectionTitle}>{t('Company Presentation Status')}</h3>
				<div className={styles.sectionContent}>
					<div className={styles.flexContainer}>
						<SelectField
							className={styles.field}
							label={t('Select the status of companies to be displayed')}
							options={statusOptions}
							control={control}
							name="companyPresentationType"
							error={errors.companyPresentationType}
						/>
					</div>
					<div className={styles.btnContainer}>
						<Btn
							className={styles.btn}
							type="submit"
							icon="arrow"
							iconSize={{ w: 10, h: 10 }}
							iconColor="#fff"
						>
							{t('save')}
						</Btn>
					</div>
				</div>
			</section>
		</form>
	)
}

CompanyPresentationType.propTypes = {
	form: PropTypes.object.isRequired,
	updateOptions: PropTypes.func.isRequired
}
