import PropTypes from 'prop-types'
import clsx from 'clsx'
import { connect } from 'react-redux'
import React, { useEffect } from 'react'
import ReactTooltip from 'react-tooltip'
import { ToastContainer } from 'react-toastify'

import Navbar from './components/Navbar'
import Modals from './components/Modals'
import Routes from './components/Routers'

import http from './shared/http'
import { TApi } from './shared/const'

import 'normalize.css/normalize.css'

import 'react-toastify/dist/ReactToastify.css'
import 'react-circular-progressbar/dist/styles.css'
import 'react-datepicker/dist/react-datepicker.css'

import style from './App.module.scss'
import { logIn } from './redux/ducks/user'
import { addOptions } from './redux/ducks/options'
import AlertTeacherMode from './components/AlertTeacherMode'

function App(props) {
	useEffect(() => {
		http.get(TApi.COMMON_ME, {
			disableToast: true
		}).then(({ data }) => {
			props.logIn({
				role: data.role,
				profile: data
			})
		})
		http.get(TApi.COMMON_GET_OPTIONS).then(({ data }) => props.addOptions(data))

		/* eslint-disable-next-line */
	}, [])

	return (
		<div className={clsx(style.root, props.isTeacherStudentView && style.teacherMode)}>
			{props.isTeacherStudentView && <AlertTeacherMode />}
			<Navbar />
			<Routes />
			<Modals />
			<ReactTooltip />
			<ToastContainer />
		</div>
	)
}

App.propTypes = {
	logIn: PropTypes.func.isRequired,
	addOptions: PropTypes.func.isRequired,
	isTeacherStudentView: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
	isAuth: state.user.isAuth,
	isTeacherStudentView: state.user.isTeacherStudentView
})
const mapDispatchToProps = dispatch => ({
	logIn: data => dispatch(logIn(data)),
	addOptions: data => dispatch(addOptions(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
