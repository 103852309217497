import { useEffect } from 'react'
import styles from '../style.module.scss'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import Joi from 'joi'
import http from '../../../../../shared/http'
import { TApi } from '../../../../../shared/const'
import { schemaOptions } from '../../../../../shared/i18n'
import { joiResolver } from '@hookform/resolvers/joi'

import TextField from '../../../../../UI/TextField'
import { useTranslation } from 'react-i18next'

const schema = Joi.object({
	email: Joi.string().email({ tlds: false }).required()
})

function Contact(props) {
	const { t } = useTranslation()
	const {
		register,
		trigger,
		handleSubmit,
		setValue,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema, {
			errors: {
				labels: false,
				language: localStorage.getItem('i18nextLng')
			},
			...schemaOptions
		})
	})

	useEffect(() => {
		if (Object.keys(errors).length) trigger()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localStorage.getItem('i18nextLng')])

	useEffect(() => {
		setValue('email', props.email)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.email])

	const onSubmit = data => {
		http.put(TApi.STUDENT_EMAIL_CHANGE, { email: data.email.toLowerCase() }).then(() => {
			toast.success(t('Letter has been sent to confirm email changes.'))
		})
	}

	return (
		<div className={styles.root}>
			<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
				<div className={styles.section}>
					<h1 className={styles.sectionTitle}>{t('contacts')}</h1>
					<div className={styles.sectionContent}>
						<div className={styles.flexContainer}>
							<TextField
								disabled
								mb={20}
								label={t('Your email')}
								placeholder="student@gmail.com"
								error={errors.email}
								register={register('email')}
							/>
							{/*
								<div className={styles.btnContainer}>
								<Btn
									className={styles.btnWithoutIcon}
									loading={isLoading}
									type="submit"
									icon="arrow"
									iconSize={{ w: 10, h: 10 }}
									iconColor="#fff">
									{t('save')}
								</Btn>
							</div>
								 */}
						</div>
					</div>
				</div>
			</form>
		</div>
	)
}
const mapStateToProps = function (state) {
	return {
		email: state.user.profile.email
	}
}

export default connect(mapStateToProps)(Contact)
