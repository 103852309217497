import { useTranslation } from 'react-i18next'
import PolicyLinks from '../../../../UI/policyLinks'
import styles from '../../common.module.scss'
import image from '../../../../assets/images/waitInvitationImage.jpg'

export default function AwaitInvatation() {
	const { t } = useTranslation()
	return (
		<div className={styles.root}>
			<PolicyLinks />
			<div className={styles.AwaitContainer}>
				{/* <h1 className={styles.confirmHeader}>{t('Please wait')}...</h1> */}
				<img src={image} alt="" />
				<span className={styles.confirmText}>
					{t(
						'Please log out and wait until you are invited by a member of an existing company. After being invited and logging in again, you will be immediately assigned to your joint company as an employee.'
					)}
				</span>
			</div>
		</div>
	)
}
