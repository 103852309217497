import clsx from 'clsx'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Icon from '../Icon'

import styles from './style.module.scss'

function TextField(props) {
	const [type, setType] = useState(props.type ? props.type : 'text')

	const handlePasswordVisibility = () => {
		type === 'password' ? setType('text') : setType('password')
	}

	return (
		<label style={props.mb ? { marginBottom: props.mb } : {}} className={clsx(styles.root, props.className)}>
			{props.label && (
				<span className={styles.inputName}>
					{props.label} {props.required ? <span className={styles.require}>*</span> : ''}
				</span>
			)}
			<input
				readOnly={props.readOnly}
				value={props.value}
				type={type}
				disabled={props.disabled || props.isTeacherStudentView}
				className={clsx(styles.input, props.classNameInput)}
				{...(props.register || {})}
				onChange={e => {
					props.register.onChange(e)
					props.onChange(e)
				}}
				placeholder={props.placeholder}
			/>
			{props.type === 'password' && (
				<button
					onClick={handlePasswordVisibility}
					type="button"
					className={clsx(styles.passwordBtn, {
						[styles.passwordRepeatBtnError]:
							props.error?.message === `"Repeat password" does not match with "Password"`,
						[styles.passwordBtnError]:
							props.error?.message === `"password" length must be at least 8 characters long`
					})}
				>
					<Icon size={{ w: 20, h: 20 }} color="#008B9C" noFill name={type === 'password' ? 'show' : 'hide'} />
				</button>
			)}
			{props.error && <span className={clsx(styles.error, props.classNameError)}>{props.error.message}</span>}
		</label>
	)
}

TextField.propTypes = {
	type: PropTypes.string,
	label: PropTypes.string,
	error: PropTypes.object,
	register: PropTypes.object,
	onChange: PropTypes.func,
	placeholder: PropTypes.string,
	mb: PropTypes.number,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	required: PropTypes.bool,
	classNameInput: PropTypes.string,
	classNameError: PropTypes.string,
	isTeacherStudentView: PropTypes.bool.isRequired
}

TextField.defaultProps = {
	disabled: false,
	onChange: e => {}
}

const mapStateToProps = state => ({
	isTeacherStudentView: state.user.isTeacherStudentView
})

export default connect(mapStateToProps)(TextField)
