import { useMemo, useEffect, useState } from 'react'
import styles from './style.module.scss'
import http from '../../../../shared/http'
import { TApi } from '../../../../shared/const'
import { downloadFile, getFormattedDate } from '../../../../shared/helpers'
import { toast } from 'react-toastify'
import MiniHeader from '../../../../UI/MiniHeader'
import Table from '../../../../UI/Table'
import graphIcon from '../../../../assets/icons/graph.svg'
import Btn from '../../../../UI/Btn'

import eventEmitter, { types } from '../../../../shared/eventEmitter'
import BtnTooltip from '../../../../UI/BtnTooltip'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

function Shares(props) {
	const { t } = useTranslation()
	const [shares, setShares] = useState([])
	const [countAll, setCountAll] = useState(0)
	const [page, setPage] = useState(0)

	const [isLoading, setLoading] = useState(false)
	const [sort, setSort] = useState({ name: 'firstName', direction: 'asc' })
	const limit = 10

	const fetchShares = offset => {
		http.get(TApi.STUDENT_GET_SHARES, {
			params: {
				limit,
				offset,
				order: sort.name,
				direction: sort.direction
			}
		}).then(({ data }) => {
			setShares(data.rows)
			setCountAll(data.count)
		})
	}

	useEffect(() => {
		fetchShares(page * limit)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, sort])

	const handleSort = (name, direction) => {
		setSort({ name, direction })
	}
	const downoload = name => {
		http.get(TApi.STUDENT_DOWNOLOAD_SHARE_CONSENT(name), {
			headers: {
				'Content-Type': 'application/pdf'
			},
			responseType: 'blob'
		}).then(({ data }) => {
			downloadFile(name, data)
		})
	}

	const remove = id => {
		setLoading(true)
		http.delete(TApi.STUDENT_DELETE_SHARE(id))
			.then(() => {
				toast.success('Shareholder has been removed successfuly.')
				fetchShares()
			})
			.finally(() => setLoading(false))
	}

	const columns = useMemo(
		() => [
			{
				Header: () => {
					return <span className={styles.tableTitleShare}>{t('Share')}</span>
				},
				accessor: 'share',
				Cell: ({ row }) => {
					return (
						<div className={styles.btnTooltipWrapper}>
							<div className={styles.shareContainer}>
								<img className={styles.sharetIcon} src={graphIcon} alt="" />
								<span className={styles.share}>{row.original.companyShare}€</span>
							</div>
							{!props.isTeacherStudentView && (
								<BtnTooltip
									items={[
										{
											label: t('profile'),
											onClick: () => {
												eventEmitter.emit(
													types.openAddShareholderModal,
													null,
													fetchShares,
													row.original,
													true
												)
											}
										},
										{ label: 'Delete', onClick: () => remove(row.original.id) }
									]}
								/>
							)}
						</div>
					)
				}
			},
			{
				Header: t('Shareholder'),
				accessor: 'firstName',
				Cell: ({ row }) => {
					return (
						<p className={styles.shareholder}>
							{row.original.firstName} {row.original.lastName}
						</p>
					)
				}
			},
			{
				Header: t('Date'),
				accessor: 'date',
				Cell: ({ row }) => {
					return (
						<div className={styles.dateContainer}>
							<span className={styles.date}>{getFormattedDate(row.original.createdAt)}</span>
							<Btn
								theme="download"
								icon="download"
								iconSize={{ w: 24, h: 24 }}
								onClick={() => downoload(row.original.consentDocumentHashName)}
							/>
						</div>
					)
				}
			}
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[shares, isLoading, t]
	)

	return (
		<div className={styles.root}>
			<MiniHeader className={styles.header} title={t('Company Shares')} bg="#f8faff">
				{!props.isTeacherStudentView && (
					<Btn
						className={styles.addShareBtn}
						iconClassName={styles.addShareBtnIcon}
						icon="plus"
						iconColor="#fff"
						theme="orange"
						onClick={() => {
							eventEmitter.emit(types.openAddShareholderModal, null, fetchShares)
						}}
					>
						{t('Add Shareholder')}
					</Btn>
				)}
			</MiniHeader>
			<div className="container">
				<Table
					columns={columns}
					items={shares}
					limit={limit}
					countAll={countAll}
					onChange={setPage}
					handleSort={handleSort}
				/>
			</div>
		</div>
	)
}

Shares.propTypes = {
	isTeacherStudentView: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
	isTeacherStudentView: state.user.isTeacherStudentView
})

export default connect(mapStateToProps)(Shares)
