import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Pagination from '../../../../UI/Pagination'

import http from '../../../../shared/http'
import { TApi } from '../../../../shared/const'

import styles from './style.module.scss'
import injectRole from '../../../HOC/injectRole'
import { connect } from 'react-redux'
import { differenceInCalendarDays, formatDistance, parseISO } from 'date-fns'
import NotificationItem from '../../../../UI/NotificationItem'
import { updateProfile } from '../../../../redux/ducks/user'
import MiniHeader from '../../../../UI/MiniHeader'

function Notifications(props) {
	const { t } = useTranslation()
	const [notifications, setNotifications] = useState([])
	const [notificationCount, setNotificationCount] = useState(0)
	const [page, setPage] = useState(0)
	const limit = 8

	const getSectionDate = index => {
		const currItem = notifications[index]
		const prevItem = notifications[index - 1]

		if (index && !differenceInCalendarDays(parseISO(currItem.createdAt), parseISO(prevItem.createdAt))) return null

		return formatDistance(new Date(), parseISO(currItem.createdAt)) + ' ago'
	}

	const fetchNotifications = offset => {
		http.get(TApi.COMMON_GET_NOTIFICATIONS, {
			params: {
				limit,
				offset
			}
		}).then(({ data }) => {
			setNotifications(data.rows)
			setNotificationCount(data.count)
		})
	}

	useEffect(() => {
		fetchNotifications(page * limit)
		/* eslint-disable-next-line */
	}, [page])

	useEffect(() => {
		if (notifications.length) {
			const notSeenIds = notifications.filter(item => item.isViewed === false).map(item => item.id)

			if (notSeenIds.length)
				http.put(TApi.COMMON_MARK_READ_NOTIFICATIONS, { notSeenIds }).then(({ data }) => {
					props.updateProfile({ notifications: { messages: data.count } })
				})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [notifications, page])

	return (
		<div className={styles.root}>
			<MiniHeader title={t('Messages')} bg="#f8faff" />
			{!notifications.length && (
				<div className={styles.empty}>
					<p className={styles.emptyDescription}>{t('You have no notifications at this time')}</p>
				</div>
			)}
			{notificationCount > limit && (
				<div style={{ margin: '20px 20px 0 40px' }}>
					<Pagination
						page={page}
						label={props.paginationLabel}
						countAll={notificationCount}
						limit={limit}
						onChange={setPage}
					/>
				</div>
			)}
			{notifications?.map((item, index) => {
				console.log(item)
				return (
					<div className={styles.messageList} key={item.id}>
						<div className={styles.dateBlock}>{getSectionDate(index)}</div>
						<NotificationItem
							notification={{
								createdAt: item.createdAt,
								info: item.info,
								type: item.type
							}}
						/>
					</div>
				)
			})}
			{notificationCount > limit && (
				<div style={{ margin: '20px 20px 0 40px' }}>
					<Pagination
						page={page}
						label={props.paginationLabel}
						countAll={notificationCount}
						limit={limit}
						onChange={setPage}
					/>
				</div>
			)}
		</div>
	)
}

Notifications.propTypes = {
	updateProfile: PropTypes.func
}

Notifications.defaultProps = {
	className: '',
	mini: true
}

const mapDispatchToProps = dispatch => ({
	updateProfile: value => dispatch(updateProfile(value))
})

export default injectRole(connect(null, mapDispatchToProps)(Notifications))
