import PropTypes from 'prop-types'
import { useState } from 'react'

import stroke from '../../../assets/icons/Stroke.svg'
import Btn from '../../Btn'

import styles from './style.module.scss'

export default function ProgressBar(props) {
	const [itemIndex, setItemIndex] = useState(0)
	if (props.isActive) {
		return (
			<div className={styles.root} style={{ left: `${props.position}%` }}>
				<div className={styles.stepTitleFulfilled}>
					<div className={styles.stepTitle}>{props.title}</div>
					<img className={styles.strokeImg} src={stroke} alt=" " />
				</div>

				<div className={styles.fulfilledStep} />
			</div>
		)
	}

	return (
		<div className={styles.root} style={{ left: `${props.position}%` }}>
			<div className={styles.stepTitle}>{props.title}</div>
			<div className={styles.pendingStep}>
				{props.items && (
					<div className={styles.popup}>
						<h3 className={styles.popupText}>{props.items[itemIndex].value}</h3>
						<div className={styles.btnContainer}>
							<Btn
								className={styles.popupBtn}
								iconClassName={styles.firstBtnIcon}
								theme="download"
								icon="arrow"
								onClick={() => itemIndex > 0 && setItemIndex(itemIndex - 1)}
							/>
							<p className={styles.pageNumber}>
								{itemIndex + 1}/{props.items.length}
							</p>
							<Btn
								className={styles.popupBtn}
								theme="download"
								icon="arrow"
								onClick={() => itemIndex < props.items.length - 1 && setItemIndex(itemIndex + 1)}
							/>
						</div>
					</div>
				)}
			</div>
			{props.items && (
				<div className={styles.underStep}>
					{/* <Btn
						className={styles.popupBtn}
						iconClassName={styles.firstBtnIcon}
						theme="download"
						icon="arrow"
						onClick={() => itemIndex > 0 && setItemIndex(itemIndex - 1)}
					/> */}
					<p className={styles.pageNumber}>
						{itemIndex + 1}/{props.items.length}
					</p>
					{/* <Btn
						className={styles.popupBtn}
						theme="download"
						icon="arrow"
						onClick={() => itemIndex < props.items.length - 1 && setItemIndex(itemIndex + 1)}
					/> */}
				</div>
			)}
		</div>
	)
}

ProgressBar.propTypes = {
	position: PropTypes.number.isRequired,
	isActive: PropTypes.bool,
	title: PropTypes.string.isRequired
}
