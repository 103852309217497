import React from 'react'
import styles from '../../style.module.scss'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import Btn from '../../../../../../../../UI/Btn'
import dangerIcon from '../../../../../../../../assets/icons/Danger Triangle.svg'
import clsx from 'clsx'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import TextFieldNumber from '../../../../../../../../UI/TextFieldNumber'
import { connect } from 'react-redux'
import Decimal from 'decimal.js'
// TODO: Clean up
function CompanyAdvanceReturn(props) {
	const { t } = useTranslation()
	const {
		control,
		watch,
		reset,
		handleSubmit,
		getValues,
		formState: { errors }
	} = useForm()
	console.log('Form errors:', errors)
	const [formShowed, setShowed] = useState(0)
	const [formExpensesShow, setFormExpensesShow] = useState(0)
	const [formOtherExpenses, setFormOtherExpenses] = useState(0)
	const advanceReturnValueAddedTaxWatch = watch('advanceReturnValueAddedTax')

	const onSubmit = form => {
		console.log('Submitting form data:', form)
		let data = form
		data.totalGrossRevenue = form.advanceVatReturnTotalIncome
		delete data.revenues
		delete data.materialExpensesItems
		delete data.otherExpensesItems

		props.updateData(data).then(() => {
			toast.success(t('Statement of revenues and expenses were updated'))
			props.handlerNextStep()
		})
	}
	useEffect(() => {
		console.log('Received form data from props:', props.form) // Add this line

		if (props.form?.revenues) {
			setShowed(props.form?.revenues?.length)
		}
		if (props.form?.materialExpensesItems) {
			setFormExpensesShow(props.form?.materialExpensesItems?.length)
		}
		if (props.form?.otherExpensesItems) {
			setFormOtherExpenses(props.form?.otherExpensesItems?.length)
		}
		const turnoverTotal =
			props.form?.revenues?.reduce((p, v) => {
				if (v.turnoverTotal !== undefined) {
					return new Decimal(p).plus(v.turnoverTotal).toNumber()
				} else {
					return p
				}
			}, 0) || 0

		// VAT of Percent calculation
		const turnoverTotalVat =
			props.form?.revenues?.reduce((p, v) => {
				if (v.vatOfPercent !== undefined) {
					return new Decimal(p).plus(v.vatOfPercent).toNumber()
				} else {
					return p
				}
			}, 0) || 0

		// Calculation of advanceVatReturnTotalIncome and advanceVatReturnTotalTaxedIncome
		const advanceVatReturnTotalIncome = turnoverTotal !== undefined ? new Decimal(turnoverTotal).toNumber() : 0
		const advanceVatReturnTotalTaxedIncome =
			turnoverTotalVat !== undefined ? new Decimal(turnoverTotalVat).toNumber() : 0

		const materialExpensesTotal =
			props.form?.materialExpensesItems?.reduce((p, v) => {
				if (v.materialExpensesTotal && v.vat) {
					return new Decimal(p).plus(v.materialExpensesTotal).toNumber()
				} else {
					return p
				}
			}, 0) || 0

		const otherExpensesTotal =
			props.form?.otherExpensesItems?.reduce((p, v) => {
				if (v.otherExpensesTotal && v.vat) {
					return new Decimal(p).plus(v.otherExpensesTotal).toNumber()
				} else {
					return p
				}
			}, 0) || 0

		const materialExpensesTotalVat =
			props.form?.materialExpensesItems?.reduce((p, v) => {
				if (v.vatOfPercent) {
					return new Decimal(p || 0).plus(v.vatOfPercent).toNumber()
				} else {
					return p || 0
				}
			}, 0) || 0

		const otherExpensesTotalVat =
			props.form?.otherExpensesItems?.reduce((p, v) => {
				if (v.vatOfPercent) {
					return new Decimal(p || 0).plus(v.vatOfPercent).toNumber()
				} else {
					return p || 0
				}
			}, 0) || 0

		// Compute the sum of materialExpensesTotalVat and otherExpensesTotalVat
		const advanceVatReturnTotalTaxedExpenses = new Decimal(materialExpensesTotalVat)
			.plus(otherExpensesTotalVat)
			.toNumber()
		let advanceVatReturnTotalExpenses = new Decimal(0)

		// iterate over otherExpensesItems
		if (props.form?.otherExpensesItems) {
			for (let i = 0; i < props.form.otherExpensesItems.length; i++) {
				let otherExpensesTotal = props.form.otherExpensesItems[i]?.otherExpensesTotal || 0
				advanceVatReturnTotalExpenses = advanceVatReturnTotalExpenses.plus(new Decimal(otherExpensesTotal))
			}
		}

		// iterate over materialExpensesItems
		if (props.form?.materialExpensesItems) {
			for (let i = 0; i < props.form.materialExpensesItems.length; i++) {
				let materialExpensesTotal = props.form.materialExpensesItems[i]?.materialExpensesTotal || 0
				advanceVatReturnTotalExpenses = advanceVatReturnTotalExpenses.plus(new Decimal(materialExpensesTotal))
			}
		}

		advanceVatReturnTotalExpenses = advanceVatReturnTotalExpenses.toDecimalPlaces(2)

		// Convert back to a JavaScript number
		let totalExpensesJSNumber = advanceVatReturnTotalExpenses.toNumber()

		console.log('turnoverTotal:', turnoverTotal)
		console.log('turnoverTotalVat:', turnoverTotalVat)
		console.log('materialExpensesTotal:', materialExpensesTotal)
		console.log('materialExpensesTotalVat:', materialExpensesTotalVat)
		console.log('otherExpensesTotal:', otherExpensesTotal)
		console.log('otherExpensesTotalVat:', otherExpensesTotalVat)

		reset({
			revenues: props.form?.revenues,
			materialExpensesItems: props.form?.materialExpensesItems,
			otherExpensesItems: props.form?.otherExpensesItems,
			advanceVatReturnTotalIncome: advanceVatReturnTotalIncome,
			advanceVatReturnTotalTaxedIncome: advanceVatReturnTotalTaxedIncome,
			advanceVatReturnNetIncomeTotal: advanceVatReturnTotalIncome - advanceVatReturnTotalTaxedIncome,
			advanceVatReturnTotalExpenses: totalExpensesJSNumber,
			advanceVatReturnTotalTaxedExpenses: advanceVatReturnTotalTaxedExpenses,
			advanceVatReturnNetExpenses: advanceVatReturnTotalExpenses - advanceVatReturnTotalTaxedExpenses,
			advanceReturnValueAddedTax: new Decimal(turnoverTotalVat)
				.minus(materialExpensesTotalVat)
				.minus(otherExpensesTotalVat)
				.toNumber()
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.form])

	if (props.currentStep !== props.needStep) return null

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<section className={styles.section}>
				<h3 className={styles.sectionTitle}>{t('Statement of revenues')}</h3>
				<div className={styles.sectionContent}>
					{[...Array(formShowed).keys()].map(i => (
						<div key={i} className={styles.flexContainer}>
							<TextFieldNumber
								mb={20}
								name={`revenues[${i}].turnoverTotal`}
								disabled
								control={control}
								placeholder="00.000,00"
								error={errors.revenues?.[i]?.turnoverTotal}
								label={`${t('Sales in € incl.')} ${getValues(`revenues[${i}].vat`)}${t('% VAT.')}`}
							/>
							<TextFieldNumber
								mb={20}
								name={`revenues[${i}].vatOfPercent`}
								disabled
								control={control}
								placeholder="00.000,00"
								error={errors.revenues?.[i]?.vatOfPercent}
								label={`${t('of which')} ${getValues(`revenues[${i}].vat`)}${t('% VAT in €')}`}
							/>
						</div>
					))}
					<div className={styles.flexContainer}>
						<TextFieldNumber
							className={styles.threeInline}
							classNameInput={styles.calculated}
							mb={20}
							name="advanceVatReturnTotalIncome"
							disabled
							control={control}
							placeholder="00.000,00"
							error={errors.advanceVatReturnTotalIncome}
							label={t('Total gross sales in €')}
						/>

						<TextFieldNumber
							mb={20}
							className={styles.threeInline}
							classNameInput={styles.calculated}
							name="advanceVatReturnTotalTaxedIncome"
							disabled
							control={control}
							placeholder="00.000,00"
							error={errors.advanceVatReturnTotalTaxedIncome}
							label={t('Total sales tax')}
						/>
						<TextFieldNumber
							disabled
							className={styles.threeInline}
							classNameInput={styles.calculated}
							mb={20}
							label={t('Turnover net total in €')}
							name="advanceVatReturnNetIncomeTotal"
							control={control}
							placeholder="00.000,00"
							error={errors.advanceVatReturnNetIncomeTotal}
						/>
					</div>
				</div>
			</section>

			<section className={styles.section}>
				<h3 className={styles.sectionTitle}>{t('List of tasks')}</h3>
				<div className={styles.sectionContent}>
					{[...Array(formExpensesShow).keys()].map(i => (
						<div key={i} className={styles.flexContainer}>
							<TextFieldNumber
								disabled
								mb={20}
								label={`${t('Material expenses in € incl.')}  ${getValues(
									`materialExpensesItems[${i}].vat`
								)}${t('% VAT.')}`}
								name={`materialExpensesItems[${i}].materialExpensesTotal`}
								control={control}
								placeholder="00.000,00"
								error={errors.materialExpensesItems?.[i]?.materialExpensesTotal}
							/>
							<TextFieldNumber
								disabled
								mb={20}
								label={`${t('of which')} ${getValues(`materialExpensesItems[${i}].vat`)}${t(
									'% VAT in €'
								)}`}
								name={`materialExpensesItems[${i}].vatOfPercent`}
								control={control}
								placeholder="00.000,00"
								error={errors.materialExpensesItems?.[i]?.vatOfPercent}
							/>
						</div>
					))}
					{[...Array(formOtherExpenses).keys()].map(i => (
						<div key={i} className={styles.flexContainer}>
							<TextFieldNumber
								disabled
								mb={20}
								label={`${t('Other expenses in € incl.')} ${getValues(
									`otherExpensesItems[${i}].vat`
								)}${t('% VAT.')}`}
								name={`otherExpensesItems[${i}].otherExpensesTotal`}
								control={control}
								placeholder="00.000,00"
								error={errors.otherExpensesItems?.[i]?.otherExpensesTotal}
							/>

							<TextFieldNumber
								disabled
								mb={20}
								label={`${t('of which')} ${getValues(`otherExpensesItems[${i}].vat`)}${t(
									'% VAT in €'
								)}`}
								name={`otherExpensesItems[${i}].vatOfPercent`}
								control={control}
								placeholder="00.000,00"
								error={errors.otherExpensesItems?.[i]?.vatOfPercent}
							/>
						</div>
					))}

					<div className={styles.flexContainer}>
						<TextFieldNumber
							disabled
							className={styles.threeInline}
							classNameInput={styles.calculated}
							mb={20}
							name="advanceVatReturnTotalExpenses"
							control={control}
							placeholder="00.000,00"
							error={errors.advanceVatReturnTotalExpenses}
							label={t('Total gross costs in €')}
						/>
						<TextFieldNumber
							disabled
							className={styles.threeInline}
							classNameInput={styles.calculated}
							mb={20}
							name="advanceVatReturnTotalTaxedExpenses"
							control={control}
							placeholder="00.000,00"
							error={errors.advanceVatReturnTotalTaxedExpenses}
							label={t('Input tax total in €')}
						/>
						<TextFieldNumber
							disabled
							className={styles.threeInline}
							classNameInput={styles.calculated}
							mb={20}
							name="advanceVatReturnNetExpenses"
							control={control}
							placeholder="00.000,00"
							error={errors.advanceVatReturnNetExpenses}
							label={t('Total net costs in €')}
						/>
					</div>
					<div className={styles.flexContainer}>
						<TextFieldNumber
							disabled
							classNameInput={styles.calculated}
							mb={20}
							name="advanceReturnValueAddedTax"
							label={t('Advance return of the value added tax (UVA)')}
							control={control}
							placeholder="00.000,00"
							error={errors.advanceReturnValueAddedTax}
						/>
						{props.showPayCredit && (
							<p className={styles.paymentCredit}>
								<img className={styles.warningIcon} src={dangerIcon} alt="" />
								{advanceReturnValueAddedTaxWatch > 0 ? t('Debit note') : t('Credit note')}
							</p>
						)}
					</div>

					<div className={clsx(styles.flexContainer, styles.btnContainer)}>
						{props.form?.isSubmitted || props.isTeacherStudentView ? (
							<>
								<Btn className={styles.btn} type="button" onClick={() => props.handlerPrevStep()}>
									{t('Previous')}
								</Btn>
								<Btn className={styles.btn} type="button" onClick={() => props.handlerNextStep()}>
									{t('Next')}
								</Btn>
							</>
						) : (
							<Btn
								disabled={props.form?.isLoading || props.form?.isSubmitted}
								className={styles.btn}
								type="submit"
								width={337}
							>
								{t('save')}
							</Btn>
						)}
					</div>
				</div>
			</section>
		</form>
	)
}

CompanyAdvanceReturn.propTypes = {
	form: PropTypes.object,
	updateData: PropTypes.func,

	currentStep: PropTypes.number,
	needStep: PropTypes.number,
	handlerNextStep: PropTypes.func,
	handlerPrevStep: PropTypes.func,
	isTeacherStudentView: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
	reportPeriod: state.user.profile.reportPeriod,
	isTeacherStudentView: state.user.isTeacherStudentView
})
export default connect(mapStateToProps, null)(CompanyAdvanceReturn)
