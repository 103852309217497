import ee from 'event-emitter'

const Emiter = function () {}

export default ee(Emiter.prototype)

export const types = {
	openAddAdmin: 'openAddAdmin',
	openAddFeedModal: 'openAddFeedModal',
	openAddExpertModal: 'openAddExpertModal',
	openDetailFeedModal: 'openDetailFeedModal',
	openAddTeacherModal: 'openAddTeacherModal',
	openMergeSchoolModal: 'openMergeSchoolModal',
	openMergeTeacherModal: 'openMergeTeacherModal',
	openAddEmployeeModal: 'openAddEmployeeModal',
	openCustomizeMultiModal: 'openCustomizeMultiModal',
	openAddShareholderModal: 'openAddShareholderModal',
	openCheckConfirmAccount: 'openCheckConfirmAccount',
	openCustomizeIncomeModal: 'openCustomizeIncomeModal',
	openCustomizeCompaniesModal: 'openCustomizeCompaniesModal',
	openRejectCompanyCommentModal: 'openRejectCompanyCommentModal',
	openCustomizeSimpleWidgetModal: 'openCustomizeSimpleWidgetModal',
	openCompanyRisk: 'openCompanyRisk',
	openCompanyProfile: 'openCompanyProfile',
	openAddSchool: 'openAddSchool',
	openAddTeacher: 'openAddTeacher',
	openStudentProfile: 'openStudentProfile',
	openInvoiceChangeData: 'openInvoiceChangeData',
	openStudentWorkAreaModal: 'openStudentWorkAreaModal',
	refreshEmployeesNavbar: 'refreshEmployeesNavbar',
	openCropperModal: 'openCropperModal'
}
