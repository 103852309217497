import { useEffect, useMemo, useState } from 'react'
import styles from './style.module.scss'
import { useTranslation } from 'react-i18next'

import Btn from '../../../../UI/Btn'
import MiniHeader from '../../../../UI/MiniHeader'
import Search from '../../../../UI/Search'
import Table from '../../../../UI/Table'
import businessPlanIcon from '../../../../assets/icons/businessPlan.svg'
import paperParentalIcon from '../../../../assets/icons/paperParental.svg'
import reportIcon from '../../../../assets/icons/reportIcon.svg'
import { DocumentOptions, TApi } from '../../../../shared/const'
import http from '../../../../shared/http'
import Filter from '../../../../UI/Filter'
import BtnFilter from '../../../../UI/BtnFilter'
import Pagination from '../../../../UI/Pagination'
import { setDocumentsFilter } from '../../../../redux/ducks/filter'
import { connect } from 'react-redux'
import { generateFilter } from '../../../../shared/helpers'

const documentTypesIcon = {
	BUSINESS_PLAN: businessPlanIcon,
	PARTICIPATION_DOCUMENT: businessPlanIcon,
	PARENTAL_CONFIRMATION: paperParentalIcon,
	CONSENT_DOCUMENT: businessPlanIcon,
	COMPANY_CERTIFICATE: businessPlanIcon,
	SHAREHOLDER_CERTIFICATE: businessPlanIcon,
	SHARE_TEMPLATE: businessPlanIcon,
	REPORT: reportIcon
}

function DocumentsList(props) {
	const { t } = useTranslation()
	const [documents, setDocuments] = useState([])
	const [countDocuments, setCountDocuments] = useState(0)
	const [page, setPage] = useState(0)
	const limit = 10
	// eslint-disable-next-line no-unused-vars
	const [company, setCompany] = useState({})
	const [sort, setSort] = useState({ name: 'name', direction: 'asc' })
	const [search, setSearch] = useState('')
	const [showFilter, setShowFilter] = useState(false)
	const [countFilters, setCountFilters] = useState(0)

	const download = name => {
		http.get(TApi.ADMIN_DOWNLOAD_DOCUMENT(name), {
			headers: {
				'Content-Type': 'application/pdf'
			},
			responseType: 'blob'
		}).then(({ data }) => {
			const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', name)
			document.body.appendChild(link)
			link.click()
			link.parentNode.removeChild(link)
		})
	}

	const fetchDocuments = () => {
		const filter = generateFilter(props.documentsFilter)

		http.get(TApi.ADMIN_GET_LIST_DOCUMENTS, {
			params: {
				limit,
				offset: page * limit,
				direction: sort.direction,
				order: sort.name,
				id: company.value ? company.value : null,
				search,
				filter: JSON.stringify(filter)
			}
		}).then(({ data }) => {
			setDocuments(
				data.rows.map(e => {
					const date = new Date(e.createdAt)
					return {
						name: e.originalName,
						file: e.name,
						type: e.type,
						time:
							('0' + date.getDate()).slice(-2) +
							'/' +
							('0' + (date.getMonth() + 1)).slice(-2) +
							'/' +
							date.getFullYear(),
						company: e.Company ? e.Company.name : ''
					}
				})
			)
			setCountDocuments(data.count)
		})
	}

	const handleSort = (name, direction) => {
		setSort({ name, direction })
	}
	const handleSearch = e => {
		if (page === 0) fetchDocuments()
		setPage(0)
	}
	useEffect(() => {
		fetchDocuments()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, company, sort])

	useEffect(() => {
		if (Object.values(props.documentsFilter).every(item => !item.length)) fetchDocuments()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.documentsFilter])

	const columns = useMemo(
		() => [
			{
				Header: () => {
					return <span className={styles.tableTitleName}>{t('Document name')}</span>
				},
				minWidth: 170,
				accessor: 'name',
				Cell: ({ row }) => {
					return (
						<div className={styles.tableNameContainer}>
							<img
								className={styles.tableDocumentIcon}
								src={documentTypesIcon[row.values.type]}
								alt="icon"
							/>
							<span className={styles.tableName}>{row.values.name}</span>
						</div>
					)
				}
			},
			{
				Header: t('Type'),
				minWidth: 170,
				accessor: 'type',
				Cell: ({ row }) => {
					return (
						<p className={styles.tableType}>
							{t(DocumentOptions.find(({ value }) => value === row.values.type)?.label)}
						</p>
					)
				}
			},
			{
				Header: t('Company'),
				minWidth: 150,
				accessor: 'company',
				Cell: ({ row }) => {
					return <p className={styles.tableCompany}>{row.values.company}</p>
				}
			},
			{
				Header: t('Date'),
				width: 80,
				accessor: 'time',
				Cell: ({ row }) => {
					return (
						<div className={styles.tableDateContainer}>
							<p className={styles.tableDate}>{row.values.time}</p>
							<Btn
								theme="download"
								icon="download"
								iconSize={{ w: 24, h: 24 }}
								onClick={() => download(row.original.file)}
							/>
						</div>
					)
				}
			}
		],
		[t]
	)
	return (
		<div className={styles.root}>
			<MiniHeader className={styles.headerContainer} title={t('documents')}>
				<div className={styles.header}>
					<div className={styles.searchContainer}>
						<Search
							value={search}
							onChange={e => setSearch(e.target.value)}
							onSubmit={e => {
								e.preventDefault()
								handleSearch()
							}}
							className={styles.searchLabel}
							inputClassName={styles.searchInput}
							btnClassName={styles.searchBtn}
							placeholder={t('Search for documents...')}
						/>
						<BtnFilter
							className={styles.filterBtn}
							icon="arrow"
							onClick={() => setShowFilter(!showFilter)}
							count={countFilters}
						>
							Filter ({countFilters})
						</BtnFilter>
					</div>
				</div>
			</MiniHeader>
			<div className={styles.subHeader}>
				<Pagination
					limit={limit}
					page={page}
					label={t('documents')}
					countAll={countDocuments}
					onChange={setPage}
				/>
				{/* 	<div className={styles.downloadContent}>
					{t('Download list as')}
					<div>
						<Btn theme="download" iconSize={{ w: 24, h: 24 }} icon="download">
							XLS
						</Btn>
						<Btn theme="download" iconSize={{ w: 24, h: 24 }} icon="download">
							PDF
						</Btn>
					</div>
				</div> */}
			</div>

			<div className="container">
				<Table
					columns={columns}
					handleSort={handleSort}
					items={documents}
					limit={limit}
					countAll={countDocuments}
					onChange={setPage}
					page={page}
					paginationLabel="Documents"
				/>
			</div>
			<Filter
				toShow={{
					adminDocumentType: true
				}}
				onSearch={handleSearch}
				showFilter={showFilter}
				setShowFilter={setShowFilter}
				onCountFilters={setCountFilters}
				onFilterOptions={props.setFilter}
				filters={props.documentsFilter}
			/>
		</div>
	)
}

const mapStateToProps = state => ({
	documentsFilter: state.filter.documents
})

const mapDispatchToProps = dispatch => ({
	setFilter: data => dispatch(setDocumentsFilter(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsList)
