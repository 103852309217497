import clsx from 'clsx'
import PropTypes from 'prop-types'

import Icon from '../Icon'

import styles from './style.module.scss'

export default function BtnAdd(props) {
	return (
		<button type={props.type} onClick={props.onClick} disabled={props.disabled} className={clsx(styles.root)}>
			<Icon name="plus" color="#22404D" size={{ w: 9, h: 9 }} className={styles.icon} />
		</button>
	)
}

BtnAdd.propTypes = {
	type: PropTypes.string,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	onClick: PropTypes.func
}
BtnAdd.defaultProps = {
	disabled: false
}
