import clsx from 'clsx'
import { useEffect, useMemo, useState } from 'react'
import Avatar from 'react-avatar'
import { toast } from 'react-toastify'
import { StateOptions, TApi } from '../../../../shared/const'
import eventEmitter, { types } from '../../../../shared/eventEmitter'
import http from '../../../../shared/http'
import Btn from '../../../../UI/Btn'
import MiniHeader from '../../../../UI/MiniHeader'
import Table from '../../../../UI/Table'
import styles from './style.module.scss'
import { useTranslation } from 'react-i18next'
import Pagination from '../../../../UI/Pagination'
import Search from '../../../../UI/Search'
import BtnFilter from '../../../../UI/BtnFilter'
import Filter from '../../../../UI/Filter'
import { blobTypes, downloadFile, generateFilter } from '../../../../shared/helpers'
import Icon from '../../../../UI/Icon'
import { setEconomicExpertsFilter } from '../../../../redux/ducks/filter'
import { connect } from 'react-redux'

function Experts(props) {
	const { t } = useTranslation()
	const [countExperts, setCountExperts] = useState(0)
	const [page, setPage] = useState(0)
	const limit = 10
	const [isLoading, setLoading] = useState(false)
	const [sort, setSort] = useState({ name: 'name', direction: 'desc' })
	const [experts, setExperts] = useState([])
	const [search, setSearch] = useState('')
	const [openFilter, setOpenFilter] = useState(false)
	const [countFilters, setCountFilters] = useState(0)

	const handleSort = (name, direction) => {
		setSort({ name, direction })
	}

	const fetchExperts = () => {
		const filter = generateFilter(props.economicExpertsFilter)

		http.get(TApi.ADMIN_EXPERTS, {
			params: {
				limit,
				offset: page * limit,
				order: sort.name,
				direction: sort.direction,
				search,
				filter: JSON.stringify(filter)
			}
		}).then(({ data }) => {
			setExperts(data.rows)
			setCountExperts(data.count)
		})
	}

	const deleteExpert = id => {
		setLoading(true)
		http.delete(TApi.ADMIN_EXPERT_ID(id))
			.then(() => {
				toast.success(t('The expert was deleted successfully'))
				fetchExperts()
			})
			.finally(() => setLoading(false))
	}

	const resendEmailConfirmation = id => {
		setLoading(true)
		http.post(TApi.ADMIN_RESEND_EMAIL_EXPERT(id))
			.then(() => {
				toast.success(t('The request was successfully resend'))
				fetchExperts()
			})
			.finally(() => setLoading(false))
	}
	/*
	const confirmExpert = id => {
		setLoading(true)
		http
			.put(TApi.ADMIN_CONFIRM_EXPERT(id))
			.then(() => {
				toast.success(t('The expert is confirmed'))
				fetchExperts()
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const unConfirmExpert = id => {
		setLoading(true)
		http
			.put(TApi.ADMIN_UNCONFIRM_EXPERT(id))
			.then(() => {
				toast.success(t('The expert was rejected'))
				fetchExperts()
			})
			.finally(() => {
				setLoading(false)
			})
	}
*/
	const handleSearch = () => {
		if (page === 0) fetchExperts()
		setPage(0)
	}

	useEffect(() => {
		fetchExperts()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, sort])

	useEffect(() => {
		if (Object.values(props.economicExpertsFilter).every(item => !item.length)) fetchExperts()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.economicExpertsFilter])

	const columns = useMemo(
		() => [
			{
				Header: () => {
					return <span style={{ paddingLeft: 20 }}>Name</span>
				},
				accessor: originalRow => {
					return {
						avatar: originalRow.avatar,
						firstName: originalRow.firstName,
						lastName: originalRow.lastName
					}
				},
				id: 'name',
				minWidth: 250,
				Cell: ({ row }) => {
					return (
						<div className={styles.name}>
							<div>
								<Avatar
									style={{ marginLeft: 20 }}
									src={row.values.name.avatar}
									round
									size={32}
									name={`${row.values.name.firstName} ${row.values.name.lastName}`}
								/>
								<span
									className={styles.nameText}
								>{`${row.values.name.firstName} ${row.values.name.lastName}`}</span>
							</div>
						</div>
					)
				}
			},
			{
				Header: 'Email',
				accessor: 'email',
				width: 200,
				Cell: ({ row }) => {
					return <div style={{ wordBreak: 'break-word' }}>{row.original.email}</div>
				}
			},
			{
				Header: t('Company'),
				accessor: 'company',
				disableSortBy: true,
				Cell: ({ row }) => {
					return <div>{row.original.Company?.name}</div>
				}
			},
			{
				Header: t('state'),
				accessor: 'state',
				Cell: ({ row }) => {
					return (
						<div>{t(StateOptions.find(({ value }) => row.original.Company?.state === value)?.label)}</div>
					)
				}
			},
			{
				Header: t('phone'),
				accessor: 'phone',
				width: 110,
				Cell: ({ row }) => {
					return <p style={{ fontSize: 13 }}>{row.values.phone}</p>
				}
			},
			/*
			{
				Header: t('Status'),
				accessor: 'expertConfirmed',
				Cell: ({ row }) => {
					return (
						<span>
							{row.values.expertConfirmed ? (
								<div className={styles.accepted}>{t('Verified')}</div>
							) : (
								<div className={styles.pending}>{t('Not Verified')}</div>
							)}
						</span>
					)
				}
			},
			*/
			{
				Header: 'Email Status',
				accessor: 'emailConfirmed',
				Cell: ({ row }) => {
					return (
						<div className={styles.branchContainer}>
							<span>
								{row.values.emailConfirmed ? (
									<div className={styles.accepted}>{t('Accepted')}</div>
								) : (
									<div className={styles.pending}>{t('Pending')}</div>
								)}
							</span>
						</div>
					)
				},
				width: 110
			},
			{
				accessor: 'id',
				width: 80,
				disableSortBy: true,
				Cell: ({ row }) => {
					return (
						<div className={styles.btnContainer}>
							{!row.values.emailConfirmed && (
								<Btn
									tooltip="Resend request"
									loading={isLoading}
									className={styles.resendBtn}
									iconColor="#000"
									iconSize={{ w: 16, h: 16 }}
									icon={isLoading ? '' : 'resend'}
									theme="white"
									onClick={() => {
										resendEmailConfirmation(row.original.id)
									}}
								/>
							)}

							<Btn
								loading={isLoading}
								iconColor={isLoading ? '#000' : 'none'}
								iconSize={isLoading ? { w: 16, h: 16 } : { w: 20, h: 20 }}
								icon={isLoading ? '' : 'edit'}
								theme="white"
								onClick={() => {
									eventEmitter.emit(
										types.openAddExpertModal,
										experts.find(item => item.id === row.original.id),
										fetchExperts
									)
								}}
							/>
							<Btn
								loading={isLoading}
								className={styles.deleteBtn}
								iconColor="#000"
								iconSize={{ w: 16, h: 16 }}
								icon={isLoading ? '' : 'delete'}
								theme="white"
								onClick={() => {
									if (
										window.confirm(
											t('Are you sure you want to remove this expert?', {
												fullName: `${row.values.name.firstName} ${row.values.name.lastName}`
											})
										)
									)
										deleteExpert(row.original.id)
								}}
							/>
						</div>
					)
				}
			}
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[experts, isLoading, t]
	)
	const downoloadList = fileType => {
		setLoading(true)
		http.get(TApi.ADMIN_DOWNOLOAD_EXPERTS_LIST, {
			params: {
				type: fileType,
				leng: localStorage.getItem('i18nextLng')
			},
			responseType: 'arraybuffer'
		})
			.then(res => {
				var blob = new Blob([res.data], {
					type: blobTypes[fileType]
				})
				downloadFile('Experts', blob)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	return (
		<div className={styles.root}>
			<MiniHeader className={styles.header} title={t('Economic experts')} bg="#f8faff">
				<div className={styles.headerContent}>
					<Search
						value={search}
						className={styles.search}
						onChange={e => setSearch(e.target.value)}
						placeholder={t('Find a user')}
						onSubmit={e => {
							e.preventDefault()
							handleSearch()
						}}
					/>
					<BtnFilter
						className={styles.filterBtn}
						icon="arrow"
						onClick={() => setOpenFilter(true)}
						count={countFilters}
					>
						Filter ({countFilters})
					</BtnFilter>
				</div>
			</MiniHeader>
			<div className={styles.subHeader}>
				<Pagination limit={limit} page={page} label={t('experts')} countAll={countExperts} onChange={setPage} />
				<div className={styles.downloadContent}>
					{t('Download list as')}
					<div>
						{!isLoading && (
							<Btn
								theme="download"
								iconSize={{ w: 24, h: 24 }}
								icon="download"
								onClick={() => {
									downoloadList('XLS')
								}}
							>
								XLS
							</Btn>
						)}
						{!isLoading && (
							<Btn
								theme="download"
								iconSize={{ w: 24, h: 24 }}
								icon="download"
								onClick={() => {
									downoloadList('PDF')
								}}
							>
								PDF
							</Btn>
						)}
						{isLoading && (
							<Icon
								className={clsx(styles.icon, styles.rootLoading)}
								size={{ w: 16, h: 16 }}
								name="loading"
								color={'#22404D'}
							/>
						)}
					</div>
				</div>
			</div>
			<div className={clsx('container', styles.tableContainer)}>
				<Table
					columns={columns}
					handleSort={handleSort}
					items={experts}
					limit={limit}
					countAll={countExperts}
					onChange={setPage}
					page={page}
				/>
			</div>
			<Filter
				toShow={{
					states: true,
					isExpertEmailConfirmed: true,
					verifiedExpert: true
				}}
				onSearch={handleSearch}
				showFilter={openFilter}
				setShowFilter={setOpenFilter}
				onCountFilters={setCountFilters}
				onFilterOptions={props.setFilter}
				filters={props.economicExpertsFilter}
			/>
		</div>
	)
}
const mapStateToProps = state => ({
	economicExpertsFilter: state.filter.economicExperts
})

const mapDispatchToProps = dispatch => ({
	setFilter: data => dispatch(setEconomicExpertsFilter(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Experts)
