import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

import Alert from '../../UI/Alert'
import { useTranslation } from 'react-i18next'
import StudentShares from '../../components/views/student/Shares'

import injectRole from '../../components/HOC/injectRole'

import { TRoles, TRoutes } from '../../shared/const'

function Shares(props) {
	const { t } = useTranslation()

	if (props.role !== TRoles.STUDENT) return <Redirect to={TRoutes.DASHBOARD} />

	if (!props.isActivatedCompany)
		return (
			<div style={{ padding: 20, width: '100%' }}>
				<Alert style={{ maxWidth: '100%' }}>
					{t('You need to activate the company to unlock this feature')}
				</Alert>
			</div>
		)

	return <StudentShares />
}

Shares.propTypes = {
	role: PropTypes.string.isRequired,
	isActivatedCompany: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
	isActivatedCompany: !!state.user.profile.EmploymentCompany?.isActive
})

export default injectRole(Shares, mapStateToProps)
