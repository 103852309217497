const ger = {
	translations: {
		dashboard: 'Dashboard',
		companyProfile: 'Unternehmensprofil',
		riskAnalysis: 'Risikoanalyse',
		employees: 'MitarbeiterInnen',
		documents: 'Dokumente',
		shares: 'Anteile',
		reports: 'Berichte',
		Reports: 'Berichte',
		experts: 'Experten',
		companies: 'Unternehmen',
		students: 'SchülerInnen',
		users: 'BenutzerInnen',
		invoices: 'Rechnungen',
		income: 'Einnahmen',
		emailNewsletter: 'E-Mail-Newsletter',
		newsPages: 'Nachrichtenseiten',
		stateAdmins: 'Landesadmins',
		schools: 'Schulen',
		settings: 'Einstellungen',
		profile: 'Profil',
		exit: 'Abmelden',
		'Economic experts': 'Wirtschaftsexperten',
		companyExperts: 'Unternehmensexperten',
		uploadBP: 'Businessplan hochladen',
		companyEmployees: 'MitarbeiterInnen',
		companyTeachers: 'Weitere LehrerInnen',
		news: 'Neuigkeit',
		event: 'Veranstaltung',
		'Notify via email to all users': 'zusätzlich Email an alle Nutzer senden',
		genders: 'Genders',
		'School Types': 'Schultypen',
		'VAT Rates Revenues': 'Steuersätze für Einnahmen',
		'VAT Rates Expenditure': 'Steuersätze für Ausgaben',
		'Work Areas': 'Arbeitsbereiche',
		'Type of Offers': 'Art der Angebote',
		'Shareholder Shares': 'Aktionärsanteile',
		'Max. Share Amounts': 'Max. Aktien Gesamtvolumen',
		'Junior Insurance': 'Versicherungsbeitrag',
		'Reports deadline': 'Fristen für Berichtsabgabe',
		'Invoices overdue time': 'Fälligkeit der Rechnungen',
		'Bank Data': 'Bankdaten',
		'No news yet': 'Noch keine Nachrichten',
		'No shares yet': 'Noch keine Aktionäre',
		'No reports yet': 'Noch keine Berichte',
		'Supervision Teacher': 'BetreuungslehrerIn',
		Report: 'Geschäftsbericht',
		Registration: 'Anmeldung',
		Activation: 'Aktivierung',
		'BUSINESS ACTIVITIES': 'GESCHÄFTSTÄTIGKEITEN',
		'Basic Info': 'Basisinformation',
		'Company name': 'Name der Firma',
		Companies: 'Unternehmen',
		'The company name as well as the company type, if any, can only be changed via the country administrator.':
			'Der Firmenname sowie allenfalls der Company-Typ kann nur über den Landesadministrator geändert werden.',
		Note: 'Hinweis',
		'Company type': 'Unternehmenstyp',
		state: 'Bundesland',
		save: 'Speichern',
		contacts: 'Kontakte',
		CompanyEmailDescription:
			'Die Unternehmens-E-Mail-Adresse kann für den Kontakt mit externen Stakeholdern verwendet und z.B. auf der Unternehmenswebsite, Rechnungen, etc. veröffentlicht werden. Wenn euer Unternehmen noch keine E-Mail-Adresse hat, können ihr diese bei Anbietern wie gmx.at, gmail.com oder icloud.com kostenlos einrichten.',
		phone: 'Telefon',
		phoneDesc:
			'Die Telefonnummer des Unternehmens kann für den Kontakt mit externen Akteuren verwendet und z. B. auf der Website des Unternehmens, auf Rechnungen usw. veröffentlicht werden.',
		School: 'Schule',
		schoolDesc:
			'Die JUNIOR Company wird an Eurem Schulstandort gemeldet. Bitte wählt eine bereits registrierte Schule oder gebt einen neuen Schulstandort an.',
		teacherDesc:
			'Bitte wählt eine/n bereits registrierte/n JUNIOR LehrerIn aus. Ist Euer/Eure LehrerIn noch nicht bei uns angemeldet: hier neuen teacher anmelden!',
		'Business Activity': 'Geschäftstätigkeit',
		branch: 'Branche',
		'Company email': 'Firmen-E-Mail',
		description: 'Bezeichnung',
		typeOffers: 'Angebotsart',
		descriptionNote:
			'Die JUNIOR Company wird an Eurem Schulstandort gemeldet. Bitte wählt eine bereits registrierte Schule oder gebt einen neuen Schulstandort an.',
		typeOffersNote:
			'Bitte wählt eine/n bereits registrierte/n JUNIOR LehrerIn aus. Ist Euer/Eure LehrerIn noch nicht bei uns angemeldet: hier neuen teacher anmelden!',
		descriptionPlaceholder: 'Geben Sie eine kurze Firmenbeschreibung ein',
		'Business Idea': 'Geschäftsidee',
		'Your Business Idea': 'Ihre Geschäftsidee',
		'We are the...': 'Wir sind die...',
		'Symbols Left': 'Zeichen übrig',
		Symbols: 'Symbole',
		'Detailed description of business activity': 'Detaillierte Beschreibung der Geschäftstätigkeit',
		'Our...': 'Unsere...',
		'Select Branch': 'Branche auswählen',
		'Company Employee': 'MitarbeiterInnen',
		'Go back': 'Geh zurück',
		'Add Expert': 'Experte hinzufügen',
		'Company Documents': 'Unternehmensdokumente',
		'Document name': 'Dokumentname',
		EMPLOYEE: 'ANGESTELLTER',
		Date: 'Datum',
		'Company Shares': 'Anteile',
		Shareholder: 'Aktionär',
		shareholdersDesc:
			'Alle KaptitalgeberInnen müssen mit Vor- und Nachname sowie einer gültigen Adresse registriert werden. Sobald diese Daten vollständig eingegeben sind, erhält der/die KapitalgeberIn ein persönliches Zertifikat über die Beteiligung an eurer Junior Company per E-Mail. Bitte überpüft daher, dass alle Daten bei der ersten Eingabe gleich korrekt sind, spätere Änderungen sind nur über euren Landesbetreuer möglich.',
		Important: 'Wichtig',
		salutation: 'Anrede',
		'Title Prefixed': 'Titel vor.',
		'First Name': 'Vorname',
		'Last Name': 'Nachname',
		'Title Suffixed': 'Titel nach.',
		gender: 'Geschlecht',
		'Company Share': 'Unternehmensanteile',
		'Select the amount': 'Wähle den Betrag',
		'Warrant Upload': 'Optionsschein Upload',
		'Please upload the warrant!': 'Bitte laden Sie den Optionsschein hoch!',
		'Shareholder Phone': 'Aktionärstelefon',
		Street: 'Straße',
		'House number': 'Hausnummer',
		'Zip Code': 'PLZ',
		City: 'Stadt',
		'Type First Name': 'Geben Sie den Vornamen ein',
		'Type Last Name': 'Geben Sie den Nachnamen ein',
		'Enter Title': 'Geben Sie einen Titel ein',
		'Select Gender': 'Wähle Geschlecht',
		SHAREHOLDER: 'Aktionär',
		'Market and Risk Analysis': 'Markt- und Risikoanalyse',
		'Market Description': 'Marktbeschreibung',
		'To market description': 'Zu Marktbeschreibung',
		'Target group(s)': 'Zielgruppe/n',
		'Product usage': 'Produktnutzen',
		'Add usage...': 'Vorteile hinzufügen',
		'To product benefit': 'Zu Produktnutzen',
		'Describe your target group(s) as precisely as possible with their specific characteristics, such as age group, life situation, interests, whereabouts, etc.':
			'Beschreibt Eure Zielgruppe(n) möglichst genau mit ihren speziellen Eigenschaften wie z.B. Altersgruppe, Lebenssituation, Interessen, Aufenthaltsorte, usw.',
		'What added value does your offer bring to the target group, what needs does it serve? For what benefit does the customer pay the purchase price?':
			'Welchen Mehrwert bringt Euer Angebot der Zielgruppe, welche Bedürfnisse werden damit bedient? Für welchen Nutzen zahlt der Kunde den Kaufpreis?',
		'Customer contact, sales strategy': 'Kundenkontakt, Verkaufsstrategie',
		'Where is/are your target group(s) to be found? How do you promote your offer to the respective target group? Which communication channels do you use?':
			'Wo ist/sind Eure Zielgruppe(n) anzutreffen? Wie bewerbt Ihr Euer Angebot passend zur jeweiligen Zielgruppe? Welche Kommunikationskanäle nutzt ihr?',
		'Add contact & Strategy': 'Kontakt & Strategie hinzufügen',
		'Sales forecast': 'Umsatzprognose',
		'Enter your expected sales figures for your up to 5 most important offers and their respective prices. This will give you a forecast of your sales for the entire project year.':
			'Tragt hier Eure erwarteten Verkaufszahlen für Eure bis zu 5 wichtigsten Angebote und deren jeweiligen Preis ein. Damit erhaltet Ihr eine Prognose Eures Umsatzes für das gesamte Projektjahr.',
		'Product / Service': 'Produkt / Dienstleistung',
		'Product name': 'Produktname',
		'Price (€) / unit': 'Preis (€) / Einheit',
		'Expected sales (number)': 'Erwartete Verkäufe (Anzahl)',
		'Expected turnover (€)': 'Erwarteter Umsatz (€)',
		'Add new forecast': 'Neue Prognose hinzufügen',
		'The total amount of estimated annual sales is €.': 'Die Gesamtsumme des geschätzten Jahresumsatzes beträgt €.',
		'Risk and strategy': 'Risiken & Strategie',
		'Enter here possible risks affecting your company and describe a strategy for dealing with the risk in each case. Risks can arise, for example, in connection with your production, with the target group, with suppliers or with the competition.':
			'Tragt hier mögliche Risiken ein, die Euer Unternehmen betreffen, und beschreibt jeweils eine Strategie für den Umgang mit dem Risiko. Risiken können z.B. im Zusammenhang mit Eurer Produktion, mit der Zielgruppe, mit LieferantInnen oder der Konkurrenz entstehen.',
		Risk: 'Risiko',
		'Avoidance strategy': 'Vermeidungsstrategie',
		'Add avoidance strategy': 'Vermeidungsstrategie hinzufügen',
		'Add new Risk': 'Neues Risiko hinzufügen',
		'Date of Birth': 'Geburtsdatum',
		'Letter has been sent to confirm email changes.':
			'Nachricht wurde versendet, um die E-Mail Änderung zu bestätigen.',
		'Your email': 'E-Mail',
		'Change email': 'Ändern Sie die E-Mail',
		'Profile has been updated successfully.': 'Profil wurde erfolgreich aktualisiert.',
		'Avatar photo': 'Avatarfoto',
		'Upload profile photo': 'Profilfoto hochladen',
		'Parental Confirmation': 'Elternbestätigung',
		'click for open file': 'zum Öffnen der Datei anklicken',
		ParentalDesc:
			'Die Unternehmens-E-Mail-Adresse kann für den Kontakt mit externen Stakeholdern verwendet und z.B. auf der Unternehmenswebsite, Rechnungen, etc. veröffentlicht werden. Wenn euer Unternehmen noch keine E-Mail-Adresse hat, können ihr diese bei Anbietern wie gmx.at, gmail.com oder icloud.com kostenlos einrichten.',
		'Residential address': 'Wohnadresse',
		'Your contact data will only be used for our communication with you and will not be passed on to third parties!':
			'Ihre Kontaktdaten werden nur für unsere Kommunikation mit Ihnen verwendet und nicht an Dritte weitergegeben!',
		'Main function': 'Hauptfunktion',
		'Your main work area': 'Ihr Hauptarbeitsgebiet',
		'2nd work area': '2. Arbeitsbereich',
		'3rd work area': '3. Arbeitsbereich',
		'Passsword has been changed successfuly.': 'Passwort wurde erfolgreich geändert.',
		'Change PW section': 'PW-Abschnitt ändern',
		'New password': 'Neues Kennwort',
		'Old password': 'Altes Passwort',
		'Repeat new password': 'Wiederhole das neue Passwort',
		'Change Password': 'Passwort ändern',
		'My Profile': 'Mein Profil',
		'The expert was': 'Der Experte war',
		updated: 'Aktualisiert',
		created: 'erstellt',
		successfully: 'erfolgreich',
		'Update economic expert': 'Wirtschaftsexperte aktualisieren',
		'Add economic expert': 'Wirtschaftsexperte hinzufügen',
		Company: 'Unternehmen',
		'Function, position': 'Funktion, Position',
		update: 'Aktualisieren',
		Cancel: 'Abbrechen',
		'Add Employee': 'MitarbeiterIn hinzufügen',
		'Invitation was sent successfully': 'Einladung wurde erfolgreich gesendet',
		'CEO Updated': 'Geschäftsführer aktualisiert',
		You: 'Du',
		Employee: 'MitarbeiterIn',
		Director: 'Direktor',
		'Make CEO': 'Weise CEO / GF zu',
		'Invite new employee by Email': 'Neue MitarbeiterIn per E-Mail einladen',
		'Employee Email': 'Mitarbeiter-E-Mail',
		'Teacher added successfully': 'Lehrer erfolgreich hinzugefügt',
		'Invite deleted successfully': 'Einladung erfolgreich gelöscht',
		'Teacher deleted from your company successfully': 'Lehrer erfolgreich aus Ihrem Unternehmen gelöscht',
		'Invite Teacher': 'Lehrer einladen',
		Student: 'SchülerIn',
		Teacher: 'LehrerIn',
		Pending: 'Ausstehend',
		Remove: 'Entfernen',
		'Select teacher': 'Lehrer auswählen',
		Verified: 'Verifiziert',
		Send: 'Senden',
		'Teacher Email': 'Lehrer-E-Mail',
		'Or invite new teacher by Email': 'Oder laden Sie einen neuen Lehrer per E-Mail ein',
		'You can create first report after Company activation':
			'Sie können den ersten Bericht nach der Aktivierung des Unternehmens erstellen',
		'Risk Analysis Form': 'Formular zur Risikoanalyse',
		'Go To Reports': 'Gehe zu Berichten',
		'Go To Shares': 'Gehe zu Aktien',
		'Go To News': 'Gehe zu Nachrichten',
		'Sign In': 'Zur Anmeldung',
		'Do not have account yet?': 'Sie haben noch kein Konto?',
		SignUp: {
			noun: 'Registrierung',
			verb: 'Registrieren'
		},
		Password: 'Passwort',
		'Your password': 'Passwort',
		'Forgot password?': 'Passwort vergessen?',
		'Recover Pass': 'Passwort wiederherstellen',
		'A letter with a link to reset the password is sent to the e-mail':
			'Ein Brief mit einem Link zum Zurücksetzen des Passworts wird an die E-Mail gesendet',
		'Back to': 'Zurück zu',
		page: 'Seite',
		Recover: 'Wiederherstellen',
		'Password changed successfully !': 'Das Passwort wurde erfolgreich geändert !',
		'Successfully updated': 'Erfolgreich aktualisiert',
		'Password Recovery': 'Passwort-Wiederherstellung',
		'Set password for your new account': 'Passwort für Ihr neues Konto festlegen',
		'Create a new password': 'Erstelle ein neues Passwort',
		'Expert Activation': 'Expertenaktivierung',
		'Account confirmation is in progress. Loading...': 'Die Kontobestätigung ist in Bearbeitung. Wird geladen...',
		'account successfully activated': 'Konto erfolgreich aktiviert',
		'Token is not founded': 'Token ist nicht gegründet',
		'Account confirmation': 'Account Bestätigung',
		Dashboard: 'Armaturenbrett',
		'Go to': 'Gehe zu',
		'Registration was successful. Please sign in': 'Die Registrierung war erfolgreich. Bitte einloggen',
		'Registration was successful. Please confirm email':
			'Die Registrierung war erfolgreich. Bitte E-Mail bestätigen',
		'Registration was successful.': 'Die Registrierung war erfolgreich.',
		'as student account': 'als Studentenkonto',
		'Have account?': 'Sie haben bereits ein Konto?',
		Role: 'Rolle',
		'Repeat password': 'Passwort wiederholen',
		'I agree that the above data will be collected and stored by Junior Achievement Austria for the duration of this school year for the purpose of fulfilling and processing the Junior Project. These data will not be passed on to third parties by Junior Achievement Austria and will be removed from the database after the end of the school year.':
			'Ich bin damit einverstanden, dass die oben angeführten Daten zur Erfüllung und Abwicklung des Junior Projektes für die Dauer dieses Schuljahres von Junior Achievement Austria erfasst und gespeichert werden. Diese werden von Junior Achievement Austria nicht an Dritte weitergegeben und nach dem Ablauf des Schuljahres aus der Datenbank entfernt.',
		'Step 2': 'Schritt 2',
		'Provide your personal details': 'Geben Sie Ihre persönlichen Daten an',
		'Select salutation': 'Anrede auswählen',
		'Select gender': 'Wähle Geschlecht',
		Back: 'Zurück',
		Continue: 'Weitermachen',
		Submit: 'Einreichen',
		'Create Company': 'Unternehmen Erstellen',
		'Choose company Type': 'Wählen Sie den Unternehmenstyp',
		'Classical Junior Company for 15-19 year old students. Duration 1 school year (2 semesters)':
			'Klassische Junior Company für 15-19 jährige SchülerInnen. Dauer: 1 Schuljahr (2 Semester)',
		'Simplified Junior Company for 7th-9th grade. Duration 1 semester. Simplified Accounting.':
			'Vereinfachte Junior Company für die 7.-9. Schulstufe. Dauer: 1 Semester. Vereinfachte Buchführung.',
		'Shortened Junior Company for 9th-13th grade students. Can be done in 1 semester.':
			'Verkürzte Junior Company für die 9.-13. Schulstufe. Kann in 1 Semester durchgeführt werden.',
		Create: 'Erstellen',
		'Provide us company data': 'Stellen Sie uns Firmendaten zur Verfügung',
		'Company state': 'Bundesland',
		'Type Short Company Description': 'Kurze Firmenbeschreibung eingeben',
		'Hello, welcome back': 'Hallo, willkommen zurück',
		'Edit My Profile': 'mein Profil bearbeiten',
		'Supervision MaleTeacher in': 'Lehrer an der Schule',
		'Supervision FemaleTeacher in': 'Lehrerin an der Schule',
		'Last Assigned Companies': 'Zuletzt zugewiesene Unternehmen',
		'Last Assigned Students': 'Zuletzt zugewiesene Schüler',
		'Full list': 'Volle Liste',
		'All School Companies': 'Alle Unternehmen in dieser Schule',
		'Go To Companies': 'Gehe zu Unternehmen',
		'No companies yet': 'Noch keine Unternehmen',
		'All Students In School': 'Alle SchülerInnen in dieser Schule',
		'No students yet': 'Noch keine Schüler',
		'ASSIGNED COMPANIES': 'Mir zugeordnete Unternehmen',
		'Search companies...': 'Firmen suchen ...',
		'Assigned Students': 'Mir zugeordnete SchülerInnen',
		'Search for a student': 'SchülerInnen Suche',
		'Registration Date': 'Registrierungsdatum',
		Certificate: 'Zertifikat',
		Insurance: 'Versicherungsbeitrag',
		'Business Plan': 'Businessplan (optional)',
		'Search for documents...': 'Dokumente suchen',
		Type: 'Typ',
		COMPANY: 'Unternehmen',
		'Contacts updated': 'Kontakte aktualisiert',
		'Change contacts': 'Kontakte ändern',
		Select: 'Auswahl',
		'Change School': 'Schule wechseln',
		'General Info': 'Allgemeine Information',
		Customise: 'Anpassen',
		'Quick Lists': 'Schnelllisten',
		'Last Companies': 'Letzte Unternehmen',
		'Last Students': 'Letzte Schüler',
		'Last Teachers': 'Letzte Lehrer',
		'Available widgets': 'Verfügbare Widgets',
		'Max number of widgets is 8': 'Maximale Anzahl von Widgets ist 8',
		'Widgets updated': 'Widgets aktualisiert',
		Members: 'Mitglieder ',
		member: 'mitglied',
		SCHOOL: 'Schule',
		'Company Name': 'Name der Firma',
		Balance: 'Saldo',
		Registered: 'Registriert',
		'Download list as': 'Liste herunterladen als:',
		'Create School': 'Schule anlegen',
		'Update School': 'Schule aktualisieren',
		'Find a school': 'Eine Schule finden',
		Result: 'Ergebnis',
		from: 'von',
		'Find a user': 'Einen Benutzer finden',
		Amount: 'Betragen',
		'Days Left': 'Tage Übrig',
		'Search a invoice': 'eine Rechnung suchen',
		'Total Invoice Amount': 'Totaler Rechnungsbetrag',
		'Paid Invoices, %': 'Bezahlte Rechnungen, %',
		'Paid Invoices': 'Bezahlte Rechnungen',
		'Overdue Invoices': 'Überfällige Rechnungen',
		'TEMPLATE NAME': 'VORLAGENAME',
		'MODIFIED DATE': 'GEÄNDERT DATUM',
		'Email Templates': 'E-Mail-Vorlagen',
		Templates: 'Vorlagen',
		'Create Template': 'Vorlage erstellen',
		'New email template': 'Neue E-Mail-Vorlage',
		'Template name': 'Vorlagenname',
		'Email body': 'Nachrichtentext',
		'Events & News': 'Veranstaltungen & Neuigkeiten',
		'Create message': 'Nachricht erstellen',
		Edit: 'Bearbeiten',
		'Target groups': 'Zielgruppen',
		Place: 'Adresse',
		All: 'Alle',
		'Start date': 'Startdatum',
		'End date': 'Endtermin',
		'External Link': 'externer Link',
		'Add event': 'Ereignis hinzufügen',
		Title: 'Titel',
		'Select state or states': 'auswählen Bundesland',
		'User roles': 'Benutzertypen',
		'Select role or roles': 'Rolle oder Rollen auswählen',
		'Select type or types': 'Typ oder Typen auswählen',
		'Organization name': 'Organisationsname',
		'Administrator email': 'Administrator-E-Mail',
		'Add Administrator': 'Administrator hinzufügen',
		Administrators: 'Administratoren',
		'Admin was successfully': 'Admin wurde erfolgreich',
		'Invitation Pending': 'Einladung ausstehend',
		'The expert was deleted successfully': 'Der Experte wurde erfolgreich gelöscht',
		'The expert is confirmed': 'Der Experte ist bestätigt',
		Accepted: 'Akzeptiert',
		'Companies Pending, %': 'Firmen ausstehend, %',
		Resolve: 'Beschließen',
		'Show Invoices': 'Rechnungen anzeigen',
		'Customize Multi Widget': 'Multi-Widget anpassen',
		'First Slot': 'Erster Slot',
		'Second Slot': 'Zweiter Slot',
		'Third Slot': 'Dritter Slot',
		'Fourth Slot': 'Vierter Slot',
		'Customize Companies Widget': 'Firmen-Widget anpassen',
		New: 'Neu',
		Overdue: 'Überfällig',
		Unpaid: 'Unbezahlt',
		teachers: 'LehrerInnen',
		Turnover: 'Umsatz',
		'Confirm Company Profile': 'Firmenprofil bestätigen',
		Documentation: 'Dokumentation',
		'Business activity empty': 'Geschäftstätigkeit leer',
		'Type of offers empty': 'Art der Angebote leer',
		'Business idea empty': 'Geschäftsidee leer',
		'Company Description': 'Firmen Beschreibung',
		'Company description empty': 'Firmenbeschreibung leer',
		'Add Admin Comment': 'Admin-Kommentar hinzufügen',
		'Your Comment about Company (Only administrators will see this comment)':
			'Ihr Kommentar zum Unternehmen (Nur Administratoren sehen diesen Kommentar)',
		'Type comment about company': 'Kommentar zum Unternehmen eingeben',
		'Activate Company': 'Unternehmen aktivieren',
		'Annual financial statements': 'Jahresabschluss',
		Expenditures: 'Ausgaben',
		'Profit/Loss': 'Gewinn/Verlust',
		'To successfully complete the Junior Basic Company the following final accounting must be done.':
			'Um die Junior Basic Company erfolgreich abzuschließen muss die folgende Endabrechnung gemacht werden.',
		Revenue: 'Einnahmen',
		'Total gross revenue': 'Summe brutto-einnahmen',
		'All income from your sales WITHOUT income from the sale of Junior Basic Company shares.':
			'Alle Einnahmen aus euren Verkäufen OHNE Einnahmen aus dem Verkauf von Anteilen der Junior Basic Company.',
		Expenses: 'Ausgaben',
		'Total salary expenses': 'Gehaltsausgaben gesamt',
		'All expenditures for wages of employees of your Junior Basic Company.':
			'Alle Ausgaben für Löhne der MitarbeiterInnen eurer Junior Basic Company.',
		'Material expenses': 'Materialausgaben',
		'All expenses for the materials you needed to make your products.':
			'Alle Ausgaben für das Material, das ihr zum Herstellen eurer Produkte benötigt habt.',
		'Other expenses': 'Sonstige Ausgaben',
		'Other expenses such as transportation or travel expenses, company Christmas party, etc.':
			'Andere Ausgaben wie z.B. für Transport- oder Reisekosten, Firmenweihnachtsfeier etc.',
		'Total gross expenses': 'Summe Brutto-Ausgaben',
		Validate: 'Weiter',
		Next: 'Vor',
		'Profit/Loss was submitted': 'Gewinn/Verlust wurde eingereicht',
		'Your income minus expenses result in the profit or loss. Important a loss is written as a minus number!':
			'Eure Einnahmen minus Ausgaben ergeben den Gewinn bzw. Verlust. Wichtig: ein Verlust wird als Minuszahl geschrieben!',
		'insurance contribution': 'Versicherungsbeitrag',
		'Insurance premium for all activities of your Junior Company.':
			'Versicherungsbeitrag für sämtliche Aktivitäten euer Junior Company.',
		'Net profit/loss': 'Reingewinn/-verlust',
		'Profit/loss minus insurance contribution gives the net profit/loss.':
			'Gewinn/Verlust abzüglich Versicherungsbeitrag ergibt den Reingewinn/-verlust.',
		'Date of submission (cannot be changed)': 'Datum der Eingabe (kann nicht geändert werden)',
		Previous: 'Zurück',
		'Calculation of profit loss before taxes': 'Berechnung des Gewinnverlusts vor Steuern',
		'Calculation of corporate income tax': 'Berechnung der Körperschaftsteuer',
		'Calculation of net profit/loss': 'Berechnung des Nettogewinns/-verlusts',
		'Calculation of levies to Junior': 'Berechnung der Abgaben an Junior',
		'Calculation of profit/loss before taxes was updated':
			'Berechnung des Gewinns/Verlustes vor Steuern wurde aktualisiert',
		'To successfully complete the Junior Compact Company the following final financial statement must be made.':
			'Um die Junior Compact Company erfolgreich abzuschließen muss die folgende Endabrechnung gemacht werden.',
		'Total Income': 'Summe Einnahmen',
		'Total revenue.': 'Summe Einnahmen',
		'All income from your sales and other income WITHOUT income from the sale of Junior Compact Company shares!':
			'Alle Einnahmen aus euren Verkäufen und sonstige Einnahmen OHNE Einnahmen aus dem Verkauf von Anteilen der Junior Compact Company!',
		'Salary expenses': 'Gehaltsausgaben',
		'All expenses for wages of employees of your Junior Compact Company ("Entrepreneur`s Wages").':
			'Alle Ausgaben für Löhne der MitarbeiterInnen Eurer Junior Compact Company ("UnternehmerInlohn").',
		'All expenses for materials you needed to produce your products, including sales taxes.':
			'Alle Ausgaben für Material, das Ihr zum Herstellen Eurer Produkte benötigt habt inkl. darin enthaltene Umsatzsteuern.',
		'Other expenses such as transportation or travel expenses, company Christmas party, ....':
			'Andere Ausgaben wie z.B. für Transport- oder Reisekosten, Firmenweihnachtsfeier, ...',
		'Sum of expenses': 'Summe der Ausgaben',
		'Calculation of profit/loss before taxes': 'Berechnung Gewinn/Verlust vor Steuern',
		'Profit/Loss (before tax)': 'Gewinn/Verlust (vor Steuern)',
		'Preliminary profit/loss before deduction of taxes.': 'Vorläufiger Gewinn/Verlust vor dem Abzug der Steuern.',
		'- 13% Salary-related social security contributions': '- 13% Gehaltsabhängige Sozialabgaben',
		'Salary-related social security contributions': 'Gehaltsabhängige Sozialabgaben',
		'Depending on the amount of salaries or entrepreneur wages paid, 13% social contributions must be paid.':
			'Abhängig von der Höhe der ausbezahlten Gehälter bzw. UnternehmerInnenlöhne müssen 13% Sozialabgaben entrichtet werden.',
		'Insurance contribution': 'Versicherungsbeitrag',
		'Insurance contribution for all activities of your Junior Company.':
			'Versicherungsbeitrag für sämtliche Aktivitäten euer Junior Company.',
		'Profit/loss (before corporate income tax)': 'Gewinn/Verlust (vor KöSt.)',
		'Preliminary profit/loss before deduction of corporate income tax, which is due only in case of profit.':
			'Vorläufiger Gewinn/Verlust vor dem Abzug der KöSt, die nur im Falle eines Gewinnes anfällt.',
		'Calculation of corporate income tax was updated': 'Berechnung der Körperschaftsteuer wurde aktualisiert',
		'25% corporate income tax': '25% Körperschaftssteuer',
		'Corporate income tax': 'Körperschaftssteuer',
		'Corporate income is taxed at a fixed rate of 25%.':
			'Das Einkommen der Körperschaften wird mit einem fixen Steuersatz iHv. 25% besteuert.',
		'Founder`s bonus (20% of corporate income tax)': 'Gründerbonus (20% der KöSt.)',
		'This represents a tax relief of 20% of the corporate income tax exclusively for junior companies.':
			'Dieser stellt eine steuerliche Entlastung iHv. 20% der KöSt. exklusiv für Junior Companies dar.',
		'Reduced corporate income tax': 'Reduzierte Körperschaftssteuer',
		'The calculated amount represents the deductible corporate income tax.':
			'Der errechnete Betrag stellt die abzüführende Körperschaftssteuer dar.',
		'Calculation of net profit/loss was updated': 'Berechnung des Nettogewinns/-verlusts wurde aktualisiert',
		'Calculation of net profit/loss (to be distributed)': 'Berechnung Reingewinn/-verlust (zu verteilen)',
		'The reduced corporate income tax is to be deducted from the provisional profit.':
			'Die reduzierte Körperschaftssteuer ist vom vorläufigen Gewinn abzuziehen.',
		'Net profit / Net loss': 'Reingewinn / Reinverlust',
		'This amount represents your profit/loss after deduction of taxes.':
			'Dieser Betrag stellt euren Gewinn/Verlust nach Abzug der Steuern dar.',
		'Calculation of levies to Junior was submitted': 'Umlageberechnung an Junior wurde eingereicht',
		'Levies to be transferred to Junior Austria': 'Abgaben an Junior Österreich zu überweisen',
		'According to this schedule, levies are to be remitted to the account indicated in the invoice.':
			'Laut dieser Aufstellung sind Abgaben auf das in der Rechnung angeführtes Konto zu überweisen.',
		'+ 13% salary-related social security contributions': '+ 13% Gehaltsabhängige Sozialabgaben',
		'= Taxes to be transferred to Junior Austria': '= Abgaben an Junior Österreich zu überweisen',
		'Revenues and sales': 'Einnahmen und Verkäufe',
		'Expenses and input tax deduction': 'Aufwendungen und Vorsteuerabzug',
		'Salary-related taxes (employee share)': 'Gehaltsabhängige Abgaben (Dienstnehmeranteil)',
		'Advance return for sales tax': 'Umsatzsteuer Voranmeldung (UVA)',
		'Calculation half-year result': 'Berechnung Halbjahresergebnis',
		'Financial report first semester': 'Finanzbericht erstes Semester',
		'Revenues was deleted': 'Einnahmen wurden gelöscht',
		'Revenues and sales was updated': 'Umsätze und Verkäufe wurden aktualisiert',
		'Deadline by DD.MM.YYYY': 'Frist bis TT.MM.JJJJ',
		'Income from the sale of company shares does not count towards your sales!':
			'Einnahmen aus dem Verkauf von Unternehmensanteilen zählen nicht zu Euren Umsätzen!',
		'Here you can create new groups of turnovers, with different VAT rates.':
			'Hier könnt ihr neue Gruppen an Umsätzen anlegen, mit unterschiedlichen Umsatzsteuersätzen.',
		'Turnovers in € incl. VAT': 'Umsätze in € inkl. USt.',
		'VAT rate': 'Umsatzsteuersatz',
		'Turnover total': 'Umsätze gesamt',
		'of which': 'davon',
		'% VAT in €': '%-ige Umsatzsteuer in €',
		'Create new entry': 'Neuen Eintrag anlegen',
		'Junior tax-exempt revenues': 'Junior Abgabenfreie Einnahmen',
		'These revenues do not count towards the calculation of junior corporate tax and do not include VAT - e.g. sponsorship.':
			'Diese Einnahmen zählen nicht zur Berechnung der Junior Körperschaftsteuer und enthalten keine Umsatzsteuer – bspw. Sponsoring.',
		'Total in €': 'Summe in €',
		'For VAT-exempt revenue from sponsorship, the company must provide a consideration in the form of advertising or public relations. Describe here what consideration you will provide for each sponsorship received.':
			'Für USt-freie Einnahmen aus Sponsoring muss das Unternehmen eine Gegenleistung in Form von Werbung oder Öffentlichkeitsarbeit erbringen. Beschreibt hier, welche Gegenleistung Ihr für jedes erhaltene Sponsoring erbringen werdet.',
		'Describe returns...': 'Retouren beschreiben...',
		'Material expenses were deleted': 'Materialaufwand wurde gelöscht',
		'Other expenses were deleted': 'Andere Ausgaben wurden gelöscht',
		'Expenses and input tax deduction was updated': 'Aufwand und Vorsteuerabzug wurde aktualisiert',
		'Enter the total of your material and other expenses and the input tax (sales tax) included in them. The input tax is offset against the sales tax collected on your sales and reduces the tax payment.':
			'Gebt hier die Summe Eurer Material- und sonstigen Ausgaben sowie die darin enthaltene Vorsteuer (Umsatzsteuer) an. Die Vorsteuer wird mit der eingenommen Umsatzsteuer eurer Verkäufe gegengerechnet und vermindert die Abgabenzahlung.',
		'Sales tax rate': 'Umsatzsteuersatz',
		'Material expenses in € incl. <%> VAT.': 'Materialausgaben in € inkl. % USt.',
		'% sales tax in €': '%-ige Umsatzsteuer in €',
		'Other expenses in € incl. <%> VAT.': 'Sonstige Ausgaben in € inkl. % USt.',
		'Other expenses not subject to sales tax': 'Sonstige umsatzsteuerfreie Ausgaben',
		'Please enter an accurate list of VAT-exempt expenses here.':
			'Bitte tragt hier eine genaue Liste der umsatzsteuerfreien Ausgaben ein.',
		'Salary-related taxes was updated': 'Gehaltsbezogene Steuern wurden aktualisiert',
		'Total gross salaries (in €)': 'Summe der Bruttogehälter (in €)',
		'minus 5% social security contribution (in €)': 'minus 5% Sozialversicherungsabgabe (in €)',
		'= Calculation basis (in €)': '= Berechnungsgrundlage (in €)',
		'minus 10% wage tax (in €)': 'minus 10% Lohnsteuer (in €)',
		'= Net wages (in €)': '= Nettolöhne (in €)',
		'Salary-related taxes (employer`s contribution)': 'Gehaltsabhängige Abgaben (Arbeitgeberanteil)',
		'of which 6% social security contribution (in €)': 'davon 6% Sozialversicherungsabgabe (in €)',
		'Statement of revenues and expenses were updated': 'Ertrags- und Aufwandsrechnung wurde aktualisiert',
		'Statement of revenues': 'Aufstellung der Einnahmen',
		'Sales in € incl.': 'Umsatz in € inkl.',
		'% VAT.': '% MwSt.',
		'Total gross sales in €': 'Umsätze brutto gesamt in €',
		'Total sales tax': 'Umsatzsteuer gesamt',
		'Turnover net total in €': 'Umsätze netto gesamt in €',
		'Material expenses in € incl.': 'Materialkosten in € inkl.',
		'Other expenses in € incl.': 'Sonstige Ausgaben in € inkl.',
		'Total gross costs in €': 'Kosten brutto gesamt in €',
		'Input tax total in €': 'Vorsteuer gesamt in €',
		'Total net costs in €': 'Kosten netto gesamt in €',
		'Advance return of the value added tax (UVA)': 'Umsatzsteuer Voranmeldung (UVA)',
		'Statement of expenses': 'Spesenabrechnung',
		'List of tasks': 'Aufstellung der Ausgaben',
		'Calculation half-year result was submitted': 'Berechnung Halbjahresergebnis wurde vorgelegt',
		'The semi-annual report was successfully submitted and cannot be changed now. Date':
			'Der Halbjahresbericht wurde erfolgreich eingereicht und kann nun nicht mehr verändert werden. Datum',
		'Statement of income/expenses': 'Einnahmen-/Ausgaben-Rechnung',
		'Sales net in €': 'Umsätze netto in €',
		'less material and other costs net in €': 'abzügl. Material- und sonstige Kosten netto in €',
		'less personnel costs incl. all taxes in €': 'abzügl. Personalkosten inkl. aller Abgaben in €',
		'less other expenses not subject to sales tax in €': 'abzügl. sonstige umsatzsteuerfreie Ausgaben in €',
		'less junior insurance in €': 'abzügl. Junior-Versicherung in €',
		'Half-year result total in €': 'Halbjahresergebnis gesamt in €',
		'Calculation half-year expenses': 'Berechnung Halbjahresausgaben',
		'Sales taxes collected in €': 'Eingenommene Umsatzsteuern in €',
		'less input taxes in €': 'abzügl. Vorsteuern in €',
		'Wage and salary contributions in €': 'Lohn- und Gehaltsabgaben in €',
		'plus junior insurance in €': 'zuzügl. Junior-Versicherung in €',
		'Total charges in €': 'Abgaben gesamt in €',
		'Submit half-yearly financial statements': 'Halbjahresabschluss einreichen',
		'Payload or credit': 'Zahllast oder Guthaben',
		'Income/Expense statement ': 'Gewinn- und Verlustrechnung (GuV)',
		'Calculation of contributions 2nd half': 'Berechnung der Abgaben 2. HJ',
		'Financial report second semester': 'Finanzbericht zweites Semester',
		'1st Semiannual Advance return of the value added tax (UVA)':
			'1. Halbjahresvoranmeldung der Mehrwertsteuer (UVA)',
		'2nd Semiannual Advance return of the value added tax (UVA)':
			'2. Halbjahresvoranmeldung der Mehrwertsteuer (UVA)',
		'= Sales tax calculation': '= Umsatzsteuerberechnung',
		'Income/Expense statement was updated': 'Ertrags-/Aufwandsrechnung wurde aktualisiert',
		'Income/Expense statement for whole year': 'Einnahmen-/Ausgaben-Rechnung für das ganze Jahr',
		'Total net income for the year in €': 'Gesamtjahresergebnis in €',
		'thereof 10% corporate income tax in €': 'davon 10% Körperschaftsteuer in €',
		'Payload or credit was updated': 'Nutzlast oder Guthaben wurde aktualisiert',
		'Calculation of contributions 2nd half was submitted': 'Beitragsberechnung 2. Hälfte wurde eingereicht',
		'Calculated corporate income tax': 'Berechnete Körperschaftsteuer',
		'Result VAT return in €': 'Ergebnis Umsatzsteuererklärung in €',
		'Total contributions in €': 'Gesamtbeiträge in €',
		'Submit yearly financial statements': 'Jahresabschluss einreichen',
		'School deleted successfully': 'Schule erfolgreich gelöscht',
		'No business plan yet': 'Noch kein Businessplan',
		'No risk analysis yet': 'Noch keine Risikoanalyse',
		'Go To Students': 'Gehe zu Studenten',
		'Privacy Statement': 'Zur Datenschutzerklärung',
		'Confirm email': 'Bitte bestätigen Sie Ihre E-Mail',
		'Need to confirm email': 'E-Mail muss bestätigt werden',
		'Mr.': 'Herr',
		'Ms.': 'Frau',
		'Mrs.': 'Frau',
		Miss: 'Frau',
		Male: 'Männlich',
		Female: 'Weiblich',
		Divers: 'Vielfältig',
		Products: 'Produkte',
		Service: 'Dienstleistungen',
		'Services & Products': 'Produkte & Dienstleistungen',
		'Clothing & Accessories': 'Bekleidung & Accessories',
		'Office Supplies': 'Bürobedarf',
		Electronics: 'Elektronik',
		Vehicles: 'Fahrzeuge',
		Vienna: 'Wien',
		'Lower Austria': 'Niederösterreich',
		Burgenland: 'Burgenland',
		Styria: 'Steiermark',
		'Upper Austria': 'Oberösterreich',
		Salzburg: 'Salzburg',
		Tyrol: 'Tirol',
		Vorarlberg: 'Vorarlberg',
		Carinthia: 'Kärnten',
		'Parental confirmation updated': 'Elternbestätigung aktualisiert',
		'Managing director': 'Geschäftsführung',
		Procurement: 'Einkauf',
		Finance: 'Finanzen',
		'Human Resources / Administration': 'Human Resources / Administration',
		'Marketing / Communication': 'Marketing / Kommunikation',
		'Production / Services': 'Produktion / Leistungserstellung',
		Other: 'Sonstige',
		'Technology / IT': 'Technologie / IT',
		Sales: 'Verkauf',
		'Avatar successfully updated': 'Avatar erfolgreich aktualisiert',
		'Avatar successfully deleted': 'Avatar erfolgreich gelöscht',
		consent: 'Ich habe die <1>Datenschutzerklärung</1> gelesen und willige in die Datenverarbeitungen ein',
		'Approve expert': 'Experte genehmigen',
		'Disapprove expert': 'Experten ablehnen',
		'The expert was rejected': 'Der Experte wurde abgelehnt',
		'Тhe expert is approved': 'Тder Sachverständige ist anerkannt',
		'Not Activated': 'Nicht aktiviert',
		Reviewed: 'Bewertet',
		'Not Reviewed': 'Nicht überprüft',
		'You have no notifications at this time': 'Sie haben derzeit keine Benachrichtigungen',
		'Repeat password does not match with Password': 'Die Passwörter stimmen nicht überein',
		'Checklist confirm account': 'Benutzerkonto',
		'Select or add your school': 'Wähle bzw. füge deine Schule hinzu',
		'Select or add your teacher': 'Wähle bzw. füge deine Lehrkraft hinzu',
		'Confirm Email': 'E-Mail Bestätigung',
		Hello: 'Hallo',
		'Add Employees': 'MA hinzufügen',
		'Add Experts': 'Exp. hinzufügen',
		'Risk Analysis': 'Risiko-analyse',
		Check: 'Prüfung',
		'Create Share': 'Verkauf von Anteilen',
		'Semester Report': 'Finanz-Bericht',
		'Pay Invoice': 'Abgaben entrichten',
		'Document Type': 'Art des Dokuments',
		'Request sent successfully': 'Anfrage erfolgreich gesendet',
		'View profile': 'Profil anzeigen',
		'Request to assign': 'Zuordnung anfordern',
		'Company status': 'Unternehmensstatus',
		'Clear all': 'Alles löschen',
		Messages: 'Mitteilungen',
		'General Profit': 'Allgemeiner Gewinn',
		'Overdue Reports': 'Überfällige Berichte',
		'Search reports': 'Berichte suchen...',
		'Report type': 'Berichtstyp',
		'Clear All': 'Alles löschen',
		'You can sell the company shares after activating the company':
			'Sie können die Unternehmensanteil nach der Aktivierung des Unternehmens verkaufen',
		Position: 'Arbeitsbereich',
		'Your parental': 'Einverständnis der Eltern',
		'Refresh status': 'Status aktualisieren',
		'Resend activation email': 'Bestätigungsmail erneut senden',
		confirmEmail:
			'Eine Bestätigungsmail wurde an [<1>Datenschutzerklärung</1>] geschickt. Bitte klicken Sie den Bestätigungslink in dieser E-Mail, um das Benutzerkonto zu aktivieren. Bitte prüfen Sie auch Ihren Spam-Ordner.',
		'Get certificate template': 'Zertifikatvorlage abrufen',
		'The file is too large. Current file size': 'Die Datei ist zu groß. Aktuelle Dateigröße',
		'Max file size is': 'Die maximale Dateigröße beträgt',
		'School Name': 'Schulname',
		Number: 'Nummer',
		'Add Teacher': 'Lehrer hinzufügen',
		'Please confirm your email': 'Bitte bestätigen Sie Ihre E-Mail',
		Register: 'Registrieren',
		'Deadline by': 'Abschluss bis',
		'Create new school': 'Schule bearbeiten',
		STUDENT: 'SchülerInnen',
		TEACHER: 'LehrerInnen',
		'Functional and working area': 'Funktions - und Arbeitsbereich',
		'Risk analysis form successfully updated': 'Risikoanalyseformular erfolgreich aktualisiert',
		'You need to activate the company to unlock this feature':
			'Sie müssen das Unternehmen aktivieren, um diese Funktion freizuschalten',
		Share: 'Anteil',
		'Add Shareholder': 'Aktionär hinzufügen',
		'Company approved': 'Unternehmen genehmigt',
		'Company not yet approved': 'Unternehmen noch nicht genehmigt',
		'Submit for approval': 'Zur Genehmigung einreichen',
		'Expert already confirmed.': 'Experte hat es bereits bestätigt.',
		'The letter is not valid.': 'Der Brief ist ungültig.',
		'The registration link has expired.': 'Der Registrierungslink ist abgelaufen.',
		'The email does not seem to be associated with any user.':
			'Die E-Mail scheint keinem Benutzer zugeordnet zu sein',
		'The email does not seem to be associated with any expert.':
			'Die E-Mail scheint keinem Experten zugeordnet zu sein.',
		'Company submitted for approval': 'Unternehmen zur Genehmigung eingereicht',
		'Check Confirmed': 'Check bestätigt',
		Reject: 'Ablehnen',
		'Reject Company': 'Unternehmen ablehnen',
		'Reject Parental Confirmation': 'Elternbestätigung ablehnen',
		'Download template': 'Formular download',
		'Approve school': 'Schule verfizieren',
		'The school has been approved': 'Die Schule wurde genehmigt',
		'Verify teacher': 'Lehrer bestätigen',
		'Merge and remove': 'Zusammenführen und entfernen',
		'Teacher status': 'Lehrerstatus',
		Yes: 'Ja',
		No: 'Nein',
		'The shareholder was created successfully': 'Der Aktionär wurde erfolgreich geschaffen',
		'Need review': 'Überprüfung erforderlich',
		'Analysis & Plan': 'Analyse & Plan',
		'Parental consent': 'Einverst. Eltern',
		'Fed. State': 'Bundesland',
		'Pass Recovery': 'PW Wiederherstellung',
		'Account already activated.': 'Benutzerkonto wurde bereits aktiviert.',
		Assign: 'Auswählen',
		'Market and Riskanalysis': 'Markt- und Riskoanalyse',
		Example: 'Beispiel',
		Activated: 'Aktiviert',
		'No main work area yet': 'Noch kein Hauptarbeitsbereich',
		'Need to upload parental confirmation': 'Müssen Elternbestätigung hochladen',
		'This icon shows incomplete steps, such as missing parental confirmation, unassigned working area, or pending employee invitation.':
			'Dieses Symbol kennzeichnet unvollständige Schritte, wie fehlende Elternbestätigung, nicht zugewiesenen Aufgabenbereich oder ausstehende Mitarbeiter-Einladung.',
		Payee: 'Beneficiary',
		Beneficiary: 'Begünstigter',
		Merge: 'Zusammenführen',
		'Merge schools': 'Schulen Zusammenführen',
		'Merge school and remove': 'Schule zusammenführen und entfernen',
		'School has been created successfully.': 'Schule wurde erfolgreich erstellt.',
		'School has been updated successfully.': 'Schule wurde erfolgreich aktualisiert.',
		'Company profile has been updated successfully.': 'Firmenprofil wurde erfolgreich aktualisiert.',
		'User profile has been updated.': 'Benutzerprofil wurde aktualisiert.',
		'Annual deadline': 'Jahresfrist',
		'Semiannual deadline': 'Halbjahresfrist',
		Deadline: 'Termin',
		'The parental confirmation was rejected successfully': 'Die Elternbestätigung wurde erfolgreich abgelehnt',
		'The company was rejected successfully': 'Das Unternehmen wurde erfolgreich abgelehnt',
		'The letter was sent successfully': 'Bestätigungsmail wurde versendet',
		'No need review': 'Keine Überprüfung erforderlich',
		'Review status': 'Überprüfungsstatus',
		Submitted: 'Bereitgestellt',
		Open: 'Offen',
		Checked: 'Geprüft',
		'Company was created successful.': 'Unternehmen wurde erfolgreich erstellt.',
		'Company comment is updated': 'Firmenkommentar wird aktualisiert',
		'Not Verified': 'Nicht verifiziert',
		'Wait for an invitation from an existing company':
			'Warten Sie auf eine Einladung von einem bestehenden Unternehmen',
		'Teacher registered successful.': 'Lehrer erfolgreich registriert.',
		'Wait invitation': 'Warte Einladung',
		'Wait Invitation': 'Einladung Warten',
		'Email successfully changed': 'E-Mail erfolgreich geändert',
		'Email change confirmation': 'Bestätigung der E-Mail-Änderung',
		Rejected: 'Abgewiesen',
		'Parental confirmation was rejected, upload new please !':
			'Elternbestätigung wurde abgelehnt, bitte neu hochladen!',
		'Status was updated': 'Status wurde aktualisiert',
		'Invoice created, you can find it in documents': 'Rechnung erstellt, Sie finden sie in den Dokumenten',
		'Change payment': 'Zahlungseingang',
		'Change pay': 'Lohn ändern',
		'Teacher Status': 'Lehrerstatus',
		'Number of Students': 'SchülerInnen',
		'Number of companies': 'Unternehmen',
		'Company Reminders': 'Firmenerinnerungen',
		'1st reminder [x] days after company set-up started and not finished and no login':
			'Erste Erinnerung [x] Tage nach Firmenbeginn und noch nicht abgeschlossen und kein Login',
		'1st reminder [x] days after company profile has been submitted for approval and no login':
			'Erste Erinnerung [x] Tage nachdem das Firmenprofil zur Genehmigung eingereicht wurde und kein Login',
		'Financial Reporting Reminders': 'Erinnerungen an die Finanzberichterstattung',
		'Junior Company': 'Junior-Unternehmen',
		'1st reminder [x] days before deadline': 'Erste Mahnung [x] Tage vor Fristablauf',
		'Reminder frequency each [x] day(s)': 'Erinnerungshäufigkeit jeden [x] Tag(e)',
		'Junior Basic Company and Junior Compact Company': 'Junior Basic Company und Junior Compact Company',
		'Invoicing Reminders': 'Rechnungserinnerungen',
		'1st reminder [x] days after invoice has been issued': 'Erste Mahnung [x] Tage nach Rechnungsstellung',
		'Options was updated': 'Optionen wurde aktualisiert',
		days: 'Tage',
		details: 'Einzelheiten',
		'The schools was merged successfully': `Die Schulen wurden erfolgreich zusammengelegt`,
		'The teachers was merged successfully': 'Die Lehrer wurden erfolgreich zusammengeführt',
		school: 'Schule',
		Administrator: 'Landesadmin',
		'Federal Administrator': 'Bundesadmin',
		B: 'S',
		A: 'B',
		'St.': 'BL',
		D: 'F',
		'Comp.': 'Untern.',
		'All states': 'Alle',
		'Customize Widget': 'Widget Konfiguration',
		VAT: 'Ust.',
		'Debit note': 'Zahllast',
		'Credit note': 'Gutschrift',
		Semiannual: 'Halbjahresbericht',
		Annual: 'Jahresbericht',
		'Open Risk Analysis Form': 'Markt- und Riskoanalyse anzeigen',
		Paid: 'Bezahlt',
		'If the invoice amount matches the payment, the invoice status changes to paid. If there is a difference, the balance is not equal to zero.':
			'Stimmen Rechnungsbetrag mit Einzahlung zusammen, so wechselt der Rechnungsstatus auf bezahlt. Bei Differenz entsteht ein Saldo ungleich Null.',
		'Change status': 'Status ändern',
		'To be checked': 'Zu überprüfen',
		'Widget settings updated successfully': 'Widget-Einstellungen erfolgreich aktualisiert',
		'Request failed with status code 401': 'Anfrage fehlgeschlagen mit Statuscode 401',
		'Email or password is not correct': 'E-Mail oder Passwort ist nicht korrekt',
		'The new password should differ from the old one': 'Das neue Passwort sollte sich vom alten unterscheiden',
		'Request failed with status code 409': 'Anfrage fehlgeschlagen mit Statuscode 409',
		'Emp.': 'MA',
		'Pa.C': 'Elt.E',
		FS: 'BL',
		'Rep.': 'Ber.',
		'Bal.': 'Sal.',
		'Rev.': 'Ein.',
		'Exp.': 'Aus.',
		'Res.': 'Erg.',
		'Cha.': 'Abg.',
		'Please wait': 'Warten Sie mal',
		'Wrong result': 'Falsches Ergebnis',
		'Report was successfully submitted.': 'Bericht wurde erfolgreich eingereicht.',
		'You have a credit balance that can be claimed by stating the beneficiary (=account holder), IBAN and this statement as a file attachment via an e-mail to info@junior.cc.':
			'Sie haben ein Guthaben, das mittels Angabe des Begünstigten (=KontoinhaberIn), IBAN und dieser Aufstellung als Dateianhang über eine E-Mail an info@junior.cc eingefordert werden kann.',
		Total: 'Gesamt',
		Active: 'Aktiv',
		Invoice: 'Rechnung',
		'Business plan': 'Businessplan',
		'Parental confirmation': 'Bestätigung der Eltern',
		'Consent document': 'Einverstädniserklärung',
		'Participation document': 'Teilnahmedokument',
		'Company certificate': 'Firmenbuchauszug',
		'Shareholder certificate': 'Anteilschein',
		ANNUAL: 'Jahresbericht',
		SEMIANNUAL: 'Halbjahresbericht',
		'Invoice payment reminder': 'Zahlungserinnerung',
		'Assign Request': 'Anfrage zuweisen',
		'The report has been submitted, the invoice was created. Please pay the invoice.':
			'Der Bericht wurde eingereicht, die Rechnung wurde erstellt. Bitte bezahlen Sie die Rechnung.',
		'Your request was accepted. Company': 'Deine Anfrage wurde angenommen. Gesellschaft',
		'Your request was rejected. Company': 'Ihre Anfrage wurde abgelehnt. Gesellschaft',
		'wants to be assigned as teacher in your company.': 'möchte als Lehrer in Ihrem Unternehmen eingesetzt werden.',
		'Company Confirmation': 'Firmenbestätigung',
		'has submitted company for business approval': 'hat das Unternehmen zur Geschäftsgenehmigung eingereicht',
		'Congratulation!': 'Herzlichen Glückwunsch!',
		'Great Job, you have been awarded for participation in Junior Company Program - download your certificate':
			'Tolle Arbeit, Sie wurden für die Teilnahme am Junior Company Program ausgezeichnet - laden Sie Ihr Zertifikat herunter',
		Payment: 'Zahlung',
		'You company has paid the invoice': 'Ihr Unternehmen hat die Rechnung bezahlt',
		'You company has to make a payment invoice': 'Ihr Unternehmen muss eine Zahlungsrechnung erstellen',
		'New User': 'Neuer Benutzer',
		'joined successfully to company !': 'trat erfolgreich in das Unternehmen ein!',
		'Congratulation, you have successfully submitted the': 'Herzlichen Glückwunsch, Sie haben den',
		'Fin. Report': 'Bericht erfolgreich eingereicht',
		'Teacher Confirmation': 'Lehrerbestätigung',
		'Congratulation, your account was confirmed by an admin':
			'Herzlichen Glückwunsch, Ihr Konto wurde von einem Administrator bestätigt',
		'Congratulation, your parental confirmation has been approved by an admin':
			'Herzlichen Glückwunsch, Ihre elterliche Bestätigung wurde von einem Administrator genehmigt',
		'Company Activation': 'Unternehmensaktivierung',
		'Congratulation, your company has been approved by an admin':
			'Herzlichen Glückwunsch, Ihr Unternehmen wurde von einem Administrator genehmigt',
		'Report was rejected': 'Bericht wurde abgelehnt',
		'Company report was rejected. Please check and submit report again.':
			'Der Firmenbericht wurde abgelehnt. Bitte überprüfen Sie den Bericht und senden Sie ihn erneut.',
		Employees: 'MitarbeiterInnen',
		'Rep.Typ': 'Bericht',
		Revenues: 'Einnahmen',
		Expanses: 'Ausgaben',
		Charges: 'Abgaben',
		Reported: 'Berichtet',
		'Deadline (d)': 'Frist (T)',
		'Invoice Number': 'Numer',
		Credit: 'Gutschrift',
		'View Report': 'Bericht öffnen',
		'Reject report': 'Bericht ablehnen',
		'Not approved': 'Nicht approved',
		'Company Rejected': 'Unternehmen abgelehnt',
		'Submit Report': 'Bericht senden',
		'Please submit report before deadline !': 'Bericht bitte vor Fristablauf einreichen!',
		'Edit Userprofile': 'Benutzerprofil bearbeiten',
		'Download parental consent': 'Elternbestätigung herunterladen',
		'Download parental consent (not uploaded yet!)': 'Elternbestätigung herunterladen (noch nicht hochgeladen!)',
		'Parental consent verfied': 'Elternbestätigung (verfiziert)',
		'Parental consent (need verifying)': 'Elternbestätigung (muss überprüft werden)',
		'New Employee': 'Neuer Angestellter',
		'has invited': 'hat',
		'to work in the company.': 'zur Mitarbeit im Unternehmen eingeladen.',
		'New Teacher': 'Neuer Lehrer',
		'has invited the teacher': 'hat die Lehrkraft',
		'to the enterprise.': 'zum Unternehmen eingeladen.',
		'New Expert': 'Neuer Experte',
		'has registered': 'hat',
		'as a economic experts.': 'als WirtschafsexpertIn eingetragen.',
		'Expert Confirmation': 'Expertenbestätigung',
		'Business expert': 'WirtschaftsexpertIn',
		'agrees with the GDPR.': 'ist mit der DSGVO einverstanden.',
		'Great, you have dealt with the market and risk analysis.':
			'Super, ihr habt euch mit der Markt- und Risikoanalyse befasst.',
		'Your business plan has been successfully uploaded.': 'Euer Businessplan wurde erfolgreich hochgeladen.',
		'has uploaded the parent/guardian consent form.':
			'hat die Einverständniserklärung der Eltern bzw. Erziehungsberechtigten hochgeladen.',
		'has submitted the company for verification and activation by JA Austria.':
			'hat das Unternehmen zur Überprüfung und Aktivierung durch JA Austria eingereicht.',
		'The parental/guardian consent form of':
			'Die Einverständniserklärung der Eltern bzw. Erziehungsberechtigten von',
		'has been verified and confirmed.': 'wurde überprüft und bestätigt.',
		'The declaration of consent of the parents or legal guardians of':
			'Die Einverständniserklärung der Eltern bzw. Erziehungsberechtigten  von',
		'was rejected.': 'wurde abgewiesen.',
		'Congratulations! Your company has been successfully verified by JA Austria. For the legitimation of your business, we have issued a company register extract':
			'Gratulation! Euer Unternehmen wurde erfolgreich durch JA Austria überprüft. Für die Freischaltung eurer Geschäfte haben wir ein Firmenbuchauszug',
		'for you. For the sale of your company shares, the signed warrants':
			'für euch ausgestellt. Für den Verkauf eurer Unternehmensanteile müssen die unterzeichneten Optionsscheine',
		'must be uploaded up to a max. amount of': 'bis zu einer max. Summe von',
		'in the Share section.': 'im Bereich Anteil hochgeladen werden.',
		'Do not forget to prepare your financial report by': 'Vergisst nicht euren Finanzbericht bis  zu erstellen',
		'Your company has been reviewed by JA Austria and unfortunately could not be approved for business yet.':
			'Euer Unternehmen wurde durch JA Austria überprüft und konnte leider noch nicht für eine Geschäftstätigkeit freigegeben werden.',
		'Please do not forget to prepare your financial report by deadline.':
			'Bitte vergisst nicht auf euren Finanzbericht, der bis deadline zu erstellen ist.',
		'Congratulations, your financial report has arrived at JA Austria! On the basis of your data, a compilation of the levies has been prepared, which you can find in the "Documents" section. An open invoice':
			'Gratulation, euer Finanzbericht ist bei JA Austria eingelangt! Auf Basis eurer Angaben wurde eine Zusammenstellung der Abgaben erstellt, Ihr findet diese im Bereich "Unterlagen". Eine offene Rechnung',
		'must be paid by bank transfer within': 'ist innerhalb von',
		'days.': 'mittels Überweisung zu begleichen.',
		'Attention. Your financial report has been put on hold after submission and needs to be resubmitted after revision.':
			'Achtung! Euer Finanzbericht wurde nach der Einreichung zurückgestellt und muss  nach einer Überarbeitung neu eingereicht werden.',
		Congratulations: 'Gratulation',
		'You have successfully completed the JA Austria programme. You can find your certificate in the "Documents" section':
			'Du hast das JA Austria Programm erfolgreich abgeschlossen. Sie finden Ihr Teilnahmezertifikat im Bereich "Dokumente" oder hier',
		'Consent of parent or guardian': 'Einverständnis Erziehungsberechtigte(r)',
		'is a required field': 'erforderlich',
		'is must be less than 10000 characters': ' muss kleiner oder gleich 10000 sein',
		'is must be greater than 200 characters': ' muss größer oder gleich 200 sein',
		'Total fin. result': 'Gesamtergebnis',
		'Wage and Salary contributions': 'Lohn- und Gehaltsabgaben',
		'Salary-dependent contributions (employee share)': 'Gehaltsabhängige Abgaben (Dienstnehmeranteil)',
		'Salary-related contributions (employer`s contribution)': 'Gehaltsabhängige Abgaben (Arbeitgeberanteil)',
		'Show Reports': 'Berichte anzeigen',
		'Option warrant': 'Optionsschein',
		'Certificate of completion': 'Abschlusszertifikat',
		'Shareholder certificate template': 'Anteilschein vorlage',
		'link to submission': 'link zur Vorlage',
		'link to invoice': 'Link zur Rechnung',
		'link to certificate of participation': 'Link zum Teilnahmezertifikat',
		'Welcome !': 'Willkommen!',
		'Here you will find information about upcoming tasks around your company as well as hints about interesting offers and events of our partners.':
			'Du erhältst hier Informationen über anstehende Aufgaben rund um Euer Unternehmen als auch Hinweise auf interessante Angebote und Veranstaltungen unserer Partner.',
		'First enter all relevant information about your company, add further employees, supervising teachers and company experts. Optionally, you can also add a business plan. Do not forget to upload the signed consent of a parent or guardian in your user profile.':
			'Erfasse zunächst alle relevanten Informationen zu Eurem Unternehmen, füge weitere MitarbeiterInnen, betreuende LehrerInnen und  WirtschaftsexpertInnen hinzu. Optional kann auch ein Businessplan beigefügt werden. Vergiss auf keinen Fall die unterzeichnete Einverständnis einer erziehungsberechtigten Person in deinem Benutzerprofil hochzuladen.',
		'Before your company starts business, it must be submitted to JA Austria for verification and activation. After activation you can offer your products, services and shares in your company on the market - for the legitimisation of these activities you will receive a company register extract and the template for issuing warrants.':
			'Bevor Euer Unternehmen die Geschäftstätigkeit aufnimmt muss es zur Überprüfung und Aktivierung an JA Austria eingereicht werden. Nach der Aktivierung könnt Ihr Eure Produkte, Dienstleistungen und Anteile an Eurem Unternehmen am Markt anbieten - für die Legitimation dieser Aktivitäten erhält Ihr dafür einen Firmenbuchauszug und die Vorlage für die Ausstellung von Optionsscheinen.',
		'The financial report provides you with information on the necessary taxes that are usually incurred in the course of business activities.':
			'Der Finanzbericht gibt euch Orientierung über notwendige Abgaben, welche üblicherweise im Rahmen einer Geschäftstätigkeit anfallen.',
		'Good luck for your company': 'Viel Erfolg für Euer Unternehmen',
		Request: 'Anfrage',
		'Open a modal window and confirm or reject the request.':
			'Öffne ein modales Fenster und bestätige oder lehne die Anfrage ab.',
		'Further support teachers': 'Weitere Betreuungslehrkräfte',
		'Final Report uploaded successfully': 'Geschäftsbericht erfolgreich hochgeladen!',
		'Business Plan uploaded successfully': 'Businessplan erfolgreich hochgeladen!',
		'Business Plan removed successfully': 'Geschäftsplan erfolgreich entfernt',
		'Please log out and wait until you are invited by a member of an existing company. After being invited and logging in again, you will be immediately assigned to your joint company as an employee.':
			'Bitte logge dich aus und warte bis du von einem Mitglied eines bestehenden Unternehmens eingeladen wirst. Nach der Einladung und dem erneuten Login wirst du eurem gemeinsamen Unternehmen als MitarbeiterIn gleich zugewiesen.',
		Direktor: 'Geschäftsführung',
		"Don't forget to submit your company for approval (button in dashboard area)":
			'Vergiss nicht dein Unternehmen zur Genehmigung einzureichen (Button im Dashboard Bereich)',
		'is waiting for an activation check for': 'wartet',
		'days!': 'Tage auf einen Aktivierungscheck',
		'Company activation page.': 'Aktivierungsseite des Unternehmens.',
		Reminder: 'Erinnerung',
		'Business Report': 'Geschäftsbericht',
		'Your business report has been successfully uploaded.': 'Ihr Geschäftsbericht wurde erfolgreich hochgeladen.',
		'Download Final Report': 'Abschlussbericht herunterladen',
		'Download Business Plan': 'Businessplan herunterladen',
		'Shareholder Share for Junior': 'Aktionärsanteile Junior',
		'Shareholder Share for Junior Compact': 'Aktionärsanteile Compact',
		'Shareholder Share for Junior Basic': 'Aktionärsanteile Basic',
		'Junior Annual report deadline': 'Junior Jahresbericht',
		'Junior Semiannual report deadline': 'Junior Halbjahresbericht',
		'Junior Compact report deadline': 'Junior Compact Bericht',
		'Junior Basic report deadline': 'Junior Basic Bericht',
		'The open invoice from for was paid incompletely. Your company account has a balance of. Please transfer the difference to the account details provided.':
			'Die offene Rechnung vom {{val1}} über {{val2}} wurde unvollständig bezahlt. Euer Unternehmenskonto weist ein Saldo von {{val3}} auf. Bitte überweist die Differenz an die angegebene Kontoverbindung.',
		'New employee invited': 'Neue(r) MitarbeiterIn eingeladen',
		'has invited to work in the company.': '{{val1}} hat {{val2}} zur Mitarbeit im Unternehmen eingeladen.',
		'has invited the teacher to the enterprise.': '{{val1}} hat die Lehrkraft {{val2}} zum Unternehmen eingeladen.',
		'Teacher invited': 'Lehrkraft eingeladen',
		'has registered as a company expert.': '{{val1}} hat {{val2}} als WirtschafsexpertIn eingetragen.',
		'Economic expert added': 'Wirtschaftsexperte hinzugefügt',
		'Business expert agrees with the GDPR.': 'WirtschaftsexpertIn {{val1}} ist mit der DSGVO einverstanden.',
		'Economic expert confirmed': 'Wirtschaftsexperte bestätigt',
		'Market- & Riskanalysis': 'Markt- und Risikoanalyse',
		'Parent/guardian consent': 'Einverständniserklärung',
		'Company under verfication': 'Unternehmen wird geprüft',
		'The parental/guardian consent form of has been verified and confirmed.':
			'Die Einverständniserklärung der Eltern bzw. Erziehungsberechtigten von {{val1}} wurde überprüft und bestätigt.',
		'Consent confirmed': 'Einverständniserklärung bestätigt',
		'Consent rejected': 'Einverständniserklärung abgewiesen',
		'The declaration of consent of the parents or legal guardians of was rejected.':
			'Die Einverständniserklärung der Eltern bzw. Erziehungsberechtigten  von {{val1}} wurde abgewiesen.',
		'Company verified': 'Unternehmen geprüft',
		'Company not approved': 'Unternehmen nicht freigegeben',
		'Financial report reminder': 'Erinnerung Finanzbericht',
		'Financial report rejected': 'Finanzbericht zurückgewiesen',
		'The open invoice from for has been paid.': 'Die offene Rechnung vom {{val1}} über  {{val2}} wurde bezahlt.',
		'Participation certificate': 'Teilnahmeurkunde ausgestellt',
		'Invoice amount paid incompletely': 'Rechnungsbetrag unvollständig bezahlt',
		'Invoice generated': 'Rechnung erstellt',
		'Here you will find information about activities of the companies you support as well as information about interesting offers and events of our partners.':
			'Hier erhalten Sie Informationen über Aktivitäten der von Ihnen betreuten Junior Unternehmen als auch Hinweise auf interessante Angebote und Veranstaltungen unserer Partner.',
		'The JA Austria company has added you as a supervising teacher. You can now follow the details of the assigned companies in the "Companies" section and also have access to their documents such as business plan, shareholder certificates, financial reports and annual report.':
			'Das Junior Unternehmen {{val1}} hat Sie als betreuende Lehrkraft hinzugefügt. Sie können nun die Details der zugeordneten Unternehmen im Bereich "Unternehmen" mitverfolgen und haben außerdem die Einsicht in deren Dokumente wie z.B. Businessplan, Anteilsscheine, Finanzberichte und Geschäftsbericht.',
		'New company': 'Neues Unternehmen',
		'Report submitted': 'Bericht eingereicht',
		'The company you supervise has filed. You can find the report here':
			'Das von Ihnen betreute Junior Unternehmen {{val1}} hat {{val2}} eingereicht. Den Bericht finden Sie hier',
		'Congratulations! Your company has submitted its financial report and thus successfully completed the JA Austria Programme. For the students involved in the company we have deposited completion certificates in the "Documents" section - the students can download them independently. You can find the business report here ':
			'Gratulation! Das von Ihnen betreute Junior Unternehmen {{val1}} hat den Geschäftsbericht eingereicht und damit erfolgreich das JA Austria Programm abgeschlossen. Für die beteiligten SchülerInnen am Unternehmen haben wir im Bereich "Dokumente" Abschlussurkunden hinterlegt - die SchülerInnen können diese selbständig herunterladen. Den Geschäftsbericht finden Sie hier ',
		'We would also like to thank you very much for your support of the JA Austria programme with a certificate':
			'Wir möchten uns ebenfalls bei Ihnen mit einer Urkunde für Ihre Betreuung des JA Austria Programmes ganz herzlich bedanken ',
		Author: 'Autor',
		Expired: 'Abgelaufen',
		'Financial report submitted & certificates have been issued':
			'Geschäftsbericht eingereicht & Urkunden wurden ausgestellt',
		'Attention the following JA Austria companies you are in charge of have not yet submitted their report in our system and therefore cannot successfully complete the programme. Company: ':
			'Achtung folgende Junior Unternehmen, die von Ihnen betreut werden haben ihren Bericht in unserem System noch nicht eingereicht und können somit das Programm nicht erfolgreich abschließen. Unternehmen: ',
		'Congratulations! The company you are in charge of has been checked by JA Austria and activated for further business activities. The students have received an extract from the company register and can now sell shares in the company up to a total value of EUR . The following date(s) have been set for report submission':
			'Gratulation! Das von Ihnen betreute Junior Unternehmen {{val1}} wurde seitens JA Austria geprüft und für die weiteren geschäftlichen Tätigkeiten aktiviert. Die SchülerInnen haben einen Firmenbuchauszug erhalten und können nun auch Unternehmensanteile bis zu einem Gesamtwert von EUR {{val2}} verkaufen. Für Berichtsabgabe wurde(n) folgende(n) Termin(e) festgelegt:',
		'Invoice paid': 'Rechnung bezahlt',
		'Anonimize and clear db': 'Anonymisieren und Löschen der Datenbank',
		'download anonimized database': 'Anonymisierte Daten herunterladen',
		'Clear Database': 'Datenbank löschen',
		From: 'Ab',
		To: 'Bis',
		Target: 'Zielgruppe',
		'Work Area': 'Funktion',
		'Rep.Type': 'Bericht',
		'Semi-annual Invoice': 'Halbjahresrechnung',
		'Annual Invoice': 'Jahresrechnung',
		'Semi-annual Report': 'Halbjahresbericht',
		'Annual Report': 'Jahresbericht',
		'Social Media': 'Soziale Medien',
		'Website & Social Media': 'Website & Social Media',
		Branches: 'Branchen',
		Planned: 'Geplant',
		'Delete employee': 'Mitarbeiter löschen',
		'Delete Invite': 'Einladung löschen',
		C: 'Ü',
		'A teacher from': 'Eine Lehrkraft aus',
		'is interested in your company. Please add': 'ist an eurem Unternehmen interessiert. Bitte füge',
		'to your company in the company profile section.':
			'im Bereich "Unternehmensprofil" zu Eurem Unternehmen hinzu.',
		'Request from a teacher': 'Anfrage einer Lehrkraft',
		'Ready for submission': 'Bereit zur Einreichung',
		'Congratulations you have everything complete. Your company can now be submitted to JA Austria for review.':
			'Gratulation, eure Daten sind nun vollständig. Euer Unternehmen kann nun zur Überprüfung an JA Austria eingereicht werden.',
		'Company submitted for verification and activation.':
			'Unternehmen zur Verifizierung und Aktivierung eingereicht.',
		'Final Report': 'Geschäftsbericht',
		'Please upload your final report via the upload function in the dashboard. Afterwards you will find your participation certificates for the successful completion of the JA Austria programme in the document section.':
			'Bitte ladet noch Euer Geschäftsbericht über die Uploadfunktion im Dashboard hoch. Im Anschluss findet ihr Eure Teilnahmeurkunden für den erfolgreichen Abschluss des JA Austria Programms im Dokumentenbereich.',
		Accept: 'Akzeptieren',
		'Open profile': 'Profil öffnen',
		'Are you sure you wish to delete the economic expert ?':
			'Möchten Sie den Wirtschaftsexperten wirklich löschen?',
		'Are you sure?': 'Bist du sicher?',
		'You will not be able to revert this!': 'Sie können dies nicht rückgängig machen!',
		'Yes, delete it!': 'Ja, löschen Sie es!',
		'You have new news - click to look details': 'Sie haben neue Neuigkeiten - klicken Sie, um Details anzuzeigen',
		P: 'T',
		and: 'und',
		others: 'andere',
		'read more': 'weiter lesen',
		'Are you sure you want to remove this expert?': 'Are you sure you want to remove this expert({{fullName}})?',
		NEWS: 'Neuigkeit',
		EVENT: 'Veranstaltung',
		'This field must be over 0': 'Dieses Feld muss über 0 sein',
		'Resend request': 'Anfrage wiederausschicken',
		Delete: 'Löschen',
		'Delete the company': 'lösche das Unternehmen',
		'The company successfully deleted': 'Das Unternehmen wurde erfolgreich gelöscht',
		'Upload logo & product images': 'Logo hochladen',
		'Function & working area': 'Funktion und Arbeitsbereich',
		'The company must not be leaderless!': 'Das Unternehmen darf nicht führungslos sein!',
		'First assign your CEO function to another person!':
			'Übertragen zunächst deine CEO Funktion an eine andere Person!',
		'CEO / Managing Director': 'CEO / Geschäftsführung',
		'Add link': 'Link hinzufügen',
		'CEO role can be transferred in the view "Employees" via the 3-point menu':
			'CEO Rolle kann in der Ansicht "MitarbeiterInnen" über das 3-Punkte Menü übertragen werden',
		'Edit function & working area': 'Funktion und Arbeitsbereich bearbeiten',
		public: 'veröffentlicht',
		private: 'nicht veröffentlicht',
		Explanation: 'Begründung',
		Approved: 'Genehmigt',
		Approval: 'Genehmigung',
		'Approval not possible yet!': 'Genehmigung noch nicht möglich!',
		Requirements: 'Voraussetzungen',
		'Complete company profile & risk analysis': 'Vollständiges Unternehmensprofil & Risikoanalyse',
		'At least 1 business expert': 'Mind. 1 Wirtschaftsexperte/-in',
		'Parental consent from all employees': 'Einverständniserklärung für alle Mitarbeitenden',
		'Not verified': 'Nicht verifiziert',
		Show: 'Anzeigen',
		'Wait for confirmation new employee': 'Warten auf die Einladungsbestätigung des neuen Mitarbeiters',
		'The CEO role must always be assigned to one person. It can be assigned to another person via the 3-point menu in the "Employees" view. CEO name is shown on the company register extract.':
			'Die CEO-Rolle muss stets einer Person zugewiesen sein. Über das 3-Punkte-Menü in der "MitarbeiterInnen"-Ansicht kann sie einer anderen Person zugeteilt werden. CEO Name wird auf dem Firmenbuchauszug ausgewiesen.',
		'Administrator comment about Company': 'Kommentar des Administrators zum Unternehmen',
		'Type social media title': 'Titel für soziale Medien eingeben',
		'Final report removed successfully': 'Abschlussbericht erfolgreich entfernt',
		'Business report': 'Geschäftsbericht',
		'You can change the logo on the "Company Profile" page':
			'Sie können das Logo auf der Seite "Unternehmensprofil" ändern',
		'Every employee must have a function & working area':
			'Jeder Mitarbeiter muss ein/e Arbeitsbereich/Funktion haben',
		'Change view': 'Sicht wechseln',
		'view mode as Student': 'Ansichtsmodus als Student',
		years: 'Jahre',
		'A working area has to be selected. Please assign for a working area':
			'Es muss ein Hauptaufgabenbereich ausgewählt sein. Bitte weisen Sie einen Hauptaufgabenbereich zu',
		'Gross salaries may not be EUR 0 in both semesters':
			'Bruttogehälter dürfen nicht in beiden Semestern EUR 0,- betragen.',
		'Remove employee.': 'Mitarbeiter:in entfernen.',
		'No working area': 'Kein Arbeitsbereich',
		'The company must consist of at least two employees':
			'Das Unternehmen muss aus mindestens zwei Mitarbeitern bestehen',
		"You are currently in a student's view": 'Sie befinden sich derzeit in der Schüler:innen Ansicht',
		'Please note the URL syntax e.g. https://www.junior.cc':
			'Bitte beachten Sie die URL-Syntax, z.B. https://www.junior.cc',
		'The invitation to join the company has been revoked. Begin your registration in the system without the invitation link to create your own company.':
			' Die Einladung, der Company beizutreten, wurde rückgängig gemacht. Starten Sie Ihre Registrierung im System ohne den Einladungslink, um eine eigene Company anzulegen.',
		Export: 'Exportieren',
		'Download report': 'Bericht herunterladen',
		invoice: 'Rechnung',
		'We have created an': 'Auf Basis eures Berichts haben wir eine',
		'based on your report. Please pay it within the next': 'erstellt. Bitte begleicht diese innerhalb der nächsten',
		'You can find the invoice in the': 'Die Rechnung findet ihr im Bereich',
		'Please do not forget to submit your financial report.':
			'Bitte vergisst nicht auf die Abgabe eures Finanzberichts.',
		'Half-year report': 'Halbjahresbericht',
		'annual report': 'Jahresbericht',
		'Select the status of companies to be displayed': 'Wählen Sie den Status der anzuzeigenden Unternehmen',
		'Company Presentation Status': 'Unternehmenspräsentation Status',
		Content: 'Inhalt'
	}
}

export default ger
