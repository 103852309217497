import React from 'react'
import styles from '../../style.module.scss'
import PropTypes from 'prop-types'
import TextField from '../../../../../../../../UI/TextField'
import { useForm } from 'react-hook-form'
import Btn from '../../../../../../../../UI/Btn'
import { toast } from 'react-toastify'
import { useEffect } from 'react'
import { optionsDate } from '../../../../../../../../shared/const'
import { useTranslation } from 'react-i18next'
import TextFieldNumber from '../../../../../../../../UI/TextFieldNumber'
import Decimal from 'decimal.js'
import { connect } from 'react-redux'

function CompanyContrinutionsCalculation(props) {
	const { t } = useTranslation()
	const {
		register,
		control,
		reset,
		handleSubmit,
		formState: { errors }
	} = useForm()

	const onSubmit = form => {
		let data = form
		props
			.updateData({
				...data,
				isSubmitted: true,
				annualReportDate: new Date().toISOString(),
				taxesTransferredToJuniorAustria: data.semiAnnualReportTotalDuties
			})
			.then(() => {
				toast.success(t('Calculation of contributions 2nd half was submitted'))
				toast.success(t('Invoice created, you can find it in documents'))
			})
	}

	useEffect(() => {
		const semiAnnualReportWagesSalariesContributions = new Decimal(
			props.form?.socialSecurityContributionEmployee || 0
		)
			.plus(props.form?.wageTax || 0)
			.plus(props.form?.socialSecurityContributionEmployer || 0)
			.toDecimalPlaces(2)
			.toNumber()

		const semiAnnualReportTotalDuties = new Decimal(props.form?.semiAnnualReportResultTotalTax || 0)
			.plus(props.form?.salesTaxCalculation || 0)
			.plus(semiAnnualReportWagesSalariesContributions)
			.toDecimalPlaces(2)
			.toNumber()

		let updatedSalesTaxCalculation = 0
		if (props.form?.advanceReturnValueAddedTaxFirst > 0) {
			updatedSalesTaxCalculation = new Decimal(props.form?.advanceReturnValueAddedTax || 0)
				.toDecimalPlaces(2)
				.toNumber()
		} else {
			updatedSalesTaxCalculation =
				new Decimal(props.form?.advanceReturnValueAddedTax || 0)
					.plus(new Decimal(props.form?.advanceReturnValueAddedTaxFirst || 0))
					.toDecimalPlaces(2)
					.toNumber() || 0
		}
		reset({
			semiAnnualReportResultTotalTax: props.form?.semiAnnualReportResultTotalTax,
			salesTaxCalculation: updatedSalesTaxCalculation,
			semiAnnualReportWagesSalariesContributions:
				semiAnnualReportWagesSalariesContributions || props.form?.semiAnnualReportWagesSalariesContributions,
			semiAnnualReportTotalDuties: semiAnnualReportTotalDuties || props.form?.semiAnnualReportTotalDuties,

			annualReportDate:
				props.form?.annualReportDate &&
				new Date(props.form?.annualReportDate).toLocaleDateString('de-DE', optionsDate)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.form])

	if (props.currentStep !== props.needStep) return null

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<section className={styles.section}>
				<h3 className={styles.sectionTitle}>{t('Calculation of contributions 2nd half')}</h3>
				<div className={styles.sectionContent}>
					<TextFieldNumber
						disabled
						mb={20}
						label={t('Calculated corporate income tax')}
						name="semiAnnualReportResultTotalTax"
						control={control}
						placeholder="00.000,00"
						error={errors.semiAnnualReportResultTotalTax}
					/>

					<TextFieldNumber
						disabled
						mb={20}
						label={t('Result VAT return in €')}
						name="salesTaxCalculation"
						control={control}
						placeholder="00.000,00"
						error={errors.salesTaxCalculation}
					/>
					<TextFieldNumber
						disabled
						classNameInput={styles.calculated}
						mb={20}
						label={t('Wage and salary contributions in €')}
						name="semiAnnualReportWagesSalariesContributions"
						control={control}
						placeholder="00.000,00"
						error={errors.semiAnnualReportWagesSalariesContributions}
					/>

					<TextFieldNumber
						disabled
						classNameInput={styles.calculated}
						mb={20}
						label={t('Total contributions in €')}
						name="semiAnnualReportTotalDuties"
						control={control}
						placeholder="00.000,00"
						error={errors.semiAnnualReportTotalDuties}
					/>
					{props.form?.isSubmitted && (
						<TextField
							disabled
							mb={20}
							label={t('Report was successfully submitted.')}
							register={register('annualReportDate')}
							error={errors.annualReportDate}
							placeholder="dd.mm.yyyy hh:mm:ss"
						/>
					)}

					{props.form?.isSubmitted || props.isTeacherStudentView ? (
						<Btn className={styles.btn} type="button" width={337} onClick={() => props.handlerPrevStep()}>
							{t('Previous')}
						</Btn>
					) : (
						<Btn
							disabled={props.form?.isSubmitted}
							loading={props.isLoading}
							className={styles.btn}
							type="submit"
							width={337}
						>
							{t('Submit yearly financial statements')}
						</Btn>
					)}
				</div>
			</section>
		</form>
	)
}

CompanyContrinutionsCalculation.propTypes = {
	form: PropTypes.object,
	updateData: PropTypes.func,

	currentStep: PropTypes.number,
	needStep: PropTypes.number,
	handlerPrevStep: PropTypes.func,
	isTeacherStudentView: PropTypes.bool.isRequired
}
const mapStateToProps = state => ({
	isTeacherStudentView: state.user.isTeacherStudentView
})

export default connect(mapStateToProps)(CompanyContrinutionsCalculation)
