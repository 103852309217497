import Joi from 'joi'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { joiResolver } from '@hookform/resolvers/joi'

import Btn from '../../../../UI/Btn'
import Chip from '../../../../UI/Chip'
import Icon from '../../../../UI/Icon'
import SelectField from '../../../../UI/SelectField'
import BaseModal from '../../../../UI/BaseModal/BaseModal'

import http from '../../../../shared/http'
import { TApi } from '../../../../shared/const'
import { schemaOptions } from '../../../../shared/i18n'
import { types } from '../../../../shared/eventEmitter'

import styles from '../AddFeedModal/style.module.scss'

const schema = Joi.object({
	teacher: Joi.object({
		id: Joi.number().integer().required()
	})
		.unknown()
		.required()
})

export default function MergeTeachersModal() {
	const { t } = useTranslation()
	const {
		reset,
		control,
		handleSubmit,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema, {
			errors: {
				labels: false,
				language: localStorage.getItem('i18nextLng')
			},
			...schemaOptions
		})
	})

	const [isLoading, setLoading] = useState(false)
	const [onDone, setOnDone] = useState(null)
	const [fromTeacher, setFromTeacher] = useState({})

	const handleOpen = (fromTeacher, onDone) => {
		setFromTeacher(fromTeacher)
		setOnDone(() => onDone)
		reset()
	}

	const loadTeacherOptions = async (search, loadedOptions) => {
		const {
			data: { rows, count }
		} = await http.get(TApi.ADMIN_GET_TEACHERS, {
			params: {
				search,
				limit: 30,
				except: fromTeacher.id,
				offset: loadedOptions.length
			}
		})

		return {
			options: rows.map(row => ({ ...row, value: row.id, label: row.name })),
			hasMore: loadedOptions.length < count
		}
	}

	const onSubmit = (fields, closeModal) => {
		setLoading(true)

		http.put(TApi.ADMIN_PUT_TEACHERS_MERGE, {
			toTeacherId: fields.teacher.id,
			fromTeacherId: fromTeacher.id
		})
			.then(() => {
				toast.success(t(`The teachers was merged successfully`))
				closeModal()
				reset()
				if (onDone) onDone()
			})
			.finally(() => {
				setLoading(false)
			})
	}

	return (
		<BaseModal
			isLoading={isLoading}
			onOpen={handleOpen}
			nameEvent={types.openMergeTeacherModal}
			title="Teachers merge"
			maxWidth={522}
		>
			{({ closeModal }) => (
				<form className={styles.root} onSubmit={handleSubmit(form => onSubmit(form, closeModal))}>
					<p>
						Merge from teachers: <br />
						{fromTeacher.verifiedTeacher && <Chip miniRadius>Verified</Chip>}
						<span style={{ marginLeft: 10 }}>
							{fromTeacher.titlePrefixed} {fromTeacher.firstName} {fromTeacher.lastName}{' '}
							{fromTeacher.titleSuffixed}
						</span>
					</p>
					<div>
						<Icon
							className={styles.iconArrowMerge}
							name="mergeArrow"
							size={{ w: 45, h: 50 }}
							color="#22404D"
						/>
					</div>
					<SelectField
						async
						label="To teacher"
						isSearchable
						error={errors.teacher}
						disabled={isLoading}
						control={control}
						name="teacher"
						className={styles.selectField}
						placeholder={t('Teacher')}
						debounceTimeout={400}
						loadOptions={loadTeacherOptions}
						formatOptionLabel={({ label, ...row }) => (
							<div className={styles.selectFieldOption}>
								{row.verifiedTeacher && <Chip miniRadius>Verified</Chip>}
								<span style={{ marginLeft: 10 }}>
									{row.titlePrefixed} {row.firstName} {row.lastName} {row.titleSuffixed}
								</span>
							</div>
						)}
					/>

					<Btn
						loading={isLoading}
						disabled={isLoading}
						className={styles.btnSubmit}
						type="submit"
						icon="send"
						iconSize={{ w: 15, h: 15 }}
						iconColor="#fff"
					>
						{t('Merge')}
					</Btn>
				</form>
			)}
		</BaseModal>
	)
}
