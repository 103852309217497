import Joi from 'joi'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { useState, useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { joiResolver } from '@hookform/resolvers/joi'

import Btn from '../../../../UI/Btn'
import FlatLink from '../../../../UI/FlatLink'
import Checkbox from '../../../../UI/Checkbox'
import TextField from '../../../../UI/TextField'
import SelectField from '../../../../UI/SelectField'

import http from '../../../../shared/http'
import { schemaOptions } from '../../../../shared/i18n'
import { fullLogIn, useInvite } from '../../../../shared/helpers'
import { TRoutes, TRoles, TApi } from '../../../../shared/const'

import { setSignInMode } from '../../../../redux/ducks/user'

import styles from '../../common.module.scss'
import PolicyLinks from '../../../../UI/policyLinks'

function MainInfo(props) {
	const { t } = useTranslation()

	const schema = Joi.object({
		role: Joi.string().required(),
		email: Joi.string().email({ tlds: false }).required(),
		password: Joi.string().min(8).max(32).regex(/^\S+$/).required().messages({
			'string.pattern.base': 'Not allowed spaces !'
		}),
		repeatPassword: Joi.any()
			.empty('')
			.equal(Joi.ref('password'))
			.required()
			.error(errors => {
				return errors.map(error => {
					if (error.code === 'any.only') error.message = t('Repeat password does not match with Password')
					return error
				})
			}),

		confirmRight: Joi.boolean()
			.custom((value, helper) => {
				if (value) return value

				return helper.error('confirm.invalid')
			})
			// .invalid(false)
			.required()
	})

	const { isToken, role, email, invite } = useInvite()
	const [isLoading, setLoading] = useState(false)

	const {
		register,
		control,
		trigger,
		handleSubmit,
		formState: { errors, isValid }
	} = useForm({
		resolver: joiResolver(schema, {
			errors: {
				labels: false,
				language: localStorage.getItem('i18nextLng')
			},
			...schemaOptions
		}),
		mode: 'onChange',
		defaultValues: {
			email: email || ''
		}
	})

	useEffect(() => {
		if (Object.keys(errors).length) trigger()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [t])

	const onSubmit = fields => {
		setLoading(true)
		http.post(TApi.PUBLIC_AUTH_SING_UP, {
			role: fields.role,
			email: fields.email.toLowerCase(),
			password: fields.password,
			inviteToken: invite || null
		})
			.then(({ data }) => {
				fullLogIn(data.token, data.user)
				setSignInMode()
				props.onNext()
				toast.success(t('The letter was sent successfully'))
			})
			.finally(() => setLoading(false))
	}

	useEffect(() => {
		if (props.isSignInMode) props.onNext()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.isSignInMode])

	const getLink = () => (
		<a className={styles.linkDocument} href="/data-policy.pdf" download>
			{t('Privacy Statement')}
		</a>
	)

	return (
		<div className={styles.root}>
			<div className={styles.header}>
				<div className={styles.miniContainer}>
					<h2 className={styles.title}>{t('SignUp.noun')}</h2>

					<p className={clsx(styles.accountMessage, styles.accountMessageLogIn)}>
						{t('Have account?')}{' '}
						<FlatLink to={TRoutes.SIGN_IN} icon="arrow" iconSize={{ w: 10, h: 10 }} iconColor="#008B9C">
							{t('Sign In')}
						</FlatLink>
					</p>
				</div>
				<PolicyLinks />
			</div>
			<form
				className={clsx(styles.miniContainer, styles.form)}
				onSubmit={handleSubmit(onSubmit)}
				autoComplete="off"
			>
				<SelectField
					disabled={props.isLoading}
					required
					mb={20}
					label={t('Role')}
					control={control}
					name="role"
					error={errors.role}
					placeholder={t('Role')}
					options={[
						{
							value: TRoles.STUDENT,
							label: t('Student')
						},
						{
							value: TRoles.TEACHER,
							label: t('Teacher')
						}
					].filter(option => (isToken ? option.value.toLowerCase() === role.toLocaleLowerCase() : true))}
				/>
				<TextField
					required
					disabled={!!email || props.isLoading}
					mb={20}
					label="Email"
					placeholder={t('Your email')}
					error={errors.email}
					register={register('email')}
				/>
				<TextField
					disabled={props.isLoading}
					required
					mb={20}
					type="password"
					label={t('Password')}
					placeholder={t('Your password')}
					error={errors.password}
					register={register('password')}
				/>
				<TextField
					disabled={props.isLoading}
					required
					mb={35}
					type="password"
					label={t('Repeat password')}
					placeholder={t('Repeat password')}
					error={errors.repeatPassword}
					register={register('repeatPassword')}
				/>
				<Checkbox
					mb={22}
					error={errors.confirmRight}
					register={register('confirmRight')}
					label={
						<span>
							<Trans i18nKey="consent">
								I have read the declaration of data protection {getLink()} and consent to data
								processing
							</Trans>
						</span>
					}
				/>

				<Btn
					type="submit"
					disabled={!isValid}
					width={167}
					loading={isLoading}
					className={styles.btnSignUp}
					iconSize={{ w: 10, h: 10 }}
					iconColor="#fff"
					icon="arrow"
				>
					{t('SignUp.verb')}
				</Btn>
			</form>
		</div>
	)
}

MainInfo.propTypes = {
	onNext: PropTypes.func.isRequired,
	isSignInMode: PropTypes.bool.isRequired,

	setSignInMode: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
	isSignInMode: state.user.isSignInMode
})

const mapDispatchToProps = dispatch => ({
	setSignInMode: value => dispatch(setSignInMode(value))
})

export default connect(mapStateToProps, mapDispatchToProps)(MainInfo)
