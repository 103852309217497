import Joi from 'joi'
import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { joiResolver } from '@hookform/resolvers/joi'

import Btn from '../../../../../../UI/Btn'
import TextField from '../../../../../../UI/TextField'

import styles from '../style.module.scss'

const schema = Joi.object({
	invoicingReportingReminders: Joi.object().keys({
		firstReminderDaysInvoiceIssued: Joi.number().integer().min(1).required(),
		reminderFrequencyDays: Joi.number().integer().min(1).required()
	})
})

export default function InvocingReportingReminders(props) {
	const { t } = useTranslation()
	const {
		register,
		reset,
		handleSubmit,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema)
	})

	const onSubmit = form => {
		props
			.updateOptions({
				name: 'invoicingReportingReminders',
				value: form.invoicingReportingReminders
			})
			.then(() => toast.success(t('Options was updated')))
	}

	useEffect(() => {
		reset({
			invoicingReportingReminders: props.form?.invoicingReportingReminders || {}
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.form])
	return (
		<form id="invocingReportingReminders" className={styles.root} onSubmit={handleSubmit(onSubmit)}>
			<section className={styles.section}>
				<h3 className={styles.sectionTitle}>{t('Invoicing Reminders')}</h3>
				<div className={styles.sectionContent}>
					<div className={styles.flexContainer}>
						<TextField
							className={styles.field}
							label={t('1st reminder [x] days after invoice has been issued')}
							mb={20}
							register={register(`invoicingReportingReminders.firstReminderDaysInvoiceIssued`)}
							error={errors.invoicingReportingReminders?.firstReminderDaysInvoiceIssued}
						/>
					</div>
					<div className={styles.flexContainer}>
						<TextField
							className={styles.field}
							label={t('Reminder frequency each [x] day(s)')}
							mb={20}
							register={register(`invoicingReportingReminders.reminderFrequencyDays`)}
							error={errors.invoicingReportingReminders?.reminderFrequencyDays}
						/>
					</div>
					<div className={styles.btnContainer}>
						<Btn
							className={styles.btn}
							type="submit"
							icon="arrow"
							iconSize={{ w: 10, h: 10 }}
							iconColor="#fff"
						>
							{t('save')}
						</Btn>
					</div>
				</div>
			</section>
		</form>
	)
}

InvocingReportingReminders.propTypes = {
	form: PropTypes.object.isRequired,
	updateOptions: PropTypes.func.isRequired
}
