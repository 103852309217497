import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { Trans, useTranslation } from 'react-i18next'

import Btn from '../../../../UI/Btn'

import http from '../../../../shared/http'
import { TApi } from '../../../../shared/const'

import { updateProfile } from '../../../../redux/ducks/user'

import styles from '../../common.module.scss'
import PolicyLinks from '../../../../UI/policyLinks'

function AwaitConfirmEmail(props) {
	const { t } = useTranslation()

	const handleRefreshStatus = () => {
		http.get(TApi.COMMON_ME).then(({ data }) => {
			props.updateProfile(data)
			toast.success(t('Profile has been updated successfully.'))
		})
	}

	const handleResendEmail = () => {
		http.put(TApi.COMMON_RESEND_EMAIL).then(() => toast.success(t('The letter was sent successfully')))
	}

	useEffect(() => {
		if (props.isSignInMode && props.emailConfirmed) props.onNext()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.isSignInMode, props.emailConfirmed])

	return (
		<div>
			<PolicyLinks />
			<div className={styles.confirmContent}>
				<div>
					<h3 className={styles.minHeader}>{t('Register')}</h3>
					<h1 className={styles.confirmHeader}>{t('Please confirm your email')}</h1>
				</div>
				<span className={styles.confirmText}>
					<Trans i18nKey="confirmEmail">
						A confirmation email has been sent to [{props.email}]. Please click the confirmation link in
						this email to activate the account. Please also check your spam folder.
					</Trans>
				</span>
				<div className={styles.confirmBtns}>
					<Btn className={styles.btnEmail} theme="orange" width={284} onClick={handleResendEmail}>
						{t('Resend activation email')}
					</Btn>
					<Btn
						className={styles.btnEmail}
						icon="refresh"
						iconSize={{ w: 30, h: 30 }}
						iconColor="#fff"
						theme="orange"
						width={284}
						onClick={handleRefreshStatus}
					>
						{t('Refresh status')}
					</Btn>
				</div>
			</div>
		</div>
	)
}

AwaitConfirmEmail.propTypes = {
	onNext: PropTypes.func.isRequired,
	updateProfile: PropTypes.func.isRequired,
	email: PropTypes.string.isRequired,
	isSignInMode: PropTypes.bool.isRequired,
	emailConfirmed: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
	email: state.user.profile.email,
	isSignInMode: state.user.isSignInMode,
	emailConfirmed: state.user.profile.emailConfirmed
})

const mapDispatchToProps = dispatch => ({
	updateProfile: data => dispatch(updateProfile(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AwaitConfirmEmail)
