import AddFeedModal from './componets/AddFeedModal'
import ViewFeedModal from './componets/ViewFeedModal'
import AddExpertModal from './componets/AddExpertModal'
import AddTeacherModal from './componets/AddTeacherModal'
import AddEmployeeModal from './componets/AddEmployeeModal'
import MergeSchoolsModal from './componets/MergeSchoolsModal'
import MergeTeachersModal from './componets/MergeTeachersModal'
import AddShareholderModal from './componets/AddShareholderModal'
import CheckConfirmAccountModal from './componets/CheckConfirmAccountModal'
import RejectCompanyCommentModal from './componets/RejectCompanyCommentModal'

import CustomizeCompaniesModal from './componets/CustomizeWidgetModals/CustomizeCompaniesModal'
import CustomizeIncomeModal from './componets/CustomizeWidgetModals/CustomizeIncomeModal'
import CustomizeMultiModal from './componets/CustomizeWidgetModals/CustomizeMultiModal'
import CustomizeSimpleWidgetModal from './componets/CustomizeSimpleWidgetModal'

import AddAdmin from './componets/AddAdmin'
import CompanyRisk from './componets/CompanyRisk'
import CompanyProfile from './componets/CompanyProfile'
import AddSchool from './componets/AddSchool'
import AddTeacher from './componets/AddTeacher'
import StudentProfile from './componets/StudentProfile'
import InvoiceChangeData from './componets/InvoiceChangeData'
import StudentWorkAreaModal from './componets/StudentWorkAreaModal'
import CropperModal from './componets/CropperModal'

export default function Modals() {
	return (
		<div>
			<AddAdmin />
			<CompanyRisk />
			<AddFeedModal />
			<ViewFeedModal />
			<CompanyProfile />
			<AddExpertModal />
			<AddTeacherModal />
			<AddEmployeeModal />
			<MergeSchoolsModal />
			<MergeTeachersModal />
			<CustomizeMultiModal />
			<AddShareholderModal />
			<CustomizeIncomeModal />
			<CustomizeCompaniesModal />
			<CheckConfirmAccountModal />
			<RejectCompanyCommentModal />
			<CustomizeSimpleWidgetModal />
			<AddSchool />
			<AddTeacher />
			<StudentProfile />
			<InvoiceChangeData />
			<StudentWorkAreaModal />
			<CropperModal />
		</div>
	)
}
