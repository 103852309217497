import { useEffect, useState } from 'react'

import styles from './style.module.scss'
import Filter from '../../Filter'
import Pagination from '../../Pagination'
import Search from '../../Search'
import BtnFilter from '../../BtnFilter'
import { TApi, TRoutes } from '../../../shared/const'
import http from '../../../shared/http'
import SingleData from '../../Widget/singleData'
import FlatLink from '../../FlatLink'
import CardCompany from '../../../components/views/common/CardCompany'
import { useTranslation } from 'react-i18next'
import LimitFilterButton from '../../LimitFilterButton'
import { setCompanyPresentationFilter } from '../../../redux/ducks/filter'
import { connect } from 'react-redux'
import { generateFilter } from '../../../shared/helpers'

function CompaniesPresentation(props) {
	const { t } = useTranslation()
	const [companies, setCompanies] = useState([])
	const [statistic, setStatistic] = useState([])
	const [countAll, setCountAll] = useState(0)
	const [page, setPage] = useState(0)
	const [search, setSearch] = useState('')
	const [showFilter, setShowFilter] = useState(false)
	const [countFilters, setCountFilters] = useState(0)
	const [loading, setLoading] = useState(false)
	const [limit, setLimit] = useState(30)

	const options = [
		{ value: 30, label: '30' },
		{ value: 60, label: '60' },
		{ value: 90, label: '90' },
		{ value: 120, label: '120' },
		{ value: 150, label: '150' }
	]

	const fetchStatistic = () => {
		http.get(TApi.COMMON_GET_STATS).then(({ data }) => {
			setStatistic(data)
		})
	}

	const fetchAllCompanies = () => {
		const filter = generateFilter(props.companyPresentationFilter)

		setLoading(true)
		http.get(TApi.COMMON_GET_COMPANIES, {
			params: {
				limit,
				offset: page * limit,
				search,
				filter: JSON.stringify(filter)
			}
		})
			.then(({ data }) => {
				setCompanies(data.allCompanies)
				setCountAll(data.count)
			})
			.finally(() => setLoading(false))
	}

	const handleChangeLimit = selectedOption => {
		setLimit(selectedOption.value)
	}

	useEffect(() => {
		fetchAllCompanies()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [limit, page])

	const handleSearch = e => {
		if (page === 0) fetchAllCompanies()
		setPage(0)
	}

	useEffect(() => {
		fetchStatistic()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	return (
		<div className={styles.root}>
			<div className={styles.miniHeaderContent}>
				<FlatLink to={TRoutes.SIGN_IN} className={styles.btnSignIn}>
					{`${t('Sign In')} / ${t('Registration')}`}
				</FlatLink>
			</div>
			<div className={styles.miniHeaderContent}>
				<Search
					value={search}
					className={styles.search}
					placeholder={t('Search companies...')}
					onChange={e => setSearch(e.target.value)}
					onSubmit={e => {
						e.preventDefault()
						handleSearch()
					}}
				/>
				<BtnFilter
					loading={loading}
					className={styles.filterBtn}
					icon="arrow"
					width={150}
					onClick={() => setShowFilter(true)}
					count={countFilters}
				>
					Filter ({countFilters})
				</BtnFilter>
			</div>
			<div className={styles.subHeader}>
				<Pagination limit={limit} page={page} label={t('companies')} countAll={countAll} onChange={setPage} />
				<LimitFilterButton limit={limit} options={options} onChange={handleChangeLimit} />
			</div>
			{countFilters === 0 && (
				<div className={styles.widgetsContainer}>
					{statistic.map(el => (
						<SingleData header={el.name} data={el.total} />
					))}
				</div>
			)}
			<div className={styles.companiesContainer}>
				{companies.map(company => (
					<CardCompany company={company} />
				))}
			</div>
			<div className={styles.subHeader}>
				<Pagination limit={limit} page={page} label={t('companies')} countAll={countAll} onChange={setPage} />
			</div>
			<Filter
				toShow={{
					states: true,
					companyTypes: true,
					branches: true,
					schools: true,
					schoolType: true
				}}
				onSearch={handleSearch}
				showFilter={showFilter}
				setShowFilter={setShowFilter}
				onCountFilters={setCountFilters}
				onFilterOptions={props.setFilter}
				filters={props.companyPresentationFilter}
				isPublic
			/>
		</div>
	)
}

const mapStateToProps = state => ({
	companyPresentationFilter: state.filter.companyPresentations
})

const mapDispatchToProps = dispatch => ({
	setFilter: data => dispatch(setCompanyPresentationFilter(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesPresentation)
