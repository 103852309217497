import PropTypes from 'prop-types'

import styles from '../style.module.scss'
import Btn from '../../../../../../UI/Btn'
import TextField from '../../../../../../UI/TextField'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useEffect } from 'react'
import { optionsDate } from '../../../../../../shared/const'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import TextFieldNumber from '../../../../../../UI/TextFieldNumber'

function CalculationOfLevisToJunior(props) {
	const { t } = useTranslation()

	const {
		control,
		register,
		handleSubmit,
		reset,
		formState: { errors }
	} = useForm()

	useEffect(() => {
		reset({
			reducedCorporateIncomeTax: props.form?.reducedCorporateIncomeTax,
			juniorInsurance: props.juniorInsurance,
			profitLossInsuranceContribution: props.form?.profitLossInsuranceContribution,
			taxesTransferredToJuniorAustria:
				props.form?.taxesTransferredToJuniorAustria ||
				Math.round(
					(props.form?.reducedCorporateIncomeTax +
						props.juniorInsurance +
						props.form?.profitLossInsuranceContribution) *
						100
				) / 100,
			annualReportDate:
				props.form?.annualReportDate &&
				new Date(props.form?.annualReportDate).toLocaleDateString('de-DE', optionsDate)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.form])

	const onSubmit = form => {
		let data = form

		for (const key in data) {
			if (data[key] === '') {
				data[key] = null
			}
		}
		delete data.juniorInsurance
		props
			.updateData({
				...data,
				annualReportDate: new Date().toISOString(),
				isSubmitted: true
			})
			.then(() => {
				toast.success(t('Calculation of levies to Junior was submitted'))
				toast.success(t('Invoice created, you can find it in documents'))
			})
	}

	if (props.currentStep !== props.needStep) return null
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<section className={styles.section} style={{ border: 'none' }}>
				<h1 className={styles.sectionTitle}>{t('Calculation of levies to Junior')}</h1>
				<div className={styles.sectionContent}>
					<p className={styles.description}>
						<p>{t('Levies to be transferred to Junior Austria')}</p>
						{t(
							'According to this schedule, levies are to be remitted to the account indicated in the invoice.'
						)}
					</p>
					<TextFieldNumber
						label={t('Reduced corporate income tax')}
						name="reducedCorporateIncomeTax"
						control={control}
						error={errors.reducedCorporateIncomeTax}
						disabled
						mb={13}
						placeholder="00.000,00"
					/>
					<TextFieldNumber
						label={`+ ${t('insurance contribution')}`}
						name="juniorInsurance"
						control={control}
						error={errors.juniorInsurance}
						disabled
						mb={13}
						placeholder="00.000,00"
					/>
					<div className={styles.flexContainer}>
						<TextFieldNumber
							label={t('+ 13% salary-related social security contributions')}
							name="profitLossInsuranceContribution"
							control={control}
							error={errors.profitLossInsuranceContribution}
							disabled
							mb={13}
							placeholder="00.000,00"
						/>
						<TextFieldNumber
							label={t('= Taxes to be transferred to Junior Austria')}
							name="taxesTransferredToJuniorAustria"
							classNameInput={styles.calculated}
							control={control}
							error={errors.taxesTransferredToJuniorAustria}
							disabled
							mb={16}
							placeholder="00.000,00"
						/>
					</div>
					{props.form?.isSubmitted && (
						<TextField
							disabled
							mb={20}
							label={t('Report was successfully submitted.')}
							register={register('annualReportDate')}
							error={errors.annualReportDate}
							placeholder="dd.mm.yyyy hh:mm:ss"
						/>
					)}
					{!props.form?.isSubmitted && !props.isTeacherStudentView ? (
						<Btn className={styles.btn} loading={props.isLoading} type="submit" width={337}>
							{t('Submit')}
						</Btn>
					) : (
						<Btn
							className={styles.btn}
							loading={props.isLoading}
							onClick={props.handlerPrevStep}
							width={337}
						>
							{t('Previous')}
						</Btn>
					)}
				</div>
			</section>
		</form>
	)
}
CalculationOfLevisToJunior.propTypes = {
	form: PropTypes.object,
	updateData: PropTypes.func,
	currentStep: PropTypes.number,
	needStep: PropTypes.number,
	handlerNextStep: PropTypes.func,
	handlerPrevStep: PropTypes.func,
	juniorInsurance: PropTypes.number,
	isTeacherStudentView: PropTypes.bool.isRequired
}
const mapStateToProps = state => ({
	juniorInsurance: state.options.juniorInsurance?.JCC,
	isTeacherStudentView: state.user.isTeacherStudentView
})
export default connect(mapStateToProps)(CalculationOfLevisToJunior)
