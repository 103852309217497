import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import TextAreaField from '../../../../../../UI/TextAreaField'

import styles from '../../style.module.scss'

function School(props) {
	const [school, setSchool] = useState('')
	const { t } = useTranslation()

	const getSchoolFullInfoValue = () => {
		return `${props.school.name} - ${props.school.type} - ${props.school.streetName}`
	}

	useEffect(() => {
		setSchool(getSchoolFullInfoValue())

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.school])

	return (
		<div className={styles.section}>
			<h1 className={styles.sectionTitle}>{t('school')}</h1>
			<div className={styles.sectionContent}>
				<div className={styles.flexContainer} style={{ justifyContent: 'space-between' }}>
					<TextAreaField
						disabled
						register={{ value: school }}
						classNameLabel={styles.schoolTextarea}
						mb={20}
						name="school"
						placeholder={t('Select')}
					/>
				</div>
			</div>
		</div>
	)
}

School.props = {
	school: PropTypes.object
}

const mapStateToProps = state => ({
	school: state.user.profile.School
})

export default connect(mapStateToProps)(School)
