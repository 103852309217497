import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'

import i18n from './shared/i18n'
import store from './redux'
import reportWebVitals from './reportWebVitals'

ReactDOM.render(
	<Provider store={store}>
		<React.StrictMode>
			<Router>
				<I18nextProvider i18n={i18n}>
					<App />
				</I18nextProvider>
			</Router>
		</React.StrictMode>
	</Provider>,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
