import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TApi, TRoutes } from '../../../../shared/const'

import Btn from '../../../../UI/Btn'
import Icon from '../../../../UI/Icon'
import QuickList from '../../../../UI/QuickList'
import GeneralInfoWidgets from '../GeneralInfoWidgets'

import quickListStyles from '../../common/quickList.module.scss'
import styles from './style.module.scss'
import http from '../../../../shared/http'
import StudentItem from '../../../../UI/QuickList/components/StudentItem'
import CompanyItem from '../../../../UI/QuickList/components/CompanyItem'

export default function Dashboard() {
	const { t } = useTranslation()
	const [isActiveWidgetBar, setActiveWidgetBar] = useState(false)
	const [data, setData] = useState({
		lastCompanies: [],
		lastStudents: [],
		lastTeachers: []
	})

	const handleWidgetBar = () => setActiveWidgetBar(!isActiveWidgetBar)

	useEffect(() => {
		http.get(TApi.ADMIN_GET_DASHBOARD).then(({ data }) => {
			setData(data)
		})
	}, [])

	return (
		<div className={styles.root}>
			<div className={clsx('container', styles.header)}>
				<h2 className={styles.title}>Dashboard</h2>
			</div>
			<div className={isActiveWidgetBar ? clsx(styles.generalInfoItems, styles.active) : styles.generalInfoItems}>
				<div className={styles.generalInfoHeader}>
					<Icon noFill size={{ w: 24, h: 21 }} name="ticket" />
					<h3 className={styles.generalInfoTitle}>{t('General Info')}</h3>
					<Btn
						className={styles.btnCustomise}
						onClick={handleWidgetBar}
						width={129}
						iconOrder={0}
						theme="grey"
						icon="filter"
					>
						{t('Customise')}
					</Btn>
				</div>
				<div className={styles.generalInfoWidgets}>
					<GeneralInfoWidgets isActiveBar={isActiveWidgetBar} handleWidgetBar={handleWidgetBar} />
				</div>
			</div>
			<div className={isActiveWidgetBar ? clsx(styles.container, styles.active) : 'container'}>
				<div className={styles.quickListsHeader}>
					<h3 className={styles.quickListsTitle}>{t('Quick Lists')}</h3>
				</div>
				<div className={clsx(quickListStyles.quickLists, styles.quickListMb)}>
					<QuickList
						linkTitle={t('Full list')}
						className={quickListStyles.quickList}
						title={t('Last Companies')}
						icon
						items={data.lastCompanies}
						toFullList={TRoutes.ADMIN_COMPANIES}
					>
						{item => <CompanyItem id={item.id} name={item.name} members={Number(item.employeesCount)} />}
					</QuickList>
					<QuickList
						linkTitle={t('Full list')}
						className={quickListStyles.quickList}
						title={t('Last Students')}
						icon
						items={data.lastStudents}
						toFullList={TRoutes.USERS}
					>
						{item => (
							<StudentItem
								id={item.id}
								avatar={TApi.PUBLIC_PROFILE_AVATAR(item.avatarHashName)}
								name={`${item.firstName} ${item.lastName}`}
							/>
						)}
					</QuickList>
					<QuickList
						linkTitle={t('Full list')}
						className={quickListStyles.quickList}
						title={t('Last Teachers')}
						icon
						items={data.lastTeachers}
						toFullList={TRoutes.USERS}
					>
						{item => (
							<StudentItem
								id={item.id}
								avatar={TApi.PUBLIC_PROFILE_AVATAR(item.avatarHashName)}
								name={`${item.firstName} ${item.lastName}`}
							/>
						)}
					</QuickList>
				</div>
			</div>
		</div>
	)
}
