const name = 'filter'

const SET_COMPANY_FILTER = `${name}/SET_COMPANY_FILTER`
const SET_USERS_FILTER = `${name}/SET_USERS_FILTER`
const SET_DOCUMENTS_FILTER = `${name}/SET_DOCUMENTS_FILTER`
const SET_REPORTS_FILTER = `${name}/SET_REPORTS_FILTER`
const SET_ECONOMIC_EXPERTS_FILTER = `${name}/SET_ECONOMIC_EXPERTS_FILTER`
const SET_INVOICES_FILTER = `${name}/SET_INVOICESas_FILTER`
const SET_SCHOOL_FILTER = `${name}/SET_SCHOOL_FILTER`
const SET_NEWS_FILTER = `${name}/SET_NEWS_FILTER`
const SET_STUDENTS_FILTER = `${name}/SET_STUDENTS_FILTER`
const SET_COMPANY_PRESENTATIONS_FILTER = `${name}/SET_COMPANY_PRESENTATIONS_FILTER`
const initialState = () => ({
	companies: {
		state: [],
		companyType: [],
		branch: [],
		school: [],
		teacher: [],
		companyStatus: [],
		schoolTypes: []
	},
	schools: {
		state: [],
		schoolType: [],
		schoolStatus: [],
		companyTypes: []
	},
	users: {
		state: [],
		role: [],
		branch: [],
		school: [],
		schoolType: [],
		teacherStatus: []
	},
	reports: {
		state: [],
		companyType: [],
		branch: []
	},
	invoices: {
		state: [],
		companyType: [],
		branch: [],
		school: [],
		invoiceStatus: []
	},
	economicExperts: {
		state: [],
		emailStatus: [],
		status: []
	},
	documents: {
		documentType: []
	},
	news: {
		state: [],
		schoolType: [],
		companyType: [],
		role: [],
		branch: [],
		newsStatus: []
	},
	students: {
		reviewedParentalConfirmation: [],
		isCEO: []
	},
	companyPresentations: {
		state: [],
		schoolType: [],
		companyType: [],
		branches: [],
		schools: []
	}
})

export default function reducer(state = initialState(), action = {}) {
	const { payload } = action
	switch (action.type) {
		case SET_COMPANY_FILTER: {
			return {
				...state,
				companies: payload
			}
		}
		case SET_USERS_FILTER: {
			return {
				...state,

				users: payload
			}
		}
		case SET_DOCUMENTS_FILTER: {
			return {
				...state,
				documents: payload
			}
		}
		case SET_REPORTS_FILTER: {
			return {
				...state,
				reports: payload
			}
		}
		case SET_ECONOMIC_EXPERTS_FILTER: {
			return {
				...state,
				economicExperts: payload
			}
		}
		case SET_INVOICES_FILTER: {
			return {
				...state,
				invoices: payload
			}
		}
		case SET_SCHOOL_FILTER: {
			return {
				...state,
				schools: payload
			}
		}
		case SET_NEWS_FILTER: {
			return {
				...state,
				news: payload
			}
		}
		case SET_STUDENTS_FILTER: {
			return {
				...state,
				students: payload
			}
		}
		case SET_COMPANY_PRESENTATIONS_FILTER: {
			return {
				...state,
				companyPresentations: payload
			}
		}
		default: {
			return state
		}
	}
}

export function setCompanyFilter(data) {
	return {
		type: SET_COMPANY_FILTER,
		payload: data
	}
}

export function setSchoolFilter(data) {
	return {
		type: SET_SCHOOL_FILTER,
		payload: data
	}
}
export function setUsersFilter(data) {
	return {
		type: SET_USERS_FILTER,
		payload: data
	}
}

export function setDocumentsFilter(data) {
	return {
		type: SET_DOCUMENTS_FILTER,
		payload: data
	}
}

export function setEconomicExpertsFilter(data) {
	return {
		type: SET_ECONOMIC_EXPERTS_FILTER,
		payload: data
	}
}

export function setReportsFilter(data) {
	return {
		type: SET_REPORTS_FILTER,
		payload: data
	}
}
export function setInvoicesFilter(data) {
	return {
		type: SET_INVOICES_FILTER,
		payload: data
	}
}
export function setNewsFilter(data) {
	return {
		type: SET_NEWS_FILTER,
		payload: data
	}
}

export function setStudentsFilter(data) {
	return {
		type: SET_STUDENTS_FILTER,
		payload: data
	}
}
export function setCompanyPresentationFilter(data) {
	return {
		type: SET_COMPANY_PRESENTATIONS_FILTER,
		payload: data
	}
}
