import React from 'react'
import styles from '../../style.module.scss'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import Btn from '../../../../../../../../UI/Btn'
import clsx from 'clsx'
import { toast } from 'react-toastify'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { updateProfile } from '../../../../../../../../redux/ducks/user'
import TextFieldNumber from '../../../../../../../../UI/TextFieldNumber'
import { format } from 'date-fns'
import dangerIcon from '../../../../../../../../assets/icons/Danger Triangle.svg'
import Decimal from 'decimal.js'

function CompanySemiAnnualReport(props) {
	const { t } = useTranslation()
	const {
		control,
		watch,
		reset,
		handleSubmit,
		formState: { errors }
	} = useForm()
	const advanceReturnValueAddedTaxWatch = watch('advanceReturnValueAddedTax')
	const onSubmit = form => {
		let data = form
		data.netProfitLoss = form.semiAnnualReportResultTotal
		delete data.grossSalariesEmployeeTotal
		delete data.socialSecurityContributionEmployee
		delete data.wageTax
		delete data.socialSecurityContributionEmployer
		delete data.advanceVatReturnTotalIncome
		delete data.advanceVatReturnTotalExpenses
		delete data.revenues
		delete data.materialExpensesItems
		delete data.otherExpensesItems
		delete data.juniorInsurance

		props
			.updateData({
				...data,
				isSubmitted: true,
				annualReportDate: new Date().toISOString(),
				taxesTransferredToJuniorAustria: data.semiAnnualReportTotalDuties
			})
			.then(() => {
				props.updateProfile({
					reportPeriod: { SEMIANNUAL: true, ANNUAL: false }
				})
				toast.success(t('Calculation half-year result was submitted'))
				toast.success(t('Invoice created, you can find it in documents'))
			})
	}

	useEffect(() => {
		const semiAnnualReportPersonellCosts = props.form?.grossSalariesEmployeeTotal
			? new Decimal(props.form.grossSalariesEmployeeTotal)
					.times(0.06)
					.plus(props.form.grossSalariesEmployeeTotal)
					.toDecimalPlaces(2)
					.toNumber()
			: 0

		const semiAnnualReportResultTotal = new Decimal(props.form?.advanceVatReturnNetIncomeTotal || 0)
			.minus(props.form?.advanceVatReturnNetExpenses || 0)
			.minus(semiAnnualReportPersonellCosts)
			.minus(props.form?.expensesTaxFreeTotal || 0)
			.minus(props.juniorInsurance)
			.toDecimalPlaces(2)
			.toNumber()

		const semiAnnualReportWagesSalariesContributions =
			props.form?.socialSecurityContributionEmployee &&
			props.form?.wageTax &&
			props.form?.socialSecurityContributionEmployer
				? new Decimal(props.form.socialSecurityContributionEmployee)
						.plus(props.form.wageTax)
						.plus(props.form.socialSecurityContributionEmployer)
						.toDecimalPlaces(2)
						.toNumber()
				: 0

		const semiAnnualReportTotalDuties =
			semiAnnualReportWagesSalariesContributions !== undefined && props.juniorInsurance !== undefined
				? new Decimal(semiAnnualReportWagesSalariesContributions)
						.plus(props.juniorInsurance)
						.plus(props.form?.advanceReturnValueAddedTax > 0 ? props.form.advanceReturnValueAddedTax : 0)
						.toDecimalPlaces(2)
						.toNumber()
				: 0

		reset({
			juniorInsurance: props.juniorInsurance,
			semiAnnualReportPersonellCosts:
				props.form?.semiAnnualReportPersonellCosts || semiAnnualReportPersonellCosts,
			semiAnnualReportResultTotal: semiAnnualReportResultTotal || 0,
			semiAnnualReportWagesSalariesContributions:
				props.form?.semiAnnualReportWagesSalariesContributions || semiAnnualReportWagesSalariesContributions,
			semiAnnualReportTotalDuties: props.form?.semiAnnualReportTotalDuties || semiAnnualReportTotalDuties,

			advanceVatReturnNetIncomeTotal: props.form?.advanceVatReturnNetIncomeTotal,
			advanceVatReturnNetExpenses: props.form?.advanceVatReturnNetExpenses,
			grossSalariesEmployeeTotal: props.form?.grossSalariesEmployeeTotal,
			expensesTaxFreeTotal: props.form?.expensesTaxFreeTotal,

			advanceVatReturnTotalTaxedIncome: props.form?.advanceVatReturnTotalTaxedIncome,
			advanceVatReturnTotalTaxedExpenses: props.form?.advanceVatReturnTotalTaxedExpenses,
			advanceReturnValueAddedTax: props.form?.advanceReturnValueAddedTax,
			socialSecurityContributionEmployee: props.form?.socialSecurityContributionEmployee,
			wageTax: props.form?.wageTax,
			socialSecurityContributionEmployer: props.form?.socialSecurityContributionEmployer,

			advanceVatReturnTotalIncome: props.form?.advanceVatReturnTotalIncome,
			advanceVatReturnTotalExpenses: props.form?.advanceVatReturnTotalExpenses,
			revenues: props.form?.revenues,
			materialExpensesItems: props.form?.materialExpensesItems,
			otherExpensesItems: props.form?.otherExpensesItems
		})

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.form])

	if (props.currentStep !== props.needStep) return null

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<section className={styles.section}>
				<h3 className={styles.sectionTitle}>{t('Calculation half-year result')}</h3>
				<div className={styles.sectionContent}>
					<p className={styles.description}>
						{props.form?.isSubmitted &&
							`${t(
								'The semi-annual report was successfully submitted and cannot be changed now. Date'
							)}: ${format(new Date(props.form?.annualReportDate), 'yyyy-MM-dd hh:mm')}`}
					</p>
				</div>
			</section>
			<section className={styles.section}>
				<h3 className={styles.sectionTitle}>{t('Statement of income/expenses')}</h3>
				<div className={styles.sectionContent}>
					<div className={styles.flexContainer}>
						<TextFieldNumber
							disabled
							mb={20}
							label={t('Sales net in €')}
							name={'advanceVatReturnNetIncomeTotal'}
							control={control}
							placeholder="00.000,00"
							error={errors.advanceVatReturnNetIncomeTotal}
						/>

						<TextFieldNumber
							disabled
							mb={20}
							label={t('less material and other costs net in €')}
							name={'advanceVatReturnNetExpenses'}
							control={control}
							placeholder="00.000,00"
							error={errors.advanceVatReturnNetExpenses}
						/>
					</div>
					<div className={styles.flexContainer}>
						<TextFieldNumber
							disabled
							mb={20}
							label={t('less personnel costs incl. all taxes in €')}
							name={'semiAnnualReportPersonellCosts'}
							control={control}
							placeholder="00.000,00"
							error={errors.semiAnnualReportPersonellCosts}
						/>

						<TextFieldNumber
							disabled
							mb={20}
							label={t('less other expenses not subject to sales tax in €')}
							name={'expensesTaxFreeTotal'}
							control={control}
							placeholder="00.000,00"
							error={errors.expensesTaxFreeTotal}
						/>
					</div>
					<div className={styles.flexContainer}>
						<TextFieldNumber
							className={styles.disabledInput}
							disabled
							mb={20}
							label={t('less junior insurance in €')}
							error={errors.juniorInsurance}
							placeholder="00.000,00"
							name={'juniorInsurance'}
							control={control}
						/>

						<TextFieldNumber
							disabled
							classNameInput={styles.calculated}
							mb={20}
							label={t('Half-year result total in €')}
							name={'semiAnnualReportResultTotal'}
							control={control}
							placeholder="00.000,00"
							error={errors.semiAnnualReportResultTotal}
						/>
					</div>
				</div>
			</section>
			<section className={styles.section}>
				<h3 className={styles.sectionTitle}>{t('Calculation half-year expenses')}</h3>
				<div className={styles.sectionContent}>
					<TextFieldNumber
						disabled
						mb={20}
						label={t('Sales taxes collected in €')}
						name={'advanceVatReturnTotalTaxedIncome'}
						control={control}
						placeholder="00.000,00"
						error={errors.advanceVatReturnTotalTaxedIncome}
					/>

					<TextFieldNumber
						disabled
						mb={20}
						label={t('less input taxes in €')}
						name={'advanceVatReturnTotalTaxedExpenses'}
						control={control}
						placeholder="00.000,00"
						error={errors.advanceVatReturnTotalTaxedExpenses}
					/>

					<div className={styles.flexContainer}>
						<TextFieldNumber
							disabled
							mb={20}
							label={t('Advance return of the value added tax (UVA)')}
							name={'advanceReturnValueAddedTax'}
							control={control}
							placeholder="00.000,00"
							error={errors.advanceReturnValueAddedTax}
						/>
						{props.showPayCredit && (
							<p className={styles.paymentCredit}>
								<img className={styles.warningIcon} src={dangerIcon} alt="" />
								{advanceReturnValueAddedTaxWatch > 0 ? t('Debit note') : t('Credit note')}
							</p>
						)}
					</div>
					<TextFieldNumber
						disabled
						mb={20}
						label={t('Wage and salary contributions in €')}
						name={'semiAnnualReportWagesSalariesContributions'}
						control={control}
						placeholder="00.000,00"
						error={errors.semiAnnualReportWagesSalariesContributions}
					/>
					<TextFieldNumber
						className={styles.disabledInput}
						disabled
						mb={20}
						label={t('plus junior insurance in €')}
						error={errors.juniorInsurance}
						placeholder="00.000,00"
						name={'juniorInsurance'}
						control={control}
					/>

					<TextFieldNumber
						disabled
						classNameInput={styles.calculated}
						mb={20}
						label={t('Total charges in €')}
						name={'semiAnnualReportTotalDuties'}
						control={control}
						placeholder="00.000,00"
						error={errors.semiAnnualReportTotalDuties}
					/>

					<div className={clsx(styles.flexContainer, styles.btnContainer)}>
						{props.form?.isSubmitted || props.isTeacherStudentView ? (
							<Btn className={styles.btn} type="button" onClick={() => props.handlerPrevStep()}>
								{t('Previous')}
							</Btn>
						) : (
							<Btn
								disabled={props.form?.isSubmitted}
								loading={props.isLoading}
								className={styles.btn}
								type="submit"
								width={337}
							>
								{t('Submit half-yearly financial statements')}
							</Btn>
						)}
					</div>
				</div>
			</section>
		</form>
	)
}

CompanySemiAnnualReport.propTypes = {
	form: PropTypes.object,
	updateData: PropTypes.func,

	currentStep: PropTypes.number,
	needStep: PropTypes.number,
	handlerPrevStep: PropTypes.func,
	juniorInsurance: PropTypes.number,
	updateProfile: PropTypes.func.isRequired,
	isTeacherStudentView: PropTypes.bool.isRequired
}
const mapStateToProps = state => ({
	juniorInsurance: state.options.juniorInsurance?.JC,
	isTeacherStudentView: state.user.isTeacherStudentView
})
const mapDispatchToProps = dispatch => ({
	updateProfile: data => dispatch(updateProfile(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(CompanySemiAnnualReport)
