import { Redirect, Route, Switch } from 'react-router-dom'

import Profile from '../../pages/Profile'
import NotFound from '../../pages/NotFound'
import { TRoutes } from '../../shared/const'
import Dashboard from '../../pages/Dashboard'
import CreateSchool from '../../pages/CreateSchool'
import CompanyProfile from '../../pages/CompanyProfile'
import CompanyReview from '../../pages/CompanyReview'
import CompanyEmployee from '../../pages/CompanyEmployee'
import CompanyInsurance from '../../pages/CompanyInsurance'
import InvoicesPage from '../../pages/AdminPages/Invoice'
import UsersPage from '../../pages/AdminPages/Users'
import AdminsPage from '../../pages/AdminPages/Admins'
import SchoolsPage from '../../pages/AdminPages/Schools'
import CompaniesPage from '../../pages/AdminPages/Companies'
import EmailTemplatesPage from '../../pages/AdminPages/EmailTemplates'
import NewEmailTemplatePage from '../../pages/AdminPages/NewEmailTemplate'
import CompanyExperts from '../../components/views/student/CompanyExperts'

import CompaniesList from '../../pages/CompaniesList'
import StudentsList from '../../pages/StudentsList'
import DocumentsList from '../../pages/DocumentsList'
import Shares from '../../pages/Shares'
import News from '../../pages/News'
import Reports from '../../pages/Reports'
import ReportsTable from '../../pages/ReportsTable'
import UpdateSchools from '../../pages/UpdateSchools'
import NotificationPage from '../../pages/Notification'

import ConfirmEmailChange from '../../components/views/teacher/Profile/components/ConfirmEmailChange'
import StudentEmailConfirm from '../../components/views/student/ConfirmEmailChange'
import AdminEmailConfirm from '../../components/views/admin/Profile/components/ConfirmEmailChange'
import ExpertsPage from '../../pages/AdminPages/Experts'
import Settings from '../../pages/Settings'
import EditCompany from '../../pages/AdminPages/EditCompany'
import DocumentsPage from '../../pages/AdminPages/Documents'
import ReportReview from '../../pages/AdminPages/ReportReview'

function Main() {
	return (
		<Switch>
			<Route path={TRoutes.DASHBOARD} exact component={Dashboard} />
			<Route path={TRoutes.COMPANY} exact component={CompanyProfile} />
			<Route path={TRoutes.COMPANY_REVIEW()} exact component={CompanyReview} />
			<Route path={TRoutes.STUDENTS} exact component={StudentsList} />

			<Route path={TRoutes.SHARES} exact component={Shares} />

			<Route path={TRoutes.COMPANIES} exact component={CompaniesList} />

			<Route path={TRoutes.DOCUMENTS} exact component={DocumentsList} />

			<Route path={TRoutes.PROFILE} exact component={Profile} />

			<Route path={TRoutes.CREATE_SCHOOL} exact>
				<CreateSchool />
			</Route>

			<Route path={TRoutes.UPDATE_SCHOOL()} exact>
				<UpdateSchools />
			</Route>

			<Route path={TRoutes.COMPANY} exact component={CompanyProfile} />
			<Route path={TRoutes.COMPANY_INSURANCE} exact component={CompanyInsurance} />
			<Route path={TRoutes.COMPANY_EMPLOYEE} exact component={CompanyEmployee} />
			<Route path={TRoutes.COMPANY_EXPERTS} exact component={CompanyExperts} />
			<Route path={TRoutes.UPDATE_COMPANY()} exact component={EditCompany} />

			<Route path={TRoutes.REPORTS} exact component={Reports} />
			<Route path={TRoutes.REPORTS_TABLE} exact component={ReportsTable} />
			<Route path={TRoutes.REPORT_REVIEW()} exact component={ReportReview} />
			<Route path={TRoutes.USERS} exact component={UsersPage} />
			<Route path={TRoutes.INVOICES} exact component={InvoicesPage} />
			<Route path={TRoutes.ADMINS} exact component={AdminsPage} />
			<Route path={TRoutes.SCHOOLS} exact component={SchoolsPage} />
			<Route path={TRoutes.ADMIN_COMPANIES} exact component={CompaniesPage} />
			<Route path={TRoutes.ADMIN_DOCUMENTS} exact component={DocumentsPage} />
			<Route path={TRoutes.EMAILS} exact component={EmailTemplatesPage} />
			<Route path={TRoutes.NEW_EMAIL_TEMPLATE} exact component={NewEmailTemplatePage} />
			<Route path={TRoutes.NEWS} exact component={News} />
			<Route path={TRoutes.EXPERTS} exact component={ExpertsPage} />
			<Route path={TRoutes.NOTIFICATIONS} exact component={NotificationPage} />
			<Route path={TRoutes.SETTINGS} exact component={Settings} />

			<Route path={TRoutes.EMAIL_CHANGE_CONFIRM} exact component={ConfirmEmailChange} />
			<Route path={TRoutes.STUDENT_EMAIL_CHANGE_CONFIRM} exact component={StudentEmailConfirm} />
			<Route path={TRoutes.ADMIN_EMAIL_CHANGE_CONFIRM} exact component={AdminEmailConfirm} />
			<Route exact path={TRoutes.NOT_FOUND} component={NotFound} />
			<Redirect exact from="*" to={TRoutes.NOT_FOUND} />
		</Switch>
	)
}

export default Main
