import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Btn from '../../UI/Btn'
import styles from './style.module.scss'
import { logIn, setTeacherCompanyView } from '../../redux/ducks/user'
import http, { getAuthInfo } from '../../shared/http'
import { TApi, TRoutes } from '../../shared/const'

function AlertTeacherMode(props) {
	const { t } = useTranslation()
	const history = useHistory()
	const handleExitMode = async () => {
		const { token } = getAuthInfo()
		http.defaults.headers['Authorization'] = `Bearer ${token}`
		const { data } = await http.get(TApi.COMMON_ME, { disableToast: true })

		props.setTeacherCompanyView(false)
		props.logIn({
			role: data.role,
			profile: data
		})
		history.push(TRoutes.STUDENTS)
	}

	return (
		<div className={styles.root}>
			<p className={styles.description}>{t("You are currently in a student's view")}</p>
			<Btn className={styles.exitBtn} onClick={handleExitMode}>
				Exit mode
			</Btn>
		</div>
	)
}

AlertTeacherMode.propTypes = {
	isAuth: PropTypes.bool.isRequired,
	isSignInMode: PropTypes.bool.isRequired,
	fullName: PropTypes.string.isRequired,
	role: PropTypes.string.isRequired,
	isParentalConfirmation: PropTypes.bool.isRequired,
	reportType: PropTypes.string.isRequired,
	reportPeriod: PropTypes.object.isRequired
}

const mapDispatchToProps = dispatch => ({
	setTeacherCompanyView: data => dispatch(setTeacherCompanyView(data)),
	logIn: data => dispatch(logIn(data))
})

export default connect(null, mapDispatchToProps)(AlertTeacherMode)
