import PropTypes from 'prop-types'

import styles from '../style.module.scss'
import Btn from '../../../../../../UI/Btn'
import { schemaOptions } from '../../../../../../shared/i18n'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { toast } from 'react-toastify'
import Joi from 'joi'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import TextFieldNumber from '../../../../../../UI/TextFieldNumber'
import { connect } from 'react-redux'

function CalculationOfNetProfit(props) {
	const { t } = useTranslation()
	const schema = Joi.object({
		profitLossBeforeIncomeTax: Joi.number().required(),
		reducedCorporateIncomeTax: Joi.number().required(),
		profitLossReducedCorporateIncomeTax: Joi.number()
			.custom((value, helper) => {
				if (
					Math.round(
						(helper.state.ancestors[0].profitLossBeforeIncomeTax -
							helper.state.ancestors[0].reducedCorporateIncomeTax) *
							100
					) /
						100 ===
					value
				)
					return value
				return helper.message(t('Wrong result'))
			})
			.required()
			.messages({
				'any.required': `is not allowed to be empty`
			})
	})

	const [validate, setValidate] = useState(false)

	const {
		control,
		handleSubmit,
		reset,
		formState: { errors }
	} = useForm({
		resolver: validate
			? null
			: joiResolver(schema, {
					errors: {
						labels: false,
						language: localStorage.getItem('i18nextLng')
					},
					...schemaOptions
			  })
	})

	const onSubmit = form => {
		let data = form
		data.netProfitLoss = form.profitLossReducedCorporateIncomeTax

		for (const key in data) {
			if (data[key] === '') {
				data[key] = null
			}
		}
		props
			.updateData(data)
			.then(() => {
				toast.success(t('Calculation of net profit/loss was updated'))
				if (!validate) props.handlerNextStep()
			})
			.finally(() => {
				setValidate(false)
			})
	}
	useEffect(() => {
		reset({
			profitLossBeforeIncomeTax: props.form?.profitLossBeforeIncomeTax,
			reducedCorporateIncomeTax: props.form?.reducedCorporateIncomeTax,
			profitLossReducedCorporateIncomeTax: props.form?.profitLossReducedCorporateIncomeTax
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.form])
	if (props.currentStep !== props.needStep) return null
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<section className={styles.section}>
				<h1 className={styles.sectionTitle}>{t('Calculation of net profit/loss (to be distributed)')}</h1>
				<div className={styles.sectionContent}>
					<div className={styles.flexContainer}>
						<TextFieldNumber
							label={t('Profit/loss (before corporate income tax)')}
							name="profitLossBeforeIncomeTax"
							control={control}
							error={errors.profitLossBeforeIncomeTax}
							disabled
							mb={20}
							placeholder="00.000,00"
						/>
						<p className={styles.description}>
							<p>{t('Profit/loss (before corporate income tax)')}</p>
							{t(
								'Preliminary profit/loss before deduction of corporate income tax, which is due only in case of profit.'
							)}
						</p>
					</div>
					<div className={styles.flexContainer}>
						<TextFieldNumber
							label={`- ${t('Reduced corporate income tax')}`}
							name="reducedCorporateIncomeTax"
							control={control}
							error={errors.reducedCorporateIncomeTax}
							disabled
							mb={20}
							placeholder="00.000,00"
						/>
						<p className={styles.description}>
							<p>{t('Reduced corporate income tax')}</p>
							{t('The reduced corporate income tax is to be deducted from the provisional profit.')}
						</p>
					</div>
					<div className={styles.flexContainer}>
						<TextFieldNumber
							label={`= ${t('Net profit / Net loss')}`}
							name="profitLossReducedCorporateIncomeTax"
							control={control}
							error={errors.profitLossReducedCorporateIncomeTax}
							disabled={props.isLoading || props.form?.isSubmitted}
							mb={20}
							placeholder="00.000,00"
						/>
						<p className={styles.description}>
							<p>{t('Net profit / Net loss')}</p>
							{t('This amount represents your profit/loss after deduction of taxes.')}
						</p>
					</div>
					{!props.form?.isSubmitted && !props.isTeacherStudentView ? (
						<div className={styles.flexContainer}>
							<Btn className={styles.btn} loading={props.isLoading} type="submit" theme="whiteBackground">
								{t('Validate')}
							</Btn>

							<Btn
								className={styles.btn}
								loading={props.isLoading}
								type="submit"
								onClick={() => setValidate(true)}
							>
								{t('save')}
							</Btn>
						</div>
					) : (
						<div className={styles.flexContainer}>
							<Btn className={styles.btn} loading={props.isLoading} onClick={props.handlerPrevStep}>
								{t('Previous')}
							</Btn>
							<Btn className={styles.btn} loading={props.isLoading} onClick={props.handlerNextStep}>
								{t('Next')}
							</Btn>
						</div>
					)}
				</div>
			</section>
		</form>
	)
}
CalculationOfNetProfit.propTypes = {
	form: PropTypes.object,
	updateData: PropTypes.func,
	currentStep: PropTypes.number,
	needStep: PropTypes.number,
	handlerNextStep: PropTypes.func,
	handlerPrevStep: PropTypes.func,
	isTeacherStudentView: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
	isTeacherStudentView: state.user.isTeacherStudentView
})
export default connect(mapStateToProps)(CalculationOfNetProfit)
