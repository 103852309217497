import React from 'react'
import PropTypes from 'prop-types'

import { parseISO, format, differenceInDays, add } from 'date-fns'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import eventEmitter, { types } from '../../shared/eventEmitter'

import styles from './style.module.scss'
import { TApi, TNotifications, TRoutes, TNews } from '../../shared/const'
import i18n from 'i18next'
import RoundWarningIcon from '../../assets/icons/round-warning.png'
import NotificationIcon from '../../assets/icons/notification-ring.png'
import CongratulationIcon from '../../assets/icons/thumbs-up-like.png'
import InformationIcon from '../../assets/icons/info.png'
import NewsIcon from '../../assets/icons/news.png'
import EventIcon from '../../assets/icons/event.png'
import http from '../../shared/http'
import Btn from '../Btn'
import { updateCompanyInfo } from '../../redux/ducks/user'
import { toast } from 'react-toastify'

function getLayoutNotifications(
	type,
	info,
	reportsDeadline,
	companyType,
	invoicesOverdue,
	createdAt,
	isActivated,
	isNeedReview,
	updateCompanyInfo
) {
	switch (type) {
		case TNotifications.COMPANY_CONFIRMATION: {
			return (
				<div className={styles.message}>
					<div>
						{' '}
						<img alt="" src={CongratulationIcon} className={styles.congratulationIcon} />{' '}
					</div>
					<div>
						<h3 className={styles.title}>{i18n.t('Company verified')}</h3>
						<span className={styles.description}>
							{i18n.t(
								'Congratulations! Your company has been successfully verified by JA Austria. For the legitimation of your business, we have issued a company register extract'
							)}{' '}
							<span
								className={styles.link}
								href="/"
								onClick={e => {
									e.preventDefault()
									downoload(info?.companyCertificate, 'Company certificate.pdf')
								}}
							>
								[Link]
							</span>{' '}
							{i18n.t('for you. For the sale of your company shares, the signed warrants')}{' '}
							<a href={TRoutes.SHARES} className={styles.link}>
								{i18n.t('link to submission')}
							</a>{' '}
							{i18n.t('must be uploaded up to a max. amount of')} {info?.maxBudget}€{' '}
							{i18n.t('in the Share section.')}{' '}
							{i18n.t('Do not forget to prepare your financial report by')}
							{(companyType === 'JC' && (
								<>
									<span className={styles.deadlineText}>
										{i18n.t('Annual deadline')}:{' '}
										{reportsDeadline?.JC_semiannual &&
											format(new Date(reportsDeadline?.JC_annual), 'dd.MM.yyyy')}
									</span>{' '}
									<span className={styles.deadlineText}>
										{i18n.t('Semiannual deadline')}:{' '}
										{reportsDeadline?.JC_semiannual &&
											format(new Date(reportsDeadline?.JC_semiannual), 'dd.MM.yyyy')}
									</span>
								</>
							)) ||
								(companyType === 'JBC' && (
									<span className={styles.deadlineText}>
										{i18n.t('Deadline')}:{' '}
										{reportsDeadline?.JBC && format(new Date(reportsDeadline?.JBC), 'dd.MM.yyyy')}
									</span>
								)) ||
								(companyType === 'JCC' && (
									<span className={styles.deadlineText}>
										{i18n.t('Deadline')}:{' '}
										{reportsDeadline?.JC_semiannual &&
											format(new Date(reportsDeadline?.JCC), 'dd.MM.yyyy')}
									</span>
								))}
							.
						</span>
					</div>
				</div>
			)
		}
		case TNotifications.PARENTAL_CONFIRMATION: {
			return (
				<div className={styles.message}>
					<div>
						{' '}
						<img alt="" src={CongratulationIcon} className={styles.infoIcon} />{' '}
					</div>
					<div>
						<h3 className={styles.title}>{i18n.t('Consent confirmed')}</h3>
						<span className={styles.description}>
							{i18n.t('The parental/guardian consent form of has been verified and confirmed.', {
								val1: info?.name
							})}
						</span>
					</div>
				</div>
			)
		}
		case TNotifications.TEACHER_CONFIRMATION: {
			return (
				<div className={styles.message}>
					<div>
						{' '}
						<img alt="" src={InformationIcon} className={styles.infoIcon} />{' '}
					</div>
					<div>
						<h3 className={styles.title}>{i18n.t('Teacher Confirmation')}</h3>
						<span className={styles.description}>
							{i18n.t('Congratulation, your account was confirmed by an admin')}
						</span>
					</div>
				</div>
			)
		}

		case TNotifications.REJECT_COMPANY: {
			return (
				<div className={styles.message}>
					<div>
						{' '}
						<img alt="" src={RoundWarningIcon} className={styles.warningIcon} />{' '}
					</div>
					<div>
						<h3 className={styles.titleRejectCompany}>{i18n.t('Company not approved')}</h3>
						<span className={styles.description}>
							{i18n.t(
								'Your company has been reviewed by JA Austria and unfortunately could not be approved for business yet.'
							)}{' '}
							<br />
							{info?.description && `${i18n.t('Explanation')}: ${info.description}`}
						</span>
					</div>
				</div>
			)
		}

		case TNotifications.REJECT_PARENTAL: {
			return (
				<div className={styles.message}>
					<div>
						{' '}
						<img alt="" src={RoundWarningIcon} className={styles.warningIcon} />{' '}
					</div>
					<div>
						<h3 className={styles.titleRejectCompany}>{i18n.t('Consent rejected')}</h3>
						<span className={styles.description}>
							{i18n.t('The declaration of consent of the parents or legal guardians of was rejected.', {
								val1: info?.name
							})}
							<br />
							<br />
							{info?.description && `${i18n.t('Explanation')}: ${info?.description}`}
						</span>
					</div>
				</div>
			)
		}

		// case TNotifications.REPORT_SUBMITTING: {
		// 	return (
		// 		<div className={styles.message}>
		// 			<div>
		// 				{' '}
		// 				<img alt="" src={CongratulationIcon} className={styles.congratulationIcon} />{' '}
		// 			</div>
		// 			<div>
		// 				<h3 className={styles.title}>
		// 					{info?.type?.charAt(0) + info?.type?.toLowerCase()?.slice(1)} {i18n.t('Report')}
		// 				</h3>
		// 				<span className={styles.description}>
		// 					{i18n.t(
		// 						'Congratulations, your financial report has arrived at JA Austria! On the basis of your data, a compilation of the levies has been prepared, which you can find in the "Documents" section. An open invoice'
		// 					)}{' '}
		// 					<Link to={TRoutes.DOCUMENTS} className={styles.link}>
		// 						{i18n.t('link to invoice')}
		// 					</Link>{' '}
		// 					{i18n.t('must be paid by bank transfer within')} {invoicesOverdue?.value} {i18n.t('days.')}
		// 				</span>
		// 			</div>
		// 		</div>
		// 	)
		// }

		// case TNotifications.USERS_JOINING: {
		// 	return (
		// 		<div className={styles.message}>
		// 			<div>
		// 				{' '}
		// 				<img alt="" src={InformationIcon} className={styles.infoIcon} />{' '}
		// 			</div>
		// 			<div>
		// 				<h3 className={styles.title}>{i18n.t('New User')}</h3>
		// 				<span className={styles.description}>
		// 					{info?.name} {`(${info?.role})`} {i18n.t('joined successfully to company !')}
		// 				</span>
		// 			</div>
		// 		</div>
		// 	)
		// }

		// case TNotifications.PAYMENT_REQUEST: {
		// 	return (
		// 		<div className={styles.message}>
		// 			<div>
		// 				{' '}
		// 				<img alt="" src={InformationIcon} className={styles.infoIcon} />{' '}
		// 			</div>
		// 			<div>
		// 				<h3 className={styles.title}>{i18n.t('Payment')}</h3>
		// 				<span className={styles.description}>{i18n.t('You company has to make a payment invoice')}</span>
		// 			</div>
		// 		</div>
		// 	)
		// }

		case TNotifications.PAYMENT_DONE: {
			return (
				<div className={styles.message}>
					<div>
						{' '}
						<img alt="" src={CongratulationIcon} className={styles.congratulationIcon} />{' '}
					</div>
					<div>
						<h3 className={styles.title}>{i18n.t('Invoice paid')}</h3>
						<span className={styles.description}>
							{i18n.t('The open invoice from for has been paid.', {
								val1: format(new Date(createdAt), 'dd.MM.yyyy'),
								val2: info?.amount
							})}
						</span>
					</div>
				</div>
			)
		}

		case TNotifications.PROGRAM_FINISH: {
			return (
				<div className={styles.message}>
					<div>
						{' '}
						<img alt="" src={InformationIcon} className={styles.infoIcon} />{' '}
					</div>
					<div>
						<h3 className={styles.titleFinish}>{i18n.t('Congratulation!')}</h3>
						<span className={styles.description}>
							{i18n.t(
								'Great Job, you have been awarded for participation in Junior Company Program - download your certificate'
							)}
						</span>
					</div>
				</div>
			)
		}

		case TNotifications.NEED_REVIEW: {
			return (
				<div className={styles.message}>
					<div>
						{' '}
						<img alt="" src={CongratulationIcon} className={styles.congratulationIcon} />{' '}
					</div>
					<div>
						<h3 className={styles.title}>{i18n.t('Company under verfication')}</h3>
						<span className={styles.description}>
							{info?.name}{' '}
							{i18n.t('has submitted the company for verification and activation by JA Austria.')}
						</span>
					</div>
				</div>
			)
		}

		case TNotifications.ASSIGN_REQUEST: {
			return (
				<div className={styles.message}>
					<div>
						{' '}
						<img alt="" src={InformationIcon} className={styles.infoIcon} />{' '}
					</div>
					<div>
						<h3 className={styles.title}>{i18n.t('Request from a teacher')}</h3>
						<span className={styles.description}>
							{i18n.t('A teacher from')} {info.schoolName}{' '}
							{i18n.t('is interested in your company. Please add')} {info.salutation} {info.name}{' '}
							{i18n.t('to your company in the company profile section.')}
							<br />
							<Link
								to="#"
								className={styles.link}
								onClick={() => eventEmitter.emit(types.openAddTeacherModal)}
							>
								{i18n.t('Open a modal window and confirm or reject the request.')}
							</Link>{' '}
						</span>
					</div>
				</div>
			)
		}

		case TNotifications.REJECT_ASSIGN_REQUEST: {
			return (
				<div className={styles.message}>
					<div>
						{' '}
						<img alt="" src={RoundWarningIcon} className={styles.warningIcon} />{' '}
					</div>
					<div>
						<h3 className={styles.titleRejectCompany}>{i18n.t('Assign Request')}</h3>
						<span className={styles.description}>
							{i18n.t('Your request was rejected. Company')} - {info?.company}
						</span>
					</div>
				</div>
			)
		}

		case TNotifications.INVOICE_PAYMENT_REMINDER: {
			return (
				<div className={styles.message}>
					<div>
						{' '}
						<img alt="" src={NotificationIcon} className={styles.congratulationIcon} />{' '}
					</div>
					<div>
						<h3 className={styles.title}>{i18n.t('Invoice payment reminder')}</h3>
						<span className={styles.description}>
							{i18n.t('The report has been submitted, the invoice was created. Please pay the invoice.')}
						</span>
					</div>
				</div>
			)
		}

		case TNotifications.REJECT_REPORT: {
			return (
				<div className={styles.message}>
					<div>
						{' '}
						<img alt="" src={RoundWarningIcon} className={styles.warningIcon} />{' '}
					</div>
					<div>
						<h3 className={styles.title}>{i18n.t('Financial report rejected')}</h3>
						<span className={styles.description}>
							{i18n.t(
								'Attention. Your financial report has been put on hold after submission and needs to be resubmitted after revision.'
							)}
						</span>
					</div>
				</div>
			)
		}

		case TNotifications.REPORT_REMINDER: {
			//TODO: Add dead line
			return (
				<div className={styles.message}>
					<div>
						{' '}
						<img alt="" src={NotificationIcon} className={styles.congratulationIcon} />{' '}
					</div>
					<div>
						<h3 className={styles.title}>{i18n.t('Financial report reminder')}</h3>
						<span className={styles.description}>
							{i18n.t('Please do not forget to submit your financial report.')}{' '}
							{(companyType === 'JC' && (
								<>
									<span className={styles.deadlineText}>
										{i18n.t('Half-year report')}:{' '}
										{reportsDeadline?.JC_semiannual &&
											format(new Date(reportsDeadline?.JC_semiannual), 'dd.MM.yyyy')}
									</span>
									<span className={styles.deadlineText}>
										{i18n.t('annual report')}:{' '}
										{reportsDeadline?.JC_semiannual &&
											format(new Date(reportsDeadline?.JC_annual), 'dd.MM.yyyy')}
									</span>{' '}
								</>
							)) ||
								(companyType === 'JBC' && (
									<span className={styles.deadlineText}>
										{i18n.t('Half-year report')}:{' '}
										{reportsDeadline?.JBC && format(new Date(reportsDeadline?.JBC), 'dd.MM.yyyy')}
									</span>
								)) ||
								(companyType === 'JCC' && (
									<span className={styles.deadlineText}>
										{i18n.t('Half-year report')}:{' '}
										{reportsDeadline?.JC_semiannual &&
											format(new Date(reportsDeadline?.JCC), 'dd.MM.yyyy')}
									</span>
								))}
							.
						</span>
					</div>
				</div>
			)
		}

		case TNotifications.INVITE_EMPLOYEE: {
			return (
				<div className={styles.message}>
					<div>
						{' '}
						<img alt="" src={InformationIcon} className={styles.infoIcon} />{' '}
					</div>
					<div>
						<h3 className={styles.title}>{i18n.t('New employee invited')}</h3>
						<span className={styles.description}>
							{i18n.t('has invited to work in the company.', {
								val1: info?.userName,
								val2: info?.name
							})}
						</span>
					</div>
				</div>
			)
		}

		case TNotifications.INVITE_TEACHER: {
			return (
				<div className={styles.message}>
					<div>
						{' '}
						<img alt="" src={InformationIcon} className={styles.infoIcon} />{' '}
					</div>
					<div>
						<h3 className={styles.title}>{i18n.t('Teacher invited')}</h3>
						<span className={styles.description}>
							{i18n.t('has invited the teacher to the enterprise.', {
								val1: info?.userName,
								val2: info?.name
							})}
						</span>
					</div>
				</div>
			)
		}

		case TNotifications.ADD_EXPERT: {
			return (
				<div className={styles.message}>
					<div>
						{' '}
						<img alt="" src={InformationIcon} className={styles.infoIcon} />{' '}
					</div>
					<div>
						<h3 className={styles.title}>{i18n.t('Economic expert added')}</h3>
						<span className={styles.description}>
							{i18n.t('has registered as a company expert.', {
								val1: info?.userName,
								val2: info?.name
							})}
						</span>
					</div>
				</div>
			)
		}

		case TNotifications.CONFIRM_EXPERT: {
			return (
				<div className={styles.message}>
					<div>
						{' '}
						<img alt="" src={InformationIcon} className={styles.infoIcon} />{' '}
					</div>
					<div>
						<h3 className={styles.title}>{i18n.t('Economic expert confirmed')}</h3>
						<span className={styles.description}>
							{i18n.t('Business expert agrees with the GDPR.', {
								val1: info?.name
							})}
						</span>
					</div>
				</div>
			)
		}

		case TNotifications.SUBMIT_RISK: {
			return (
				<div className={styles.message}>
					<div>
						{' '}
						<img alt="" src={CongratulationIcon} className={styles.congratulationIcon} />{' '}
					</div>
					<div>
						<h3 className={styles.title}>{i18n.t('Market- & Riskanalysis')}</h3>
						<span className={styles.description}>
							{i18n.t('Great, you have dealt with the market and risk analysis.')}
						</span>
					</div>
				</div>
			)
		}

		case TNotifications.SUBMIT_BUSINESS_PLAN: {
			return (
				<div className={styles.message}>
					<div>
						{' '}
						<img alt="" src={CongratulationIcon} className={styles.congratulationIcon} />{' '}
					</div>
					<div>
						<h3 className={styles.title}>{'Businessplan'}</h3>
						<span className={styles.description}>
							{i18n.t('Your business plan has been successfully uploaded.')}
						</span>
					</div>
				</div>
			)
		}

		case TNotifications.SUBMIT_PARENTAL: {
			return (
				<div className={styles.message}>
					<div>
						{' '}
						<img alt="" src={CongratulationIcon} className={styles.congratulationIcon} />{' '}
					</div>
					<div>
						<h3 className={styles.title}>{i18n.t('Parent/guardian consent')}</h3>
						<span className={styles.description}>
							{info?.name} {i18n.t('has uploaded the parent/guardian consent form.')}
						</span>
					</div>
				</div>
			)
		}

		case TNotifications.FINISH_COMPANY: {
			return (
				<div className={styles.message}>
					<div>
						{' '}
						<img alt="" src={CongratulationIcon} className={styles.congratulationIcon} />{' '}
					</div>
					<div>
						<h3 className={styles.title}>{i18n.t('Participation certificate')}</h3>
						<span className={styles.description}>
							{i18n.t('Congratulations')} {info?.name}!{' '}
							{i18n.t('You can find your certificate in the "Documents" section')}{' '}
							<span
								className={styles.link}
								href="/"
								onClick={e => {
									e.preventDefault()
									downoload(info?.participationCertificate, 'Perticipation certificate.pdf')
								}}
							>
								{i18n.t('link to certificate of participation')}
							</span>{' '}
							.
						</span>
					</div>
				</div>
			)
		}

		case TNotifications.FIRST_ENTER: {
			return (
				<div className={styles.message}>
					<div>
						{' '}
						<img alt="" src={InformationIcon} className={styles.infoIcon} />{' '}
					</div>
					<div>
						<h3 className={styles.title}>{i18n.t('Welcome !')}</h3>
						<span className={styles.description}>
							{i18n.t(
								'Here you will find information about upcoming tasks around your company as well as hints about interesting offers and events of our partners.'
							)}
							<br />
							<br />
							{i18n.t(
								'First enter all relevant information about your company, add further employees, supervising teachers and company experts. Optionally, you can also add a business plan. Do not forget to upload the signed consent of a parent or guardian in your user profile.'
							)}
							<br />
							<br />
							{i18n.t(
								'Before your company starts business, it must be submitted to JA Austria for verification and activation. After activation you can offer your products, services and shares in your company on the market - for the legitimisation of these activities you will receive a company register extract and the template for issuing warrants.'
							)}
							<br />
							<br />
							{i18n.t(
								'The financial report provides you with information on the necessary taxes that are usually incurred in the course of business activities.'
							)}
							<br />
							<br />
							{i18n.t('Good luck for your company')}
							<br />
							{i18n.t('JA Austria Team')}
						</span>
					</div>
				</div>
			)
		}

		case TNotifications.ACTIVATE_COMPANY_REMINDER: {
			return (
				<div className={styles.message}>
					<div>
						{' '}
						<img alt="" src={NotificationIcon} className={styles.congratulationIcon} />{' '}
					</div>
					<div>
						<h3 className={styles.title}>{i18n.t('Reminder')}</h3>
						<span className={styles.description}>
							{i18n.t("Don't forget to submit your company for approval (button in dashboard area)")}
						</span>
					</div>
				</div>
			)
		}

		case TNotifications.SUBMIT_APPROVAL_COMPANY_REMINDER: {
			return (
				<div className={styles.message}>
					<div>
						{' '}
						<img alt="" src={NotificationIcon} className={styles.congratulationIcon} />{' '}
					</div>
					<div>
						<h3 className={styles.title}>{i18n.t('Company Activation')}</h3>
						<span className={styles.description}>
							{info?.companyName} {i18n.t('is waiting for an activation check for')}{' '}
							{info?.firstReminderSubmittedApproval} {i18n.t('days!')}{' '}
							<Link to={TRoutes.COMPANY_REVIEW(info.companyId)} className={styles.link}>
								{i18n.t('Company activation page.')}
							</Link>{' '}
						</span>
					</div>
				</div>
			)
		}

		// case TNotifications.SUBMIT_FINAL_REPORT: {
		// 	return (
		// 		<div className={styles.message}>
		// 			<div>
		// 				{' '}
		// 				<img alt="" src={CongratulationIcon} className={styles.congratulationIcon} />{' '}
		// 			</div>
		// 			<div>
		// 				<h3 className={styles.title}>{i18n.t('Business Report')}</h3>
		// 				<span className={styles.description}>{i18n.t('Your business report has been successfully uploaded.')}</span>
		// 			</div>
		// 		</div>
		// 	)
		// }
		case TNotifications.INVOICE_GENERATED: {
			const daysForPay =
				differenceInDays(add(new Date(createdAt), { days: invoicesOverdue?.value }), new Date()) + 1

			return (
				<div className={styles.message}>
					<div>
						<img alt="" src={CongratulationIcon} className={styles.congratulationIcon} />{' '}
					</div>
					<div>
						<h3 className={styles.title}>{i18n.t('Invoice generated')}</h3>
						<span className={styles.description}>
							{i18n.t('We have created an')}{' '}
							<Link
								className={styles.deadlineText}
								onClick={e => {
									e.preventDefault()
									downoload(info?.name, 'invoice.pdf')
								}}
							>
								{i18n.t('invoice')}
							</Link>{' '}
							{i18n.t(`based on your report. Please pay it within the next`)} {daysForPay}{' '}
							{i18n.t(`days`)}. {i18n.t(`You can find the invoice in the`)}{' '}
							<Link to={TRoutes.DOCUMENTS} className={styles.deadlineText}>
								"{i18n.t('documents')}"
							</Link>
							{' .'}
						</span>
					</div>
				</div>
			)
		}
		case TNotifications.INVOICE_PART_PAID: {
			return (
				<div className={styles.message}>
					<div>
						<img alt="" src={RoundWarningIcon} className={styles.warningIcon} />{' '}
					</div>
					<div>
						<h3 className={styles.title}>{i18n.t('Invoice amount paid incompletely')}</h3>
						<span className={styles.description}>
							{i18n.t(
								`The open invoice from for was paid incompletely. Your company account has a balance of. Please transfer the difference to the account details provided.`,
								{
									val1: format(new Date(info?.createdAt), 'dd.MM.yyyy'),
									val2: info?.amount,
									val3: info?.balance
								}
							)}
						</span>
					</div>
				</div>
			)
		}

		case TNotifications.TEACHER_FIRST_ENTER: {
			return (
				<div className={styles.message}>
					<div>
						<img alt="" src={InformationIcon} className={styles.warningIcon} />{' '}
					</div>
					<div>
						<h3 className={styles.title}>{i18n.t('Welcome !')}</h3>
						<span className={styles.description}>
							{i18n.t(
								'Here you will find information about activities of the companies you support as well as information about interesting offers and events of our partners.'
							)}
							<br />
							<br />
							{i18n.t('Good luck for your company')}
							<br />
							{i18n.t('JA Austria Team')}
						</span>
					</div>
				</div>
			)
		}
		case TNotifications.TEACHER_NEW_COMPANY: {
			return (
				<div className={styles.message}>
					<div>
						<img alt="" src={InformationIcon} className={styles.warningIcon} />{' '}
					</div>
					<div>
						<h3 className={styles.title}>{i18n.t('New company')}</h3>
						<span className={styles.description}>
							{i18n.t(
								'The JA Austria company has added you as a supervising teacher. You can now follow the details of the assigned companies in the "Companies" section and also have access to their documents such as business plan, shareholder certificates, financial reports and annual report.',
								{ val1: info?.companyName }
							)}
						</span>
					</div>
				</div>
			)
		}
		case TNotifications.TEACHER_COMPANY_ACTIVATED: {
			return (
				<div className={styles.message}>
					<div>
						<img alt="" src={InformationIcon} className={styles.warningIcon} />{' '}
					</div>
					<div>
						<h3 className={styles.title}>{i18n.t('Company activated')}</h3>
						<span className={styles.description}>
							{i18n.t(
								'Congratulations! The company you are in charge of has been checked by JA Austria and activated for further business activities. The students have received an extract from the company register and can now sell shares in the company up to a total value of EUR . The following date(s) have been set for report submission',
								{ val1: info?.companyName, val2: info?.shares }
							)}{' '}
							{(info?.companyType === 'JC' && (
								<>
									<span className={styles.deadlineText}>
										{i18n.t('Annual deadline')}:{' '}
										{reportsDeadline?.JC_semiannual &&
											format(new Date(reportsDeadline?.JC_annual), 'dd.MM.yyyy')}
									</span>{' '}
									<span className={styles.deadlineText}>
										{i18n.t('Semiannual deadline')}:{' '}
										{reportsDeadline?.JC_semiannual &&
											format(new Date(reportsDeadline?.JC_semiannual), 'dd.MM.yyyy')}
									</span>
								</>
							)) ||
								(info?.companyType === 'JBC' && (
									<span className={styles.deadlineText}>
										{i18n.t('Deadline')}:{' '}
										{reportsDeadline?.JBC && format(new Date(reportsDeadline?.JBC), 'dd.MM.yyyy')}
									</span>
								)) ||
								(info?.companyType === 'JCC' && (
									<span className={styles.deadlineText}>
										{i18n.t('Deadline')}:{' '}
										{reportsDeadline?.JC_semiannual &&
											format(new Date(reportsDeadline?.JCC), 'dd.MM.yyyy')}
									</span>
								))}
							.
						</span>
					</div>
				</div>
			)
		}
		case TNotifications.TEACHER_REPORT_SUBMITTED: {
			return (
				<div className={styles.message}>
					<div>
						<img alt="" src={InformationIcon} className={styles.warningIcon} />{' '}
					</div>
					<div>
						<h3 className={styles.title}>{i18n.t('Report submitted')}</h3>
						<span className={styles.description}>
							{i18n.t('The company you supervise has filed. You can find the report here', {
								val1: info?.companyName,
								val2: i18n.t(`${info?.reportType}`)
							})}
							{<a href="/reports-table">[link to report]</a>}
						</span>
					</div>
				</div>
			)
		}
		case TNotifications.TEACHER_FINANCIAL_REPORT_SUBMITTED: {
			return (
				<div className={styles.message}>
					<div>
						<img alt="" src={CongratulationIcon} className={styles.warningIcon} />{' '}
					</div>
					<div>
						<h3 className={styles.title}>
							{i18n.t('Financial report submitted & certificates have been issued')}
						</h3>
						<span className={styles.description}>
							{i18n.t(
								'Congratulations! Your company has submitted its financial report and thus successfully completed the JA Austria Programme. For the students involved in the company we have deposited completion certificates in the "Documents" section - the students can download them independently. You can find the business report here ',
								{
									val1: info?.compamyName
								}
							)}
							<span
								className={styles.link}
								onClick={e => {
									e.preventDefault()
									downoloadTeacher(info?.finalReportName, 'final report.pdf')
								}}
							>
								{' '}
								[link to report]
							</span>{' '}
							.
							<br />
							<br />
							{i18n.t(
								'We would also like to thank you very much for your support of the JA Austria programme with a certificate'
							)}
							<span
								className={styles.link}
								onClick={e => {
									e.preventDefault()
									downoloadTeacher(info?.participationCertificate, 'participation certificate.pdf')
								}}
							>
								{' '}
								[link to certificate]
							</span>{' '}
							<br />
							{i18n.t('JA Austria Team')}
						</span>
					</div>
				</div>
			)
		}
		case TNotifications.TEACHER_REPORT_REMINDER: {
			return (
				<div className={styles.message}>
					<div>
						<img alt="" src={RoundWarningIcon} className={styles.warningIcon} />{' '}
					</div>
					<div>
						<h3 className={styles.title}>{i18n.t('Overdue reports')}</h3>
						<span className={styles.description}>
							{i18n.t(
								'Attention the following JA Austria companies you are in charge of have not yet submitted their report in our system and therefore cannot successfully complete the programme. Company: '
							)}
							<ul>
								{info?.companies?.map(v => (
									<li>{v.name}</li>
								))}
							</ul>

							<a href="/">[link to report]</a>
						</span>
					</div>
				</div>
			)
		}
		case TNotifications.TEACHER_INVOICE_REMINDER: {
			return (
				<div className={styles.message}>
					<div>
						<img alt="" src={RoundWarningIcon} className={styles.warningIcon} />{' '}
					</div>
					<div>
						<h3 className={styles.title}>{i18n.t('Overdue reports')}</h3>
						<span className={styles.description}>
							{i18n.t('The company you supervise has filed. You can find the report here', {
								val1: info?.compamyName,
								val2: info?.reportType
							})}
							<a href="/">[link to report]</a>
						</span>
					</div>
				</div>
			)
		}
		case TNotifications.COMPANY_READY_FOR_SUBMISSION: {
			return (
				<div className={styles.message}>
					<div>
						<img alt="" src={InformationIcon} className={styles.warningIcon} />{' '}
					</div>
					<div>
						<h3 className={styles.title}>{i18n.t('Ready for submission')}</h3>
						<span className={styles.description}>
							{i18n.t(
								'Congratulations you have everything complete. Your company can now be submitted to JA Austria for review.'
							)}{' '}
							{!isNeedReview && !isActivated && (
								<Btn
									onClick={() => {
										http.put(TApi.STUDENT_PUT_SET_REVIEW).then(() => {
											updateCompanyInfo({
												isNeedReview: true
											})
											toast.success(i18n.t('Company submitted for verification and activation.'))
										})
									}}
									className={styles.btnSubmitReview}
									theme="minimal"
								>
									{i18n.t('Submit for approval')}
								</Btn>
							)}
						</span>
					</div>
				</div>
			)
		}
		case TNotifications.REPORT_SUBMITTING_FINAL_REPORT: {
			return (
				<div className={styles.message}>
					<div>
						<img alt="" src={InformationIcon} className={styles.warningIcon} />{' '}
					</div>
					<div>
						<h3 className={styles.title}>{i18n.t('Final Report')}</h3>
						<span className={styles.description}>
							{i18n.t(
								'Please upload your final report via the upload function in the dashboard. Afterwards you will find your participation certificates for the successful completion of the JA Austria programme in the document section.'
							)}
						</span>
					</div>
				</div>
			)
		}
		case TNotifications.CREATING_NEWS:
		case TNotifications.CREATING_EVENT: {
			const isNews = info.newsType === TNews.NEWS
			return (
				<div className={styles.message}>
					<div>
						<img alt="" src={isNews ? NewsIcon : EventIcon} className={styles.warningIcon} />{' '}
					</div>
					<div>
						<h3 className={styles.title}>{isNews ? i18n.t('news') : i18n.t('event')}</h3>
						<span className={styles.description}>
							<Link
								to="#"
								className={styles.link}
								onClick={() =>
									eventEmitter.emit(types.openDetailFeedModal, {
										id: info.id,
										title: info.title,
										description: info.description,
										externalLink: info.externalLink,
										place: info.place,
										startDate: info.startDate,
										endDate: info.endDate,
										states: info.states,
										roles: info.roles,
										companyTypes: info.companyTypes
									})
								}
							>
								{` ${info.title}`}
							</Link>{' '}
						</span>
					</div>
				</div>
			)
		}
		default:
			return null
	}
}

function NotificationItem(props) {
	return (
		<div className={styles.notificationsItem}>
			{getLayoutNotifications(
				props.notification.type,
				props.notification.info,
				props.options?.reportsDeadline,
				props.company.type,
				props.options?.invoicesOverdue,
				props.notification.createdAt,
				props.company.isActivated,
				props.company.isNeedReview,
				props.updateCompanyInfo
			)}
			<div>{format(parseISO(props.notification.createdAt), 'HH:mm dd.MM.yyyy')}</div>
		</div>
	)
}

NotificationItem.propTypes = {
	notification: PropTypes.shape({
		createdAt: PropTypes.string.isRequired,
		info: PropTypes.object,
		type: PropTypes.string.isRequired
	}).isRequired,
	updateCompanyInfo: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
	options: state.options,
	company: {
		type: state.user.profile.EmploymentCompany?.companyType || '',
		isActivated: !!state.user.profile.EmploymentCompany?.isActive,
		isNeedReview: !!state.user.profile.EmploymentCompany?.isNeedReview
	}
})

const mapDispatchToProps = dispatch => ({
	updateCompanyInfo: data => dispatch(updateCompanyInfo(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationItem)

const downoload = (hashName, downloadName) => {
	http.get(TApi.STUDENT_DOWNLOAD_DOCUMENT(hashName), {
		headers: {
			'Content-Type': 'application/pdf'
		},
		responseType: 'blob'
	}).then(({ data }) => {
		const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
		const link = document.createElement('a')
		link.href = url
		link.setAttribute('download', downloadName)
		document.body.appendChild(link)
		link.click()
		link.parentNode.removeChild(link)
	})
}
const downoloadTeacher = (hashName, downloadName) => {
	http.get(TApi.TEACHER_DOWNLOAD_DOCUMENT(hashName), {
		headers: {
			'Content-Type': 'application/pdf'
		},
		responseType: 'blob'
	}).then(({ data }) => {
		const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
		const link = document.createElement('a')
		link.href = url
		link.setAttribute('download', downloadName)
		document.body.appendChild(link)
		link.click()
		link.parentNode.removeChild(link)
	})
}
