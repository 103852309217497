import clsx from 'clsx'
import PropTypes from 'prop-types'
import styles from './style.module.scss'

export default function MiniProgressBar(props) {
	return (
		<div className={clsx(styles.root, props.className)} style={{ backgroundColor: props.bg }}>
			<div className={styles.progressBar}>{props.children}</div>
		</div>
	)
}

MiniProgressBar.propTypes = {
	className: PropTypes.string,
	bg: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
}
