import { Route, Switch, Redirect } from 'react-router-dom'

import SignIn from '../../../pages/Auth/SignIn'
import SignUp from '../../../pages/Auth/SignUp'
import NewPass from '../../../pages/Auth/NewPass'
import Confirm from '../../../pages/Auth/Confirm'
import ExpertConfirm from '../../../pages/Auth/ExpertConfirm'
import RecoverPass from '../../../pages/Auth/RecoverPass'

import { TRoutes } from '../../../shared/const'

import styles from './style.module.scss'

export default function Auth() {
	return (
		<div className={styles.root}>
			<div className={styles.banner}>
				{/* 	<Link to={TRoutes.MAIN} className={styles.projectName}>
					Junior
					<br />
					Datenbank
				</Link> */}
			</div>

			<Switch>
				<Route path={TRoutes.SIGN_IN} component={SignIn} exact />
				<Route path={TRoutes.SIGN_UP} component={SignUp} exact />

				<Route path={TRoutes.RECOVER_PASS} component={RecoverPass} exact />
				<Route path={TRoutes.NEW_PASS} component={NewPass} exact />
				<Route path={TRoutes.NEW_PASS_NEW_USER} component={NewPass} exact />

				<Route path={TRoutes.AUTH_CONFIRM} component={Confirm} exact />
				<Route path={TRoutes.EXPERT_CONFIRM} component={ExpertConfirm} exact />

				<Redirect to={TRoutes.SIGN_IN} exact />
			</Switch>
		</div>
	)
}
