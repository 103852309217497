import React, { useRef, useState } from 'react'
import Cropper from 'react-cropper'
import { useTranslation } from 'react-i18next'
import './cropper.css'
import { types } from '../../../../shared/eventEmitter'

import BaseModal from '../../../../UI/BaseModal/BaseModal'
import Btn from '../../../../UI/Btn'
import styles from './style.module.scss'

function CropperImage() {
	const { t } = useTranslation()
	const cropperRef = useRef(null)
	const [src, setSrc] = useState(null)
	const [cropData, setCropData] = useState('#')
	const [callbackReadyFile, setCallbackReadyFile] = useState(() => () => {})

	const onCrop = () => {
		const cropper = cropperRef.current?.cropper
		if (!cropper) return
		cropper.getCroppedCanvas().toBlob(blob => {
			if (!blob) return
			const file = new File([blob], blob.type, {
				type: blob.type,
				lastModified: Date.now()
			})
			setCropData(file)
		}, 'image/jpeg')
	}

	const handleOpen = (file = null, callbackReadyFile) => {
		if (!file) return
		setSrc(URL.createObjectURL(file))
		setCallbackReadyFile(() => callbackReadyFile)
	}

	const handleSubmit = closeModal => {
		callbackReadyFile(cropData)
		closeModal()
	}

	return (
		<BaseModal onOpen={handleOpen} nameEvent={types.openCropperModal} title={t('Crop image')} maxWidth={750}>
			{({ closeModal }) => (
				<div className={styles.modal}>
					<Cropper
						ref={cropperRef}
						src={src}
						style={{ height: 400, width: 600 }}
						initialAspectRatio={1}
						guides={true}
						crop={onCrop}
						zoomTo={0.5}
						viewMode={1}
						minCropBoxHeight={10}
						minCropBoxWidth={10}
						background={true}
						responsive={true}
						autoCropArea={1}
						checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
					/>
					<Btn
						width={155}
						isLoading={false}
						onClick={() => handleSubmit(closeModal)}
						icon="send"
						iconSize={{ w: 15, h: 15 }}
						iconColor="#fff"
					>
						{t('save')}
					</Btn>
				</div>
			)}
		</BaseModal>
	)
}

export default CropperImage
