import clsx from 'clsx'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Table from '../../../../UI/Table'
import Search from '../../../../UI/Search'
import Filter from '../../../../UI/Filter'
import BtnFilter from '../../../../UI/BtnFilter'
import BtnToggle from '../../../../UI/BtnToggle'
import BtnTooltip from '../../../../UI/BtnTooltip'
import MiniHeader from '../../../../UI/MiniHeader'
import Pagination from '../../../../UI/Pagination'

import http from '../../../../shared/http'
import { TApi } from '../../../../shared/const'
import eventEmitter, { types } from '../../../../shared/eventEmitter'
import { customNumberFormat, generateFilter } from '../../../../shared/helpers'

import styles from './style.module.scss'
import { setCompanyFilter } from '../../../../redux/ducks/filter'

const statusClassName = {
	false: styles.statusVerification,
	true: styles.statusActive
}

function CompaniesList(props) {
	const { t } = useTranslation()
	const [companies, setCompanies] = useState([])
	const [countAll, setCountAll] = useState(0)
	const [assigned, setAssigned] = useState(true)
	const [page, setPage] = useState(0)
	const [sort, setSort] = useState({ name: 'name', direction: 'asc' })
	const [search, setSearch] = useState('')
	const limit = 10
	const [showFilter, setShowFilter] = useState(false)
	const [countFilters, setCountFilters] = useState(0)

	const fetchAllCompanies = () => {
		const filter = generateFilter(props.companyFilter)

		http.get(assigned ? TApi.TEACHER_GET_ASSIGNED_COMPANIES : TApi.TEACHER_GET_ALL_COMPANIES, {
			params: {
				limit,
				offset: page * limit,
				order: sort.name,
				direction: sort.direction,
				search,
				filter: JSON.stringify(filter)
			}
		}).then(({ data }) => {
			setCompanies(assigned ? data.supervisedCompanies : data.allCompanies)
			setCountAll(data.count)
		})
	}

	useEffect(() => {
		fetchAllCompanies()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, assigned, sort])

	useEffect(() => {
		if (Object.values(props.companyFilter).every(item => !item.length)) fetchAllCompanies()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.companyFilter])

	const handleSearch = e => {
		const { value } = e.target
		setSearch(value)
	}

	const handleSort = (name, direction) => {
		setSort({ name, direction })
	}

	const assignRequest = id => {
		http.post(TApi.TEACHER_ASSIGN_REQUEST, { id: id })
			.then(() => {
				toast.success(t('Request sent successfully'))
			})
			.then(() => {
				fetchAllCompanies()
			})
	}

	const columns = useMemo(
		() => [
			{
				Header: () => {
					return <span className={styles.tableTitleCompanyName}>{t('Companies')}</span>
				},
				minWidth: 250,
				accessor: 'name',
				Cell: ({ row }) => {
					return (
						<div className={styles.name}>
							<span className={styles.companyName}>{row.values.name}</span>

							<BtnTooltip
								items={[
									{
										label: t('View profile'),
										onClick: () => eventEmitter.emit(types.openCompanyProfile, row.original),
										disabled: !assigned
									},
									{
										label: t('Risk Analysis'),
										onClick: () =>
											eventEmitter.emit(
												types.openCompanyRisk,
												row.original.InsuranceCompany,
												null
											),
										disabled: !assigned
									},
									{
										label: t('Request to assign'),
										onClick: () => assignRequest(row.original.id),
										disabled:
											assigned ||
											row.original.Requests?.find(({ teacherId }) => teacherId === props.id) ||
											row.original.TeachersSupervisions?.find(
												({ teacherId }) => teacherId === props.id
											)
									}
								]}
							/>
						</div>
					)
				}
			},

			{
				Header: t('employees'),
				accessor: 'employeesCount',
				disableSortBy: true,
				Cell: ({ row }) => {
					return <span className={styles.emploees}>{row.values.employeesCount}</span>
				}
			},
			{
				Header: t('Company type'),
				accessor: 'companyType',
				Cell: ({ row }) => {
					return <span className={styles.companyType}>{row.values.companyType}</span>
				}
			},
			{
				Header: t('branch'),
				accessor: 'branch',
				Cell: ({ row }) => {
					return <span className={styles.industryType}>{t(row.values.branch)}</span>
				}
			},
			{
				Header: t('Bal.'),
				Cell: ({ row }) => {
					return (
						<span className={styles.industryType}>{`${
							(row.original.Invoice?.[0]?.balance &&
								customNumberFormat(row.original.Invoice?.[0]?.balance)) ||
							'-'
						} | ${
							(row.original.Invoice?.[1]?.balance &&
								customNumberFormat(row.original.Invoice?.[1]?.balance)) ||
							'-'
						}`}</span>
					)
				}
			},
			{
				Header: 'Status',
				accessor: 'isActive',
				Cell: ({ row }) => {
					return (
						<div className={styles.optionBtnCell}>
							<span className={clsx(styles.status, statusClassName[row.values.isActive])}>
								{row.original.isActive ? t('Activated') : t('Not Activated')}
							</span>
						</div>
					)
				}
			}
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[assigned, t]
	)
	return (
		<div className={styles.root}>
			<MiniHeader className={styles.headerContainer} title={t('companies')}>
				<div className={styles.header}>
					<div className={styles.toggleContainer}>
						<p className={styles.toggleLeftTitle}>{t('ASSIGNED COMPANIES')}</p>
						<BtnToggle
							onClick={() => {
								setAssigned(!assigned)
							}}
						/>
						<p className={styles.toggleRightTitle}>{t('All School Companies')}</p>
					</div>
					<div className={styles.searchContainer}>
						<Search
							value={search}
							className={styles.searchLabel}
							inputClassName={styles.searchInput}
							btnClassName={styles.searchBtn}
							placeholder={t('Search companies...')}
							onChange={handleSearch}
							onSubmit={e => {
								e.preventDefault()
								fetchAllCompanies(page * limit)
							}}
						/>
						<BtnFilter
							className={styles.filterBtn}
							icon="arrow"
							onClick={() => setShowFilter(true)}
							count={countFilters}
						>
							Filter ({countFilters})
						</BtnFilter>
					</div>
				</div>
			</MiniHeader>
			<div className={styles.subHeader}>
				<Pagination limit={limit} page={page} label={t('companies')} countAll={countAll} onChange={setPage} />
			</div>
			<div className="container">
				<Table
					columns={columns}
					page={page}
					items={companies}
					limit={limit}
					countAll={countAll}
					onChange={setPage}
					handleSort={handleSort}
				/>
			</div>
			<Filter
				toShow={{
					companyTypes: true,
					branches: true,
					isActive: true
				}}
				onSearch={fetchAllCompanies}
				showFilter={showFilter}
				setShowFilter={setShowFilter}
				onCountFilters={setCountFilters}
				onFilterOptions={props.setFilter}
				filters={props.companyFilter}
			/>
		</div>
	)
}

const mapStateToProps = state => ({
	id: state.user.profile.id,
	companyFilter: state.filter.companies
})

const mapDispatchToProps = dispatch => ({
	setFilter: data => dispatch(setCompanyFilter(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesList)
