import React from 'react'
import styles from '../../style.module.scss'
import PropTypes from 'prop-types'
import Joi from 'joi'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import Btn from '../../../../../../../../UI/Btn'
import { schemaOptions } from '../../../../../../../../shared/i18n'
import clsx from 'clsx'
import { useEffect } from 'react'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import TextFieldNumber from '../../../../../../../../UI/TextFieldNumber'
import dangerIcon from '../../../../../../../../assets/icons/Danger Triangle.svg'
import Decimal from 'decimal.js'
import { connect } from 'react-redux'
function CompanyTaxCalculation(props) {
	let checkSalesTaxCalculation = props.form?.advanceReturnValueAddedTax
	if (props.form?.advanceReturnValueAddedTaxFirst < 0) {
		checkSalesTaxCalculation += props.form?.advanceReturnValueAddedTaxFirst
	}

	const { t } = useTranslation()
	const schema = Joi.object({
		advanceReturnValueAddedTaxFirst: Joi.number().required().messages({
			'number.base': `must be a number`,
			'any.required': `is a required field`
		}),
		advanceReturnValueAddedTax: Joi.number().required().messages({
			'number.base': `must be a number`,
			'any.required': `is a required field`
		}),
		salesTaxCalculation: Joi.number()
			.required()
			.custom((value, helper) => {
				const roundedSalesTaxCalculation = new Decimal(checkSalesTaxCalculation).toDecimalPlaces(2).toNumber()
				const roundedValue = new Decimal(value).toDecimalPlaces(2).toNumber()
				if (roundedSalesTaxCalculation === roundedValue) return roundedValue
				return helper.message(t('Wrong result'))
			})
			.messages({
				'number.base': `must be a number`,
				'any.required': `is a required field`
			})
	})
	const [validate, setValidate] = useState(false)
	const {
		control,
		reset,
		watch,
		handleSubmit,
		formState: { errors }
	} = useForm({
		resolver: validate
			? null
			: joiResolver(schema, {
					errors: {
						labels: false,
						language: localStorage.getItem('i18nextLng')
					},
					...schemaOptions
			  })
	})
	const onSubmit = form => {
		let data = form

		delete data.advanceReturnValueAddedTaxFirst
		delete data.advanceReturnValueAddedTax

		props
			.updateData(data)
			.then(() => {
				toast.success(t('Payload or credit was updated'))
				if (!validate) props.handlerNextStep()
			})
			.finally(() => setValidate(false))
	}

	useEffect(() => {
		reset({
			advanceReturnValueAddedTaxFirst: props.form?.advanceReturnValueAddedTaxFirst,
			advanceReturnValueAddedTax: props.form?.advanceReturnValueAddedTax,
			salesTaxCalculation: checkSalesTaxCalculation
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.form])

	if (props.currentStep !== props.needStep) return null

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<section className={styles.section}>
				<h3 className={styles.sectionTitle}>{t('Payload or credit')}</h3>
				<div className={styles.sectionContent}>
					<div className={styles.flexContainer}>
						<TextFieldNumber
							disabled
							mb={20}
							label={t('1st Semiannual Advance return of the value added tax (UVA)')}
							name="advanceReturnValueAddedTaxFirst"
							control={control}
							placeholder="00.000,00"
							error={errors.advanceReturnValueAddedTaxFirst}
						/>
						<p style={{ marginTop: '25px' }} className={styles.paymentCredit}>
							<img className={styles.warningIcon} src={dangerIcon} alt="" />
							{props.form.advanceReturnValueAddedTaxFirst > 0 ? t('Debit note') : t('Credit note')}
						</p>
					</div>
					<div className={styles.flexContainer}>
						<TextFieldNumber
							disabled
							mb={20}
							label={t('2nd Semiannual Advance return of the value added tax (UVA)')}
							name="advanceReturnValueAddedTax"
							control={control}
							placeholder="00.000,00"
							error={errors.advanceReturnValueAddedTax}
						/>

						<p style={{ marginTop: '25px' }} className={styles.paymentCredit}>
							<img className={styles.warningIcon} src={dangerIcon} alt="" />
							{props.form.advanceReturnValueAddedTax > 0 ? t('Debit note') : t('Credit note')}
						</p>
					</div>
					<div className={styles.flexContainer}>
						<TextFieldNumber
							disabled={props.form?.isLoading || props.form?.isSubmitted}
							mb={20}
							label={t('= Sales tax calculation')}
							name="salesTaxCalculation"
							control={control}
							placeholder="00.000,00"
							error={errors.salesTaxCalculation}
						/>

						<p className={styles.paymentCredit}>
							<img className={styles.warningIcon} src={dangerIcon} alt="" />
							{watch('salesTaxCalculation') > 0 ? t('Debit note') : t('Credit note')}
						</p>
					</div>
					{watch('salesTaxCalculation') < 0 && (
						<div className={styles.creditTitle}>
							{t(
								'You have a credit balance that can be claimed by stating the beneficiary (=account holder), IBAN and this statement as a file attachment via an e-mail to info@junior.cc.'
							)}
						</div>
					)}
					{props.form?.isSubmitted || props.isTeacherStudentView ? (
						<div className={styles.flexContainer}>
							<Btn
								className={styles.btn}
								type="button"
								width={150}
								onClick={() => props.handlerPrevStep()}
							>
								{t('Previous')}
							</Btn>
							<Btn
								className={styles.btn}
								type="button"
								width={150}
								onClick={() => props.handlerNextStep()}
							>
								{t('Next')}
							</Btn>
						</div>
					) : (
						<div className={clsx(styles.flexContainer, styles.btnContainer)}>
							<Btn
								disabled={props.form?.isLoading || props.form?.isSubmitted}
								className={styles.btn}
								type="submit"
								theme="whiteBackground"
								width={150}
							>
								{t('Validate')}
							</Btn>
							<Btn
								disabled={props.form?.isLoading || props.form?.isSubmitted}
								className={styles.btn}
								type="submit"
								width={150}
								onClick={() => {
									setValidate(true)
								}}
							>
								{t('save')}
							</Btn>
						</div>
					)}
				</div>
			</section>
		</form>
	)
}

CompanyTaxCalculation.propTypes = {
	form: PropTypes.object,
	updateData: PropTypes.func,

	currentStep: PropTypes.number,
	needStep: PropTypes.number,
	handlerNextStep: PropTypes.func,
	handlerPrevStep: PropTypes.func,
	isTeacherStudentView: PropTypes.bool.isRequired
}
const mapStateToProps = state => ({
	isTeacherStudentView: state.user.isTeacherStudentView
})
export default connect(mapStateToProps, null)(CompanyTaxCalculation)
