import { useEffect } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import Btn from '../../../../../../UI/Btn'
import styles from '../style.module.scss'
import Joi from 'joi'
import { joiResolver } from '@hookform/resolvers/joi'
import { useTranslation } from 'react-i18next'
import TextFieldNumber from '../../../../../../UI/TextFieldNumber'

const schema = Joi.object({
	shareholderShares: Joi.object().keys({
		JC: Joi.array().items(
			Joi.object().keys({
				value: Joi.number().required()
			})
		),
		JCC: Joi.array().items(
			Joi.object().keys({
				value: Joi.number().required()
			})
		),
		JBC: Joi.array().items(
			Joi.object().keys({
				value: Joi.number().required()
			})
		)
	})
})

export default function ShareholderShares(props) {
	const { t } = useTranslation()
	const {
		control,
		reset,
		handleSubmit,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema)
	})
	const {
		fields: JC,
		append: appendJC,
		remove: removeJC
	} = useFieldArray({
		control, // control props comes from useForm (optional: if you are using FormContext)
		name: 'shareholderShares.JC' // unique name for your Field Array
		// keyName: "id", default to "id", you can change the key name
	})
	const {
		fields: JCC,
		append: appendJCC,
		remove: removeJCC
	} = useFieldArray({
		control, // control props comes from useForm (optional: if you are using FormContext)
		name: 'shareholderShares.JCC' // unique name for your Field Array
		// keyName: "id", default to "id", you can change the key name
	})
	const {
		fields: JBC,
		append: appendJBC,
		remove: removeJBC
	} = useFieldArray({
		control, // control props comes from useForm (optional: if you are using FormContext)
		name: 'shareholderShares.JBC' // unique name for your Field Array
		// keyName: "id", default to "id", you can change the key name
	})
	const onSubmit = form => {
		props
			.updateOptions({
				name: 'shareholderShares',
				value: form.shareholderShares
			})
			.then(() => toast.success(t('Options was updated')))
	}

	useEffect(() => {
		reset({ shareholderShares: props.form?.shareholderShares || {} })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.form])
	return (
		<form
			id="shareholderShares"
			className={styles.root}
			onSubmit={handleSubmit(onSubmit)}
			style={{ borderBottom: '1px solid #e5eaf5' }}
		>
			<section className={styles.section} style={{ borderBottom: 0 }}>
				<h3 className={styles.sectionTitle}>{t('Shareholder Share for Junior')}</h3>
				<div className={styles.sectionContent} style={{ borderBottom: '1px solid #e5eaf5' }}>
					{JC?.map((item, index) => (
						<div key={item.id} className={styles.block}>
							<TextFieldNumber
								className={styles.field}
								mb={20}
								error={errors.shareholderShares?.JC[index].value}
								placeholder="Input new shareholder share"
								control={control}
								name={`shareholderShares.JC.${index}.value`}
							/>
							<Btn
								className={styles.deleteBtn}
								theme="white"
								icon="delete"
								iconColor="#000"
								type="button"
								onClick={() => removeJC(index)}
							/>
						</div>
					))}
					<Btn
						className={styles.addBtn}
						theme="white"
						icon="plus"
						iconColor="#22404D"
						iconSize={{ w: 9, h: 9 }}
						type="button"
						onClick={() => appendJC({ value: '' })}
					/>
				</div>
			</section>
			<section className={styles.section} style={{ borderBottom: 0 }}>
				<h3 className={styles.sectionTitle}>{t('Shareholder Share for Junior Compact')}</h3>
				<div className={styles.sectionContent} style={{ borderBottom: '1px solid #e5eaf5' }}>
					{JCC?.map((item, index) => (
						<div key={item.id} className={styles.block}>
							<TextFieldNumber
								className={styles.field}
								mb={20}
								error={errors.shareholderShares?.JCC[index].value}
								placeholder="Input new shareholder share"
								control={control}
								name={`shareholderShares.JCC.${index}.value`}
							/>
							<Btn
								className={styles.deleteBtn}
								theme="white"
								icon="delete"
								iconColor="#000"
								type="button"
								onClick={() => removeJCC(index)}
							/>
						</div>
					))}
					<Btn
						className={styles.addBtn}
						theme="white"
						icon="plus"
						iconColor="#22404D"
						iconSize={{ w: 9, h: 9 }}
						type="button"
						onClick={() => appendJCC({ value: '' })}
					/>
				</div>
			</section>
			<section className={styles.section} style={{ borderBottom: 0 }}>
				<h3 className={styles.sectionTitle}>{t('Shareholder Share for Junior Basic')}</h3>
				<div className={styles.sectionContent}>
					{JBC?.map((item, index) => (
						<div key={item.id} className={styles.block}>
							<TextFieldNumber
								className={styles.field}
								mb={20}
								error={errors.shareholderShares?.JBC[index].value}
								placeholder="Input new shareholder share"
								control={control}
								name={`shareholderShares.JBC.${index}.value`}
							/>
							<Btn
								className={styles.deleteBtn}
								theme="white"
								icon="delete"
								iconColor="#000"
								type="button"
								onClick={() => removeJBC(index)}
							/>
						</div>
					))}
					<Btn
						className={styles.addBtn}
						theme="white"
						icon="plus"
						iconColor="#22404D"
						iconSize={{ w: 9, h: 9 }}
						type="button"
						onClick={() => appendJBC({ value: '' })}
					/>
				</div>
			</section>
			<div className={styles.btnContainer} style={{ marginBottom: 20 }}>
				<Btn className={styles.btn} type="submit" icon="arrow" iconSize={{ w: 10, h: 10 }} iconColor="#fff">
					{t('save')}
				</Btn>
			</div>
		</form>
	)
}
ShareholderShares.propTypes = {
	form: PropTypes.object.isRequired,
	updateOptions: PropTypes.func.isRequired
}
