import clsx from 'clsx'
import { format } from 'date-fns'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useEffect, useMemo, useState } from 'react'

import http from '../../../../shared/http'
import { StateOptions, TApi, TRoles } from '../../../../shared/const'
import { blobTypes, downloadFile, generateFilter } from '../../../../shared/helpers'

import Btn from '../../../../UI/Btn'
import Icon from '../../../../UI/Icon'
import Table from '../../../../UI/Table'
import Search from '../../../../UI/Search'
import Filter from '../../../../UI/Filter'
import BtnFilter from '../../../../UI/BtnFilter'
import MiniHeader from '../../../../UI/MiniHeader'
import BtnTooltip from '../../../../UI/BtnTooltip'
import Pagination from '../../../../UI/Pagination'

import eventEmitter, { types } from '../../../../shared/eventEmitter'

import styles from './style.module.scss'
import { setUsersFilter } from '../../../../redux/ducks/filter'
import { logIn, setTeacherCompanyView } from '../../../../redux/ducks/user'
import { connect } from 'react-redux'

function Users(props) {
	const { t, i18n } = useTranslation()
	const [users, setUsers] = useState([])
	const [countAll, setCountAll] = useState(0)
	const [page, setPage] = useState(0)
	const [sort, setSort] = useState({ name: 'firstName', direction: 'asc' })
	const [search, setSearch] = useState('')
	const [openFilter, setOpenFilter] = useState(false)
	const [countFilters, setCountFilters] = useState(0)
	const [loading, setLoading] = useState(false)
	const limit = 10

	const fetchUsers = () => {
		const filter = generateFilter(props.usersFilter)

		http.get(TApi.ADMIN_GET_USERS, {
			params: {
				limit,
				offset: page * limit,
				order: sort.name,
				direction: sort.direction,
				search,
				filter: JSON.stringify(filter)
			}
		}).then(({ data }) => {
			const tableArray = data.rows.map(v => {
				return {
					...v,
					createdAt: format(new Date(v.createdAt), 'dd.MM.yyyy'),
					state:
						v.EmploymentCompany?.TeachersSupervisions?.[0]?.Teacher?.School?.state ||
						v.EmploymentCompany?.state ||
						v.School?.state ||
						v?.state ||
						'-'
				}
			})
			setUsers(tableArray)
			setCountAll(data.count)
		})
	}

	useEffect(() => {
		fetchUsers()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, sort])

	useEffect(() => {
		if (Object.values(props.usersFilter).every(item => !item.length)) fetchUsers()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.usersFilter])

	const handleSearch = () => {
		if (page === 0) fetchUsers()
		setPage(0)
	}

	const handleSort = (name, direction) => {
		setSort({ name, direction })
	}

	const handleUserMode = async userId => {
		const {
			data: { token }
		} = await http.get(TApi.ADMIN_GET_TOKEN_USER(userId))

		http.defaults.headers['Authorization'] = `Bearer ${token}`
		props.setTeacherCompanyView(true)
		const { data } = await http.get(TApi.COMMON_ME, { disableToast: true })
		props.logIn({
			role: data.role,
			profile: data
		})
	}

	const columns = useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'firstName',
				minWidth: 250,
				Cell: ({ row }) => {
					const getActions = () => {
						switch (row.original.role) {
							case TRoles.STUDENT: {
								const parentalConfirmation = row.original.Documents.find(
									item => item.type === 'PARENTAL_CONFIRMATION'
								)

								return [
									{
										label: t('view mode as Student'),
										onClick: () => handleUserMode(row.original.id)
									},
									{
										label: t('Edit Userprofile'),
										onClick: () =>
											eventEmitter.emit(types.openStudentProfile, row.original, fetchUsers)
									},
									{
										label: parentalConfirmation
											? t('Download parental consent')
											: t('Download parental consent (not uploaded yet!)'),
										disabled: !parentalConfirmation,
										onClick: () => {
											http.get(TApi.ADMIN_GET_FILES_DOCUMENT(parentalConfirmation.name), {
												headers: {
													'Content-Type': 'application/pdf,image/jpeg,image/png,image/gif'
												},
												responseType: 'blob'
											}).then(({ data }) => {
												downloadFile(
													`${row.original.firstName}-${row.original.lastName}: Parental Confirmation`,
													data
												)
											})
										}
									},
									{
										label: row.original.reviewedParentalConfirmation
											? t('Parental consent verfied')
											: t('Parental consent (need verifying)'),
										disabled: row.original.reviewedParentalConfirmation || !parentalConfirmation,
										className: styles.actionGreen,
										onClick: () =>
											http
												.put(TApi.ADMIN_REVIEW_PARENTAL_CONFIRMATION(row.original.id))
												.then(() => {
													const _users = users.slice()
													_users[row.index] = {
														..._users[row.index],
														reviewedParentalConfirmation: true
													}
													setUsers(_users)
													toast.success(t('Status was updated'))
												})
									}
								]
							}
							case TRoles.TEACHER: {
								return [
									{
										label: t('profile'),
										onClick: () =>
											eventEmitter.emit(types.openStudentProfile, row.original, fetchUsers)
									},
									{
										label: t('Merge and remove'),
										onClick: () =>
											eventEmitter.emit(types.openMergeTeacherModal, row.original, () => {
												fetchUsers()
											})
									},
									{
										label: t('Verify teacher'),
										disabled: row.original.verifiedTeacher,
										onClick: () =>
											http.put(TApi.ADMIN_ACTIVATE_TEACHER(row.original.id)).then(() => {
												toast.success('Teacher activated')
												fetchUsers()
											})
									}
								]
							}
							default: {
								return null
							}
						}
					}

					const actions = getActions()
					if (!actions) return ''
					return (
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							{row.original.firstName && row.original.lastName ? (
								<span style={{ width: '220px' }}>
									{row.original.firstName} {row.original.lastName}
								</span>
							) : (
								<span
									style={{
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										whiteSpace: 'nowrap',
										opacity: 0.5,
										width: '220px'
									}}
								>
									Bestätigung ausstehend
								</span>
							)}
							<BtnTooltip items={actions} />
						</div>
					)
				},
				maxWidth: 150
			},

			{
				Header: t('R'),
				headerToolTip: t('Role'),
				accessor: 'role',
				Cell: ({ row }) => {
					return t(row.values.role)
						.split('_')
						.map(v => v.slice(0, 1))
						.join('')
				},
				width: 70
			},
			{
				Header: t('Company'),
				accessor: 'companyName',
				minWidth: 120,
				Cell: ({ row }) => {
					return (
						<span style={{ overflow: 'hidden', wordBreak: 'break-word' }}>
							{row.original.EmploymentCompany?.name || '-'}
						</span>
					)
				}
			},
			{
				Header: t('School'),
				accessor: 'school',
				minWidth: 120,
				Cell: ({ row }) => (
					<span style={{ overflow: 'hidden', wordBreak: 'break-word' }}>
						{row.original.School?.name ||
							row.original.EmploymentCompany?.TeachersSupervisions?.[0]?.Teacher?.School?.name ||
							'-'}
					</span>
				)
			},
			{
				Header: t('FS'),
				headerToolTip: t('Fed. State'),
				accessor: 'state',
				width: 70,
				Cell: ({ row }) =>
					t(StateOptions.find(({ value }) => value === row.original.state)?.label)
						?.split(' ')
						.map(v => v.slice(0, 1))
						.join('')
			},
			{
				Header: t('Reg.Dat.'),
				accessor: 'createdAt',
				width: 80
			}
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[t, users]
	)

	const downoloadList = fileType => {
		setLoading(true)
		console.log(1, i18n.language)
		http.get(TApi.ADMIN_DOWNOLOAD_USERS_LIST, {
			params: {
				type: fileType,
				leng: localStorage.getItem('i18nextLng')
			},
			responseType: 'arraybuffer'
		})
			.then(res => {
				let blob = new Blob([res.data], {
					type: blobTypes[fileType]
				})
				downloadFile(i18n.language === 'ger' ? 'Benutzerlnnen' : 'Users', blob)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	return (
		<div className={styles.root}>
			<MiniHeader className={styles.miniHeader} title={t('users')}>
				<div className={styles.miniHeaderContent}>
					<Search
						value={search}
						className={styles.search}
						onChange={e => setSearch(e.target.value)}
						placeholder={t('Find a user')}
						onSubmit={e => {
							e.preventDefault()
							handleSearch()
						}}
					/>
					<BtnFilter
						className={styles.filterBtn}
						icon="arrow"
						onClick={() => setOpenFilter(true)}
						count={countFilters}
					>
						Filter ({countFilters})
					</BtnFilter>
				</div>
			</MiniHeader>
			<div className={styles.subHeader}>
				<Pagination limit={limit} page={page} label={t('users')} countAll={countAll} onChange={setPage} />
				<div className={styles.downloadContent}>
					{t('Download list as')}
					<div>
						{!loading && (
							<Btn
								theme="download"
								iconSize={{ w: 24, h: 24 }}
								icon="download"
								onClick={() => {
									downoloadList('XLS')
								}}
							>
								XLS
							</Btn>
						)}
						{loading && (
							<Icon
								className={clsx(styles.icon, styles.rootLoading)}
								size={{ w: 16, h: 16 }}
								name="loading"
								color={'#22404D'}
							/>
						)}
						{!loading && (
							<Btn
								theme="download"
								iconSize={{ w: 24, h: 24 }}
								icon="download"
								onClick={() => {
									downoloadList('PDF')
								}}
							>
								PDF
							</Btn>
						)}
					</div>
				</div>
			</div>
			<div className="container">
				<Table
					columns={columns}
					items={users}
					limit={limit}
					countAll={countAll}
					onChange={setPage}
					handleSort={handleSort}
					paginationLabel={t('users')}
					page={page}
				/>
			</div>
			<Filter
				toShow={{
					schools: true,
					role: true,
					verifiedTeacher: true,
					states: true,
					schoolType: true,
					branches: true
				}}
				onSearch={handleSearch}
				showFilter={openFilter}
				setShowFilter={setOpenFilter}
				onCountFilters={setCountFilters}
				onFilterOptions={props.setFilter}
				filters={props.usersFilter}
			/>
		</div>
	)
}

const mapStateToProps = state => ({
	usersFilter: state.filter.users
})

const mapDispatchToProps = dispatch => ({
	setTeacherCompanyView: data => dispatch(setTeacherCompanyView(data)),
	logIn: data => dispatch(logIn(data)),
	setFilter: data => dispatch(setUsersFilter(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Users)
