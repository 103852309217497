import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

import { TRoutes } from '../../shared/const'

function PrivateRoute({ isAuth, role, component: Component, ...rest }) {
	return (
		<Route
			{...rest}
			render={({ location }) =>
				isAuth ? (
					<Component />
				) : (
					<Redirect
						to={{
							pathname: TRoutes.SIGN_IN,
							state: { from: location }
						}}
					/>
				)
			}
		/>
	)
}

PrivateRoute.propTypes = {
	isAuth: PropTypes.bool.isRequired,
	role: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
	isAuth: state.user.isAuth,
	role: state.user.role
})

export default connect(mapStateToProps)(PrivateRoute)
