import { useState, useEffect } from 'react'
import styles from '../style.module.scss'
import { useForm, useFieldArray } from 'react-hook-form'
import { toast } from 'react-toastify'
import Joi from 'joi'
import http from '../../../../../shared/http'
import { TApi, TRoutes } from '../../../../../shared/const'
import { joiPhone } from '../../../../../shared/helpers'
import { schemaOptions } from '../../../../../shared/i18n'
import { joiResolver } from '@hookform/resolvers/joi'
import TextField from '../../../../../UI/TextField'
import BtnAdd from '../../../../../UI/BtnAdd'
import Btn from '../../../../../UI/Btn'
import LogoUploader from '../../../../../UI/LogoUploader'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import { joiFile } from '../../../../../shared/helpers'

const schema = Joi.object({
	companyEmail: Joi.string().email({ tlds: false }).required(),
	phone: joiPhone,
	websiteURL: Joi.string().uri().empty('').allow(null).default(null),
	socialNet: Joi.array().items(
		Joi.object({
			name: Joi.string().required(),
			link: Joi.string().uri(),
			public: Joi.boolean().default(false)
		})
	),
	logo: joiFile(10)
})

function CompanyProfile(props) {
	const { id } = useParams()
	const location = useLocation()
	const path = location.pathname
	const { t } = useTranslation()

	const {
		register,
		trigger,
		handleSubmit,
		control,
		reset,
		setValue,
		getValues,
		watch,
		formState: { errors }
	} = useForm({
		reValidateMode: 'onChange',
		resolver: joiResolver(schema, {
			errors: {
				labels: false,
				language: localStorage.getItem('i18nextLng')
			},
			...schemaOptions
		}),
		defaultValues: {
			companyEmail: props.companyProfile.email,
			phone: props.companyProfile.phone,
			websiteURL: props.companyProfile.websiteURL,
			socialNet: props.companyProfile.socialNet
		}
	})
	const [isLoading, setLoading] = useState(false)
	const [logoSrc, setLogoSrc] = useState('')

	const {
		fields: socialFields,
		append,
		remove
	} = useFieldArray({
		reset,
		control,
		name: 'socialNet'
	})

	useEffect(() => {
		setLogoSrc(
			props.companyProfile.logoHashName ? TApi.PUBLIC_PROFILE_AVATAR(props.companyProfile.logoHashName) : ''
		)
		if (Object.keys(errors).length) trigger()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localStorage.getItem('i18nextLng'), props.companyProfile.logoHashName])

	const onSubmit = data => {
		setLoading(true)
		const formData = new FormData()

		formData.append('email', data.companyEmail)
		formData.append('phone', data.phone)
		formData.append('websiteURL', data.websiteURL)
		formData.append('socialNet', JSON.stringify(data.socialNet))
		if (data.logo && data.logo[0]) formData.append('logo', data.logo[0])

		const updateCompany =
			path === TRoutes.COMPANY
				? http.put(TApi.COMPANY_PUT_PROFILE, formData)
				: http.put(TApi.ADMIN_GET_COMPANY(id), formData)

		updateCompany
			.then(() => {
				toast.success(t('Company profile has been updated successfully.'))
			})
			.finally(() => setLoading(false))
	}

	const handleChangeFile = file => {
		setValue('logo', [file])
		setLogoSrc(URL.createObjectURL(file))
	}

	const handleHide = index => {
		const fieldName = `socialNet.${index}.public`
		const currentValue = getValues(fieldName)
		setValue(fieldName, !currentValue)
	}

	return (
		<div className={styles.root}>
			<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
				<div className={styles.section}>
					<h1 className={styles.sectionTitle}>
						Logo {t('and')} {t('Social Media')}
					</h1>
					<div className={styles.sectionContent}>
						<div className={styles.flexContainer}>
							<TextField
								required
								className={styles.field}
								disabled={isLoading}
								mb={36}
								label={t('Company email')}
								placeholder="company@email.com"
								error={errors.companyEmail}
								register={register('companyEmail')}
							/>
							<span className={styles.sectionNote} style={{ marginBottom: 0 }}>
								{t('CompanyEmailDescription')}
							</span>
						</div>
						<div className={styles.flexContainer}>
							<TextField
								required
								className={styles.field}
								disabled={isLoading}
								mb={36}
								label={t('phone')}
								placeholder="+43 6XX XXXXXX"
								error={errors.phone}
								register={register('phone')}
							/>
							<span className={styles.sectionNote}>{t('phoneDesc')}</span>
						</div>
						<LogoUploader
							disabled={isLoading || props.isTeacherStudentView}
							src={logoSrc}
							className={styles.avatarUploader}
							onChange={handleChangeFile}
							errors={errors.logo}
							register={register('logo')}
						/>
						<TextField
							disabled={isLoading}
							mb={10}
							label={t('Website & Social Media')}
							placeholder="https://www.example.com/"
							error={errors.websiteURL}
							register={register('websiteURL')}
							classNameError={styles.websiteError}
						/>
						<div className={styles.socialMediaContainer}>
							{socialFields.map((item, index) => {
								const isPublic = watch(`socialNet.${index}.public`)
								return (
									<div key={item.id} className={styles.socialField}>
										<div className={styles.flexWrapp}>
											<TextField
												disabled={isLoading}
												mb={10}
												label={t('Social Media')}
												placeholder={t('Type social media title')}
												error={errors.socialNet && errors.socialNet[index]?.name}
												register={register(`socialNet.${index}.name`)}
											/>
											<TextField
												disabled={isLoading}
												mb={10}
												placeholder="https://www.example.com"
												error={errors.socialNet && errors.socialNet[index]?.link}
												register={register(`socialNet.${index}.link`)}
												classNameError={styles.websiteError}
											/>
										</div>
										<div className={styles.hideBtn}>
											<Btn
												icon={isPublic ? 'public' : 'private'}
												type="button"
												disabled={isLoading || props.isTeacherStudentView}
												onClick={() => handleHide(index)}
												className={styles.publicPrivateBtn}
												iconClassName={styles.publicIcon}
												noFillIcon
											/>
											{isPublic ? t('public') : t('private')}
										</div>

										<Btn
											disabled={isLoading || props.isTeacherStudentView}
											className={styles.deleteBtn}
											theme="white"
											icon="delete"
											iconColor="#000"
											onClick={() => remove(index)}
										/>
									</div>
								)
							})}
						</div>
						{!socialFields.length && <div className={styles.socialFieldTitle}>{t('Social Media')}</div>}
						{socialFields.length < 5 && !props.isTeacherStudentView && (
							<div className={styles.btnAdd}>
								<BtnAdd
									type="button"
									disabled={isLoading || props.isTeacherStudentView}
									onClick={() => append({ name: '', link: '', public: false })}
								/>
								<h1 className={styles.btnTitle}>{t('Add link')}</h1>
							</div>
						)}

						{!props.isTeacherStudentView && (
							<div className={styles.btnContainer}>
								<Btn
									className={styles.btn}
									loading={isLoading}
									type="submit"
									icon="arrow"
									iconSize={{ w: 10, h: 10 }}
									iconColor="#fff"
								>
									{t('save')}
								</Btn>
							</div>
						)}
					</div>
				</div>
			</form>
		</div>
	)
}

CompanyProfile.propTypes = {
	companyProfile: PropTypes.object,
	isTeacherStudentView: PropTypes.bool.isRequired
}

export default CompanyProfile
