import { useEffect, useMemo, useState } from 'react'
import Btn from '../../../../UI/Btn'
import Table from '../../../../UI/Table'
import MiniHeader from '../../../../UI/MiniHeader'
import { useTranslation } from 'react-i18next'
import { add, differenceInDays, format, sub } from 'date-fns'

import styles from './style.module.scss'
import ReportsCard from '../../../../UI/ReportsCard'
import Search from '../../../../UI/Search'
import BtnFilter from '../../../../UI/BtnFilter'
import http from '../../../../shared/http'
import { StateOptions, TApi } from '../../../../shared/const'
import BtnTooltip from '../../../../UI/BtnTooltip'
import { blobTypes, customNumberFormat, downloadFile, generateFilter } from '../../../../shared/helpers'
import Filter from '../../../../UI/Filter'
import Pagination from '../../../../UI/Pagination'
import eventEmitter, { types } from '../../../../shared/eventEmitter'
import clsx from 'clsx'
import Icon from '../../../../UI/Icon'
import { setInvoicesFilter } from '../../../../redux/ducks/filter'
import { connect } from 'react-redux'

function Invoices(props) {
	const { t } = useTranslation()
	const [search, setSearch] = useState('')
	const [invoices, setInvoices] = useState([])
	const [paginationCount, setPaginationCount] = useState(0)
	const [countAll, setCountAll] = useState(0)
	const [page, setPage] = useState(0)
	const [sort, setSort] = useState({ name: 'name', direction: 'asc' })
	const [paidCount, setPaidCount] = useState(0)
	const [overdueCount, setOverdueCount] = useState(0)
	const [totalAmount, setTotalAmount] = useState(0)
	const [openFilter, setOpenFilter] = useState(false)
	const [countFilters, setCountFilters] = useState(0)
	const [loading, setLoading] = useState(false)
	const limit = 10

	const fetchInvoices = () => {
		const filter = generateFilter(props.invoicesFilter)

		http.get(TApi.ADMIN_GET_INVOICES, {
			params: {
				limit,
				offset: page * limit,
				order: sort.name,
				direction: sort.direction,
				search,
				filter: filter
			}
		}).then(({ data }) => {
			const arrayTable = data.rows.map(v => {
				const createdAt = new Date(v.createdAt)
				const overdue = createdAt < sub(new Date(), { days: data.daysOverdue })
				return {
					...v,
					name: v.Report.Company.name,
					type: v.Report.Company.companyType,
					state: v.Report.Company.state,
					period: v.Report.type,
					createdAt: format(createdAt, 'dd.MM.yyyy'),
					school: v.Report.Company?.TeachersSupervisions?.[0]?.Teacher?.School?.name,
					overdueDays: differenceInDays(add(createdAt, { days: data.daysOverdue }), new Date()),
					statusStyle:
						(v.status === 'Unpaid' && overdue && styles.red) || (v.status === 'Paid' && styles.green),
					overdue
				}
			})
			setInvoices(arrayTable)
			setPaginationCount(data.count)
			setCountAll(data.count)
			setPaidCount(data.paidInvoices)
			setOverdueCount(data.overdueInvoices)
			setTotalAmount(Math.round((data.totalAmount || 0) * 100) / 100)
		})
	}
	const handleSort = (name, direction) => {
		setSort({ name, direction })
	}

	const handleSearch = () => {
		if (page === 0) fetchInvoices()
		setPage(0)
	}
	const downoload = id => {
		http.get(TApi.ADMIN_DOWNLOAD_INVOICES(id), {
			headers: {
				'Content-Type': 'application/pdf'
			},
			responseType: 'blob'
		}).then(({ data }) => {
			downloadFile(`Invoice_${id}`, data)
		})
	}

	useEffect(() => {
		fetchInvoices()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, sort])

	useEffect(() => {
		if (Object.values(props.invoicesFilter).every(item => !item.length)) fetchInvoices()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.invoicesFilter])

	const columns = useMemo(
		() => [
			{
				Header: 'S',
				headerToolTip: t('Status'),
				accessor: 'status',
				Cell: ({ row }) => {
					return (
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
								width: '110px',
								height: '100%'
							}}
						>
							<div className={row.original.statusStyle} style={{ fontWeight: 800 }}>
								{t(row.original.status)}
							</div>
							<BtnTooltip
								items={[
									{
										disabled: row.original.status === 'Paid',
										label: t('Change status'),
										onClick: () => {
											eventEmitter.emit(
												types.openInvoiceChangeData,
												{
													id: row.original.id,
													amount: row.original.amount,
													balance: row.original.balance
												},
												fetchInvoices
											)
										}
									}
								]}
							/>
						</div>
					)
				},
				width: 130
			},

			{
				Header: t('D'),
				headerToolTip: t('Deadline (d)'),
				accessor: 'overdueDays',
				width: 50
			},
			{
				Header: t('A'),
				headerToolTip: t('Amount'),
				accessor: 'amount',
				Cell: ({ row }) => {
					return customNumberFormat(Math.round(row.values.amount * 100) / 100)
				},
				width: 100
			},
			{
				Header: t('B'),
				accessor: 'balance',
				headerToolTip: t('Balance'),
				Cell: ({ row }) => {
					return customNumberFormat(Math.round(row.values.balance * 100) / 100)
				},
				width: 100
			},
			{
				Header: 'No.',
				headerToolTip: t('Invoice Number'),
				accessor: 'id',
				width: 50,
				Cell: ({ row }) => row.original.no || null
			},
			{
				Header: t('Comp.'),
				accessor: 'name',
				Cell: ({ row }) => {
					return <span style={{ overflow: 'hidden' }}>{row.values.name}</span>
				},
				width: 120
			},
			{
				Header: 'Typ',
				accessor: 'type',
				width: 60
			},
			{
				Header: t('FS'),
				headerToolTip: t('Fed. State'),
				accessor: 'state',
				Cell: ({ row }) =>
					t(StateOptions.find(({ value }) => value === row.original.state)?.label)
						?.split(' ')
						.map(v => v.slice(0, 1))
						.join(''),
				width: 50
			},
			{
				Header: t('School'),
				headerToolTip: t('School'),
				accessor: 'school',
				Cell: ({ row }) => {
					return <span style={{ overflow: 'hidden', wordBreak: 'break-word' }}>{row.values.school}</span>
				},
				width: 120
			},
			{
				Header: t('Rep.Type'),
				accessor: 'period',
				Cell: ({ row }) => {
					return (
						<span
							style={{
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap'
							}}
						>
							{t(row.values.period)}
						</span>
					)
				},
				width: 100
			},

			{
				Header: t('Dat'),
				accessor: 'createdAt',
				width: 80
			},
			{
				Header: '',
				accessor: 'btnDownload',
				disableSortBy: true,
				Cell: ({ row }) => {
					return (
						<Btn
							icon="download"
							iconSize={{ w: 24, h: 24 }}
							theme="download"
							onClick={() => {
								downoload(row.original.id)
							}}
						/>
					)
				},
				width: 50
			}
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[t]
	)

	const downoloadList = fileType => {
		setLoading(true)
		http.get(TApi.ADMIN_DOWNOLOAD_INVOICES_LIST, {
			params: {
				type: fileType,
				leng: localStorage.getItem('i18nextLng')
			},
			responseType: 'arraybuffer'
		})
			.then(res => {
				let blob = new Blob([res.data], {
					type: blobTypes[fileType]
				})
				downloadFile('Invoices', blob)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	return (
		<div className={styles.root}>
			<MiniHeader className={styles.header} title={t('invoices')}>
				<div className={styles.miniHeaderContent}>
					<Search
						onChange={e => setSearch(e.target.value)}
						value={search}
						onSubmit={e => {
							e.preventDefault()
							handleSearch()
						}}
						className={styles.search}
						placeholder={t('Search a invoice')}
					/>
					<BtnFilter
						className={styles.btn}
						icon="arrow"
						onClick={() => setOpenFilter(true)}
						count={countFilters}
					>
						Filter ({countFilters})
					</BtnFilter>
				</div>
			</MiniHeader>
			<div className={styles.subHeader}>
				<Pagination
					limit={limit}
					page={page}
					label={t('invoices')}
					countAll={paginationCount}
					onChange={setPage}
				/>
				<div className={styles.downloadContent}>
					{t('Download list as')}
					<div>
						{!loading && (
							<Btn
								theme="download"
								iconSize={{ w: 24, h: 24 }}
								icon="download"
								onClick={() => {
									downoloadList('XLS')
								}}
							>
								XLS
							</Btn>
						)}
						{!loading && (
							<Btn
								theme="download"
								iconSize={{ w: 24, h: 24 }}
								icon="download"
								onClick={() => {
									downoloadList('PDF')
								}}
							>
								PDF
							</Btn>
						)}
						{loading && (
							<Icon
								className={clsx(styles.icon, styles.rootLoading)}
								size={{ w: 16, h: 16 }}
								name="loading"
								color={'#22404D'}
							/>
						)}
					</div>
				</div>
			</div>
			<div className={styles.reportCards}>
				<ReportsCard title={t('Total Invoice Amount')} value={`€ ${customNumberFormat(totalAmount)}`} />
				<ReportsCard
					title={t('Paid Invoices, %')}
					value={countAll ? `${customNumberFormat(((paidCount / countAll) * 100).toFixed(2))}%` : 0}
				/>
				<ReportsCard title={t('Paid Invoices')} value={`${paidCount}/${countAll}`} />
				<ReportsCard title={t('Overdue Invoices')} value={`${overdueCount}/${countAll}`} />
			</div>
			<div className="container">
				<Table
					page={page}
					columns={columns}
					items={invoices}
					limit={limit}
					countAll={paginationCount}
					onChange={setPage}
					handleSort={handleSort}
				/>
			</div>
			<Filter
				toShow={{
					states: true,
					branches: true,
					companyTypes: true,
					invoiceStatus: true,
					schools: true
				}}
				onSearch={handleSearch}
				showFilter={openFilter}
				setShowFilter={setOpenFilter}
				onCountFilters={setCountFilters}
				onFilterOptions={props.setFilter}
				filters={props.invoicesFilter}
			/>
		</div>
	)
}

const mapStateToProps = state => ({
	invoicesFilter: state.filter.invoices
})

const mapDispatchToProps = dispatch => ({
	setFilter: data => dispatch(setInvoicesFilter(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Invoices)
