import clsx from 'clsx'
import PropTypes from 'prop-types'
import Avatar from 'react-avatar'
import { connect } from 'react-redux'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import FlatLink from '../../../../UI/FlatLink'
import Location from '../../../../UI/location'
import CardUsers from '../../../../UI/СardUsers'
import QuickList from '../../../../UI/QuickList'
import FeedItem from '../../../../UI/QuickList/components/FeedItem'
import CompanyItem from '../../../../UI/QuickList/components/CompanyItem'
import StudentItem from '../../../../UI/QuickList/components/StudentItem'

import http from '../../../../shared/http'
import { TApi, TRoutes } from '../../../../shared/const'

import quickListStyles from '../../common/quickList.module.scss'

import styles from './style.module.scss'

function Dashboard(props) {
	const { t } = useTranslation()
	const [dashboard, setDashboard] = useState({
		allCompanies: [],
		allStudents: [],
		assignedCompanies: {
			count: 0,
			rows: []
		},
		assignedStudents: {
			count: 0,
			rows: []
		},
		news: []
	})

	const [avatarSrc, setAvatarSrc] = useState('')

	useEffect(() => {
		http(TApi.TEACHER_GET_DASHBOARD).then(({ data }) => setDashboard(data))
	}, [])

	useEffect(() => {
		setAvatarSrc(props.avatarHashName ? TApi.PUBLIC_PROFILE_AVATAR(props.avatarHashName) : '')
	}, [props.avatarHashName])

	return (
		<div className={styles.root}>
			<div className={clsx(styles.userWrapperContent, 'container')}>
				<div className={styles.userWrapper}>
					<div className={styles.user}>
						<Avatar
							src={avatarSrc}
							className={styles.userAvatar}
							size={76}
							round
							name={`${props.firstName} ${props.lastName}`}
						/>
						<div className={styles.userNameWrapper}>
							<p className={styles.helloMessage}>{t('Hello, welcome back')}</p>
							<span className={styles.userName}>{props.firstName}</span>
						</div>
					</div>
					<FlatLink
						color="#303E65"
						icon="arrow"
						classNameIcon={styles.editProfileIcon}
						iconColor="#008B9C"
						iconSize={{ w: 10, h: 10 }}
						to={TRoutes.PROFILE}
					>
						{t('Edit My Profile')}
					</FlatLink>
				</div>
				<div className={styles.infoPanel}>
					<div className={styles.locationWrapper}>
						<Location
							label={
								props.gender === 'MALE'
									? t('Supervision MaleTeacher in')
									: t('Supervision FemaleTeacher in')
							}
							title={props.school?.name}
							allInfo={`${props.school?.location ? `${props.school?.location} ,` : ''} ${
								props.school?.streetName ? props.school?.streetName : ''
							} ${props.school?.number ? props.school?.number : ''}`}
						/>
					</div>
					<CardUsers
						className={styles.infoPanelItem}
						title={t('Last Assigned Companies')}
						labelCount={`${dashboard.assignedCompanies.count} ${t('companies')}`}
						toFullList={TRoutes.COMPANIES}
						users={dashboard.assignedCompanies.rows.map(item => ({
							fullName: item.name
						}))}
					/>

					<CardUsers
						className={styles.infoPanelItem}
						title={t('Last Assigned Students')}
						labelCount={`${dashboard.assignedStudents.count} ${t('students')}`}
						toFullList={TRoutes.STUDENTS}
						users={dashboard.assignedStudents.rows.map(item => ({
							fullName: `${item.firstName} ${item.lastName}`,
							avatar: item.avatarHashName && TApi.PUBLIC_PROFILE_AVATAR(item.avatarHashName)
						}))}
					/>
				</div>
			</div>

			<div className={clsx('container', quickListStyles.quickLists)}>
				<QuickList
					className={quickListStyles.quickList}
					title={t('news')}
					linkTitle={t('Go To News')}
					items={dashboard.news}
					noMessages={[t('No news yet')]}
					toFullList={TRoutes.NEWS}
				>
					{item => (
						<FeedItem
							id={item.id}
							title={item.title}
							description={item.description}
							startDate={item.startDate}
							endDate={item.endDate}
							place={item.place}
							externalLink={item.externalLink}
							roles={item.roles}
							states={item.states}
							companyTypes={item.companyTypes}
						/>
					)}
				</QuickList>
				<QuickList
					className={quickListStyles.quickList}
					title={t('All School Companies')}
					linkTitle={t('Go To Companies')}
					noMessages={[t('No companies yet')]}
					items={dashboard.allCompanies}
					toFullList={TRoutes.COMPANIES}
				>
					{item => <CompanyItem id={item.id} name={item.name} members={Number(item.employeesCount)} />}
				</QuickList>
				<QuickList
					className={quickListStyles.quickList}
					title={t('All Students In School')}
					linkTitle={t('Go To Students')}
					noMessages={[t('No students yet')]}
					items={dashboard.allStudents}
					toFullList={TRoutes.STUDENTS}
				>
					{item => (
						<StudentItem
							id={item.id}
							avatar={TApi.PUBLIC_PROFILE_AVATAR(item.avatarHashName)}
							name={`${item.firstName} ${item.lastName}`}
						/>
					)}
				</QuickList>
			</div>
		</div>
	)
}

Dashboard.propTypes = {
	firstName: PropTypes.string.isRequired,
	lastName: PropTypes.string.isRequired,
	avatarHashName: PropTypes.string,
	school: PropTypes.object,
	gender: PropTypes.string
}

const mapStateToProps = state => ({
	firstName: state.user.profile.firstName,
	lastName: state.user.profile.lastName,
	school: state.user.profile.School,
	avatarHashName: state.user.profile.avatarHashName,
	gender: state.user.profile.gender
})

export default connect(mapStateToProps)(Dashboard)
