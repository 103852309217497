import Joi from 'joi'
import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { joiResolver } from '@hookform/resolvers/joi'

import Btn from '../../../../../../UI/Btn'
import TextField from '../../../../../../UI/TextField'

import styles from '../style.module.scss'

const schema = Joi.object({
	financialReportingReminders: Joi.object().keys({
		JCFirstReminderDaysBeforeDeadline: Joi.number().integer().min(1).required(),
		JCJBCFirstReminderDaysBeforeDeadline: Joi.number().integer().min(1).required(),
		JCReminderFrequencyDay: Joi.number().integer().min(1).required(),
		JCJBCReminderFrequencyDay: Joi.number().integer().min(1).required()
	})
})

export default function FinancialReportingReminders(props) {
	const { t } = useTranslation()
	const {
		register,
		reset,
		handleSubmit,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema)
	})

	const onSubmit = form => {
		props
			.updateOptions({
				name: 'financialReportingReminders',
				value: form.financialReportingReminders
			})
			.then(() => toast.success(t('Options was updated')))
	}

	useEffect(() => {
		reset({
			financialReportingReminders: props.form?.financialReportingReminders || {}
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.form])
	return (
		<form id="financialReportingReminders" className={styles.root} onSubmit={handleSubmit(onSubmit)}>
			<section className={styles.section}>
				<h3 className={styles.sectionTitle}>{t('Financial Reporting Reminders')}</h3>
				<div className={styles.sectionContent}>
					<div className={styles.miniSection}>
						<h5 className={styles.miniSectionTitle}>Junior Company</h5>
						<div className={styles.flexContainer}>
							<TextField
								className={styles.field}
								label={t('1st reminder [x] days before deadline')}
								mb={20}
								register={register(`financialReportingReminders.JCFirstReminderDaysBeforeDeadline`)}
								error={errors.financialReportingReminders?.JCFirstReminderDaysBeforeDeadline}
							/>
							<TextField
								className={styles.field}
								label={t('Reminder frequency each [x] day(s)')}
								mb={20}
								register={register(`financialReportingReminders.JCReminderFrequencyDay`)}
								error={errors.financialReportingReminders?.JCReminderFrequencyDay}
							/>
						</div>
					</div>

					<div>
						<h5 className={styles.miniSectionTitle}>
							{t('Junior Basic Company and Junior Compact Company')}
						</h5>
						<div className={styles.flexContainer}>
							<TextField
								className={styles.field}
								label={t('1st reminder [x] days before deadline')}
								mb={20}
								register={register(`financialReportingReminders.JCJBCFirstReminderDaysBeforeDeadline`)}
								error={errors.financialReportingReminders?.JCJBCFirstReminderDaysBeforeDeadline}
							/>
							<TextField
								className={styles.field}
								label={t('Reminder frequency each [x] day(s)')}
								mb={20}
								register={register(`financialReportingReminders.JCJBCReminderFrequencyDay`)}
								error={errors.financialReportingReminders?.JCJBCReminderFrequencyDay}
							/>
						</div>
					</div>
					<div className={styles.btnContainer}>
						<Btn
							className={styles.btn}
							type="submit"
							icon="arrow"
							iconSize={{ w: 10, h: 10 }}
							iconColor="#fff"
						>
							{t('save')}
						</Btn>
					</div>
				</div>
			</section>
		</form>
	)
}

FinancialReportingReminders.propTypes = {
	form: PropTypes.object.isRequired,
	updateOptions: PropTypes.func.isRequired
}
