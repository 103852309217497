import styles from './style.module.scss'
import MiniHeader from '../../../../../UI/MiniHeader'
import CompanyRevenues from './components/FirstReporting/CompanyRevenues'
import CompanyExpenditure from './components/FirstReporting/CompanyExpenditure'
import CompanyWagesSalary from './components/FirstReporting/CompanyWagesSalaries'
import CompanyAdvanceReturn from './components/FirstReporting/CompanyAdvanceReturn'
import CompanySemiAnnualReport from './components/FirstReporting/CompanySemiAnnualReport'
import { useState } from 'react'
import PropTypes from 'prop-types'
import http from '../../../../../shared/http'
import MiniProgressBar from '../../../../../UI/MiniProgressBar'
import MiniStep from '../../../../../UI/MiniProgressBar/MiniStep'
import { useEffect } from 'react'
import { TApi } from '../../../../../shared/const'
import { useTranslation } from 'react-i18next'

export default function JuniorCompanyFirstReporting(props) {
	const { t } = useTranslation()
	const steps = [
		{
			title: t('Revenues and sales')
		},
		{
			title: t('Expenses and input tax deduction')
		},
		{
			title: t('Wage and Salary contributions')
		},
		{
			title: t('Advance return for sales tax')
		},
		{
			title: t('Calculation half-year result')
		}
	]
	const [isLoading, setLoading] = useState(false)
	const [formData, setFormData] = useState(null)
	const [currentStep, setcurrentStep] = useState(1)
	const prevStep = () => {
		setcurrentStep(currentStep - 1)
	}
	const nextStep = () => {
		setcurrentStep(currentStep + 1)
	}

	const fetchData = () => {
		setLoading(true)
		http.get(TApi.STUDENT_REPORTS)
			.then(({ data }) => {
				setFormData({ ...formData, ...data.SemiannualReport })
			})
			.finally(() => setLoading(false))
	}

	const updateData = async form => {
		setLoading(true)
		return http
			.put(TApi.STUDENT_REPORTS, { type: 'SEMIANNUAL', ...form })
			.then(({ data }) => {
				setFormData({ ...formData, ...data })
			})
			.finally(() => setLoading(false))
	}

	useEffect(() => {
		!props.data && fetchData()
		props.data && setFormData(props.data)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	return (
		<div className={styles.root}>
			<MiniHeader title={t('Financial report first semester')} bg="#f8faff" />

			<MiniProgressBar className={styles.progerssBar} bg="#F8FAFF">
				{steps.map((v, i, a) => {
					return (
						<MiniStep
							key={i}
							needStep={i}
							currentStep={currentStep}
							allSteps={a.length}
							title={v.title}
							bg="#F8FAFF"
							onClick={e =>
								formData?.isSubmitted ? setcurrentStep(i + 1) : i < currentStep && setcurrentStep(i + 1)
							}
						/>
					)
				})}
			</MiniProgressBar>
			<div className="container">
				<CompanyRevenues
					isLoading={isLoading}
					form={formData}
					updateData={updateData}
					currentStep={currentStep}
					needStep={1}
					handlerNextStep={nextStep}
				/>
				<CompanyExpenditure
					isLoading={isLoading}
					form={formData}
					updateData={updateData}
					currentStep={currentStep}
					needStep={2}
					handlerNextStep={nextStep}
					handlerPrevStep={prevStep}
				/>
				<CompanyWagesSalary
					isLoading={isLoading}
					form={formData}
					updateData={updateData}
					currentStep={currentStep}
					needStep={3}
					handlerNextStep={nextStep}
					handlerPrevStep={prevStep}
				/>
				<CompanyAdvanceReturn
					showPayCredit
					isLoading={isLoading}
					form={formData}
					updateData={updateData}
					currentStep={currentStep}
					needStep={4}
					handlerNextStep={nextStep}
					handlerPrevStep={prevStep}
				/>
				<CompanySemiAnnualReport
					showPayCredit
					isLoading={isLoading}
					form={formData}
					updateData={updateData}
					currentStep={currentStep}
					needStep={5}
					handlerPrevStep={prevStep}
				/>
			</div>
		</div>
	)
}

JuniorCompanyFirstReporting.propTypes = {
	reportPeriod: PropTypes.string,
	data: PropTypes.object
}
