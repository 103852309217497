import clsx from 'clsx'
import { connect } from 'react-redux'
import { useEffect, useState } from 'react'
import * as queryString from 'query-string'
import { useLocation } from 'react-router-dom'

import http from '../../../../../shared/http'
import { TApi } from '../../../../../shared/const'

import Logo from '../../../../../assets/images/logo.svg'

import styles from '../../../../../pages/Auth/common.module.scss'
import { logIn } from '../../../../../redux/ducks/user'
import instance from '../../../../../shared/http'

function ConfirmEmailChange(props) {
	const [isLoading, setLoading] = useState(false)
	const [message, setMessage] = useState('')
	const [isStatusError, setStatusError] = useState(false)
	const { search } = useLocation()

	const { token } = queryString.parse(search)

	const fetchConfirm = () => {
		setLoading(true)

		http.put(
			TApi.EMAIL_CHANGE_CONFIRM,
			{
				token
			},
			{
				disableToast: true
			}
		)
			.then(({ data }) => {
				instance.defaults.headers['Authorization'] = `Bearer ${data.token}`
				props.logIn({ role: data.user.role, profile: data.user })
				localStorage.setItem(
					'auth',
					JSON.stringify({
						role: data.user.role,
						token: data.token
					})
				)
				setMessage('Email successfully activated')
			})
			.catch(err => {
				const message = err.response?.data?.message || ''
				setMessage(`${err.message}. ${message}`)
				setStatusError(true)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	useEffect(() => {
		if (!token) {
			setStatusError(true)
			return setMessage('Token is not founded')
		}

		fetchConfirm()
		/* eslint-disable-next-line */
	}, [])

	return (
		<div className={styles.root}>
			<div className={styles.header}>
				<div className={styles.miniContainer}>
					<h2 className={styles.title}>Email confirmation</h2>
				</div>
			</div>
			<div className={clsx(styles.container, styles.form, styles.confirmContentSuc)}>
				<img className={styles.logoImg} src={Logo} alt="Logo" />
				{isLoading && (
					<span className={styles.confirmText}>Account confirmation is in progress. Loading...</span>
				)}
				{!!message && (
					<span className={clsx(styles.confirmText, isStatusError && styles.confirmTextError)}>
						{message}
					</span>
				)}
			</div>
		</div>
	)
}

const mapDispatchToProps = dispatch => ({
	logIn: data => dispatch(logIn(data))
})

export default connect(null, mapDispatchToProps)(ConfirmEmailChange)
