import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import Btn from '../../../../../../UI/Btn'
import styles from '../style.module.scss'
import Joi from 'joi'
import { joiResolver } from '@hookform/resolvers/joi'
import DateTimeField from '../../../../../../UI/DateTimeField'
import { useTranslation } from 'react-i18next'

const schema = Joi.object({
	reportsDeadline: Joi.object().keys({
		JC_annual: Joi.date().required(),
		JC_semiannual: Joi.date().required(),
		JCC: Joi.date().required(),
		JBC: Joi.date().required()
	})
})

export default function ReportsDeadLine(props) {
	const { t } = useTranslation()
	const {
		control,
		reset,
		handleSubmit,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema)
	})

	const onSubmit = form => {
		let data = {}
		for (const key in form.reportsDeadline) {
			data[key] = form.reportsDeadline[key].toISOString()
		}
		props
			.updateOptions({ name: 'reportsDeadline', value: data })
			.then(() => toast.success(t('Options was updated')))
	}

	useEffect(() => {
		let data = {}
		for (const key in props.form?.reportsDeadline) {
			data[key] = Date.parse(props.form.reportsDeadline[key])
		}
		reset({ reportsDeadline: data })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.form])
	return (
		<form id="reportsDeadline" className={styles.root} onSubmit={handleSubmit(onSubmit)}>
			<section className={styles.section}>
				<h3 className={styles.sectionTitle}>{t('Reports deadline')}</h3>
				<div className={styles.sectionContent}>
					<div className={styles.flexContainer}>
						<DateTimeField
							className={styles.field}
							control={control}
							minDate={new Date()}
							label={t('Junior Annual report deadline')}
							mb={20}
							name={`reportsDeadline.JC_annual`}
							error={errors.reportsDeadline?.JC_annual}
						/>
						<DateTimeField
							className={styles.field}
							control={control}
							minDate={new Date()}
							label={t('Junior Semiannual report deadline')}
							mb={20}
							name={`reportsDeadline.JC_semiannual`}
							error={errors.reportsDeadline?.JC_semiannual}
						/>
					</div>
					<DateTimeField
						className={styles.field}
						control={control}
						minDate={new Date()}
						label={t('Junior Compact report deadline')}
						mb={20}
						name={`reportsDeadline.JCC`}
						error={errors.reportsDeadline?.JCC}
					/>
					<DateTimeField
						className={styles.field}
						control={control}
						minDate={new Date()}
						label={t('Junior Basic report deadline')}
						mb={20}
						name={`reportsDeadline.JBC`}
						error={errors.reportsDeadline?.JBC}
					/>
					<div className={styles.btnContainer}>
						<Btn
							className={styles.btn}
							type="submit"
							icon="arrow"
							iconSize={{ w: 10, h: 10 }}
							iconColor="#fff"
						>
							{t('save')}
						</Btn>
					</div>
				</div>
			</section>
		</form>
	)
}
ReportsDeadLine.propTypes = {
	form: PropTypes.object.isRequired,
	updateOptions: PropTypes.func.isRequired
}
