import { useState } from 'react'

import MiniHeader from '../../../../UI/MiniHeader'

import School from './components/School'
import Contact from './components/Contact'
import Security from './components/Security'
import BasicInfo from './components/BasicInfo'
import AvatarPhoto from './components/AvatarPhoto'
import { useTranslation } from 'react-i18next'

import styles from './style.module.scss'
import { connect } from 'react-redux'

function Profile(props) {
	const { t } = useTranslation()
	const [isLoading, setLoading] = useState(false)

	if (!props.profile.id) {
		return <div />
	}

	return (
		<div className={styles.root}>
			<div>
				<MiniHeader title={t('profile')} bg="#f8faff" />
				<BasicInfo isLoading={isLoading} setLoading={setLoading} />
				<Contact isLoading={isLoading} setLoading={setLoading} />
				<AvatarPhoto isLoading={isLoading} setLoading={setLoading} />
				<School isLoading={isLoading} setLoading={setLoading} />
				<Security isLoading={isLoading} setLoading={setLoading} />
			</div>
		</div>
	)
}

const mapStateToProps = state => {
	return {
		profile: state.user.profile
	}
}

export default connect(mapStateToProps)(Profile)
