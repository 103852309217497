import BaseModal from '../../../../UI/BaseModal/BaseModal'
import { types } from '../../../../shared/eventEmitter'
import styles from '../style.module.scss'
import SelectField from '../../../../UI/SelectField'
import Joi from 'joi'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { joiPhone } from '../../../../shared/helpers'
import TextField from '../../../../UI/TextField'
import Btn from '../../../../UI/Btn'
import http from '../../../../shared/http'
import { TApi, TLanguages, TRoles } from '../../../../shared/const'
import { schemaOptions } from '../../../../shared/i18n'
import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import DateTimeField from '../../../../UI/DateTimeField'
import { subYears } from 'date-fns'

const schema = Joi.object({
	salutation: Joi.string().required(),
	titlePrefixed: Joi.string().allow(''),
	firstName: Joi.string()
		.min(2)
		.max(26)
		.regex(/^[a-zA-ZА-яЁёÄÖÜẞäöüß]+( [a-zA-ZА-яЁёÄÖÜẞäöüß]+)*$/)
		.required()
		.messages({
			'string.pattern.base': 'Only letters are allowed !'
		}),
	lastName: Joi.string()
		.min(2)
		.max(26)
		.regex(/^[a-zA-ZА-яЁёÄÖÜẞäöüß]+( [a-zA-ZА-яЁёÄÖÜẞäöüß]+)*$/)
		.required()
		.messages({
			'string.pattern.base': 'Only letters are allowed !',
			'object.regex"': 'Only letters are allowed !'
		}),
	titleSuffixed: Joi.string().allow(''),
	company: Joi.string().required(),
	position: Joi.string().allow(''),
	email: Joi.string().email({ tlds: false }).required(),
	streetName: Joi.string().min(2).max(26).allow(''),
	areaCode: Joi.string()
		.regex(/^[0-9]{4}(?:-[0-9]{4})?$/)
		.allow('')
		.message('Only 0000 format or a empty string is allowed !'),
	houseNumber: Joi.number().empty('').allow(null).default(null),
	city: Joi.string().allow(''),
	phone: joiPhone.allow(''),
	birthday: Joi.date()
})

function AddExpertModal(props) {
	const { t, i18n } = useTranslation()
	const {
		register,
		handleSubmit,
		setValue,
		trigger,
		reset,
		control,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema, {
			errors: {
				labels: false,
				language: localStorage.getItem('i18nextLng')
			},
			...schemaOptions
		})
	})

	const [id, setId] = useState(null)
	const [isLoading, setLoading] = useState(false)
	const [onDone, setOnDone] = useState(null)
	const minAge = subYears(new Date(), 6)

	useEffect(() => {
		if (Object.keys(errors).length) trigger()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localStorage.getItem('i18nextLng')])

	const handleOpen = (expert = null, onDone = null) => {
		setOnDone(() => onDone)

		if (!expert) return
		setId(expert.id)
		setValue('salutation', expert.salutation)
		setValue('titlePrefixed', expert.titlePrefixed)
		setValue('firstName', expert.firstName)
		setValue('lastName', expert.lastName)
		setValue('titleSuffixed', expert.titleSuffixed)
		setValue('company', expert.company)
		setValue('position', expert.position)
		setValue('email', expert.email)
		setValue('streetName', expert.streetName)
		setValue('areaCode', expert.areaCode ? expert.areaCode : '')
		setValue('houseNumber', expert.houseNumber ? expert.houseNumber : null)
		setValue('city', expert.city)
		setValue('phone', expert.phone)
		if (expert.dateOfBirth) setValue('birthday', new Date(expert.dateOfBirth))
	}

	const onSubmit = (form, closeModal) => {
		setLoading(true)

		if (id) delete form.email
		const request =
			props.role === TRoles.STUDENT
				? id
					? http.put(TApi.STUDENT_CHANGE_EXPERTS(id), form)
					: http.post(TApi.STUDENT_EXPERTS, form)
				: id && http.put(TApi.ADMIN_EXPERT_ID(id), form)

		request
			.then(({ data }) => {
				toast.success(`${t('The expert was')} ${id ? t('updated') : t('created')} ${t('successfully')}`)
				closeModal()
				setId(null)
				if (onDone) onDone(0)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const handleClose = () => {
		reset()
		setId(null)
	}

	return (
		<BaseModal
			onClose={handleClose}
			onOpen={handleOpen}
			nameEvent={types.openAddExpertModal}
			title={id ? t('Update economic expert') : t('Add economic expert')}
			isLoading={isLoading}
			maxWidth={890}
		>
			{({ closeModal }) => (
				<div>
					<form className={styles.container} onSubmit={handleSubmit(form => onSubmit(form, closeModal))}>
						<section className={styles.section}>
							<span className={styles.leftForm}>
								<SelectField
									required
									mb={20}
									disabled={isLoading}
									label={t('salutation')}
									control={control}
									error={errors.salutation}
									name="salutation"
									placeholder={t('salutation')}
									options={props.salutations?.map(v => ({
										value: v.value_EN,
										label: i18n.language === TLanguages.EN ? v.value_EN : v.value_DE
									}))}
								/>
							</span>
							<span className={styles.rightForm}>
								<TextField
									mb={20}
									disabled={isLoading}
									label={t('Title Prefixed')}
									register={register('titlePrefixed')}
									error={errors.titlePrefixed}
									placeholder={t('Enter Title')}
								/>
							</span>
						</section>

						<section className={styles.section}>
							<span className={styles.leftForm}>
								<TextField
									required
									mb={20}
									disabled={isLoading}
									label={t('First Name')}
									register={register('firstName')}
									error={errors.firstName}
									placeholder={t('Type First Name')}
								/>
							</span>
							<span className={styles.rightForm}>
								<TextField
									required
									mb={20}
									disabled={isLoading}
									label={t('Last Name')}
									register={register('lastName')}
									error={errors.lastName}
									placeholder={t('Type Last Name')}
								/>
							</span>
						</section>

						<section className={styles.section}>
							<span className={styles.leftForm}>
								<TextField
									label={t('Title Suffixed')}
									disabled={isLoading}
									error={errors.titleSuffixed}
									register={register('titleSuffixed')}
									placeholder={t('Enter Title')}
								/>
							</span>
							<span className={styles.rightForm}>
								<DateTimeField
									disabled={isLoading}
									mb={20}
									name="birthday"
									label={t('Date of Birth')}
									placeholder="01.01.2000"
									error={errors.birthday}
									control={control}
									dateFormat="dd.MM.yyyy"
									maxDate={minAge}
								/>
							</span>
						</section>

						<div className={styles.line} />

						<section className={styles.section}>
							<span className={styles.leftForm}>
								<TextField
									required
									mb={20}
									label={t('Company')}
									disabled={isLoading}
									error={errors.company}
									register={register('company')}
									placeholder={t('Company name')}
								/>
							</span>
						</section>

						<section className={styles.section}>
							<span className={styles.leftForm}>
								<TextField
									mb={20}
									label={t('Function, position')}
									disabled={isLoading}
									error={errors.position}
									register={register('position')}
									placeholder={t('Function, position')}
								/>
							</span>
						</section>

						<div className={styles.line} />

						<section className={styles.section}>
							<span className={styles.leftForm}>
								<TextField
									required
									mb={20}
									disabled={isLoading || id}
									label="Email"
									register={register('email')}
									error={errors.email}
									placeholder="expert@email.com"
								/>
							</span>
							<span className={styles.rightForm}>
								<TextField
									mb={20}
									disabled={isLoading}
									label={t('phone')}
									register={register('phone')}
									error={errors.phone}
									placeholder="+43 6XX XXXXXX"
								/>
							</span>
						</section>

						<section className={styles.section}>
							<span className={styles.leftForm}>
								<TextField
									mb={20}
									disabled={isLoading}
									label={t('Street')}
									register={register('streetName')}
									error={errors.streetName}
									placeholder={t('Street')}
								/>
							</span>
							<span className={styles.rightForm}>
								<TextField
									mb={20}
									disabled={isLoading}
									label={t('House number')}
									register={register('houseNumber')}
									error={errors.houseNumber}
									placeholder={t('House number')}
								/>
							</span>
						</section>

						<section className={styles.section}>
							<span className={styles.leftForm}>
								<TextField
									mb={20}
									disabled={isLoading}
									label={t('Zip Code')}
									register={register('areaCode')}
									error={errors.areaCode}
									placeholder={t('Zip Code')}
								/>
							</span>
							<span className={styles.rightForm}>
								<TextField
									mb={20}
									disabled={isLoading}
									label={t('City')}
									register={register('city')}
									error={errors.city}
									placeholder={t('City')}
								/>
							</span>
						</section>

						<div className={styles.btn}>
							<Btn
								loading={isLoading}
								width={150}
								className={styles.cancelBtn}
								theme="whiteBackground"
								onClick={() => {
									handleClose()
									closeModal()
								}}
							>
								{t('Cancel')}
							</Btn>
							<div style={{ display: 'inline-block', marginLeft: 12 }}>
								<Btn
									loading={isLoading}
									width={150}
									type="submit"
									icon="arrow"
									iconSize={{ w: 10, h: 10 }}
									iconColor="#fff"
								>
									{id ? t('update') : t('save')}
								</Btn>
							</div>
						</div>
					</form>
				</div>
			)}
		</BaseModal>
	)
}
AddExpertModal.propTypes = {
	role: PropTypes.string.isRequired,

	salutations: PropTypes.array
}
const mapStateToProps = state => ({
	role: state.user.role,
	salutations: state.options.salutations
})
export default connect(mapStateToProps, null)(AddExpertModal)
