import PropTypes from 'prop-types'

import styles from '../style.module.scss'

export default function SelectedOptions(props) {
	return props.values.map(item => (
		<div key={item.value} className={styles.optionBlock}>
			<span className={styles.optionName}>{item.label}</span>
			<button
				className={styles.btnCancelOption}
				onClick={() => props.onChange(props.values.filter(({ value }) => value !== item.value))}
			>
				X
			</button>
		</div>
	))
}

SelectedOptions.propTypes = {
	values: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired
		})
	).isRequired,
	onChange: PropTypes.func.isRequired
}
