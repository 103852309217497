import Joi from 'joi'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { joiResolver } from '@hookform/resolvers/joi'

import Btn from '../../../../UI/Btn'
import Chip from '../../../../UI/Chip'
import SelectField from '../../../../UI/SelectField'
import BaseModal from '../../../../UI/BaseModal/BaseModal'

import http from '../../../../shared/http'
import { TApi } from '../../../../shared/const'
import { schemaOptions } from '../../../../shared/i18n'
import { types } from '../../../../shared/eventEmitter'

import styles from '../AddFeedModal/style.module.scss'
import Icon from '../../../../UI/Icon'

const schema = Joi.object({
	school: Joi.object({
		id: Joi.number().integer().required()
	})
		.unknown()
		.required()
})

export default function MergeSchoolsModal() {
	const { t } = useTranslation()
	const {
		reset,
		control,
		handleSubmit,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema, {
			errors: {
				labels: false,
				language: localStorage.getItem('i18nextLng')
			},
			...schemaOptions
		})
	})

	const [isLoading, setLoading] = useState(false)
	const [onDone, setOnDone] = useState(null)
	const [fromSchool, setFromSchool] = useState({})

	const handleOpen = (fromSchool, onDone) => {
		setFromSchool(fromSchool)
		setOnDone(() => onDone)
		reset()
	}

	const loadSchoolOptions = async (search, loadedOptions) => {
		const {
			data: { rows, count }
		} = await http.get(TApi.ADMIN_GET_SCHOOLS, {
			params: {
				search,
				limit: 30,
				except: fromSchool.id,
				offset: loadedOptions.length
			}
		})

		return {
			options: rows.map(row => ({ ...row, value: row.id, label: row.name })),
			hasMore: loadedOptions.length < count
		}
	}

	const onSubmit = (fields, closeModal) => {
		setLoading(true)

		http.put(TApi.ADMIN_PUT_SCHOOLS_MERGE, {
			fromSchoolId: fromSchool.id,
			toSchoolId: fields.school.id
		})
			.then(() => {
				toast.success(t(`The schools was merged successfully`))
				closeModal()
				reset()
				if (onDone) onDone()
			})
			.finally(() => {
				setLoading(false)
			})
	}

	return (
		<BaseModal
			isLoading={isLoading}
			onOpen={handleOpen}
			nameEvent={types.openMergeSchoolModal}
			title={t('Merge schools')}
			maxWidth={522}
		>
			{({ closeModal }) => (
				<form className={styles.root} onSubmit={handleSubmit(form => onSubmit(form, closeModal))}>
					<p>
						Merge from school: <br />
						{fromSchool.verified && (
							<Chip className={styles.fromSchoolChip} miniRadius>
								Verified
							</Chip>
						)}
						<span style={{ marginLeft: 10 }}>
							{fromSchool.id} - <b>{fromSchool.name}</b> - #{fromSchool.number} - {fromSchool.zipCode}
						</span>
					</p>
					<div>
						<Icon
							className={styles.iconArrowMerge}
							name="mergeArrow"
							size={{ w: 45, h: 50 }}
							color="#22404D"
						/>
					</div>
					<SelectField
						async
						label={t('To school')}
						isSearchable
						error={errors.school}
						disabled={isLoading}
						control={control}
						name="school"
						className={styles.selectField}
						placeholder={t('School')}
						debounceTimeout={400}
						loadOptions={loadSchoolOptions}
						formatOptionLabel={({ label, ...row }) => (
							<div className={styles.selectFieldOption}>
								{row.verified && <Chip miniRadius>{t('Verified')}</Chip>}
								<span style={{ marginLeft: 10 }}>
									{row.id} - {row.name} - #{row.number} - {row.zipCode}
								</span>
							</div>
						)}
					/>

					<Btn
						loading={isLoading}
						disabled={isLoading}
						className={styles.btnSubmit}
						type="submit"
						icon="send"
						iconSize={{ w: 15, h: 15 }}
						iconColor="#fff"
					>
						{t('Merge')}
					</Btn>
				</form>
			)}
		</BaseModal>
	)
}
