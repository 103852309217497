import { connect } from 'react-redux'
import { TApi } from '../../../../../../shared/const'
import http from '../../../../../../shared/http'

import styles from '../../style.module.scss'
import AvatarUploader from '../../../../../../UI/AvatarUploader'
import { useEffect, useState } from 'react'
import { updateProfile } from '../../../../../../redux/ducks/user'
import { useTranslation } from 'react-i18next'
import { joiFile } from '../../../../../../shared/helpers'
import Joi from 'joi'
import { toast } from 'react-toastify'
import { joiResolver } from '@hookform/resolvers/joi'
import { useForm } from 'react-hook-form'

const schema = Joi.object({
	avatar: joiFile(10)
})

function AvatarPhoto(props) {
	const { t } = useTranslation()
	const [avatarSrc, setAvatarSrc] = useState('')

	const {
		register,
		handleSubmit,
		clearErrors,
		formState: { errors }
	} = useForm({
		reValidateMode: 'onChange',
		resolver: joiResolver(schema)
	})

	const handleChange = fields => {
		const form = new FormData()
		if (fields.avatar && fields.avatar[0]) form.append('avatar', fields.avatar[0])

		http.put(TApi.TEACHER_PROFILE_AVATAR, form).then(({ data }) => {
			props.updateProfile({ ...data })
			setAvatarSrc(URL.createObjectURL(fields.avatar[0]))
			toast.success(t('Avatar successfully updated'))
		})
	}

	const handleClean = () =>
		http.delete(TApi.TEACHER_PROFILE_AVATAR).then(() => {
			toast.success(t('Avatar successfully deleted'))
			setAvatarSrc('')
			clearErrors('avatar')
			props.updateProfile({ avatarHashName: '' })
		})

	useEffect(() => {
		setAvatarSrc(props.avatarHashName ? TApi.PUBLIC_PROFILE_AVATAR(props.avatarHashName) : '')
	}, [props.avatarHashName])

	return (
		<form className={styles.section}>
			<h1 className={styles.sectionTitle}>{t('Avatar photo')}</h1>
			<div className={styles.sectionContent}>
				<AvatarUploader
					src={avatarSrc}
					className={styles.avatarUploader}
					onChange={handleSubmit(handleChange)}
					error={errors.avatar}
					onClean={handleClean}
					register={register('avatar')}
				/>
			</div>
		</form>
	)
}
const mapStateToProps = state => ({
	avatarHashName: state.user.profile.avatarHashName
})

const mapDispatchToProps = dispatch => ({
	updateProfile: data => dispatch(updateProfile(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AvatarPhoto)
