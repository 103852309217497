import clsx from 'clsx'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'

import Icon from '../Icon'

import styles from './style.module.scss'

export default function BtnTooltip(props) {
	const id = `${Date.now()}-${Math.random()}`

	return (
		<div className={props.className}>
			<button
				// https://github.com/wwayne/react-tooltip/issues/377
				onMouseEnter={() => ReactTooltip.hide()}
				className={styles.btnActive}
				data-tip
				data-event="click"
				data-for={id}
			>
				<Icon name="threeDots" color="#008B9C" />
			</button>
			<ReactTooltip
				id={id}
				clickable
				className={styles.tooltip}
				globalEventOff="click"
				getContent={() => (
					<div className={styles.bthList}>
						{props.items.map((item, index) => (
							<button
								disabled={item.disabled}
								style={item.style}
								className={clsx(styles.btnItem, item.className)}
								key={index}
								onClick={item.onClick}
							>
								{item.label}
							</button>
						))}
					</div>
				)}
			/>
		</div>
	)
}

BtnTooltip.propTypes = {
	className: PropTypes.string,
	items: PropTypes.arrayOf(
		PropTypes.shape({
			disabled: PropTypes.bool,
			className: PropTypes.string,
			label: PropTypes.string.isRequired,
			onClick: PropTypes.func.isRequired
		}).isRequired
	).isRequired
}

BtnTooltip.defaultProps = {
	className: ''
}
